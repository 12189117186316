const sellerTabs = {
  dados: {
    index: 0,
    name: 'Título',
  },
};

const buyerTabs = {
  dados: {
    index: 0,
    name: 'Título',
  },
  reputacaoCedente: {
    index: 1,
    name: 'Reputação',
  },
  cedente: {
    index: 2,
    name: 'Cedente',
  },
};

const adminTabs = {
  dados: {
    index: 0,
    name: 'Título',
  },
  lances: {
    index: 1,
    name: 'Lances',
  },
  cedente: {
    index: 2,
    name: 'Dossiê Cedente',
  },
  reputacaoCedente: {
    index: 3,
    name: 'Reputação',
  },
  tipoMercado: {
    index: 4,
    name: 'Tipo de Mercado',
  },
};

export const selectTab = (userTypeId) => {
  if (userTypeId === 1) return sellerTabs;
  if (userTypeId === 2) return buyerTabs;
  if (userTypeId === 3) return adminTabs;
};
