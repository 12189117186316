import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useReactToPrint } from "react-to-print";

import {
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bepayLogo from "assets/images/bepay.png";
import ptBR from "date-fns/locale/pt-BR";
import { formatCNPJOutput } from "pages/CadastroFinaciadorCedente/cnpjFormatter";

import "date-fns";

import PageTitle from "components/PageTitle";
import { ReactCatch, ReactIf, ReactThen } from "components/ReactIf";

import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";

import "./extrato.scss";
import AxiosInterceptor from "utils/axios-interceptor";

export default () => {
  const { accountId } = useParams();
  const { user, isAdmin } = useAuth();
  const { toast } = useToast();

  const [accountInfo, setAccountInfo] = useState(undefined);
  const [extractPeriod, setExtractPeriod] = useState({ accountId });
  const [extractStatement, setExtractStatement] = useState(undefined);
  const [generating, setGenerating] = useState(false);
  const [todayAmount, setTodayAmount] = useState(undefined);
  const [historyTypes, setHistoryTypes] = useState({});
  const [bankAccountId, setBankAccountId] = useState(undefined);
  const [unavailableAccount, setUnavailableAccount] = useState(undefined);

  const getAccountId = () =>
    new Promise((resolve, reject) => {
      const axiosInstance = AxiosInterceptor();

      axiosInstance
        .get(
          `${process.env.REACT_APP_NODE_API}/business/bankAccounts/${user.businessId}`
        )
        .then((res) => {
          resolve(res.data[0]?.accountId);
        })
        .catch(() => {
          reject();
        });
    });

  const getAccountInfo = async (businessAccountId) => {
    if (accountInfo !== undefined) return;
    const axiosInstance = AxiosInterceptor();
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_NODE_API}/businessBankAccounts/accountId/${
        businessAccountId || accountId
      }`
    );

    const colocaDigito = (accountNumber) => {
      const strLength = accountNumber.length;
      return `${accountNumber.slice(0, strLength - 1)}-${accountNumber.charAt(
        strLength - 1
      )}`;
    };

    const pix = (accountNumber) => {
      if (accountNumber.length !== 10) return undefined;
      const pixAccount = Number(accountNumber) - 2 * 10 ** 9;
      return pixAccount;
    };

    const infos = {
      corporateName: response.data.corporateName,
      countryIdentity: formatCNPJOutput(response.data.countryIdentity),
      bankCode: response.data.bankCode,
      bankBranch: response.data.bankBranch,
      accountNumber: response.data.accountNumber
        ? colocaDigito(response.data.accountNumber.replace("-", ""))
        : "",
      pix: response.data.accountNumber
        ? pix(response.data.accountNumber.replace("-", ""))
        : "",
    };
    setAccountInfo(infos);
  };

  const getExtract = async ({ accountId, begin, end }) => {
    const axiosInstance = AxiosInterceptor();
    if (generating === true) return;
    if (unavailableAccount) return;
    setGenerating(true);
    const extractBody = {
      AccountID: accountId,
      INICIO: begin,
      FIM: end,
    };
    let response;
    try {
      response = await axiosInstance.post(
        `${process.env.REACT_APP_DECISION_API}/api/v1/le/execute/86b4a73b-7bb1-4349-8ba4-3df937fa06cf`,
        extractBody,
        {
          headers: { Authorization: process.env.REACT_APP_DECISION_API_TOKEN },
        }
      );
      if (response.data.result.error) {
        setUnavailableAccount(true);
        return;
      }
      setGenerating(false);
      setExtractStatement(
        response.data.info.external_sources[1].data.data.statement
      );
    } catch (e) {
      setGenerating(false);
    }
  };

  const getTodayAmount = async (businessAccountId) => {
    if (todayAmount !== undefined) return;
    if (unavailableAccount) return;

    const formatDate = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      return `${date.getFullYear()}-${month < 10 ? `0${month}` : month}-${
        day < 10 ? `0${day}` : day
      }`;
    };

    const formatNumber = (number, prefix) =>
      prefix +
      (number === 0
        ? "0,00"
        : number.toLocaleString({
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }));

    const today = new Date();

    const extractBody = {
      AccountID: businessAccountId || accountId,
      INICIO: formatDate(today),
      FIM: formatDate(today),
    };
    const axiosInstance = AxiosInterceptor();
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_DECISION_API}/api/v1/le/execute/86b4a73b-7bb1-4349-8ba4-3df937fa06cf`,
      extractBody,
      {
        headers: { Authorization: process.env.REACT_APP_DECISION_API_TOKEN },
      }
    );

    if (response.data.result.error) {
      setUnavailableAccount(true);
      return;
    }
    const saldo =
      response.data.info.external_sources[1].data.data.statement.find((item) =>
        item.description.toLowerCase().includes("disponivel")
      );
    if (saldo === undefined) {
      setTodayAmount(null);
      return;
    }
    const day = saldo.creditDate.split("-")[2];
    const month = saldo.creditDate.split("-")[1];
    const year = saldo.creditDate.split("-")[0];

    saldo.creditDate = `${day}/${month}/${year}`;
    saldo.amount = formatNumber(saldo.amount, "R$");

    setTodayAmount(saldo);
  };

  const changePeriod = (attr, value) => {
    const newPeriod = extractPeriod;
    if (attr === "begin") {
      newPeriod.begin = value;
    } else if (attr === "end") {
      newPeriod.end = value;
    } else {
      return;
    }
    setExtractPeriod(newPeriod);
  };

  const evalComponentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => evalComponentRef.current,
    documentTitle: `Extrato ${accountInfo?.corporateName}`,
    pageStyle:
      "@page { width: 1500px; margin: 5mm } @media print { .table-responsive { overflow-x: hidden } } ",
  });

  const periods = [
    {
      value: 1,
      label: "Hoje",
    },
    {
      value: 2,
      label: "Últimos 7 dias",
    },
    {
      value: 3,
      label: "Mês",
    },
    {
      value: 4,
      label: "Período personalizado",
    },
  ];

  const monthNames = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const [months, setMonths] = useState(undefined);
  const [selectedPeriodOption, setSelectedPeriodOption] = useState(0);
  const [selectedMonthOption, setSelectedMonthOption] = useState(0);
  const [selectedBeginDate, setSelectedBeginDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    return `${date.getFullYear()}-${month < 10 ? `0${month}` : month}-${
      day < 10 ? `0${day}` : day
    }`;
  };

  const handleChangePeriod = (value) => {
    setSelectedPeriodOption(value);
    if (value === 1) {
      const today = new Date();
      changePeriod("begin", formatDate(today));
      changePeriod("end", formatDate(today));
    } else if (value === 2) {
      const today = new Date();
      const day = 1000 * 60 * 60 * 24;
      const subtract = today.valueOf() - 7 * day;
      changePeriod("begin", formatDate(new Date(subtract)));
      changePeriod("end", formatDate(today));
    } else if (value === 3) {
      handleMonthChange(0);
    } else if (value === 4) {
      const today = new Date();
      changePeriod("begin", formatDate(today));
      changePeriod("end", formatDate(today));
    } else {
      changePeriod("begin", "");
      changePeriod("end", "");
    }
  };

  const handleMonthChange = (value) => {
    const month = months[value];
    changePeriod("begin", month.begin);
    changePeriod("end", month.end);
    setSelectedMonthOption(value);
  };

  const handleBeginDateChange = (date) => {
    const formattedDate = formatDate(date);
    if (selectedEndDate === undefined) {
      changePeriod("begin", formattedDate);
      setSelectedBeginDate(date);
    } else if (selectedEndDate - date > 0) {
      changePeriod("begin", formattedDate);
      setSelectedBeginDate(date);
    } else {
      toast("Data inicial deve ser menor que a data final");
    }
  };

  const handleEndDateChange = (date) => {
    const formattedDate = formatDate(date);
    if (selectedBeginDate === undefined) {
      changePeriod("end", formattedDate);
      setSelectedEndDate(date);
    } else if (date - selectedBeginDate > 0) {
      changePeriod("end", formattedDate);
      setSelectedEndDate(date);
    } else {
      toast("Data final deve ser maior que a data inicial");
    }
  };

  useEffect(() => {
    if (accountId === undefined) {
      getAccountId().then((res) => {
        if (res !== undefined) {
          if (res.length === 0) {
            setUnavailableAccount(true);
          } else {
            getAccountInfo(res);
            getTodayAmount(res);
            setExtractPeriod({ ...extractPeriod, accountId: res });
            setBankAccountId(res);
          }
        } else {
          setUnavailableAccount(true);
        }
      });
    } else {
      getAccountInfo();
      getTodayAmount();
    }
    if (months === undefined) {
      const mmonths = [];
      for (let i = 1; i < 14; i++) {
        const actualYear = new Date().getFullYear();
        let MM = new Date().getMonth() - i + 1;

        const prevYear = MM < 0;
        MM += prevYear ? 12 : 0;

        mmonths.push({
          value: i - 1,
          label: monthNames[MM],
          begin: `${prevYear ? actualYear - 1 : actualYear}-${
            MM + 1 > 9 ? MM + 1 : `0${MM + 1}`
          }-01`,
          end: `${prevYear ? actualYear - 1 : actualYear}-${
            MM + 1 > 9 ? MM + 1 : `0${MM + 1}`
          }-${new Date(
            prevYear ? actualYear - 1 : actualYear,
            MM + 1,
            0
          ).getDate()}`,
        });
      }
      setMonths(mmonths);
    }
  }, []);

  const ExtractRow = ({ extractItem, businessAccountId }) => {
    const formatDate = (date) => {
      if (date === undefined) return "";
      const day = date.split("-")[2];
      const month = date.split("-")[1];
      const year = date.split("-")[0];
      return `${day}/${month}/${year}`;
    };

    if (
      extractItem.additionalInfo === undefined &&
      extractItem.comment === undefined &&
      !(extractItem.historyCode === 99 || extractItem.historyCode === undefined)
    ) {
      const hTypes = historyTypes;
      if (hTypes[extractItem.historyCode] === undefined) {
        hTypes[extractItem.historyCode] = [extractItem];
      } else {
        hTypes[extractItem.historyCode].push(extractItem);
      }
      setHistoryTypes(hTypes);
    }

    const row = {
      dataLancamento: formatDate(extractItem?.creditDate),
      descricao: extractItem?.description,
      complemento:
        extractItem.additionalInfo !== undefined ? (
          extractItem.additionalInfo
        ) : extractItem.comment !== undefined ? (
          extractItem.comment
        ) : extractItem.historyCode === 99 ||
          extractItem.historyCode === 205 ||
          extractItem.historyCode === undefined ? (
          ""
        ) : (
          <ShowAdditionalInfoButton
            accountId={businessAccountId || accountId}
            transactionId={extractItem.transactionId}
            transactionType={extractItem.type}
          />
        ),
      tipo: extractItem.type,
    };

    if (row.tipo === "D" || row.tipo === "C") {
      row.valor = extractItem.amount;
      row.saldo = "";
    } else if (row.tipo === "S") {
      row.valor = "";
      row.saldo = extractItem.amount;
      row.dataLancamento = formatDate(extractItem?.creditDate);
    }

    const formatNumber = (number, prefix) =>
      prefix +
      (number === 0
        ? "0,00"
        : number.toLocaleString({
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }));

    const breakComplement =
      typeof row.complemento === "string"
        ? row.complemento.indexOf(" ")
        : undefined;
    return (
      <tr>
        <td className="tx-nowrap" style={{ fontSize: ".8em", maxWidth: 100 }}>
          {row.dataLancamento}
        </td>
        <td
          className="d-none d-sm-table-cell text-black-50"
          style={{ fontSize: ".8em" }}
        >
          {row.descricao}
        </td>
        {breakComplement ? (
          <td
            className="tx-left "
            style={{ fontSize: ".8em", whiteSpace: "normal" }}
          >
            {row.complemento?.slice(0, breakComplement)} <br />
            {row.complemento?.slice(breakComplement + 1)}
          </td>
        ) : (
          <td className="tx-left" style={{ fontSize: ".8em" }}>
            {row.complemento}
          </td>
        )}
        <td className="tx-left" style={{ fontSize: ".8em" }}>
          {row.valor === ""
            ? ""
            : formatNumber(row.valor, row.tipo === "D" ? "-R$" : "+R$")}
        </td>
        <td className="tx-left" style={{ fontSize: ".8em" }}>
          {row.saldo === "" ? "" : formatNumber(row.saldo, "R$")}
        </td>
      </tr>
    );
  };

  const PeriodSection = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      <div className="w-25 mr-3 mt-3">
        <TextField
          fullWidth
          className="m-2"
          id="outlined-select-currency"
          select
          label="Período"
          value={selectedPeriodOption}
          onChange={(e) => handleChangePeriod(e.target.value)}
          helperText="Por favor selecione um período"
          variant="outlined"
        >
          {periods.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {selectedPeriodOption === 3 && months !== undefined && (
        <div className="w-25 mt-3">
          <TextField
            fullWidth
            className="m-2"
            id="outlined-select-currency"
            select
            label="Mês"
            value={selectedMonthOption}
            onChange={(e) => handleMonthChange(e.target.value)}
            helperText="Por favor selecione um mês"
            variant="outlined"
          >
            {months.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label} - {option.begin.substring(4, 0)}
              </MenuItem>
            ))}
          </TextField>
        </div>
      )}
      {selectedPeriodOption === 4 && months !== undefined && (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              marginTop: "-22px",
            }}
          >
            <KeyboardDatePicker
              helperText=""
              cancelLabel="Cancelar"
              disableToolbar
              variant="outlined"
              format="dd/MM/yyyy"
              locale={ptBR}
              maxDate={selectedEndDate}
              minDateMessage="Data de início não pode ser menor que o dia atual"
              maxDateMessage="Data de início não pode ser maior que a data limite"
              margin="normal"
              id="date-picker-inline"
              label="De"
              className="period-date-picker"
              value={selectedBeginDate}
              onChange={handleBeginDateChange}
              onKeyDown={(e) => e.preventDefault()}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              onError={(error) => {
                if (error != "") {
                  toast("Data inicial deve ser menor que a data final");
                }
              }}
            />

            <KeyboardDatePicker
              helperText=""
              cancelLabel="Cancelar"
              disableToolbar
              style={{ marginLeft: "20px" }}
              variant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              minDate={selectedBeginDate}
              minDateMessage="Data de início não pode ser menor que o dia atual"
              id="date-picker-inline"
              label="Até"
              className="period-date-picker"
              value={selectedEndDate}
              onChange={handleEndDateChange}
              onKeyDown={(e) => e.preventDefault()}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              onError={(error) => {
                if (error != "") {
                  toast("Data final deve ser maior que a data inicial");
                }
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      )}
    </div>
  );

  const ShowAdditionalInfoButton = ({
    accountId,
    transactionId,
    transactionType,
  }) => {
    const [additionalInfo, setAdditionalInfo] = useState(undefined);
    const [loadingInfo, setLoadingInfo] = useState(false);

    const getInfo = async (accountId, transactionId, transactionType) => {
      if (loadingInfo) return;
      setLoadingInfo(true);
      const transactionBody = {
        AccountID: accountId,
        TransacionID: transactionId,
      };
      const axiosInstance = AxiosInterceptor();
      try {
        const response = await axiosInstance.post(
          `${process.env.REACT_APP_DECISION_API}/api/v1/le/execute/875b8e3c-4ffc-487b-827d-eea9699e10a0`,
          transactionBody,
          {
            headers: {
              Authorization: process.env.REACT_APP_DECISION_API_TOKEN,
            },
          }
        );

        const type = transactionType === "C" ? "sender" : "recipient";
        const infoRaw =
          response.data.info.external_sources[1].data.data.transactions
            .length === 0 ||
          response.data.info.external_sources[1].data?.data?.transactions ===
            undefined
            ? ""
            : response.data.info.external_sources[1].data.data.transactions[0]
                .instantPayment[type];

        let info;
        try {
          info = `${formatCNPJOutput(infoRaw.taxIdentifier.taxId)} % Banco ${
            infoRaw.psp.id
          } - ag ${infoRaw.account.branch} | ${infoRaw.account.account}`;
        } catch (e) {
          info = "";
        }
        setAdditionalInfo(info);
        setLoadingInfo(false);
      } catch (e) {
        setLoadingInfo(false);
      }
    };

    return (
      <div>
        {additionalInfo === undefined ? (
          <Tooltip title="Mostrar detalhes" placement="top">
            <div style={{ position: "relative" }}>
              <IconButton
                color="primary"
                size="medium"
                variant="outlined"
                style={{
                  color: "#373A44",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: -12,
                  marginLeft: -12,
                  transform: "translateX(-25%) translateY(-25%)",
                }}
                disabled={loadingInfo}
                onClick={() =>
                  getInfo(accountId, transactionId, transactionType)
                }
              >
                <InfoIcon className="font-size-lg" />
              </IconButton>
              {loadingInfo && (
                <CircularProgress
                  size={24}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                    color: "#878a94",
                  }}
                />
              )}
            </div>
          </Tooltip>
        ) : (
          <div
            className="text-left"
            style={{ fontSize: ".8em", whiteSpace: "normal" }}
          >
            {additionalInfo.split("%")[0]} <br />
            {additionalInfo.split("%")[1]}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <PageTitle
        titleHeading="Extrato"
        titleDescription="Visualize aqui todo seu extrato."
      />
      <ReactIf condition={!unavailableAccount}>
        <ReactThen>
          <div
            style={{ marginTop: "50px", marginLeft: "-30px", width: "100%" }}
          >
            <Card className="card-box">
              <div className="card-body p-4">
                <div className="d-flex flex-row flex-lg-row align-items-center justify-content-end">
                  {todayAmount === undefined ? (
                    <div style={{ position: "relative" }}>
                      <CircularProgress
                        size={24}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: -12,
                          marginLeft: -12,
                          color: "green",
                        }}
                      />
                    </div>
                  ) : todayAmount === null ? (
                    <Card className="card-box d-flex flex-column align-items-center justify-content-between">
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <h6
                          style={{
                            verticalAlign: "bottom",
                            marginRight: 16,
                            marginTop: 4,
                            fontSize: "1.5em",
                            fontFamily: "Poppins , sans-serif",
                            fontWeight: "bold",
                          }}
                        >
                          Saldo do dia indisponível
                        </h6>
                      </div>
                    </Card>
                  ) : (
                    <Card className="card-box d-flex flex-column align-items-center justify-content-between">
                      <div className="d-flex flex-row align-items-center justify-content-between">
                        <h2
                          style={{
                            verticalAlign: "bottom",
                            marginRight: 16,
                            marginTop: 4,
                            fontSize: "1.5em",
                            fontFamily: "Poppins , sans-serif",
                            fontWeight: "bold",
                          }}
                        >
                          Saldo
                        </h2>
                        <h1
                          style={{
                            fontFamily: "Poppins , sans-serif",
                            fontWeight: "bold",
                          }}
                        >
                          {todayAmount.amount}
                        </h1>
                      </div>
                      <p
                        className=" text-black-50"
                        style={{
                          marginTop: "-1em",
                          alignSelf: "flex-end",
                          marginRight: "1em",
                        }}
                      >
                        {todayAmount.creditDate}
                      </p>
                    </Card>
                  )}
                </div>
                <Divider className="my-3" />
                <PeriodSection changePeriod={changePeriod} />
                <Divider className="my-3" />
                <div className="text-left text-lg-left d-flex flex-row align-items-center">
                  <div style={{ position: "relative" }}>
                    <Button
                      variant="contained"
                      className="text-white my-1 mx-2"
                      style={{
                        backgroundColor: generating ? "#878a94" : "#373A44",
                        color: "#fff",
                      }}
                      disabled={generating}
                      onClick={() => getExtract(extractPeriod)}
                    >
                      <span className="btn-wrapper--label">Gerar Extrato</span>
                    </Button>
                    {generating && (
                      <CircularProgress
                        size={24}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: -12,
                          marginLeft: -12,
                          color: "green",
                        }}
                      />
                    )}
                  </div>
                  {extractStatement && (
                    <Button
                      variant="contained"
                      className="text-white m-1"
                      style={{
                        backgroundColor: "#373A44",
                        color: "#fff",
                      }}
                      onClick={handlePrint}
                    >
                      <span className="btn-wrapper--icon">
                        <FontAwesomeIcon icon={["fas", "print"]} />
                      </span>
                      <span className="btn-wrapper--label">Imprimir</span>
                    </Button>
                  )}
                </div>
                <div
                  className="table-responsive my-4 p-3"
                  style={{ maxWidth: 920 }}
                  ref={evalComponentRef}
                >
                  {extractStatement && accountInfo !== undefined && (
                    <div className="d-flex flex-column justify-content-between">
                      <div className="d-flex flex-row justify-content-between">
                        <img
                          src={bepayLogo}
                          style={{
                            minWidth: 130,
                            width: "25%",
                            objectFit: "contain",
                          }}
                        />
                        <div className="text-right">
                          <div className="text-uppercase text-primary mb-2 font-size-md">
                            BePay Instituição de Pagamento S.A.
                          </div>
                          <p>
                            <span className="d-block pb-1">
                              CNPJ: 24.313.102/0001-25
                            </span>
                          </p>
                          <p>
                            <span className="d-block pb-1">
                              Alameda Rio Negro nº 585, 1º andar -Alphaville,
                            </span>
                            <span className="d-block">Barueri-SP</span>
                          </p>
                          <p>
                            <span className="d-block pb-1">CEP: 06454-000</span>
                          </p>
                        </div>
                      </div>
                      <div>
                        <div className="text-uppercase text-primary mb-2 font-size-md">
                          {accountInfo.corporateName}
                        </div>
                        <ul className="list-unstyled">
                          <li className="d-flex justify-content-between pb-1">
                            <span className="pr-4">
                              CNPJ: {accountInfo.countryIdentity}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between pb-1">
                            <span className="pr-4">
                              Banco: {accountInfo.bankCode}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between pb-1">
                            <span className="pr-4">
                              Agência: {accountInfo.bankBranch}
                            </span>
                          </li>
                          <li className="d-flex justify-content-between pb-1">
                            <span className="pr-4">
                              Conta recebimento: {accountInfo.accountNumber}
                            </span>
                          </li>
                          {accountInfo.pix && (
                            <li className="d-flex justify-content-between">
                              <span className="pr-4">
                                PIX: {accountInfo.pix}
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                  {extractStatement && (
                    <table
                      className="text-nowrap font-size-xs table table-striped table-hover px-3"
                      style={{ tableLayout: "fixed" }}
                    >
                      <thead>
                        <tr>
                          <th className="wd-40p" style={{ width: 116 }}>
                            Data de <br /> Lançamento
                          </th>
                          <th
                            className="wd-40p d-none d-sm-table-cell"
                            style={{ width: 164 }}
                          >
                            Descrição
                          </th>
                          <th className="tx-left">Complemento</th>
                          <th className="tx-left">Valor</th>
                          <th className="tx-left">Saldo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {extractStatement?.map((extractItem, index) => (
                          <ExtractRow
                            key={`${extractItem.historyCode}-${index}`}
                            extractItem={extractItem}
                            businessAccountId={bankAccountId}
                          />
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </Card>
          </div>
        </ReactThen>
        <ReactCatch>
          <Card
            className="card-box p-4 mb-4"
            style={{ marginTop: 50, marginLeft: "-30px", maxWidth: "100%" }}
          >
            <div className="text-center">
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div
                  className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper text-success m-0 d-130 align-items-center"
                  style={{ backgroundColor: "#fff4e5" }}
                >
                  <ReportProblemOutlinedIcon
                    style={{
                      color: "#ff9800",
                      fontSize: 56,
                    }}
                  />
                </div>
              </div>
              <h4
                className="font-weight-bold mt-4"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "26px",
                  textAlign: "center",
                  color: "#663c00",
                }}
              >
                Não há uma Conta Consignada ligada à esta conta
              </h4>
              <ReactIf condition={!isAdmin()}>
                <ReactThen>
                  <p
                    className="mb-0 font-size-lg"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      color: "#212529",
                    }}
                  >
                    Entre em contato com nossa equipe para abrir sua Conta
                    Consignada!
                  </p>
                </ReactThen>
              </ReactIf>
            </div>
          </Card>
        </ReactCatch>
      </ReactIf>
    </>
  );
};
