import React, { useEffect, useState } from "react";

import { LinearProgress, Dialog, DialogTitle } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

import { PageTitle, ExampleWrapperSeamless } from "layout-components";

import BradescoPreAprovadosTable from "components/Table/BradescoTable";

import formatDate from "utils/formatDate";

import AxiosInterceptor from "utils/axios-interceptor";

export default () => {
  const [preAprovados, setPreAprovados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState({});
  const axiosInstance = AxiosInterceptor();

  async function fetchData() {
    setPreAprovados([]);
    setLoading(true);
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_ANTICORS}/${process.env.REACT_APP_SERPRO_PHP}/v1/preaprovados.php`
    );

    if (data) {
      const dataValidade = data.map((data) =>
        formatDate(data.VALIDADE_CONE.date)
      );

      const dataAtual = formatDate(new Date());

      if (dataAtual > dataValidade) {
        setPreAprovados([]);
        setLoading(false);
      } else {
        setPreAprovados(data);
        setLoading(false);
      }
    }

    if (!data) {
      setMessageSuccess({ message: "Falha ao carregar logs", success: false });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <PageTitle
        titleHeading="Títulos Pré Aprovados"
        titleDescription="Relação de títulos Pré Aprovados do Bradesco"
      />
      <ExampleWrapperSeamless sectionHeading="">
        {!loading && (
          <BradescoPreAprovadosTable
            data={preAprovados}
            fetchFunc={fetchData}
          />
        )}
        {loading && <LinearProgress value={55} color="primary" />}
      </ExampleWrapperSeamless>
      {openSuccess ? (
        <Dialog
          onClose={() => setOpenSuccess(false)}
          open={openSuccess}
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle
            style={{ display: "flex", alignItems: "center" }}
            id="simple-dialog-title"
          >
            {messageSuccess.message}{" "}
            {messageSuccess.success ? (
              <CheckCircleIcon style={{ color: "green" }} />
            ) : (
              <ErrorIcon style={{ color: "red" }} />
            )}
          </DialogTitle>
        </Dialog>
      ) : (
        ""
      )}
    </>
  );
};
