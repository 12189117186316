import React, { useState } from 'react';

import {
  Card,
  CardContent,
  FormControl,
  TextField,
  Tooltip,
  IconButton,
  Menu,
  List,
  ListItem,
  Divider,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ptBR from 'date-fns/locale/pt-BR';

import { CancelButton, ConfirmButton } from 'components/Button';
import { ReactIf, ReactThen, ReactCatchIf } from 'components/ReactIf';
import { Wrapper } from 'components/Wrapper';

import getDaysToGo from 'utils/diffDays';
import formatDate from 'utils/formatDate';
import formatDateToHours from 'utils/formatDateToHours';
import formatReal from 'utils/formatReal';

import { useAuth } from 'contexts/auth';

import { useModal } from 'hooks/useModal';
import { useSolicitarProrrogacao } from 'hooks/useSolicitarProrrogacao';

const UploadButton = ({ handleSetDocs }) => (
  <>
    <Tooltip
      placement="right"
      style={{ position: 'unset', marginTop: 12, marginLeft: 4 }}
      title="Anexar Documento"
      aria-label="attach-file"
      className="card-tr-actions"
    >
      <div className="">
        <input
          accept={
            'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*'
          }
          className="d-none"
          id="icon-button-file"
          type="file"
          multiple
          onChange={(e) => {
            handleSetDocs(e.target.files);
          }}
        />
        <label htmlFor="icon-button-file">
          <IconButton color="primary" aria-label="attach-file" component="span">
            <AttachFileIcon />
          </IconButton>
        </label>
      </div>
    </Tooltip>
  </>
);

const DocumentCard = (props) => {
  const { document, index, isFinanciador, isCedente, handleRemoveDoc } = props;

  const documentState = document;
  const [anchorElMenu4, setAnchorElMenu4] = useState(null);

  const handleOpenMenu = (e) => {
    setAnchorElMenu4(e.currentTarget);
  };

  const handleCloseMenu4 = () => {
    setAnchorElMenu4(null);
  };

  return (
    <>
      <Card className="card-box mb-2">
        <CardContent className="p-1 d-flex flex-row align-items-center justify-content-between">
          <div className="pb-3 d-flex flex-column align-items-start justify-content-center">
            <a
              href={documentState.url}
              target="_blank"
              rel="noreferrer noopener"
            >
              {documentState.name}
            </a>
            <div className="d-flex flex-column align-items-start justify-content-center">
              <div className="font-size-sm pt-2">
                {documentState.name} -{' '}
                {(Number(documentState.size) / 1024 / 1024).toFixed(2)} MB
              </div>
              <div className="font-size-sm pt-2">
                <FontAwesomeIcon icon={['far', 'clock']} className="mr-1" />
                {new Date(documentState.lastModifiedDate).toLocaleString(
                  'pt-BR'
                )}
              </div>
            </div>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center">
            <div>
              <IconButton
                aria-label="menu"
                className="m-2"
                onClick={(e) => handleOpenMenu(e)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorElMenu4}
                keepMounted
                open={Boolean(anchorElMenu4)}
                onClose={handleCloseMenu4}
                classes={{ list: 'p-0' }}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div className="overflow-hidden dropdown-menu-xl">
                  <List className="flex-column">
                    <ListItem
                      button
                      onClick={() => {
                        window.open(documentState.url, 'blank');
                        handleCloseMenu4();
                      }}
                    >
                      <FolderIcon />
                      <span className="ml-2">Abrir</span>
                    </ListItem>
                    <ReactIf condition={isFinanciador()}>
                      <ReactThen>
                        <ListItem
                          button
                          onClick={() => {
                            window.open(documentState.downloadUrl, 'blank');
                            handleCloseMenu4();
                          }}
                        >
                          <SaveAltIcon />
                          <span className="ml-2">Baixar</span>
                        </ListItem>
                      </ReactThen>
                    </ReactIf>
                    <Divider />
                    <ReactIf condition={isCedente()}>
                      <ReactThen>
                        <ListItem
                          button
                          onClick={() => {
                            handleRemoveDoc(index);
                          }}
                        >
                          <DeleteIcon />
                          <span className="ml-2">Excluir</span>
                        </ListItem>
                      </ReactThen>
                    </ReactIf>
                  </List>
                </div>
              </Menu>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
};

const BuyerProposal = ({
  buyerFee,
  setBuyerFee,
  maturity,
  interest,
  setInterest,
  removeFormatReal,
  handleRejectBuyer,
  buyerExtensionDate,
  handleChangeBuyerExtensionDate,
  minSellerExtensionDate,
  handleChangeBuyerReasonText,
  buyerReasonText,

  handleProposalBuyer,
}) => {
  const { openModal } = useModal();
  const { isAdmin } = useAuth();
  return (
    <div
      style={{
        marginTop: 20,
        width: '100%',
      }}
    >
      <ReactIf condition={maturity?.extension?.buyerRequestDate}>
        <ReactThen>
          <h4 style={{ fontFamily: 'Poppins' }} className="text-center mb-3">
            Proposta do Financiador em{' '}
            {formatDate(maturity.extension.buyerRequestDate)}{' '}
            {formatDateToHours(maturity.extension.buyerRequestDate)}
          </h4>
        </ReactThen>
      </ReactIf>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginTop: '-20px', display: 'flex', width: '100%' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <KeyboardDatePicker
              helperText=""
              cancelLabel="Cancelar"
              fullWidth
              disabled
              locale={ptBR}
              className="mb-0 text-field"
              id="extension-date"
              label="Nova Data Proposta"
              format="dd/MM/yyyy"
              // value={
              //   maturity.extension?.statusId === 1
              //     ? buyerExtensionDate
              //     : new Date(
              //         new Date(buyerExtensionDate).getTime() +
              //           1000 * 60 * 60 * 3
              //       )
              // }
              value={
                new Date(
                  new Date(maturity.extension.sellerExtensionDate).getTime() +
                    1000 * 60 * 60 * 3
                )
              }
              onChange={handleChangeBuyerExtensionDate}
              minDate={
                maturity.extension?.statusId !== 1
                  ? undefined
                  : minSellerExtensionDate
              }
              variant="outlined"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              // disabled={maturity.extension?.statusId !== 1 || isAdmin()}
            />
          </MuiPickersUtilsProvider>
        </div>
        <TextField
          fullWidth
          className="mx-3"
          variant="outlined"
          id="buyerFee-text"
          label="Juros"
          value={buyerFee || 'R$ 0,00'}
          onChange={(e) => setBuyerFee(formatReal(e.target.value))}
          name="buyerFee"
          disabled={maturity.extension?.statusId !== 1 || isAdmin()}
        />
        <TextField
          fullWidth
          className="mx-3"
          variant="outlined"
          id="interest-text"
          label="Multa"
          value={interest || 'R$ 0,00'}
          onChange={(e) => setInterest(formatReal(e.target.value))}
          name="interest"
          disabled={maturity.extension?.statusId !== 1 || isAdmin()}
        />
        <TextField
          fullWidth
          className="mx-3"
          variant="outlined"
          id="interest-text"
          label="Total"
          value={
            formatReal(
              `${
                removeFormatReal(buyerFee) * 100 +
                removeFormatReal(interest) * 100
              }`.replace('.', '')
            ) || 'R$ 0,00'
          }
          name="total"
          disabled
        />
        <TextField
          fullWidth
          className="mx-3"
          // style={{ alignSelf: 'flex-end', width: '40%' }}
          variant="outlined"
          id="interest-text"
          label="Prazo"
          value={
            buyerExtensionDate
              ? getDaysToGo(buyerExtensionDate) === 0
                ? 0
                : getDaysToGo(buyerExtensionDate)
              : ''
          }
          name="daysToGoBuyer"
          disabled
        />
      </div>
      <div style={{ marginTop: '20px' }}>
        <TextField
          fullWidth
          variant="outlined"
          id="reason-text"
          label="Proposta Aprovação"
          className="w-100 mb-2"
          onChange={handleChangeBuyerReasonText}
          value={buyerReasonText || ''}
          name="reason"
          disabled={maturity.extension?.statusId !== 1 || isAdmin()}
        />
      </div>
      {!isAdmin() && (
        <ReactIf condition={maturity.extension?.statusId === 1}>
          <ReactThen>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                marginTop: '20px',
              }}
            >
              <CancelButton
                onClick={() => {
                  openModal(
                    'error',
                    'Tem certeza ?',
                    'Confirme para rejeitar proposta',
                    handleRejectBuyer
                  );
                }}
                className="mr-3"
              >
                Rejeitar
              </CancelButton>
              <ConfirmButton
                onClick={() =>
                  openModal(
                    'error',
                    'Tem certeza ?',
                    'Confirme para enviar proposta',
                    handleProposalBuyer
                  )
                }
              >
                Propor
              </ConfirmButton>
            </div>
          </ReactThen>
        </ReactIf>
      )}
    </div>
  );
};

const SellerProposal = ({
  handleChangeSellerReasonText,
  sellerReasonText,
  maturity,
  originalDate,
  sellerExtensionDate,
  handleChangeSellerExtensionDate,
  minSellerExtensionDate,
  isCedente,
  handleSetDocs,
  extensionDocs,
  isFinanciador,
  handleRemoveDoc,
}) => {
  const { isAdmin } = useAuth();
  return (
    <>
      <div
        style={{
          marginTop: 20,
          width: '100%',
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          id="reason-text"
          label="Motivo da Prorrogação"
          className="w-100"
          onChange={handleChangeSellerReasonText}
          value={sellerReasonText || ''}
          name="reason"
          // multiline
          // rowsMax={10}
          // rows={10}
          disabled={maturity.extension || isAdmin()}
        />
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          marginTop: '15px',
        }}
      >
        <FormControl className="mr-2">
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <KeyboardDatePicker
              helperText=""
              cancelLabel="Cancelar"
              fullWidth
              disabled
              locale={ptBR}
              className="mr-2 mb-0 text-field"
              id="extension-date"
              label="Data Original de Vencimento"
              format="dd/MM/yyyy"
              value={originalDate}
              variant="outlined"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
        <FormControl className="mr-2">
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <KeyboardDatePicker
              helperText=""
              cancelLabel="Cancelar"
              fullWidth
              locale={ptBR}
              className="ml-2 mr-2 mb-0 text-field"
              id="extension-date"
              label="Nova Data Proposta"
              format="dd/MM/yyyy"
              value={
                !maturity.extension
                  ? sellerExtensionDate
                  : new Date(
                      new Date(sellerExtensionDate).getTime() +
                        1000 * 60 * 60 * 3
                    )
              }
              onChange={handleChangeSellerExtensionDate}
              minDate={maturity.extension ? undefined : minSellerExtensionDate}
              variant="outlined"
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              disabled={maturity.extension || isAdmin()}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
        <TextField
          disabled
          variant="outlined"
          id="days-to-go"
          label="Prazo"
          className="w-100 days-to-go-extension ml-3"
          style={{ marginTop: 16, paddingBottom: 8 }}
          value={getDaysToGo(sellerExtensionDate) || ''}
          name="days"
        />
      </div>

      <div
        style={{
          marginTop: 20,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <div
          className={`w-100 d-flex flex-row justify-content-${
            isCedente() ? 'between' : 'start'
          }`}
        >
          <h4 style={{ fontFamily: 'Poppins' }}>Evidência</h4>
          <ReactIf condition={isCedente() && !maturity?.extension}>
            <ReactThen>
              <UploadButton handleSetDocs={handleSetDocs} />
            </ReactThen>
          </ReactIf>
        </div>
        {extensionDocs.map((doc, index) => (
          <DocumentCard
            key={index}
            document={doc}
            index={index}
            isFinanciador={isFinanciador}
            isCedente={isCedente}
            handleRemoveDoc={handleRemoveDoc}
          />
        ))}
      </div>
    </>
  );
};

export function SolicitarProrrogacao(props) {
  const { maturity, invoice, bid, onClose } = props;

  const {
    sellerReasonText,
    originalDate,
    sellerExtensionDate,
    minSellerExtensionDate,
    daysToGo,
    handleChangeSellerReasonText,
    handleChangeSellerExtensionDate,

    handleCreateMaturityExtension,
    handleRejectBuyer,
    handleProposalBuyer,
    handleRejectSeller,
    handleApproveSeller,
    handleCancelBuyer,

    extensionDocs,
    handleSetDocs,
    handleRemoveDoc,
    isCedente,
    isFinanciador,
    buyerReasonText,
    buyerExtensionDate,
    handleChangeBuyerReasonText,
    handleChangeBuyerExtensionDate,
    buyerFee,
    setBuyerFee,
    interest,
    setInterest,
    removeFormatReal,
  } = useSolicitarProrrogacao(
    maturity,
    maturity.invoiceMaturityId,
    invoice,
    maturity.originalDate,
    bid,
    maturity.extension,
    onClose
  );

  const { openModal } = useModal();
  const [approved, setApproved] = useState(false);
  const { isAdmin } = useAuth();

  return (
    <>
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignContent: 'flex-start',
          alignItems: 'flex-start',
          width: '100%',
          maxHeight: 760,
          padding: 30,
        }}
      >
        <h3 style={{ fontFamily: 'Poppins' }}>
          Solicitação de Prorrogação
          {maturity?.extension?.sellerRequestDate &&
            ` - Feita em ${formatDate(
              maturity?.extension?.sellerRequestDate
            )} ${formatDateToHours(maturity?.extension?.sellerRequestDate)}`}
        </h3>
        <SellerProposal
          handleChangeSellerReasonText={handleChangeSellerReasonText}
          sellerReasonText={sellerReasonText}
          maturity={maturity}
          originalDate={originalDate}
          sellerExtensionDate={sellerExtensionDate}
          handleChangeSellerExtensionDate={handleChangeSellerExtensionDate}
          minSellerExtensionDate={minSellerExtensionDate}
          daysToGo={daysToGo}
          isCedente={isCedente}
          handleSetDocs={handleSetDocs}
          extensionDocs={extensionDocs}
          isFinanciador={isFinanciador}
          handleRemoveDoc={handleRemoveDoc}
        />
        {/* <BuyerProposal
          buyerFee={buyerFee}
          setBuyerFee={setBuyerFee}
          maturity={maturity}
          interest={interest}
          setInterest={setInterest}
          removeFormatReal={removeFormatReal}
          buyerExtensionDate={buyerExtensionDate}
          handleChangeBuyerExtensionDate={handleChangeBuyerExtensionDate}
          minSellerExtensionDate={minSellerExtensionDate}
          handleChangeBuyerReasonText={handleChangeBuyerReasonText}
          buyerReasonText={buyerReasonText}
          onClose={onClose}
          handleProposalBuyer={handleProposalBuyer}
        /> */}
        <>
          {isAdmin() ? (
            <ReactIf condition={!maturity.extension}>
              <ReactCatchIf
                condition={!approved && maturity?.extension?.statusId === 1}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <ConfirmButton
                    onClick={() => {
                      setApproved(true);
                    }}
                  >
                    Ver proposta do Financiador
                  </ConfirmButton>
                </div>
              </ReactCatchIf>
            </ReactIf>
          ) : (
            <ReactIf condition={!maturity.extension}>
              <ReactThen>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <CancelButton onClick={onClose} className="mr-3">
                    Cancelar
                  </CancelButton>
                  <ConfirmButton
                    onClick={() =>
                      openModal(
                        'error',
                        'Tem certeza ?',
                        'Confirme para prosseguir para solicitar prorrogação',
                        handleCreateMaturityExtension
                      )
                    }
                  >
                    Solicitar
                  </ConfirmButton>
                </div>
              </ReactThen>
              <ReactCatchIf
                condition={!approved && maturity?.extension?.statusId === 1}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                  }}
                >
                  <CancelButton
                    onClick={() => {
                      openModal(
                        'error',
                        'Tem certeza ?',
                        'Confirme para rejeitar proposta',
                        handleRejectBuyer
                      );
                    }}
                    className="mr-3"
                  >
                    Rejeitar
                  </CancelButton>
                  <ConfirmButton
                    onClick={() => {
                      setApproved(true);
                    }}
                  >
                    Prosseguir
                  </ConfirmButton>
                </div>
              </ReactCatchIf>
            </ReactIf>
          )}
        </>
        <ReactIf
          condition={
            approved || [3, 4, 5].includes(maturity?.extension?.statusId)
          }
        >
          <ReactThen>
            <BuyerProposal
              buyerFee={buyerFee}
              setBuyerFee={setBuyerFee}
              maturity={maturity}
              interest={interest}
              setInterest={setInterest}
              removeFormatReal={removeFormatReal}
              buyerExtensionDate={buyerExtensionDate}
              handleChangeBuyerExtensionDate={handleChangeBuyerExtensionDate}
              minSellerExtensionDate={minSellerExtensionDate}
              handleChangeBuyerReasonText={handleChangeBuyerReasonText}
              buyerReasonText={buyerReasonText}
              onClose={onClose}
              handleProposalBuyer={handleProposalBuyer}
            />
          </ReactThen>
        </ReactIf>
        <ReactIf condition={isCedente() && maturity?.extension?.statusId === 3}>
          <ReactThen>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <CancelButton
                onClick={() => {
                  openModal(
                    'error',
                    'Tem certeza ?',
                    'Confirme para rejeitar proposta',
                    handleRejectSeller
                  );
                }}
                className="mr-3"
              >
                Rejeitar
              </CancelButton>
              <ConfirmButton
                onClick={() => {
                  openModal(
                    'error',
                    'Tem certeza ?',
                    'Confirme para aceitar proposta',
                    handleApproveSeller
                  );
                }}
              >
                Aceitar
              </ConfirmButton>
            </div>
          </ReactThen>
        </ReactIf>
        <ReactIf condition={[4].includes(maturity?.extension?.statusId)}>
          <ReactThen>
            <Wrapper sectionHeading="" style={{ alignSelf: 'end' }}>
              <h3
                style={{ fontFamily: 'Poppins' }}
                className="text-center w-100"
              >
                Prorrogação aceita em{' '}
                {formatDate(maturity?.extension?.sellerApproveDate)}{' '}
                {formatDateToHours(maturity?.extension?.sellerApproveDate)}
              </h3>
            </Wrapper>
            <ReactIf condition={isFinanciador()}>
              <ReactThen>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    marginTop: 16,
                  }}
                >
                  <CancelButton onClick={onClose} className="mr-3">
                    Fechar
                  </CancelButton>

                  <CancelButton
                    onClick={() => {
                      openModal(
                        'error',
                        'Tem certeza ?',
                        'Confirme para cancelar prorrogação',
                        handleCancelBuyer
                      );
                    }}
                    className="mr-3"
                  >
                    Cancelar Prorrogação
                  </CancelButton>
                </div>
              </ReactThen>
            </ReactIf>
          </ReactThen>
        </ReactIf>
      </Card>
    </>
  );
}
