import React from 'react';

import { Paper } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export function WarningCard({ color, children, ...props }) {
  return (
    <>
      <Paper
        elevation={6}
        style={{
          display: 'flex',
          padding: '2em',
          flexDirection: 'column',
          alignSelf: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          gap: '4px',
          borderRadius: '8px',
        }}
        {...props}
      >
        <InfoOutlinedIcon
          style={{
            color: `${'#ff9800' || color}`,
            fontSize: '6em',
            flex: 1,
            padding: 0,
            margin: 0,
          }}
        />
        <h3
          style={{
            fontFamily: 'Poppins, sans-serif',
            flex: 5,
            fontSize: '1.5em',
          }}
        >
          {children || 'Não foi possível encontrar a informação desejada'}
        </h3>
      </Paper>
    </>
  );
}

export function ErrorCard({ color, children, ...props }) {
  return (
    <>
      <Paper
        elevation="6"
        style={{
          display: 'flex',
          padding: '2em',
          flexDirection: 'column',
          alignSelf: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          gap: '4px',
          borderRadius: '8px',
        }}
        {...props}
      >
        <InfoOutlinedIcon
          style={{
            color: `${'#f44336' || color}`,
            fontSize: '6em',
            flex: 1,
            padding: 0,
            margin: 0,
          }}
        />
        <h3
          style={{
            fontFamily: 'Poppins, sans-serif',
            flex: 5,
            fontSize: '1.5em',
          }}
        >
          {children || 'Não foi possível encontrar a informação desejada'}
        </h3>
      </Paper>
    </>
  );
}

export function InfoCard({ color, children, ...props }) {
  return (
    <>
      <Paper
        elevation="6"
        style={{
          display: 'flex',
          padding: '2em',
          flexDirection: 'column',
          alignSelf: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          gap: '4px',
          borderRadius: '8px',
        }}
        {...props}
      >
        <InfoOutlinedIcon
          style={{
            color: `${'#2196F3' || color}`,
            fontSize: '6em',
            flex: 1,
            padding: 0,
            margin: 0,
          }}
        />
        <h3
          style={{
            fontFamily: 'Poppins, sans-serif',
            flex: 5,
            fontSize: '1.5em',
          }}
        >
          {children || 'Não foi possível encontrar a informação desejada'}
        </h3>
      </Paper>
    </>
  );
}
