/* eslint eqeqeq: 0 */
import React, { useState, useEffect } from "react";

import LinearProgress from "@material-ui/core/LinearProgress";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import MuiAlert from "@material-ui/lab/Alert";

import formatDate from "utils/formatDate";
import formatReal from "utils/formatReal";
import formatTelefone from "utils/formatTelefone";

import "moment/locale/pt-br";
import "date-fns";

import Produtos from "../../Icons/Produtos";
import Servicos from "../../Icons/Servicos";
import VencList from "../../VencList";
import "./styles.scss";

export default (props) => {
  const { blank, agenda, type } = props;

  const isOnModalLastro = type === "modal-lastro";

  const [invoice, setInvoice] = useState(undefined);
  const [invoiceKey, setInvoiceKey] = useState(undefined);
  const [invoiceSeries, setInvoiceSeries] = useState(undefined);
  const [numberInvoice, setNumberInvoice] = useState(undefined);
  const [emissionInvoice, setEmissionInvoice] = useState(undefined);
  const [valueInvoice, setValueInvoice] = useState(undefined);
  const [valueUsed, setValueUsed] = useState(undefined);
  const [docDrawn, setDocDrawn] = useState(undefined);
  const [nameDrawn, setNameDrawn] = useState(undefined);
  const [cellphoneDrawn, setCellphoneDrawn] = useState(undefined);
  const [emailDrawn, setEmailDrawn] = useState(undefined);
  const [cepDrawn, setCepDrawn] = useState(undefined);
  const [adressDrawn, setAdressDrawn] = useState(undefined);
  const [numberDrawn, setNumberDrawn] = useState(undefined);
  const [complementDrawn, setComplementDrawn] = useState(undefined);
  const [districtDrawn, setDistrictDrawn] = useState(undefined);
  const [cityDrawn, setCityDrawn] = useState(undefined);
  const [ufDrawn, setUfDrawn] = useState(undefined);
  const [nameContact, setNameContact] = useState(undefined);
  const [emailContact, setEmailContact] = useState(undefined);
  const [cellPhoneContact, setCellPhoneContact] = useState(undefined);
  const [roleContact, setRoleContact] = useState(undefined);
  const [typeDrawn, setTypeDrawn] = useState(undefined);
  const [expirationValue, setExpirationValue] = useState(undefined);

  const typeDrawns = {
    fisica: "Pessoa Física",
    juridica: "Pessoa Jurídica",
  };

  const [vencList, setVencList] = useState([
    {
      index: Math.random(),
      descricao: "",
      dtvencimento: "",
      valor: "",
      dias: "",
    },
  ]);

  const [isLoading] = useState(false);
  const [formAdd, setValues] = useState({
    businessId: localStorage.getItem("businessId"),
    username: localStorage.getItem("name"),
  });

  const [pessoa, SetPessoa] = useState({
    tipo: "",
    nome: "",
    doc: "",
  });

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const getIcon = (tiponota) => {
    switch (tiponota?.toLowerCase()) {
      case "produtos":
        return <Produtos size={25} />;
      case "serviços":
        return <Servicos size={25} />;
      default:
        break;
    }
  };

  useEffect(() => {
    if (blank) {
      setValues({});
    }
    if (typeDrawn == typeDrawns?.fisica) {
      SetPessoa({ tipo: typeDrawn, nome: "Nome", doc: "CPF" });
      formAdd.tiposacado = typeDrawn;
    } else {
      SetPessoa({ tipo: typeDrawn, nome: "Razão Social", doc: "CNPJ" });
      formAdd.tiposacado = typeDrawn;
    }
    setValues({ ...formAdd, ...agenda });
    if (agenda.agenda_id) {
      formAdd.nfjson = agenda?.xmlScheduling.xml;
      formAdd.tiponota = agenda?.type_name;
      formAdd.numeronota = agenda?.number;
      formAdd.emissaonota = agenda?.issue_date;
      formAdd.valornota = formatReal(agenda?.invoice_value?.toFixed(2));
      formAdd.valorUtilizado = formatReal(agenda?.commited_value?.toFixed(2));
      formAdd.valorVencimento = formatReal(
        agenda?.maturities_value?.toFixed(2)
      );
      formAdd.tiposacado = agenda?.drawee?.person_type_name;
      formAdd.docsacado = agenda?.drawee?.country_identity;
      formAdd.nomesacado =
        agenda?.drawee?.name || agenda?.drawee?.corporate_name;
      formAdd.telefonesacado = agenda?.drawee?.office_phone;
      formAdd.emailsacado = agenda?.drawee?.email;
      formAdd.cepsacado = agenda?.drawee?.address_zip_code;
      formAdd.enderecosacado = agenda?.drawee?.address_street;
      formAdd.numerosacado = agenda?.drawee?.address_number;
      formAdd.complementosacado = agenda?.drawee?.address_complement;
      formAdd.bairrosacado = agenda?.drawee?.address_district;
      formAdd.cidadesacado = agenda?.drawee?.address_city;
      formAdd.ufsacado = agenda?.drawee?.address_state;
      formAdd.nomecontato = agenda?.drawee?.contact_name;
      formAdd.emailcontato = agenda?.drawee?.contact_email;
      formAdd.telcontato = agenda?.drawee?.mobile_phone;
      formAdd.cargocontato = agenda?.drawee?.contact_role;
      formAdd.invoiceKey = agenda?.invoice_key;
      formAdd.invoiceSeries = agenda?.invoice_series;

      if (!agenda.maturity) return;
      if (agenda.maturity.length > 0) {
        const parsedVencimentos = agenda.maturity.map((maturity) => ({
          ...maturity,
          value: formatReal(maturity?.value?.toFixed(2)),
          valor: formatReal(maturity?.value?.toFixed(2)),
          dt_vencimento: maturity?.maturity_date,
        }));
        setVencList(parsedVencimentos);
      }

      setInvoice(formAdd.tiponota);
      setInvoiceKey(formAdd.invoiceKey);
      setInvoiceSeries(formAdd.invoiceSeries);
      setTypeDrawn(formAdd.tiposacado);
      setNumberInvoice(formAdd.numeronota);
      setEmissionInvoice(formAdd.emissaonota);
      setValueInvoice(formAdd.valornota);
      setValueUsed(formAdd.valorUtilizado);
      setExpirationValue(formAdd.valorVencimento);
      setDocDrawn(formAdd.docsacado);
      setNameDrawn(formAdd.nomesacado);
      setCellphoneDrawn(formAdd.telefonesacado);
      setEmailDrawn(formAdd.emailsacado);
      setCepDrawn(formAdd.cepsacado);
      setAdressDrawn(formAdd.enderecosacado);
      setNumberDrawn(formAdd.numerosacado);
      setComplementDrawn(formAdd.complementosacado);
      setDistrictDrawn(formAdd.bairrosacado);
      setCityDrawn(formAdd.cidadesacado);
      setUfDrawn(formAdd.ufsacado);
      setNameContact(formAdd.nomecontato);
      setEmailContact(formAdd.emailcontato);
      setCellPhoneContact(formAdd.telcontato);
      setRoleContact(formAdd.cargocontato);
    }
  }, [agenda]);

  return (
    <>
      {isLoading && <LinearProgress value={55} color="primary" />}

      <div className="containerDetails">
        <div
          className="searchForm"
          style={{
            marginLeft: !isOnModalLastro && 35,
            width: isOnModalLastro && "100%",
            padding: 0,
          }}
        >
          {snackbarState.open && (
            <>
              <Snackbar
                open={snackbarState.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  onClose={handleCloseSnackbar}
                  severity={snackbarState.severity}
                >
                  {snackbarState.message}
                </Alert>
              </Snackbar>
            </>
          )}

          <div>
            <div className="headerModalDetail">Nota fiscal</div>
            <div className="itemLastroDetails">
              {/* <div className="itensLastroDetails">
                <Button
                  className="lastroDetailsButton"
                  startIcon={<Download size={25} />}
                  variant="contained"
                >
                  Baixar PDF
                </Button>
                <Button
                  className="lastroDetailsButton"
                  startIcon={<Download size={25} />}
                  variant="contained"
                >
                  Baixar XML
                </Button>
              </div> */}
              <div className="itensLastroDetails">
                <div style={{ maxWidth: 150, width: "100%" }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    id="numeronota"
                    label="Número da nota"
                    name="numeronota"
                    defaultValue={0}
                    value={numberInvoice || ""}
                    variant="outlined"
                    disabled
                  />
                </div>

                <div style={{ maxWidth: 200, width: "100%" }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    id="tiponota"
                    label="Tipo da nota"
                    name="tiponota"
                    defaultValue="Serviços"
                    value={invoice || ""}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <div style={{ marginRight: 10 }}>
                          {getIcon(invoice)}
                        </div>
                      ),
                    }}
                    disabled
                  />
                </div>

                <div style={{ maxWidth: 120, width: "100%" }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    id="emissaonota"
                    label="Data de emissão"
                    name="emissaonota"
                    value={formatDate(emissionInvoice) || ""}
                    variant="outlined"
                    disabled
                  />
                </div>

                <div style={{ maxWidth: 150, width: "100%" }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    disabled
                    id="valornota"
                    label="Valor total"
                    name="valornota"
                    value={formatReal(valueInvoice) || ""}
                    variant="outlined"
                  />
                </div>

                <div style={{ maxWidth: 150, width: "100%" }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    disabled
                    id="valorNotaUtilizado"
                    label="Valor utilizado"
                    name="valornota"
                    value={formatReal(valueUsed) || ""}
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="itensLastroDetails">
                {invoiceKey && (
                  <div style={{ maxWidth: 400, width: "100%" }}>
                    <TextField
                      className="lastroDetailsTextField"
                      classes={{ root: "lastroDetailsTextFieldRoot" }}
                      autoFocus
                      fullWidth
                      id="invoiceKey"
                      label="Chave da Nota Fiscal"
                      name="invoiceKey"
                      defaultValue={0}
                      value={invoiceKey}
                      variant="outlined"
                      disabled
                    />
                  </div>
                )}

                {invoiceSeries && (
                  <div style={{ maxWidth: 200, width: "100%" }}>
                    <TextField
                      className="lastroDetailsTextField"
                      classes={{ root: "lastroDetailsTextFieldRoot" }}
                      autoFocus
                      fullWidth
                      id="invoiceSeries"
                      label="Série da Nota Fiscal"
                      name="invoiceSeries"
                      defaultValue="Serviços"
                      value={invoiceSeries || ""}
                      variant="outlined"
                      disabled
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div>
            <div className="headerModalDetailNoBorder">Sacado</div>
            <div className="itemLastroDetails">
              <div style={{ display: "flex", gap: 25 }}>
                <div style={{ width: 170 }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    disabled
                    id="docsacado"
                    label="Tipo de sacado"
                    name="docsacado"
                    value={typeDrawn || ""}
                    variant="outlined"
                  />
                </div>

                <div style={{ width: 170 }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    disabled
                    id="docsacado"
                    label={pessoa.doc}
                    name="docsacado"
                    value={docDrawn || ""}
                    variant="outlined"
                  />
                </div>
                <div style={{ width: 250 }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    disabled
                    id="nomesacado"
                    label={pessoa?.nome}
                    name="nomesacado"
                    value={nameDrawn || ""}
                    variant="outlined"
                  />
                </div>
              </div>

              <div style={{ display: "flex", gap: 25 }}>
                <div style={{ width: 250 }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    disabled
                    id="email"
                    type="email"
                    label="Email"
                    name="emailsacado"
                    value={emailDrawn || ""}
                    variant="outlined"
                  />
                </div>

                <div style={{ width: 150 }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    disabled
                    id="telefone"
                    label="Telefone"
                    name="telefonesacado"
                    value={formatTelefone(cellphoneDrawn) || ""}
                    variant="outlined"
                  />
                </div>
              </div>

              <div style={{ display: "flex", gap: 25 }}>
                <div style={{ maxWidth: 150, width: "100%" }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    disabled
                    id="cep"
                    label="CEP"
                    name="cepsacado"
                    value={cepDrawn || ""}
                    variant="outlined"
                  />
                </div>

                <div style={{ maxWidth: 300, width: "100%" }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    disabled
                    id="endereco"
                    label="Endereço"
                    name="enderecosacado"
                    value={adressDrawn || ""}
                    variant="outlined"
                  />
                </div>

                <div style={{ maxWidth: 125, width: "100%" }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    disabled
                    id="numero"
                    label="Número"
                    name="numerosacado"
                    value={numberDrawn || ""}
                    variant="outlined"
                  />
                </div>

                <div style={{ maxWidth: 300, width: "100%" }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    disabled
                    id="complemento"
                    label="Complemento"
                    name="complementosacado"
                    value={complementDrawn || ""}
                    variant="outlined"
                  />
                </div>
              </div>

              <div style={{ display: "flex", gap: 25 }}>
                <div style={{ maxWidth: 300, width: "100%" }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    disabled
                    id="bairro"
                    label="Bairro"
                    name="bairrosacado"
                    value={districtDrawn || ""}
                    variant="outlined"
                  />
                </div>

                <div style={{ maxWidth: 300, width: "100%" }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    disabled
                    id="cidade"
                    label="Cidade"
                    name="cidadesacado"
                    value={cityDrawn || ""}
                    variant="outlined"
                  />
                </div>

                <div style={{ maxWidth: 70, width: "100%" }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    autoFocus
                    fullWidth
                    disabled
                    id="uflabel"
                    label="UF"
                    name="ufsacado"
                    value={ufDrawn || ""}
                    variant="outlined"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="headerModalDetailNoBorder">Contato</div>
            <div className="itemLastroDetails">
              <div style={{ display: "flex", gap: 25 }}>
                <div style={{ maxWidth: 300, width: "100%" }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    fullWidth
                    disabled
                    id="nomecontato"
                    label="Nome do Contato"
                    name="nomecontato"
                    value={nameContact || ""}
                    variant="outlined"
                  />
                </div>

                <div style={{ width: 250 }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    fullWidth
                    disabled
                    id="emailcontato"
                    label="Email do Contato"
                    name="emailcontato"
                    value={emailContact || ""}
                    variant="outlined"
                  />
                </div>

                <div style={{ width: 150 }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    fullWidth
                    disabled
                    id="telcontato"
                    label="Telefone"
                    name="telcontato"
                    value={formatTelefone(cellPhoneContact) || ""}
                    variant="outlined"
                  />
                </div>

                <div style={{ width: 200 }}>
                  <TextField
                    className="lastroDetailsTextField"
                    classes={{ root: "lastroDetailsTextFieldRoot" }}
                    fullWidth
                    disabled
                    id="cargocontato"
                    label="Cargo"
                    name="cargocontato"
                    value={roleContact || ""}
                    variant="outlined"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="headerModalDetailNoBorder">
              Vencimentos ({vencList.length})
            </div>
            <div style={{ display: "flex", gap: 50 }}>
              <div className="itemLastroDetails">
                <VencList setVencList={setVencList} venclist={vencList} />
              </div>
              <div style={{ paddingTop: 25 }}>
                <div className="totalMaturitiesContainerDotted">
                  <span>Valor total dos vencimentos</span>
                  <h1>{expirationValue}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
