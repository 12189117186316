import useSWR from 'swr';

import { fetchWithTokenExternalService } from '../fetchWithToken';

export default function useExtensions(maturityId = null) {
  const {
    data: extensions,
    error,
    isValidating,
    mutate,
  } = useSWR(
    maturityId
      ? `${process.env.REACT_APP_EXTENSION_API}/extensions/${maturityId}`
      : false,
    fetchWithTokenExternalService,
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      shouldRetryOnError: true,
      errorRetryInterval: 1000,
    }
  );

  return {
    extensions: extensions && extensions.data ? extensions.data : null,
    error,
    isValidating,
    isLoading: !error && !extensions,
    mutate,
  };
}
