import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { Box, LinearProgress, Paper } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Pagination } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';

import clsx from 'clsx';

import FilterAccounts from '../../components/AccountManagement/FilterAccounts';
import TableAccountConsigned from '../../components/AccountManagement/TableAccounts';
import { AccountManagementContext } from '../../contexts/accountManager';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function AccountsManagement() {
  const {
    tabValue,
    page,
    setPage,
    setFetchBanking,
    setTabValue,
    accounts,
    isLoading,
    error,
  } = useContext(AccountManagementContext);
  const query = useQuery();
  const history = useHistory();

  useEffect(() => {
    const page = query?.get('page');

    if (page) {
      setPage(+page);
    }

    setFetchBanking(true);
  }, [query, page]);

  return (
    <div className="pageInvoice">
      <Paper
        elevation={3}
        className={clsx('app-page-title')}
        style={{
          maxWidth: '100%',
          display: 'flow-root',
        }}
      >
        <div>
          <div style={{ marginTop: '-25px', marginLeft: '22px' }}>
            <div className="app-page-title--heading">
              <h1 style={{ fontFamily: 'Poppins' }}>Contas Bancárias</h1>
            </div>
          </div>
        </div>
      </Paper>

      <Box style={{ marginLeft: '-2rem' }}>
        <Paper square>
          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, value) => {
              setTabValue(value);
            }}
          >
            <Tab label="Contas consignadas" value="account-consigned" />
            <Tab label="Livre movimentação" value="free-movement" />
          </Tabs>
        </Paper>

        <Box mt={2}>
          {tabValue === 'account-consigned' ? (
            <>
              <Box mb={2}>
                <FilterAccounts />
              </Box>
              {isLoading ? (
                <LinearProgress />
              ) : (
                <>
                  {error ? (
                    <Alert severity="error">
                      Tivemos um problema na conexão com o servidor. Estamos
                      tentando reconectar...
                    </Alert>
                  ) : (
                    <>
                      <TableAccountConsigned rows={accounts ?? []} />
                      <Box display="flex" justifyContent="center" mt={2}>
                        <Pagination
                          color="primary"
                          // page={page}
                          page={!page ? 1 : +page}
                          count={1}
                          size="small"
                          onChange={(e, page) => {
                            history.push(`?page=${page}`);
                          }}
                        />
                      </Box>
                    </>
                  )}
                </>
              )}
            </>
          ) : null}

          {tabValue === 'free-movement' ? (
            <>
              <Box mb={2}>
                <FilterAccounts />
              </Box>
              <TableAccountConsigned rows={[]} />
              <Box display="flex" justifyContent="center" mt={2}>
                {/* <Pagination */}
                {/*  color="primary" */}
                {/*  // page={page} */}
                {/*  page={!page ? 1 : +page} */}
                {/*  count={1} */}
                {/*  size="small" */}
                {/*  onChange={(e, page) => { */}
                {/*    router.push(`?page=${page}`); */}
                {/*  }} */}
                {/* /> */}
              </Box>
            </>
          ) : null}
        </Box>
      </Box>
    </div>
  );
}
