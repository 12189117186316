import React, { useState } from "react";

import "../capa.scss";

import { Card, TextField } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";

import { ConfirmButton } from "components/Button";
import { ReactIf, ReactThen, ReactCatch } from "components/ReactIf";

import formatCpf from "utils/formatCPF";
import formatRg from "utils/formatRG";
import formatTelefone from "utils/formatTelefone";

import axios from "axios";

import { useAuth } from "contexts/auth";

class Related {
  constructor(
    businessId,
    name,
    countryIdentity,
    birthDate,
    typeId,
    businessParticipation,
    stateIdentity,
    stateIdentityIssuingEntity,
    stateIdentityFederativeUnity,
    addressZipCode,
    addressStreet,
    addressNumber,
    addressComplement,
    addressDistrict,
    addressCity,
    addressState,
    signsForCompany,
    ecpf,
    email,
    officePhone,
    mobilePhone,
    maritalStatusId
  ) {
    this.businessId = businessId;
    this.name = name;
    this.countryIdentity = countryIdentity;
    this.birthDate = birthDate;
    this.typeId = typeId;
    this.businessParticipation = businessParticipation;
    this.stateIdentity = stateIdentity;
    this.stateIdentityIssuingEntity = stateIdentityIssuingEntity;
    this.stateIdentityFederativeUnity = stateIdentityFederativeUnity;
    this.addressZipCode = addressZipCode;
    this.addressStreet = addressStreet;
    this.addressNumber = addressNumber;
    this.addressComplement = addressComplement;
    this.addressDistrict = addressDistrict;
    this.addressCity = addressCity;
    this.addressState = addressState;
    this.signsForCompany = signsForCompany;
    this.ecpf = ecpf;
    this.email = email;
    this.officePhone = officePhone;
    this.mobilePhone = mobilePhone;
    this.maritalStatusId = maritalStatusId;
  }
}

const Relacionados = (props) => {
  const { business, businessRelateds, setBusinessRelateds, setSnackbarState } =
    props;

  const { isAdmin, canSelfUpdate } = useAuth();

  const [removedRelateds, setRemovedRelateds] = useState([]);
  const [allFilled, setAllFilled] = useState(true);
  const [savingRelateds, setSavingRelateds] = useState(false);

  const stringAttributeIsInvalid = (attr) =>
    attr === undefined || attr === "" || attr === null || attr === 0;

  // const numberAttributeIsInvalid = (attr, number) =>
  //   attr === undefined || Number(attr) === number || attr === null;

  const updateBusinessRelateds = () => {
    if (businessRelateds === undefined || savingRelateds) return;

    setSavingRelateds(true);

    const allFilled = !businessRelateds.some(
      (related) =>
        stringAttributeIsInvalid(related.name) ||
        stringAttributeIsInvalid(related.countryIdentity) ||
        stringAttributeIsInvalid(related.birthDate) ||
        related.birthDate === null ||
        stringAttributeIsInvalid(related.stateIdentity) ||
        stringAttributeIsInvalid(related.stateIdentityIssuingEntity) ||
        stringAttributeIsInvalid(related.stateIdentityFederativeUnity) ||
        stringAttributeIsInvalid(related.email) ||
        // stringAttributeIsInvalid(related.officePhone) ||
        stringAttributeIsInvalid(related.mobilePhone)
    );

    if (allFilled) {
      axiosInstance
        .patch(
          `${process.env.REACT_APP_NODE_API}/responsibles/update`,
          businessRelateds
        )
        .then(() => {
          if (
            removedRelateds.filter(
              (removed) => removed.responsibleId !== undefined
            ).length > 0
          ) {
            axios
              .post(
                `${process.env.REACT_APP_NODE_API}/responsibles/delete`,
                removedRelateds
              )
              .then(() => {
                setRemovedRelateds([]);
                setSnackbarState({
                  severity: "success",
                  message: "Dados atualizados com sucesso!",
                  open: true,
                });
                window.scrollTo(0, 0);
                setSavingRelateds(false);
              });
          } else {
            setRemovedRelateds([]);
            setSnackbarState({
              severity: "success",
              message: "Dados atualizados com sucesso!",
              open: true,
            });
            window.scrollTo(0, 0);
            setSavingRelateds(false);
          }
        });
    } else {
      setAllFilled(false);
      setSnackbarState({
        severity: "error",
        message: "Por favor, preencha todos os dados!",
        open: true,
      });
      window.scrollTo(0, 0);
      setSavingRelateds(false);
    }
  };

  const changeBusinessRelateds = (newState, index) => {
    const relateds = businessRelateds;
    relateds[index] = newState;

    setBusinessRelateds(relateds);
  };

  const handlerChange = (e, state, setter, index) => {
    const newState = { ...state, [e.target.name]: e.target.value };

    changeBusinessRelateds(newState, index);

    setter(newState);
  };

  const addRelated = () => {
    const relateds = businessRelateds;
    const newRelated = new Related();
    newRelated.businessId = business.businessId;
    relateds.push(newRelated);

    setBusinessRelateds([...relateds]);
  };

  const removeRelated = (index) => {
    setRemovedRelateds([...removedRelateds, businessRelateds[index]]);

    const relateds = businessRelateds;
    relateds.splice(index, 1);

    setBusinessRelateds(relateds);
  };

  const StylizedInput = ({
    className,
    label,
    inputName,
    disabled,
    required,
    related,
    setRelated,
    index,
    andError,
    format,
    ...props
  }) => (
    <div className={`item pr-2 w-100 mt-2 ${className}`}>
      <TextField
        {...props}
        fullWidth
        className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
        id={label}
        label={`${label}${required ? "*" : ""}`}
        name={inputName}
        value={format ? format(related[inputName]) : related[inputName]}
        disabled={disabled || !canSelfUpdate(business?.statusId)}
        error={
          (andError !== undefined ? andError : true) &&
          stringAttributeIsInvalid(related[inputName]) &&
          !allFilled &&
          required
        }
        variant="outlined"
        onChange={(e) => {
          handlerChange(e, related, setRelated, index);
        }}
      />
    </div>
  );

  const RelacionadoComponent = (props) => {
    const { index, relatedItem, isLast } = props;

    const [related, setRelated] = useState(relatedItem);

    return (
      <div className="searchForm" style={{ marginBottom: 0 }}>
        <div className="containerTitle w-100 mt-4">
          <div className="itemTile d-flex flex-row justify-content-between w-100">
            <h3
              style={{
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Representantes
            </h3>
            <ReactIf condition={canSelfUpdate(business?.statusId)}>
              <ReactThen>
                <div className="d-flex flex-row">
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    className="mr-2"
                    onClick={() => removeRelated(index)}
                  />
                  <AddCircleOutlineIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => addRelated()}
                  />
                </div>
              </ReactThen>
              <ReactCatch>
                <div />
              </ReactCatch>
            </ReactIf>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between w-100 responsible">
          <StylizedInput
            label="Nome"
            inputName="name"
            required
            related={related}
            setRelated={setRelated}
            index={index}
          />
          <StylizedInput
            label="CPF"
            inputName="countryIdentity"
            required
            related={related}
            setRelated={setRelated}
            index={index}
            format={formatCpf}
            inputProps={{ maxLength: 14 }}
          />
          <div className="item pr-2 w-100 mt-2">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <div className="containerDatePicker w-100">
                <KeyboardDatePicker
                  helperText=""
                  cancelLabel="Cancelar"
                  className="mt-0 date-picker"
                  fullWidth
                  locale={ptBR}
                  disableToolbar
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Data de Nascimento*"
                  name="birthDate"
                  disabled={!canSelfUpdate(business?.statusId)}
                  value={related?.birthDate}
                  error={
                    stringAttributeIsInvalid(related.birthDate) && !allFilled
                  }
                  variant="inline"
                  onChange={(date) => {
                    setRelated((related) => ({ ...related, birthDate: date }));
                    changeBusinessRelateds(
                      { ...related, birthDate: date },
                      index
                    );
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </div>
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div className="d-flex flex-row responsible">
          <StylizedInput
            label="Número do RG"
            inputName="stateIdentity"
            required
            related={related}
            setRelated={setRelated}
            index={index}
            format={formatRg}
            inputProps={{ maxLength: 12 }}
          />
          <StylizedInput
            label="Orgão Emissor do RG"
            inputName="stateIdentityIssuingEntity"
            required
            related={related}
            setRelated={setRelated}
            index={index}
          />
          <StylizedInput
            label="UF do RG"
            inputName="stateIdentityFederativeUnity"
            required
            related={related}
            setRelated={setRelated}
            index={index}
            inputProps={{ maxLength: 2 }}
          />
        </div>

        {/* email, tel, cel... */}
        <div className="d-flex flex-row">
          <StylizedInput
            label="E-mail"
            inputName="email"
            required
            related={related}
            setRelated={setRelated}
            index={index}
          />
          <StylizedInput
            label="Telefone"
            inputName="officePhone"
            // required
            related={related}
            setRelated={setRelated}
            index={index}
            format={formatTelefone}
            inputProps={{ maxLength: 13 }}
          />
          <StylizedInput
            label="Celular"
            inputName="mobilePhone"
            required
            related={related}
            setRelated={setRelated}
            index={index}
            format={formatTelefone}
            inputProps={{ maxLength: 14 }}
          />
        </div>

        {isLast && canSelfUpdate(business?.statusId) && (
          <div className="item itemPicker" style={{ paddingRight: "2.5%" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <ConfirmButton onClick={() => updateBusinessRelateds()}>
                Salvar
              </ConfirmButton>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-100 relacionados">
      <ReactIf
        condition={business && !isAdmin() && canSelfUpdate(business?.statusId)}
      >
        <ReactThen>
          <Card
            className="card-box p-4 mb-4"
            style={{ width: "95%", maxWidth: "95%", margin: "0 auto" }}
          >
            <div className="text-center">
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper text-success m-0 d-65 align-items-center">
                  <InfoOutlinedIcon
                    style={{
                      color: "#2196f3",
                      fontSize: 28,
                    }}
                  />
                </div>
              </div>
              <h4
                className="font-weight-bold"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "26px",
                  textAlign: "center",
                  color: "#663c00",
                }}
              >
                Verifique se as informações abaixo estão corretas.
              </h4>
              <span>
                Os seus dados são consultados, automaticamente, em bancos de
                dados públicos.
              </span>
            </div>
          </Card>
        </ReactThen>
      </ReactIf>
      {businessRelateds.length === 0 && canSelfUpdate(business?.statusId) ? (
        <div className="d-flex flew-row align-items-center justify-content-center w-100">
          <span color="primary">
            Nenhum representante encontrado. Clique no botão ao lado para
            adicionar o primeiro!
          </span>
          <AddCircleOutlineIcon
            color="primary"
            style={{ cursor: "pointer" }}
            onClick={() => addRelated()}
          />
        </div>
      ) : (
        businessRelateds.map((related, index) => (
          <div key={related.responsibleId}>
            <RelacionadoComponent
              relatedItem={related}
              isLast={index === businessRelateds.length - 1}
              index={index}
            />
            {index !== businessRelateds.length - 1 && (
              <div className="separation-bar" />
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default Relacionados;
