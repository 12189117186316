import { CircularProgress } from "@material-ui/core";

const LoadingPage = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size={32} color="primary" />
    </div>
  );
};

export default LoadingPage;
