export function openDocumentWithUrlUtil(url) {
  if (url.includes('docx')) {
    const newUrl = `https://docs.google.com/gview?url=${encodeURIComponent(
      url
    )}`;
    window.open(newUrl, 'blank');
  } else {
    window.open(url, 'blank');
  }
}
