import React, { useContext } from 'react';

import { Button } from '@material-ui/core';

import { DialogContext } from '../../contexts/dialog';
import ExtensionModal from '../ExtensionModal';

export default function ExtensionButton({ maturity, invoice }) {
  const { toggleDialog } = useContext(DialogContext);

  if (!maturity || maturity?.statusName !== 'Título Pago') {
    return null;
  }

  return (
    <asside className="container-button">
      <Button
        variant="contained"
        color="primary"
        style={{ color: 'white' }}
        onClick={() => {
          toggleDialog(
            true,
            'Prorrogações',
            <ExtensionModal
              maturityId={maturity.invoiceMaturityId}
              invoice={invoice}
            />
          );
        }}
      >
        Prorrogações
      </Button>
    </asside>
  );
}
