import AxiosInterceptor from "utils/axios-interceptor";
import Requester from "./Requester";

export function fetchWithToken(url) {
  return Requester.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("bearer")}`,
    },
  });
}

export function fetchWithTokenExternalService(url) {
  const axiosInstance = AxiosInterceptor();
  return axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("bearer")}`,
    },
  });
}
