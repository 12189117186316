import { useState } from 'react';

import { useToast } from 'contexts/toast';

export function useOferta({ calcularTituloCet }) {
  const { toast } = useToast();
  const [alterandoOferta, setAlterandoOferta] = useState(false);
  const [desagio, setDesagio] = useState(0);
  const [novoFactor, setNovoFactor] = useState(0);

  function subirOferta(newValue) {
    try {
      setAlterandoOferta(true);

      handleChange({
        target: { value: newValue },
      });
      calcularTituloCet(newValue);
      setAlterandoOferta(false);
    } catch (error) {
      toast.error(error);
    }
  }

  function diminuirOferta(newValue) {
    try {
      setAlterandoOferta(true);

      handleChange({
        target: {
          value: newValue,
        },
      });
      calcularTituloCet(newValue);
      setAlterandoOferta(false);
    } catch (error) {
      toast.error(error);
    }
  }

  function handleChange(event) {
    const newValue = event.target.value;

    setDesagio(newValue);
    setNovoFactor(newValue);
  }

  return {
    alterandoOferta,
    subirOferta,
    diminuirOferta,
    desagio,
    novoFactor,
    setAlterandoOferta,
    setDesagio,
    setNovoFactor,
    handleChange,
  };
}
