import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./invoice.scss";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  LinearProgress,
  Button,
  Paper,
  TableContainer,
  Table,
  TableCell,
  FormControlLabel,
  Switch,
  Tooltip,
  IconButton,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleRoundedIcon from "@material-ui/icons/AddCircleRounded";
import NotificationsActiveOutlinedIcon from "@material-ui/icons/NotificationsActiveOutlined";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";

import clsx from "clsx";
import Cookies from "js-cookie";

import { ConfirmButton } from "components/Button";
import EnhancedTableHead from "components/EnhancedTableHead";

import formatDate from "utils/formatDate";
import formatDateToHours from "utils/formatDateToHours";

import { getTableCadastro, getTableCadastroFilter } from "services/table";

import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";

import {
  status,
  motives,
  businessTypes,
  marketTypes,
  headCells,
  motivesIdsByStatusType,
} from "./items";

const ANTECIPA_EXTERNAL_LINK =
  "https://app.antecipafacil.com.br/admin/app/#/app/";

const useStyles = makeStyles({
  paper: {
    background: "white",
    width: "105%",
    boxShadow: "none",
    border: "1px solid #99999966",
    maxWidth: "100%",
  },
  pagination: {
    background: "white",
    boxShadow: "none",
    border: "1px solid #99999966",
    marginLeft: "-30px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    width: "105%",
    maxWidth: "100%",
    marginTop: "70%",
    position: "absolute",
    bottom: "-75px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

export default function RegularTablesCadastro() {
  const classes = useStyles();

  const { userOrigin } = useAuth();
  const { toast } = useToast();

  const [isAgenda, setIsAgenda] = useState(false);
  const [state, setState] = useState({
    checkedB: false,
  });

  const [filter, setFilter] = useState({ pesquisaPrincipal: "", parceiro: "" });
  // const [invoiceConstant, setInvoiceConstant] = useState([]);
  const [countElements, setCountElements] = useState(0);
  const [statusIds, setStatusId] = useState("");
  const [typesIds, setTypesId] = useState("");
  const [businessTypesIds, setBusinessTypesId] = useState("");
  const [motivesIds, setMotivesIds] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [businesses, setBusinesses] = useState(undefined);
  const [openedAccordion, setOpenedAccordion] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    async function getItems() {
      try {
        const response = await getTableCadastroFilter(isAgenda, userOrigin);

        setBusinesses(response.data.businesses);
        setCountElements(parseInt(response.data.count / 10));
        setIsLoading(false);
      } catch (error) {
        toast.error("Ocorreu um erro ao buscar dados.");
      }
    }

    getItems();
  }, []);

  const handleSetMotives = (statusTypeId) => {
    if (statusTypeId === undefined || statusTypeId === "") return [];
    if (!motivesIdsByStatusType[statusTypeId]) return [];
    const selectedMotives = motivesIdsByStatusType[statusTypeId].map(
      (motiveId) => motives.find((motive) => motive.motiveId == motiveId)
    );
    return selectedMotives;
  };

  async function filterSearch(e, page, orderBy) {
    e.preventDefault();
    if (isLoading === false) {
      setIsLoading(true);

      const filterFull = {
        ...filter,
        statusId: statusIds,
        typeId: businessTypesIds,
        marketTypeId: typesIds,
        motiveId: motivesIds,
      };
      let filterParams = "";
      Object.keys(filterFull).forEach((key) => {
        filterParams +=
          filterFull[key] !== "" ? `${key}=${filterFull[key]}&` : "";
      });

      const response = await getTableCadastro(
        filterParams,
        orderBy,
        page,
        isAgenda,
        userOrigin
      );

      setBusinesses(response.data.businesses);
      setCountElements(parseInt(response.data.count / 10));
    }
    setIsLoading(false);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handlerChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const labelDisplayedRows = ({ from, to, count }) =>
    `${from}-${to} de ${count}`;

  const twentyMin = new Date(new Date().getTime() + 20 * 60 * 1000);

  const saveBusinessId = (id) => {
    Cookies.set("businessId", id, { expires: twentyMin });
    return id;
  };

  function getStatusColor(business) {
    const statusColor = status.find(
      (item) => item.statusName == business.statusName
    );

    return statusColor ? statusColor.backgroundColor : "red";
  }

  const handleShowAgendas = (event) => {
    async function _filterSearch(isAgenda, e, page, orderBy) {
      setIsLoading(true);
      e.preventDefault();

      const filterFull = {
        ...filter,
        statusId: statusIds,
        typeId: businessTypesIds,
        marketTypeId: typesIds,
        motiveId: motivesIds,
      };
      let filterParams = "";
      Object.keys(filterFull).forEach((key) => {
        filterParams +=
          filterFull[key] !== "" ? `${key}=${filterFull[key]}&` : "";
      });

      const response = await getTableCadastro(
        filterParams,
        orderBy,
        page,
        isAgenda,
        userOrigin
      );

      if (isAgenda) {
        const sorteadoPorQtdAgendasPendentes = response.data.businesses.sort(
          actionsAgendas.sortAgendasPendentes
        );

        setBusinesses(sorteadoPorQtdAgendasPendentes);
      } else {
        setBusinesses(response.data.businesses);
      }

      setCountElements(parseInt(response.data.count / 10));
      setIsLoading(false);
    }

    setState({ ...state, [event.target.name]: event.target.checked });
    setIsAgenda((old) => {
      _filterSearch(!old, event);

      return !old;
    });
  };

  const actionsAgendas = {
    sortAgendasPendentes(a, b) {
      if (
        a.agenda.filter(
          (agenda) => agenda.status_name == "Aguardando Validação"
        ).length >
        b.agenda.filter(
          (agenda) => agenda.status_name == "Aguardando Validação"
        ).length
      ) {
        return -1;
      }
      if (
        a.agenda.filter(
          (agenda) => agenda.status_name == "Aguardando Validação"
        ).length <
        b.agenda.filter(
          (agenda) => agenda.status_name == "Aguardando Validação"
        ).length
      ) {
        return 1;
      }
      return 0;
    },

    getAgendaQtd(agenda) {
      if (!agenda) return;
      const qtd = agenda.filter(
        (agenda) => agenda.status_name == "Aguardando Validação"
      ).length;

      return qtd;
    },
  };

  return (
    <>
      <div className="pageInvoice">
        <Paper
          elevation={3}
          className={clsx("app-page-title")}
          style={{
            // height: '135px',
            maxWidth: "100%",
            display: "flow-root",
          }}
        >
          <div>
            <div style={{ marginTop: "-25px" }}>
              <div className="app-page-title--heading">
                <h1 style={{ fontFamily: "Poppins" }}>Cadastros</h1>
              </div>
            </div>
          </div>
          <div
            className="searchForm"
            style={{
              boxShadow: "none",
              width: "100%",
            }}
          >
            <Accordion className="w-100">
              <AccordionSummary
                expandIcon={
                  openedAccordion ? (
                    <RemoveCircleRoundedIcon />
                  ) : (
                    <AddCircleRoundedIcon />
                  )
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="w-100"
                onClick={() => {
                  setOpenedAccordion(!openedAccordion);
                }}
              >
                <div
                  className="item w-100"
                  aria-label="Search"
                  onClick={(e) => e.stopPropagation()}
                  onFocus={(e) => e.stopPropagation()}
                >
                  <TextField
                    fullWidth
                    className="mt-2 mb-2 ml-2"
                    style={{ marginRight: -3.125 }}
                    id="outlined-basic"
                    label="Pesquisa(NOME FANTASIA, RAZÃO SOCIAL, NOME SÓCIO, NOME USUÁRIO ou CNPJ)"
                    name="pesquisaPrincipal"
                    value={filter.pesquisaPrincipal}
                    onChange={handlerChange}
                    variant="outlined"
                    onKeyPressCapture={(e) => {
                      if (e.key == "Enter") filterSearch(e);
                    }}
                  />
                </div>
              </AccordionSummary>
              <AccordionDetails className="d-flex flex-column">
                <div className="item itemPicker pr-4">
                  <div className="containerDatePicker">
                    <FormControl className="w-100 m-2">
                      <InputLabel
                        id="demo-simple-select-label"
                        className="ml-2 mt-2"
                      >
                        Status
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={statusIds}
                        onChange={(e) => {
                          setStatusId(e.target.value);
                        }}
                        onKeyPressCapture={(e) => {
                          if (e.key == "Enter") filterSearch(e);
                        }}
                      >
                        <MenuItem value="" />
                        {status.map((element) => (
                          <MenuItem
                            key={element.statusId}
                            value={element.statusId}
                          >
                            {element.statusName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="containerDatePicker">
                    <FormControl className="w-100 m-2">
                      <InputLabel
                        id="demo-simple-select-label"
                        className="ml-2 mt-2"
                      >
                        Motivo
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select-label"
                        value={motivesIds}
                        onChange={(e) => {
                          setMotivesIds(e.target.value);
                        }}
                        onKeyPressCapture={(e) => {
                          if (e.key == "Enter") filterSearch(e);
                        }}
                      >
                        <MenuItem value="" />
                        {handleSetMotives(statusIds).map((element) => (
                          <option
                            key={element.motiveId}
                            value={element.motiveId}
                          >
                            {element.motiveName}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="containerDatePicker">
                    <FormControl className="w-100 m-2">
                      <InputLabel
                        id="demo-simple-select-label"
                        className="ml-2 mt-2"
                      >
                        Tipo de Mercado
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select-label"
                        value={typesIds}
                        onChange={(e) => {
                          setTypesId(e.target.value);
                        }}
                        onKeyPressCapture={(e) => {
                          if (e.key == "Enter") filterSearch(e);
                        }}
                      >
                        <MenuItem value="" />
                        {marketTypes.map((element) => (
                          <option key={element.typeId} value={element.typeId}>
                            {element.typeName}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="item pr-4">
                  <div className="containerDatePicker w-100">
                    <FormControl className="w-100 m-2">
                      <InputLabel
                        id="demo-simple-select-label"
                        className="ml-2 mt-2"
                      >
                        Tipo
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={businessTypesIds}
                        onChange={(e) => {
                          setBusinessTypesId(e.target.value);
                        }}
                        onKeyPressCapture={(e) => {
                          if (e.key == "Enter") filterSearch(e);
                        }}
                      >
                        <MenuItem value="" />
                        {businessTypes.map((element) => (
                          <option key={element.typeId} value={element.typeId}>
                            {element.typeName}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <div className="item itemPicker pr-5">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  marginLeft: "30px",
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={state.checkedB}
                      onChange={handleShowAgendas}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Agendas"
                />
                <ConfirmButton onClick={(e) => filterSearch(e)}>
                  Consultar
                </ConfirmButton>
              </div>
            </div>
          </div>
        </Paper>
        <>
          <div
            className="tableContainerInvoice"
            style={{
              marginTop: "50px",
              marginLeft: "-30px",
              width: "100%",
              position: "relative",
              height: "max-content",
            }}
          >
            {(businesses === undefined || isLoading) && (
              <div style={{ marginLeft: "-30px" }}>
                <LinearProgress value={55} color="primary" />
              </div>
            )}
            {!isLoading && (
              <>
                <Paper className={classes.paper}>
                  <TableContainer>
                    <Table
                      aria-labelledby="tableTitle"
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={businesses.length}
                        headCells={headCells}
                        classes={classes}
                      />
                      {stableSort(businesses, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                        .map((business) => (
                          <>
                            <TableRow key={business.businessId}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: "5px",
                                  marginBottom: "5px",
                                }}
                              >
                                <TableCell
                                  style={{
                                    textAlign: "center",
                                    height: "50px",
                                    width: "50px",
                                    borderRadius: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontWeight: 500,
                                    backgroundColor:
                                      business.typeId == 1
                                        ? "#39854d"
                                        : business.typeId == 2
                                        ? "#d18b28"
                                        : "#366199",
                                    color: "#fff",
                                  }}
                                >
                                  {business.typeId == 1
                                    ? "C"
                                    : business.typeId == 2
                                    ? "F"
                                    : "P"}
                                </TableCell>
                              </div>
                              <TableCell style={{ textAlign: "center" }}>
                                {business?.typeId == 1 ||
                                business?.typeId == 2 ||
                                business?.typeId == 3 ? (
                                  <Link to="/capa">
                                    <Button
                                      style={{ width: "108%" }}
                                      color="default"
                                      onClick={() => {
                                        saveBusinessId(business.businessId);
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <b
                                          className="text-left"
                                          style={{
                                            fontSize: "1.25em",
                                            whiteSpace: "normal",
                                            width: 272,
                                            lineHeight: 1.25,
                                          }}
                                        >
                                          {`${
                                            business.name ||
                                            "Preencher cadastro"
                                          }`}
                                          &nbsp;({business.originType})
                                        </b>
                                        <span
                                          className="text-black-50 text-left mb-2"
                                          style={{
                                            width: "100%",
                                            lineHeight: 1.25,
                                            maxWidth: "30ch",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {business.corporateName}
                                        </span>
                                        <span
                                          className="text-left"
                                          style={{ lineHeight: 1.25 }}
                                        >
                                          CNPJ: {business.countryIdentity}
                                        </span>
                                      </div>

                                      {actionsAgendas.getAgendaQtd(
                                        business.agenda
                                      ) > 0 ? (
                                        <>
                                          <div className="d-flex flex-column align-items-center justify-content-center">
                                            <div className="d-flex flex-row align-items-center justify-content-around">
                                              <div />
                                              <Tooltip
                                                title={`${actionsAgendas.getAgendaQtd(
                                                  business.agenda
                                                )} Agenda pendente(s)`}
                                                placement="top"
                                              >
                                                <IconButton
                                                  size="medium"
                                                  variant="outlined"
                                                  style={{ color: "grey" }}
                                                  onClick={() => {}}
                                                >
                                                  <NotificationsActiveOutlinedIcon className="font-size-lg" />
                                                </IconButton>
                                              </Tooltip>
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                    </Button>
                                  </Link>
                                ) : (
                                  <a
                                    target="_blank"
                                    href={`${ANTECIPA_EXTERNAL_LINK}partner/edit/${business.businessId}`}
                                    rel="noreferrer"
                                  >
                                    <Button
                                      color="default"
                                      variant="outlined"
                                      className="d-flex align-items-center"
                                    >
                                      <div className="d-flex flex-column align-items-start">
                                        <b
                                          className="text-left"
                                          style={{
                                            fontSize: "1.25em",
                                            whiteSpace: "normal",
                                            width: 272,
                                            lineHeight: 1.25,
                                          }}
                                        >
                                          {business.name}
                                        </b>
                                        <span
                                          className="text-black-50 text-left mb-2"
                                          style={{
                                            whiteSpace: "normal",
                                            width: 272,
                                            lineHeight: 1.25,
                                          }}
                                        >
                                          {business.corporateName}
                                        </span>
                                        <span style={{ lineHeight: 1.25 }}>
                                          {business.countryIdentity}
                                        </span>
                                      </div>
                                    </Button>
                                  </a>
                                )}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                }}
                              >
                                {formatDate(business.lastUpdateDate)}
                                {"\n"}
                                {formatDateToHours(business.lastUpdateDate)}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  fontSize: "16px",
                                }}
                              >
                                {formatDate(business.statusDate)}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                <div
                                  style={{
                                    cursor: "default",
                                    backgroundColor: getStatusColor(business),
                                    color: "#FFF",
                                    height: "30px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    borderRadius: "180px",
                                    minWidth: "270px",
                                    maxWidth: "360px",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                  }}
                                >
                                  {business.statusName.toUpperCase()}
                                </div>
                                {business.motiveName}
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="paginacao"
                    rowsPerPageOptions={[10, 25, 50]}
                    component="body"
                    count={countElements}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage="Linhas por página"
                    labelDisplayedRows={labelDisplayedRows}
                  />
                </Paper>
              </>
            )}
          </div>
        </>
      </div>
    </>
  );
}
