const formatDateSmallYear = (date) =>
  `${
    date.getDate() < 10
      ? `0${date.getDate()}`
      : date.getDate()
  }/${
    date.getMonth() + 1 < 10
      ? `0${date.getMonth() + 1}`
      : date.getMonth() + 1
  }/${date.getFullYear().toString().slice(-2)}`;

export default formatDateSmallYear;
