function validateCep(v) {
  const regex = /^\d{5}-\d{3}$/;

  if (regex.test(v)) {
    return true;
  }
  return false;
}

export default validateCep;
