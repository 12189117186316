import useSWR from 'swr';
import { fetchWithToken } from '../fetchWithToken';


export default function useExtract(
  accountId = null,
  filters = null,
  fetch = false,
  options = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
  }
) {
  const {
    data: extract,
    error,
    isValidating,
    mutate,
  } = useSWR(
    fetch && accountId && filters
      ? `${
          process.env.REACT_APP_BANKING_API
        }/extract/${accountId}/?${new URLSearchParams(filters)}`
      : false,
    fetchWithToken,
    options
  );

  return {
    extract: extract && extract.data ? extract.data : null,
    error,
    isValidating,
    isLoading: fetch && filters && !error && !extract,
    mutate,
  };
}
