import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  FormControl,
  FormHelperText,
  TextField,
  MenuItem,
  Select,
  CircularProgress,
} from '@material-ui/core';

import { ConfirmButton } from 'components/Button';

import { useToast } from 'contexts/toast';

import { useInvoice } from 'hooks/useInvoice';

import { riskStyle } from 'styles/material/risk';

import 'styles/risk.scss';
import { CONST_RISCO } from './risconConsts';

function AvaliarRisco() {
  const classes = riskStyle();
  const { invoiceId } = useParams();

  const { invoice, updateRisk } = useInvoice(invoiceId);
  const [newInvoice, setNewInvoice] = useState({
    riskModelCode: '',
    riskExecutedCode: null,
    riskExecutedDate: null,
    riskName: '',
    riskObs: '',
    riskId: 0,
  });

  const { toast } = useToast();

  useEffect(() => {
    if (!invoice) return;
    if (invoice.invoiceId != invoiceId) return;

    setNewInvoice({
      riskModelCode: invoice.riskModelCode,
      riskExecutedCode: invoice.riskExecutedCode,
      riskExecutedDate: invoice.riskExecutedDate,
      riskName: invoice.riskName,
      riskObs: invoice.riskObs,
      riskId: invoice.riskId,
    });
  }, [invoice, invoiceId]);

  async function handleUpdateRisk() {
    const parsedInvoice = {
      riskModelCode: newInvoice.riskModelCode,
      riskName: newInvoice.riskName,
      riskObs: newInvoice.riskObs,
      riskId: newInvoice.riskId,
      invoiceId,
    };
    const validObject = !Object.values(parsedInvoice).some((item) => !item);

    if (!validObject) return toast('Verifique os campos obrigatórios');

    await updateRisk(parsedInvoice, invoiceId);
  }

  function CodigoExecucao({ value }) {
    return (
      <>
        {!newInvoice && 'Carregando'}
        {newInvoice && (
          <TextField
            disabled
            id="outlined-basic"
            name="executionCode"
            helperText="Código de execução"
            variant="outlined"
            placeholder="Código indefinido"
            // defaultValue="Código indefinido"
            value={value}
          />
        )}
      </>
    );
  }

  function DataExecucao({ value }) {
    return (
      <>
        {!newInvoice && 'Carregando'}
        {newInvoice && (
          <TextField
            disabled
            id="outlined-basic"
            name="executionDate"
            helperText="Data de execução"
            variant="outlined"
            placeholder="Data indefinida"
            // defaultValue="Data indefinida"
            value={value}
          />
        )}
      </>
    );
  }

  return (
    <main id="risco" className="formContainer">
      <div className="containerTitle">
        <h4 style={{ fontFamily: 'Poppins, sans-serif' }}>Risco</h4>
      </div>
      {invoice && (
        <>
          <div className="content">
            <div className="container">
              <FormControl variant="outlined">
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={(e) =>
                    setNewInvoice({
                      ...newInvoice,
                      riskModelCode: e.target.value,
                    })
                  }
                  defaultValue=""
                  displayEmpty
                  placeholder="Selecione um código"
                  value={newInvoice.riskModelCode}
                >
                  <MenuItem disabled value="">
                    Selecionar
                  </MenuItem>
                  <MenuItem value="OPV2">OPV2</MenuItem>
                </Select>
                <FormHelperText>Código do modelo*</FormHelperText>
              </FormControl>
            </div>

            <div className="container">
              <CodigoExecucao value={newInvoice.riskExecutedCode} />
            </div>

            <div className="container">
              <DataExecucao
                value={
                  newInvoice.riskExecutedDate
                    ? new Date(newInvoice.riskExecutedDate).toLocaleDateString()
                    : newInvoice.riskExecutedDate
                }
              />
            </div>

            <div className="container">
              <FormControl variant="outlined">
                <Select
                  displayEmpty
                  onChange={(e) =>
                    setNewInvoice({
                      ...newInvoice,
                      riskName: e.target.value,
                      riskId:
                        e.target.value == 'INAPTO'
                          ? 0
                          : CONST_RISCO.find((x) => x.name == e.target.value)
                              .value,
                    })
                  }
                  value={newInvoice.riskName}
                >
                  <MenuItem
                    value=""
                    disabled
                    classes={{ root: classes.default }}
                  >
                    Selecionar
                  </MenuItem>
                  <MenuItem value="AA" classes={{ root: classes.AA }}>
                    AA
                  </MenuItem>
                  <MenuItem value="AB" classes={{ root: classes.AB }}>
                    AB
                  </MenuItem>
                  <MenuItem value="BA" classes={{ root: classes.BA }}>
                    BA
                  </MenuItem>
                  <MenuItem value="BB" classes={{ root: classes.BB }}>
                    BB
                  </MenuItem>
                  <MenuItem value="CA" classes={{ root: classes.CA }}>
                    CA
                  </MenuItem>
                  <MenuItem value="CB" classes={{ root: classes.CB }}>
                    CB
                  </MenuItem>
                  <MenuItem value="DA" classes={{ root: classes.DA }}>
                    DA
                  </MenuItem>
                  <MenuItem value="INAPTO" classes={{ root: classes.INAPTO }}>
                    INAPTO
                  </MenuItem>
                </Select>
                <FormHelperText>Risco da operação*</FormHelperText>
              </FormControl>
            </div>

            <div className="container">
              <TextField
                variant="outlined"
                id="outlined-basic"
                name="observacao"
                placeholder="Digite algo."
                helperText="Observação*"
                onChange={(e) => {
                  setNewInvoice({ ...newInvoice, riskObs: e.target.value });
                }}
                value={newInvoice.riskObs}
              />
            </div>
          </div>
          <aside className="container-button">
            <ConfirmButton
              disabled={!invoice}
              onClick={() => handleUpdateRisk()}
            >
              Salvar risco
            </ConfirmButton>
          </aside>
        </>
      )}
      {!invoice && (
        <div className="container">
          <CircularProgress color="primary" />
        </div>
      )}
    </main>
  );
}

export default AvaliarRisco;
