import { sendBusinessNotification } from "./businessNotification";
import AxiosInterceptor from "utils/axios-interceptor";

export const createMaturityExtension = async (newMaturityExtension) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/maturityExtension/create`,
    newMaturityExtension
  );

  await sendBusinessNotification({
    businessReceiverId: data.buyerId,
    businessSenderId: data.sellerId,
    notificationText: "Você recebeu uma solicitação de prorrogação!",
    notificationLink: `/Invoice/${data.invoiceId}`,
  });

  return data;
};

export const getMaturityExtensionByMaturityIds = async (maturityIds) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/maturityExtension/maturityIds/`,
    maturityIds
  );

  return data;
};

const getProgress = (event, handleProgress) => {
  if (event.lengthComputable) {
    const percentComplete = Math.round((event.loaded * 100) / event.total);

    handleProgress(percentComplete);
  }
};

export const uploadDocument = async (formData, handleUploadProgress) => {
  const axiosInstance = AxiosInterceptor();
  const response = await axiosInstance.post(
    `${process.env.REACT_APP_UPLOAD_BACKEND_API}/files/upload`,
    formData,
    {
      onUploadProgress: (event) => {
        getProgress(event, handleUploadProgress);
      },
      responseType: "arraybuffer",
      transformRequest: (data) => data,
    }
  );
  return response;
};

export const getUploadDocument = async (maturityExtensionId) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/maturityExtension/documents/${maturityExtensionId}`
  );
  return data;
};

export const buyerRejectExtension = async ({
  maturityExtensionId,
  approveBuyerUserId,
}) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/maturityExtension/update`,
    {
      maturityExtensionId,
      approveBuyerUserId,
      buyerApproveDate: new Date(),
      statusId: 2,
      statusName: "Solicitação Rejeitada",
    }
  );

  await sendBusinessNotification({
    businessReceiverId: data.sellerId,
    businessSenderId: data.buyerId,
    notificationText: "O financiador rejeitou a sua proposta de prorrogação",
    notificationLink: `/Invoice/${data.invoiceId}`,
  });

  return data;
};

export const sellerRejectExtension = async ({
  maturityExtensionId,
  approveSellerUserId,
}) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/maturityExtension/update`,
    {
      maturityExtensionId,
      approveSellerUserId,
      sellerApproveDate: new Date(),
      statusId: 5,
      statusName: "Rejeição Cedente",
    }
  );

  await sendBusinessNotification({
    businessReceiverId: data.buyerId,
    businessSenderId: data.sellerId,
    notificationText: "O cedente cancelou a sua proposta de prorrogação",
    notificationLink: `/Invoice/${data.invoiceId}`,
  });

  return data;
};

export const sellerApproveExtension = async ({
  maturityExtensionId,
  approveSellerUserId,
}) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/maturityExtension/update`,
    {
      maturityExtensionId,
      approveSellerUserId,
      sellerApproveDate: new Date(),
      statusId: 4,
      statusName: "Aceite Cedente",
    }
  );

  await sendBusinessNotification({
    businessReceiverId: data.buyerId,
    businessSenderId: data.sellerId,
    notificationText: "O cedente aprovou a sua proposta de prorrogação!",
    notificationLink: `/Invoice/${data.invoiceId}`,
  });

  return data;
};

export const buyerCancelExtension = async ({
  maturityExtensionId,
  approveSellerUserId,
}) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/maturityExtension/update`,
    {
      maturityExtensionId,
      approveSellerUserId,
      sellerApproveDate: new Date(),
      statusId: 6,
      statusName: "Cancelamento Financiador",
    }
  );

  await sendBusinessNotification({
    businessReceiverId: data.sellerId,
    businessSenderId: data.buyerId,
    notificationText: "O financiador cancelou a prorrogação solicitada",
    notificationLink: `/Invoice/${data.invoiceId}`,
  });

  return data;
};

export const buyerProposalExtension = async ({
  maturityExtensionId,
  requestBuyerUserId,
  buyerFee,
  interest,
  buyerReason,
  buyerExtensionDate,
}) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/maturityExtension/update`,
    {
      maturityExtensionId,
      requestBuyerUserId,
      buyerRequestDate: new Date(),
      buyerExtensionDate,
      buyerFee,
      interest,
      buyerReason,
      statusId: 3,
      statusName: "Proposta Financiador",
    }
  );

  await sendBusinessNotification({
    businessReceiverId: data.sellerId,
    businessSenderId: data.buyerId,
    notificationText:
      "O financiador enviou uma proposta de prorrogação para você!",
    notificationLink: `/Invoice/${data.invoiceId}`,
  });

  return data;
};

export const getBuyerExtensions = async (buyerId) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/invoice/buyerExtensions/${buyerId}`
  );

  return data;
};
