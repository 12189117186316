import React, { useEffect } from 'react';

import { IconButton, Menu } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import EventBus from '../../services/EventBus';

export default function IsolateMenu({ children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    EventBus.on('closeIsolateMenu', () => {
      handleClose();
    });
  }, []);

  return (
    <>
      <IconButton aria-haspopup="true" onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {children}
      </Menu>
    </>
  );
}
