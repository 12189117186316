function formatCnpj(valor) {
  if (!valor) return;
  valor = valor.replace(/\D/g, ''); // Remove tudo o que não é dígito
  valor = valor.replace(/^(\d{2})(\d)/, '$1.$2'); // Coloca ponto entre o segundo e o terceiro dígitos
  valor = valor.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // Coloca ponto entre o quinto e o sexto dígitos
  valor = valor.replace(/\.(\d{3})(\d)/, '.$1/$2'); // Coloca uma barra entre o oitavo e o nono dígitos
  valor = valor.replace(/(\d{4})(\d)/, '$1-$2'); // Coloca um hífen depois do bloco de quatro dígitos
  return valor;
}

export default formatCnpj;
