import useSWR from 'swr';

import { fetchWithTokenExternalService } from '../fetchWithToken';

export default function useBankingAccounts(
  fetch = false,
  filters = {},
  options = {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    shouldRetryOnError: true,
    errorRetryInterval: 1000,
  }
) {
  const {
    data: accounts,
    error,
    isValidating,
    mutate,
  } = useSWR(
    fetch
      ? `${
          process.env.REACT_APP_BANKING_API
        }/abc-accounts?${new URLSearchParams(filters)}`
      : false,
    fetchWithTokenExternalService,
    options
  );

  return {
    accounts: accounts && accounts.data ? accounts.data : null,
    error,
    isValidating,
    isLoading: fetch && !error && !accounts,
    mutate,
  };
}
