import React, { useContext } from 'react';

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { AccountManagementContext } from '../../../contexts/accountManager';
import { DialogContext } from '../../../contexts/dialog';
import CreateAccountModal from '../CreateAccountModal';

const STATUS = [
  { statusId: 1, statusName: 'LIBERADA', backgroundColor: '#39854d' },
  { statusId: 2, statusName: 'BLOQUEADA', backgroundColor: '#c21d36' },
  { statusId: 3, statusName: 'NEGADA', backgroundColor: '#c21d36' },
  { statusId: 4, statusName: 'ABERTA', backgroundColor: '#366199' },
];

function FilterAccounts() {
  const { toggleDialog } = useContext(DialogContext);
  const { optionsMenu, tabValue, setPage, setFilters, openAccountConsigned } =
    useContext(AccountManagementContext);
  const formik = useFormik({
    initialValues: {
      term: '',
      status: '',
    },

    validationSchema: Yup.object({}),

    onSubmit: (values) => {
      setPage(1);
      setFilters(values);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            fullWidth
            label="Pesquisar (NOME FANTASIA, RAZÃO SOCIAL, E-MAIL ou CNPJ)"
            name="term"
            value={formik.values.term}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              fullWidth
              labelId="status-label"
              value={formik.values.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label="Status"
              name="status"
            >
              <MenuItem value="">Selecione o status</MenuItem>
              {STATUS.map((val, index) => (
                <MenuItem value={val.statusId} key={index}>
                  {val.statusName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <Box display="flex" height="100%" flex={1}>
            <Button
              type="submit"
              color="primary"
              fullWidth
              variant="contained"
              disabled={!formik.isValid}
              startIcon={<SearchIcon />}
            >
              Buscar
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <Box display="flex" height="100%" flex={1}>
            <Button
              type="button"
              color="primary"
              fullWidth
              variant="contained"
              onClick={() => {
                toggleDialog(
                  true,
                  'Nova conta',
                  <CreateAccountModal
                    tabValue={tabValue}
                    statuses={optionsMenu}
                    openAccountConsigned={openAccountConsigned}
                    setFilters={setFilters}
                    setPage={setPage}
                  />
                );
              }}
              startIcon={<AddIcon />}
            >
              Nova conta
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

export default React.memo(FilterAccounts);
