export const headCells = [
  {
    align: 'left',
    id: 'number',
    label: 'Nota fiscal',
    minWidth: 150,
    maxWidth: 150,
    backgroundColor: '#FFFFFF',
  },
  {
    align: 'left',
    id: 'risk_name',
    label: 'Risco',
    minWidth: 100,
    maxWidth: 100,
    backgroundColor: '#FFFFFF',
  },
  {
    align: 'left',
    id: 'drawee.name',
    label: 'Sacado',
    minWidth: 200,
    maxWidth: 200,
    backgroundColor: '#FFFFFF',
  },
  {
    align: 'left',
    id: 'first_maturity_date',
    label: '1º vencimento Parcelas',
    minWidth: 175,
    maxWidth: 175,
    backgroundColor: '#FFFFFF',
  },
  {
    align: 'left',
    id: 'agendaValue',
    label: 'Valor total',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#FFFFFF',
  },
  {
    align: 'left',
    id: 'commited_value',
    label: 'Valor Utilizado',
    minWidth: 300,
    maxWidth: 330,
    backgroundColor: '#FFFFFF',
  },
  {
    id: 'edit',
    label: 'Ação',
    minWidth: 50,
    maxWidth: 50,
    backgroundColor: '#FFFFFF',
  },
];
