import React from 'react';

export default function  Produtos({ size = 40 }) {
  return (
    <svg
      id="box"
      xmlns="http://www.w3.org/2000/svg"
      width={(size * 107.3) / 100}
      height={size}
      viewBox="0 0 34.792 32.423"
    >
      <path
        id="Caminho_101"
        data-name="Caminho 101"
        d="M41.673,39.6H39.919a1.728,1.728,0,0,0-1.649-1.268H36.4v-4.58a.483.483,0,0,0-.236-.427L22.95,25.7a.488.488,0,0,0-.49,0L9.245,33.33A.492.492,0,0,0,9,33.757V49.018a.493.493,0,0,0,.246.427l13.216,7.63a.493.493,0,0,0,.247.066.519.519,0,0,0,.254-.066l6.955-4.011v2.9a2.088,2.088,0,0,0,2.105,2.093h9.65a2.1,2.1,0,0,0,2.119-2.093V41.73A2.127,2.127,0,0,0,41.673,39.6Zm-3.386-.282a.716.716,0,0,1,.716.716v.048a.716.716,0,0,1-.716.716H35.422a.716.716,0,0,1-.716-.716v-.048a.716.716,0,0,1,.716-.716ZM22.709,26.7,28.2,29.867,15.971,36.927,10.48,33.757Zm-.469,29.1L9.986,48.733V34.61l12.255,7.061Zm.469-14.977L16.957,37.5l12.229-7.061,5.752,3.321Zm7.208.913v10.2l-6.691,3.869V41.671L35.411,34.61v3.727h.015A1.728,1.728,0,0,0,33.777,39.6H32.022A2.115,2.115,0,0,0,29.917,41.73ZM42.806,55.964a1.112,1.112,0,0,1-1.133,1.107h-9.65A1.1,1.1,0,0,1,30.9,55.964V41.73a1.128,1.128,0,0,1,1.119-1.14H33.8a1.686,1.686,0,0,0,1.63,1.2h2.843a1.686,1.686,0,0,0,1.63-1.2h1.774a1.14,1.14,0,0,1,1.133,1.14Z"
        transform="translate(-9 -25.634)"
      />
      <path
        id="Caminho_102"
        data-name="Caminho 102"
        d="M337.489,281.31h.516a.493.493,0,0,0,0-.986h-.019v-1.338h1.36a.475.475,0,0,0,.469.333.494.494,0,0,0,.5-.492V278.5a.5.5,0,0,0-.5-.5h-2.322a.492.492,0,0,0-.489.5v2.322a.49.49,0,0,0,.489.492Z"
        transform="translate(-313.899 -260.226)"
      />
      <path
        id="Caminho_103"
        data-name="Caminho 103"
        d="M337.493,345.31h2.324a.493.493,0,0,0,.493-.493v-2.324a.493.493,0,0,0-.493-.493h-2.324a.493.493,0,0,0-.493.493v2.324A.493.493,0,0,0,337.493,345.31Zm.493-2.324h1.338v1.338h-1.338Z"
        transform="translate(-313.899 -319.719)"
      />
      <path
        id="Caminho_104"
        data-name="Caminho 104"
        d="M338.005,408.324h-.02v-1.338h1.333a.5.5,0,1,0,.991,0v-.475a.514.514,0,0,0-.5-.512h-2.322a.506.506,0,0,0-.489.512v2.322a.476.476,0,0,0,.489.476h.516a.493.493,0,1,0,0-.986Z"
        transform="translate(-313.899 -379.211)"
      />
      <path
        id="Caminho_105"
        data-name="Caminho 105"
        d="M357.079,284.683l-1.444,1.444-.439-.439a.493.493,0,0,0-.7.7l.788.788a.493.493,0,0,0,.7,0l1.793-1.793a.493.493,0,1,0-.7-.7Z"
        transform="translate(-330.032 -266.304)"
      />
      <path
        id="Caminho_106"
        data-name="Caminho 106"
        d="M357.079,413.932l-1.444,1.444-.439-.439a.493.493,0,0,0-.7.7l.788.788a.493.493,0,0,0,.7,0l1.793-1.793a.493.493,0,0,0-.7-.7Z"
        transform="translate(-330.032 -386.45)"
      />
      <path
        id="Caminho_107"
        data-name="Caminho 107"
        d="M417.48,295h-3.1a.493.493,0,0,0,0,.986h3.1a.493.493,0,1,0,0-.986Z"
        transform="translate(-385.375 -276.029)"
      />
      <path
        id="Caminho_108"
        data-name="Caminho 108"
        d="M417.48,359h-3.1a.493.493,0,0,0,0,.986h3.1a.493.493,0,1,0,0-.986Z"
        transform="translate(-385.375 -335.521)"
      />
      <path
        id="Caminho_109"
        data-name="Caminho 109"
        d="M417.48,423h-3.1a.493.493,0,0,0,0,.986h3.1a.493.493,0,1,0,0-.986Z"
        transform="translate(-385.375 -395.014)"
      />
    </svg>
  );
}
