import { rest } from "msw";

import {
  getBusinessNotificationBusinessReceiverResponseSuccess,
  getInvoiceActionResponseSuccess,
  getTagsAllResponseSuccess,
  postBidCalculoCet,
  postNoGordLeExecuteResponseSuccess,
} from "./fixtures";

const apiHmg = "https://api-hmg.antecipafacil.com.br";

const api = apiHmg;

export const handlers = [
  rest.get(
    "https://cdn.loom.com/assets/fonts/circular/CircularXXWeb-Book-cd7d2bcec649b1243839a15d5eb8f0a3.woff2",
    (req, res, ctx) => res(ctx.status(200), ctx.json(res))
  ),
  rest.get(`${api}/invoice/action/:businessId/FIF`, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(getInvoiceActionResponseSuccess))
  ),
  rest.get(
    `${api}/businessNotification/businessReceiver/:businessId`,
    (req, res, ctx) =>
      res(
        ctx.status(200),
        ctx.json(getBusinessNotificationBusinessReceiverResponseSuccess)
      )
  ),
  rest.get(`${api}/tags/all`, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(getTagsAllResponseSuccess))
  ),
  rest.post(`${api}/bid/calculo/cet`, (req, res, ctx) =>
    res(ctx.status(200), ctx.json(postBidCalculoCet))
  ),
  rest.post(
    `${process.env.REACT_APP_DECISION_API}/api/v1/le/execute/:id`,
    (req, res, ctx) =>
      res(ctx.status(200), ctx.json(postNoGordLeExecuteResponseSuccess))
  ),
];
