import React from 'react';

import Cookies from 'js-cookie';

import { Recebiveis } from 'components/Recebiveis';

export default (props) => {
  const { title } = props;

  const businessId = Cookies.get('businessId');

  return (
    <>
      <div style={{ marginTop: '30px', marginLeft: '-65px', width: '105%' }}>
        <div className="searchForm" style={{ marginLeft: '35px' }}>
          <div
            className="containerTitle"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <h2 style={{ fontFamily: 'Poppins', alignSelf: 'center' }}>
              {title || 'Agenda de Recebíveis'}
            </h2>
          </div>
          <div className="containerTitle w-100 mt-4">
            <Recebiveis
              edit
              typeId={404}
              businessId={businessId}
              cadastroTitulo
              aprovarECriarTitulo
              esconderDataVencimento
              esconderSacado
              esconderValorUtlizadoTitulo
            />
          </div>
        </div>
      </div>
    </>
  );
};
