import React, { useEffect, useState } from 'react';

import {
  TextField,
  Tooltip,
  LinearProgress,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TablePagination,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';

import clsx from 'clsx';

import { ConfirmButton } from 'components/Button';
import EnhancedTableHead from 'components/EnhancedTableHead';

import {
  getAllTagsServices,
  saveTagServices,
  deleteTagServices,
} from 'services/tags-services';

import { useToast } from 'contexts/toast';

import { useModal } from 'hooks/useModal';

import { headCells } from './items';

const useStyles = makeStyles({
  paper: {
    background: 'white',
    width: '105%',
    boxShadow: 'none',
    border: '1px solid #99999966',
    maxWidth: '100%',
    marginLeft: '-30px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

const CadastroTags = () => {
  const classes = useStyles();
  const { openModal } = useModal();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [tags, SetTags] = useState([]);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const { toast } = useToast();

  const [tagName, setTagName] = useState('');
  // const [tipo, setTipo] = useState(1);

  useEffect(() => {
    async function getTags() {
      try {
        const { data } = await getAllTagsServices();
        SetTags(data);
      } catch (error) {
        toast.error('Ocorreu um erro ao obter as tags');
      }
    }
    setLoadingRequest(true);
    getTags();
    setLoadingRequest(false);
  }, []);

  // const changeType = (event) => {
  //   setTipo(event.target.value);
  // };

  const handleSave = async () => {
    if (!tagName) {
      toast.error('O nome da Tag não pode estar em branco');
      return;
    }
    const tag = {
      // typeId: tipo,
      typeId: 0,
      // typeName: tipo == 1 ? 'Título' : 'Agenda',
      typeName: 'Default',
      name: tagName,
    };
    try {
      await toast.promise(saveTagServices(tag), {
        loading: 'Salvando a tag',
        success: 'Tag salva com sucesso.',
        error: 'Erro ao salvar a tag',
      });
      await refresh();
    } catch (error) {
      toast.error('Erro ao salvar tag');
    }
  };

  const refresh = async () => {
    try {
      setLoadingRequest(true);
      const { data } = await getAllTagsServices();
      SetTags(data);
    } catch (error) {
      toast.error('Ocorreu um erro ao obter as tags');
    } finally {
      setLoadingRequest(false);
    }
  };

  const deleteTag = async (tagId) => {
    try {
      setLoadingRequest(true);
      await toast.promise(deleteTagServices(tagId), {
        loading: 'Deletando a tag',
        success: 'Tag deletada com sucesso.',
        error: 'Erro ao deletar tag',
      });
      await refresh();
    } catch (error) {
      toast.error('Ocorreu um erro ao deletar a tag');
    } finally {
      setLoadingRequest(false);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const labelDisplayedRows = ({ from, to, count }) =>
    `${from}-${to} de ${count}`;

  return (
    <>
      <div className="pageInvoice">
        <Paper
          elevation={3}
          className={clsx('app-page-title')}
          style={{
            // height: '135px',
            maxWidth: '100%',
            display: 'flow-root',
          }}
        >
          <div>
            <div style={{ marginTop: '-25px' }}>
              <div className="app-page-title--heading">
                <h1 style={{ fontFamily: 'Poppins' }}>Cadastro de Tags</h1>
              </div>
            </div>
          </div>

          <div
            className="searchForm"
            style={{
              boxShadow: 'none',
              width: '100%',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <TextField
                fullWidth
                label="Nome da tag"
                id="outlined-size-small"
                variant="outlined"
                onChange={(e) => setTagName(e.target.value)}
              />

              {/* <TextField
                fullWidth
                className="m-2"
                id="outlined-select-type"
                select
                label="Tipo da Tag"
                size="small"
                variant="outlined"
                value={tipo}
                onChange={changeType}
              > */}
              {/* {tagTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
              {/* </TextField> */}
            </div>

            <div className="item itemPicker">
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                  marginTop: '10px',
                }}
              >
                <ConfirmButton onClick={() => handleSave()}>
                  Salvar
                </ConfirmButton>
              </div>
            </div>
          </div>
        </Paper>

        <div
          className="containerTitle w-100 mt-4"
          style={{ marginTop: '50px !important' }}
        >
          {(tags === undefined || loadingRequest) && (
            <div style={{ marginLeft: '-30px' }}>
              <LinearProgress value={55} color="primary" />
            </div>
          )}
          {tags !== undefined && !loadingRequest && (
            <Paper className={classes.paper}>
              <TableContainer>
                <Table>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headCells}
                    classes={classes}
                  />
                  {stableSort(tags, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((tag) => (
                      <>
                        <TableBody key={tag?.tagId}>
                          <TableCell style={{ textAlign: 'center' }}>
                            <Tooltip title={tag?.tagId} placement="top-start">
                              <span style={{ fontWeight: 'bold' }}>
                                {tag?.tagId}
                              </span>
                            </Tooltip>
                          </TableCell>

                          {/* <TableCell style={{ textAlign: 'center' }}>
                            {tag?.typeId}
                          </TableCell> */}

                          {/* <TableCell style={{ textAlign: 'center' }}>
                            <Tooltip
                              title={tag?.typeName}
                              placement="top-start"
                            >
                              <span>{tag?.typeName}</span>
                            </Tooltip>
                          </TableCell> */}

                          <TableCell style={{ textAlign: 'center' }}>
                            <Tooltip title={tag?.name} placement="top-start">
                              <span>{tag?.name}</span>
                            </Tooltip>
                          </TableCell>

                          <TableCell style={{ textAlign: 'center' }}>
                            <Tooltip title="Deletar tag" placement="top">
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  openModal(
                                    'warning',
                                    'Atenção!',
                                    'Tem certeza que deseja remover essa tag?',
                                    deleteTag,
                                    tag?.tagId
                                  )
                                }
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableBody>
                      </>
                    ))}
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tags.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Linhas por página"
                labelDisplayedRows={labelDisplayedRows}
              />
            </Paper>
          )}
        </div>
      </div>
    </>
  );
};

export default CadastroTags;
