import React from 'react';

import { useTheme } from '@material-ui/core/styles';

export default function Buildings({
  color = '#A0A0A0',
  primary = false,
  size = 30,
}) {
  const theme = useTheme();

  const iconColor = primary ? theme.palette.primary.main : color;

  return (
    <svg
      id="XMLID_1_"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 99.25) / 100}
      viewBox="0 0 41.273 40.963"
    >
      <path
        id="Caminho_7"
        data-name="Caminho 7"
        fill={iconColor}
        d="M14.36,9.09,29.511,12.1a2.541,2.541,0,0,1,2.046,2.511V32.151a2.576,2.576,0,0,1-2.58,2.58H13.5a.852.852,0,0,0,.86-.86v-.86H28.977a.862.862,0,0,0,.86-.86V14.61a.864.864,0,0,0-.671-.843L14.36,10.844Z"
        transform="translate(9.716 6.232)"
      />
      <path
        id="Caminho_8"
        data-name="Caminho 8"
        fill={iconColor}
        d="M21.3,14a.86.86,0,1,1,0,1.72H17.86a.86.86,0,1,1,0-1.72Z"
        transform="translate(12.235 9.766)"
      />
      <path
        id="Caminho_9"
        data-name="Caminho 9"
        fill={iconColor}
        d="M21.3,17a.86.86,0,1,1,0,1.72H17.86a.86.86,0,1,1,0-1.72Z"
        transform="translate(12.235 11.925)"
      />
      <path
        id="Caminho_10"
        data-name="Caminho 10"
        fill={iconColor}
        d="M21.3,20a.86.86,0,1,1,0,1.72H17.86a.86.86,0,1,1,0-1.72Z"
        transform="translate(12.235 14.084)"
      />
      <path
        id="Caminho_11"
        data-name="Caminho 11"
        fill={iconColor}
        d="M14.72,33.936a.86.86,0,1,1-1.72,0V9.86a.914.914,0,0,1,.31-.671.9.9,0,0,1,.722-.172l.688.138V33.936Z"
        transform="translate(9.356 6.167)"
      />
      <path
        id="Caminho_12"
        data-name="Caminho 12"
        fill={iconColor}
        d="M15.519,23v.86a.852.852,0,0,0,.86.86H9.5a.852.852,0,0,0,.86-.86V23Z"
        transform="translate(6.837 16.243)"
      />
      <path
        id="Caminho_13"
        data-name="Caminho 13"
        fill={iconColor}
        d="M12.3,5a.86.86,0,1,1,0,1.72H8.86A.86.86,0,0,1,8.86,5Z"
        transform="translate(5.758 3.288)"
      />
      <path
        id="Caminho_14"
        data-name="Caminho 14"
        fill={iconColor}
        d="M13.159,8.86a.852.852,0,0,1-.86.86H8.86A.86.86,0,0,1,8.86,8H12.3A.852.852,0,0,1,13.159,8.86Z"
        transform="translate(5.758 5.447)"
      />
      <path
        id="Caminho_15"
        data-name="Caminho 15"
        fill={iconColor}
        d="M12.3,11a.86.86,0,1,1,0,1.72H8.86a.86.86,0,1,1,0-1.72Z"
        transform="translate(5.758 7.606)"
      />
      <path
        id="Caminho_16"
        data-name="Caminho 16"
        fill={iconColor}
        d="M12.3,14a.86.86,0,1,1,0,1.72H8.86a.86.86,0,1,1,0-1.72Z"
        transform="translate(5.758 9.766)"
      />
      <path
        id="Caminho_17"
        data-name="Caminho 17"
        fill={iconColor}
        d="M8.159,14.86a.852.852,0,0,1-.86.86H3.86a.86.86,0,1,1,0-1.72H7.3A.852.852,0,0,1,8.159,14.86Z"
        transform="translate(2.159 9.766)"
      />
      <path
        id="Caminho_18"
        data-name="Caminho 18"
        fill={iconColor}
        d="M7.3,5a.86.86,0,0,1,0,1.72H3.86A.86.86,0,1,1,3.86,5Z"
        transform="translate(2.159 3.288)"
      />
      <path
        id="Caminho_19"
        data-name="Caminho 19"
        fill={iconColor}
        d="M7.3,8a.86.86,0,0,1,0,1.72H3.86A.86.86,0,0,1,3.86,8Z"
        transform="translate(2.159 5.447)"
      />
      <path
        id="Caminho_20"
        data-name="Caminho 20"
        fill={iconColor}
        d="M7.3,11a.86.86,0,1,1,0,1.72H3.86a.86.86,0,1,1,0-1.72Z"
        transform="translate(2.159 7.606)"
      />
      <path
        id="Caminho_21"
        data-name="Caminho 21"
        fill={iconColor}
        d="M12.6,19.58a.862.862,0,0,0-.86-.86H6.58a.852.852,0,0,0-.86.86v7.739H4V19.58A2.576,2.576,0,0,1,6.58,17h5.159a2.576,2.576,0,0,1,2.58,2.58v7.739H12.6Z"
        transform="translate(2.879 11.925)"
      />
      <path
        id="Caminho_22"
        data-name="Caminho 22"
        fill={iconColor}
        d="M5.72,23h8.6v.86a.852.852,0,0,1-.86.86H4.86A.852.852,0,0,1,4,23.86V23Z"
        transform="translate(2.879 16.243)"
      />
      <path
        id="Caminho_23"
        data-name="Caminho 23"
        fill={iconColor}
        d="M3.01.214l18.9,2.872a2.57,2.57,0,0,1,2.167,2.545V15.5l-.688-.138a.9.9,0,0,0-.722.172.914.914,0,0,0-.31.671V5.631a.869.869,0,0,0-.722-.86L2.734,1.917A.476.476,0,0,0,2.58,1.9a.811.811,0,0,0-.55.206.822.822,0,0,0-.31.653v35.8a.862.862,0,0,0,.86.86h4.3v.86a.852.852,0,0,0,.86.86H2.58A2.576,2.576,0,0,1,0,38.564V2.759A2.532,2.532,0,0,1,.911.8,2.581,2.581,0,0,1,3.01.214Z"
        transform="translate(0 -0.181)"
      />
    </svg>
  );
}
