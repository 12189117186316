import React, { useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  Card,
  CardContent,
  TextField,
  Tooltip,
  IconButton,
  Button,
  Menu,
  List,
  ListItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FolderIcon from "@material-ui/icons/Folder";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import UndoIcon from "@material-ui/icons/Undo";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ConfirmButton } from "components/Button";
import { ReactIf, ReactThen } from "components/ReactIf";

import { openDocumentWithUrlUtil } from "utils/openDocuments";

import { sendToAnalysis } from "services/business";
import {
  updatePending,
  createPending,
  uploadDocument,
  getUploadDocument,
} from "services/documentos/cadastro";

import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";

import { useModal } from "hooks/useModal";

import "../capa.scss";

export default (props) => {
  const {
    business,
    businessDocuments,
    businessPending,
    setBusinessPending,
    refreshBusinessData,
  } = props;

  const { isAdmin, isFinanciador, canSelfUpdate } = useAuth();
  const { toast } = useToast();
  const { openModal } = useModal();

  const [openedBusinessAccordion, setOpenedBusinessAccordion] = useState(false);
  const [openedResponsiblesAccordion, setOpenedResponsiblesAccordion] =
    useState(false);
  const [openedIncludedDocsAccordion, setOpenedIncludedDocsAccordion] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const businessFinancierDocTypes = [
    {
      title: "Documentos de Constituição",
      description:
        "Se sua empresa se enquadra como EIRELI ou LTDA aqui você precisará incluir o Contrato Social ou Requerimento de empresário, consolidado com a última alteração contratual (caso possua). Para empresas SA precisamos do Estatuto e Ata de Eleição da Diretoria, ambos atualizados.",
      documentTypeId: 1,
    },
    {
      title: "Comprovante de endereço da sede",
      description:
        "Incluir um comprovante de endereço da sede da empresa emitido nos últimos 90 dias. São aceitas contas de água, luz, telefone fixo ou gás, em nome da empresa ou de sócio. Em caso do imóvel ser alugado, necessário enviar contrato de locação e comprovante de endereço em nome do proprietário do imóvel.",
      documentTypeId: 4,
    },
    {
      title: "Declaração simplificada da junta",
      description:
        "Adicionar Declaração Simplificada da Junta Comercial que é o documento que pode ser encontrado no site da Junta Comercial de seu estado. Caso tenha dificuldade em encontrar este documento, peça ajuda ao seu contador.",
      documentTypeId: 11,
    },
  ];

  const businessDocTypes = [
    {
      title: "Documentos de Constituição",
      description:
        "Se sua empresa se enquadra como EIRELI ou LTDA aqui você precisará incluir o Contrato Social ou Requerimento de empresário, consolidado com a última alteração contratual (caso possua). Para empresas SA precisamos do Estatuto e Ata de Eleição da Diretoria, ambos atualizados.",
      documentTypeId: 1,
    },
    {
      title: "Declaração de faturamento",
      description:
        "Para empresas simples é possível gerar declaração de faturamento (PGDAS-D) pelo site do Simples Nacional da Receita Federal, basta fazer o download e adicioná-la aqui. Se você não se enquadra na opção acima será necessário um documento que expõe o faturamento da sua empresa nos últimos 12 meses com assinatura do sócio, assinatura e carimbo do contador. Caso tenha dificuldade em encontrar este documento, peça ajuda ao seu contador.",
      documentTypeId: 5,
    },
    {
      title: "Comprovante de endereço da sede",
      description:
        "Incluir um comprovante de endereço da sede da empresa emitido nos últimos 90 dias. São aceitas contas de água, luz, telefone fixo ou gás, em nome da empresa ou de sócio. Em caso do imóvel ser alugado, necessário enviar contrato de locação e comprovante de endereço em nome do proprietário do imóvel.",
      documentTypeId: 4,
    },
    {
      title: "Extrato bancário",
      description:
        "Inclua o extrato, da conta corrente da sua empresa, gerado pelo Banco de maior movimentação, no formato pdf, com os últimos 2 meses de transações.",
      documentTypeId: 13,
    },
    {
      title: "Declaração simplificada da junta",
      description:
        "Adicionar Declaração Simplificada da Junta Comercial que é o documento que pode ser encontrado no site da Junta Comercial de seu estado. Caso tenha dificuldade em encontrar este documento, peça ajuda ao seu contador.",
      documentTypeId: 11,
    },
  ];

  const responsiblesDocTypes = [
    {
      title: "Documento de identificação",
      description:
        "Foto ou cópia do RG e CPF ou CNH vigente (não pode estar vencida) ou documento de órgão de classe (OAB, CREA) dos sócios administradores e procuradores. Necessário ser legível.",
      documentTypeId: 15,
    },
    {
      title: "Comprovante de endereço",
      description:
        "Procuradores envolvidos (se houver procurador). Necessário ter sido emitido nos últimos 90 dias. São aceitas contas de água, luz, telefone fixo ou gás em nome dos sócios, procuradores ou parentes em primeiro grau (mediante comprovação de parentesco).",
      documentTypeId: 7,
    },
    {
      title: "Documento de Procuração",
      description:
        "No caso de possui procurador é necessário incluir a procuração que dá poder para essa pessoa responder pela empresa. Necessário ser válida (dentro da data de vigência).",
      documentTypeId: 12,
    },
  ];

  const analysisDocTypes = [
    {
      documentTypeId: 0,
      title: "Análise e Relatório",
      description:
        "Documentos incluídos nesta seção são enviados automaticamente ao dossiê.",
    },
    {
      documentTypeId: 55,
      title: "Vídeos",
      description:
        "Documentos incluídos nesta seção são enviados automaticamente ao dossiê.",
    },
  ];

  const handleSendToAnalysis = async () => {
    await toast.promise(sendToAnalysis(business.businessId), {
      loading: "Enviando cadastro para análise",
      success: "Cadastro enviado para análise com sucesso!",
      error: "Erro ao enviar cadastro para análise",
    });

    window.location.reload();
  };

  const updateDocumentStatus = async (document, documents, setDocuments) => {
    try {
      await updatePending(document);

      const updatedDocs = documents.map((doc) => {
        if (doc.fileId === document.fileId) return document;
        return doc;
      });
      setDocuments(updatedDocs);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const updateDocument = async (document, documents, setDocuments) => {
    try {
      setIsLoading(true);
      const promises = [refreshBusinessData(business), updatePending(document)];

      await toast.promise(Promise.all(promises), {
        loading: "Atualizando dados",
        success: "Atualizado com sucesso!",
        error: "Erro ao atualizar!",
      });

      const updatedDocs = documents.map((doc) => {
        if (doc.fileId === document.fileId) return document;
        return doc;
      });
      setDocuments(updatedDocs);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const includePending = async (businessId, documentTypeName) => {
    try {
      const response = await createPending(businessId, documentTypeName);
      setBusinessPending([...businessPending, response.data]);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const ModifyDescriptionModal = (props) => {
    const {
      open,
      handleClose,
      initialDescripiton,
      document,
      documents,
      setDocuments,
    } = props;
    const [newDescription, setNewDescription] = useState(
      initialDescripiton ?? ""
    );

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Alterar Descrição</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Descrição do Documento"
            type="email"
            fullWidth
            value={newDescription || document.name}
            onChange={(e) => setNewDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              const updatedDoc = document;
              updatedDoc.description = newDescription;
              updateDocument(updatedDoc, documents, setDocuments);
              handleClose();
            }}
            color="primary"
          >
            Alterar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const IncludePendingModal = (props) => {
    const { open, handleClose, businessId } = props;
    const [newDescription, setNewDescription] = useState("");

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Inserir Nova Tag</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nova Tag"
            type="email"
            fullWidth
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              includePending(businessId, newDescription);
              handleClose();
            }}
            color="primary"
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const UploadButton = ({
    typeId,
    typeName,
    uploadDocuments,
    pendingId,
    analise,
  }) => (
    <>
      <Tooltip
        placement="right"
        style={{ position: "unset", marginTop: 12, marginLeft: 4 }}
        title="Anexar Documento"
        aria-label="attach-file"
        className="card-tr-actions"
      >
        <div className="">
          <input
            accept={
              typeId == 55
                ? "video/*"
                : "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
            }
            className="d-none"
            id={`icon-button-file-${typeId}${
              typeName && typeName.replaceAll(" ", "-")
            }${pendingId !== undefined ? pendingId : ""}${
              analise !== undefined ? "analise" : ""
            }`}
            type="file"
            multiple
            onChange={(e) => {
              uploadDocuments(e.target.files, typeId, typeName, pendingId);
            }}
          />
          <label
            htmlFor={`icon-button-file-${typeId}${
              typeName && typeName.replaceAll(" ", "-")
            }${pendingId !== undefined ? pendingId : ""}${
              analise !== undefined ? "analise" : ""
            }`}
          >
            <IconButton
              color="primary"
              aria-label="attach-file"
              component="span"
            >
              <AttachFileIcon />
            </IconButton>
          </label>
        </div>
      </Tooltip>
    </>
  );

  const AccordionSection = ({
    title,
    docTypes,
    businessDocuments,
    pending,
  }) => {
    // const [documents, setDocuments] = useState(businessDocuments);
    const documents = businessDocuments;
    const [openedAccordion, setOpenedAccordion] = useState(false);
    return (
      <>
        {docTypes !== undefined && (
          <Accordion
            className="w-100"
            defaultExpanded={
              title === "Da Empresa:" || title === "Dos Sócios e Procuradores:"
            }
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="w-100"
              onClick={() => {
                setOpenedAccordion(!openedAccordion);
              }}
            >
              <h4>{title}</h4>
            </AccordionSummary>
            <AccordionDetails className="d-flex flex-column">
              {docTypes.map((docType) => (
                <DocumentAccordion
                  key={pending ? docType.pendingId : docType.documentTypeId}
                  docType={{
                    ...docType,
                    documentTypeId: pending ? 0 : docType.documentTypeId,
                    title: pending ? docType.typeName : docType.title,
                  }}
                  documents={documents}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        )}
      </>
    );
  };

  const AnalysisAccordionSection = ({ title, docTypes, businessDocuments }) => {
    // const [documents, setDocuments] = useState(businessDocuments);
    const documents = businessDocuments;
    const [openedAccordion, setOpenedAccordion] = useState(false);

    return (
      <Accordion className="w-100">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="w-100"
          onClick={() => {
            setOpenedAccordion(!openedAccordion);
          }}
        >
          <h4>{title}</h4>
        </AccordionSummary>
        <AccordionDetails className="d-flex flex-column">
          {docTypes.map((docType) => (
            <DocumentAccordion
              key={docType.documentTypeId}
              docType={docType}
              documents={documents}
              analise
            />
          ))}
        </AccordionDetails>
      </Accordion>
    );
  };

  const DocumentAccordion = (props) => {
    const { docType, documents, analise } = props;

    const [thisAccordionOpened, setThisAccordionOpened] = useState(false);
    const [accordionDocs, setAccordionDocs] = useState(documents);

    // handler progresso de upload de documentos
    const handleUploadProgress = (percentComplete) => {
      if (percentComplete === 0) return;
      toast(`Enviando arquivo - ${percentComplete}%`, {
        id: "percentComplete",
      });
    };

    const countByStatusId = (statusId) =>
      accordionDocs
        .filter((doc) =>
          docType.pendingId === undefined || docType.pendingId === null
            ? doc.documentTypeId === docType.documentTypeId &&
              Number(doc.originTypeId) === 1
            : doc.pendingId === docType.pendingId
        )
        .filter((doc) => Number(doc.statusId) === statusId).length;

    const uploadDocuments = async (files, typeId, typeName, pendingId) => {
      const formData = new FormData();

      Object.values(files).forEach((file) => formData.append("files", file));
      formData.append("originId", business.businessId);
      formData.append("documentTypeId", typeId);
      formData.append("documentTypeName", typeName);
      if (pendingId !== undefined) formData.append("pendingId", pendingId);
      formData.append("originTypeId", 1);
      formData.append("originTypeName", "Dossiê Cedente");

      try {
        setIsLoading(true);
        const promises = [
          refreshBusinessData(business),
          uploadDocument(formData, handleUploadProgress),
        ];

        await toast.promise(Promise.all(promises), {
          loading: `Enviando documento`,
          success: "Documento enviado com sucesso",
          error: "Erro ao enviar documento",
        });

        const response = await toast.promise(
          getUploadDocument(business.businessId),
          {
            loading: "Carregando documentos",
            success: "Documentos carregados com sucesso",
            error: "Erro ao carregar documentos",
          }
        );
        setAccordionDocs(response);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <Accordion className="w-100">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="w-100 d-flex flex-row align-items-center justify-content-center"
          style={{ position: "relative" }}
          onClick={() => {
            setThisAccordionOpened(!thisAccordionOpened);
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            onFocus={(e) => e.stopPropagation()}
            className="d-flex flex-row align-items-center"
          >
            <div style={{ marginTop: 12 }}>
              <Tooltip
                placement="top"
                style={{ position: "unset" }}
                title="Aguardando Aprovação"
                aria-label="Aguardando Aprovação"
                className="card-tr-actions"
              >
                <div className="px-3 mx-1 badge badge-warning">
                  {countByStatusId(1)}
                </div>
              </Tooltip>
              <Tooltip
                placement="top"
                style={{ position: "unset" }}
                title="Aprovados"
                aria-label="Aprovados"
                className="card-tr-actions"
              >
                <div className="px-3 mx-1 badge badge-success">
                  {countByStatusId(2)}
                </div>
              </Tooltip>
              <Tooltip
                placement="top"
                style={{ position: "unset" }}
                title="Reprovados"
                aria-label="Reprovados"
                className="card-tr-actions"
              >
                <div className="px-3 mx-1 badge badge-danger">
                  {countByStatusId(3)}
                </div>
              </Tooltip>
              {isAdmin() && (
                <Tooltip
                  placement="top"
                  style={{ position: "unset" }}
                  title="Excluidos"
                  aria-label="Excluidos"
                  className="card-tr-actions"
                >
                  <div className="px-3 mx-1 badge badge-dark ">
                    {countByStatusId(6)}
                  </div>
                </Tooltip>
              )}
            </div>
            <h5 style={{ marginTop: 12, marginLeft: 4 }}>{docType.title}:</h5>
            {/* <Badge color="primary" className="px-3">
                {countByStatusId(6)}
              </Badge> */}

            {docType.description && (
              <Tooltip
                placement="right"
                style={{ position: "unset", marginTop: 12, marginLeft: 4 }}
                title={docType.description}
                aria-label={docType.title}
                className="card-tr-actions"
              >
                <InfoIcon />
              </Tooltip>
            )}
            <ReactIf condition={canSelfUpdate(business.statusId, true)}>
              <ReactThen>
                <UploadButton
                  typeId={docType.documentTypeId}
                  typeName={docType.title}
                  uploadDocuments={uploadDocuments}
                  pendingId={docType.pendingId}
                  analise={analise}
                />
              </ReactThen>
            </ReactIf>
          </div>
        </AccordionSummary>
        <AccordionDetails className="d-flex flex-column">
          {accordionDocs !== undefined &&
            accordionDocs
              .filter((doc) =>
                docType.pendingId === undefined || docType.pendingId === null
                  ? doc.documentTypeId === docType.documentTypeId &&
                    Number(doc.originTypeId) === 1
                  : doc.pendingId === docType.pendingId
              )
              .filter((doc) => isAdmin() || Number(doc.statusId) !== 6)
              .sort((a, b) => Number(a.statusId) - Number(b.statusId))
              .map((doc) => (
                <DocumentCard
                  key={doc.fileId}
                  document={doc}
                  documents={accordionDocs}
                  setDocuments={setAccordionDocs}
                />
              ))}
        </AccordionDetails>
      </Accordion>
    );
  };

  const DocumentCard = (props) => {
    const { document, documents, setDocuments } = props;

    // const [documentState, setDocumentState] = useState(document);
    const documentState = document;
    const [anchorElMenu4, setAnchorElMenu4] = useState(null);
    const [descriptionDialogOpen, setDescriptionDialogOpen] = useState(false);

    const handleOpenMenu = (e) => {
      setAnchorElMenu4(e.currentTarget);
    };

    const handleCloseMenu4 = () => {
      setAnchorElMenu4(null);
    };

    const handleCloseDescriptionDialog = () => {
      setDescriptionDialogOpen(false);
    };

    return (
      <>
        <Card className="card-box mb-4">
          {Number(documentState.statusId) === 1 ? (
            <div className="card-indicator bg-warning" />
          ) : Number(documentState.statusId) === 2 ? (
            Number(document.inDossie) === 1 ? (
              <div className="card-indicator bg-info" />
            ) : (
              <div className="card-indicator bg-success" />
            )
          ) : Number(documentState.statusId) === 3 ? (
            <div className="card-indicator bg-danger" />
          ) : Number(documentState.statusId) === 6 ? (
            <div className="card-indicator bg-first" />
          ) : (
            <></>
          )}
          <CardContent className="px-4 py-3 d-flex flex-row align-items-center justify-content-between">
            <div>
              <div className="pb-3 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  {Number(documentState.statusId) === 1 ? (
                    <div
                      className="px-3 badge badge-warning"
                      style={{ width: "100% !", cursor: "default" }}
                    >
                      {documentState.statusName}
                    </div>
                  ) : Number(documentState.statusId) === 2 ? (
                    Number(document.inDossie) === 1 ? (
                      <div
                        className="px-3 badge badge-info"
                        style={{ width: "100% !", cursor: "default" }}
                      >
                        Dossiê
                      </div>
                    ) : (
                      <div
                        className="px-3 badge badge-success"
                        style={{ width: "100% !", cursor: "default" }}
                      >
                        {documentState.statusName}
                      </div>
                    )
                  ) : Number(documentState.statusId) === 3 ? (
                    <div
                      className="px-3 badge badge-danger"
                      style={{ width: "100% !", cursor: "default" }}
                    >
                      {documentState.statusName}
                    </div>
                  ) : Number(documentState.statusId) === 6 ? (
                    <Badge
                      color="primary"
                      className="px-3 badge badge-dark"
                      style={{ width: "100% !", cursor: "default" }}
                    >
                      {documentState.statusName}
                    </Badge>
                  ) : (
                    <></>
                  )}
                  <a
                    href={documentState.url}
                    target="_blank"
                    className="ml-3"
                    rel="noreferrer"
                  >
                    {documentState.description === null ||
                    documentState.description === undefined
                      ? documentState.name
                      : documentState.description}
                  </a>
                </div>
              </div>
              <div className="d-flex flex-column align-items-start justify-content-center">
                <div className="font-size-sm pt-2">
                  {documentState.name} -{" "}
                  {(Number(documentState.size) / 1024 / 1024).toFixed(2)} MB
                </div>
                <div className="font-size-sm pt-2">
                  <FontAwesomeIcon icon={["far", "clock"]} className="mr-1" />
                  {new Date(documentState.registerDate).toLocaleString("pt-BR")}
                </div>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center">
              {isAdmin() && Number(documentState.statusId) === 1 ? (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <Button
                    variant="outlined"
                    className="m-2 btn text-success w-100"
                    style={{ borderColor: "green" }}
                    onClick={() => {
                      const updatedDoc = documentState;
                      updatedDoc.statusId = 2;
                      updatedDoc.statusName = "Aprovado";
                      updateDocumentStatus(updatedDoc, documents, setDocuments);
                    }}
                  >
                    Aprovar
                  </Button>
                  <Button
                    variant="outlined"
                    className="m-2 btn text-danger w-100"
                    style={{ borderColor: "red" }}
                    onClick={() => {
                      const updatedDoc = documentState;
                      updatedDoc.statusId = 3;
                      updatedDoc.statusName = "Reprovado";
                      updateDocumentStatus(updatedDoc, documents, setDocuments);
                    }}
                  >
                    Reprovar
                  </Button>
                </div>
              ) : isAdmin() &&
                Number(documentState.statusId) === 2 &&
                (documentState.inDossie !== null ||
                  documentState.inDossie !== undefined) &&
                Number(documentState.inDossie) === 0 ? (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <Button
                    variant="contained"
                    className="m-2 btn text-white"
                    style={{ backgroundColor: "#373A44" }}
                    onClick={() => {
                      const updatedDoc = documentState;
                      updatedDoc.inDossie = 1;
                      updateDocument(updatedDoc, documents, setDocuments);
                    }}
                  >
                    Enviar para o Dossiê
                  </Button>
                </div>
              ) : (
                <></>
              )}
              <div>
                <IconButton
                  aria-label="menu"
                  className="m-2"
                  onClick={(e) => handleOpenMenu(e)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorElMenu4}
                  keepMounted
                  open={Boolean(anchorElMenu4)}
                  onClose={handleCloseMenu4}
                  classes={{ list: "p-0" }}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className="overflow-hidden dropdown-menu-xl">
                    <List className="flex-column">
                      {isAdmin() && Number(documentState.statusId) !== 1 && (
                        <ListItem
                          button
                          onClick={() => {
                            const updatedDoc = documentState;
                            updatedDoc.statusId = 1;
                            updatedDoc.inDossie = 0;
                            updatedDoc.statusName = "Aguardando Aprovação";
                            updateDocument(updatedDoc, documents, setDocuments);
                          }}
                        >
                          <UndoIcon />
                          <span className="ml-2">Desfazer Ação</span>
                        </ListItem>
                      )}
                      {isAdmin() && (
                        <ListItem
                          button
                          onClick={() => {
                            setDescriptionDialogOpen(true);
                            handleCloseMenu4();
                          }}
                        >
                          <EditIcon />
                          <span className="ml-2">Alterar Descrição</span>
                        </ListItem>
                      )}
                      <ListItem
                        button
                        onClick={() => {
                          const { url } = documentState;
                          openDocumentWithUrlUtil(url);
                          handleCloseMenu4();
                        }}
                      >
                        <FolderIcon />
                        <span className="ml-2">Abrir</span>
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => {
                          window.open(documentState.downloadUrl, "blank");
                          handleCloseMenu4();
                        }}
                      >
                        <SaveAltIcon />
                        <span className="ml-2">Baixar</span>
                      </ListItem>
                      <Divider />
                      {isAdmin() ||
                        (isFinanciador() && (
                          <ListItem
                            button
                            onClick={() => {
                              const updatedDoc = documentState;
                              updatedDoc.statusId = 6;
                              updatedDoc.statusName = "Excluido";
                              updateDocumentStatus(
                                updatedDoc,
                                documents,
                                setDocuments
                              );
                            }}
                          >
                            <DeleteIcon />
                            <span className="ml-2">Excluir</span>
                          </ListItem>
                        ))}
                    </List>
                  </div>
                </Menu>
              </div>
            </div>
          </CardContent>
        </Card>
        <ModifyDescriptionModal
          open={descriptionDialogOpen}
          handleClose={handleCloseDescriptionDialog}
          initialDescripiton={documentState.description}
          document={documentState}
          documents={documents}
          setDocuments={setDocuments}
        />
      </>
    );
  };

  const IncludePendingButton = () => {
    const [includePendingDialogOpen, setIncludePendingDialogOpen] =
      useState(false);
    const handleCloseIncludePendingDialog = () => {
      setIncludePendingDialogOpen(false);
    };

    return (
      <>
        <Button
          variant="contained"
          className="m-2 btn text-white"
          style={{ backgroundColor: "#373A44" }}
          onClick={() => {
            setIncludePendingDialogOpen(true);
          }}
        >
          Incluir Pendência
        </Button>
        <IncludePendingModal
          open={includePendingDialogOpen}
          handleClose={handleCloseIncludePendingDialog}
          businessId={business.businessId}
        />
      </>
    );
  };

  return (
    <>
      {!isLoading && (
        <div className="w-100">
          <div className="searchForm">
            <div className="containerTitle w-100 mt-4">
              <div className="itemTile d-flex flex-row justify-content-between align-items-center w-100">
                <h3 style={{ fontFamily: "Poppins, sans-serif" }}>
                  Documentos Necessários
                </h3>
                {isAdmin() ? <IncludePendingButton /> : <div />}
              </div>
            </div>
            {businessDocuments !== undefined && (
              <div>
                <AccordionSection
                  title="Da Empresa:"
                  docTypes={
                    isFinanciador()
                      ? businessFinancierDocTypes
                      : businessDocTypes
                  }
                  businessDocuments={businessDocuments}
                  openedAccordion={openedBusinessAccordion}
                  setOpenedAccordion={setOpenedBusinessAccordion}
                />
                <AccordionSection
                  title="Dos Sócios e Procuradores:"
                  docTypes={responsiblesDocTypes}
                  businessDocuments={businessDocuments}
                  openedAccordion={openedResponsiblesAccordion}
                  setOpenedAccordion={setOpenedResponsiblesAccordion}
                />
                <AccordionSection
                  title="Documentos Incluidos:"
                  docTypes={businessPending}
                  businessDocuments={businessDocuments}
                  openedAccordion={openedIncludedDocsAccordion}
                  setOpenedAccordion={setOpenedIncludedDocsAccordion}
                  pending
                />
              </div>
            )}
          </div>
          <ReactIf condition={isAdmin()}>
            <ReactThen>
              <div className="searchForm" style={{ marginTop: "30px" }}>
                <div className="containerTitle w-100 mt-4">
                  <div className="itemTile d-flex flex-row justify-content-between align-items-center w-100">
                    <h3 style={{ fontFamily: "Poppins, sans-serif" }}>
                      Documentos de Análise
                    </h3>
                  </div>
                </div>
                {businessDocuments !== undefined && (
                  <AnalysisAccordionSection
                    title="Documentos Incluidos:"
                    docTypes={analysisDocTypes}
                    businessDocuments={businessDocuments}
                    openedAccordion={openedIncludedDocsAccordion}
                    setOpenedAccordion={setOpenedIncludedDocsAccordion}
                  />
                )}
              </div>
            </ReactThen>
          </ReactIf>
          <ReactIf condition={business.statusId < 6 && !isAdmin()}>
            <ReactThen>
              <div
                className="item itemPicker"
                style={{ paddingRight: "2.5%", marginTop: "2.5%" }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <ConfirmButton
                    onClick={async () => {
                      await openModal(
                        "warning",
                        "Atenção!",
                        "Tem certeza que deseja enviar cadastro para análise?",
                        handleSendToAnalysis
                      );
                    }}
                    disabled={!canSelfUpdate(business?.statusId, true)}
                  >
                    {!canSelfUpdate(business?.statusId, true)
                      ? "Em análise"
                      : "Enviar para análise"}
                  </ConfirmButton>
                </div>
              </div>
            </ReactThen>
          </ReactIf>
        </div>
      )}
    </>
  );
};
