import React, { useState, useEffect } from "react";

import "../capa.scss";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  LinearProgress,
  Card,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { ConfirmButton } from "components/Button";
import { ReactIf, ReactThen } from "components/ReactIf";

import formatCep from "utils/formatCEP";
import formatCnpj from "utils/formatCNPJ";
import formatTelefone from "utils/formatTelefone";
import validateCep from "utils/validateCEP";

import { updateBusinessService } from "services/business";
import { getBigBoostCnpj } from "services/business/";
import { get } from "services/cep";
import { getQuiz } from "services/quiz";

import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";

import { useModal } from "hooks/useModal";

import { status } from "./items";
import AxiosInterceptor from "utils/axios-interceptor";

const DadosComerciaisCedente = (props) => {
  const { businessInitial, setBusinessInitial, businessTypes, refreshData } =
    props;

  const { toast } = useToast();
  const { isAdmin, isFinanciador, canSelfUpdate, isCedente } = useAuth();
  const { openModal, closeModal } = useModal();

  const [cepatual, setCepAtual] = useState("000");
  const [cepatualOperacao, setCepAtualOperacao] = useState("000");
  const [isLoading, setIsLoading] = useState(false);
  const [business, setBusiness] = useState(undefined);
  const { buyerTypes, businessSizes, taxRules, constitutions } = businessTypes;

  const fillRegisterData = async (cnpj) => {
    try {
      setIsLoading(true);
      const quiz = await getQuiz(cnpj);
      const bigBoostBusinessData = await toast.promise(getBigBoostCnpj(cnpj), {
        loading: "Preenchendo campos do cadastro",
        success: "Campos preenchidos com sucesso!",
        error: "Erro ao preencher campos",
      });
      const bigBoostBusiness = bigBoostBusinessData.result.value;
      const businessCnae =
        bigBoostBusinessData.info.external_sources[1].data.Result[0].BasicData.Activities.find(
          (activity) => activity.IsMain
        );

      const filledBusiness = {
        ...businessInitial,
        corporateName: bigBoostBusiness.Razao_Social,
        name: bigBoostBusiness.Nome_Fantasia,
        addressZipCode: formatCep(bigBoostBusiness.CEP),
        addressStreet: bigBoostBusiness.Logradouro,
        addressCity: bigBoostBusiness.Cidade,
        addressDistrict: bigBoostBusiness.Bairro,
        addressState: bigBoostBusiness.Estado,
        addressComplement: bigBoostBusiness.Complemento,
        addressNumber: bigBoostBusiness.Numero,
        codeActivity: businessCnae ? businessCnae.Code : "",
        activity: businessCnae ? businessCnae.Activity : "NÃO INFORMADO",
        email: quiz.statusQuiz.Email,
        mobilePhone: quiz.statusQuiz.Telefone,
        officePhone: quiz.statusQuiz.Telefone,
      };

      await toast.promise(updateBusinessService(filledBusiness), {
        loading: "Preenchendo campos do cadastro",
        success: "Campos preenchidos com sucesso!",
        error: "Erro ao preencher campos",
      });
      setBusiness(filledBusiness);
      setBusinessInitial(filledBusiness);
      setIsLoading(false);
    } catch (error) {
      setBusiness(businessInitial);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      canSelfUpdate(businessInitial?.statusId) &&
      !businessInitial.corporateName &&
      !businessInitial.name
    ) {
      fillRegisterData(businessInitial.countryIdentity);
    } else {
      setBusiness(businessInitial);
    }
  }, [setBusinessInitial]);

  const propertyStatuses = [
    { statusId: 1, statusDescription: "Alugado" },
    { statusId: 2, statusDescription: "Próprio" },
    { statusId: 3, statusDescription: "Financiado" },
  ];

  const updateBusiness = async (updateOption) => {
    if (isLoading) return;
    setIsLoading(true);
    openModal("loading");
    if (business === undefined) return;
    let updatedBusiness = {};
    if (updateOption === "save") {
      updatedBusiness = {
        ...businessInitial,
        countryIdentity: business.countryIdentity,
        corporateName: business.corporateName,
        name: business.name,
        email: business.email,
        mobilePhone: business.mobilePhone,
        addressZipCode: business.addressZipCode,
        addressStreet: business.addressStreet,
        addressNumber: business.addressNumber,
        addressComplement: business.addressComplement,
        addressDistrict: business.addressDistrict,
        addressCity: business.addressCity,
        addressState: business.addressState,
        buyerTypeId: business.buyerTypeId,
        businessSizeId: business.businessSizeId,
        taxRuleId: business.taxRuleId,
        constitutionId: business.constitutionId,
        netWorth: business.netWorth,
        shareCapital: business.shareCapital,
      };
    } else if (updateOption === "status") {
      updatedBusiness = {
        ...businessInitial,
        typeId: business.typeId,
        typeName: business.typeName,
        motiveId: business.motiveId,
        motiveName: business.motiveName,
        note: business.note,
      };
    } else if (updateOption === "all") {
      updatedBusiness = {
        ...businessInitial,
        ...business,
      };
    }

    updatedBusiness.buyerType = undefined;
    updatedBusiness.businessSize = undefined;
    updatedBusiness.taxRule = undefined;
    updatedBusiness.constitution = undefined;

    await toast.promise(apiUpdateBusiness(business, updatedBusiness), {
      loading: "Atualizando dados",
      success: "Dados atualizados",
      error: "Erro ao atualizar dados",
    });

    await toast.promise(refreshData(), {
      loading: "Recarregando dados",
      success: "Dados recarregados",
      error: "Erro ao recarregar dados",
    });

    closeModal();
    setIsLoading(false);
    if (isCedente()) {
      openModal(
        "info",
        "Atenção!",
        "Vá para a próxima página para continuar o cadastro."
      );
    }
  };

  const copyFromRegisterAddress = () => {
    setBusiness({
      ...business,
      operationAddressStreet: business.addressStreet,
      operationAddressNumber: business.addressNumber,
      operationAddressComplement: business.addressComplement,
      operationAddressDistrict: business.addressDistrict,
      operationAddressCity: business.addressCity,
      operationAddressState: business.addressState,
      operationAddressZipCode: business.addressZipCode,
    });
  };

  useEffect(() => {
    if (!business?.addressZipCode) return;
    if (business?.addressZipCode.length < 9) return;
    if (
      validateCep(business?.addressZipCode) &&
      business?.addressZipCode != cepatual
    ) {
      buscarDadosCEP();
    }
  }, [business?.addressZipCode]);

  useEffect(() => {
    if (!business?.operationAddressZipCode) return;
    if (business?.operationAddressZipCode.length < 9) return;
    if (
      validateCep(business?.operationAddressZipCode) &&
      business?.operationAddressZipCode != cepatualOperacao
    ) {
      buscarDadosCEPOperacao();
    }
  }, [business?.operationAddressZipCode]);

  async function buscarDadosCEP() {
    setIsLoading(true);

    const cep = business?.addressZipCode;

    await get(cep)
      .then((response) => response.json())
      .then((response) => {
        business.addressStreet = response.logradouro || business.addressStreet;
        business.addressCity = response.localidade || business.addressCity;
        business.addressDistrict = response.bairro || business.addressDistrict;
        business.addressState = response.uf || business.addressState;
        setCepAtual(business?.addressZipCode);
      });
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }

  async function buscarDadosCEPOperacao() {
    setIsLoading(true);
    const cep = business?.operationAddressZipCode;

    await get(cep)
      .then((response) => response.json())
      .then((response) => {
        business.operationAddressStreet = response.logradouro;
        business.operationAddressCity = response.localidade;
        business.operationAddressDistrict = response.bairro;
        business.operationAddressState = response.uf;
        setCepAtualOperacao(business?.operationAddressZipCode);
      });
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }

  const handlerChange = (e) => {
    setBusiness({ ...business, [e.target.name]: e.target.value });
  };

  function getStatusColor(business) {
    const statusColor = status.find(
      (item) => item.statusName == business.statusName
    );

    return statusColor ? statusColor.backgroundColor : "red";
  }

  return (
    <>
      {isLoading && <LinearProgress value={55} color="primary" />}
      <div className="w-100">
        <ReactIf
          condition={
            business && !isAdmin() && canSelfUpdate(business?.statusId)
          }
        >
          <ReactThen>
            <Card
              className="card-box p-4 mb-4"
              style={{ width: "95%", maxWidth: "95%", margin: "0 auto" }}
            >
              <div className="text-center">
                <div className="avatar-icon-wrapper rounded-circle m-0">
                  <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper text-success m-0 d-65 align-items-center">
                    <InfoOutlinedIcon
                      style={{
                        color: "#2196f3",
                        fontSize: 28,
                      }}
                    />
                  </div>
                </div>
                <h4
                  className="font-weight-bold"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "26px",
                    textAlign: "center",
                    color: "#663c00",
                  }}
                >
                  Preencha todas as informações do cadastro.
                </h4>
                <span>
                  A finalização dele garantirá uma maior agilidade em seu
                  atendimento.
                </span>
              </div>
            </Card>
          </ReactThen>
        </ReactIf>
        <ReactIf
          condition={business && business?.typeId === 1 && business?.note}
        >
          <ReactThen>
            <div
              style={{
                width: "95%",
                margin: "0 auto",
                backgroundColor: "#f44336",
                color: "white",
                padding: 8,
                marginBottom: 8,
                fontWeight: "bold",
                borderRadius: 5,
              }}
            >
              {business?.note}
            </div>
          </ReactThen>
        </ReactIf>
        {business && (
          <div className="searchForm">
            <div className="containerTitle w-100 mt-4 align-items-center">
              <div className="itemTile d-flex flex-row justify-content-between w-100">
                <h3
                  style={{
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Empresa
                </h3>
                <div
                  style={{
                    cursor: "default",
                    backgroundColor: getStatusColor(business),
                    color: "#FFF",
                    height: "30px",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    borderRadius: "180px",
                    minWidth: "270px",
                    maxWidth: "360px",
                    fontWeight: 500,
                  }}
                >
                  {business?.statusName}
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between w-100">
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="CNPJ*"
                  name="countryIdentity"
                  disabled={
                    isFinanciador() ||
                    (business.countryIdentity != null &&
                      business.countryIdentity.length > 19) ||
                    !canSelfUpdate(business.statusId)
                  }
                  value={formatCnpj(business?.countryIdentity)}
                  variant="outlined"
                  onChange={handlerChange}
                  inputProps={{ maxLength: 18 }}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Razão Social*"
                  disabled={
                    isFinanciador() ||
                    (business.corporateName != null &&
                      business.corporateName.length > 70) ||
                    !canSelfUpdate(business.statusId)
                  }
                  name="corporateName"
                  value={business?.corporateName}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Nome Fantasia*"
                  name="name"
                  disabled={
                    isFinanciador() ||
                    (business.name != null && business.name.length > 70) ||
                    !canSelfUpdate(business.statusId)
                  }
                  value={business?.name}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="E-mail*"
                  name="email"
                  disabled={
                    isFinanciador() ||
                    (business.email != null && business.email.length > 70) ||
                    !canSelfUpdate(business.statusId)
                  }
                  value={business?.email}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Telefone*"
                  name="officePhone"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={formatTelefone(business?.officePhone)}
                  variant="outlined"
                  onChange={handlerChange}
                  inputProps={{ maxLength: 14 }}
                />
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-4 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Site"
                  name="site"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.site}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Explique em poucas palavras o que sua empresa faz"
                  name="activityDescription"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.activityDescription}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
            </div>

            <div className="d-flex flex-row">
              <div className="item pr-2" style={{ width: "37.5%" }}>
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Cnae Principal"
                  name="codeActivity"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.codeActivity}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Descrição do Cnae"
                  name="activity"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.activity?.toUpperCase()}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
              {/* <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Patrimônio líquido R$:"
                  name="netWorth"
                  disabled
                  value={formatReal(business?.netWorth)}
                  variant="outlined"
                  onChange={handlerChange}
                  inputProps={{ maxLength: 24 }}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Capital Social R$:"
                  name="shareCapital"
                  disabled
                  value={formatReal(business?.shareCapital)}
                  variant="outlined"
                  onChange={handlerChange}
                  inputProps={{ maxLength: 24 }}
                />
              </div> */}
            </div>

            {/* Tipo da Empresa */}
            <div className="containerTitle mt-4">
              <div className="itemTile">
                <h3 style={{ fontFamily: "Poppins, sans-serif" }}>
                  Tipo da Empresa
                </h3>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="item pr-2 w-100 ">
                {business?.typeId === 2 && (
                  <div
                    className={`containerDatePicker w-100 d-flex flex-row align-items-center justify-content-"between"`}
                  >
                    <FormControl className="w-25 mt-2 mr-2 mb-2 ml-1 w-100">
                      <InputLabel
                        id="demo-simple-select-label"
                        className="ml-2 mt-2"
                      >
                        Tipo do Financiador
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        disabled={!canSelfUpdate(business.statusId)}
                        value={
                          business.buyerType === null ||
                          business.buyerType === undefined
                            ? ""
                            : business.buyerType.buyerTypeId
                        }
                        onChange={(e) => {
                          setBusiness({
                            ...business,
                            buyerTypeId: e.target.value,
                            buyerType: {
                              buyerTypeId: e.target.value,
                              buyerTypeName: buyerTypes.find(
                                (buyerType) =>
                                  buyerType.buyerTypeId === e.target.value
                              )?.buyerTypeName,
                            },
                          });
                        }}
                      >
                        <MenuItem value={0}> </MenuItem>
                        {buyerTypes.map((element) => (
                          <MenuItem
                            key={element.buyerTypeId}
                            value={element.buyerTypeId}
                          >
                            {element.buyerTypeName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}

                <div
                  className={`containerDatePicker w-100 d-flex flex-row align-items-center justify-content-"between"`}
                >
                  <FormControl className="w-25 mt-2 mr-2 mb-2 ml-1 w-100">
                    <InputLabel
                      id="demo-simple-select-label"
                      className="ml-2 mt-2"
                    >
                      Porte da Empresa
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      disabled={!canSelfUpdate(business.statusId)}
                      value={
                        business.businessSize === null ||
                        business.businessSize === undefined
                          ? ""
                          : business.businessSize.businessSizeId
                      }
                      onChange={(e) => {
                        setBusiness({
                          ...business,
                          businessSizeId: e.target.value,
                          businessSize: {
                            businessSizeId: e.target.value,
                            businessSizeName: businessSizes.find(
                              (businessSize) =>
                                businessSize.businessSizeId === e.target.value
                            )?.businessSizeName,
                          },
                        });
                      }}
                    >
                      <MenuItem value={0}> </MenuItem>
                      {businessSizes.map((element) => (
                        <MenuItem
                          key={element.businessSizeId}
                          value={element.businessSizeId}
                        >
                          {element.businessSizeName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div
                  className={`containerDatePicker w-100 d-flex flex-row align-items-center justify-content-"between"`}
                >
                  <FormControl className="w-25 mt-2 mr-2 mb-2 ml-1 w-100">
                    <InputLabel
                      id="demo-simple-select-label"
                      className="ml-2 mt-2"
                    >
                      Regime Tributário
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      disabled={!canSelfUpdate(business?.statusId)}
                      value={
                        business.taxRule === null ||
                        business.taxRule === undefined
                          ? ""
                          : business.taxRule.taxRuleId
                      }
                      onChange={(e) => {
                        setBusiness({
                          ...business,
                          taxRuleId: e.target.value,
                          taxRule: {
                            taxRuleId: e.target.value,
                            taxRuleName: taxRules?.find(
                              (taxRule) => taxRule.taxRuleId === e.target.value
                            )?.taxRuleName,
                          },
                        });
                      }}
                    >
                      <MenuItem> </MenuItem>
                      {taxRules?.map((element) => (
                        <MenuItem
                          key={element.taxRuleId}
                          value={element.taxRuleId}
                        >
                          {element.taxRuleName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div
                  className={`containerDatePicker w-100 d-flex flex-row align-items-center justify-content-"between"`}
                >
                  <FormControl className="w-25 mt-2 mr-2 mb-2 ml-1 w-100">
                    <InputLabel
                      id="demo-simple-select-label"
                      className="ml-2 mt-2"
                    >
                      Constituição
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      disabled={!canSelfUpdate(business.statusId)}
                      value={
                        business.constitution === null ||
                        business.constitution === undefined
                          ? ""
                          : business.constitution.constitutionId
                      }
                      onChange={(e) => {
                        setBusiness({
                          ...business,
                          constitutionId: e.target.value,
                          constitution: {
                            constitutionId: e.target.value,
                            constitutionName: constitutions.find(
                              (constitution) =>
                                constitution.constitutionId === e.target.value
                            )?.constitutionName,
                          },
                        });
                      }}
                    >
                      <MenuItem value={0}> </MenuItem>
                      {constitutions.map((element) => (
                        <MenuItem
                          key={element.constitutionId}
                          value={element.constitutionId}
                        >
                          {element.constitutionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            {/* Redes Sociais */}
            <div className="containerTitle mt-4">
              <div className="itemTile">
                <h3
                  style={{
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Redes Sociais
                </h3>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between w-100">
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Facebook"
                  name="facebook"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.facebook}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="LinkedIn"
                  name="linkedin"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.linkedin}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Instagram"
                  name="instagram"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.instagram}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
            </div>

            {/* Endereço de Registro */}
            <div className="containerTitle mt-4">
              <div className="itemTile">
                <h3
                  style={{
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Endereço de Registro da Empresa
                </h3>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between w-100">
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="CEP*"
                  name="addressZipCode"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={formatCep(business?.addressZipCode) || ""}
                  variant="outlined"
                  onChange={(e) => {
                    e.target.value = formatCep(e.target.value);
                    handlerChange(e);
                  }}
                  inputProps={{ maxLength: 9 }}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Logradouro*"
                  name="addressStreet"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.addressStreet || ""}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Número*"
                  name="addressNumber"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.addressNumber || ""}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Complemento"
                  name="addressComplement"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.addressComplement || ""}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Bairro*"
                  name="addressDistrict"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.addressDistrict || ""}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Cidade*"
                  name="addressCity"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.addressCity || ""}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Estado*"
                  name="addressState"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.addressState || ""}
                  variant="outlined"
                  onChange={handlerChange}
                  inputProps={{ maxLength: 2 }}
                />
              </div>
            </div>

            {/* Endereço de Funcionamento da Empresa */}
            <div className="containerTitle mt-4">
              <div className="itemTile d-flex flex-row align-items-center">
                <h3
                  className="mr-4"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Endereço de Funcionamento da Empresa
                </h3>
                <ReactIf condition={canSelfUpdate(business.statusId)}>
                  <ReactThen>
                    <ConfirmButton onClick={() => copyFromRegisterAddress()}>
                      Copiar Endereço de Registro
                    </ConfirmButton>
                  </ReactThen>
                </ReactIf>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between w-100">
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="CEP*"
                  name="operationAddressZipCode"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={formatCep(business?.operationAddressZipCode) || ""}
                  variant="outlined"
                  onChange={(e) => {
                    e.target.value = formatCep(e.target.value);
                    handlerChange(e);
                  }}
                  inputProps={{ maxLength: 9 }}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Logradouro*"
                  name="operationAddressStreet"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.operationAddressStreet || ""}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Número*"
                  name="operationAddressNumber"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.operationAddressNumber || ""}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Complemento"
                  name="operationAddressComplement"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.operationAddressComplement || ""}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Bairro*"
                  name="operationAddressDistrict"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.operationAddressDistrict || ""}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Cidade*"
                  name="operationAddressCity"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.operationAddressCity || ""}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Estado*"
                  name="operationAddressState"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={business?.operationAddressState || ""}
                  variant="outlined"
                  onChange={handlerChange}
                />
              </div>
              <div className="item pr-2 w-100">
                <FormControl className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput w-100">
                  <InputLabel id="situacaoImovel">
                    Situação do Imóvel
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disabled={!canSelfUpdate(business.statusId)}
                    variant="outlined"
                    value={Number(business?.propertyStatusId)}
                    onChange={(e) => {
                      setBusiness({
                        ...business,
                        propertyStatusId: e.target.value,
                        propertyStatusDescription: propertyStatuses.find(
                          (id) => id.statusId == e.target.value
                        )?.statusDescription,
                      });
                    }}
                  >
                    <MenuItem value={0}> </MenuItem>
                    {propertyStatuses.map((element) => (
                      <MenuItem key={element.statusId} value={element.statusId}>
                        {element.statusDescription}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <ReactIf condition={canSelfUpdate(business.statusId)}>
              <ReactThen>
                <div
                  className="item itemPicker"
                  style={{ paddingRight: "2.5%" }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <ConfirmButton onClick={() => updateBusiness("all")}>
                      Salvar
                    </ConfirmButton>
                  </div>
                </div>
              </ReactThen>
            </ReactIf>
          </div>
        )}
      </div>
    </>
  );
};

export default DadosComerciaisCedente;
function apiUpdateBusiness(business, updatedBusiness) {
  const axiosInstance = AxiosInterceptor();
  return axiosInstance.patch(
    `${process.env.REACT_APP_NODE_API}/business/${business.businessId}`,
    updatedBusiness
  );
}
