import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import "./capa.scss";
import { Box, Snackbar, Tab, Tabs, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import Content from "example-components/content";
import ReputacaoCedente from "pages/CadastroFinaciadorCedente/tabs/ReputacaoCedente";

import PageTitle from "components/PageTitle";

import { useAuth } from "contexts/auth";
import { usePermission } from "contexts/permission";
import { useToast } from "contexts/toast";

import { selectTab } from "./abasCadastro";
import Capa from "./tabs/Capa";
import Cedente from "./tabs/Cedente";
import Documentos from "./tabs/Documentos";
import Dossie from "./tabs/Dossie";
import Lances from "./tabs/Lances";
import Tags from "./tabs/Tags/Tags";
import TipoMercado from "./tabs/TipoMercado/TipoMercado";
import AxiosInterceptor from "utils/axios-interceptor";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      className="w-100"
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box className="p-0" p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

export default () => {
  const { toast } = useToast();
  const { user, isAdmin, isFinanciador, userOrigin } = useAuth();
  const { sellerInvoicesIds } = usePermission();
  const history = useHistory();

  const [invoice, setInvoice] = useState(undefined);
  const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);

  const [invoiceDocuments, setInvoiceDocuments] = useState([]);
  const [invoicePending, setInvoicePending] = useState([]);
  const [bids, setBids] = useState(undefined);
  const [seller, setSeller] = useState(undefined);
  const [sellerDocuments, setSellerDocuments] = useState(undefined);
  const [sellerPending, setSellerPending] = useState(undefined);
  const axiosInstance = AxiosInterceptor();

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [tabs, setTabs] = useState(undefined);
  const [tabValue, setTabValue] = useState(0);

  const { invoiceId } = useParams();

  const hasInvoiceViewPermission = () => {
    if (isAdmin() || isFinanciador()) return true;
    return sellerInvoicesIds?.some((id) => invoiceId.localeCompare(id) === 0);
  };

  const handleSetTabs = (userTypeId) => {
    const selectedTab = selectTab(userTypeId);
    setTabs(selectedTab);
  };

  const getInvoice = (shortOrigin) => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_NODE_API}/invoice/filter?invoiceId=${invoiceId}&shortOrigin=${shortOrigin}`
      )
      .then((res) => {
        setInvoice(res.data.invoices[0]);
        getSeller(res.data.invoices[0]?.businessId);
      });
  };

  const getDocuments = async () => {
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_NODE_API}/invoice/documents/${invoiceId}`
    );

    setInvoiceDocuments(data);

    return data;
  };

  const getPendings = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_NODE_API}/pending-invoice`)
      .then((res) => {
        setInvoicePending(res.data);
      });
  };

  const getBids = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_NODE_API}/bid/findAllDetails/${invoiceId}`)
      .then((response) => {
        setBids(response.data);
      });
  };

  const getBidsBuyer = () => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_NODE_API}/bid/findAllDetailsBuyer/${invoiceId}/${user.businessId}`
      )
      .then((response) => {
        setBids(response.data);
      });
  };

  const getSeller = (businessId) => {
    axiosInstance
      .get(`${process.env.REACT_APP_NODE_API}/business/find/full/${businessId}`)
      .then((res) => {
        setSeller(res.data.business);
        setSellerDocuments(res.data.documents);
        setSellerPending(res.data.pendings);
      });
  };

  async function loadTitulos() {
    try {
      const dataPromises = [
        handleSetTabs(user.userTypeId),
        getInvoice(userOrigin),
        isFinanciador() ? getBidsBuyer() : getBids(),
        getDocuments(),
        getPendings(),
      ];

      await toast.promise(Promise.all(dataPromises), {
        loading: "Carregando dados",
        success: "Dados carregados",
        error: "Erro carregando dados",
      });

      setIsAllDataLoaded(true);
    } catch (error) {
      history.goBack();
    }
  }

  useEffect(() => {
    if (hasInvoiceViewPermission()) {
      loadTitulos();
    } else {
      return history.replace({ pathname: "/invoices" });
    }
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  return (
    <>
      <Content>
        <PageTitle
          titleHeading={
            invoice !== undefined
              ? `Detalhes do Título - ID: ${invoice.id}`
              : ""
          }
          titleDescription={
            invoice !== undefined
              ? `Cedente: ${invoice.invoiceSeller.corporateName} `
              : ""
          }
        />
        <div className="example-card-seamless mb-4-spacing">
          <h5 className="display-5 mb-4 font-weight-bold" />
          <div
            style={{ marginTop: "30px", marginLeft: "-32px", width: "100%" }}
          >
            {invoice && tabs && isAllDataLoaded && (
              <div>
                <Snackbar
                  open={snackbarState.open}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  key="topcenter"
                >
                  <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarState.severity}
                  >
                    {snackbarState.message}
                  </Alert>
                </Snackbar>
                <div
                  className="tabs-header"
                  style={{ marginLeft: "0px", marginBottom: "30px" }}
                >
                  <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {Object.keys(tabs).map((key) => (
                      <Tab label={tabs[key].name} />
                    ))}
                  </Tabs>
                </div>
                {tabs.dados && (
                  <TabPanel value={tabValue} index={tabs.dados.index}>
                    <Capa
                      invoiceData={invoice}
                      setInvoiceData={setInvoice}
                      invoiceBids={bids}
                    />
                  </TabPanel>
                )}
                {tabs.documentos && (
                  <TabPanel value={tabValue} index={tabs.documentos.index}>
                    <Documentos
                      title={tabs.documentos.name}
                      refreshInvoiceData={getDocuments}
                      invoice={invoice}
                      setInvoice={(value) => setInvoice(value)}
                      invoiceDocuments={invoiceDocuments}
                      setInvoiceDocuments={(value) =>
                        setInvoiceDocuments(value)
                      }
                      invoicePending={invoicePending}
                      setInvoicePending={(value) => setInvoicePending(value)}
                    />
                  </TabPanel>
                )}
                {tabs.dossie && (
                  <TabPanel value={tabValue} index={tabs.dossie.index}>
                    <Dossie
                      title={tabs.dossie.name}
                      invoice={invoice}
                      refreshInvoiceData={getDocuments}
                      setInvoice={(value) => setInvoice(value)}
                      invoiceDocuments={invoiceDocuments}
                      setInvoiceDocuments={(value) =>
                        setInvoiceDocuments(value)
                      }
                      invoicePending={invoicePending}
                      setInvoicePending={(value) => setInvoicePending(value)}
                    />
                  </TabPanel>
                )}
                {tabs.tags && (
                  <TabPanel value={tabValue} index={tabs.tags.index}>
                    <Tags invoice={invoice} />
                  </TabPanel>
                )}
                {tabs.tipoMercado && (
                  <TabPanel value={tabValue} index={tabs.tipoMercado.index}>
                    <TipoMercado invoiceId={invoice.invoiceId} />
                  </TabPanel>
                )}
                {tabs.lances && (
                  <TabPanel value={tabValue} index={tabs.lances.index}>
                    <Lances bids={bids} />
                  </TabPanel>
                )}
                {tabs.reputacaoCedente && (
                  <TabPanel
                    value={tabValue}
                    index={tabs.reputacaoCedente.index}
                  >
                    {seller && (
                      <>
                        <ReputacaoCedente business={seller} />
                      </>
                    )}
                  </TabPanel>
                )}
                {tabs.cedente && (
                  <TabPanel value={tabValue} index={tabs.cedente.index}>
                    <Cedente
                      title={tabs.cedente.name}
                      business={seller}
                      setBusiness={(value) => setSeller(value)}
                      businessDocuments={sellerDocuments}
                      setBusinessDocuments={(value) =>
                        setSellerDocuments(value)
                      }
                      businessPending={sellerPending}
                      setBusinessPending={(value) => setSellerPending(value)}
                    />
                  </TabPanel>
                )}
              </div>
            )}
          </div>
        </div>
      </Content>
    </>
  );
};
