import { makeStyles } from '@material-ui/core';

export const riskStyle = makeStyles({
  default: {
    backgroundColor: '#888 !important',
    color: 'white',
    fontWeight: 600,
  },
  AA: {
    backgroundColor: '#05FF23 !important',
    color: 'white',
    fontWeight: 600,
  },
  AB: {
    backgroundColor: '#9AFF05 !important',
    color: 'white',
    fontWeight: 600,
  },
  BA: {
    backgroundColor: '#FFF305 !important',
    color: 'white',
    fontWeight: 600,
  },
  BB: {
    backgroundColor: '#FFD505 !important',
    color: 'white',
    fontWeight: 600,
  },
  CA: {
    backgroundColor: '#FF7C05 !important',
    color: 'white',
    fontWeight: 600,
  },
  CB: {
    backgroundColor: '#FF5E05 !important',
    color: 'white',
    fontWeight: 600,
  },
  DA: {
    backgroundColor: '#FF0505 !important',
    color: 'white',
    fontWeight: 600,
  },
  INAPTO: {
    backgroundColor: '#C60202 !important',
    color: 'white',
    fontWeight: 600,
  },
  BUTTON: {
    backgroundColor: '#000 !important',
    color: '#FFF !important',
    maxHeight: '50px',
    alignSelf: 'center',
  },
});
