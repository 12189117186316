import React, { useState, useEffect } from 'react';
import '../capa.scss';

import { TextField, CircularProgress } from '@material-ui/core';

import { ConfirmButton } from 'components/Button';
import { ReactIf, ReactThen } from 'components/ReactIf';

import formatCpf from 'utils/formatCPF';
import formatRg from 'utils/formatRG';

import { updateTestemunha } from 'services/testemunhas';

import { useAuth } from 'contexts/auth';
import { useToast } from 'contexts/toast';

import Witness from './classes/Witness';

const Testemunhas = (props) => {
  const {
    business,
    businessWitnesses,
    setBusinessWitnesses,
    refreshDataTestemunha,
  } = props;

  const { canSelfUpdate } = useAuth();
  const { toast } = useToast();

  const [witnesses, setWitnesses] = useState({
    firstWitness:
      businessWitnesses[0] !== undefined
        ? new Witness(
            businessWitnesses[0].witnessId,
            businessWitnesses[0].businessId,
            businessWitnesses[0].name,
            businessWitnesses[0].countryIdentity,
            businessWitnesses[0].stateIdentity,
            businessWitnesses[0].email
          )
        : new Witness(undefined, business.businessId),
    secondWitness:
      businessWitnesses[1] !== undefined
        ? new Witness(
            businessWitnesses[1].witnessId,
            businessWitnesses[1].businessId,
            businessWitnesses[1].name,
            businessWitnesses[1].countryIdentity,
            businessWitnesses[1].stateIdentity,
            businessWitnesses[1].email
          )
        : new Witness(undefined, business.businessId),
  });

  const [savingBusinessWitness, setSavingBusinessWitness] = useState(false);

  const updateBusinessWitness = async () => {
    if (witnesses === undefined || savingBusinessWitness) return;
    setSavingBusinessWitness(true);
    const updateBusinessWitnesses = [];

    if (
      Object.values(witnesses.firstWitness).find((value) => value !== undefined)
    ) {
      updateBusinessWitnesses.push(witnesses.firstWitness);
    }
    if (
      Object.values(witnesses.secondWitness).find(
        (value) => value !== undefined
      )
    ) {
      updateBusinessWitnesses.push(witnesses.secondWitness);
    }

    try {
      await toast.promise(updateTestemunha(updateBusinessWitnesses), {
        loading: 'Salvando dados',
        success: 'Dados salvo com sucesso',
        error: 'Erro ao salvar dados',
      });

      window.scrollTo(0, 0);
      setSavingBusinessWitness(false);
    } catch {
      toast.error('Erro ao salvar dados');
      window.scrollTo(0, 0);
      setSavingBusinessWitness(false);
    }

    await refreshDataTestemunha();
  };

  const changeBusinessWitnesses = (newState, index) => {
    const newWitnesses = witnesses;
    newWitnesses[index === 0 ? 'firstWitness' : 'secondWitness'] = newState;

    setWitnesses(newWitnesses);
  };

  const handleChange = (e, state, setter, index) => {
    const newState = { ...state, [e.target.name]: e.target.value };

    changeBusinessWitnesses(newState, index);

    setter(newState);
  };

  useEffect(() => {
    setBusinessWitnesses(businessWitnesses);
  }, [businessWitnesses]);

  const BusinessWitnessContent = (props) => {
    const { businessWitness, index } = props;

    const [witness, setWitness] = useState(businessWitness);
    return (
      <div className="d-flex flex-column justify-content-center">
        <h4 style={{ fontFamily: 'Poppins, sans-serif' }}>{`${
          index === 0 ? 'Primeira' : 'Segunda'
        } Testemunha`}</h4>
        <div className="d-flex flex-row justify-content-between w-100 responsible">
          <TextField
            fullWidth
            variant="outlined"
            className="m-2"
            label="Nome"
            name="name"
            disabled={!canSelfUpdate(business?.statusId)}
            value={witness.name}
            onChange={(e) => handleChange(e, witness, setWitness, index)}
          />
          <TextField
            fullWidth
            variant="outlined"
            className="m-2"
            label="CPF"
            name="countryIdentity"
            disabled={!canSelfUpdate(business?.statusId)}
            value={formatCpf(witness.countryIdentity)}
            onChange={(e) => handleChange(e, witness, setWitness, index)}
            inputProps={{ maxLength: 14 }}
          />
          <TextField
            fullWidth
            variant="outlined"
            className="m-2"
            label="Número do RG"
            name="stateIdentity"
            disabled={!canSelfUpdate(business?.statusId)}
            value={formatRg(witness.stateIdentity)}
            onChange={(e) => handleChange(e, witness, setWitness, index)}
            inputProps={{ maxLength: 12 }}
          />
        </div>
        <div className="d-flex flex-row justify-content-between w-100 responsible">
          <TextField
            fullWidth
            variant="outlined"
            className="m-2"
            label="E-mail"
            name="email"
            disabled={!canSelfUpdate(business?.statusId)}
            value={witness.email}
            onChange={(e) => handleChange(e, witness, setWitness, index)}
            type="email"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="w-100 relacionados">
      <div className="searchForm" style={{ marginBottom: 0 }}>
        <div className="containerTitle w-100 mt-4">
          <div className="itemTile d-flex flex-row justify-content-between w-100">
            <h3 style={{ fontFamily: 'Poppins, sans-serif' }}>Testemunhas</h3>
          </div>
        </div>
        {businessWitnesses && (
          <div className="d-flex flex-column justify-content-center">
            <BusinessWitnessContent
              key={witnesses.firstWitness.witnessId}
              businessWitness={witnesses.firstWitness}
              index={0}
            />
            <BusinessWitnessContent
              key={witnesses.secondWitness.witnessId}
              businessWitness={witnesses.secondWitness}
              index={1}
            />
          </div>
        )}
        <div
          className="d-flex justify-content-end mt-3"
          style={{ paddingRight: '2.5%' }}
        >
          <ReactIf condition={canSelfUpdate(business?.statusId)}>
            <ReactThen>
              <div style={{ position: 'relative' }}>
                <ConfirmButton
                  variant="contained"
                  className="text-white"
                  disabled={savingBusinessWitness}
                  onClick={() => updateBusinessWitness()}
                >
                  Salvar
                </ConfirmButton>
                {savingBusinessWitness && (
                  <CircularProgress
                    size={24}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: -12,
                      marginLeft: -12,
                      color: 'green',
                    }}
                  />
                )}
              </div>
            </ReactThen>
          </ReactIf>
        </div>
      </div>
    </div>
  );
};

export default Testemunhas;
