class PayrollAccount {
  constructor(
    bankAccountId,
    businessId,
    countryIdentity,
    personTypeId,
    personTypeName,
    phone,
    email,
    name,
    corporateName,
    businessTypeId,
    businessTypeName,
    isDefault,
    ispb,
    bankName,
    bankCode,
    bankBranch,
    accountNumber,
    bankAccountTypeId,
    bankAccountTypeName,
    accountId,
    accountTypeId,
    payrollAccountId,
    statusId,
    statusName,
    statusDate,
    openingDate,
    liberationDate,
    negativeDate,
    blockDate,
    unblockDate,
    cancelDate
  ) {
    this.bankAccountId = bankAccountId;
    this.businessId = businessId;
    this.countryIdentity = countryIdentity;
    this.personTypeId = personTypeId;
    this.personTypeName = personTypeName;
    this.phone = phone;
    this.email = email;
    this.name = name;
    this.corporateName = corporateName;
    this.businessTypeId = businessTypeId;
    this.businessTypeName = businessTypeId === 1 ? 'C' : 'J';
    this.isDefault = isDefault;
    this.ispb = '24313102';
    this.bankName = 'BEPAY';
    this.bankCode = '301';
    this.bankBranch = '1';
    this.accountNumber = accountNumber;
    this.bankAccountTypeId = 1;
    this.bankAccountTypeName = 'CC';
    this.accountId = accountId;
    this.accountTypeId = 1;
    this.payrollAccountId = payrollAccountId;
    this.statusId = statusId;
    this.statusName = statusName;
    this.statusDate = statusDate;
    this.openingDate = openingDate;
    this.liberationDate = liberationDate;
    this.negativeDate = negativeDate;
    this.blockDate = blockDate;
    this.unblockDate = unblockDate;
    this.cancelDate = cancelDate;
  }
}

export default PayrollAccount;
