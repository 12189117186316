import React, { useEffect, useState } from 'react';

import '../capa.scss';
import {
  Card,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { ConfirmButton } from 'components/Button';
import { ReactCatch, ReactIf, ReactThen } from 'components/ReactIf';

import formatCep from 'utils/formatCEP';
import formatCpf from 'utils/formatCPF';
import formatRg from 'utils/formatRG';
import formatTelefone from 'utils/formatTelefone';
import validateCep from 'utils/validateCEP';


import { getBigBoostCnpj } from 'services/business';
import {
  updateBusinessResponsibles,
  deleteBusinessResponsibles,
  getBusinessResponsibles,
} from 'services/business-responsibles';
import { get } from 'services/cep';

import { useAuth } from 'contexts/auth';
import { useToast } from 'contexts/toast';

import { useModal } from 'hooks/useModal';

import Related from './classes/Related';

const Relacionados = (props) => {
  const { business, businessRelateds, setBusinessRelateds } = props;

  const { isAdmin, canSelfUpdate, isCedente } = useAuth();
  const { toast } = useToast();
  const { openModal } = useModal();

  const [removedRelateds, setRemovedRelateds] = useState([]);
  const [allFilled, setAllFilled] = useState(true);
  const [savingRelateds, setSavingRelateds] = useState(false);

  const stringAttributeIsInvalid = (attr) =>
    attr === undefined || attr === '' || attr === null || attr === 0;

  const numberAttributeIsInvalid = (attr, number) =>
    attr === undefined || Number(attr) === number || attr === null;

  useEffect(() => {
    if (
      business &&
      canSelfUpdate(business.statusId) &&
      businessRelateds.length === 0
    )
      fillRegisterData(business.countryIdentity);
  }, []);

  const fillRegisterData = async (cnpj) => {
    try {
      setSavingRelateds(true);
      const bigBoostBusinessData = await toast.promise(getBigBoostCnpj(cnpj), {
        loading: 'Preenchendo campos do cadastro',
        success: 'Campos preenchidos com sucesso!',
        error: 'Erro ao preencher campos',
      });
      const bigBoostBusiness = bigBoostBusinessData.result.value;

      const bigBoostRelateds = bigBoostBusiness.Representantes_Legais.map(
        (item, index) => {
          if (item === 'CPF') {
            return {
              cpf: bigBoostBusiness.Representantes_Legais[index + 1],
              nome: bigBoostBusiness.Representantes_Legais[index + 2],
            };
          }
          return false;
        }
      ).filter((item) => !!item);

      const newAddedRelateds = addRelateds(bigBoostRelateds);

      setBusinessRelateds([...newAddedRelateds]);
      await toast.promise(updateBusinessRelateds(true), {
        loading: 'Guardando dados',
        success: 'Dados guardados com sucesso!',
        error: 'Erro ao guardar dados',
      });
      setSavingRelateds(false);
    } catch (error) {
      setSavingRelateds(false);
    }
  };

  const updateBusinessRelateds = async (autoFill) => {
    if (businessRelateds === undefined || savingRelateds) return;

    setSavingRelateds(true);

    const allFilled = !businessRelateds.some(
      (related) =>
        stringAttributeIsInvalid(related.name) ||
        stringAttributeIsInvalid(related.countryIdentity) ||
        related.birthDate === undefined ||
        numberAttributeIsInvalid(related.typeId, 0) ||
        stringAttributeIsInvalid(related.businessParticipation) ||
        stringAttributeIsInvalid(related.stateIdentity) ||
        stringAttributeIsInvalid(related.stateIdentityIssuingEntity) ||
        stringAttributeIsInvalid(related.stateIdentityFederativeUnity) ||
        stringAttributeIsInvalid(related.addressZipCode) ||
        stringAttributeIsInvalid(related.addressStreet) ||
        stringAttributeIsInvalid(related.addressNumber) ||
        stringAttributeIsInvalid(related.addressDistrict) ||
        stringAttributeIsInvalid(related.addressCity) ||
        stringAttributeIsInvalid(related.addressState) ||
        numberAttributeIsInvalid(related.signsForCompany, 3) ||
        numberAttributeIsInvalid(related.ecpf, 3) ||
        stringAttributeIsInvalid(related.email) ||
        stringAttributeIsInvalid(related.mobilePhone) ||
        stringAttributeIsInvalid(related.maritalStatusId, 0)
      // ||
      // (Number(related?.maritalStatusId) === 2 &&
      //   (stringAttributeIsInvalid(related.spouseName) ||
      //     stringAttributeIsInvalid(related.spouseCountryIdentity) ||
      //     stringAttributeIsInvalid(related.spouseStateIdentity) ||
      //     stringAttributeIsInvalid(related.spouseEmail)))
    );

    if (allFilled || autoFill) {
      await toast.promise(
        updateBusinessResponsibles(businessRelateds),
        {
          loading: 'Atualizando informações',
          success: 'Informações atualizados com sucesso!',
          error: 'Erro ao atualizar informações',
        },
        {
          id: 'update-responsibles',
        }
      );
      if (
        removedRelateds.some((removed) => removed.responsibleId !== undefined)
      ) {
        await toast.promise(deleteBusinessResponsibles(removedRelateds), {
          loading: 'Removendo relacionados',
          success: 'Relacionados removidos com sucesso!',
          error: 'Erro ao remover relacionados',
        });

        setRemovedRelateds([]);
        window.scrollTo(0, 0);
        setSavingRelateds(false);
      } else {
        setRemovedRelateds([]);
        setSavingRelateds(false);
      }
      const updatedRelateds = await toast.promise(
        getBusinessResponsibles(business.businessId),
        {
          loading: 'Carregando informações',
          success: 'Informações carregadas com sucesso!',
          error: 'Erro ao carregar informações',
        },
        {
          id: 'update-responsibles',
        }
      );

      setBusinessRelateds(updatedRelateds);
    } else {
      setAllFilled(false);
      toast.error('Por favor, preencha todos os dados!');

      window.scrollTo(0, 0);
      setSavingRelateds(false);
    }
  };

  const handleOpenModalSave = () => {
    if (isCedente()) {
      openModal(
        'info',
        'Atenção!',
        'Vá para a próxima página para continuar o cadastro.'
      );
    }
  };

  const changeBusinessRelateds = (newState, index) => {
    const relateds = businessRelateds;
    relateds[index] = newState;

    setBusinessRelateds(relateds);
  };

  const handlerChange = (e, state, setter, index) => {
    const newState = { ...state, [e.target.name]: e.target.value };

    changeBusinessRelateds(newState, index);

    setter(newState);
  };

  const addRelated = () => {
    if (savingRelateds) return;
    const relateds = businessRelateds;
    const newRelated = new Related();
    newRelated.businessId = business.businessId;
    relateds.push(newRelated);

    setBusinessRelateds([...relateds]);
  };

  const addRelateds = (newRelateds) => {
    const relateds = businessRelateds;
    newRelateds.forEach((newRelatedItem) => {
      const newRelated = new Related();
      newRelated.businessId = business.businessId;
      newRelated.typeId = 2;
      newRelated.typeName = 'Representante';
      newRelated.businessParticipation = 0;
      newRelated.maritalStatusId = 0;
      newRelated.signsForCompany = 0;
      newRelated.ecpf = 0;
      if (newRelatedItem.nome) newRelated.name = newRelatedItem.nome;
      if (newRelatedItem.cpf) newRelated.countryIdentity = newRelatedItem.cpf;
      relateds.push(newRelated);
    });

    return relateds;
  };

  const removeRelated = (index) => {
    setRemovedRelateds([...removedRelateds, businessRelateds[index]]);

    const relateds = businessRelateds;
    relateds.splice(index, 1);

    setBusinessRelateds(relateds);
  };

  const StylizedInput = ({
    className,
    label,
    inputName,
    disabled,
    required,
    related,
    setRelated,
    index,
    andError,
    format,
    ...props
  }) => (
    <div className={`item pr-2 w-100 mt-2 ${className}`}>
      <TextField
        fullWidth
        className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
        id={label}
        label={`${label}${required ? '*' : ''}`}
        name={inputName}
        value={(format ? format(related[inputName]) : related[inputName]) || ''}
        disabled={disabled || !canSelfUpdate(business.statusId)}
        error={
          (andError !== undefined ? andError : true) &&
          stringAttributeIsInvalid(related[inputName]) &&
          !allFilled &&
          required
        }
        variant="outlined"
        onChange={(e) => {
          if (format) e.target.value = format(e.target.value);
          handlerChange(e, related, setRelated, index);
        }}
        {...props}
      />
    </div>
  );

  const RelacionadoComponent = (props) => {
    const { index, relatedItem } = props;

    const [related, setRelated] = useState(relatedItem);
    const [cepatual, setCepAtual] = useState('000');
    const [isLoading, setIsLoading] = useState(false);

    async function buscarDadosCEP() {
      setIsLoading(true);

      const cep = related?.addressZipCode;

      await get(cep)
        .then((response) => response.json())
        .then((response) => {
          related.addressStreet = response.logradouro || related.addressStreet;
          related.addressCity = response.localidade || related.addressCity;
          related.addressDistrict = response.bairro || related.addressDistrict;
          related.addressState = response.uf || related.addressState;
          setCepAtual(related?.addressZipCode);
        });
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    }

    useEffect(() => {
      if (!related?.addressZipCode) return;
      if (related?.addressZipCode.length < 9) return;
      if (
        validateCep(related?.addressZipCode) &&
        related?.addressZipCode != cepatual
      ) {
        buscarDadosCEP();
      }
    }, [related?.addressZipCode]);

    return (
      <div className="searchForm" style={{ marginBottom: 0 }}>
        {isLoading && <LinearProgress value={55} color="primary" />}
        <div className="containerTitle w-100 mt-4">
          <div className="itemTile d-flex flex-row justify-content-between w-100">
            <h3
              style={{
                fontFamily: 'Poppins, sans-serif',
              }}
            >
              Relacionados
            </h3>
            <ReactIf condition={canSelfUpdate(business.statusId)}>
              <ReactThen>
                <div className="d-flex flex-row">
                  <CloseIcon
                    style={{ cursor: 'pointer' }}
                    className="mr-2"
                    onClick={() => removeRelated(index)}
                  />
                  <AddCircleOutlineIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => addRelated()}
                  />
                </div>
              </ReactThen>
              <ReactCatch>
                <div />
              </ReactCatch>
            </ReactIf>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between w-100 responsible">
          <StylizedInput
            label="Nome"
            inputName="name"
            required
            related={related}
            setRelated={setRelated}
            index={index}
          />
          <StylizedInput
            label="CPF"
            inputName="countryIdentity"
            required
            related={related}
            setRelated={setRelated}
            index={index}
            format={formatCpf}
            inputProps={{ maxLength: 14 }}
          />
          <div className="item pr-2 w-100 mt-2">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <div className="containerDatePicker w-100">
                <KeyboardDatePicker
                  helperText=""
                  cancelLabel="Cancelar"
                  className="mt-0 date-picker"
                  fullWidth
                  disableToolbar
                  locale={ptBR}
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Data de Nascimento*"
                  name="birthDate"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={related?.birthDate}
                  error={
                    stringAttributeIsInvalid(related.birthDate) && !allFilled
                  }
                  variant="inline"
                  onChange={(date) => {
                    setRelated((related) =>
                      ({...related , birthDate: date})
                    )
                    changeBusinessRelateds({...related , birthDate: date}, index)
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </div>
            </MuiPickersUtilsProvider>
          </div>
          <div className="item pr-2 w-100 ">
            <div
              className={`containerDatePicker w-100 d-flex flex-row align-items-center justify-content-"between"`}
            >
              <FormControl className="w-25 mt-2 mr-1 mb-2 ml-1 w-100">
                <InputLabel id="Tipo" className="ml-2 mt-2">
                  Tipo*
                </InputLabel>
                <Select
                  labelId="Tipo"
                  id="tipo-select"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={Number(related?.typeId)}
                  error={
                    numberAttributeIsInvalid(related.typeId, 0) && !allFilled
                  }
                  onChange={(e) => {
                    const newState = {
                      ...related,
                      typeId: e.target.value,
                      typeName: ['', 'Sócio', 'Procurador'][e.target.value],
                    };
                    changeBusinessRelateds(newState, index);
                    setRelated(newState);
                  }}
                >
                  <MenuItem value={0} />
                  <MenuItem value={1}>Sócio</MenuItem>
                  <MenuItem value={2}>Procurador</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <StylizedInput
            className="participacao-empresa"
            label="Participação na Empresa %"
            inputName="businessParticipation"
            required
            related={related}
            setRelated={setRelated}
            index={index}
          />
        </div>
        <div className="d-flex flex-row responsible">
          <StylizedInput
            label="Número do RG"
            inputName="stateIdentity"
            required
            related={related}
            setRelated={setRelated}
            index={index}
            format={formatRg}
            inputProps={{ maxLength: 12 }}
          />
          <StylizedInput
            label="Orgão Emissor do RG"
            inputName="stateIdentityIssuingEntity"
            required
            related={related}
            setRelated={setRelated}
            index={index}
          />
          <StylizedInput
            label="UF do RG"
            inputName="stateIdentityFederativeUnity"
            required
            related={related}
            setRelated={setRelated}
            index={index}
            inputProps={{ maxLength: 2 }}
          />
        </div>

        {/* Endereço */}
        <div className="d-flex flex-row justify-content-between w-100 responsible">
          <StylizedInput
            label="CEP"
            inputName="addressZipCode"
            required
            related={related}
            setRelated={setRelated}
            index={index}
            format={formatCep}
            inputProps={{ maxLength: 9 }}
          />
          <StylizedInput
            label="Logradouro"
            inputName="addressStreet"
            required
            related={related}
            setRelated={setRelated}
            index={index}
          />
          <StylizedInput
            label="Número"
            inputName="addressNumber"
            required
            related={related}
            setRelated={setRelated}
            index={index}
          />
          <StylizedInput
            label="Complemento"
            inputName="addressComplement"
            related={related}
            setRelated={setRelated}
            index={index}
          />
        </div>
        <div className="d-flex flex-row responsible">
          <StylizedInput
            label="Bairro"
            inputName="addressDistrict"
            required
            related={related}
            setRelated={setRelated}
            index={index}
          />
          <StylizedInput
            label="Cidade"
            inputName="addressCity"
            required
            related={related}
            setRelated={setRelated}
            index={index}
          />
          <StylizedInput
            label="Estado"
            inputName="addressState"
            required
            related={related}
            setRelated={setRelated}
            index={index}
            inputProps={{ maxLength: 2 }}
          />
        </div>

        {/* assina pela empresa?, e-cpf? */}
        <div className="d-flex flex-row responsible">
          <div className="item pr-2 w-100 ">
            <div
              className={`containerDatePicker w-100 d-flex flex-row align-items-center justify-content-"between"`}
            >
              <FormControl className="w-25 mt-2 mr-2 mb-2 ml-1 w-100">
                <InputLabel id="demo-simple-select-label" className="ml-2 mt-2">
                  Assina pela Empresa?*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disabled={!canSelfUpdate(business.statusId)}
                  name="signsForCompany"
                  value={Number(related?.signsForCompany)}
                  error={
                    numberAttributeIsInvalid(related.signsForCompany, 3) &&
                    !allFilled
                  }
                  onChange={(e) => handlerChange(e, related, setRelated, index)}
                >
                  <MenuItem value={0}>Não</MenuItem>
                  <MenuItem value={1}>Sim</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="item pr-2 w-100 ">
            <div
              className={`containerDatePicker w-100 d-flex flex-row align-items-center justify-content-"between"`}
            >
              <FormControl className="w-25 mt-2 mr-2 mb-2 ml-1 w-100">
                <InputLabel id="demo-simple-select-label" className="ml-2 mt-2">
                  Possui e-CPF?*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disabled={!canSelfUpdate(business.statusId)}
                  name="ecpf"
                  value={Number(related?.ecpf)}
                  error={
                    numberAttributeIsInvalid(related.ecpf, 3) && !allFilled
                  }
                  onChange={(e) => handlerChange(e, related, setRelated, index)}
                >
                  <MenuItem value={0}>Não</MenuItem>
                  <MenuItem value={1}>Sim</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        {/* email, tel, cel... */}
        <div className="d-flex flex-row">
          <StylizedInput
            label="E-mail"
            inputName="email"
            required
            related={related}
            setRelated={setRelated}
            index={index}
          />
          <StylizedInput
            label="Telefone"
            inputName="officePhone"
            related={related}
            setRelated={setRelated}
            index={index}
            format={formatTelefone}
            inputProps={{ maxLength: 14 }}
          />
          <StylizedInput
            label="Celular"
            inputName="mobilePhone"
            required
            related={related}
            setRelated={setRelated}
            index={index}
            format={formatTelefone}
            inputProps={{ maxLength: 14 }}
          />
          <div className="item pr-2 w-100 ">
            <div
              className={`containerDatePicker w-100 d-flex flex-row align-items-center justify-content-"between"`}
            >
              <FormControl className="w-25 mt-2 mr-2 mb-2 ml-1 w-100">
                <InputLabel id="demo-simple-select-label" className="ml-2 mt-2">
                  Estado Civil*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  disabled={!canSelfUpdate(business.statusId)}
                  value={Number(related?.maritalStatusId)}
                  error={
                    numberAttributeIsInvalid(related?.maritalStatusId, 0) &&
                    !allFilled
                  }
                  onChange={(e) => {
                    const newState = {
                      ...related,
                      maritalStatusId: e.target.value,
                      maritalStatusName: [
                        '',
                        'Solteiro',
                        'Casado',
                        '',
                        'Divorciado',
                        'Viúvo',
                      ][e.target.value],
                    };
                    changeBusinessRelateds(newState, index);
                    setRelated(newState);
                  }}
                >
                  <MenuItem value={0} />
                  <MenuItem value={1}>Solteiro</MenuItem>
                  <MenuItem value={2}>Casado</MenuItem>
                  {/* <MenuItem value={3}></MenuItem> */}
                  <MenuItem value={4}>Divorciado</MenuItem>
                  <MenuItem value={5}>Viúvo</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        {related?.maritalStatusId === 2 && (
          <>
            <div className="containerTitle w-100 mt-4">
              <div className="itemTile d-flex flex-row justify-content-between w-100">
                <h3>Cônjuge</h3>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between w-100">
              <StylizedInput
                label="Nome do Cônjuge"
                inputName="spouseName"
                // required
                andError={Number(related?.maritalStatusId) === 2}
                related={related}
                setRelated={setRelated}
                index={index}
              />
              <StylizedInput
                label="CPF do Cônjuge"
                inputName="spouseCountryIdentity"
                // required
                andError={Number(related?.maritalStatusId) === 2}
                related={related}
                setRelated={setRelated}
                index={index}
                format={formatCpf}
                inputProps={{ maxLength: 14 }}
              />
              <StylizedInput
                label="RG do Cônjuge"
                inputName="spouseStateIdentity"
                // required
                andError={Number(related?.maritalStatusId) === 2}
                related={related}
                setRelated={setRelated}
                index={index}
                format={formatRg}
                inputProps={{ maxLength: 12 }}
              />
              <StylizedInput
                label="Email do Cônjuge"
                inputName="spouseEmail"
                // required
                andError={Number(related?.maritalStatusId) === 2}
                related={related}
                setRelated={setRelated}
                index={index}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="w-100 relacionados">
      <ReactIf
        condition={business && !isAdmin() && canSelfUpdate(business?.statusId)}
      >
        <ReactThen>
          <Card
            className="card-box p-4 mb-4"
            style={{ width: '95%', maxWidth: '95%', margin: '0 auto' }}
          >
            <div className="text-center">
              <div className="avatar-icon-wrapper rounded-circle m-0">
                <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper text-success m-0 d-65 align-items-center">
                  <InfoOutlinedIcon
                    style={{
                      color: '#2196f3',
                      fontSize: 28,
                    }}
                  />
                </div>
              </div>
              <h4
                className="font-weight-bold"
                style={{
                  fontFamily: 'Poppins',
                  fontSize: '26px',
                  textAlign: 'center',
                  color: '#663c00',
                }}
              >
                Verifique se as informações abaixo estão corretas.
              </h4>
              <span>
                Os seus dados são consultados, automaticamente, em bancos de
                dados públicos.
              </span>
            </div>
          </Card>
        </ReactThen>
      </ReactIf>
      <ReactIf
        condition={
          businessRelateds.length === 0 && canSelfUpdate(business.statusId)
        }
      >
        <ReactThen>
          <div className="d-flex flew-row align-items-center justify-content-center w-100">
            <span color="primary">
              Nenhum representante encontrado. Clique no botão ao lado para
              adicionar o primeiro!
            </span>
            <AddCircleOutlineIcon
              color="primary"
              style={{ cursor: 'pointer' }}
              onClick={() => addRelated()}
            />
          </div>
        </ReactThen>
        <ReactCatch>
          {businessRelateds.map((related, index) => (
            <div key={related.responsibleId}>
              <RelacionadoComponent
                relatedItem={related}
                isLast={index === businessRelateds.length - 1}
                index={index}
              />
              {index !== businessRelateds.length - 1 && (
                <div className="separation-bar" />
              )}
            </div>
          ))}
        </ReactCatch>
      </ReactIf>
      <ReactIf condition={canSelfUpdate(business.statusId)}>
        <ReactThen>
          <div
            className="item itemPicker"
            style={{ paddingRight: '2.5%', marginTop: '2.5%' }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <ConfirmButton
                onClick={() => {
                  updateBusinessRelateds();
                  handleOpenModalSave();
                }}
              >
                Salvar
              </ConfirmButton>
            </div>
          </div>
        </ReactThen>
      </ReactIf>
    </div>
  );
};

export default Relacionados;
