import React, { createContext, useContext, useEffect, useState } from "react";

import { parseTheme } from "utils/origin";
import { handleThemeAppTab } from "utils/setAppTab";

import { getOriginByHostname } from "services/origin";

const HostThemeContext = createContext();

export const HostThemeProvider = ({ children }) => {
  const [tema, setTema] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [executed, setExecuted] = useState(false);

  const [whitelabel, setWhitelabel] = useState(false);

  async function loadTheme() {
    let theme = await getOriginByHostname(window.location.hostname);

    const theme_origin_id = theme.ORIGIN_TYPE_ID;
    localStorage.setItem("@App:userOriginId", theme_origin_id);
    theme = {
      shortOrigin: theme.ORIGIN_TYPE_SHORT,
      longOrigin: theme.ORIGIN_TYPE_NAME,
      logoLogin: theme.LOGO_LOGIN,
      logoTopo: theme.LOGO_TOPO,
      favIcon: theme.FAV_ICON,
      tema: parseTheme({ tema: theme.TEMA }).tema,
    };

    localStorage.setItem("@App:hostTheme", JSON.stringify(theme));
    setTema(theme);

    if (theme) {
      setWhitelabel(theme.shortOrigin !== "AF");
    } else {
      setWhitelabel(false);
    }
    handleThemeAppTab();
    setExecuted(true);
    setIsLoading(false);
  }

  function isWl() {
    if (!tema) return false;
    return tema.shortOrigin !== "AF";
  }

  useEffect(() => {
    setIsLoading(true);
    loadTheme();
  }, []);

  return (
    <HostThemeContext.Provider
      value={{
        hasTema: Boolean(tema),
        isWl: isWl,
        whitelabel,
        loading: isLoading,
        executed,
        setExecuted,
        tema,
      }}
    >
      {children}
    </HostThemeContext.Provider>
  );
};

export function useHostTheme() {
  const context = useContext(HostThemeContext);

  return context;
}
