import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useAuth } from 'contexts/auth';

import ilustraBlack from '../../assets/images/illustrations/black.svg';
import ilustraGreen from '../../assets/images/illustrations/green.svg';
import ilustraOrange from '../../assets/images/illustrations/orange.svg';

export default function LivePreviewExample() {
  const { user } = useAuth();
  const [ilustras, setIlustras] = React.useState();
  const history = useHistory();
  const location = useLocation();

  function loadPerfilIllustration() {
    const typeId = user.userTypeId;

    if (location.pathname.includes('vitrine') && typeId !== 2) {
      history.push('/');
    }

    if (typeId === 3) {
      setIlustras(ilustraBlack);
    } else if (typeId === 2) {
      setIlustras(ilustraOrange);
    } else if (typeId === 1) {
      setIlustras(ilustraGreen);
    } else return ilustraBlack;
  }

  React.useEffect(() => {
    loadPerfilIllustration();
  }, [loadPerfilIllustration]);

  return (
    <>
      {ilustras && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '-80px',
            marginLeft: '-30px',
            minHeight: '100%',
            maxHeight: '100%',
          }}
        >
          <img
            src={ilustras}
            className="w-50 mx-auto d-block my-5 img-fluid"
            alt=".404"
          />

          <h3
            style={{ fontFamily: 'Poppins, sans-serif', fontWeight: 'bold' }}
            className="font-size-xxl line-height-sm d-block px-3 mb-3 text-black-50"
          >
            Esta página não está disponível
          </h3>
          <p style={{ fontFamily: 'Poppins, sans-serif' }} className="mb-4">
            Pedimos desculpa. mas o endereço que inseriu já não se encontra
            disponível.
          </p>
        </div>
      )}
    </>
  );
}
