import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import PropTypes from "prop-types";
import React from "react";
import useAccountBalance from "../../../hooks/useAccountBalance";
import TitleSection from "../../TitleSection";

function Balance({ bankingAccount, ...rest }) {
  const { balance, isLoading, isValidating, mutate, error } = useAccountBalance(
    true,
    bankingAccount?.account?.accountId
  );

  return (
    <Box {...rest}>
      <Paper elevation={4} variant="outlined">
        <TitleSection title="Saldo em conta" />
        {isLoading || isValidating ? (
          <Box display="flex" justifyContent="center" mt={2} mb={2}>
            <CircularProgress />
          </Box>
        ) : null}

        {!isLoading && !isValidating ? (
          <Box
            p={2}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
          >
            {!error ? (
              <Typography
                variant="h3"
                color={balance?.balance <= 0 ? "error" : "success"}
              >
                {Number(balance?.balance ?? 0).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "BRL",
                })}
              </Typography>
            ) : (
              <Typography variant="h3" color="error">
                Falha ao buscar
              </Typography>
            )}

            <IconButton onClick={() => mutate()}>
              <Refresh color={error ? "error" : "action"} />
            </IconButton>
          </Box>
        ) : null}
      </Paper>
    </Box>
  );
}

Balance.propTypes = {
  bankingAccount: PropTypes.object.isRequired,
};

export default React.memo(Balance);
