/* eslint eqeqeq: 0 */
import React, { useState } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import formatCnpj from 'utils/formatCNPJ';

import * as moment from 'moment';

const headCells = [
  { id: 'cnpj', label: 'CNPJ', minWidth: 170 },
  { id: 'banco', label: 'BANCO', minWidth: 100 },
  { id: 'agencia', label: 'AGÊNCIA', minWidth: 100 },
  { id: 'conta', label: 'CONTA', minWidth: 100 },
  { id: 'cone_ano', label: 'CONE ANO', minWidth: 105 },
  { id: 'cone_agencia', label: 'CONE AGÊNCIA', minWidth: 135 },
  { id: 'cone_estudo', label: 'CONE ESTUDO', minWidth: 250 },
  { id: 'valor_aprovado', label: 'VALOR APROVADO', minWidth: 170 },
  { id: 'tx_12x', label: 'TX 12X', minWidth: 100 },
  { id: 'tx_24x', label: 'TX 24X', minWidth: 100 },
  { id: 'tx_36x', label: 'TX 36X', minWidth: 100 },
  { id: 'validade_cone', label: 'VALIDADE CONE', minWidth: 170 },
  { id: 'prazo', label: 'PRAZO MÁXIMO', minWidth: 170 },
];

const useStyles = makeStyles({
  root: {
    background: 'white',
    width: '105%',
    boxShadow: 'none',
    border: '1px solid #99999966',
    // borderBottom: 'none',
    maxWidth: '100%',
    marginLeft: '-30px',
  },
});

export default function BradescoPreAprovadosTable(props) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const labelDisplayedRows = ({ from, to, count }) =>
    `${from}-${to} de ${count}`;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper className={classes.root}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align="center"
                    style={{ minWidth: headCell.minWidth }}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {props.data.length == 0 ? (
              <>
                <TableBody>
                  <TableRow hover role="checkbox" tabIndex={-1}>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell
                      style={{
                        textAlign: 'center',
                        width: '200px',
                        fontFamily: 'Poppins, sans-serif',
                        fontWeight: 'bold',
                        fontSize: '18px',
                      }}
                    >
                      Não há Titulos Pré Aprovados
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableBody>
              </>
            ) : (
              <>
                {props.data
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((log) => (
                    <>
                      <TableBody>
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={log.CPF_CNPJ_BASE}
                        >
                          <TableCell>{formatCnpj(log.CPF_CNPJ_BASE)}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {log.BANCO}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {log.AGENCIA}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {log.CONTA}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {log.CONE_ANO}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {log.CONE_AGENCIA}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {log.CONE_ESTUDO}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {Number(log.VALOR_APROVADO).toLocaleString(
                              'pt-BR',
                              {
                                style: 'currency',
                                currency: 'BRL',
                              }
                            )}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {log.TX_12X}%
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {log.TX_24X}%
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {log.TX_36X}%
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {moment(new Date(log.VALIDADE_CONE.date)).format(
                              'DD/MM/YYYY'
                            )}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {parseInt(log.PRAZO_MAXIMO, 10)} dias
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </>
                  ))}
              </>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={labelDisplayedRows}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
