import { useEffect, useState } from "react";

import { parseTheme } from "utils/origin";

import { getOriginTypeList } from "services/origin-type";

import { useToast } from "contexts/toast";

export function useCadastroWhiteLabel({ load }) {
  const { toast } = useToast();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [isLoading, setIsLoading] = useState(false);

  const [originList, setOriginList] = useState(null);
  const [openCadastroOrigin, setOpenCadastroOrigin] = useState(false);

  async function getOriginType() {
    const response = await getOriginTypeList();

    return response;
  }

  useEffect(() => {
    if (!load) return;
    setIsLoading(true);

    async function getItems() {
      try {
        let response = await getOriginTypeList();

        response = response.map((origin) => parseTheme(origin));

        setOriginList(response);
        setIsLoading(false);
      } catch (error) {
        toast.error("Ocorreu um erro ao buscar dados.");
      }
    }

    getItems();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleChangePage = async (event, newPage) => {
    getOriginType(event, newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const labelDisplayedRows = ({ from, to, count }) =>
    `${from}-${to} de ${count}`;

  return {
    stableSort,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    getComparator,
    handleChangeRowsPerPage,
    handleChangePage,
    labelDisplayedRows,
    isLoading,
    setIsLoading,
    setOriginList,
    originList,
    setOpenCadastroOrigin,
    openCadastroOrigin,
    getOriginType,
  };
}
