const sellerTabs = {
  dadosComerciais: {
    index: 0,
    name: "Dados Comerciais",
  },
  relacionadosCedente: {
    index: 1,
    name: "Sócios/Procuradores",
  },
  clientes: {
    index: 2,
    name: "Informações Comerciais",
  },
  dadosBancarios: {
    index: 3,
    name: "Dados Bancários",
  },
  documentos: {
    index: 4,
    name: "Documentos",
  },
};

const buyerTabs = {
  dadosComerciais: {
    index: 0,
    name: "Dados Comerciais",
  },
  relacionadosFinanciador: {
    index: 1,
    name: "Representantes",
  },
  usuarios: {
    index: 2,
    name: "Usuários",
  },
  taxas: {
    index: 3,
    name: "Taxas",
  },
  testemunhas: {
    index: 4,
    name: "Testemunhas",
  },
  documentos: {
    index: 5,
    name: "Documentos",
  },
};

// const partnerTabs = {
//   dadosComerciais: {
//     index: 0,
//     name: 'Dados Comerciais',
//   },
//   usuarios: {
//     index: 1,
//     name: 'Usuários',
//   },
//   documentos: {
//     index: 2,
//     name: 'Documentos',
//   },
// };

const adminSellerTabs = {
  status: {
    index: 0,
    name: "Status",
  },
  recebiveis: {
    index: 1,
    name: "Agenda de Recebiveis",
  },
  dadosComerciais: {
    index: 2,
    name: "Dados Comerciais",
  },
  relacionadosCedente: {
    index: 3,
    name: "Relacionados",
  },
  reputacao: {
    index: 4,
    name: "Reputação",
  },
  clientes: {
    index: 5,
    name: "Clientes",
  },
  usuarios: {
    index: 6,
    name: "Usuários",
  },
  dadosBancarios: {
    index: 7,
    name: "Dados Bancários",
  },
  documentos: {
    index: 8,
    name: "Documentos",
  },
  dossie: {
    index: 9,
    name: "Dossiê",
  },
};

const adminBuyerTabs = {
  status: {
    index: 0,
    name: "Status",
  },
  dadosComerciais: {
    index: 1,
    name: "Dados Comerciais",
  },
  relacionadosFinanciador: {
    index: 2,
    name: "Representantes",
  },
  usuarios: {
    index: 3,
    name: "Usuários",
  },
  taxas: {
    index: 4,
    name: "Taxas",
  },
  testemunhas: {
    index: 5,
    name: "Testemunhas",
  },
  documentos: {
    index: 6,
    name: "Documentos",
  },
  dadosBancarios: {
    index: 7,
    name: "Dados Bancários",
  },
};

const adminPartnerTabs = {
  dadosComerciais: {
    index: 0,
    name: "Dados Comerciais",
  },
  usuarios: {
    index: 1,
    name: "Usuários",
  },
  documentos: {
    index: 2,
    name: "Documentos",
  },
};

export const selectTab = (userTypeId, businessTypeId) => {
  if (userTypeId === 1) return sellerTabs;
  if (userTypeId === 2) return buyerTabs;

  if (userTypeId === 3) {
    if (businessTypeId === 1) return adminSellerTabs;
    if (businessTypeId === 2) return adminBuyerTabs;
    if (businessTypeId === 3) return adminPartnerTabs;
  }
};
