import React from 'react';

export default function Details({ color = '#A0A0A0', size = 40 }) {
  return (
    <svg
      id="bill"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 95.2) / 100}
      viewBox="0 0 43.09 41.025"
    >
      <path
        id="Caminho_94"
        data-name="Caminho 94"
        d="M348.711,42.206l.057,0a.7.7,0,0,0,.1-.007,1.56,1.56,0,0,1,1.528,1.558.7.7,0,0,0,1.4,0,2.96,2.96,0,0,0-2.323-2.887V40.2a.7.7,0,0,0-1.4,0v.7a2.956,2.956,0,0,0,.764,5.811,1.56,1.56,0,1,1-1.56,1.56.7.7,0,0,0-1.4,0,2.961,2.961,0,0,0,2.192,2.855V51.9a.7.7,0,0,0,1.4,0v-.733a2.956,2.956,0,0,0-.633-5.843,1.559,1.559,0,0,1-.122-3.113Zm0,0"
        transform="translate(-315.694 -36.091)"
      />
      <path
        id="Caminho_95"
        data-name="Caminho 95"
        d="M33.179.391a10.017,10.017,0,0,0-9.333,6.555H6.519a.73.73,0,0,0-.7.729l-.165,24.49H.717A.687.687,0,0,0,0,32.83v3.108A5.484,5.484,0,0,0,5.236,41.4v.015H26.79v-.02c.175.006.246.02.357.02h.015a5.484,5.484,0,0,0,5.475-5.483V20.219c.175.01.359.015.54.015a9.921,9.921,0,0,0,0-19.843ZM5.5,40.019h0a4.106,4.106,0,0,1-4.1-4.088v-2.37H21.642v2.36A5.477,5.477,0,0,0,23.258,39.8c.073.073.164.13.241.217Zm25.74-4.088a4.087,4.087,0,0,1-4.079,4.088h-.013a4.118,4.118,0,0,1-4.11-4.1V32.83a.644.644,0,0,0-.666-.665H7.052l.16-23.823H23.458a10.49,10.49,0,0,0-.191,1.939,9.844,9.844,0,0,0,.843,4H17.516a.7.7,0,1,0,0,1.4h7.351a9.852,9.852,0,0,0,6.374,4.372Zm1.938-17.094a8.516,8.516,0,1,1,8.516-8.516A8.515,8.515,0,0,1,33.179,18.837Zm0,0"
        transform="translate(0 -0.391)"
      />
      <path
        id="Caminho_96"
        data-name="Caminho 96"
        d="M126,212.746a2.165,2.165,0,1,0,2.235,2.164A2.2,2.2,0,0,0,126,212.746Zm0,2.931a.77.77,0,1,1,.839-.767A.806.806,0,0,1,126,215.677Zm0,0"
        transform="translate(-112.962 -194.215)"
      />
      <path
        id="Caminho_97"
        data-name="Caminho 97"
        d="M192.719,230.2a.7.7,0,0,0,.7.7h9.307a.7.7,0,0,0,0-1.4h-9.307A.7.7,0,0,0,192.719,230.2Zm0,0"
        transform="translate(-175.901 -209.51)"
      />
      <path
        id="Caminho_98"
        data-name="Caminho 98"
        d="M126,143.2a2.165,2.165,0,1,0,2.235,2.164A2.2,2.2,0,0,0,126,143.2Zm0,2.931a.771.771,0,1,1,.839-.767.806.806,0,0,1-.839.767Zm0,0"
        transform="translate(-112.962 -130.741)"
      />
      <path
        id="Caminho_99"
        data-name="Caminho 99"
        d="M126,282.281a2.165,2.165,0,1,0,2.235,2.164A2.2,2.2,0,0,0,126,282.281Zm0,2.931a.77.77,0,1,1,.839-.767A.806.806,0,0,1,126,285.212Zm0,0"
        transform="translate(-112.962 -257.682)"
      />
      <path
        id="Caminho_100"
        data-name="Caminho 100"
        d="M202.724,299.5h-9.307a.7.7,0,0,0,0,1.4h9.307a.7.7,0,0,0,0-1.4Zm0,0"
        transform="translate(-175.901 -273.402)"
      />
    </svg>
  );
}
