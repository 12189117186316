import React, { useEffect } from "react";
import { BrowserRouter, Switch } from "react-router-dom";

import { ThemeProvider } from "@material-ui/styles";
import MuiTheme from "theme";

import { getRedirectUrl } from "utils/origin";

const SignRoutes = () => {
  useEffect(() => {
    // window.location.replace(getRedirectUrl());
  }, []);

  return (
    <ThemeProvider theme={MuiTheme()}>
      <BrowserRouter>
        <Switch>
          {/*   <LoginProvider>
            <> */}
          {/* <Route
                path="/definitive-password"
                exact
                component={DefinitivePassword}
              />
              <Route path="/send-reset" exact component={RedefinirSenha} />
              <Route
                path="/password-recovery/:token"
                exact
                component={NewResetPassword}
              />
              <Route path="/" exact component={LoginWhiteLabel} />
              <Route path="/quiz" component={QuizPage} /> */}
          {/*     </>
          </LoginProvider> */}
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default SignRoutes;
