import React, { useState } from 'react';

import {
  Dialog,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';

import Content from 'example-components/content';

import { ConfirmButton } from 'components/Button';
import CadastroOrigin from 'components/CadastroOrigin';
import EnhancedTableHead from 'components/EnhancedTableHead';
import { Modal } from 'components/material/Modal';
import PageTitle from 'components/PageTitle';

import { useCadastroWhiteLabel } from 'hooks/useCadastroWhiteLabel';
import { useModal } from 'hooks/useModal';

import { headCells } from './items';

const useStyles = makeStyles({
  paper: {
    background: 'white',
    width: '105%',
    boxShadow: 'none',
    border: '1px solid #99999966',
    maxWidth: '100%',
  },
  pagination: {
    background: 'white',
    boxShadow: 'none',
    border: '1px solid #99999966',
    marginLeft: '-30px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    width: '105%',
    maxWidth: '100%',
    marginTop: '70%',
    position: 'absolute',
    bottom: '-75px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

function ListagemOrigin() {
  const classes = useStyles();

  const [openEdit, setOpenEdit] = useState(false);
  const [selectedOrigin, setSelectedOrigin] = useState(null);

  const {
    getComparator,
    handleRequestSort,
    isLoading,
    openCadastroOrigin,
    setOpenCadastroOrigin,
    order,
    orderBy,
    originList,
    page,
    rowsPerPage,
    stableSort,
  } = useCadastroWhiteLabel({ load: true });

  const { openModal, closeModal } = useModal();

  const handleClose = () => {
    setOpenEdit(false);
    setOpenCadastroOrigin(false);
  };

  const setOpenEditModal = async (origin) => {
    openModal('loading');
    // const origin = await getOriginType(origin);
    setSelectedOrigin(origin);
    setOpenEdit(true);
    closeModal();
  };

  return (
    <>
      <Content>
        <PageTitle titleHeading="Cadastro de WhiteLabel" />
        <div className="example-card-seamless mb-4-spacing">
          <h5 className="display-5 mb-4 font-weight-bold" />
        </div>

        <div style={{ marginTop: '30px', marginLeft: '-65px', width: '105%' }}>
          <div className="searchForm" style={{ marginLeft: '35px' }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginTop: '40px',
              }}
            >
              <ConfirmButton
                onClick={() => {
                  openModal('loading');
                  setOpenCadastroOrigin(true);
                  closeModal();
                }}
              >
                Criar WhiteLabel
              </ConfirmButton>
            </div>
            <div
              className="tableContainerInvoice"
              style={{
                position: 'relative',
                height: 'max-content',
              }}
            >
              {(originList === null || isLoading) && (
                <div>
                  <LinearProgress value={55} color="primary" />
                </div>
              )}
              {originList != null && !isLoading && (
                <>
                  <Paper className={classes.paper}>
                    <TableContainer>
                      <Table stickyHeader aria-label="sticky table">
                        <EnhancedTableHead
                          order={order}
                          orderBy={orderBy}
                          onRequestSort={handleRequestSort}
                          rowCount={originList.length}
                          headCells={headCells}
                          classes={classes}
                        />
                        {stableSort(originList, getComparator(order, orderBy))
                          .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                          .map((origin) => (
                            <>
                              <TableBody>
                                <TableCell style={{ textAlign: 'center' }}>
                                  <IconButton
                                    onClick={() => setOpenEditModal(origin)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                  {origin.originTypeShort}
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                  {origin.originTypeName}
                                </TableCell>
                                <TableCell style={{ textAlign: 'center' }}>
                                  {origin.hostname}
                                </TableCell>
                              </TableBody>
                            </>
                          ))}
                      </Table>
                    </TableContainer>
                  </Paper>
                </>
              )}
            </div>
          </div>
        </div>
      </Content>

      <Dialog
        onClose={() => setOpenCadastroOrigin(false)}
        disableBackdropClick
        aria-labelledby="simple-dialog-title"
        open={openCadastroOrigin}
        scroll="body"
        maxWidth="xl"
      >
        <Modal id="customized-dialog-title" onClose={handleClose} />

        <div
          style={{
            display: 'flex',
            width: '80vw',
          }}
        >
          <CadastroOrigin blank />
        </div>
      </Dialog>

      <Dialog
        onClose={() => setOpenEdit(false)}
        disableBackdropClick
        aria-labelledby="simple-dialog-title"
        open={openEdit}
        scroll="body"
        maxWidth="xl"
      >
        <Modal id="customized-dialog-title" onClose={handleClose} />

        <div
          style={{
            display: 'flex',
            width: '80vw',
          }}
        >
          <CadastroOrigin edit origin={selectedOrigin} />
        </div>
      </Dialog>
    </>
  );
}

export default ListagemOrigin;
