import useSWR from 'swr';

import { fetchWithTokenExternalService } from '../fetchWithToken';

export default function useCompany(
  fetch = false,
  businessId,
  options = {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    shouldRetryOnError: true,
    errorRetryInterval: 1000,
  }
) {
  const {
    data: company,
    error,
    isValidating,
    mutate,
  } = useSWR(
    fetch && businessId
      ? `${process.env.REACT_APP_NODE_API}/business/find/${businessId}`
      : false,
    fetchWithTokenExternalService,
    options
  );

  return {
    company: company && company.data ? company.data : null,
    error,
    isValidating,
    isLoading: fetch && businessId && !error && !company,
    mutate,
  };
}
