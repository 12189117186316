import React from 'react';

export default function Bell({ color = '#A0A0A0', size = 40 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 119.35) / 100}
      viewBox="0 0 31.143 37.171"
    >
      <g id="bell" transform="translate(0.5 0.5)">
        <g id="Grupo_7" data-name="Grupo 7" transform="translate(12.057 0)">
          <path
            id="Caminho_4"
            data-name="Caminho 4"
            d="M15.275,6.3a.754.754,0,0,1-.754-.754V3.014a1.507,1.507,0,1,0-3.014,0V5.546a.754.754,0,1,1-1.507,0V3.014a3.014,3.014,0,1,1,6.029,0V5.546A.753.753,0,0,1,15.275,6.3Z"
            transform="translate(-10 0)"
            fill={color}
            stroke={color}
            strokeWidth="1"
          />
        </g>
        <g id="Grupo_8" data-name="Grupo 8" transform="translate(9.796 30.143)">
          <path
            id="Caminho_5"
            data-name="Caminho 5"
            d="M13.775,26.029A5.281,5.281,0,0,1,8.5,20.754a.754.754,0,0,1,1.507,0,3.768,3.768,0,0,0,7.536,0,.754.754,0,1,1,1.507,0A5.281,5.281,0,0,1,13.775,26.029Z"
            transform="translate(-8.5 -20)"
            fill={color}
            stroke={color}
            strokeWidth="1"
          />
        </g>
        <g id="Grupo_9" data-name="Grupo 9" transform="translate(0 4.521)">
          <path
            id="Caminho_6"
            data-name="Caminho 6"
            d="M29.882,30.129H4.261A2.261,2.261,0,0,1,2.791,26.15a10.478,10.478,0,0,0,3.73-8.021V13.55a10.55,10.55,0,0,1,21.1,0v4.579a10.466,10.466,0,0,0,3.718,8.01,2.261,2.261,0,0,1-1.457,3.989ZM17.071,4.507A9.052,9.052,0,0,0,8.029,13.55v4.579a11.973,11.973,0,0,1-4.252,9.162.753.753,0,0,0,.484,1.331H29.882a.754.754,0,0,0,.49-1.326,11.983,11.983,0,0,1-4.258-9.166V13.55a9.052,9.052,0,0,0-9.043-9.043Z"
            transform="translate(-2 -3)"
            fill={color}
            stroke={color}
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
}
