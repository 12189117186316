const formatDateToHours = (date) =>
  `${
    new Date(date).getHours() < 10
      ? `0${new Date(date).getHours()}`
      : new Date(date).getHours()
  }:${
    new Date(date).getMinutes() < 10
      ? `0${new Date(date).getMinutes()}`
      : new Date(date).getMinutes()
  }:${
    new Date(date).getSeconds() < 10
      ? `0${new Date(date).getSeconds()}`
      : new Date(date).getSeconds()
  }`;

export default formatDateToHours;
