import AxiosInterceptor from "utils/axios-interceptor";

export const getTableCadastroFilter = async (isAgenda, shortOrigin) => {
  const axiosInstance = AxiosInterceptor();
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/business/filter?asc=desc&limit=50&isAgenda=${isAgenda}&shortOrigin=${shortOrigin}`
  );
  return response;
};

export const getTableCadastro = async (
  filterParams,
  orderBy,
  page,
  isAgenda,
  shortOrigin
) => {
  const axiosInstance = AxiosInterceptor();
  const response = await axiosInstance.get(
    `${
      process.env.REACT_APP_NODE_API
    }/business/filter?${filterParams}&offset=0&limit=50&orderBy=${
      orderBy !== undefined ? orderBy : "lastUpdateDate"
    }&asc=DESC&page=${
      page !== undefined ? page : "0"
    }&isAgenda=${isAgenda}&shortOrigin=${shortOrigin}`
  );

  return response;
};
