import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Dossie from 'components/Dossie';
import { useModal } from 'hooks/useModal';
import { useRecebiveis } from 'hooks/useRecebiveis';
import React, { useState } from 'react';
import { Recebiveis } from './Recebiveis';
import './styles.scss';
import NovaDetail from './Tabs/NovaDetail/NovaDetail';

function WithBackButton({ children, onClick, title }) {
  return (
    <div className="with-back-button">
      <div className="with-back-button--top">
        <IconButton onClick={onClick}>
          <ArrowBackIcon style={{ color: '#000000', fontSize: 36 }} />
        </IconButton>

        <h1>{title}</h1>
      </div>

      <div className="with-back-button--content">{children}</div>
    </div>
  );
}

export default function Lastro({ titulo, values }) {
  const typeId = 404;

  const { openModal, closeModal } = useModal();
  const [activeComponent, setActiveComponent] = useState({
    data: {},
    name: 'recebiveis',
  });
  const { refresh: refreshAgendas } = useRecebiveis(
    typeId,
    titulo?.business?.businessId,
    JSON.parse(titulo?.invoice?.recebiveis)
  );

  function getComponent() {
    switch (activeComponent.name) {
      case 'recebiveis': {
        return (
          <div>
            <div>
              <span className="title-lastro">Direitos creditórios</span>
            </div>
            <Recebiveis
              agendaValues={values}
              businessId={titulo?.business?.businessId}
              cadastroTitulo={false}
              changeActiveComponent={(component, data) =>
                setActiveComponent(component, data)
              }
              closeModal={closeModal}
              esconderStatus
              listaIdRecebiveis={JSON.parse(titulo?.invoice?.recebiveis)}
              openModal={openModal}
              style={{ marginBottom: 30 }}
              typeId={typeId}
            />
          </div>
        );
      }

      case 'detalhes': {
        return (
          activeComponent?.data?.agenda && (
            <WithBackButton
              onClick={() =>
                setActiveComponent({ data: {}, name: 'recebiveis' })
              }
              title={
                <span>
                  <strong style={{ color: '#383838', fontSize: 26 }}>
                    Detalhes NF{' '}
                  </strong>

                  <span style={{ color: '#383838', fontSize: 26 }}>
                    {activeComponent?.data?.agenda?.number}
                  </span>
                </span>
              }
            >
              <NovaDetail
                agenda={activeComponent?.data?.agenda}
                edit
                getAgendas={async () => {
                  await refreshAgendas(typeId);
                }}
                title="Detalhes"
                type="modal-lastro"
              />
            </WithBackButton>
          )
        );
      }

      case 'dossie': {
        return (
          activeComponent?.data?.agendaId && (
            <WithBackButton
              onClick={() =>
                setActiveComponent({ data: {}, name: 'recebiveis' })
              }
              title={
                <span>
                  <strong style={{ color: '#383838', fontSize: 26 }}>
                    Dossiê NF{' '}
                  </strong>

                  <span style={{ color: '#383838', fontSize: 26 }}>
                    {activeComponent?.data?.agenda?.number}
                  </span>
                </span>
              }
            >
              <Dossie
                agenda={activeComponent.data.agenda}
                agendaDocs={activeComponent.data.agendaDocs}
                agendaId={activeComponent.data.agendaId}
                edit={activeComponent.data.edit}
                setAgenda={activeComponent.data.setAgenda}
                setAgendaDocuments={activeComponent.data.setAgendaDocs}
                title="Dossiê"
                type="modal-lastro"
              />
            </WithBackButton>
          )
        );
      }

      default:
        return null;
    }
  }

  return <div className="lastro--container">{getComponent()}</div>;
}
