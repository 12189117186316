import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { getInvoiceById, updateRiskInvoice } from 'services/invoice';

import { useAuth } from 'contexts/auth';

export function useInvoice(invoiceId) {
  const [invoice, setInvoice] = useState();

  const { userOrigin } = useAuth();

  useEffect(() => {
    if (!invoiceId) return;
    handleGetInvoiceFromDatabase(invoiceId);

    return () => {
      setInvoice();
    };
  }, [invoiceId]);

  async function handleGetInvoiceFromDatabase(invoiceId) {
    const _invoice = await getInvoiceById(invoiceId, userOrigin);
    const { invoices } = _invoice;

    setInvoice(invoices[0]);
  }

  async function handleUpdateRiskInvoice(newRisk, invoiceId) {
    if (!newRisk) return;

    await toast.promise(updateRiskInvoice(newRisk, invoiceId), {
      loading: 'Atualizando Risco...',
      success: () => `Risco do invoice atualizado`,
      error: () => `Erro ao atualizar invoice`,
    });
  }

  return { invoice, updateRisk: handleUpdateRiskInvoice };
}
