import { useEffect, useState } from "react";
import { getBusinessByUserEmail, getOriginByHostname } from "services/origin";
import {
  getBusinessByBusinessIdWithToken,
  getCompanies,
  getDefaultCompany,
} from "services/business";
import { getRedirectUrl } from "utils/origin";
import { useAuth } from "contexts/auth";
import axios from "axios";
import { readCookie } from "utils/cookie";

const getGroupId = (group) => {
  switch (group) {
    case "Administrador":
      return 3;
    case "Cedente":
      return 1;
    case "Financiador":
      return 2;
    case "Parceiro":
      return 1;
  }
};

export const useAuthToken = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userTypeId, setUserTypeId] = useState("");

  const auth = useAuth();

  useEffect(() => {
    // prettier-ignore
    (async () => {

      try {
        setLoading(true)
        const applicationToken = readCookie('ApplicationToken')

        const user = (await axios.get(`${process.env.REACT_APP_URL_AUTH}/get-user`, {headers: {Authorization: `Bearer ${applicationToken}`}})).data;
        const userAttributes = user.UserAttributes.reduce((acc, { Name, Value }) => ({...acc, [Name]: Value }), {})
        const group = userAttributes['cognito:groups'][0] ?? ''

        const userTypeIdAux = parseInt(user.payload['custom:type_id']) || getGroupId(group)
    

        if (parseInt(user.payload['custom:type_id']) === 3 || getGroupId(group) === 3) {
          localStorage.setItem('@App:token', applicationToken);
          localStorage.setItem('bearer', applicationToken);
          const response = await getOriginByHostname(window.location.hostname)
          const business = await getBusinessByUserEmail(userAttributes.email, applicationToken)
          const statusCadastro = await getBusinessByBusinessIdWithToken(business?.businessId, applicationToken)

          if (!business?.businessId) {
            return window.location.replace(getRedirectUrl());
          }
          setLoggedIn(!!user)
          setUserTypeId(userTypeIdAux)
          localStorage.setItem('businessId', business?.businessId)
          localStorage.setItem('name', userAttributes.name)

          document.getElementById("descriptionTag").content = `${response.ORIGIN_TYPE_NAME} - Crédito ao Fornecedor e Capital de Giro`
          document.getElementById("titleTag").content = `${response.ORIGIN_TYPE_NAME} é um Mercado de Crédito PJ para Fornecedores. Negociamos NF-e, NFS-e, CT-e para sua empresa receber hoje pagamentos futuros.`

          const formatedUser = JSON.stringify({
            businessId: business?.businessId,
            name: userAttributes.name,
            email: userAttributes.email,
            userTypeId: userTypeIdAux,
            originType: response?.ORIGIN_TYPE_SHORT,
            origin: {
              originTypeShort: response?.ORIGIN_TYPE_SHORT,
              originTypeName: response?.ORIGIN_TYPE_NAME,
              hostname: response?.HOSTNAME,
              telaLogin: response?.TELALOGIN,
              tema: JSON.parse(response?.TEMA),
              logoTopo: response?.LOGO_TOPO,
              logoLogin: response?.LOGO_LOGIN,
              favIcon: response?.FAV_ICON,
              originTypeId: response?.ORIGIN_TYPE_ID,
              businessId: business?.businessId
            },
            company: {
              name: statusCadastro?.corporateName,
              fullName: statusCadastro?.corporateName
            },
            userId: userAttributes['custom:sql_id'],
            status_cadastro: statusCadastro?.statusId,
            tema: {
              shortOrigin: response?.ORIGIN_TYPE_SHORT,
              longOrigin: response?.ORIGIN_TYPE_NAME,
              logoTopo: response?.LOGO_TOPO,
              logoLogin: response?.LOGO_LOGIN,
              favIcon: response?.FAV_ICON,
              tema: JSON.parse(response?.TEMA)
            }
          })
          localStorage.setItem('@App:user', formatedUser);
          auth?.setUser(JSON.parse(formatedUser))
          setLoading(false)
      }  else {
        localStorage.setItem('@App:token', applicationToken);
        localStorage.setItem('bearer', applicationToken);
        localStorage.setItem('@App:token', applicationToken);
        localStorage.setItem('bearer', applicationToken);
        const response = await getOriginByHostname(window.location.hostname)
        const defaultCompany = await getDefaultCompany(applicationToken)
        const statusCadastro = await getBusinessByBusinessIdWithToken(defaultCompany?.businessId, applicationToken)
        const companies = await getCompanies(applicationToken)

        if (!defaultCompany?.businessId) {
          return window.location.replace(getRedirectUrl());
        } else if(!['4', '12', '13'].includes(String(defaultCompany?.statusId))){
          return window.location.replace(getRedirectUrl() + '/register-company');
        }

        document.getElementById("descriptionTag").content = `${response.ORIGIN_TYPE_NAME} - Crédito ao Fornecedor e Capital de Giro`
        document.getElementById("titleTag").content = `${response.ORIGIN_TYPE_NAME} é um Mercado de Crédito PJ para Fornecedores. Negociamos NF-e, NFS-e, CT-e para sua empresa receber hoje pagamentos futuros.`

        setLoggedIn(!!user)
        setUserTypeId(userTypeIdAux)
        localStorage.setItem('businessId', defaultCompany?.businessId)
        localStorage.setItem('name', userAttributes.name)

        const formatedUser = JSON.stringify({
          businessId: defaultCompany?.businessId,
          name: userAttributes.name,
          email: userAttributes.email,
          userTypeId: userTypeIdAux,
          originType: response?.ORIGIN_TYPE_SHORT,
          origin: {
            originTypeShort: response?.ORIGIN_TYPE_SHORT,
            originTypeName: response?.ORIGIN_TYPE_NAME,
            hostname: response?.HOSTNAME,
            telaLogin: response?.TELALOGIN,
            tema: JSON.parse(response?.TEMA),
            logoTopo: response?.LOGO_TOPO,
            logoLogin: response?.LOGO_LOGIN,
            favIcon: response?.FAV_ICON,
            originTypeId: response?.ORIGIN_TYPE_ID,
            businessId: defaultCompany?.businessId
          },
          companies: {
            companies
          },
          company: {
            name: statusCadastro?.corporateName,
            fullName: statusCadastro?.corporateName
          },
          defaultCompany: {
            defaultCompany
          },
          userId: userAttributes['custom:sql_id'],
          status_cadastro: statusCadastro?.statusId,
          tema: {
            shortOrigin: response?.ORIGIN_TYPE_SHORT,
            longOrigin: response?.ORIGIN_TYPE_NAME,
            logoTopo: response?.LOGO_TOPO,
            logoLogin: response?.LOGO_LOGIN,
            favIcon: response?.FAV_ICON,
            tema: JSON.parse(response?.TEMA)
          }
        })
        localStorage.setItem('@App:user', formatedUser);
        auth?.setUser(JSON.parse(formatedUser))
        setLoading(false)
      }
    } catch (err) {
      setLoggedIn(false)
      setLoading(false)
    }

    })()
  }, []);

  return {
    userTypeId,
    loggedIn,
    loading,
  };
};
