/* eslint eqeqeq: 0 */
import React, { useState, useEffect } from 'react';
import '../capa.scss';
import NumberFormat from 'react-number-format';

import { TextField, CircularProgress, Button } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { ReactIf, ReactThen } from 'components/ReactIf';

import { updateTaxasService } from 'services/taxas';

import { useAuth } from 'contexts/auth';
import { useToast } from 'contexts/toast';

import { taxasDescription } from './items';

const Taxas = (props) => {
  const { buyerRates, refreshData } = props;

  const { isCedente } = useAuth();
  const { toast } = useToast();

  const [buyerRate, setBuyerRate] = useState(undefined);
  // const [allFilled, setAllFilled] = useState(true);
  const [savingBuyerRate, setSavingBuyerRate] = useState(false);

  const updateBuyerRate = async () => {
    if (buyerRate === undefined || savingBuyerRate) return;

    setSavingBuyerRate(true);

    const standardizeNumber = (value) => {
      if (typeof value !== 'string') return value;
      const standardValue = value.replaceAll('.', '').replaceAll(',', '.');

      return parseFloat(standardValue);
    };

    const updateBuyerRate = {
      adValorem: standardizeNumber(buyerRate.adValorem),
      analysis: standardizeNumber(buyerRate.analysis),
      bankSlip: standardizeNumber(buyerRate.bankSlip),
      businessId: buyerRate.businessId,
      ccb: standardizeNumber(buyerRate.ccb),
      commission: standardizeNumber(buyerRate.commission),
      iofAmount: standardizeNumber(buyerRate.iofAmount),
      iofTerm: standardizeNumber(buyerRate.iofTerm),
      operation: standardizeNumber(buyerRate.operation),
      paymentTerm: standardizeNumber(buyerRate.paymentTerm),
      serpro: standardizeNumber(buyerRate.serpro),
    };

    await toast.promise(
      updateTaxasService(buyerRate.businessId, updateBuyerRate),
      {
        loading: 'Carregando taxas',
        success: 'Taxas atualizadas com sucesso',
        error: 'Erro ao atualizar taxas',
      }
    );

    setSavingBuyerRate(false);
    window.scrollTo(0, 0);

    await toast.promise(refreshData(), {
      loading: 'Recarregando taxas',
      success: 'Taxas recarregadas',
      error: 'Erro ao recarregar taxas',
    });
  };

  const handleChange = (e) => {
    const newState = { ...buyerRate, [e.target.name]: e.target.value };

    setBuyerRate(newState);
  };

  useEffect(() => {
    setBuyerRate(buyerRates);
  }, [buyerRates]);

  return (
    <div style={{ marginTop: '30px', width: '100%' }}>
      <div className="searchForm" style={{ marginBottom: 0 }}>
        <div className="mt-4 containerTitle w-100">
          <div className="flex-row itemTile d-flex justify-content-between w-100">
            <h3 style={{ fontFamily: 'Poppins, sans-serif' }}>
              Custos operacionais
              <Tooltip
                placement="right"
                title={
                  taxasDescription.find((taxas) => taxas?.id == 1).description
                }
              >
                <InfoOutlinedIcon
                  style={{
                    marginLeft: '10px',
                  }}
                />
              </Tooltip>
            </h3>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <div className="flex-row d-flex justify-content-between w-100 responsible">
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px',
              }}
            >
              <NumberFormat
                fullWidth
                className="m-2"
                label="Ad Valorem (%)"
                name="adValorem"
                disabled={isCedente()}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={4}
                fixedDecimalScale
                value={buyerRate?.adValorem}
                onChange={handleChange}
                customInput={TextField}
              />
              <Tooltip
                placement="top"
                title={
                  taxasDescription.find((taxas) => taxas?.id == 3).description
                }
              >
                <InfoOutlinedIcon
                  style={{
                    marginLeft: '-1em',
                    zIndex: 999,
                  }}
                />
              </Tooltip>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px',
              }}
            >
              <NumberFormat
                fullWidth
                className="m-2"
                label="IOF do Valor (%)"
                name="iofAmount"
                disabled={isCedente()}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={4}
                fixedDecimalScale
                value={buyerRate?.iofAmount}
                onChange={handleChange}
                customInput={TextField}
              />
              <Tooltip
                placement="top"
                title={
                  taxasDescription.find((taxas) => taxas?.id == 4).description
                }
              >
                <InfoOutlinedIcon
                  style={{
                    marginLeft: '-1em',
                    zIndex: 999,
                  }}
                />
              </Tooltip>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px',
              }}
            >
              <NumberFormat
                fullWidth
                className="m-2"
                label="IOF do Prazo (%)"
                name="iofTerm"
                disabled={isCedente()}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={4}
                fixedDecimalScale
                value={buyerRate?.iofTerm}
                onChange={handleChange}
                customInput={TextField}
              />
              <Tooltip
                placement="top"
                title={
                  taxasDescription.find((taxas) => taxas?.id == 5).description
                }
              >
                <InfoOutlinedIcon
                  style={{
                    marginLeft: '-1em',
                    zIndex: 999,
                  }}
                />
              </Tooltip>
            </div>

            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px',
              }}
            >
              <NumberFormat
                fullWidth
                className="m-2"
                label="Prazo de Liquidação/Floating (Dias)"
                name="paymentTerm"
                disabled={isCedente()}
                thousandSeparator="."
                decimalSeparator=","
                value={buyerRate?.paymentTerm}
                onChange={handleChange}
                customInput={TextField}
              />
              <Tooltip
                placement="top"
                title={
                  taxasDescription.find((taxas) => taxas?.id == 6).description
                }
              >
                <InfoOutlinedIcon
                  style={{
                    marginLeft: '-1em',
                    zIndex: 999,
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <div className="flex-row d-flex justify-content-between w-100 responsible">
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px',
              }}
            >
              <NumberFormat
                fullWidth
                className="m-2"
                label="Taxa Cobrança (R$)"
                name="bankSlip"
                disabled={isCedente()}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
                value={buyerRate?.bankSlip}
                onChange={handleChange}
                customInput={TextField}
              />
              <Tooltip
                placement="top"
                title={
                  taxasDescription.find((taxas) => taxas?.id == 7).description
                }
              >
                <InfoOutlinedIcon
                  style={{
                    marginLeft: '-1em',
                    zIndex: 999,
                  }}
                />
              </Tooltip>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px',
              }}
            >
              <NumberFormat
                fullWidth
                className="m-2"
                label="Taxa de Operação (%)"
                name="operation"
                disabled={isCedente()}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={4}
                fixedDecimalScale
                value={buyerRate?.operation}
                onChange={handleChange}
                customInput={TextField}
              />
              <Tooltip
                placement="top"
                title={
                  taxasDescription.find((taxas) => taxas?.id == 8).description
                }
              >
                <InfoOutlinedIcon
                  style={{
                    marginLeft: '-1em',
                    zIndex: 999,
                  }}
                />
              </Tooltip>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '10px',
              }}
            >
              <NumberFormat
                fullWidth
                className="m-2"
                label="Taxa de Análise (R$)"
                name="analysis"
                disabled={isCedente()}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
                value={buyerRate?.analysis}
                onChange={handleChange}
                customInput={TextField}
              />
              <Tooltip
                placement="top"
                title={
                  taxasDescription.find((taxas) => taxas?.id == 9).description
                }
              >
                <InfoOutlinedIcon
                  style={{
                    marginLeft: '-1em',
                    zIndex: 999,
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <div
            // className="searchForm"
            className="d-flex flex-column"
          >
            <div className="mt-4 containerTitle w-100">
              <div className="flex-row itemTile d-flex justify-content-between w-100">
                <h3 style={{ fontFamily: 'Poppins, sans-serif' }}>
                  Antecipa Gov
                  <Tooltip
                    placement="right"
                    title={
                      taxasDescription.find((taxas) => taxas?.id == 2)
                        .description
                    }
                  >
                    <InfoOutlinedIcon
                      style={{
                        marginLeft: '10px',
                      }}
                    />
                  </Tooltip>
                </h3>
              </div>
            </div>
            <div className="flex-row d-flex justify-content-between w-100 responsible">
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '10px',
                }}
              >
                <NumberFormat
                  fullWidth
                  className="m-2"
                  label="Taxa SERPRO (%)"
                  name="serpro"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={4}
                  fixedDecimalScale
                  value={buyerRate?.serpro}
                  onChange={handleChange}
                  customInput={TextField}
                  disabled="true" // {isCedente()}
                />
                <Tooltip
                  placement="top"
                  title={
                    taxasDescription.find((taxas) => taxas?.id == 10)
                      .description
                  }
                >
                  <InfoOutlinedIcon
                    style={{
                      marginLeft: '-1em',
                      zIndex: 999,
                    }}
                  />
                </Tooltip>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '10px',
                }}
              >
                <NumberFormat
                  fullWidth
                  className="m-2"
                  label="Taxa CCB (%)"
                  name="ccb"
                  disabled={isCedente()}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={4}
                  fixedDecimalScale
                  value={buyerRate?.ccb}
                  onChange={handleChange}
                  customInput={TextField}
                />
                <Tooltip
                  placement="top"
                  title={
                    taxasDescription.find((taxas) => taxas?.id == 11)
                      .description
                  }
                >
                  <InfoOutlinedIcon
                    style={{
                      marginLeft: '-1em',
                      zIndex: 999,
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="pr-2 item w-100 ">
            <div
              className={`containerDatePicker w-100 d-flex flex-row align-items-center justify-content-"between"`}
            />
          </div>
        </div>

        <div
          className="d-flex justify-content-end"
          style={{ paddingRight: '2.5%' }}
        >
          <ReactIf condition={!isCedente()}>
            <ReactThen>
              <div style={{ position: 'relative' }}>
                <Button
                  variant="contained"
                  className="text-white"
                  style={{
                    backgroundColor: savingBuyerRate ? '#878a94' : '#373A44',
                  }}
                  disabled={savingBuyerRate}
                  onClick={() => updateBuyerRate()}
                >
                  Salvar
                </Button>
                {savingBuyerRate && (
                  <CircularProgress
                    size={24}
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: -12,
                      marginLeft: -12,
                      color: 'green',
                    }}
                  />
                )}
              </div>
            </ReactThen>
          </ReactIf>
        </div>
      </div>
    </div>
  );
};

export default Taxas;
