function mascaraMoeda(valor) {
  const onlyDigits = valor
    .split("")
    .filter((s) => /\d/.test(s))
    .join("")
    .padStart(3, "0");
  const digitsFloat = `${onlyDigits.slice(0, -2)}.${onlyDigits.slice(-2)}`;
  valor = maskCurrency(digitsFloat);
  return valor;
}

function maskCurrency(valor, locale = "pt-BR", currency = "BRL") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(valor);
}

function formatReal(valor) {
  if (!valor) return mascaraMoeda("0");

  return mascaraMoeda(`${valor}`);
}

export function removeRealFormat(valor_formatado) {
  if (!isNaN(valor_formatado)) {
    return valor_formatado;
  }

  return parseFloat(
    valor_formatado.replace(/\D/g, (match) => {
      if (match === ".") {
        return "";
      } else if (match === ",") {
        return ".";
      } else {
        return "";
      }
    })
  );
}

export default formatReal;
