import React from 'react';

import { Button, TableCell, TableRow } from '@material-ui/core';

import formatReal from 'utils/formatReal';
import { removerCaracteresEspeciais } from 'utils/removerCaracteresEspeciais';

import Moment from 'moment';

import Details from '../../Icons/Details';
import Dossier from '../../Icons/Dossier';
import './styles.scss';

function calcularRisco(riskName) {
  switch (riskName) {
    case 'AA':
      return '#05ff22';
    case 'AB':
      return '#9afe06';
    case 'BA':
      return '#fef306';
    case 'BB':
      return '#fff203';
    case 'CA':
      return '#fb7f03';
    case 'CB':
      return '#e37100';
    case 'INAPTO':
      return '#c80203';
    case 'Não Definido':
      return '#0048bd';
    case 'Médio':
      return '#0048bd';
    default:
      return '#0048bd';
  }
}

export const AgendaRow = (props) => {
  const { agenda, setOpenDetailModal, setOpenDossieModal, agendaValue } = props;

  function parseAgenda(agenda) {
    // Removendo caracteres especiais do status
    agenda.status_name = removerCaracteresEspeciais(agenda.status_name);
    return agenda;
  }

  const row = parseAgenda(agenda);

  return (
    <>
      <TableRow key={row.agenda_id} className="container-agenda-row">
        <TableCell component="th" scope="row">
          <span className="lastrosTextValueNumber">{row.number}</span>
        </TableCell>

        <TableCell>
          <div>
            <div
              className="leilao-card--rating"
              style={{
                backgroundColor: calcularRisco(row.risk_name),
              }}
            >
              {row.risk_name}
            </div>
          </div>
        </TableCell>

        <TableCell>
          <div className="lastrosTextValueSacado">{row.drawee?.name}</div>
          <div className="lastrosTextCnpj">{row.drawee?.country_identity}</div>
        </TableCell>

        <TableCell>
          <div className="lastrosTextValueNumber">
            {Moment(row.first_maturity_date).utc(0).format('DD/MM/YYYY')}
          </div>
          <div className="lastrosTextValueNumber">
            {row.maturities} parcela{row.maturities !== 1 ? 's' : ''}
          </div>
        </TableCell>

        <TableCell>
          <span className="lastrosTextValue">
            {formatReal(parseFloat(agendaValue).toFixed(2))}
          </span>
        </TableCell>

        <TableCell>
          <span className="lastrosTextValue">
            {formatReal(parseFloat(row.commited_value).toFixed(2))}
          </span>
        </TableCell>

        <TableCell>
          <div style={{ display: 'flex', gap: 15 }}>
            <Button
              onClick={() => {
                setOpenDossieModal(row.agenda_id);
              }}
              color="secondary"
              variant="contained"
              classes={{ root: 'buttonRootDossie' }}
              startIcon={<Dossier size={30} />}
            >
              Dossiê
            </Button>

            <Button
              onClick={() => setOpenDetailModal(row.agenda_id)}
              variant="contained"
              classes={{ root: 'buttonRootDetails' }}
              startIcon={<Details size={40} />}
            >
              Detalhes
            </Button>
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};
