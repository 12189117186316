import AxiosInterceptor from "utils/axios-interceptor";

export const getBusinessResponsibles = async (businessId) => {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/responsibles/partners/${businessId}`
  );
  return data;
};

export const updateBusinessResponsibles = async (businessRelateds) => {
  const axiosInstance = AxiosInterceptor();

  await axiosInstance.patch(
    `${process.env.REACT_APP_NODE_API}/responsibles/update`,
    businessRelateds
  );
};

export const deleteBusinessResponsibles = async (removedRelateds) => {
  const axiosInstance = AxiosInterceptor();

  await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/responsibles/delete`,
    removedRelateds
  );
};
