import React, { useState } from "react";
import axios from "axios";
import "./cotacaoSimulator.scss";
import NumberFormat from "react-number-format";
import { Button, CircularProgress } from "@material-ui/core";
import * as moment from "moment";
import CloseIcon from "@material-ui/icons/Close";

import { useSnackbar } from "notistack";

export default (props) => {
  const axiosInstance = AxiosInterceptor();
  const initSimulator = {
    valorAFinanciar: 35000,
    taxaMensal: 1,
    numeroParcelas: 12,
    comissao: 0,
    dataPrimeiraParcela: moment(new Date())
      .add("30", "days")
      .toISOString()
      .substr(0, 10),
    dataInicioOperacao: new Date().toISOString().substr(0, 10),
  };
  const { handlerMaturity, row: cotacao, close } = props;
  const [valuesSimulator, setValuesSimulator] = useState([]);
  const [invoiceSummary, setInvoiceSummary] = useState(null);
  const [loadingSimulator, setLoadingSimulator] = useState(false);
  const [loadingRegistroOp, setLoadingRegistroOp] = useState(false);
  const [loadingTitulo, setLoadingTitulo] = useState(false);
  const [simulatorObj, setSimulatorObj] = useState({ ...initSimulator });

  const token = localStorage.getItem("bearer");
  const { enqueueSnackbar } = useSnackbar();

  const currencyFormatter = (value) => {
    if (!Number(value)) return 0;
    const amount = Number(value).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return amount;
  };

  const HttpCreateInvoice = async (data) => {
    return axiosInstance.post(
      `${process.env.REACT_APP_ANTICORS}/${process.env.REACT_APP_API_URL}/serpro/CreateInvoice`,
      data
    );
  };

  const HttpCreateBid = async (data) => {
    return axiosInstance.post(
      `${process.env.REACT_APP_ANTICORS}/${process.env.REACT_APP_API_BUYER}/bids`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const HttpLinkBidToInvoice = async (invoiceId) => {
    return axiosInstance.get(
      `${process.env.REACT_APP_ANTICORS}/${process.env.REACT_APP_SERPRO_PHP}/index.php?invoiceId=${invoiceId}`
    );
  };
  const criarTitulo = async () => {
    if (!valuesSimulator.length) return;
    setLoadingTitulo(true);
    cotacao.InvoiceMaturityCommands = valuesSimulator.map((valueSimulator) => {
      return {
        value: valueSimulator.amort_cc,
        maturityDate: new Date(valueSimulator.dat_parcela),
        maturityDays: valueSimulator.dias_parcela,
        juros_cc: valueSimulator.juros_cc,
      };
    });
    cotacao.ValorCotacao = valuesSimulator.reduce(
      (acc, obj) => (acc += obj.amort_cc),
      0
    );
    cotacao.TaxMax = parseFloat(
      simulatorObj.taxaMensal.toString().replace("%", "").replace(",", ".")
    );
    const invoiceCommand = { ...cotacao, resumo: { ...invoiceSummary } };
    // CRIAR TITULO
    try {
      var { data: invoiceCreated = null } = await HttpCreateInvoice(
        invoiceCommand
      );
      enqueueSnackbar("Título criado!", { variant: "success" });
    } catch (error) {
      let message =
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Falha ao criar o título!";
      enqueueSnackbar(message, { variant: "error" });
      return;
    } finally {
      setLoadingTitulo(false);
    }

    // verificar se é bradesco (tem pre aprovado)
    if (cotacao.preAprovado) {
      // CRIAR LANCE
      try {
        const bid = await HttpCreateBid({
          invoiceId: invoiceCreated.invoiceId,
          factor: cotacao.TaxMax,
          note: "AntecipaGov",
        });

        // VINCULAR LANCE AO INVOICE
        try {
          await HttpLinkBidToInvoice(invoiceCreated.invoiceId);
          enqueueSnackbar("Lance criado!", { variant: "default" });
        } catch (error) {
          const {
            response: {
              data: { messages } = ["Falha ao vincular o lance ao título"],
            },
          } = error;
          const errorMessage =
            messages && messages.length
              ? messages[0]
              : "Falha ao vincular o lance ao título";
          enqueueSnackbar(errorMessage, { variant: "error" });
        } finally {
          setLoadingTitulo(false);
        }
      } catch (error) {
        const {
          response: { data: { messages } = ["Falha ao criar o lance"] },
        } = error;
        const errorMessage =
          messages && messages.length ? messages[0] : "Falha ao criar o lance";
        enqueueSnackbar(errorMessage, { variant: "error" });
        setLoadingTitulo(false);
      }
    }
  };
  const getSimulator = () => {
    if (loadingSimulator || loadingTitulo) return;
    setLoadingSimulator(true);
    let simulatorNew = { ...simulatorObj };
    simulatorNew["valorAFinanciar"] = parseFloat(
      simulatorObj["valorAFinanciar"]
        .toString()
        .replace("R$", "")
        .replace(".", "")
        .replace(",", ".")
    );
    simulatorNew["taxaMensal"] = parseFloat(
      simulatorObj["taxaMensal"].toString().replace("%", "").replace(",", ".")
    );
    // simulatorNew['comissao'] = parseFloat(simulatorObj['comissao'].toString().replace('%', '').replace(',', '.'));
    simulatorNew.dataPrimeiraParcela = simulatorObj.dataPrimeiraParcela;
    simulatorNew.dataInicioOperacao = simulatorObj.dataInicioOperacao;
    simulatorNew.numeroParcelas = simulatorObj.numeroParcelas;

    if (!simulatorObj.dataPrimeiraParcela || !simulatorObj.dataInicioOperacao) {
      simulatorNew.dataPrimeiraParcela = new Date();
      simulatorNew.dataInicioOperacao = new Date();
    }

    cotacao.InvoiceMaturityCommands = simulatorNew.InvoiceMaturityCommands;
    // setSimulatorObj(simulatorNew)

    simulatorNew.niFornecedor = cotacao.niFornecedor;
    axiosInstance
      .post(
        `${process.env.REACT_APP_ANTICORS}/${process.env.REACT_APP_API_URL}/serpro/simulator`,
        JSON.stringify(simulatorNew),
        {
          headers: {
            "Content-Type": `application/json`,
          },
        }
      )
      .then((response) => {
        setValuesSimulator(response.data.parcelas);
        setInvoiceSummary(response.data.resumo);
      })
      .catch((error) => {})
      .finally(() => setLoadingSimulator(false));
  };

  const handlerChange = (e) => {
    setSimulatorObj({ ...simulatorObj, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="containerTitleSimulator">
        <div className="containerHead">
          <h3>Simulação</h3>
          <CloseIcon
            onClick={() => close(false)}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="containerInputsGroup">
          <div className="containerInput">
            <label>Valor a financiar</label>
            <NumberFormat
              name="valorAFinanciar"
              thousandSeparator={"."}
              decimalSeparator={","}
              prefix={"R$"}
              value={simulatorObj.valorAFinanciar}
              onChange={handlerChange}
            />
          </div>
          <div className="containerInput">
            <label>Taxa Mensal</label>
            <NumberFormat
              thousandSeparator={false}
              value={simulatorObj.taxaMensal}
              onChange={handlerChange}
              name="taxaMensal"
              suffix={"%"}
              decimalSeparator={","}
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </div>
          {/* <div className="containerInput">
            <label>TAC</label>
            <NumberFormat
              thousandSeparator={false}
              value={simulatorObj.comissao}
              onChange={handlerChange}
              name="comissao"
              suffix={'%'}
              decimalSeparator={","}
            />
          </div> */}
          <div className="containerInput">
            <label>Número de Parcelas</label>
            <NumberFormat
              name="numeroParcelas"
              thousandSeparator={true}
              onChange={handlerChange}
              value={simulatorObj.numeroParcelas}
            />
          </div>
          <div className="containerInput">
            <label>Data de Início da Operação</label>
            <input
              type="date"
              name="dataInicioOperacao"
              onChange={handlerChange}
              // defaultValue={new Date()}
              value={simulatorObj.dataInicioOperacao}
            />
          </div>
          <div className="containerInput">
            <label>Data da Primeira Parcela</label>
            <input
              type="date"
              name="dataPrimeiraParcela"
              onChange={handlerChange}
              // defaultValue={new Date()}
              value={simulatorObj.dataPrimeiraParcela}
            />
          </div>
        </div>
        <div className="tableParcelContainer">
          <table>
            <thead>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <strong>#</strong>
                </td>
                <td style={{ textAlign: "center" }}>
                  <strong>Data Parcela</strong>
                </td>
                <td style={{ textAlign: "right" }}>
                  <strong>Valor da Parcela</strong>
                </td>
                <td style={{ textAlign: "right" }}>
                  <strong>Juros</strong>
                </td>
                <td style={{ textAlign: "right" }}>
                  <strong>IOF Parcela</strong>
                </td>
                <td style={{ textAlign: "right" }}>
                  <strong>IOF Operação</strong>
                </td>
              </tr>
            </thead>
            <tbody>
              {!loadingSimulator &&
                valuesSimulator.map((valueSimulator) => (
                  <tr key={valueSimulator.num_parcela}>
                    <td style={{ textAlign: "center" }}>
                      {valueSimulator.num_parcela}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {moment(new Date(valueSimulator.dat_parcela)).format(
                        "DD/MM/YYYY"
                      )}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {Number(valueSimulator.amort_cc).toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {Number(valueSimulator.juros_cc).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "BRL",
                      })}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {Number(valueSimulator.iof_parc).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "BRL",
                      })}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {Number(valueSimulator.iof_38).toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "BRL",
                      })}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {loadingSimulator && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress value={55} color="primary" />
          </div>
        )}
        {!loadingSimulator && valuesSimulator.length <= 0 && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Preencha adequadamente os campos acima e faça a simulação.
          </div>
        )}
        <div className="containerGenerateTitle">
          <div style={{ placeSelf: "flex-end" }}>
            {/* <Button
              className={"btnCalc " + (!valuesSimulator.length ? "disabledBtn" : "")}
              onClick={registrarOperacao}>
              {loadingRegistroOp ? <CircularProgress size={25} color="#fff" /> : "Registrar operação"}
            </Button> */}
            {valuesSimulator && valuesSimulator.length > 0 && (
              <p>
                <strong>Valor total: </strong>
                <span>
                  {parseFloat(
                    valuesSimulator.reduce(
                      (acc, obj) => (acc += obj.amort_cc),
                      0
                    )
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                  })}
                </span>
              </p>
            )}
          </div>
          <div className="d-flex align-items-center">
            <Button
              className={
                "gerar-titulo " +
                (loadingTitulo || !valuesSimulator.length ? "disabledBtn" : "")
              }
              onClick={criarTitulo}
            >
              {loadingTitulo ? (
                <CircularProgress size={25} color="secondary" />
              ) : (
                "Gerar Titulo"
              )}
            </Button>
            <Button
              disabled
              className={"btnCalc " + (loadingSimulator ? "disabledBtn" : "")}
              onClick={getSimulator}
            >
              {loadingSimulator ? (
                <CircularProgress size={25} color="secondary" />
              ) : (
                "Calcular"
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
