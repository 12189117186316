import React from 'react';

import { Button } from '@material-ui/core';

export default function RejectButton({ disabled, onClick }) {
  return (
    <Button
      fullWidth
      disabled={disabled}
      variant="contained"
      color="secondary"
      onClick={onClick}
    >
      Rejeitar
    </Button>
  );
}
