import React, { useEffect, useState } from "react";

import { Tab, Tabs } from "@material-ui/core";

import { PageTitle } from "layout-components";

import "./capa.scss";

import { Recebiveis } from "components/Recebiveis";

import { getAgenda } from "services/agenda";

import { useAuth } from "contexts/auth";

import Nova from "./tabs/Nova";
import TabPage from "./tabs/TabPage";

function TabPanel(props) {
  const { children, value, index } = props;

  return <>{value === index && <>{children}</>}</>;
}

export default () => {
  const tabs = {
    agendas: {
      index: 0,
      name: "Minhas Notas Fiscais",
    },
    nova: {
      index: 1,
      name: "Adicionar Nota Fiscal",
    },
    documentos: {
      index: 2,
      name: "Documentos",
    },
  };

  const { isAdmin, user } = useAuth();

  const [agendas, setAgendas] = useState(undefined);

  const [tabValue, setTabValue] = useState(0);

  async function getAgendas() {
    const data = await getAgenda(isAdmin() ? undefined : user.businessId);
    setAgendas(data);
  }

  useEffect(() => {
    getAgendas();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const { businessId: sessionBusinessId } = localStorage.getItem("@App:user");

  return (
    <>
      <PageTitle titleHeading="Agenda de Recebíveis" />

      <div className="example-card-seamless mb-4-spacing">
        <h5 className="display-5 mb-4 font-weight-bold" />
        <Tabs
          value={tabValue}
          aria-label="simple tabs example"
          onChange={handleTabChange}
          style={{ marginLeft: "-30px" }}
        >
          <Tab label={tabs.agendas.name} />
          <Tab label={tabs.nova.name} />
        </Tabs>

        <TabPanel value={tabValue} index={tabs.nova.index}>
          <Nova
            blank
            agenda={{}}
            agendas={agendas}
            getAgendas={getAgendas}
            initiposacado="Pessoa Jurídica"
          />
        </TabPanel>

        <TabPanel value={tabValue} index={tabs.agendas.index}>
          <TabPage title="Minhas Notas Fiscais">
            <div className="containerTitle w-100 mt-4">
              <Recebiveis
                typeId={404}
                edit
                criarTitulo
                esconderValorComprometido
                esconderSacado
                esconderDataVencimento
                esconderRisco
                esconderPorcentagem
                esconderValorUtlizadoTitulo
                businessId={sessionBusinessId}
              />
            </div>
          </TabPage>
        </TabPanel>
      </div>
    </>
  );
};
