class Customer {
  constructor(
    customerId,
    businessId,
    statusId,
    statusName,
    name,
    personTypeId,
    personTypeName,
    countryIdentity,
    officePhone,
    email,
    contactName
  ) {
    this.customerId = customerId;
    this.businessId = businessId;
    this.name = name;
    this.countryIdentity = countryIdentity;
    this.statusId = statusId;
    this.statusName = statusName;
    this.personTypeId = personTypeId;
    this.personTypeName = personTypeName;
    this.contactName = contactName;
    this.email = email;
    this.officePhone = officePhone;
  }
}

export default Customer;
