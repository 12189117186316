import React from 'react';

import './styles.scss';

import { TextField, Box, InputAdornment } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import formatDate from 'utils/formatDate';
import formatReal from 'utils/formatReal';

import { useMaturitys } from 'hooks/useMaturitys';

import { Calendar, Produtos, Servicos } from '../../../../components/Icons';

const Icons = {
  Produtos: <Produtos size={25} />,
  Serviços: <Servicos size={25} />,
};
function BoxTitle({ titulo }) {
  return (
    <Box
      sx={{
        display: 'grid',
        p: 0,
        bgcolor: '#fceddd',
        pl: 1,
        fontSize: 22,
        fontWeight: '600',
        gridTemplateColumns: '100%',
      }}
      justifyContent="flex-start"
      height="65px"
      alignItems="center"
    >
      {' '}
      {titulo}{' '}
    </Box>
  );
}

export default function Titulo({ titulo: { invoice } }) {
  const { maturitys, isLoadingMatutitys } = useMaturitys(invoice.invoiceId);

  return (
    <Box
      id="titulo"
      sx={{
        border: '1px solid',
        borderColor: '#DEDEDE',
        borderRadius: '5px',
      }}
      className="container--title"
    >
      <BoxTitle titulo="Informações do Título" />
      <Box
        sx={{
          display: 'grid',
          p: 4,
          gridTemplateColumns: 'repeat(6, 1fr)',
          gap: 32,
        }}
        justifyContent="flex-start"
      >
        <TextField
          classes={{ root: 'textField--titles--root' }}
          fullWidth
          label="Id"
          variant="outlined"
          value={invoice.id}
          disabled
        />
        <TextField
          classes={{ root: 'textField--titles--root' }}
          fullWidth
          label="Tipo"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {Icons[invoice.typeName]}
              </InputAdornment>
            ),
          }}
          variant="outlined"
          value={invoice.typeName}
          disabled
        />
        <TextField
          classes={{ root: 'textField--titles--root' }}
          label="Risco"
          variant="outlined"
          value={invoice.riskName}
          disabled
          fullWidth
        />

        <TextField
          classes={{ root: 'textField--titles--root' }}
          label="Valor Total"
          variant="outlined"
          value={formatReal(invoice.maturitiesValue.toFixed(2))}
          fullWidth
          disabled
        />
        <TextField
          classes={{ root: 'textField--titles--root' }}
          fullWidth
          label="Primeiro Vencimento"
          variant="outlined"
          value={formatDate(invoice.issueDate)}
          disabled
        />
        <TextField
          classes={{ root: 'textField--titles--root' }}
          label="Nº de parcelas"
          variant="outlined"
          value={invoice.maturities}
          disabled
          fullWidth
        />
      </Box>
      {isLoadingMatutitys && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 1,
            m: 1,
          }}
        >
          <CircularProgress
            size={30}
            color="primary"
            loading={isLoadingMatutitys}
          />
        </Box>
      )}

      {maturitys && !isLoadingMatutitys && (
        <>
          <BoxTitle titulo={`Vencimentos (${maturitys.length})`} />
          <Box
            sx={{
              display: 'grid',
              gap: 32,
              p: 4,
              gridTemplateColumns: '60% 40%',
            }}
            justifyContent="flex-start"
            alignItems="start"
          >
            <Box
              sx={{
                display: 'grid',
                gap: 32,
                alignItems: 'center',
                gridTemplateColumns: '5% 30% 25% 25%',
              }}
            >
              {maturitys?.map((item, index) => (
                <>
                  <Box
                    sx={{
                      bgcolor: '#ddf2d7',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    {' '}
                    {index + 1}{' '}
                  </Box>

                  <Box>
                    <TextField
                      classes={{ root: 'textField--titles--root' }}
                      fullWidth
                      label="Data de vencimento"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Calendar size={20} />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      value={formatDate(item.originalDate)}
                      disabled
                    />
                  </Box>
                  <Box>
                    <TextField
                      classes={{ root: 'textField--titles--root' }}
                      fullWidth
                      label="Valor"
                      variant="outlined"
                      value={formatReal(item.valor)}
                      disabled
                    />
                  </Box>
                  <Box>
                    <TextField
                      classes={{ root: 'textField--titles--root' }}
                      fullWidth
                      label="Prazo em dias"
                      variant="outlined"
                      value={item.dias}
                      disabled
                    />
                  </Box>
                </>
              ))}
            </Box>
            <Box
              sx={{
                display: 'grid',
                gap: 16,
                alignItems: 'center',
                gridTemplateColumns: '100%',
              }}
            >
              <div className="vitrine--container--dotted">
                <span>Valor total dos Vencimentos</span>
                <h1>{formatReal(invoice.maturitiesValue.toFixed(2))}</h1>
              </div>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
