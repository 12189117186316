import { uploadDocument } from 'services/documentos/cadastro';
import { insertOrUpdateOrigin } from 'services/origin';

import { useToast } from 'contexts/toast';

function docType() {
  return {
    originTypeId: 69,
    originTypeName: 'TB_ORIGIN_TYPE',
  };
}

const docsEnum = [
  {
    key: 'logoLogin',
    documentTypeId: 70,
    documentTypeName: 'Logo Login',
  },
  { key: 'logoTopo', documentTypeId: 71, documentTypeName: 'Logo Topo' },
  {
    key: 'favIcon',
    documentTypeId: 72,
    documentTypeName: 'Fav Icon',
  },
];

export function useCadastroOrigin() {
  const { toast } = useToast();

  // handler progresso de upload de documentos
  const handleUploadProgress = (percentComplete) => {
    if (percentComplete === 0) return;
    toast(`Enviando arquivo - ${percentComplete}%`, {
      id: 'percentComplete',
    });
  };
  const uploadDocuments = async (
    files,
    originId,
    typeId,
    typeName,
    pendingId
  ) => {
    const formData = new FormData();

    // document type enum
    const enumDoc = docType();

    Object.values(files).forEach((file) => formData.append('files', file));
    formData.append(
      'originId',
      originId || 'eb635e4f-ad8d-47f7-9b9f-143626279523'
    );
    formData.append('documentTypeId', typeId);
    formData.append('documentTypeName', typeName);
    if (pendingId !== undefined) formData.append('pendingId', pendingId);
    formData.append('originTypeId', enumDoc.originTypeId);
    formData.append('originTypeName', enumDoc.originTypeName);

    try {
      // setIsLoading(true);

      const { data: uploadedDocument } = await toast.promise(
        uploadDocument(formData, handleUploadProgress),
        {
          loading: `Enviando documento`,
          success: 'Documento enviado com sucesso',
          error: 'Erro ao enviar documento',
        }
      );

      return uploadedDocument;
    } catch (error) {
      toast.error(error.message);
    } finally {
      // setIsLoading(false);
    }
  };

  function serializeForm(values) {
    if (typeof values.tema === 'object') {
      values.tema = JSON.stringify(values.tema);
    }
    return values;
  }

  async function enviarFormulario(values) {
    const serializedForm = serializeForm(values);
    try {
      toast.loading('Cadastrando...', { id: 'origin' });
      await insertOrUpdateOrigin(serializedForm);
      toast.success('Origem cadastrada', { id: 'origin' });
    } catch (error) {
      toast.error('Erro no cadastro', { id: 'origin' });
    }
    values.tema = JSON.parse(values.tema);
  }

  return {
    enviarFormulario,
    uploadDocuments,
    docsEnum,
  };
}
