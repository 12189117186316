import React from 'react';

import { useTheme } from '@material-ui/core/styles';

export default function Contract({
  color = '#A0A0A0',
  primary = false,
  size = 28,
}) {
  const theme = useTheme();

  const iconColor = primary ? theme.palette.primary.main : color;

  return (
    <svg
      id="contract"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 120) / 100}
      viewBox="0 0 41.273 49.526"
    >
      <g id="Grupo_17" data-name="Grupo 17" transform="translate(30.954 28.89)">
        <g id="Grupo_16" data-name="Grupo 16">
          <path
            id="Caminho_28"
            data-name="Caminho 28"
            fill={iconColor}
            d="M367.82,298.667a5.159,5.159,0,1,0,5.159,5.159A5.165,5.165,0,0,0,367.82,298.667Zm0,8.254a3.1,3.1,0,1,1,3.1-3.1A3.1,3.1,0,0,1,367.82,306.921Z"
            transform="translate(-362.661 -298.667)"
          />
        </g>
      </g>
      <g
        id="Grupo_19"
        data-name="Grupo 19"
        transform="translate(30.955 37.141)"
      >
        <g id="Grupo_18" data-name="Grupo 18">
          <path
            id="Caminho_29"
            data-name="Caminho 29"
            fill={iconColor}
            d="M372.971,395.113,370.907,384.8a1.032,1.032,0,0,0-2.024.4l1.651,8.25-2.241-1.121a1.036,1.036,0,0,0-.922,0l-2.241,1.121,1.651-8.25a1.032,1.032,0,0,0-2.024-.4l-2.064,10.318a1.033,1.033,0,0,0,1.473,1.127l3.665-1.833,3.665,1.833a1.055,1.055,0,0,0,.462.109,1.034,1.034,0,0,0,1.013-1.236Z"
            transform="translate(-362.673 -383.964)"
          />
        </g>
      </g>
      <g id="Grupo_21" data-name="Grupo 21" transform="translate(0)">
        <g id="Grupo_20" data-name="Grupo 20" transform="translate(0)">
          <path
            id="Caminho_30"
            data-name="Caminho 30"
            fill={iconColor}
            d="M78.773,0h-22.7a1.039,1.039,0,0,0-.731.3L42.961,12.683a1.039,1.039,0,0,0-.3.731V44.367A1.032,1.032,0,0,0,43.692,45.4H70.519a1.032,1.032,0,0,0,0-2.064h-25.8v-29.5L56.5,2.064H77.741V25.795a1.032,1.032,0,0,0,2.064,0V1.032A1.032,1.032,0,0,0,78.773,0Z"
            transform="translate(-42.66)"
          />
        </g>
      </g>
      <g id="Grupo_23" data-name="Grupo 23" transform="translate(0)">
        <g id="Grupo_22" data-name="Grupo 22" transform="translate(0)">
          <path
            id="Caminho_31"
            data-name="Caminho 31"
            fill={iconColor}
            d="M56.073,0a1.032,1.032,0,0,0-1.032,1.032v11.35H43.692a1.032,1.032,0,1,0,0,2.064H56.073a1.032,1.032,0,0,0,1.032-1.032V1.032A1.032,1.032,0,0,0,56.073,0Z"
            transform="translate(-42.66)"
          />
        </g>
      </g>
      <g id="Grupo_25" data-name="Grupo 25" transform="translate(20.636 8.252)">
        <g id="Grupo_24" data-name="Grupo 24" transform="translate(0)">
          <path
            id="Caminho_32"
            data-name="Caminho 32"
            fill={iconColor}
            d="M261.331,90.487l-.221-.035c-2.522-.386-3.052-1.046-3.052-1.529,0-.714,1.354-1.548,3.1-1.55s3.025.784,3.1,1.488a1.031,1.031,0,1,0,2.051-.208c-.194-1.9-2.406-3.341-5.149-3.341-2.9,0-5.163,1.587-5.163,3.611,0,2.833,3.615,3.388,4.988,3.6l.221.035c2.518.386,3.046,1.044,3.046,1.527,0,.716-1.354,1.546-3.1,1.546s-3.025-.784-3.1-1.488A1.031,1.031,0,1,0,256,94.35c.194,1.907,2.406,3.343,5.149,3.343,2.9,0,5.163-1.585,5.163-3.609C266.312,91.252,262.7,90.7,261.331,90.487Z"
            transform="translate(-255.994 -85.311)"
          />
        </g>
      </g>
      <g id="Grupo_27" data-name="Grupo 27" transform="translate(4.127 20.636)">
        <g id="Grupo_26" data-name="Grupo 26" transform="translate(0)">
          <path
            id="Caminho_33"
            data-name="Caminho 33"
            fill={iconColor}
            d="M96.677,213.333H86.359a1.032,1.032,0,0,0,0,2.064H96.677a1.032,1.032,0,0,0,0-2.064Z"
            transform="translate(-85.327 -213.333)"
          />
        </g>
      </g>
      <g id="Grupo_29" data-name="Grupo 29" transform="translate(4.127 28.89)">
        <g id="Grupo_28" data-name="Grupo 28" transform="translate(0)">
          <path
            id="Caminho_34"
            data-name="Caminho 34"
            fill={iconColor}
            d="M109.058,298.667h-22.7a1.032,1.032,0,1,0,0,2.064h22.7a1.032,1.032,0,1,0,0-2.064Z"
            transform="translate(-85.327 -298.667)"
          />
        </g>
      </g>
      <g id="Grupo_31" data-name="Grupo 31" transform="translate(4.127 37.145)">
        <g id="Grupo_30" data-name="Grupo 30" transform="translate(0)">
          <path
            id="Caminho_35"
            data-name="Caminho 35"
            fill={iconColor}
            d="M94.613,384H86.359a1.032,1.032,0,0,0,0,2.064h8.254a1.032,1.032,0,0,0,0-2.064Z"
            transform="translate(-85.327 -384)"
          />
        </g>
      </g>
      <g id="Grupo_33" data-name="Grupo 33" transform="translate(24.763 6.191)">
        <g id="Grupo_32" data-name="Grupo 32">
          <path
            id="Caminho_36"
            data-name="Caminho 36"
            fill={iconColor}
            d="M299.692,64a1.032,1.032,0,0,0-1.032,1.032V79.477a1.032,1.032,0,0,0,2.064,0V65.032A1.032,1.032,0,0,0,299.692,64Z"
            transform="translate(-298.66 -64)"
          />
        </g>
      </g>
    </svg>
  );
}
