import AxiosInterceptor from "utils/axios-interceptor";

export async function post(userData) {
  const apiUrl = `${process.env.REACT_APP_NODE_API}/users/login`;
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.post(apiUrl, JSON.stringify(userData), {
    headers: {
      "Content-Type": `application/json`,
    },
  });

  let userCompany = {
    name: data.origin.originTypeName,
    fullName: data.origin.originTypeName,
  };
  if (data.session.userTypeId != 3) {
    const userCompanyApi = await getUserCompanyService(data.business_id);

    if (userCompanyApi.name) {
      userCompany = {
        ...userCompanyApi,
      };
    }
  }

  return {
    data: {
      token: data.access_token,
      user: {
        name: data.name,
        email: data.email,
        userTypeId: data.session.userTypeId,
        businessId: data.business_id,
        originType: data.origin_type,
        origin: data.origin,
        company: userCompany,
        userId: data.user_id,
        status_cadastro: data.status_cadastro,
      },
      session: {
        token: data.session.token,
      },
    },
  };
}

export async function getUserCompanyService(businessId) {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/users/company/${businessId}`
  );

  // data.name && data.fullName
  return data;
}

export const defaults = {
  headers: {
    Authorization: "",
  },
};
