import { useEffect, useState } from 'react';

import getDaysToGo from 'utils/diffDays';
import formatReal, { removeRealFormat } from 'utils/formatReal';

import { gerarParcelasAgendasService } from 'services/agenda';
import { cadastrarTitulo } from 'services/titulo';

import { useToast } from 'contexts/toast';

const tiposRecebiveis = [
  { value: 5, label: 'Conta Garantida' },
  { value: 3, label: 'Precatorio' },
  { value: 2, label: 'Produtos' },
  { value: 1, label: 'Serviços' },
];

export function useCadastroTituloRecebivel({
  email,
  businessId,
  getAgendas,
  handleClose,
}) {
  const { toast } = useToast();

  const [openRecebiveis, setOpenRecebiveis] = useState(false);
  const [parcelasGeradas, setParcelasGeradas] = useState(false);
  const [formValues, setFormValues] = useState({
    valor: '',
    tipoRecebivel: 0,
    tipoName: '',
    agendas: [],
    businessId,
    accessId: email,
    vencList: [
      // {
      //   index: Math.random(),
      //   descricao: '',
      //   dtvencimento: '',
      //   valor: '',
      //   dias: '1',
      // },
    ],
  });

  function somaValorRecebiveis() {
    let soma = 0;
    if (formValues.agendas.length == 0) return;
    formValues.agendas.forEach((agenda) => {
      soma += +removeRealFormat(agenda.commited_value).toFixed(2);
    });

    // soma = formatReal(soma);
    setFormValues({ ...formValues, valor: soma });
    return soma;
  }

  function somaValorVencimentos() {
    if (formValues.vencList.length == 0) return -1;
    const parsedVencimentos = vencListParser();

    let somaVencimentos = 0;

    for (const vencimento of parsedVencimentos) {
      somaVencimentos += vencimento.valor;
    }

    return somaVencimentos;
  }

  function valoresMaiorZero() {
    if (formValues.vencList.length == 0) return -1;
    const parsedVencimentos = vencListParser();

    for (const vencimento of parsedVencimentos) {
      if (vencimento.valor <= 0) {
        return false;
      }
    }

    return true;
  }

  function isVencimentosValid(form) {
    const somaVencimentos = somaValorVencimentos();
    const valorTitulo = form.valor;

    const isVencimentosValueValid = somaVencimentos <= valorTitulo;

    if (!isVencimentosValueValid) return false;

    return true;
  }

  function vencListParser() {
    return formValues.vencList.map((vencimento) => ({
      ...vencimento,
      valor: removeRealFormat(vencimento.valor),
    }));
  }

  function agendasParser() {
    return formValues.agendas.map((agenda) => ({
      ...agenda,
      commited_value: removeRealFormat(agenda.commited_value),
    }));
  }

  function formValuesParser() {
    return {
      ...formValues,
      agendas: agendasParser(),
      valor: removeRealFormat(
        formValues.valor ? formValues.valor.toFixed(2) : formValues.valor
      ),
      vencList: vencListParser(),
    };
  }

  async function handleEnviarFormulario() {
    const parsedFormValues = formValuesParser();

    if (!valoresMaiorZero())
      return toast('Parcela precisa ter valor maior que zero');

    if (!isVencimentosValid(parsedFormValues))
      return toast('Soma dos vencimentos inválida');

    if (!parsedFormValues.tipoRecebivel || !parsedFormValues.tipoName)
      return toast('Nenhum tipo de recebível informado');

    if (parsedFormValues.agendas.length == 0 || !parsedFormValues.valor)
      return toast('Valor comprometido inválido');

    if (parsedFormValues.vencList.length == 0)
      return toast('Nenhum vencimento informado');

    if (parsedFormValues.vencList.some((vencimento) => !vencimento.valor))
      return toast('Nenhum vencimento informado');

    await enviarFormulario(parsedFormValues);

    await getAgendas();
    handleClose();
  }

  function getSavedAgendas() {
    return formValues.agendas;
  }

  function saveAgendas(agendas) {
    setFormValues({ ...formValues, agendas });
  }

  function handlerChange(event) {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  }

  function validTipoRecebivel(typeId) {
    if (tiposRecebiveis.some((tipo) => tipo.value == typeId)) return true;
    return false;
  }

  const addNewVenc = (venc) => {
    const newIndex = Math.random();

    if (venc) {
      const newVenc = {
        index: newIndex,
        descricao: venc.descricao,
        dtvencimento: venc.dtvencimento,
        valor: venc.valor,
        dias: getDaysToGo(venc.dtvencimento),
      };
      setFormValues((old) => ({
        ...old,
        vencList: [...old.vencList, newVenc],
      }));
    } else {
      setFormValues((old) => ({
        ...old,
        vencList: [
          ...old.vencList,
          {
            index: newIndex,
            descricao: '',
            dtvencimento: '',
            valor: '',
            dias: '1',
          },
        ],
      }));
    }
  };

  async function deleteVenc(index) {
    const valueToAddBack = removeRealFormat(
      formValues.vencList.find((venc, i) => index === i).valor
    );
    const removed = formValues.vencList.filter((list, i) => index !== i);
    setFormValues((old) => ({
      ...old,
      vencList: removed,
      valor: formValues.valor - valueToAddBack,
      addBack: valueToAddBack,
    }));
  }

  const handleChangeVencimento = (index, value) => {
    const setted = value;
    const novo = formValues.vencList[index];
    novo.dias = getDaysToGo(setted);
    novo.dtvencimento = setted;
    novo.maturity_date = value;
    const aux = formValues.vencList;
    aux[index] = novo;

    setFormValues((old) => ({ ...old, vencList: aux }));
    setTimeout(() => {}, 200);
  };

  const changeVenclist = (idx, e) => {
    const novo = formValues.vencList[idx];
    const aux = formValues.vencList;
    aux[idx] = { ...novo, [e.target.name]: e.target.value };
    setFormValues((old) => ({ ...old, vencList: aux }));
    setTimeout(() => {}, 200);
  };

  useEffect(() => {
    const { tipoRecebivel } = formValues;

    if (validTipoRecebivel(tipoRecebivel)) setOpenRecebiveis(true);
    if (!validTipoRecebivel(tipoRecebivel)) setOpenRecebiveis(false);
  }, [formValues.tipoRecebivel]);

  useEffect(() => {
    if (formValues.agendas.length == 0) return;

    somaValorRecebiveis();
  }, [formValues.agendas]);

  useEffect(() => {
    if (!formValues.tipoRecebivel) return;

    const tipoName = tiposRecebiveis.find(
      (tipo) => tipo.value == formValues.tipoRecebivel
    ).label;

    setFormValues({ ...formValues, tipoName });
  }, [formValues.tipoRecebivel]);

  async function enviarFormulario(form) {
    await toast.promise(cadastrarTitulo(form), {
      loading: 'Cadastrando título',
      success: 'Título cadastrado',
      error: 'Erro ao cadastrar título',
    });
  }

  async function gerarParcelasAgendas(agenda_id, vlrSolicitado) {
    try {
      toast.loading('Gerando parcelas', { id: 'gerar-parcelas' });

      // gerando parcelas
      const parcelas = await gerarParcelasAgendasService([
        { agenda_id, vlrSolicitado },
      ]);

      // serializando parcelas geradas
      const parcelasGeradas = parcelas.find(
        (parcela) => parcela.agenda_id == agenda_id
      ).parcelas;

      // armazenando parcelas
      handleSaveParcelasGeradas(agenda_id, parcelasGeradas);

      // mostrando parcelas na tela
      setParcelasGeradas(true);

      toast.success('Parcelas geradas', { id: 'gerar-parcelas' });

      return parcelasGeradas;
    } catch (error) {
      toast.error(`Você está utilizando uma Nota Fiscal com uma ou mais parcelas com data inferior à 15 dias até o vencimento.`, { id: 'gerar-parcelas' });
    }
  }

  function handleSaveParcelasGeradas(agenda_id, parcelas) {
    // filtrando parcelas existentes que não pertencem a agenda_id informada
    const parcelasFiltradas = formValues.vencList.filter(
      (venc) => venc.agenda_id != agenda_id
    );

    // serializando parcelas geradas
    parcelas = parcelas.map((parcela) => ({
      ...parcela,
      valor: formatReal(parseFloat(parcela.valor).toFixed(2)),
    }));

    // concatenando as parcelas
    const newParcelas = [...parcelasFiltradas, ...parcelas];

    // salvando os parcelas
    setFormValues({
      ...formValues,
      vencList: newParcelas,
    });

    return newParcelas;
  }

  return {
    handleEnviarFormulario,
    addNewVenc,
    deleteVenc,
    changeVenclist,
    formValues,
    handleChangeVencimento,
    getSavedAgendas,
    saveAgendas,
    openRecebiveis,
    tiposRecebiveis,
    handlerChange,
    gerarParcelasAgendas,
    parcelasGeradas,
  };
}
