import useSWR from "swr";

import { fetchWithToken } from "../fetchWithToken";

export default function useTerms(
  user = null,
  identity = null,
  options = {
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
    shouldRetryOnError: false,
    errorRetryInterval: 0,
  }
) {
  const {
    data: terms,
    error,
    isValidating,
    mutate,
  } = useSWR(
    user && user?.userId ? `terms/${user.userId}/${identity}` : false,
    fetchWithToken,
    options
  );

  const value = {
    signed: false,
  };

  return {
    terms: terms && terms.data ? value : null,
    error,
    isValidating,
    isLoading: user && !error && !terms,
    mutate,
  };
}
