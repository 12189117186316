import React, { useState, useEffect } from "react";

import {
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  Snackbar,
  Select,
  InputLabel,
  MenuItem,
  FormGroup,
  LinearProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";

import { ExampleWrapperSimple } from "layout-components";

import formatReal from "utils/formatReal";

import Moment from "moment";

import "moment/locale/pt-br";
import "date-fns";

import { estados } from "../estados";
import VencList from "../VencList";

export default (props) => {
  const { blank, title, agenda, initiposacado, type } = props;

  const isOnModalLastro = type === "modal-lastro";

  const [tiponota, setTipoNota] = useState(undefined);
  const [invoiceKey, setInvoiceKey] = useState(undefined);
  const [invoiceSeries, setInvoiceSeries] = useState(undefined);
  const [numeronota, setNumeroNota] = useState(undefined);
  const [emissaonota, setEmissaoNota] = useState(undefined);
  const [valornota, setValorNota] = useState(undefined);
  const [tiposacado2, setTipoSacado2] = useState(undefined);
  const [docsacado, setDocSacado] = useState(undefined);
  const [nomesacado, setNomeSacado] = useState(undefined);
  const [telefonesacado, setTelefoneSacado] = useState(undefined);
  const [emailsacado, setEmailSacado] = useState(undefined);
  const [cepsacado, setCepSacado] = useState(undefined);
  const [enderecosacado, setEnderecoSacado] = useState(undefined);
  const [numerosacado, setNumeroSacado] = useState(undefined);
  const [complementosacado, setComplementoSacado] = useState(undefined);
  const [bairrosacado, setBairroSacado] = useState(undefined);
  const [cidadesacado, setCidadeSacado] = useState(undefined);
  const [ufsacado, setUfSacado] = useState(undefined);
  const [nomecontato, setNomeContato] = useState(undefined);
  const [emailcontato, setEmailContato] = useState(undefined);
  const [telcontato, setTelContato] = useState(undefined);
  const [cargocontato, setCargoContato] = useState(undefined);

  const [vencList, setVencList] = useState([
    {
      index: Math.random(),
      descricao: "",
      dtvencimento: "",
      valor: "",
      dias: "",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [uf, setUf] = useState("AC");
  const [formAdd, setValues] = useState({
    businessId: localStorage.getItem("businessId"),
    username: localStorage.getItem("name"),
  });
  const tiposacados = {
    fisica: "Pessoa Física",
    juridica: "Pessoa Jurídica",
  };
  const [tiposacado, SetTipoSacado] = useState(initiposacado);

  const [pessoa, SetPessoa] = useState({
    tipo: "",
    nome: "",
    doc: "",
  });
  const typenames = [
    { id: 1, type_name: "Serviços" },
    { id: 2, type_name: "Produtos" },
    { id: 3, type_name: "Precatório" },
    { id: 6, type_name: "Transporte (CTe)" },
  ];
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });

  const handleChangeVencimento = (index, value) => {
    setIsLoading(true);
    const setted = Moment(new Date(value));
    const now = Moment();
    const novo = vencList[index];
    novo.dias = setted.diff(now, "days");
    novo.dtvencimento = setted.format("YYYY/MM/DD");
    const aux = vencList;
    aux[index] = novo;
    setVencList(aux);
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  const changeVenclist = (idx, e) => {
    setIsLoading(true);
    const novo = vencList[idx];
    const aux = vencList;
    aux[idx] = { ...novo, [e.target.name]: e.target.value };
    setVencList(aux);
    setValues({ ...formAdd, vencimentos: vencList });
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleDateChange = (date) => {
    setValues({ ...formAdd, emissaonota: date });
    // setSelectedDate(date);
  };

  const addNewVenc = () => {
    setVencList([
      ...vencList,
      {
        index: Math.random(),
        descricao: "",
        dtvencimento: "",
        valor: "",
        dias: 0,
      },
    ]);
  };

  const deleteVenc = (index) => {
    setIsLoading(true);
    const removed = vencList.filter((list) => index !== list.index);
    setVencList(removed);
    setValues({ ...formAdd, vencimentos: removed });
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  const handlerChangeSelectUF = (e) => {
    setValues({ ...formAdd, ufsacado: e.target.value });
    setUf(e.target.value);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handlerChange = (e) => {
    setValues({ ...formAdd, [e.target.name]: e.target.value });
  };

  const handlerChangeSelect = (e) => {
    setValues({ ...formAdd, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (blank) {
      setValues({});
    }
    if (tiposacado == tiposacados.fisica) {
      SetPessoa({ tipo: tiposacado, nome: "Nome", doc: "CPF" });
      formAdd.tiposacado = tiposacado;
    } else {
      SetPessoa({ tipo: tiposacado, nome: "Razão Social", doc: "CNPJ" });
      formAdd.tiposacado = tiposacado;
    }
    setValues({ ...formAdd, ...agenda });

    if (agenda.agenda_id) {
      formAdd.nfjson = agenda.xmlScheduling.xml;
      formAdd.tiponota = agenda.type_name;
      formAdd.numeronota = agenda.number;
      formAdd.emissaonota = agenda.issue_date;
      formAdd.valornota = formatReal(agenda.invoice_value.toFixed(2));
      formAdd.tiposacado = agenda.drawee.person_type_name;
      formAdd.docsacado = agenda.drawee.country_identity;
      formAdd.nomesacado = agenda.drawee.name || agenda.drawee.corporate_name;
      formAdd.telefonesacado = agenda.drawee.office_phone;
      formAdd.emailsacado = agenda.drawee.email;
      formAdd.cepsacado = agenda.drawee.address_zip_code;
      formAdd.enderecosacado = agenda.drawee.address_street;
      formAdd.numerosacado = agenda.drawee.address_number;
      formAdd.complementosacado = agenda.drawee.address_complement;
      formAdd.bairrosacado = agenda.drawee.address_district;
      formAdd.cidadesacado = agenda.drawee.address_city;
      formAdd.ufsacado = agenda.drawee.address_state;
      formAdd.nomecontato = agenda.drawee.contact_name;
      formAdd.emailcontato = agenda.drawee.contact_email;
      formAdd.telcontato = agenda.drawee.mobile_phone;
      formAdd.cargocontato = agenda.drawee.contact_role;
      formAdd.invoiceKey = agenda.invoice_key;
      formAdd.invoiceSeries = agenda.invoice_series;
      if (!agenda.maturity) return;
      if (agenda.maturity.length > 0) {
        const parsedVencimentos = agenda.maturity.map((maturity) => ({
          ...maturity,
          value: formatReal(maturity.value.toFixed(2)),
          valor: formatReal(maturity.value.toFixed(2)),
          dt_vencimento: maturity.maturity_date,
        }));
        setVencList(parsedVencimentos);
      }

      setTipoNota(formAdd.tiponota);
      setInvoiceKey(formAdd.invoiceKey);
      setInvoiceSeries(formAdd.invoiceSeries);
      setNumeroNota(formAdd.numeronota);
      setEmissaoNota(formAdd.emissaonota);
      setValorNota(formAdd.valornota);
      if (agenda.drawee.person_type_id === 1) {
        SetTipoSacado(formAdd.tiposacado);
      } else {
        setTipoSacado2(formAdd.tiposacado);
      }
      setDocSacado(formAdd.docsacado);
      setNomeSacado(formAdd.nomesacado);
      setTelefoneSacado(formAdd.telefonesacado);
      setEmailSacado(formAdd.emailsacado);
      setCepSacado(formAdd.cepsacado);
      setEnderecoSacado(formAdd.enderecosacado);
      setNumeroSacado(formAdd.numerosacado);
      setComplementoSacado(formAdd.complementosacado);
      setBairroSacado(formAdd.bairrosacado);
      setCidadeSacado(formAdd.cidadesacado);
      setUfSacado(formAdd.ufsacado);
      setNomeContato(formAdd.nomecontato);
      setEmailContato(formAdd.emailcontato);
      setTelContato(formAdd.telcontato);
      setCargoContato(formAdd.cargocontato);
    }
  }, [agenda]);

  return (
    <>
      {isLoading && <LinearProgress value={55} color="primary" />}

      <div
        style={{
          marginTop: 30,
          marginLeft: !isOnModalLastro && -65,
          width: isOnModalLastro ? "100%" : "105%",
        }}
      >
        <div
          className="searchForm"
          style={{
            marginLeft: !isOnModalLastro && 35,
            width: isOnModalLastro && "100%",
          }}
        >
          <div className="containerTitle">
            <div className="itemTile">
              <h2 style={{ fontFamily: "Poppins" }}>
                {title || "Novo Recebível"}
              </h2>
            </div>
          </div>
          {snackbarState.open && (
            <>
              <Snackbar
                open={snackbarState.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  onClose={handleCloseSnackbar}
                  severity={snackbarState.severity}
                >
                  {snackbarState.message}
                </Alert>
              </Snackbar>
            </>
          )}

          <ExampleWrapperSimple sectionHeading="Nota Fiscal">
            <div className="item">
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <InputLabel id="tiponota-label">Tipo de Nota</InputLabel>
                  <Select
                    autoFocus
                    labelId="tiponota"
                    id="tiponota"
                    defaultValue="Serviços"
                    value={tiponota || ""}
                    name="tiponota"
                    onChange={handlerChangeSelect}
                    disabled
                  >
                    <MenuItem />
                    {typenames.map((tipo) => (
                      <MenuItem key={tipo.id} value={tipo.type_name}>
                        {tipo.type_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    className="ml-2 mr-2 mb-0 text-field"
                    id="numeronota"
                    label="Número da Nota Fiscal"
                    name="numeronota"
                    defaultValue={0}
                    value={numeronota || ""}
                    onChange={handlerChange}
                    variant="outlined"
                    disabled
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <KeyboardDatePicker
                      helperText=""
                      cancelLabel="Cancelar"
                      autoFocus
                      disabled
                      fullWidth
                      locale={ptBR}
                      className="ml-2 mr-2 mb-0 text-field"
                      id="emissaonota"
                      label="Emissão da Nota Fiscal"
                      format="dd/MM/yyyy"
                      value={emissaonota}
                      onChange={handleDateChange}
                      variant="outlined"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <TextField
                  autoFocus
                  fullWidth
                  disabled
                  className="ml-2 mr-2 mb-0 text-field"
                  id="valornota"
                  label="Valor da nota"
                  name="valornota"
                  value={formatReal(valornota) || ""}
                  onChange={handlerChange}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="item" style={{ width: "75%" }}>
              {invoiceKey && (
                <div className="containerDatePicker mb-3" style={{ flex: "2" }}>
                  <FormControl className="w-100">
                    <TextField
                      autoFocus
                      fullWidth
                      className=" mb-0 text-field"
                      id="invoiceKey"
                      label="Chave da Nota Fiscal"
                      name="invoiceKey"
                      defaultValue={0}
                      value={invoiceKey || ""}
                      onChange={handlerChange}
                      variant="outlined"
                      disabled
                    />
                  </FormControl>
                </div>
              )}
              {invoiceSeries && (
                <div
                  className="containerDatePicker  mr-5 mb-3"
                  style={{ flex: "1" }}
                >
                  <FormControl className="w-100">
                    <TextField
                      autoFocus
                      fullWidth
                      className={
                        invoiceKey
                          ? "ml-2 mr-2 mb-0 text-field"
                          : "mr-2 mb-0 text-field"
                      }
                      id="invoiceSeries"
                      label="Série da Nota Fiscal"
                      name="invoiceSeries"
                      defaultValue={0}
                      value={invoiceSeries || ""}
                      onChange={handlerChange}
                      variant="outlined"
                      disabled
                    />
                  </FormControl>
                </div>
              )}
            </div>
          </ExampleWrapperSimple>

          <ExampleWrapperSimple sectionHeading="Sacado">
            <div className="mr-5 mb-3">
              <FormGroup row style={{ marginLeft: "15px" }}>
                <FormControlLabel
                  control={
                    <Radio
                      disabled
                      checked={!!tiposacado}
                      onClick={() => SetTipoSacado(tiposacados.fisica)}
                      color="primary"
                      name="tiposacado"
                    />
                  }
                  label="Pessoa Física"
                />
                <FormControlLabel
                  control={
                    <Radio
                      disabled
                      checked={!!tiposacado2}
                      onClick={() => SetTipoSacado(tiposacados.juridica)}
                      color="primary"
                      name="tiposacado"
                    />
                  }
                  label="Pessoa Jurídica"
                />
              </FormGroup>
            </div>
            <div className="item">
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    disabled
                    className="ml-2 mr-2 mb-0 text-field"
                    id="docsacado"
                    label={pessoa.doc}
                    name="docsacado"
                    value={docsacado || ""}
                    onChange={handlerChange}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100 flex">
                  <TextField
                    autoFocus
                    fullWidth
                    disabled
                    className="ml-2 mr-2 mb-0 text-field"
                    id="nomesacado"
                    label={pessoa.nome}
                    name="nomesacado"
                    value={nomesacado || ""}
                    onChange={handlerChange}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    disabled
                    className="ml-2 mr-2 mb-0 text-field"
                    id="telefone"
                    label="Telefone"
                    name="telefonesacado"
                    value={telefonesacado || ""}
                    onChange={handlerChange}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    disabled
                    className="ml-2 mr-2 mb-0 text-field"
                    id="email"
                    type="email"
                    label="Email"
                    name="emailsacado"
                    value={emailsacado || ""}
                    onChange={handlerChange}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </div>
            </div>
            <div className="item">
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    disabled
                    className="ml-2 mr-2 mb-0 text-field"
                    id="cep"
                    label="CEP"
                    name="cepsacado"
                    value={cepsacado || ""}
                    onChange={handlerChange}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </div>{" "}
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    disabled
                    className="ml-2 mr-2 mb-0 text-field"
                    id="endereco"
                    label="Endereço"
                    name="enderecosacado"
                    value={enderecosacado || ""}
                    onChange={handlerChange}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </div>{" "}
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    disabled
                    className="ml-2 mr-2 mb-0 text-field"
                    id="numero"
                    label="Número"
                    name="numerosacado"
                    value={numerosacado || ""}
                    onChange={handlerChange}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    disabled
                    className="ml-2 mr-2 mb-0 text-field"
                    id="complemento"
                    label="Complemento"
                    name="complementosacado"
                    value={complementosacado || ""}
                    onChange={handlerChange}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </div>
            </div>
            <div className="item">
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    disabled
                    className="ml-2 mr-2 mb-0 text-field"
                    id="bairro"
                    label="Bairro"
                    name="bairrosacado"
                    value={bairrosacado || ""}
                    onChange={handlerChange}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    disabled
                    className="ml-2 mr-2 mb-0 text-field"
                    id="cidade"
                    label="Cidade"
                    name="cidadesacado"
                    value={cidadesacado || ""}
                    onChange={handlerChange}
                    variant="outlined"
                    required
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <InputLabel id="uflabel" className="ml-2 mt-2">
                    UF
                  </InputLabel>
                  <Select
                    labelId="uflabel"
                    id="uf"
                    name="ufsacado"
                    value={ufsacado || uf || ""}
                    onChange={handlerChangeSelectUF}
                    disabled
                  >
                    <MenuItem value="0" />
                    {estados.map((element) => (
                      <MenuItem key={element.sigla} value={element.sigla}>
                        {element.sigla}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </ExampleWrapperSimple>

          <ExampleWrapperSimple sectionHeading="Contato para a confirmação de entrega">
            <div className="item">
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    fullWidth
                    disabled
                    className="ml-2 mr-2 mb-0 text-field"
                    id="nomecontato"
                    label="Nome do Contato"
                    name="nomecontato"
                    value={nomecontato || ""}
                    onChange={handlerChange}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    fullWidth
                    disabled
                    className="ml-2 mr-2 mb-0 text-field"
                    id="telcontato"
                    label="Telefone do Contato"
                    name="telcontato"
                    value={telcontato || ""}
                    onChange={handlerChange}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    fullWidth
                    disabled
                    className="ml-2 mr-2 mb-0 text-field"
                    id="emailcontato"
                    label="Email do Contato"
                    name="emailcontato"
                    value={emailcontato || ""}
                    onChange={handlerChange}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    fullWidth
                    disabled
                    className="ml-2 mr-2 mb-0 text-field"
                    id="cargocontato"
                    label="Cargo do Contato"
                    name="cargocontato"
                    value={cargocontato || ""}
                    onChange={handlerChange}
                    variant="outlined"
                  />
                </FormControl>
              </div>
            </div>
          </ExampleWrapperSimple>
          <ExampleWrapperSimple sectionHeading="Vencimentos">
            <VencList
              add={addNewVenc}
              del={deleteVenc}
              handlerChange={changeVenclist}
              setVencList={setVencList}
              venclist={vencList}
              handleChangeVencimento={handleChangeVencimento}
            />
          </ExampleWrapperSimple>
        </div>
      </div>
    </>
  );
};
