import React from 'react';

export default function Exit({ color = '#A0A0A0', size = 40 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 31.998 31.999"
    >
      <path
        id="exit"
        d="M23.062,24.5V27a5.005,5.005,0,0,1-5,5H5.5a5.005,5.005,0,0,1-5-5V5a5.005,5.005,0,0,1,5-5H18.062a5.005,5.005,0,0,1,5,5V7.5a1.25,1.25,0,1,1-2.5,0V5a2.5,2.5,0,0,0-2.5-2.5H5.5A2.5,2.5,0,0,0,3,5V27a2.5,2.5,0,0,0,2.5,2.5H18.062a2.5,2.5,0,0,0,2.5-2.5V24.5a1.25,1.25,0,0,1,2.5,0Zm8.522-10.647-2.8-2.8a1.25,1.25,0,0,0-1.768,1.768l1.991,1.991H14a1.25,1.25,0,0,0,0,2.5H29.008L27.017,19.3a1.25,1.25,0,1,0,1.768,1.768l2.8-2.8A3.128,3.128,0,0,0,31.584,13.852Zm0,0"
        transform="translate(-0.5)"
        fill={color}
      />
    </svg>
  );
}
