import React, { useState } from 'react';
import './invoice.scss';

import { useQuery } from 'react-query';

import {
  Card,
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';

import formatDate from 'utils/formatDate';
import formatDateToHours from 'utils/formatDateToHours';
import formatReal from 'utils/formatReal';

import { buscarParcelasLance } from 'services/invoice';

import { useToast } from 'contexts/toast';

// const formatNumber = (valor) => {
//   const number = `${valor}`;

//   const value = Number(number).toLocaleString({
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2,
//   });

//   return value.indexOf(',') === -1
//     ? `${value},00`
//     : value.slice(value.indexOf(',')).length > 3
//       ? value.slice(0, value.indexOf(',')) +
//       value.slice(value.indexOf(','), value.indexOf(',') + 3)
//       : value;
// };

export const BidAttribute = ({ name, value }) => (
  <div className="d-flex flex-column align-items-start justify-content-start">
    <Typography variant="body1" noWrap color="secondary">
      {name}
    </Typography>
    <Typography variant="h6" className="pt-1 mb-4">
      {value}
    </Typography>
  </div>
);

export const BidColumn = ({ children }) => (
  <div className="d-flex flex-column align-items-start">{children}</div>
);

export const BidRow = ({ children }) => (
  <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '4em' }}>
    {children}
  </div>
);

export const BidDialog = (props) => {
  const { bid } = props;

  const [parcelas, setParcelas] = useState([]);

  const { toast } = useToast();

  function getBidInvoiceId() {
    if (!bid) return '';

    return bid.invoiceId;
  }

  useQuery(`parcelas-${getBidInvoiceId()}`, handleLoadParcelas, {
    onSuccess: (value) => {
      setParcelas(value);
    },
    onError: (error) => {
      toast.error('Erro ao carregar parcelas', {
        id: `parcelas-${bid.invoiceId}`,
      });
    },
    staleTime: 1 * 1000 * 5,
  });

  async function handleLoadParcelas() {
    if (!getBidInvoiceId()) return;
    return buscarParcelasLance(bid.invoiceId);
  }

  return (
    <Dialog
      onClose={props.handleClose}
      open={props.open}
      fullWidth
      maxWidth="lg"
    >
      <IconButton
        aria-label="close"
        style={{ position: 'absolute', right: 0 }}
        onClick={props.handleClose}
      >
        <CloseIcon />
      </IconButton>
      {bid && props.open && (
        <DialogContent dividers>
          <Typography gutterBottom variant="h5">
            Lance
          </Typography>
          <div className="d-flex flex-row align-items-center justify-content-between">
            <BidColumn>
              <BidAttribute
                name="Prazo Médio"
                value={parseFloat(bid.bidWeightedAverageTerm).toFixed(1)}
              />
              <BidAttribute
                name="Operação"
                value={formatReal(bid.bidOperationValue.toFixed(2))}
              />
            </BidColumn>
            <BidColumn>
              <BidAttribute
                name="Data de Lance"
                value={formatDate(bid.bidDate)}
              />
              <BidAttribute
                name="Análise"
                value={formatReal(bid.bidAnalysisValue.toFixed(2))}
              />
            </BidColumn>
            <BidColumn>
              <BidAttribute
                name="Fator"
                value={formatReal(bid.bidFactorValue.toFixed(2))}
              />
              <BidAttribute
                name="Valor do Título"
                value={formatReal(bid.invoice.invoiceValue.toFixed(2))}
              />
            </BidColumn>
            <BidColumn>
              <BidAttribute
                name="Comissão"
                value={formatReal(bid.bidCommissionValue.toFixed(2))}
              />
              <BidAttribute
                name="Valor dos Vencimentos"
                value={formatReal(bid.invoice.maturitiesValue.toFixed(2))}
              />
            </BidColumn>
            <BidColumn>
              <BidAttribute
                name="Ad Valorem"
                // value={formatReal(bid.bidAdValorem.toFixed(2))}
                // value={`${bid.bidAdValorem.toFixed(3).replace('.', ',')}%`}
                value={formatReal(bid.bidAdValoremValue.toFixed(2))}
              />
              <BidAttribute
                name="Descontos"
                value={formatReal(bid.bidDiscountValue.toFixed(2))}
              />
            </BidColumn>
            <BidColumn>
              <BidAttribute
                name="IOF Parcela"
                value={formatReal(bid.bidIofAmountValue.toFixed(2))}
              />
              <BidAttribute
                name="Valor Líquido"
                value={formatReal(bid.bidNetValue.toFixed(2))}
              />
            </BidColumn>
            <BidColumn>
              <BidAttribute
                name="IOF Mês"
                value={formatReal(bid.bidIofTermValue.toFixed(2))}
              />
              <BidAttribute
                name="Fator de Deságio"
                // value={formatReal(parseFloat(bid.bidFactor.toFixed(2)))}
                value={`${bid.bidFactor.toFixed(3).replace('.', ',')}%`}
              />
            </BidColumn>
            <BidColumn>
              <BidAttribute
                name="Cobrança"
                value={formatReal(bid.bidBankSlip.toFixed(2) * 100)}
              />

              <BidAttribute
                name="Custo Efetivo Total (CET)"
                value={`${bid.bidNetFactor.toFixed(3).replace('.', ',')}%`}
              />
            </BidColumn>
          </div>
        </DialogContent>
      )}
      {bid && props.open && (
        <DialogContent dividers>
          <Typography gutterBottom variant="h5">
            Detalhes
          </Typography>
          {parcelas?.length > 0 &&
            parcelas.map((parcela) => (
              <>
                <BidRow>
                  <BidAttribute name="Vencimento" value={parcela.vencimento} />
                  <BidAttribute name="Prazo" value={parcela.prazo} />
                  <BidAttribute name="Fator" value={parcela.fator} />
                  <BidAttribute name="Comissão" value={parcela.comissao} />
                  <BidAttribute name="Ad Valorem" value={parcela.ad_valorem} />
                  <BidAttribute name="IOF" value={parcela.iof} />
                  <BidAttribute name="Cobrança" value={parcela.cobranca} />
                  <BidAttribute name="Valor" value={parcela.valor} />
                </BidRow>
              </>
            ))}
        </DialogContent>
      )}
    </Dialog>
  );
};

export default function Lances(props) {
  const { bids } = props;

  const [selectedBid, setSelectedBid] = useState(undefined);
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = (bid) => {
    setSelectedBid(bid);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedBid(undefined);
  };

  return (
    <div className="pageInvoice">
      <div className="tableContainerInvoice" style={{ marginTop: '50px' }}>
        <Card className="card-box mb-4">
          <div className="table-responsive">
            {bids === undefined && (
              <LinearProgress value={55} color="primary" />
            )}
            <table className="table table-hover text-nowrap mb-0 w-100">
              <thead>
                <tr>
                  <th className="bg-white text-left dateHeader">
                    DATA DO LANCE
                  </th>
                  <th className="bg-white text-left">FINANCIADOR</th>
                  <th className="bg-white text-left">CET% AM</th>
                  <th className="bg-white text-left">VL. VENCIMENTOS</th>
                  <th className="bg-white text-left">VL. LÍQUIDO</th>
                  <th className="bg-white text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                {bids.map((bid) => (
                  <tr key={bid.bidId}>
                    <td className="text-left d-flex flex-row align-items-center">
                      <Tooltip title="Ver Detalhes" placement="top">
                        <IconButton onClick={() => handleOpenDialog(bid)}>
                          <AddCircleOutlineIcon color="secondary" />
                        </IconButton>
                      </Tooltip>
                      <span className="">
                        {formatDate(bid.bidDate)}{' '}
                        {formatDateToHours(bid.bidDate)}
                      </span>
                    </td>
                    <td className="text-left">
                      <span>{bid.buyer.name}</span>
                    </td>
                    <td className="text-left">
                      <span>
                        {bid.bidNetFactor.toFixed(3).replace('.', ',')}%
                      </span>
                    </td>
                    <td className="text-left">
                      <span>
                        {formatReal(
                          parseFloat(bid.invoice.maturitiesValue).toFixed(2)
                        )}
                      </span>
                    </td>
                    <td className="text-left">
                      <span>
                        {formatReal(parseFloat(bid.bidNetValue).toFixed(2))}
                      </span>
                    </td>
                    <td className="text-left">
                      <span>{bid.statusName}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      </div>
      <BidDialog
        bid={selectedBid}
        open={openDialog}
        handleClose={handleCloseDialog}
      />
    </div>
  );
}
