import React from 'react';
import { useHistory } from 'react-router';

import ListagemOrigin from 'components/ListagemOrigin';

import { useAuth } from 'contexts/auth';

function CadastroUsers() {
  const { isAF } = useAuth();
  const history = useHistory();

  return <>{isAF() ? <ListagemOrigin /> : history.push('/404')}</>;
}

export default CadastroUsers;
