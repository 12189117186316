import {
  AppBar,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import antecipaLogo from "assets/images/logo-afacil - branco grande.png";
import clsx from "clsx";
import Notification from "components/Notification";
import { ReactIf, ReactThen } from "components/ReactIf";
import { useAuth } from "contexts/auth";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { switchCompany } from "services/business";
import { ReactComponent as ArrowDown } from "./icons/ArrowDown.svg";
import { ReactComponent as ArrowUp } from "./icons/ArrowUp.svg";
import ExitIcon from "./icons/Exit";
import UserIcon from "./icons/User";
import VitrineIcon from "./icons/Vitrine";
import { externalMenus, loadPerfilList } from "./items";
import "./styles.scss";
import formatCnpj from "utils/formatCNPJ";
import { getRedirectUrl } from "utils/origin";
import {HelpOutline, LiveHelp, OndemandVideo } from "@material-ui/icons";

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbarFillSpace: {
    height: 64,
    width: "100%",
  },
}));

export default function SideMenu() {
  const userData = JSON.parse(localStorage.getItem("@App:user"));

  const userTypeId = userData?.userTypeId;

  const bearer = localStorage.getItem("bearer");

  const originTypeName = userData?.origin?.originTypeName;
  const companies = userData?.companies?.companies;
  const companyDocument =
    userData?.defaultCompany?.defaultCompany.countryIdentify;
  const companyName = userData?.company?.name;

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [dropdownState, setDropdownState] = useState(false);
  const [menus, setMenus] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const openHelpMenu = Boolean(anchorEl);

  const handleHelpMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHelpMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDropdownClick = () => {
    setDropdownState(!dropdownState);
  };
  const handleSetDropdownValue = (businessId) => {
    setDropdownState(!dropdownState);
    switchCompany(bearer, businessId);
  };

  const handleOutsideClick = (e) => {
    if (e.target.className === "outside__click--wraper isVisible") {
      setDropdownState(false);
    }
  };

  const { Logout, user, nomeEmpresa, userOrigin, cadastroAprovado } = useAuth();

  const projectLogo = user?.tema.logoTopo || antecipaLogo;

  useEffect(() => {
    let perfilList = loadPerfilList(user?.userTypeId);
    if (userOrigin != "AF") {
      perfilList = perfilList.filter(
        (plist) =>
          // somente usuários af podem ver cotações
          !plist.uri.includes("cotacoes") &&
          // somente usuários af podem ver pre aprovados
          !plist.uri.includes("bradesco-pre-aprovados") &&
          // somente usuários af podem ver pre aprovados
          !plist.uri.includes("listagem-parcelas") &&
          // somente usuários af podem ver cadastro whitelabel
          !plist.uri.includes("cadastro-whitelabel")
      );
    }

    if (!cadastroAprovado) {
      perfilList = perfilList.filter(
        (plist) =>
          // não exibir vitrine
          !plist.uri.includes("vitrine")
      );
    }

    if (perfilList) setMenus(perfilList);
  }, [user?.userTypeId]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => setOpen(!open);

  return (
    <div className={`${classes.root} side-menu-container`}>
      <CssBaseline />

      <AppBar
        position="fixed"
        className={clsx("side-menu-app-bar", classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar classes={{ root: "side-menu-toolbar" }}>
          <div className="side-menu-icon-button-container">
            <IconButton
              color="inherit"
              style={{
                color: "white",
                backgroundColor: theme.palette.primary.main,
              }}
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              className={clsx(
                "side-menu-icon-button",
                !open && "side-menu-icon-button--menu",
                classes.menuButton
              )}
            >
              {open ? (
                <ChevronLeftIcon
                  classes={{ root: "side-menu-icon-root--chevron" }}
                />
              ) : (
                <MenuIcon classes={{ root: "side-menu-icon-root--menu" }} />
              )}
            </IconButton>
          </div>

          <Link to="/">
            <img
              className="app-header-logo-img"
              alt="Antecipa Fácil"
              src={projectLogo}
            />
          </Link>

          <div className="side-menu-mobile__right">
            <IconButton
              aria-label="open drawer"
              className={clsx(
                "side-menu-icon-button",
                !open && "side-menu-icon-button--menu"
              )}
              color="inherit"
              edge="start"
              onClick={toggleDrawer}
              style={{
                color: "white",
                backgroundColor: theme.palette.primary.main,
              }}
            >
              {open ? (
                <CloseIcon
                  classes={{ root: "side-menu-icon-root--close" }}
                  style={{ fontSize: 40 }}
                />
              ) : (
                <MenuIcon
                  classes={{ root: "side-menu-icon-root--menu" }}
                  style={{ fontSize: 40 }}
                />
              )}
            </IconButton>
          </div>

          <div className="side-menu--right">

            {/* RESTO DO CÓDIGO DO MENU */}
            <div
              className={`outside__click--wraper ${
                dropdownState ? "isVisible" : "isHidden"
              }`}
              onClick={handleOutsideClick}
            ></div>
            <div className="side-menu--right--item--container">
              {userTypeId === 3 ? (
                `${originTypeName}`
              ) : (
                <IconButton
                  style={{ borderRadius: "4px" }}
                  onClick={dropdownState === false ? handleDropdownClick : null}
                >
                  <div className="side-menu--right--item">
                    <ArrowDown style={{ marginRight: "24px" }} />
                    {/* <div className="dropdown__arrow--box"> */}
                    <div
                      className={`dropdown-items ${
                        dropdownState ? "isVisible" : "isHidden"
                      }`}
                    >
                      <div
                        style={{
                          color: "black",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <div
                          style={{
                            color: "black",
                            marginTop: "10px",
                            marginRight: "24px",
                          }}
                        >
                          <IconButton
                            style={{
                              borderRadius: "360px",
                              width: "40px",
                              height: "40px",
                            }}
                            onClick={handleDropdownClick}
                          >
                            <ArrowUp />
                          </IconButton>
                        </div>
                        <div className="dropdown-item">
                          <div
                            style={{
                              color: "black",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {companies?.map((company) => {
                              if (
                                company?.countryIdentify === companyDocument ||
                                company === null
                              ) {
                                return;
                              } else
                                return (
                                  <div
                                    className={`dropdown__link ${
                                      dropdownState
                                        ? "isVisible"
                                        : "isHidden"
                                    }`}
                                    onClick={() =>
                                      handleSetDropdownValue(
                                        company?.businessId
                                      )
                                    }
                                  >
                                    <span
                                      className={`company__name ${
                                        dropdownState
                                          ? "isVisible"
                                          : "isHidden"
                                      }`}
                                      style={{
                                        maxWidth: "30ch",
                                        textOverflow: "ellipsis",
                                        paddingLeft: "8px",
                                      }}
                                    >
                                      {company?.corporateName}
                                    </span>
                                    <span
                                      className={`company__document ${
                                        dropdownState
                                          ? "isVisible"
                                          : "isHidden"
                                      }`}
                                      style={{ paddingLeft: "8px" }}
                                    >
                                      {formatCnpj(
                                        company?.countryIdentify
                                      )}
                                    </span>
                                  </div>
                                );
                            })}
                          </div>
                          <IconButton
                            onClick={() =>
                              window.location.replace(
                                `${getRedirectUrl()}/create-company/document-number`
                              )
                            }
                            style={{
                              borderRadius: "4px",
                              height: "64px",
                              width: "300px",
                            }}
                          >
                            <div className="dropdown-item">
                              <div
                                style={{
                                  marginRight: 24,
                                  color: "#4CAF4F",
                                }}
                                className={`dropdown-redirect-link ${
                                  dropdownState ? "isVisible" : "isHidden"
                                }`}
                              >
                                <span
                                  className={
                                    dropdownState ? "isVisible" : "isHidden"
                                  }
                                  style={{
                                    fontSize: "32px",
                                    paddingLeft: "16px",
                                  }}
                                >
                                  +
                                </span>{" "}
                                Adicionar Empresa
                              </div>
                            </div>
                          </IconButton>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "18rem",
                      }}
                    >
                      <Typography noWrap variant="h6">
                        {companyName}
                      </Typography>
                      <Typography
                        noWrap
                        style={{
                          color: "#FFFFFF",
                          textAlign: "left",
                          marginLeft: "8px",
                          fontSize: "12px",
                        }}
                      >
                        {formatCnpj(companyDocument)}
                      </Typography>
                    </div>
                  </div>
                </IconButton>
              )}
              <div className="side-menu--right--item--divider" />
              <Notification
                businessId={user?.businessId}
                userTypeId={user?.userTypeId}
              />
              <div className="side-menu--right--item">
                <UserIcon color="#ffffff" size={20} />
              <IconButton  color="inherit" onClick={handleHelpMenuClick}>
                <HelpOutline size={20} />
              </IconButton>
              </div>
            </div>
            <Menu
              anchorEl={anchorEl}
              open={openHelpMenu}
              onClose={handleHelpMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              style={{ zIndex: 8000, maxWidth: '300px' }}
            >
              <MenuItem onClick={() => window.open('https://antecipafacil.com.br/perguntas-frequentes/', '_blank')}>
                <LiveHelp />
                <Typography variant="body1" style={{ paddingLeft: 8 }}>Dúvidas frequentes</Typography>
              </MenuItem>
              <MenuItem disabled>
                <OndemandVideo/>
                <Typography
                variant="body2"
                style={{ padding: '8px 16px', opacity: 0.6, fontSize: 12, textAlign: 'justify' }}
              >
                  Tutoriais em vídeo
                </Typography>
              </MenuItem>
              <Typography
                variant="body2"
                style={{
                  padding: "8px 16px",
                  opacity: 0.6,
                  fontSize: 12,
                  textAlign: "justify",
                }}
              >
                Caso sua dúvida persista, entre em contato com nosso suporte pelo
                chat presente no canto inferior direito.
              </Typography>
            </Menu>
            <Button
              onClick={() => {
                Logout();
                // history.push('/');
              }}
              color="secondary"
            >
              <span
                className="btn-wrapper--label"
                style={{
                  color: "#ffffff",
                  fontSize: 18,
                  fontWeight: 500,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Sair
              </span>
              <span className="btn-wrapper--icon">
                <ExitIcon color="#ffffff" size={20} />
              </span>
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer anchor="top" className={clsx("drawer-mobile")} open={open}>
        <div className="drawer-mobile__content">
          <Link
            className="drawer-mobile__item"
            to="/vitrine"
            onClick={handleDrawerClose}
          >
            <ListItem
              button
              classes={{
                root: clsx(
                  "side-menu-list--list-item",
                  open && "side-menu-list--list-item--open"
                ),
              }}
            >
              <ListItemIcon classes={{ root: "side-menu-list--icon" }}>
                {window.location.pathname === "/vitrine" ? (
                  <VitrineIcon primary />
                ) : (
                  <VitrineIcon />
                )}
              </ListItemIcon>
            </ListItem>

            <ListItemText
              classes={{
                root: clsx(
                  "side-menu-list--list-item-text",
                  window.location.pathname === "/vitrine" &&
                    "side-menu-list--list-item-text--active",
                  open && "side-menu-list--list-item-text--open"
                ),
              }}
              style={{
                color:
                  window.location.pathname === "/vitrine"
                    ? theme.palette.primary.main
                    : "#727272",
              }}
              primary="Vitrine de títulos"
            />
          </Link>

          <Button
            className="drawer-mobile__item"
            onClick={() => {
              Logout();
            }}
            color="secondary"
          >
            <div className="drawer-mobile__item__left">
              <div className="drawer-mobile__item__left__icon-container">
                <UserIcon color="#727272" size={20} />
              </div>

              <ListItemText
                classes={{
                  root: "side-menu-list--list-item-text",
                }}
                style={{
                  color: "#727272",
                }}
                primary={user?.name}
              />
            </div>

            <div className="drawer-mobile__item__right">
              <ListItemText
                classes={{
                  root: "side-menu-list--list-item-text",
                }}
                style={{
                  color: "#727272",
                }}
                primary="Sair"
              />

              <span className="btn-wrapper--icon">
                <ExitIcon color="#727272" size={20} />
              </span>
            </div>
          </Button>
        </div>
      </Drawer>
      <Drawer
        className={clsx("drawer-desktop", classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        variant="permanent"
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>

        <Divider />

        <List className="side-menu-list side-menu-list-custom">
          {menus.map((menu, index) => {
            const isActive = window.location.pathname === menu.uri;
            const menuIcon = isActive ? menu.iconActive : menu.icon;

            const listItemClasses = clsx(
              "side-menu-list--list-item",
              isActive && "side-menu-list--list-item--active",
              open && "side-menu-list--list-item--open"
            );
            const listTextClasses = clsx(
              "side-menu-list--list-item-text",
              isActive && "side-menu-list--list-item-text--active",
              open && "side-menu-list--list-item-text--open"
            );

            return (
              <div
                className="side-menu-list--list-item--container"
                key={`${menu.uri} ${index}`}
                style={{
                  borderRight:
                    isActive && `3px solid ${theme.palette.primary.main}`,
                }}
              >
                {menu.uri == externalMenus.antecipaGOV.uri ||
                menu.uri == externalMenus.antigaAF.uri ? (
                  <a
                    className={`${
                      open && isActive ? "side-menu-list-link--open" : ""
                    }`}
                    key={menu.uri + index}
                    href={menu.uri}
                    target="_blank"
                    onClick={handleDrawerClose}
                    rel="noreferrer"
                  >
                    <ListItem
                      button
                      classes={{ root: listItemClasses }}
                      disabled
                    >
                      {menu.icon && (
                        <>
                          <Tooltip title={menu.name} placement="right">
                            <ListItemIcon
                              classes={{ root: "side-menu-list--icon" }}
                            >
                              {menuIcon}
                            </ListItemIcon>
                          </Tooltip>
                        </>
                      )}
                    </ListItem>

                    <ListItemText
                      classes={{ root: listTextClasses }}
                      primary={menu.name}
                    />
                  </a>
                ) : menu.redirectToV2 ? (
                  <Link
                    className={`${
                      open && isActive ? "side-menu-list-link--open" : ""
                    }`}
                    key={menu.name}
                    onClick={(e) => {
                      if (menu.redirectToV2) {
                        e.preventDefault();
                        window.location.replace(
                          `${getRedirectUrl()}${menu.uri}`
                        );
                      }
                      handleDrawerClose();
                    }}
                  >
                    <ListItem
                      button
                      classes={{ root: listItemClasses }}
                      disabled={menu.disabled}
                    >
                      <ReactIf condition={menu.icon}>
                        <ReactThen>
                          <>
                            <Tooltip title={menu.name} placement="right">
                              <ListItemIcon
                                classes={{ root: "side-menu-list--icon" }}
                              >
                                {menuIcon}
                              </ListItemIcon>
                            </Tooltip>
                          </>
                        </ReactThen>
                      </ReactIf>
                    </ListItem>

                    <ListItemText
                      classes={{ root: listTextClasses }}
                      primary={menu.name}
                    />
                  </Link>
                ) : (
                  <Link
                    className={`${
                      open && isActive ? "side-menu-list-link--open" : ""
                    }`}
                    key={menu.name}
                    to={menu.disabled ? "#" : menu.uri}
                    onClick={(e) => {
                      handleDrawerClose();
                    }}
                  >
                    <ListItem
                      button
                      classes={{ root: listItemClasses }}
                      disabled={menu.disabled}
                    >
                      <ReactIf condition={menu.icon}>
                        <ReactThen>
                          <>
                            <Tooltip title={menu.name} placement="right">
                              <ListItemIcon
                                classes={{ root: "side-menu-list--icon" }}
                              >
                                {menuIcon}
                              </ListItemIcon>
                            </Tooltip>
                          </>
                        </ReactThen>
                      </ReactIf>
                    </ListItem>

                    <ListItemText
                      classes={{ root: listTextClasses }}
                      primary={menu.name}
                    />
                  </Link>
                )}
              </div>
            );
          })}
        </List>
      </Drawer>

      <div className={classes.toolbarFillSpace} />
    </div>
  );
}
