class BankAccount {
  constructor(
    bankAccountId,
    businessId,
    countryIdentity,
    personTypeId,
    personTypeName,
    phone,
    email,
    name,
    corporateName,
    businessTypeId,
    businessTypeName,
    isDefault,
    ispb,
    bankName,
    bankCode,
    bankBranch,
    accountNumber,
    bankAccountTypeName,
    accountId
  ) {
    this.bankAccountId = bankAccountId;
    this.businessId = businessId;
    this.countryIdentity = countryIdentity;
    this.personTypeId = personTypeId;
    this.personTypeName = personTypeName;
    this.phone = phone;
    this.email = email;
    this.name = name;
    this.corporateName = corporateName;
    this.businessTypeId = businessTypeId;
    this.businessTypeName = businessTypeName;
    this.isDefault = isDefault;
    this.ispb = ispb;
    this.bankName = bankName;
    this.bankCode = bankCode;
    this.bankBranch = bankBranch;
    this.accountNumber = accountNumber;
    this.bankAccountTypeId = 2;
    this.bankAccountTypeName = bankAccountTypeName;
    this.accountId = accountId;
    this.accountTypeId = 2;
  }
}

export default BankAccount;
