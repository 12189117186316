import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Tooltip,
  TextField,
  MenuItem,
  LinearProgress,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import TablePagination from '@material-ui/lab/Pagination';

import clsx from 'clsx';

import './listagem.scss';

import { ConfirmButton } from 'components/Button';
import EnhancedTableHead from 'components/EnhancedTableHead';

import formatCnpj from 'utils/formatCNPJ';
import formatDate from 'utils/formatDate';

import {
  getTableContas,
  getTableContasFilter,
} from 'services/contas-consignadas';

import { useAuth } from 'contexts/auth';
import { useToast } from 'contexts/toast';

import AccountFocus from './AccountFocus';
import { headCells, status } from './items';

const useStyles = makeStyles({
  paper: {
    background: 'white',
    width: '105%',
    boxShadow: 'none',
    border: '1px solid #99999966',
    maxWidth: '100%',
    marginLeft: '-30px',
  },
  pagination: {
    background: 'white',
    boxShadow: 'none',
    border: '1px solid #99999966',
    marginLeft: '-30px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    width: '105%',
    maxWidth: '100%',
    marginTop: '70%',
    position: 'absolute',
    bottom: '-75px',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

export default function ListagemContasConsignadas({ bankAccount }) {
  const classes = useStyles();
  const { toast } = useToast();

  const { userOrigin } = useAuth();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [account, setAccount] = useState(bankAccount);

  const [filter, setFilter] = useState({ pesquisaPrincipal: '' });
  const [countElements, setCountElements] = useState(0);
  const [statusIds, setStatusId] = useState('');
  const [accounts, setAccounts] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [bankAccountDialog, setBankAccountDialog] = useState({ open: false });

  async function getItems() {
    try {
      setIsLoading(true);
      const response = await getTableContasFilter(userOrigin);

      const acc = response.data.bankAccounts
        .filter((account) => account.payrollAccount !== null)
        .map((account) => {
          const newAccNumber = account.accountNumber;
          // if (newAccNumber.charAt(newAccNumber.length - 1) === '-') {
          //   newAccNumber =
          //     account.accountNumber + ((Math.random() * 10) % 10);
          // }
          return {
            ...account,
            accountNumber: newAccNumber,
            accountStatusName: account.payrollAccount.statusName,
            accountStatusDate: account.payrollAccount.statusDate,
          };
        });
      setAccounts(acc);
      const count = response.data.count / 10;
      setCountElements(
        count === parseInt(count) ? parseInt(count) : parseInt(count) + 1
      );
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar dados.');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getItems();
  }, []);

  async function filterSearch(e, page, orderBy, ASC) {
    setIsLoading(true);
    e.preventDefault();

    const filterFull = {
      ...filter,
      statusId: statusIds,
    };
    let filterParams = '';
    Object.keys(filterFull).forEach((key) => {
      filterParams +=
        filterFull[key] !== '' ? `${key}=${filterFull[key]}&` : '';
    });
    filterParams += filterParams === '' ? '' : '&';

    try {
      const response = await getTableContas(
        filterParams,
        orderBy,
        ASC,
        page,
        userOrigin
      );

      const acc = response.data.bankAccounts
        .filter((account) => account.payrollAccount !== null)
        .map((account) => {
          const newAccNumber = account.accountNumber;
          // if (newAccNumber.charAt(newAccNumber.length - 1) === '-') {
          //   newAccNumber = account.accountNumber + ((Math.random() * 10) % 10);
          // }
          return {
            ...account,
            accountNumber: newAccNumber,
            accountStatusName: account.payrollAccount.statusName,
            accountStatusDate: account.payrollAccount.statusDate,
          };
        });
      setAccounts(acc);
      const count = response.data.count / 10;
      setCountElements(
        count === parseInt(count) ? parseInt(count) : parseInt(count) + 1
      );
      setIsLoading(false);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar dados.');
    }
  }

  const handleChangePage = (event, newPage) => {
    filterSearch(event, newPage - 1);
  };

  const handlerChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePayrollAccount = (key, value) => {
    const newAccount = account;
    newAccount.payrollAccount[key] = value;
    setAccount(newAccount);
  };

  function getStatusColor(account) {
    const statusColor = status.find(
      (item) => item.statusName == account.payrollAccount.statusName
    );

    return statusColor ? statusColor.backgroundColor : 'red';
  }

  const StylizedInput = ({
    className,
    label,
    inputName,
    disabled,
    required,
    bankAccount,
    index,
    ...props
  }) => (
    <div className={`${className}`}>
      <TextField
        fullWidth
        className=""
        id={`${label}${index}`}
        label={`${label}${required ? '*' : ''}`}
        name={inputName}
        value={bankAccount[inputName] === null ? '' : bankAccount[inputName]}
        disabled={disabled}
        variant="outlined"
        {...props}
      />
    </div>
  );

  return (
    <div className="pageInvoice">
      <Paper
        elevation={3}
        className={clsx('app-page-title')}
        style={{
          // height: '135px',
          maxWidth: '100%',
          display: 'flow-root',
        }}
      >
        <div>
          <div style={{ marginTop: '-25px' }}>
            <div className="app-page-title--heading">
              <h1 style={{ fontFamily: 'Poppins' }}>Contas Consignadas</h1>
            </div>
          </div>
        </div>

        <div
          className="searchForm"
          style={{
            boxShadow: 'none',
            width: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <TextField
              fullWidth
              className="mt-2 mb-2 ml-2"
              id="outlined-basic"
              label="Pesquisar (NOME FANTASIA, RAZÃO SOCIAL, E-MAIL ou CNPJ)"
              name="pesquisaPrincipal"
              value={filter.pesquisaPrincipal}
              onChange={handlerChange}
              variant="outlined"
              onKeyPressCapture={(e) => {
                if (e.key == 'Enter') filterSearch(e);
              }}
              style={{ marginRight: '15px', width: '75%' }}
            />

            <TextField
              fullWidth
              className="m-2"
              id="outlined-select-currency"
              select
              label="Status"
              value={statusIds}
              onChange={(e) => {
                setStatusId(e.target.value);
              }}
              onKeyPressCapture={(e) => {
                if (e.key == 'Enter') filterSearch(e);
              }}
              style={{ width: '25%' }}
              variant="outlined"
            >
              {status.map((element) => (
                <MenuItem key={element.statusId} value={element.statusId}>
                  {element.statusName}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="item itemPicker">
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                marginTop: '10px',
              }}
            >
              <ConfirmButton onClick={(e) => filterSearch(e)}>
                Consultar
              </ConfirmButton>
            </div>
          </div>
        </div>
      </Paper>

      <div
        className="tableContainerInvoice"
        style={{
          marginTop: '50px',
          position: 'relative',
          height: 'max-content',
        }}
      >
        {(accounts === undefined || isLoading) && (
          <div style={{ marginLeft: '-30px' }}>
            <LinearProgress value={55} color="primary" />
          </div>
        )}
        <>
          {!isLoading && accounts !== undefined && (
            <Paper className={classes.paper}>
              <TableContainer>
                <Table>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headCells}
                    classes={classes}
                  />
                  {stableSort(accounts, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((account, index) => (
                      <>
                        <TableBody key={account.accountId}>
                          <TableCell style={{ textAlign: 'center' }}>
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'default',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <b
                                  className="text-left"
                                  style={{
                                    fontSize: '1.25em',
                                    whiteSpace: 'normal',
                                    width: 272,
                                    lineHeight: 1.25,
                                  }}
                                >
                                  {account.name}
                                </b>
                                <span
                                  className="text-black-50 text-left mb-2"
                                  style={{
                                    whiteSpace: 'normal',
                                    width: '108%',
                                    lineHeight: 1.25,
                                  }}
                                >
                                  {account.corporateName}
                                </span>
                                <span
                                  className="text-left"
                                  style={{ lineHeight: 1.25 }}
                                >
                                  {formatCnpj(account.countryIdentity)}
                                </span>
                              </div>
                              <div className="d-flex flex-column align-items-center justify-content-center">
                                <div className="d-flex flex-row align-items-center justify-content-around">
                                  <div />
                                  <Link to={`/Extrato/${account.accountId}`}>
                                    <Tooltip title="Extrato" placement="top">
                                      <IconButton
                                        size="medium"
                                        variant="outlined"
                                        style={{ color: 'grey' }}
                                        onClick={() => {}}
                                      >
                                        <ReceiptIcon className="font-size-lg" />
                                      </IconButton>
                                    </Tooltip>
                                  </Link>
                                </div>
                                <div className="d-flex flex-row align-items-center justify-content-around">
                                  <div />
                                  <Tooltip
                                    title="Alterar Status"
                                    placement="top"
                                  >
                                    <IconButton
                                      size="medium"
                                      variant="outlined"
                                      style={{ color: 'grey' }}
                                      onClick={() =>
                                        setBankAccountDialog({
                                          open: true,
                                          bankAccount: account,
                                          setBankAccount: setAccount,
                                          index,
                                          handleChangePayrollAccount,
                                        })
                                      }
                                    >
                                      <EditIcon className="font-size-lg" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {account.bankCode} | {account.bankName}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            {formatDate(account.accountStatusDate)}
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            <div
                              style={{
                                cursor: 'default',
                                backgroundColor: getStatusColor(account),
                                color: '#FFF',
                                height: '30px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                borderRadius: '180px',
                                maxWidth: '360px',
                                fontWeight: 500,
                              }}
                            >
                              {account.accountStatusName}
                            </div>
                          </TableCell>
                        </TableBody>
                      </>
                    ))}
                </Table>
              </TableContainer>
            </Paper>
          )}
        </>
      </div>
      <div
        className="tableContainerInvoice"
        style={{
          marginTop: '-10px',
          position: 'relative',
          height: 'max-content',
        }}
      >
        <Paper className={classes.pagination}>
          <TablePagination
            variant="outlined"
            shape="rounded"
            size="large"
            count={countElements}
            colSpan={3}
            onChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>

      <AccountFocus
        bankAccountDialog={bankAccountDialog}
        setBankAccountDialog={setBankAccountDialog}
        StylizedInput={StylizedInput}
        refreshListagemData={getItems}
      />
    </div>
  );
}
