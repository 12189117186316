/* eslint eqeqeq: 0 */
import React, { useState } from 'react';

import { Box, Button, Checkbox, Grid } from '@material-ui/core';

import 'pages/AgendaRecebiveis/capa.scss';

import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import _ from 'lodash';

import { downloadAWSFile, updateDossie } from 'services/dossie';

import { useToast } from 'contexts/toast';

import { useRecebivel } from 'hooks/useRecebivel';

import { DossieDocumentItem } from './DossieDocumentItem';

export default ({ agendaId, edit, type }) => {
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [compressing, setCompressing] = useState(false);

  const { toast } = useToast();

  const {
    agendaDocs,
    agenda,
    setAgendaDocs,
    canEditDossie,
    isLoadingRecebivel,
  } = useRecebivel({
    edit,
    agendaId,
  });

  const checkAll = () => {
    const selecteds = agendaDocs
      .filter((doc) => {
        if (doc.statusName == 'Excluido') return false;
        if (Number(doc.inDossie) === 1) return true;
        return true;
      })
      .map((item) => item.fileId);
    setSelectedDocs(selecteds);
  };

  const checkOne = (event) => {
    if (event.target.checked) {
      setSelectedDocs([...selectedDocs, event.target.id]);
    } else {
      const newSelectedBeforeFilter = selectedDocs.filter(
        (key) => key !== event.target.id
      );
      setSelectedDocs(newSelectedBeforeFilter);
    }
  };

  const updateDocument = async (document, documents, setDocuments) => {
    try {
      await updateDossie(document);

      const updatedDocs = documents.map((doc) => {
        if (doc.fileId === document.fileId) return document;
        return doc;
      });
      setDocuments(updatedDocs);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const saveAsZIP = async () => {
    if (Object.keys(selectedDocs).length === 0) return;
    setCompressing(true);
    const documents = agendaDocs.filter((doc) =>
      selectedDocs.find((selectedDoc) => selectedDoc === doc.fileId)
    );
    const zip = JSZip();

    const files = [];
    for await (const doc of documents) {
      const file = await downloadAWSFile(doc.url, doc.name);
      files.push(file);
    }
    files.forEach((file) => zip.file(file.name, file.data));
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `Dossie${Math.floor(Math.random() * 10)}.zip`);
      setCompressing(false);
    });
  };

  const changeSelectAll = (event) => {
    if (event.target.checked) {
      return checkAll();
    }
    setSelectedDocs([]);
  };

  const renderDocs = () => {
    const component = [];
    const resposta = _.groupBy(agendaDocs, 'documentTypeName');
    _.forEach(resposta, function (value, key) {
      const dossieItensFiltred = value.filter((doc) => {
        if (doc.statusName == 'Excluido') return false;
        if (Number(doc.inDossie) === 1) return true;
        if (Number(doc.inDossie) === 0) return false;
        return true;
      });
      if (dossieItensFiltred && dossieItensFiltred.length > 0) {
        component.push(
          <DossieDocumentItem
            key={key}
            title={key}
            checkOne={checkOne}
            selectedDocs={selectedDocs}
            dossieItensFiltred={dossieItensFiltred}
          />
        );
      }
    });

    return component;
  };

  return (
    <>
      <Box
        width="100%"
        sx={{
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            justifyContent: 'space-between',
            display: 'flex',
            marginBottom: '25px',
          }}
        >
          <Box>
            <Checkbox
              onChange={changeSelectAll}
              color="#0e8018"
              iconStyle={{ fill: '#0e8018' }}
            />
            <span onClick={changeSelectAll}> Selecionar todos </span>
          </Box>

          <Button
            onClick={saveAsZIP}
            style={{ backgroundColor: '#0e8018', color: '#ffffff' }}
            variant="contained"
            disableElevation
          >
            Baixar selecionados
          </Button>
        </Box>
        {agendaDocs && agendaDocs.length > 0 && (
          <Grid container spacing={2}>
            {agendaDocs && renderDocs().map((item) => item)}
          </Grid>
        )}
      </Box>
    </>
  );
};
