import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import Axios from "axios";

import ExtractModal from "../components/AccountManagement/ExtractModal";
import ModalTransfer from "../components/ModalTransfer";
import useBankingAccounts from "../hooks/useBankingAccounts";
import {
  alertMessageSwal,
  errorMessageSwal,
  loadingSwal,
  successMessageSwal,
} from "../services/SwalService";
import { useAuth } from "./auth";
import { DialogContext } from "./dialog";
import AxiosInterceptor from "utils/axios-interceptor";

export const AccountManagementContext = createContext({
  accounts: [],
  isLoading: false,
});

export default function AccountManagementProvider({ children }) {
  const { user } = useAuth();
  const [tabValue, setTabValue] = useState("account-consigned");
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [fetchBanking, setFetchBanking] = useState(false);
  const [optionsMenu, setOptionsMenu] = useState([]);
  const axiosInstance = AxiosInterceptor();

  const { toggleDialog } = useContext(DialogContext);

  const { accounts, isLoading, error, mutate } = useBankingAccounts(
    tabValue === "account-consigned" && fetchBanking,
    { page, ...filters },
    { revalidateOnFocus: false }
  );

  const openAccountConsigned = useCallback((data) => {
    loadingSwal("Criando conta");
    return axiosInstance
      .post(`${process.env.REACT_APP_BANKING_API}/abc-accounts`, data)
      .then((res) => {
        mutate();
        successMessageSwal(
          "Conta criada",
          "Iniciamos o processo de abertura de conta, este processo leva alguns minutos. Acompanhe na listagem o status da conta"
        ).then();
        return true;
      })
      .catch((err) => {
        if (err.response.status === 409) {
          errorMessageSwal(
            "Conta já existente",
            "Já existe uma conta consignada para esta empresa"
          ).then();

          return false;
        }

        errorMessageSwal(
          "Falha ao abrir conta",
          "Não conseguimos iniciar o processo de abertura, tente novamente em alguns instantes"
        ).then();

        return false;
      });
  }, []);

  const createTransfer = useCallback((data) => {
    loadingSwal("Efetuando solicitação de transferência");

    return axiosInstance
      .post(`${process.env.REACT_APP_BANKING_API}/transfers`, data)
      .then((res) => {
        successMessageSwal(
          "Solicitação efetivada com sucesso!",
          res?.data?.transferResult ??
            "Uma solicitação de transferência foi efetivada"
        );

        return true;
      })
      .catch((err) => {
        if (err.response.data.statusCode === 422) {
          let messageShow = "";
          const { message } = err.response.data;

          if (typeof message === "string") {
            messageShow = message;
          } else {
            message.forEach((element) => {
              messageShow += `${element.message}\n`;
            });
          }

          errorMessageSwal("Falha ao solicitar transferência", messageShow);

          return false;
        }

        errorMessageSwal(
          "Falha ao solicitar transferência",
          "Falha ao solicitar transferência, tente novamente em alguns instantes"
        );

        return false;
      });
  }, []);

  useEffect(() => {
    const alloweds =
      process?.env?.REACT_APP_BANK_TRANSER_ALLOWEDS.split(",") ?? [];
    setOptionsMenu([
      {
        name: "Transferências",
        disabled: !alloweds.find((val) => val === user?.userId),
        action: (bankingAccount) => {
          if (bankingAccount.account?.statusOnAbc !== "Ativa") {
            alertMessageSwal(
              "Conta em processo de abertura",
              "Esta conta ainda não está totalmente ativa."
            );
            return;
          }
          toggleDialog(
            true,
            "Transferência de valores",
            <ModalTransfer
              bankingAccount={bankingAccount}
              createTransfer={createTransfer}
            />
          );
        },
      },
      {
        name: "Extrato",
        action: (bankingAccount) => {
          if (bankingAccount.account?.statusOnAbc !== "Ativa") {
            alertMessageSwal(
              "Conta em processo de abertura",
              "Esta conta ainda não está totalmente ativa."
            );
            return;
          }
          toggleDialog(
            true,
            "Extrato",
            <ExtractModal
              accountData={bankingAccount}
              account={bankingAccount.account}
            />
          );
        },
      },
    ]);
  }, [user]);

  const value = useMemo(
    () => ({
      tabValue,
      setTabValue,
      optionsMenu,
      accounts,
      error,
      mutate,
      isLoading,
      openAccountConsigned,
      setPage,
      setFetchBanking,
      setFilters,
    }),
    [
      filters,
      page,
      tabValue,
      optionsMenu,
      accounts,
      isLoading,
      error,
      fetchBanking,
    ]
  );

  return (
    <AccountManagementContext.Provider value={value}>
      {children}
    </AccountManagementContext.Provider>
  );
}
