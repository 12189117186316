import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Box, Snackbar, Typography, Tabs, Tab } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import "./capa.scss";
import Content from "example-components/content";
import Cookies from "js-cookie";
import PageTitle from "components/PageTitle";
import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";
import { selectTab } from "./abasCadastro";
import Capa from "./tabs/Capa";
import Clientes from "./tabs/ClientesCedente";
import DadosBancarios from "./tabs/DadosBancarios";
import DadosComerciais from "./tabs/DadosComerciaisCedente";
import Documentos from "./tabs/Documentos";
import Dossie from "./tabs/Dossie";
import AgendaRecebiveis from "./tabs/Recebiveis";
import RelacionadosCedente from "./tabs/RelacionadosCedente";
import RelacionadosFinanciador from "./tabs/RelacionadosFinanciador";
import Reputacao from "./tabs/ReputacaoCedente";
import Taxas from "./tabs/Taxas";
import Testemunhas from "./tabs/Testemunhas";
import Usuarios from "./tabs/UsuariosCedente";
import AxiosInterceptor from "utils/axios-interceptor";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      className="w-100"
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box className="p-0" p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

export default () => {
  const statusCadastro = [
    { statusId: 2, statusName: "Aguardando Aprovação" },
    { statusId: 3, statusName: "Aguardando Documentação" },
    { statusId: 1, statusName: "Cadastro Pendente" },
    { statusId: 5, statusName: "Em Análise" },
    { statusId: 11, statusName: "Checagem" },
    { statusId: 12, statusName: "Formalização" },
  ];

  const radioStates = {
    cadastro: "Status Cadastro",
    final: "Status Final",
  };

  const [businesses, setBusinesses] = useState(undefined);

  const [business, setBusiness] = useState(undefined);
  const [businessInitial, setBusinessInitial] = useState(undefined);

  const [businessRelateds, setBusinessRelateds] = useState([]);
  const [businessRelatedsInitial, setBusinessRelatedsInitial] = useState([]);

  const [businessUsers, setBusinessUsers] = useState([]);

  const [businessCustomers, setBusinessCustomers] = useState([]);
  const [businessCustomersInitial, setBusinessCustomersInitial] = useState([]);

  const [businessSuppliers, setBusinessSuppliers] = useState([]);
  const [businessSuppliersInitial, setBusinessSuppliersInitial] = useState([]);

  const [businessDocuments, setBusinessDocuments] = useState([]);
  const [businessPending, setBusinessPending] = useState([]);

  const [businessBankAccounts, setBusinessBankAccounts] = useState([]);

  const [buyerRate, setBuyerRate] = useState({});
  const [businessWitness, setBusinessWitness] = useState([]);

  const [buyerTypes, setBuyerTypes] = useState([]);
  const [businessSizes, setBusinessSizes] = useState([]);
  const [taxRules, setTaxRules] = useState([]);
  const [constitutions, setConstitutions] = useState([]);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [tabs, setTabs] = useState(undefined);
  const [tabValue, setTabValue] = useState(0);

  const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);

  const { user, isAdmin } = useAuth();
  const { toast } = useToast();
  const history = useHistory();

  const businessId =
    user.userTypeId === 3 ? Cookies.get("businessId") : user.businessId;

  const handleSetTabs = (userTypeId, businessTypeId) => {
    const selectedTab = selectTab(userTypeId, businessTypeId);
    setTabs(selectedTab);
  };

  async function getBusiness() {
    const axiosInstance = AxiosInterceptor();

    await axiosInstance
      .get(`${process.env.REACT_APP_NODE_API}/business/find/full/${businessId}`)
      .then((res) => {
        handleSetTabs(user.userTypeId, Number(res.data.business.typeId));
        setBusiness(res.data.business);
        setBusinessInitial(res.data.business);

        setBusinessRelateds(res.data.responsibles);
        setBusinessRelatedsInitial(res.data.responsibles);

        setBusinessUsers(res.data.users);

        setBusinessCustomers(res.data.customers);
        setBusinessCustomersInitial(res.data.customers);

        setBusinessSuppliers(res.data.suppliers);
        setBusinessSuppliersInitial(res.data.suppliers);

        setBusinessDocuments(res.data.documents);
        setBusinessPending(res.data.pendings);

        const bankAccounts = res.data.bankAccounts.map((bankAccount) => ({
          ...bankAccount,
          ...bankAccount.payrollAccount,
        }));
        setBusinessBankAccounts(bankAccounts);

        setBuyerRate(res.data.buyerRate);
        setBusinessWitness(res.data.businessWitness);
      });
  }

  async function getBusinesses() {
    if (!isAdmin()) return;
    const axiosInstance = AxiosInterceptor();
    await axiosInstance
      .get(`${process.env.REACT_APP_NODE_API}/business`)
      .then((res) => {
        setBusinesses(res.data);
      });
  }

  async function getDocuments() {
    const axiosInstance = AxiosInterceptor();

    await axiosInstance
      .get(`${process.env.REACT_APP_NODE_API}/business/find/full/${businessId}`)
      .then((res) => {
        setBusinessDocuments(res.data.documents);
      });
  }

  async function getBusinessWitness() {
    const axiosInstance = AxiosInterceptor();

    await axiosInstance
      .get(`${process.env.REACT_APP_NODE_API}/business/find/full/${businessId}`)
      .then((res) => {
        setBusinessWitness(res.data.businessWitness);
      });
  }

  async function getBuyerTypes() {
    const axiosInstance = AxiosInterceptor();

    await axiosInstance
      .get(`${process.env.REACT_APP_NODE_API}/buyerType`)
      .then((res) => {
        setBuyerTypes(res.data);
      });
  }

  async function getBusinessSizes() {
    const axiosInstance = AxiosInterceptor();

    await axiosInstance
      .get(`${process.env.REACT_APP_NODE_API}/businessSize`)
      .then((res) => {
        setBusinessSizes(res.data);
      });
  }

  async function getTaxRules() {
    const axiosInstance = AxiosInterceptor();

    await axiosInstance
      .get(`${process.env.REACT_APP_NODE_API}/taxRule`)
      .then((res) => {
        setTaxRules(res.data);
      });
  }

  async function getConstitutions() {
    const axiosInstance = AxiosInterceptor();

    await axiosInstance
      .get(`${process.env.REACT_APP_NODE_API}/constitution`)
      .then((res) => {
        setConstitutions(res.data);
      });
  }

  async function loadCadastroFinanciadorCedenteData() {
    try {
      const dataPromises = [
        getBusinesses(),
        getBusiness(),
        getBuyerTypes(),
        getBusinessSizes(),
        getTaxRules(),
        getConstitutions(),
        getDocuments(),
        getBusinessWitness(),
      ];

      await toast.promise(Promise.all(dataPromises), {
        loading: "Carregando dados",
        success: "Dados carregados",
        error: "Erro carregando dados",
      });

      setIsAllDataLoaded(true);
    } catch (error) {
      history.goBack();
    }
  }

  useEffect(() => {
    loadCadastroFinanciadorCedenteData();
  }, []);

  const defineInitialRadio = (business) => {
    if (
      statusCadastro.find(
        (status) => status.statusId === Number(business.statusId)
      ) !== undefined
    )
      return radioStates.cadastro;
    return radioStates.final;
  };

  const handleChange = (event, newValue) => {
    if (newValue == 9) {
      try {
        getDocuments();

        toast.promise(getDocuments(), {
          loading: "Carregando dossiê",
          success: "Dossiês carregados",
          error: "Erro ao carregar dossiê",
        });
      } catch (err) {
        toast.error(err.message);
      }
    }
    setTabValue(newValue);
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  return (
    <>
      <Content>
        <PageTitle
          titleHeading={business !== undefined ? business.corporateName : ""}
          titleDescription={
            isAdmin() ? (business !== undefined ? business.typeName : "") : ""
          }
        />
        <div className="example-card-seamless mb-4-spacing">
          <h5 className="display-5 mb-4 font-weight-bold" />
          <div
            style={{ marginTop: "30px", marginLeft: "-65px", width: "105%" }}
          >
            {business && tabs && isAllDataLoaded && (
              <div>
                <Snackbar
                  open={snackbarState.open}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  key="topcenter"
                >
                  <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbarState.severity}
                  >
                    {snackbarState.message}
                  </Alert>
                </Snackbar>
                <div
                  className="tabs-header"
                  style={
                    Object.keys(tabs).length <= 7
                      ? {
                          marginBottom: "30px",
                          marginLeft: "40px",
                        }
                      : {
                          marginBottom: "30px",
                        }
                  }
                >
                  <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {Object.keys(tabs).map((key) => (
                      <Tab label={tabs[key].name} />
                    ))}
                  </Tabs>
                </div>
                {tabs.status && (
                  <TabPanel value={tabValue} index={tabs.status.index}>
                    <Capa
                      title={tabs.status.name}
                      business={business}
                      setBusiness={(value) => setBusiness(value)}
                      businessInitial={businessInitial}
                      setBusinessInitial={(value) => setBusinessInitial(value)}
                      snackbarState={snackbarState}
                      setSnackbarState={(value) => setSnackbarState(value)}
                      initialRadio={defineInitialRadio(business)}
                      businesses={businesses}
                    />
                  </TabPanel>
                )}
                {tabs.recebiveis && (
                  <TabPanel value={tabValue} index={tabs.recebiveis.index}>
                    <div style={{ marginLeft: "70px" }}>
                      <AgendaRecebiveis />
                    </div>
                  </TabPanel>
                )}
                {tabs.dadosComerciais && (
                  <TabPanel value={tabValue} index={tabs.dadosComerciais.index}>
                    <DadosComerciais
                      title={tabs.dadosComerciais.name}
                      business={business}
                      setBusiness={(value) => setBusiness(value)}
                      businessInitial={businessInitial}
                      refreshData={getBusiness}
                      setBusinessInitial={(value) => setBusinessInitial(value)}
                      snackbarState={snackbarState}
                      setSnackbarState={(value) => setSnackbarState(value)}
                      initialRadio={defineInitialRadio(business)}
                      businessTypes={{
                        buyerTypes,
                        businessSizes,
                        taxRules,
                        constitutions,
                      }}
                    />
                  </TabPanel>
                )}
                {tabs.relacionadosCedente && (
                  <TabPanel
                    value={tabValue}
                    index={tabs.relacionadosCedente.index}
                  >
                    <RelacionadosCedente
                      title={tabs.relacionadosCedente.name}
                      business={business}
                      // setBusiness={(value) => setBusiness(value)}
                      businessRelateds={businessRelateds}
                      setBusinessRelateds={(value) =>
                        setBusinessRelateds(value)
                      }
                      businessRelatedsInitial={businessRelatedsInitial}
                      setBusinessRelatedsInitial={(value) =>
                        setBusinessRelatedsInitial(value)
                      }
                      // businessInitial={businessInitial}
                      // setBusinessInitial={(value) => setBusinessInitial(value)}
                      snackbarState={snackbarState}
                      setSnackbarState={(value) => setSnackbarState(value)}
                      initialRadio={defineInitialRadio(business)}
                    />
                  </TabPanel>
                )}
                {tabs.relacionadosFinanciador && (
                  <TabPanel
                    value={tabValue}
                    index={tabs.relacionadosFinanciador.index}
                  >
                    <RelacionadosFinanciador
                      title={tabs.relacionadosFinanciador.name}
                      business={business}
                      // setBusiness={(value) => setBusiness(value)}
                      businessRelateds={businessRelateds}
                      setBusinessRelateds={(value) =>
                        setBusinessRelateds(value)
                      }
                      businessRelatedsInitial={businessRelatedsInitial}
                      setBusinessRelatedsInitial={(value) =>
                        setBusinessRelatedsInitial(value)
                      }
                      // businessInitial={businessInitial}
                      // setBusinessInitial={(value) => setBusinessInitial(value)}
                      snackbarState={snackbarState}
                      setSnackbarState={(value) => setSnackbarState(value)}
                      initialRadio={defineInitialRadio(business)}
                    />
                  </TabPanel>
                )}
                {tabs.reputacao && (
                  <TabPanel value={tabValue} index={tabs.reputacao.index}>
                    <Reputacao
                      title={tabs.reputacao.name}
                      business={business}
                      // setBusiness={(value) => setBusiness(value)}
                      businessRelateds={businessRelateds}
                      setBusinessRelateds={(value) =>
                        setBusinessRelateds(value)
                      }
                      businessRelatedsInitial={businessRelatedsInitial}
                      setBusinessRelatedsInitial={(value) =>
                        setBusinessRelatedsInitial(value)
                      }
                      // businessInitial={businessInitial}
                      // setBusinessInitial={(value) => setBusinessInitial(value)}
                      snackbarState={snackbarState}
                      setSnackbarState={(value) => setSnackbarState(value)}
                      initialRadio={defineInitialRadio(business)}
                    />
                  </TabPanel>
                )}
                {tabs.clientes && (
                  <TabPanel value={tabValue} index={tabs.clientes.index}>
                    <div style={{ marginLeft: "30px" }}>
                      <Clientes
                        title={tabs.clientes.name}
                        business={business}
                        // setBusiness={(value) => setBusiness(value)}
                        businessCustomers={businessCustomers}
                        setBusinessCustomers={(value) =>
                          setBusinessCustomers(value)
                        }
                        businessCustomersInitial={businessCustomersInitial}
                        setBusinessCustomersInitial={(value) =>
                          setBusinessCustomersInitial(value)
                        }
                        businessSuppliers={businessSuppliers}
                        setBusinessSuppliers={(value) =>
                          setBusinessSuppliers(value)
                        }
                        businessSuppliersInitial={businessSuppliersInitial}
                        setBusinessSuppliersInitial={(value) =>
                          setBusinessSuppliersInitial(value)
                        }
                        snackbarState={snackbarState}
                        setSnackbarState={(value) => setSnackbarState(value)}
                        initialRadio={defineInitialRadio(business)}
                      />
                    </div>
                  </TabPanel>
                )}
                {tabs.usuarios && (
                  <TabPanel value={tabValue} index={tabs.usuarios.index}>
                    <div style={{ marginLeft: "65px" }}>
                      <Usuarios
                        business={business}
                        businessUsers={businessUsers}
                        setBusinessUsers={(value) => setBusinessUsers(value)}
                      />
                    </div>
                  </TabPanel>
                )}
                {tabs.dadosBancarios && (
                  <TabPanel value={tabValue} index={tabs.dadosBancarios.index}>
                    <DadosBancarios
                      title={tabs.dadosBancarios.name}
                      business={business}
                      businessBankAccounts={businessBankAccounts}
                      setBusinessBankAccounts={(value) =>
                        setBusinessBankAccounts(value)
                      }
                      snackbarState={snackbarState}
                      setSnackbarState={(value) => setSnackbarState(value)}
                      initialRadio={defineInitialRadio(business)}
                    />
                  </TabPanel>
                )}
                {tabs.documentos && (
                  <TabPanel value={tabValue} index={tabs.documentos.index}>
                    <Documentos
                      title={tabs.documentos.name}
                      business={business}
                      refreshBusinessData={getDocuments}
                      setBusiness={(value) => setBusiness(value)}
                      businessDocuments={businessDocuments}
                      setBusinessDocuments={(value) =>
                        setBusinessDocuments(value)
                      }
                      businessPending={businessPending}
                      setBusinessPending={(value) => setBusinessPending(value)}
                    />
                  </TabPanel>
                )}
                {tabs.dossie && (
                  <TabPanel value={tabValue} index={tabs.dossie.index}>
                    <Dossie
                      title={tabs.dossie.name}
                      business={business}
                      refreshBusinessData={getDocuments}
                      setBusiness={(value) => setBusiness(value)}
                      businessDocuments={businessDocuments}
                      setBusinessDocuments={(value) =>
                        setBusinessDocuments(value)
                      }
                      businessPending={businessPending}
                      setBusinessPending={(value) => setBusinessPending(value)}
                    />
                  </TabPanel>
                )}
                {tabs.taxas && (
                  <TabPanel value={tabValue} index={tabs.taxas.index}>
                    <Taxas
                      title={tabs.taxas.name}
                      business={business}
                      refreshData={getBusiness}
                      setBusiness={(value) => setBusiness(value)}
                      buyerRates={buyerRate}
                      setBuyerRates={(rate) => setBuyerRate(rate)}
                      snackbarState={snackbarState}
                      setSnackbarState={(value) => setSnackbarState(value)}
                    />
                  </TabPanel>
                )}
                {tabs.testemunhas && (
                  <TabPanel value={tabValue} index={tabs.testemunhas.index}>
                    <Testemunhas
                      title={tabs.testemunhas.name}
                      business={business}
                      businessWitnesses={businessWitness}
                      setBusinessWitnesses={(witnesses) =>
                        setBusinessWitness(witnesses)
                      }
                      refreshDataTestemunha={getBusinessWitness}
                    />
                  </TabPanel>
                )}
              </div>
            )}
          </div>
        </div>
      </Content>
    </>
  );
};
