import React, { Fragment } from 'react';

import clsx from 'clsx';

import { connect } from 'react-redux';

import projectLogo from '../../assets/images/logo-afacil - branco grande.png';

const HeaderLogo = props => {
  const { sidebarToggle, sidebarHover } = props;
  return (
    <Fragment>
      <div
        className={clsx('app-header-logo', {
          'app-header-logo-close': sidebarToggle,
          'app-header-logo-open': sidebarHover
        })}>
        <img
          className="app-header-logo-img"
          alt="Carolina React Admin Dashboard with Material-UI PRO"
          src={projectLogo}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(HeaderLogo);
