import { removePontuacao } from "utils/removePontuacao";
import AxiosInterceptor from "utils/axios-interceptor";

export const getQuiz = async (cnpj) => {
  const axiosInstance = AxiosInterceptor();
  const urlQuiz = `${
    process.env.REACT_APP_NODE_API
  }/quiz/cnpj/${removePontuacao(cnpj)}`;
  const { data } = await axiosInstance.get(urlQuiz);

  return data;
};

export const limpacadastro = async (payload) => {
  const axiosInstance = AxiosInterceptor();
  const urlQuiz = `${process.env.REACT_APP_NODE_API}/quiz/limpacadastro`;
  const { data } = await axiosInstance.post(urlQuiz, payload);
  return data;
};
