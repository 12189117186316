import useSWR from 'swr';

import { fetchWithToken } from '../fetchWithToken';

export default function useBusiness(
  options = {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    shouldRetryOnError: true,
    errorRetryInterval: 1000,
  }
) {
  const {
    data: business,
    error,
    isValidating,
    mutate,
  } = useSWR(
    `${process.env.REACT_APP_NODE_API}/business`,
    fetchWithToken,
    options
  );

  return {
    business: business && business.data ? business.data : null,
    error,
    isValidating,
    isLoading: !error && !business,
    mutate,
  };
}
