import React, { useState, useEffect } from "react";

import "../capa.scss";
import { TextField } from "@material-ui/core";

import formatTelefone from "utils/formatTelefone";

import AxiosInterceptor from "utils/axios-interceptor";

const formatDate = (date) => {
  if (date === undefined) return "";
  return `${
    new Date(date).getDate() < 10
      ? `0${new Date(date).getDate()}`
      : new Date(date).getDate()
  }/${
    new Date(date).getMonth() + 1 < 10
      ? `0${new Date(date).getMonth() + 1}`
      : new Date(date).getMonth() + 1
  }/${new Date(date).getFullYear()}`;
};

const Reputacao = (props) => {
  const { business, type } = props;

  const isOnModalVitrine = type === "modal-vitrine";

  const [metabaseEmbedURL, setMetabaseEmbedURL] = useState("");

  const getMetabase = async () => {
    const axiosInstance = AxiosInterceptor();
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_NODE_API}/business/metabase/reputacao/${business.businessId}`
    );
    setMetabaseEmbedURL(data);
  };

  useEffect(() => {
    getMetabase();
  }, []);

  const StylizedInput = ({
    className,
    label,
    inputName,
    disabled,
    required,
    related,
    format,
    ...props
  }) => (
    <div className={`item pr-2 w-100 mt-2 ${className}`}>
      <TextField
        fullWidth
        className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
        id={label}
        label={`${label}${required ? "*" : ""}`}
        name={inputName}
        value={format ? format(related[inputName]) : related[inputName]}
        disabled={disabled}
        variant="outlined"
        onChange={() => {}}
        {...props}
      />
    </div>
  );

  const RelacionadoComponent = (props) => {
    const { index } = props;

    return (
      <div
        className="searchForm"
        style={{ marginBottom: 0, width: isOnModalVitrine ? "100%" : "95%" }}
      >
        <div className="containerTitle w-100 mt-4">
          <div className="itemTile d-flex flex-row justify-content-between w-100">
            <h3
              style={{
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Cedente
            </h3>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between w-100 responsible">
          <StylizedInput
            label="Razão Social"
            inputName="corporateName"
            disabled
            related={business}
            index={index}
          />
          <StylizedInput
            label="Nome Fantasia"
            inputName="name"
            disabled
            related={business}
            index={index}
          />
          <StylizedInput
            label="CNPJ"
            inputName="countryIdentity"
            disabled
            related={business}
            index={index}
          />
        </div>

        {/* email, tel, cel... */}
        <div className="d-flex flex-row">
          <StylizedInput
            label="E-mail"
            inputName="email"
            disabled
            related={business}
            index={index}
          />
          <StylizedInput
            label="Telefone"
            inputName="officePhone"
            disabled
            related={business}
            index={index}
            format={formatTelefone}
          />
          <StylizedInput
            label="Cliente desde"
            inputName="registerDate"
            disabled
            related={{ registerDate: formatDate(business.registerDate) }}
            index={index}
          />
        </div>
      </div>
    );
  };

  const Iframe = ({ source }) => {
    if (!source) {
      return <div>Loading...</div>;
    }

    const src = source;
    return (
      <iframe
        title="Reputação do Cedente"
        height={800}
        src={src}
        frameBorder={0}
        style={{
          ...(isOnModalVitrine
            ? {
                width: "100%",
                boxShadow:
                  "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                borderRadius: 10,
                marginTop: 20,
              }
            : {
                width: "95%",
                marginLeft: 37,
                marginTop: 20,
              }),
        }}
      />
    );
  };

  return (
    <div className="w-100 relacionados">
      <RelacionadoComponent isLast index={0} />
      {metabaseEmbedURL !== "" && <Iframe source={metabaseEmbedURL} />}
    </div>
  );
};

export default Reputacao;
