import { Box, Typography, useMediaQuery } from "@material-ui/core";
import BoltIcon from "pages/Leilao/icons/Bolt";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { getDadosEmail } from "services/business";
import { getLasBuyer } from "services/invoice";
import {
  emailOfertaCoberta,
  emailOfertaRecebida,
  emailOfertaRegistrada,
} from "services/sendgrid";
import {
  errorMessageSwal,
  loadingSwal,
  successMessageSwal,
} from "services/SwalService";
import OfferModal from "../components/OfferModal";
import AxiosInstance from "../Requester";
import EventBus from "../services/EventBus";
import { useAuth } from "./auth";
import { DialogContext } from "./dialog";
import AxiosInterceptor from "utils/axios-interceptor";

export const ShowcaseContext = createContext({
  selectedTitle: null,
  openModalOffer: (title) => undefined,
  consultCet: (payload) => undefined,
  getComissionNogord: (title) => undefined,
  sendOffer: (title, nogordComission) => undefined,
});

export default function ShowcaseProvider({ children }) {
  const [selectedTitle, setSelectedTitle] = useState(null);
  const { toggleDialog } = useContext(DialogContext);
  const { user } = useAuth();
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const axiosInstance = AxiosInterceptor();

  const consultCet = useCallback(
    (payload) =>
      AxiosInstance.post("/bid/calculo/cetabdicado", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("bearer")}`,
        },
      })
        .then((response) => response)
        .catch((err) => {
          console.error(err);
          return null;
        }),
    []
  );

  const getComissionNogord = useCallback(
    (titulo) => {
      const sessionData = JSON.parse(localStorage.getItem("@App:user"));

      const payload = {
        segmentacao: titulo.business.segmentacao,
        prazoMedio: titulo.invoice.bidWeightedAverageTerm,
        origin_type_buyer: user.originType,
        origin_type_seller: titulo.business.originType,
        primeiroVenc: titulo.invoice.bidDate,
        partnerID: titulo.business.partnerId,
        buyerID: sessionData.businessId,
        invoiceID: titulo.invoice.invoiceId,
        tipoLiquidacao: titulo.invoice.liquidationTypeName,
        parcelas: titulo.invoice.maturities,
        Tipo_mercado_titulo: titulo.invoice.marketTypeName,
        sellerID: titulo.invoice.businessId,
        valor: titulo.invoice.maturitiesValue,
        numeroDC: titulo.invoice.number,
        tipoDC: titulo.invoice.typeName,
        rating: titulo.invoice.riskId,
        cetAtual: titulo.invoice.bidNetFactor, // r + diferenca,
        typeID: titulo.invoice.typeId,
      };

      return axiosInstance
        .post(
          `${process.env.REACT_APP_DECISION_API}/api/v1/le/execute/8bd478d4-d5da-4339-b41d-11892f622bf4`,
          payload,
          {
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
              Authorization: process.env.REACT_APP_DECISION_API_TOKEN,
            },
          }
        )
        .then((response) => response?.data?.result?.value)
        .catch((err) => {
          console.error(err);
          return null;
        });
    },
    [user]
  );

  const openModalOffer = useCallback(
    (title, position = 0, tab, activeComponent) => {
      const sessionData = JSON.parse(localStorage.getItem("@App:user"));
      const { businessId } = sessionData;
      const header = function () {
        return (
          <Box className="container-header-modal-offer" alignItems="center">
            <Typography
              variant="h1"
              style={{ padding: 0, fontFamily: "Calibri", fontWeight: "bold" }}
            >
              {title?.business?.name} ID: {title?.invoice?.id}
            </Typography>
            {title?.invoice?.bidBusinessId === businessId && (
              <div className="modal-dar-oferta--badge">
                <BoltIcon color="#ffffff" size={17} />
                <span>Você está ganhando</span>
              </div>
            )}
            {title?.invoice?.marketTypeId === 4 && (
              <div className="modal-dar-oferta--badge modal-dar-oferta--badge--green">
                {title?.invoice?.marketTypeName}
              </div>
            )}
            {title?.invoice?.marketTypeId === 2 && (
              <div className="modal-dar-oferta--badge modal-dar-oferta--badge--green">
                {title?.invoice?.marketTypeName}
              </div>
            )}
          </Box>
        );
      };
      toggleDialog(
        true,
        header(),
        <OfferModal
          tab={tab}
          selectedTitle={title}
          consultCet={consultCet}
          getComissionNogord={getComissionNogord}
          sendOffer={sendOffer}
          buyTitleAbdicated={buyTitleAbdicated}
          activeComponent={activeComponent}
          position={position}
        />,
        true,
        "xl"
      );
    },
    [largeScreen]
  );

  const sendOffer = useCallback(async (title, position = 0) => {
    const sessionData = JSON.parse(localStorage.getItem("@App:user"));
    const lastBuyer = await getLasBuyer(title.invoice.invoiceId);

    loadingSwal("Registrando lance", "Estamos registrando o seu lance");
    const axiosInstance = AxiosInterceptor();

    AxiosInstance.post(
      `${process.env.REACT_APP_NODE_API}/invoice/lance`,
      {
        invoiceId: title?.invoice?.invoiceId,
        businessId: sessionData.businessId,
        novoFactor: title?.invoice?.newBidFactor,
        comissaoNogord: title?.invoice?.comissionPct,
        userId: user.userId,
        userName: sessionData.name,
        ofertaAtual: title?.invoice?.bidNetFactorOffer,
        ranking: title?.invoice?.ranking,
        position: position,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("bearer")}`,
        },
      }
    )
      .then(async (res) => {
        const { retorno, mensagem } = res.data;
        if (retorno === 1) {
          const sellerData = await getDadosEmail(
            title.business.businessId,
            title.invoice.userId
          );

          const buyerData = await getDadosEmail(
            sessionData.businessId,
            user.userId
          );

          if (
            lastBuyer.length > 0 &&
            sessionData.businessId != lastBuyer[0].BUYER_ID
          ) {
            const payloadOfertaCoberta = {
              empresa: lastBuyer[0].ORIGIN_TYPE_SHORT,
              publico: 2,
              email_destinatario: lastBuyer[0].EMAIL,
              nome_cedente: sellerData[0].businessName,
              nome_financiador: lastBuyer[0].NAME,
              numeroNF: title.invoice.id,
              data_registro_titulo: title.invoice.registerDate,
              valor_parcelas: title.invoice.maturitiesValue,
              // user_email: lastBuyer[0].USER_MAIL
            };

            emailOfertaCoberta(payloadOfertaCoberta).then().catch();
          }

          const payloadOfertaRegistrada = {
            empresa: buyerData[0].origemWL,
            publico: 2,
            email_destinatario: buyerData[0].businessEmail,
            nome_cedente: sellerData[0].businessName,
            nome_financiador: buyerData[0].businessName,
            numeroNF: title.invoice.id,
            CET: title.invoice.bidNetFactorOffer.toFixed(3),
            data_registro_titulo: title.invoice.registerDate,
            valor_parcelas: title.invoice.maturitiesValue,
            user_email: buyerData[0].userEmail,
          };

          emailOfertaRegistrada(payloadOfertaRegistrada).then().catch();

          const payloadOfertaRecebida = {
            empresa: sellerData[0].origemWL,
            publico: 1,
            email_destinatario: sellerData[0].businessEmail,
            nome_cedente: sellerData[0].businessName,
            nome_financiador: buyerData[0].businessName,
            numeroNF: title.invoice.id,
            CET: title.invoice.bidNetFactorOffer.toFixed(3),
            data_registro_titulo: title.invoice.registerDate,
            valor_parcelas: title.invoice.maturitiesValue,
            user_email: sellerData[0].userEmail,
          };

          emailOfertaRecebida(payloadOfertaRecebida).then().catch();
          EventBus.dispatch("reload-vitrine");
          successMessageSwal("Lance registrado com sucesso!");

          return toggleDialog(false);
        }

        return errorMessageSwal(
          mensagem ??
            "Tivemos um problema ao tentar registrar o seu lance. Tente novamente."
        );
      })
      .catch((err) => {
        errorMessageSwal(
          "Erro ao registrar lance",
          "Tivemos um problema ao registrar o seu lance. Tente novamente."
        );
      })
      .finally();
  }, []);

  const buyTitleAbdicated = useCallback(
    async (title) => {
      loadingSwal(
        "Arrematando título",
        "Aguarde um momento. Estamos preparando o seu arremate"
      );
      const sessionData = JSON.parse(localStorage.getItem("@App:user"));
      const axiosInstance = AxiosInterceptor();

      AxiosInstance.post(
        `${process.env.REACT_APP_NODE_API}/invoice/arrematar`,
        {
          invoiceId: title?.invoice?.invoiceId,
          businessId: sessionData.businessId,
          novoFactor: title?.invoice?.newBidFactor,
          comissaoNogord: title?.invoice?.comissionPct,
          userId: user.userId,
          userName: sessionData.name,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("bearer")}`,
          },
        }
      )
        .then(async (res) => {
          const { mensagem } = res.data;

          if (!mensagem) {
            const sellerData = await getDadosEmail(
              title.business.businessId,
              title.invoice.userId
            );

            const buyerData = await getDadosEmail(
              sessionData.businessId,
              user.userId
            );

            const payloadOfertaRegistrada = {
              empresa: buyerData[0].origemWL,
              publico: 2,
              email_destinatario: buyerData[0].businessEmail,
              nome_cedente: sellerData[0].businessName,
              nome_financiador: buyerData[0].businessName,
              numeroNF: title.invoice.id,
              CET: title.invoice.bidNetFactor.toFixed(3),
              data_registro_titulo: title.invoice.registerDate,
              valor_parcelas: title.invoice.maturitiesValue,
            };
            const payloadOfertaRegistradaUsr = {
              empresa: buyerData[0].origemWL,
              publico: 2,
              email_destinatario: buyerData[0].userEmail,
              nome_cedente: sellerData[0].businessName,
              nome_financiador: buyerData[0].businessName,
              numeroNF: title.invoice.id,
              CET: title.invoice.bidNetFactor.toFixed(3),
              data_registro_titulo: title.invoice.registerDate,
              valor_parcelas: title.invoice.maturitiesValue,
            };

            emailOfertaRegistrada(payloadOfertaRegistrada).then().catch();
            emailOfertaRegistrada(payloadOfertaRegistradaUsr).then().catch();
            EventBus.dispatch("reload-vitrine");
            successMessageSwal("Título arrematado com sucesso!");

            return toggleDialog(false);
          }

          return errorMessageSwal(
            mensagem ?? "Tivemos um problema ao arrematar o título."
          );
        })
        .catch((err) => {
          console.error(err);

          errorMessageSwal("Tivemos um problema ao arrematar o título");
        });
    },
    [user]
  );

  const value = useMemo(
    () => ({
      selectedTitle,
      setSelectedTitle,
      openModalOffer,
      consultCet,
      sendOffer,
    }),
    [selectedTitle]
  );

  return (
    <ShowcaseContext.Provider value={value}>
      {children}
    </ShowcaseContext.Provider>
  );
}
