/* eslint eqeqeq: 0 */
import React, { useEffect, useState } from 'react';

import { makeStyles, TextField } from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles({
  '@global': {
    '.MuiInputBase-adornedEnd': {
      border: 'none !important',
    },
    '.MuiAutocomplete-option': {
      fontSize: '.8em !important',
      fontFamily: 'Poppins !important',
    },
    '.MuiAutocomplete-popupIndicator': {
      display: 'none',
    },
    '.MuiAutocomplete-listbox': {
      height: '128px !important',
      float: 'bottom !important',
    },
  },
});

export default function AutoComplete({ items, setItem, business }) {
  useStyles();
  const [filteredItems, setFilteredItems] = useState(undefined);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (business !== undefined && items !== undefined) {
      if (business.partnerId !== null) {
        const partner = items.find(
          (partner) => partner.businessId === business.partnerId
        );

        if (!partner) return;

        setSelectedItem(partner);
        const filteredName = items.sort((a, b) => {
          if (a.corporateName > b.corporateName) {
            return 1;
          }
          if (a.corporateName < b.corporateName) {
            return -1;
          }
          return 0;
        });
        setFilteredItems(filteredName);
      } else {
        const filteredName = items.sort((a, b) => {
          if (a.corporateName > b.corporateName) {
            return 1;
          }
          if (a.corporateName < b.corporateName) {
            return -1;
          }
          return 0;
        });
        setFilteredItems(filteredName);
      }
    }
  }, [business, items]);

  const filterOptions = (T, F) => {
    const isNumber = (char) =>
      char != ' ' && char != '' && !Number.isNaN(Number(char));
    let cnpj = '';
    // for (const char of F.inputValue) cnpj += isNumber(char) ? char : '';
    for (let i = 0; i < F.inputValue.length; i++) {
      cnpj = cnpj + isNumber(F.inputValue[i]) ? F.inputValue[i] : '';
    }
    // F.inputValue = cnpj;

    const filtered = createFilterOptions({
      limit: 50,
      matchFrom: 'any',
      stringify: (option) =>
        `${option?.corporateName} - ${option?.countryIdentity}`,
    })(T, F);

    if (
      (filtered.length === 0 && filteredItems.length !== 0) ||
      (filtered.length !== 0 && filteredItems.length === 0)
    ) {
      // const filteredName = filtered.sort((a, b) => {
      //   if (a.corporateName > b.corporateName) {
      //     return 1;
      //   }
      //   if (a.corporateName < b.corporateName) {
      //     return -1;
      //   }
      //   return 0;
      // });
      // setFilteredItems(filteredName);
    } else if (
      filtered.length !== 0 &&
      filteredItems.length !== 0 &&
      filtered[0]?.countryIdentity != filteredItems[0]?.countryIdentity
    ) {
      // const filteredName = filtered.sort((a, b) => {
      //   if (a.corporateName > b.corporateName) {
      //     return 1;
      //   }
      //   if (a.corporateName < b.corporateName) {
      //     return -1;
      //   }
      //   return 0;
      // });
      // setFilteredItems(filteredName);
    }
    return filtered;
  };

  const handleChange = (value) => {
    setItem(value);
    setSelectedItem(value);
  };

  return (
    <>
      <Autocomplete
        id="combo-box"
        filterOptions={filterOptions}
        options={items}
        getOptionLabel={(option) =>
          `${option?.corporateName} - ${option?.countryIdentity}`
        }
        value={selectedItem}
        onChange={(e, value) => handleChange(value)}
        noOptionsText=""
        disabled={items === undefined}
        classes={{ noOptions: 'd-none' }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              items === undefined ? 'Carregando parceiros...' : 'Parceiro*'
            }
            variant="outlined"
            fullWidth
            placeholder={
              items === undefined
                ? 'Carregando parceiros'
                : 'Insira a razão social do parceiro'
            }
          />
        )}
      />
    </>
  );
}
