export const headCells = [
  {
    id: 'edit',
    label: 'Editar',
    minWidth: 50,
    maxWidth: 50,
    backgroundColor: '#fafafa',
  },
  {
    id: 'originTypeShort',
    label: 'Sigla',
    minWidth: 100,
    maxWidth: 100,
    backgroundColor: '#fafafa',
  },
  {
    id: 'originTypeName',
    label: 'Nome',
    minWidth: 130,
    maxWidth: 130,
    backgroundColor: '#fafafa',
  },
  {
    id: 'hostname',
    label: 'Site',
    minWidth: 130,
    maxWidth: 130,
    backgroundColor: '#fafafa',
  },
];
