import React from 'react';

export default function User({ color = '#A0A0A0', size = 40 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 31.995 31.995"
    >
      <path
        id="user"
        d="M29.457,20.31a14.986,14.986,0,0,0-3.822-2.393A1.25,1.25,0,0,0,24.62,20.2a12.5,12.5,0,0,1,3.188,1.986A4.984,4.984,0,0,1,29.5,25.934v2.312a1.251,1.251,0,0,1-1.25,1.25H3.749a1.251,1.251,0,0,1-1.25-1.25V25.934a4.984,4.984,0,0,1,1.688-3.746C5.451,21.078,9.13,18.5,16,18.5a9.259,9.259,0,1,0-5.633-1.919A17.375,17.375,0,0,0,2.538,20.31,7.483,7.483,0,0,0,0,25.934v2.312A3.754,3.754,0,0,0,3.749,32h24.5A3.754,3.754,0,0,0,32,28.246V25.934A7.483,7.483,0,0,0,29.457,20.31ZM9.249,9.249A6.749,6.749,0,1,1,16,16,6.757,6.757,0,0,1,9.249,9.249Zm0,0"
        fill={color}
      />
    </svg>
  );
}
