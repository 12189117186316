import React, { useState, useEffect } from "react";

import {
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  Snackbar,
  Select,
  InputLabel,
  MenuItem,
  FormGroup,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import xml2js from "xml2js";

import { ExampleWrapperSimple } from "layout-components";

import { ConfirmButton } from "components/Button";

import getDaysToGo from "utils/diffDays";
import formatCep from "utils/formatCEP";
import formatCnpj from "utils/formatCNPJ";
import formatCpf from "utils/formatCPF";
import formatReal, { removeRealFormat } from "utils/formatReal";
import formatTelefone from "utils/formatTelefone";
import validateCep from "utils/validateCEP";

import { get } from "services/cep";

import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";

import { useModal } from "hooks/useModal";

import { estados } from "../estados";
import UploadButton from "../UploadButton";
import VencList from "../VencList";
import AxiosInterceptor from "utils/axios-interceptor";

export default (props) => {
  const { blank, title, agenda, getAgendas, initiposacado, edit, handleClose } =
    props;

  const { openModal, closeModal } = useModal();
  const { toast } = useToast();
  const { user } = useAuth();

  const [isGeneratingDocument, setIsGeneratingDocument] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const [cepatual, setCepAtual] = useState("000");
  const [fileUploadState, setFileUploadState] = useState([]);
  const [vencList, setVencList] = useState([
    {
      index: Math.random(),
      descricao: "",
      dtvencimento: "",
      valor: "",
      dias: "1",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [fileUploadData, setFileUploadData] = useState(null);
  const [uf, setUf] = useState("AC");
  const [formAdd, setValues] = useState({
    businessId: localStorage.getItem("businessId"),
    username: localStorage.getItem("name"),
    tiponota: "Serviços",
  });

  const [isErrorDocSacado, setIsErrorDocSacado] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const tiposacados = { fisica: "Pessoa Física", juridica: "Pessoa Jurídica" };
  const [tiposacado, SetTipoSacado] = useState(initiposacado);
  const [pessoa, SetPessoa] = useState({ tipo: "", nome: "", doc: "" });
  const typenames = [
    { id: 1, type_name: "Serviços" },
    { id: 2, type_name: "Produtos" },
    { id: 3, type_name: "Precatório" },
    { id: 6, type_name: "Transporte (CTe)" },
  ];
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });

  useEffect(() => {
    if (!edit) return;
    if (!agenda) return;
    if (!agenda.agenda_id) return;

    // Armazenando Dados
    setValues({
      ...formAdd,
      nfjson: agenda.xmlScheduling.xml,
      tiponota: agenda.type_name,
      numeronota: agenda.number,
      emissaonota: agenda.issue_date,
      valornota: formatReal(agenda.invoice_value.toFixed(2)),
      tiposacado: agenda.drawee.person_type_name,
      docsacado: agenda.drawee.country_identity,
      nomesacado: agenda.drawee.name || agenda.drawee.corporate_name,
      telefonesacado: agenda.drawee.office_phone,
      emailsacado: agenda.drawee.email,
      cepsacado: agenda.drawee.address_zip_code,
      enderecosacado: agenda.drawee.address_street,
      numerosacado: agenda.drawee.address_number,
      complementosacado: agenda.drawee.address_complement,
      bairrosacado: agenda.drawee.address_district,
      cidadesacado: agenda.drawee.address_city,
      ufsacado: agenda.drawee.address_State,
      nomecontato: agenda.drawee.contact_name,
      emailcontato: agenda.drawee.contact_email,
      telcontato: agenda.drawee.mobile_phone,
      cargocontato: agenda.drawee.contact_role,
      chavenota: agenda.invoice_key,
      serienota: agenda.invoice_series,
      cargocontato: agenda.drawee.contact_role,
    });

    SetTipoSacado(agenda.drawee.person_type_name);
    setSelectedDate(agenda.issue_date);

    if (!agenda.maturity) return;
    if (agenda.maturity.length > 0) {
      const parsedVencimentos = agenda.maturity.map((maturity) => ({
        ...maturity,
        value: formatReal(maturity.value.toFixed(2)),
        valor: formatReal(maturity.value.toFixed(2)),
        dt_vencimento: maturity.maturity_date,
      }));
      setVencList(parsedVencimentos);
    }
  }, [edit, agenda]);

  useEffect(() => {
    if (isGeneratingDocument == false) closeModal();

    if (isGeneratingDocument == true) openModal("loading");
  }, [isGeneratingDocument]);

  async function lerArquivo() {
    try {
      const nData = new FormData();
      let fileblob = new Blob();
      const valuesFromFile = Object.values(fileUploadData);
      fileblob = new Blob([valuesFromFile[0]], { type: "text/xml" });
      nData.append("files", fileblob);
      const readedFile = await pFileReader(fileblob);

      let xml = readedFile.target.result;
      xml = xml.replace("\r\n", "");
      const parser = new xml2js.Parser({ explicitArray: false });
      let successfulRead = true;
      parser.parseString(xml, async (error, result) => {
        if (!result) {
          successfulRead = false;
          return;
        }

        if (result.nfeProc) {
          if (result.nfeProc.NFe.infNFe.dest.CNPJ) {
            setValues({
              ...formAdd,
              nfjson: result.nfeProc.NFe,
              tiponota: "Produtos",
              numeronota: result.nfeProc.NFe.infNFe.ide.nNF,
              chavenota: result.nfeProc.NFe.infNFe.$.Id,
              emissaonota: new Date(result.nfeProc.NFe.infNFe.ide.dhEmi),
              valornota: formatReal(
                parseFloat(result.nfeProc.NFe.infNFe.total.ICMSTot.vNF).toFixed(
                  2
                )
              ),
              tiposacado: "Pessoa Jurídica",
              docsacado: formatCnpj(result.nfeProc.NFe.infNFe.dest.CNPJ),
              nomesacado: result.nfeProc.NFe.infNFe.dest.xNome,
              telefonesacado: formatTelefone(
                result.nfeProc.NFe.infNFe.dest.enderDest.fone
              ),
              emailsacado: result.nfeProc.NFe.infNFe.dest.email,
              cepsacado: formatCep(
                result.nfeProc.NFe.infNFe.dest.enderDest.CEP
              ),
              enderecosacado: result.nfeProc.NFe.infNFe.dest.enderDest.xLgr,
              numerosacado: result.nfeProc.NFe.infNFe.dest.enderDest.nro,
              complementosacado: result.nfeProc.NFe.infNFe.dest.enderDest.xCpl,
              bairrosacado: result.nfeProc.NFe.infNFe.dest.enderDest.xBairro,
              cidadesacado: result.nfeProc.NFe.infNFe.dest.enderDest.xMun,
              ufsacado: result.nfeProc.NFe.infNFe.dest.enderDest.UF,
              nomecontato: result.nfeProc.NFe.infNFe.dest.xNome,
              emailcontato: result.nfeProc.NFe.infNFe.dest.email,
              telcontato: result.nfeProc.NFe.infNFe.dest.enderDest.fone,
              chavenota: result.nfeProc.protNFe.infProt.chNFe,
              serienota: result.nfeProc.NFe.infNFe.ide.serie,
            });
            SetTipoSacado(tiposacados.juridica);
            if (result.nfeProc.NFe.infNFe.cobr) {
              try {
                // const dups = result.nfeProc.NFe.infNFe.cobr?.dup?.dupItem;
                const dups = result.nfeProc.NFe.infNFe.cobr?.dup;
                const vencimentos = dups.map((dup) => ({
                  index: Math.random(),
                  descricao: dup.nDup,
                  dtvencimento: new Date(dup.dVenc)
                    .toISOString()
                    .replace("Z", "")
                    .replace("T", " ")
                    .replace("(?<=.)[^.]*$", ""),
                  maturity_date: new Date(dup.dVenc)
                    .toISOString()
                    .replace("Z", "")
                    .replace("T", " ")
                    .replace("(?<=.)[^.]*$", ""),
                  value: formatReal(parseFloat(dup.vDup).toFixed(2)),
                  valor: formatReal(parseFloat(dup.vDup).toFixed(2)),
                  dias: getDaysToGo(dup.dVenc),
                }));
                setVencList(vencimentos);
              } catch (error) {
                toast.error(
                  "ATENÇÃO: Não se esqueça de configurar os vencimentos da sua Nota Fiscal."
                );
              }
            } else {
              setVencList([
                {
                  index: Math.random(),
                  descricao: "",
                  dtvencimento: new Date(
                    result.nfeProc.protNFe.infProt.dhRecbto
                  )
                    .toISOString()
                    .replace("Z", "")
                    .replace("T", " ")
                    .replace("(?<=.)[^.]*$", ""),
                  maturity_date: new Date(
                    result.nfeProc.protNFe.infProt.dhRecbto
                  )
                    .toISOString()
                    .replace("Z", "")
                    .replace("T", " ")
                    .replace("(?<=.)[^.]*$", ""),
                  value: formatReal(
                    parseFloat(
                      result.nfeProc.NFe.infNFe.total.ICMSTot.vNF
                    ).toFixed(2)
                  ),
                  valor: formatReal(
                    parseFloat(
                      result.nfeProc.NFe.infNFe.total.ICMSTot.vNF
                    ).toFixed(2)
                  ),
                  dias: getDaysToGo(result.nfeProc.protNFe.infProt.dhRecbto),
                },
              ]);
            }
          } else {
            setValues({
              ...formAdd,
              nfjson: result.nfeProc.NFe,
              numeronota: result.nfeProc.NFe.infNFe.ide.nNF,
              tiponota: "Produtos",
              chavenota: result.nfeProc.NFe.infNFe.$.Id,
              emissaonota: new Date(result.nfeProc.NFe.infNFe.ide.dhEmi),
              valornota: formatReal(
                parseFloat(result.nfeProc.NFe.infNFe.total.ICMSTot.vNF).toFixed(
                  2
                )
              ),
              tiposacado: "Pessoa Física",
              docsacado: formatCpf(result.nfeProc.NFe.infNFe.dest.CPF),
              nomesacado: result.nfeProc.NFe.infNFe.dest.xNome,
              telefonesacado: formatTelefone(
                result.nfeProc.NFe.infNFe.dest.enderDest.fone
              ),
              emailsacado: result.nfeProc.NFe.infNFe.dest.email,
              cepsacado: formatCep(
                result.nfeProc.NFe.infNFe.dest.enderDest.CEP
              ),
              enderecosacado: result.nfeProc.NFe.infNFe.dest.enderDest.xLgr,
              numerosacado: result.nfeProc.NFe.infNFe.dest.enderDest.nro,
              complementosacado: result.nfeProc.NFe.infNFe.dest.enderDest.xCpl,
              bairrosacado: result.nfeProc.NFe.infNFe.dest.enderDest.xBairro,
              cidadesacado: result.nfeProc.NFe.infNFe.dest.enderDest.xMun,
              ufsacado: result.nfeProc.NFe.infNFe.dest.enderDest.UF,
              nomecontato: result.nfeProc.NFe.infNFe.dest.xNome,
              emailcontato: result.nfeProc.NFe.infNFe.dest.email,
              telcontato: result.nfeProc.NFe.infNFe.dest.enderDest.fone,
              chavenota: result.nfeProc.protNFe.infProt.chNFe,
              serienota: result.nfeProc.NFe.infNFe.ide.serie,
            });
            SetTipoSacado(tiposacados.fisica);
            if (result.nfeProc.NFe.infNFe.cobr) {
              try {
                // const dups = result.nfeProc.NFe.infNFe.cobr?.dup?.dupItem;
                const dups = result.nfeProc.NFe.infNFe.cobr?.dup;
                const vencimentos = dups.map((dup) => ({
                  index: Math.random(),
                  descricao: dup.nDup,
                  dtvencimento: new Date(dup.dVenc),
                  maturity_date: new Date(dup.dVenc),
                  value: formatReal(parseFloat(dup.vDup).toFixed(2)),
                  valor: formatReal(parseFloat(dup.vDup).toFixed(2)),
                  dias: getDaysToGo(dup.dVenc),
                }));
                setVencList(vencimentos);
              } catch (error) {
                toast.error(
                  "ATENÇÃO: Não se esqueça de configurar os vencimentos da sua Nota Fiscal."
                );
              }
            } else {
              setVencList([
                {
                  index: Math.random(),
                  descricao: "",
                  dtvencimento: "",
                  value: formatReal(
                    parseFloat(
                      result.nfeProc.NFe.infNFe.total.ICMSTot.vNF
                    ).toFixed(2)
                  ),
                  valor: formatReal(
                    parseFloat(
                      result.nfeProc.NFe.infNFe.total.ICMSTot.vNF
                    ).toFixed(2)
                  ),
                  dias: "1",
                },
              ]);
            }
          }
        } else {
          const axiosInstance = AxiosInterceptor();
          const cidade = await axiosInstance.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/municipios/${result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse.TomadorServico.Endereco.CodigoMunicipio}`
          );
          if (
            result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
              .TomadorServico.IdentificacaoTomador.CpfCnpj.Cnpj
          ) {
            setValues({
              ...formAdd,
              nfjson: result.ConsultarNfseResposta.ListaNfse.CompNfse.InfNfse,
              tiponota: "Serviços",
              numeronota:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .Numero,
              emissaonota:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .DataEmissao,
              valornota: formatReal(
                parseFloat(
                  result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                    .Servico.Valores.ValorLiquidoNfse
                ).toFixed(2)
              ),
              tiposacado: "Pessoa Jurídica",
              docsacado: formatCnpj(
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.IdentificacaoTomador.CpfCnpj.Cnpj
              ),
              nomesacado:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.RazaoSocial,
              telefonesacado: formatTelefone(
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Contato.Telefone
              ),
              emailsacado:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Contato.Email,
              cepsacado: formatCep(
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Endereco.Cep
              ),
              enderecosacado:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Endereco.Endereco,
              numerosacado:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Endereco.Numero,
              complementosacado:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Endereco.Complemento,
              bairrosacado:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Endereco.Bairro,
              cidadesacado: cidade.data.nome,
              ufsacado:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Endereco.Uf,
              nomecontato:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.RazaoSocial,
              emailcontato:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Contato.Email,
              telcontato:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Contato.Telefone,
              // cargocontato: agenda.drawee.contact_role,
            });
            SetTipoSacado(tiposacados.juridica);
            setVencList([
              {
                index: Math.random(),
                descricao: "",
                dtvencimento: "",
                value: formatReal(
                  parseFloat(
                    result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                      .Servico.Valores.ValorLiquidoNfse
                  ).toFixed(2)
                ),
                valor: formatReal(
                  parseFloat(
                    result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                      .Servico.Valores.ValorLiquidoNfse
                  ).toFixed(2)
                ),
                dias: "1",
              },
            ]);
          } else {
            const axiosInstance = AxiosInterceptor();
            const cidade = await axiosInstance.get(
              `https://servicodados.ibge.gov.br/api/v1/localidades/distritos/${result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse.TomadorServico.Endereco.CodigoMunicipio}`
            );
            SetTipoSacado(tiposacados.fisica);
            setValues({
              ...formAdd,
              nfjson: result.ConsultarNfseResposta.ListaNfse.CompNfse.InfNfse,
              numeronota:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .Numero,
              tiponota: "Serviços",
              emissaonota:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .DataEmissao,
              valornota: formatReal(
                parseFloat(
                  result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                    .Servico.Valores.ValorLiquidoNfse
                ).toFixed(2)
              ),
              tiposacado: "Pessoa Física",
              docsacado: formatCpf(
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.IdentificacaoTomador.CpfCnpj.Cpf
              ),
              nomesacado:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.RazaoSocial,
              telefonesacado: formatTelefone(
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Contato.Telefone
              ),
              emailsacado:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Contato.Email,
              cepsacado: formatCep(
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Endereco.Cep
              ),
              enderecosacado:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Endereco.Endereco,
              numerosacado:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Endereco.Numero,
              complementosacado:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Endereco.Complemento,
              bairrosacado:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Endereco.Bairro,
              cidadesacado: cidade.data.nome,
              ufsacado:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Endereco.Uf,
              nomecontato:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.RazaoSocial,
              emailcontato:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Contato.Email,
              telcontato:
                result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                  .TomadorServico.Contato.Telefone,
              // cargocontato: agenda.drawee.contact_role,
            });
            SetTipoSacado(tiposacados.fisica);
            setVencList([
              {
                index: Math.random(),
                descricao: "",
                dtvencimento: "",
                value: formatReal(
                  parseFloat(
                    result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                      .Servico.Valores.ValorLiquidoNfse
                  ).toFixed(2)
                ),
                valor: formatReal(
                  parseFloat(
                    result.ConsultarNfseResposta.ListaNfse.CompNfse.Nfse.InfNfse
                      .Servico.Valores.ValorLiquidoNfse
                  ).toFixed(2)
                ),
                dias: "1",
              },
            ]);
          }
        }
      });
      if (successfulRead) toast.success("Dados importados com sucesso.");
      else throw new Error("XML inválido");
    } catch (error) {
      toast.error("Não foi possível importar os dados, preencha manualmente.");
    }
  }

  useEffect(() => {
    if (fileUploadData != null) {
      lerArquivo();
    }
  }, [fileUploadData]);

  useEffect(() => {
    if (isSending == true) toast.loading("Limpando formulário");

    if (isSending == false) toast.dismiss();
  }, [isSending]);

  function pFileReader(file) {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = resolve;
      fr.onerror = reject;
      fr.readAsText(file);
    });
  }

  const uploadDocuments = async () => {
    try {
      // VALIDANDO CAMPOS DO FORMULÁRIO
      if (!validarCampos()) throw new Error("Atenção aos campos obrigatórios");

      // VALIDANDO SOMA DOS VENCIMENTOS
      const somaVencimentos = getSomaVencimentos();
      if (somaVencimentos > +removeRealFormat(formAdd.valornota))
        return toast("Soma dos vencimentos inválida");

      if (!fileUploadData) {
        await handleSave();
        return;
      }

      // INICIALIZANDO OBJETOS/MÉTODOS NECESSÁRIOS
      const nData = new FormData();
      let fileblob = new Blob();
      const valuesFromFile = Object.values(fileUploadData);

      for await (const file of valuesFromFile) {
        setIsGeneratingDocument(true);
        nData.append("originTypeId", 77);
        nData.append("originTypeName", "Agenda");
        nData.append("documentTypeId", 31);
        nData.append("originId", localStorage.getItem("businessId"));
        nData.append("documentTypeName", "Nota Fiscal que gerou a operação");
        nData.append("name", formAdd.numeronota);
        nData.append("type", file.type);
        nData.append("size", file.size);
        nData.append("key", null);
        if (file.type == "application/pdf") {
          // MANIPULAND ARQUIVO
          fileblob = new Blob([file], { type: "application/octet-stream" });
          nData.append("files", fileblob, formAdd.numeronota);
          const axiosInstance = AxiosInterceptor();
          // ENVIANDO ARQUIVO PARA O BANCO
          const { data } = await axiosInstance.post(
            `${process.env.REACT_APP_UPLOAD_BACKEND_API}/files/upload/`,
            nData
          );

          // VALIDAÇÃO ARQUIVO GERADO NO BANCO
          if (data[0].originTypeId == 77) {
            setValues({ ...formAdd, filekey: data[0].key });
            toast.success(`O Arquivo ${data[0].name} foi enviado com êxito`);

            // ENVIANDO FORMULÁRIO
            await handleSubmit();
            setIsGeneratingDocument(false);
          } else {
            throw new Error(`O Arquivo ${data[0].name} não foi enviado`);
          }
        } else {
          // MANIPULANDO ARQUIVO
          fileblob = new Blob([file], { type: "text/xml" });
          nData.append("files", fileblob, formAdd.numeronota);
          const readedFile = await pFileReader(fileblob);

          let xml = readedFile.target.result;
          xml = xml.replace("\r\n", "");
          const axiosInstance = AxiosInterceptor();
          // ENVIANDO ARQUIVO PARA O BANCO
          const { data } = await axiosInstance.post(
            `${process.env.REACT_APP_UPLOAD_BACKEND_API}/files/upload/`,
            nData
          );

          // VALIDAÇÃO ARQUIVO GERADO NO BANCO
          if (data[0].originTypeId == 77) {
            const newData = {
              ...formAdd,
              filekey: data[0].key,
              nfjson: xml,
            };
            setValues(newData);
            toast.success(`O Arquivo ${data[0].name} foi enviado com êxito`);

            // ENVIANDO FORMULÁRIO
            await handleSubmit(newData);
            setIsGeneratingDocument(false);
          } else {
            throw new Error(`O Arquivo ${data[0].name} não foi enviado`);
          }
        }
      }
    } catch (error) {
      toast.error(error.message);
      setIsGeneratingDocument(false);
      setIsSending(false);
    } // TRATANDO ERROS
  };

  const handleChangeVencimento = (index, value) => {
    const setted = value;
    const novo = vencList[index];
    novo.dias = getDaysToGo(setted);
    novo.dtvencimento = setted;
    novo.maturity_date = value;
    const aux = vencList;
    aux[index] = novo;

    setVencList(aux);
    setValues({ ...formAdd, vencimentos: aux });
    setTimeout(() => {}, 200);
  };

  const changeVenclist = (idx, e) => {
    const novo = vencList[idx];
    const aux = vencList;
    aux[idx] = { ...novo, [e.target.name]: e.target.value };
    setVencList(aux);
    setValues({ ...formAdd, vencimentos: vencList });

    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleDateChange = (date) => {
    setValues({ ...formAdd, emissaonota: date });
    setSelectedDate(date);
  };

  const addNewVenc = () => {
    setVencList([
      ...vencList,
      {
        index: Math.random(),
        descricao: "",
        dtvencimento: new Date(),
        valor: "",
        dias: "1",
      },
    ]);
  };

  async function deleteVenc(index) {
    const selectedToDelete = vencList[index];

    if (selectedToDelete.maturity_id) {
      const axiosInstance = AxiosInterceptor();
      await toast.promise(
        axiosInstance.put(
          `${process.env.REACT_APP_NODE_API}/agenda/delete/${selectedToDelete.maturity_id}`
        ),
        {
          loading: "Removendo Vencimento...",
          success: "Vencimento Removido.",
          error: `Erro ao atualizar recebível.`,
        }
      );

      await getAgendas();
    }

    const removed = vencList.filter((list, i) => index !== i);
    setVencList(removed);
    setValues({ ...formAdd, vencimentos: removed });
    setTimeout(() => {}, 200);
  }

  const handlerChangeSelectUF = (e) => {
    setValues({ ...formAdd, ufsacado: e.target.value });
    setUf(e.target.value);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  useEffect(() => {
    if (!formAdd.cepsacado) return;
    if (formAdd.cepsacado.length < 9) return;
    if (validateCep(formAdd.cepsacado) && formAdd.cepsacado != cepatual) {
      buscarDadosCEP();
    }
  }, [formAdd.cepsacado]);

  async function buscarDadosCEP() {
    setIsLoading(true);
    const cep = formAdd.cepsacado;

    await get(cep)
      .then((response) => response.json())
      .then((response) => {
        formAdd.enderecosacado = response.logradouro || formAdd.enderecosacado;
        formAdd.cidadesacado = response.localidade || formAdd.cidadesacado;
        formAdd.bairrosacado = response.bairro || formAdd.bairrosacado;
        formAdd.ufsacado = response.uf || formAdd.ufsacado;
        setCepAtual(formAdd.cepsacado);
      });
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }

  const handlerChange = (e) => {
    setValues({ ...formAdd, [e.target.name]: e.target.value });
  };

  const validarCampos = () => {
    if (!formAdd.docsacado) {
      setIsErrorDocSacado(true);
      return false;
    }
    if (!formAdd.nomesacado) {
      setIsErrorDocSacado(true);
      return false;
    }

    return true;
  };

  function getSomaVencimentos() {
    let somaVencimentos = 0;
    for (const vencimento of vencList) {
      somaVencimentos += removeRealFormat(vencimento.valor);
    }

    return somaVencimentos.toFixed(2);
  }

  function venclistParser(vencList) {
    return vencList.map((vencimento) => ({
      ...vencimento,
      value: removeRealFormat(vencimento.valor).toFixed(2),
      valor: removeRealFormat(vencimento.valor).toFixed(2),
    }));
  }

  const handleSave = async () => {
    if (!validarCampos()) {
      return;
    }

    const parsedVencList = venclistParser(vencList);

    const newFormData = {
      ...formAdd,
      userId: user.userId,
      tiposacado,
      vencimentos: parsedVencList,
      tipoidnota: getTypeId(),
      valornota: removeRealFormat(formAdd.valornota).toFixed(2),
      emissaonota: selectedDate,
    };

    const somaVencimentos = getSomaVencimentos();

    if (somaVencimentos > newFormData.valornota)
      return toast("Soma dos vencimentos inválida");
    const axiosInstance = AxiosInterceptor();

    await toast.promise(
      axiosInstance.post(
        `${process.env.REACT_APP_NODE_API}/agenda`,
        newFormData
      ),
      {
        loading: "Gerando Recebível...",
        success: "Recebível gerado.",
        error: "Erro ao gerar recebível.",
      }
    );

    openModal("loading");
    await getAgendas();
    closeModal();
    window.location.reload();
  };

  const handleSubmitUpdate = async () => {
    // VALIDANDO CAMPOS NULOS
    if (!validarCampos()) {
      return;
    }

    const parsedVencList = venclistParser(vencList);

    const newFormData = {
      ...formAdd,
      userId: user.userId,
      tiposacado,
      vencimentos: parsedVencList,
      tipoidnota: getTypeId(),
      valornota: removeRealFormat(formAdd.valornota).toFixed(2),
    };

    // setValues(newFormData);

    // VALIDANDO SOMA DOS VENCIMENTOS
    const somaVencimentos = getSomaVencimentos();

    if (somaVencimentos > newFormData.valornota)
      return toast("Soma dos vencimentos inválida");

    if (!agenda.agenda_id) return;
    const axiosInstance = AxiosInterceptor();
    await toast.promise(
      axiosInstance.put(
        `${process.env.REACT_APP_NODE_API}/agenda/update/recebivel/${agenda.agenda_id}`,
        newFormData
      ),
      {
        loading: "Atualizando Recebível...",
        success: "Recebível Atualizado.",
        error: "Erro ao atualizar recebível.",
      }
    );

    openModal("loading");
    await getAgendas();
    closeModal();
    handleClose();
  };

  function getTypeId() {
    return typenames.find((tipo) => tipo.type_name == formAdd.tiponota).id;
  }

  const handleSubmit = async (newData) => {
    if (!validarCampos()) {
      return;
    }

    const parsedVenclist = venclistParser(vencList);
    const newFormData = {
      ...newData,
      userId: user.userId,
      vencimentos: parsedVenclist,
      tipoidnota: getTypeId(),
      valornota: removeRealFormat(formAdd.valornota),
    };
    // setValues(newFormData);
    const axiosInstance = AxiosInterceptor();
    await toast.promise(
      axiosInstance.post(
        `${process.env.REACT_APP_NODE_API}/agenda`,
        newFormData
      ),
      {
        loading: "Gerando Recebível...",
        success: "Recebível gerado.",
        error: "Erro ao gerar recebível.",
      }
    );

    setIsSending(true);
    setValues({
      businessId: localStorage.getItem("businessId"),
      username: localStorage.getItem("name"),
      tiponota: "Serviços",
    });

    setSelectedDate(new Date());
    setVencList([
      {
        index: Math.random(),
        descricao: "",
        dtvencimento: new Date(),
        valor: "",
        dias: "1",
      },
    ]);

    setFileUploadState([]);

    await getAgendas();
    setIsSending(false);
  };

  useEffect(() => {
    if (edit) return;
    if (blank) {
      setValues({});
    }
    if (tiposacado == tiposacados.fisica) {
      SetPessoa({ tipo: tiposacado, nome: "Nome", doc: "CPF" });
      formAdd.tiposacado = tiposacado;
    } else {
      SetPessoa({ tipo: tiposacado, nome: "Razão Social", doc: "CNPJ" });
      formAdd.tiposacado = tiposacado;
    }
    setValues({ ...agenda });
    setValues({ ...formAdd });
    if (agenda.agenda_id) {
      formAdd.nfjson = agenda.xmlScheduling.xml;
      formAdd.tiponota = agenda.type_name;
      formAdd.numeronota = agenda.number;
      formAdd.emissaonota = agenda.issue_date;
      formAdd.valornota = agenda.invoice_value;
      formAdd.tiposacado = agenda.drawee.person_type_name;
      formAdd.docsacado = agenda.drawee.country_identity;
      formAdd.nomesacado = agenda.drawee.name || agenda.drawee.corporate_name;
      formAdd.telefonesacado = agenda.drawee.office_phone;
      formAdd.emailsacado = agenda.drawee.email;
      formAdd.cepsacado = agenda.drawee.address_zip_code;
      formAdd.enderecosacado = agenda.drawee.address_street;
      formAdd.numerosacado = agenda.drawee.address_number;
      formAdd.complementosacado = agenda.drawee.address_complement;
      formAdd.bairrosacado = agenda.drawee.address_district;
      formAdd.cidadesacado = agenda.drawee.address_city;
      formAdd.ufsacado = agenda.drawee.address_state;
      formAdd.nomecontato = agenda.drawee.contact_name;
      formAdd.emailcontato = agenda.drawee.contact_email;
      formAdd.telcontato = agenda.drawee.mobile_phone;
      formAdd.cargocontato = agenda.drawee.contact_role;

      setVencList(agenda.maturity);
    }
  }, [agenda, tiposacado, vencList, fileUploadData, formAdd.nfjson]);

  return (
    <>
      {edit
        ? !agenda && <CircularProgress value={10} color="primary" />
        : isLoading && <LinearProgress value={55} color="primary" />}
      <div style={{ marginTop: "30px", marginLeft: "-65px", width: "105%" }}>
        <div className="searchForm" style={{ marginLeft: "35px" }}>
          <div className="containerTitle">
            <div className="itemTile">
              <h2 style={{ fontFamily: "Poppins" }}>
                {title || "Novo Recebível"}
              </h2>
            </div>
          </div>
          {snackbarState.open && (
            <>
              <Snackbar
                open={snackbarState.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  onClose={handleCloseSnackbar}
                  severity={snackbarState.severity}
                >
                  {snackbarState.message}
                </Alert>
              </Snackbar>
            </>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!edit && (
              <UploadButton
                fileUploadState={fileUploadState}
                setFileUploadData={setFileUploadData}
                setFileUploadState={setFileUploadState}
              />
            )}
          </div>

          <ExampleWrapperSimple sectionHeading="Nota Fiscal">
            <div className="item mb-3">
              <div className="containerDatePicker mb-3">
                <FormControl className="w-100">
                  <InputLabel id="tiponota-label">Tipo de Nota</InputLabel>
                  <Select
                    autoFocus
                    labelId="tiponota"
                    id="tiponota"
                    value={formAdd.tiponota || ""}
                    name="tiponota"
                    onChange={handlerChange}
                  >
                    <MenuItem value="-1" />
                    {typenames.map((tipo) => (
                      <MenuItem key={tipo.id} value={tipo.type_name}>
                        {tipo.type_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="containerDatePicker mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    className="mr-2 mb-0 text-field"
                    id="numeronota"
                    label="Número da Nota Fiscal"
                    name="numeronota"
                    value={formAdd.numeronota || ""}
                    onChange={handlerChange}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mb-3">
                <FormControl className="w-100">
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <KeyboardDatePicker
                      helperText=""
                      cancelLabel="Cancelar"
                      autoFocus
                      fullWidth
                      locale={ptBR}
                      className="ml-2 mr-2 mb-0 text-field"
                      id="emissaonota"
                      label="Emissão da Nota Fiscal"
                      format="dd/MM/yyyy"
                      value={formAdd.emissaonota || selectedDate}
                      onChange={handleDateChange}
                      DialogProps={{ disableBackdropClick: true }}
                      maxDate={new Date()}
                      variant="outlined"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </div>
              <div className="containerDatePicker mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    className="ml-2 mb-0 text-field"
                    id="valornota"
                    label="Valor da nota"
                    name="valornota"
                    value={formAdd.valornota || ""}
                    type="text"
                    onChange={(e) => {
                      e.target.value = formatReal(e.target.value, true);
                      handlerChange(e);
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
            </div>
            <div className="item" style={{ width: "75%" }}>
              <div
                style={{
                  flex: "2",
                  marginRight: 11,
                }}
              >
                <TextField
                  autoFocus
                  fullWidth
                  className=" mb-0 text-field"
                  id="chavenota"
                  label="Chave da Nota Fiscal"
                  name="chavenota"
                  value={formAdd.chavenota || ""}
                  onChange={handlerChange}
                  variant="outlined"
                />
              </div>
              <div style={{ flex: "1" }}>
                <FormControl className="w-100" style={{ paddingLeft: 13 }}>
                  <TextField
                    autoFocus
                    fullWidth
                    className=" mb-0 text-field"
                    id="serienota"
                    label="Série da Nota Fiscal"
                    name="serienota"
                    value={formAdd.serienota || ""}
                    onChange={handlerChange}
                    variant="outlined"
                  />
                </FormControl>
              </div>
            </div>
          </ExampleWrapperSimple>

          <ExampleWrapperSimple sectionHeading="Sacado">
            <div className="mr-5 mb-3">
              <FormGroup row style={{ marginLeft: "15px" }}>
                <FormControlLabel
                  control={
                    <Radio
                      checked={tiposacado == tiposacados.fisica}
                      onClick={() => SetTipoSacado(tiposacados.fisica)}
                      color="primary"
                      name="tiposacado"
                    />
                  }
                  label="Pessoa Física"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={tiposacado == tiposacados.juridica}
                      onClick={() => SetTipoSacado(tiposacados.juridica)}
                      color="primary"
                      name="tiposacado"
                    />
                  }
                  label="Pessoa Jurídica"
                />
              </FormGroup>
            </div>
            <div className="item">
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    className="ml-2 mr-2 mb-0 text-field"
                    id="docsacado"
                    label={pessoa.doc}
                    name="docsacado"
                    value={formAdd.docsacado || ""}
                    onChange={(e) => {
                      setIsErrorDocSacado(false);
                      if (pessoa.doc == "CPF") {
                        e.target.value = formatCpf(e.target.value);
                      } else {
                        e.target.value = formatCnpj(e.target.value);
                      }
                      handlerChange(e);
                    }}
                    variant="outlined"
                    required
                    error={isErrorDocSacado}
                    inputProps={
                      pessoa.doc == "CPF"
                        ? { maxLength: 14 }
                        : { maxLength: 18 }
                    }
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100 flex">
                  <TextField
                    autoFocus
                    fullWidth
                    className="ml-2 mr-2 mb-0 text-field"
                    id="nomesacado"
                    label={pessoa.nome}
                    name="nomesacado"
                    value={formAdd.nomesacado || ""}
                    onChange={(e) => {
                      setIsErrorDocSacado(false);
                      handlerChange(e);
                    }}
                    variant="outlined"
                    required
                    error={isErrorDocSacado}
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    className="ml-2 mr-2 mb-0 text-field"
                    id="telefone"
                    label="Telefone"
                    name="telefonesacado"
                    value={formAdd.telefonesacado || ""}
                    onChange={(e) => {
                      setIsErrorDocSacado(false);
                      e.target.value = formatTelefone(e.target.value);
                      handlerChange(e);
                    }}
                    variant="outlined"
                    inputProps={{ maxLength: 14 }}
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    className="ml-2 mr-2 mb-0 text-field"
                    id="email"
                    type="email"
                    label="Email"
                    name="emailsacado"
                    value={formAdd.emailsacado || ""}
                    onChange={(e) => {
                      setIsErrorDocSacado(false);
                      handlerChange(e);
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
            </div>
            <div className="item">
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    className="ml-2 mr-2 mb-0 text-field"
                    id="cep"
                    label="CEP"
                    name="cepsacado"
                    value={formAdd.cepsacado || ""}
                    onChange={(e) => {
                      setIsErrorDocSacado(false);
                      e.target.value = formatCep(e.target.value);
                      handlerChange(e);
                    }}
                    variant="outlined"
                    inputProps={{ maxLength: 9 }}
                  />
                </FormControl>
              </div>{" "}
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    className="ml-2 mr-2 mb-0 text-field"
                    id="endereco"
                    label="Endereço"
                    placeholder="Endereço"
                    name="enderecosacado"
                    value={formAdd.enderecosacado || ""}
                    onChange={(e) => {
                      setIsErrorDocSacado(false);
                      handlerChange(e);
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>{" "}
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    className="ml-2 mr-2 mb-0 text-field"
                    id="numero"
                    label="Número"
                    name="numerosacado"
                    value={formAdd.numerosacado || ""}
                    onChange={(e) => {
                      setIsErrorDocSacado(false);
                      handlerChange(e);
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    className="ml-2 mr-2 mb-0 text-field"
                    id="complemento"
                    label="Complemento"
                    name="complementosacado"
                    value={formAdd.complementosacado || ""}
                    onChange={(e) => {
                      setIsErrorDocSacado(false);
                      handlerChange(e);
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
            </div>
            <div className="item">
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    className="ml-2 mr-2 mb-0 text-field"
                    id="bairro"
                    label="Bairro"
                    name="bairrosacado"
                    value={formAdd.bairrosacado || ""}
                    onChange={(e) => {
                      setIsErrorDocSacado(false);
                      handlerChange(e);
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    autoFocus
                    fullWidth
                    className="ml-2 mr-2 mb-0 text-field"
                    id="cidade"
                    label="Cidade"
                    name="cidadesacado"
                    value={formAdd.cidadesacado || ""}
                    onChange={(e) => {
                      setIsErrorDocSacado(false);
                      handlerChange(e);
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <InputLabel id="uflabel" className="ml-2 mt-2">
                    UF
                  </InputLabel>
                  <Select
                    labelId="uflabel"
                    id="uf"
                    name="ufsacado"
                    value={formAdd.ufsacado || uf}
                    onChange={handlerChangeSelectUF}
                  >
                    <MenuItem value="0" />
                    {estados.map((element) => (
                      <MenuItem key={element.sigla} value={element.sigla}>
                        {element.sigla}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </ExampleWrapperSimple>

          <ExampleWrapperSimple sectionHeading="Contato para a confirmação de entrega">
            <div className="item">
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    fullWidth
                    className="ml-2 mr-2 mb-0 text-field"
                    id="nomecontato"
                    label="Nome do Contato"
                    name="nomecontato"
                    value={formAdd.nomecontato || ""}
                    onChange={(e) => {
                      setIsErrorDocSacado(false);
                      handlerChange(e);
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    fullWidth
                    className="ml-2 mr-2 mb-0 text-field"
                    id="telcontato"
                    label="Telefone do Contato"
                    name="telcontato"
                    value={formAdd.telcontato || ""}
                    onChange={(e) => {
                      setIsErrorDocSacado(false);
                      e.target.value = formatTelefone(e.target.value);
                      handlerChange(e);
                    }}
                    variant="outlined"
                    inputProps={{ maxLength: 14 }}
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    fullWidth
                    className="ml-2 mr-2 mb-0 text-field"
                    id="emailcontato"
                    label="Email do Contato"
                    name="emailcontato"
                    value={formAdd.emailcontato || ""}
                    onChange={(e) => {
                      setIsErrorDocSacado(false);
                      handlerChange(e);
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
              <div className="containerDatePicker mr-5 mb-3">
                <FormControl className="w-100">
                  <TextField
                    fullWidth
                    className="ml-2 mr-2 mb-0 text-field"
                    id="cargocontato"
                    label="Cargo do Contato"
                    name="cargocontato"
                    value={formAdd.cargocontato || ""}
                    onChange={(e) => {
                      setIsErrorDocSacado(false);
                      handlerChange(e);
                    }}
                    variant="outlined"
                  />
                </FormControl>
              </div>
            </div>
          </ExampleWrapperSimple>
          <ExampleWrapperSimple sectionHeading="Vencimentos">
            <VencList
              add={addNewVenc}
              del={(index) =>
                !edit
                  ? deleteVenc(index)
                  : openModal(
                      "warning",
                      "Atenção!",
                      "Tem certeza que deseja deletar este vencimento?",
                      deleteVenc,
                      index
                    )
              }
              edit
              handlerChange={changeVenclist}
              setVencList={setVencList}
              venclist={vencList}
              handleChangeVencimento={handleChangeVencimento}
            />
          </ExampleWrapperSimple>
          <ExampleWrapperSimple sectionHeading="">
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }}
            >
              <ConfirmButton
                onClick={() =>
                  edit ? handleSubmitUpdate() : uploadDocuments()
                }
              >
                {edit ? "Editar" : "Adicionar"}
              </ConfirmButton>
            </div>
          </ExampleWrapperSimple>
        </div>
      </div>
    </>
  );
};
