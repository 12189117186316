import React, { useCallback, useContext, useEffect } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import { DropzoneArea } from 'material-ui-dropzone';
import * as Yup from 'yup';

import * as moment from 'moment';

import { useAuth } from '../../contexts/auth';
import useExtension from '../../hooks/useExtension';
import { confirmationSwal } from '../../services/SwalService';
import ExtensionFormBuyer from '../ExtensionFormBuyer';
import { ExtensionContext } from '../ExtensionModal';
import { ReactIf, ReactThen } from '../ReactIf';
import TitleSection from '../TitleSection';

export default function ExtensionFormSeller({ extensionId = null }) {
  const { isCedente, isFinanciador, isAdmin } = useAuth();
  const {
    setFormExtension,
    maturity,
    invoiceId,
    disableButtons,
    buyerId,
    sellerId,
    sendSolicitation,
    updateSolicitation,
    extensions,
  } = useContext(ExtensionContext);

  const { extension, error, isLoading, isValidating } = useExtension(
    maturity?.maturityId,
    extensionId
  );

  const {
    values,
    errors,
    setFieldValue,
    isValid,
    handleSubmit,
    touched,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues: {
      reason: '',
      original_date: moment(maturity?.maturityDate).utc(0).format('DD/MM/YYYY'),
      seller_extension_date: null,
      deadline: '',
      files: [],
    },

    validationSchema: Yup.object().shape({
      reason: Yup.string().required('Não se esqueça do motivo'),
      seller_extension_date: Yup.mixed().required(
        'Informe uma data em que você acredita poder honrar o compromisso'
      ),
    }),

    onSubmit: async (val) => {
      const formData = new FormData();

      formData.set('maturity_id', maturity?.maturityId);
      formData.set('invoice_id', invoiceId);
      formData.set('status_id', '1');
      formData.set('seller_id', sellerId);
      formData.set('seller_reason', val.reason);
      formData.set('seller_extension_date', val.seller_extension_date);
      formData.set(
        'original_date',
        moment
          .parseZone(val.original_date, 'DD/MM/YYYY')
          .utc(0)
          .format('YYYY-MM-DD')
      );
      formData.set('buyer_id', buyerId);

      val.files.forEach((value) => {
        formData.append('files', value);
      });

      sendSolicitation(formData);
    },
  });

  useEffect(() => {
    if (extension) {
      setFieldValue('reason', extension.seller_reason);
      setFieldValue('seller_extension_date', extension.seller_extension_date);
      setFieldValue('files', extension.files);
      setFieldValue(
        'original_date',
        moment(extension.original_date).utc(0).format('DD/MM/YYYY')
      );
    }
  }, [extension]);

  const cancelExtension = useCallback(async () => {
    const { isConfirmed } = await confirmationSwal(
      'Tem certeza?',
      'Deseja realmente cancelar esta prorrogação?'
    );

    if (isConfirmed) {
      updateSolicitation(
        extension._id,
        { status_id: 2 },
        'Prorrogação cancelada com sucesso!'
      );
    }
  }, [extension]);

  const isLastExtensionFinished = useCallback(() => {
    if (extension && extensions) {
      const firstExtensionOfList = extensions.filter(
        (val) => val.status_id === 6
      )[0];

      if (firstExtensionOfList?._id === extension?._id) {
        return true;
      }
      return false;
    }

    return false;
  }, [extensions, extension]);

  return (
    <Box>
      {isLoading ? (
        <Box
          mt={4}
          mb={4}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          {error ? (
            <Box>
              <Alert severity="error">
                Tivemos um problema na conexão com o servidor. Estamos tentando
                reconectar...
              </Alert>
            </Box>
          ) : (
            <>
              <Paper elevation={4} variant="outlined">
                <TitleSection
                  title={
                    extensionId
                      ? `Prorrogação ${moment(
                          extension?.seller_extension_date
                        ).format('DD/MM/YYYY')}`
                      : 'Solicitar nova prorrogação'
                  }
                />

                <Box mb={4} mt={4} pr={4} pl={4}>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={12} md={12}>
                        <TextField
                          variant="outlined"
                          name="reason"
                          disabled={extension}
                          value={values.reason}
                          label="Motivo da prorrogação"
                          helperText={touched.reason ? errors.reason : ''}
                          error={Boolean(errors.reason) && touched.reason}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Diga ao financiador qual o motivo da prorrogação"
                          className="text-field-label"
                          fullWidth
                          InputProps={{
                            style: {
                              borderRadius: 20,
                              color: '#000',
                              fontSize: 22,
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <TextField
                          variant="outlined"
                          name="original_date"
                          value={values.original_date}
                          label="Data original de vencimento"
                          onChange={handleChange}
                          disabled
                          onBlur={handleBlur}
                          className="text-field-label"
                          fullWidth
                          InputProps={{
                            style: {
                              borderRadius: 20,
                              color: '#000',
                              fontSize: 22,
                            },
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={ptBR}
                        >
                          <DatePicker
                            cancelLabel="Cancelar"
                            fullWidth
                            disabled={extension}
                            locale={ptBR}
                            helperText={
                              touched.seller_extension_date
                                ? errors.seller_extension_date
                                : ''
                            }
                            error={
                              Boolean(errors.seller_extension_date) &&
                              touched.seller_extension_date
                            }
                            minDate={moment
                              .parseZone(maturity.maturityDate)
                              .add('3', 'hours')}
                            name="seller_extension_date"
                            id="seller_extension_date"
                            label="Nova data de vencimento"
                            onBlur={handleBlur}
                            value={values.seller_extension_date}
                            format="dd/MM/yyyy"
                            onChange={(value) =>
                              setFieldValue('seller_extension_date', value)
                            }
                            TextFieldComponent={(props) => (
                              <TextField
                                {...props}
                                className="text-field-label"
                                variant="outlined"
                                InputProps={{
                                  style: {
                                    borderRadius: 20,
                                    color: '#000',
                                    fontSize: 22,
                                  },
                                }}
                              />
                            )}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12}>
                        {!extension ? (
                          <DropzoneArea
                            showFileNames
                            showAlerts={false}
                            fileObjects={values.files}
                            name="files"
                            onChange={(val) => setFieldValue('files', val)}
                            dropzoneText="Arraste os arquivos de evidência aqui"
                            acceptedFiles={[
                              'image/jpeg',
                              'image/png',
                              'application/pdf',
                            ]}
                            maxFileSize={5000000}
                          />
                        ) : (
                          <Grid container spacing={2}>
                            {extension.files.map((value, index) => (
                              <Grid item xs={12} sm={12} md={4} key={index}>
                                <Tooltip title="Visualizar / Baixar">
                                  <Paper
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      window.open(value.location, '_blank');
                                    }}
                                    elevation={2}
                                    variant="outlined"
                                  >
                                    <Box
                                      p={4}
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <Typography variant="h5">
                                        Evidência {++index}
                                      </Typography>
                                    </Box>
                                  </Paper>
                                </Tooltip>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                      </Grid>

                      {((isCedente() || isAdmin()) &&
                        !extension?.buyer_reason) ||
                      !extension ? (
                        <Grid item xs={12} sm={12} md={6}>
                          <Button
                            variant="text"
                            disabled={disableButtons}
                            color="black"
                            onClick={() => {
                              setFormExtension(false);
                            }}
                            startIcon={<ArrowBack />}
                          >
                            Voltar
                          </Button>
                        </Grid>
                      ) : null}

                      <ReactIf
                        condition={
                          extension &&
                          !extension.buyer_interest &&
                          (isCedente() || isAdmin()) &&
                          extension?.status_id === 1
                        }
                      >
                        <ReactThen>
                          <Grid item xs={12} sm={12} md={6}>
                            <Box
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Button
                                disabled={isValidating || disableButtons}
                                variant="contained"
                                onClick={async () => {
                                  const { isConfirmed } =
                                    await confirmationSwal(
                                      'Tem certeza?',
                                      'Tem certeza que deseja cancelar a solicitação?'
                                    );

                                  if (isConfirmed) {
                                    updateSolicitation(
                                      extension._id,
                                      { status_id: 7 },
                                      'Solicitação cancelada!'
                                    );
                                  }
                                }}
                                style={{
                                  backgroundColor: 'red',
                                  color: 'white',
                                }}
                              >
                                Cancelar pedido
                              </Button>
                            </Box>
                          </Grid>
                        </ReactThen>
                      </ReactIf>

                      {!extension ? (
                        <Grid item xs={12} sm={12} md={6}>
                          <Box
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              style={{ color: 'white' }}
                              disabled={
                                !isValid || isValidating || disableButtons
                              }
                            >
                              Solicitar
                            </Button>
                          </Box>
                        </Grid>
                      ) : null}
                    </Grid>
                  </form>
                </Box>
              </Paper>

              {(extension && extension?.buyer_reason && !isAdmin()) ||
              (isFinanciador() && extension?.status_id !== 7) ? (
                <ExtensionFormBuyer extensionId={extensionId} />
              ) : null}

              {isAdmin() && extension && extension?.status_id !== 7 ? (
                <ExtensionFormBuyer extensionId={extensionId} />
              ) : null}

              <Box ml={4} mr={4} mb={4}>
                <Grid container spacing={4}>
                  {extension?.status_id === 4 ||
                  extension?.status_id === 2 ||
                  extension?.status_id === 5 ||
                  extension?.status_id === 7 ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Typography variant="h6" color="error">
                          {extension?.status_name}
                        </Typography>
                      </Box>
                    </Grid>
                  ) : null}

                  {extension?.status_id === 6 ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Typography
                          variant="h6"
                          color="success"
                          style={{ color: 'green' }}
                        >
                          {extension?.status_name}
                        </Typography>
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
              </Box>

              {(isFinanciador() || isAdmin()) &&
              extension?.status_id === 6 &&
              isLastExtensionFinished() ? (
                <Box display="flex" justifyContent="center">
                  <Button
                    disabled={isValidating || disableButtons}
                    onClick={() => cancelExtension()}
                    type="button"
                    style={{ color: 'white', backgroundColor: 'red' }}
                  >
                    Cancelar prorrogação
                  </Button>
                </Box>
              ) : null}
            </>
          )}
        </>
      )}
    </Box>
  );
}
