export const headCells = [
  {
    id: 'invoiceId',
    label: 'ID',
    minWidth: 50,
    backgroundColor: '#fafafa',
  },
  {
    id: 'number',
    label: 'Nota Fiscal',
    minWidth: 130,
    maxWidth: 130,
    backgroundColor: '#fafafa',
  },
  {
    id: 'sellerName',
    label: 'Cedente',
    minWidth: 200,
    maxWidth: 216,
    backgroundColor: '#fafafa',
  },
  {
    id: 'draweeName',
    label: 'Sacado',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'busubessName',
    label: 'Financiador',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'maturitiesValue',
    label: 'Vencimentos',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'bidNetValue',
    label: 'Valor Líquido',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'bidNetFactor',
    label: 'CET% AM',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'statusName',
    label: 'Status',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
];

export const status = [
  { statusId: 1, statusName: 'Cadastro Pendente' },
  { statusId: 2, statusName: 'Aguardando Validação' },
  { statusId: 3, statusName: 'Aguardando Ação do Usuário' },
  { statusId: 4, statusName: 'Aguardando Lance' },
  { statusId: 5, statusName: 'Em Análise' },
  { statusId: 6, statusName: 'Arquivado' },
  { statusId: 7, statusName: 'Cancelado' },
  { statusId: 8, statusName: 'Vendido' },
  { statusId: 9, statusName: 'Aceitar Lance' },
  { statusId: 10, statusName: 'Aguardando Pregão' },
  { statusId: 11, statusName: 'Título Pago' },
  { statusId: 12, statusName: 'Suspenso' },
  { statusId: 99, statusName: 'Excluido' },
];

// export const statusMaturities = [
//   { statusMaturityId: 1, statusMaturityName: 'Liquidado' },
//   { statusMaturityId: 2, statusMaturityName: 'Em atraso' },
//   { statusMaturityId: 3, statusMaturityName: 'Em dia' },
//   { statusMaturityId: 4, statusMaturityName: 'Prorrogado' },
// ];

export const risks = [
  { riskId: 1, riskName: 'AA' },
  { riskId: 2, riskName: 'AB' },
  { riskId: 3, riskName: 'BA' },
  { riskId: 4, riskName: 'BB' },
  { riskId: 5, riskName: 'CA' },
  { riskId: 6, riskName: 'CB' },
  { riskId: 7, riskName: 'DA' },
  { riskId: 8, riskName: 'INAPTO' },
];

export const marketTypesAF = [
  { typeId: 1, typeName: 'Leilão' },
  { typeId: 2, typeName: 'Balcão' },
];

export const marketTypesFIF = [
  { typeId: 1, typeName: 'Leilão' },
  { typeId: 2, typeName: 'Balcão' },
  // { typeId: 4, typeName: 'Balcão FIF' },
];

export const ufs = [
  {
    ID: '1',
    Sigla: 'AC',
    Nome: 'Acre',
  },
  {
    ID: '2',
    Sigla: 'AL',
    Nome: 'Alagoas',
  },
  {
    ID: '3',
    Sigla: 'AM',
    Nome: 'Amazonas',
  },
  {
    ID: '4',
    Sigla: 'AP',
    Nome: 'Amapá',
  },
  {
    ID: '5',
    Sigla: 'BA',
    Nome: 'Bahia',
  },
  {
    ID: '6',
    Sigla: 'CE',
    Nome: 'Ceará',
  },
  {
    ID: '7',
    Sigla: 'DF',
    Nome: 'Distrito Federal',
  },
  {
    ID: '8',
    Sigla: 'ES',
    Nome: 'Espírito Santo',
  },
  {
    ID: '9',
    Sigla: 'GO',
    Nome: 'Goiás',
  },
  {
    ID: '10',
    Sigla: 'MA',
    Nome: 'Maranhão',
  },
  {
    ID: '11',
    Sigla: 'MG',
    Nome: 'Minas Gerais',
  },
  {
    ID: '12',
    Sigla: 'MS',
    Nome: 'Mato Grosso do Sul',
  },
  {
    ID: '13',
    Sigla: 'MT',
    Nome: 'Mato Grosso',
  },
  {
    ID: '14',
    Sigla: 'PA',
    Nome: 'Pará',
  },
  {
    ID: '15',
    Sigla: 'PB',
    Nome: 'Paraíba',
  },
  {
    ID: '16',
    Sigla: 'PE',
    Nome: 'Pernambuco',
  },
  {
    ID: '17',
    Sigla: 'PI',
    Nome: 'Piauí',
  },
  {
    ID: '18',
    Sigla: 'PR',
    Nome: 'Paraná',
  },
  {
    ID: '19',
    Sigla: 'RJ',
    Nome: 'Rio de Janeiro',
  },
  {
    ID: '20',
    Sigla: 'RN',
    Nome: 'Rio Grande do Norte',
  },
  {
    ID: '21',
    Sigla: 'RO',
    Nome: 'Rondônia',
  },
  {
    ID: '22',
    Sigla: 'RR',
    Nome: 'Roraima',
  },
  {
    ID: '23',
    Sigla: 'RS',
    Nome: 'Rio Grande do Sul',
  },
  {
    ID: '24',
    Sigla: 'SC',
    Nome: 'Santa Catarina',
  },
  {
    ID: '25',
    Sigla: 'SE',
    Nome: 'Sergipe',
  },
  {
    ID: '26',
    Sigla: 'SP',
    Nome: 'São Paulo',
  },
  {
    ID: '27',
    Sigla: 'TO',
    Nome: 'Tocantins',
  },
];

export const liquidations = [
  {
    nome: 'BOLETO',
  },
  {
    nome: 'BOLETO CONSIGNADO',
  },
  {
    nome: 'CONTA CONSIGNADO(TED)',
  },
];

export const ordens = [
  {
    nome: 'Mais relevantes',
    value: 99,
  },
  {
    nome: 'Mais recentes',
    value: 1,
  },
  {
    nome: 'Menos recentes',
    value: 2,
  },
  {
    nome: 'Por valor (crescente)',
    value: 3,
  },
  {
    nome: 'Por valor (decrescente)',
    value: 4,
  },
];

export const tiposTitulo = [
  {
    nome: 'PRODUTOS',
  },
  {
    nome: 'CONTA GARANTIDA',
  },
  {
    nome: 'ANTECIPAGOV',
  },
  {
    nome: 'SERVIÇOS',
  },
  {
    nome: 'ANTECIPA CARTEIRA',
  },
  {
    nome: 'PRECATÓRIO',
  },
];
