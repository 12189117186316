import React, { useEffect, useState } from 'react';
import { MenuItem } from 'react-contextmenu';
import toast from 'react-hot-toast';

import { TextField } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

import { CancelButton, ConfirmButton } from 'components/Button';

import formatReal from 'utils/formatReal';

import { liquidarParcela } from 'services/maturity';

import { useModal } from 'hooks/useModal';

import { LiquidacaoConts } from './liquidacaoConsts';

export function LiquidacaoParcelas(props) {
  const { maturity, invoice, onClose } = props;
  const { openModal } = useModal();

  const today = new Date();
  const [tipoLiquidacao, setTipoLiquidacao] = useState({
    name: '',
    id: null,
  });

  const [dataPagamento, setDataPagamento] = useState(today);
  const [valorQuitacao, setValorQuitacao] = useState();

  useEffect(() => {
    const valueToFormated = maturity?.is_parcel
      ? maturity.valor
      : invoice.invoiceValue;
    const value =
      valueToFormated.toString().indexOf('.') > -1
        ? valueToFormated
        : `${valueToFormated.toString()}.00`;
    const formatado = formatReal(value);
    setValorQuitacao(formatado);
  }, []);

  const changeType = async (event) => {
    const option = event.target.value;

    const findTipoLiquidacao = LiquidacaoConts.find(
      (_liquidacao) => _liquidacao.value == option
    );

    setTipoLiquidacao({
      name: findTipoLiquidacao.label,
      id: findTipoLiquidacao.value,
    });
  };

  const handlerChange = (e) => {
    setValorQuitacao(e.target.value);
  };

  const handleChangeDate = (event) => {
    setDataPagamento(event);
  };

  const handleLiquidar = async () => {
    const motivoId = tipoLiquidacao.id;
    const motivoName = tipoLiquidacao.name;
    const data = dataPagamento;
    const vlrQuitacao = valorQuitacao
      ? valorQuitacao.replace(/[^0-9,]/g, '').replace(',', '.')
      : '';

    if (!motivoId || !motivoName || !data || !vlrQuitacao) return;

    const payloadLiquidar = {
      invoiceId: invoice.invoiceId,
      maturityId: maturity.invoiceMaturityId,
      tipo_id: motivoId,
      tipo_descricao: motivoName,
      valor_quitacao: parseFloat(vlrQuitacao),
      data_pagamento: data.toLocaleDateString(),
    };

    try {
      await toast.promise(liquidarParcela(payloadLiquidar), {
        loading: 'Liquidando Parcela',
        success: 'Parcela liquidada com sucesso!',
        error: 'Erro ao liquidar parcela',
      });
      window.location.reload();
    } catch (error) {
      return false;
    }
  };

  const { userTypeId } = localStorage;

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexFlow: 'column wrap',
        marginTop: '15px',
      }}
    >
      <div
        style={{
          width: '500px',
        }}
      >
        { 
          userTypeId === "2" ? 
          <TextField
            fullWidth
            id="outlined-select-type"
            select
            label="Tipo de Liquidação"
            variant="outlined"
            value={tipoLiquidacao.id}
            onChange={changeType}
          >
            {LiquidacaoConts.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )).slice(0,-1)}
          </TextField> 
          : 
            <TextField
            fullWidth
            id="outlined-select-type"
            select
            label="Tipo de Liquidação"
            variant="outlined"
            value={tipoLiquidacao.id}
            onChange={changeType}
          >
            {LiquidacaoConts.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        
        }
        
      </div>
      <div
        style={{
          width: '500px',
        }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <KeyboardDatePicker
            helperText=""
            cancelLabel="Cancelar"
            fullWidth
            locale={ptBR}
            className="mr-2 mb-0 text-field"
            id="extension-date"
            label="Data de pagamento"
            format="dd/MM/yyyy"
            value={dataPagamento}
            variant="outlined"
            onChange={handleChangeDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </div>

      <div
        style={{
          marginTop: '20px',
          width: '500px',
        }}
      >
        <TextField
          autoFocus
          fullWidth
          className="mr-2 mb-0 text-field"
          id="valornota"
          label="Valor quitação"
          name="valornota"
          value={valorQuitacao || ''}
          type="text"
          onChange={(e) => {
            e.target.value = formatReal(e.target.value, true);
            handlerChange(e);
          }}
          variant="outlined"
        />
      </div>
      <div
        style={{
          marginTop: '50px',
          display: 'flex',
          flexDirection: 'flex-end',
        }}
      >
        <CancelButton onClick={onClose} className="mr-3">
          Cancelar
        </CancelButton>
        <ConfirmButton
          onClick={() =>
            openModal(
              'warning',
              'Deseja continuar?',
              'Confirme para liquidar a parcela',
              handleLiquidar
            )
          }
        >
          Liquidar
        </ConfirmButton>
      </div>
    </div>
  );
}
