class Related {
  constructor(
    businessId,
    name,
    countryIdentity,
    birthDate,
    typeId,
    businessParticipation,
    stateIdentity,
    stateIdentityIssuingEntity,
    stateIdentityFederativeUnity,
    addressZipCode,
    addressStreet,
    addressNumber,
    addressComplement,
    addressDistrict,
    addressCity,
    addressState,
    signsForCompany,
    ecpf,
    email,
    officePhone,
    mobilePhone,
    maritalStatusId
  ) {
    this.businessId = businessId;
    this.name = name;
    this.countryIdentity = countryIdentity;
    this.birthDate = birthDate;
    this.typeId = typeId;
    this.businessParticipation = businessParticipation;
    this.stateIdentity = stateIdentity;
    this.stateIdentityIssuingEntity = stateIdentityIssuingEntity;
    this.stateIdentityFederativeUnity = stateIdentityFederativeUnity;
    this.addressZipCode = addressZipCode;
    this.addressStreet = addressStreet;
    this.addressNumber = addressNumber;
    this.addressComplement = addressComplement;
    this.addressDistrict = addressDistrict;
    this.addressCity = addressCity;
    this.addressState = addressState;
    this.signsForCompany = signsForCompany;
    this.ecpf = ecpf;
    this.email = email;
    this.officePhone = officePhone;
    this.mobilePhone = mobilePhone;
    this.maritalStatusId = maritalStatusId;
  }
}

export default Related;
