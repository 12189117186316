export const getCustomHeaders = (userType) => {
  switch (userType) {
    case "admin":
      return {
        isAdmin: true,
      };
    case "buyer":
      return {
        isBuyer: true,
      };
    case "seller":
      return {
        isSeller: true,
      };
    default:
      return {};
  }
};
