import React, { useContext } from 'react';

import {
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';

import PropTypes from 'prop-types';

import { AccountManagementContext } from '../../../contexts/accountManager';
import { DialogContext } from '../../../contexts/dialog';
import EventBus from '../../../services/EventBus';
import IsolateMenu from '../../IsolateMenu';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: 18,
  },
}))(TableCell);

function TableAccounts({ rows = [] }) {
  const { optionsMenu } = useContext(AccountManagementContext);
  const { toggleDialog } = useContext(DialogContext);
  const theme = useTheme();

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Empresa</StyledTableCell>
            <StyledTableCell align="center">CNPJ</StyledTableCell>
            <StyledTableCell align="center">Agência / Conta</StyledTableCell>
            <StyledTableCell align="center">Banco</StyledTableCell>
            <StyledTableCell align="center">Status interno</StyledTableCell>
            <StyledTableCell align="center">Ações</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!rows.length ? (
            <TableRow>
              <TableCell align="center" colSpan={7}>
                Nenhum registro encontrado
              </TableCell>
            </TableRow>
          ) : null}
          {rows?.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="center">
                {row?.businessName ?? 'Sem informação'}
              </TableCell>
              <TableCell align="center">
                {row?.account?.document ?? 'Sem informação'}
              </TableCell>
              <TableCell align="center">
                {row?.account?.accountAgency?.substring(0, 4) ??
                  'Sem informação'}
                /{row?.account?.accountNumber ?? 'NA'}
              </TableCell>
              <TableCell align="center">Banco ABC</TableCell>
              <TableCell align="center">
                {row?.account?.onboardingStatus === 'Opening' ? (
                  <Chip label="Em processo de abertura" color="primary" />
                ) : null}

                {row?.account?.onboardingStatus === 'Done' ? (
                  <Chip
                    label="Aberta"
                    style={{
                      backgroundColor: theme.palette.success.main,
                      color: theme.palette.success.contrastText,
                    }}
                  />
                ) : null}

                {row?.account?.statusName === 'Error' ? (
                  <Chip
                    label="Erro na abertura"
                    style={{
                      backgroundColor: theme.palette.error.main,
                      color: theme.palette.error.contrastText,
                    }}
                  />
                ) : null}
              </TableCell>
              <TableCell align="center">
                <IsolateMenu>
                  {optionsMenu.map((val) => (
                    <MenuItem
                      key={index}
                      disabled={val.disabled}
                      onClick={(e) => {
                        val.action(row);
                        EventBus.dispatch('closeIsolateMenu');
                      }}
                    >
                      {val.name}
                    </MenuItem>
                  ))}
                </IsolateMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TableAccounts.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default TableAccounts;
