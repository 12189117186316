import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Paper, Box, Typography } from '@material-ui/core';

import { connect } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function PageTitle(props) {
  const { pageTitleStyle, pageTitleBackground, pageTitleDescription } = props;

  return (
    <>
      <Paper
        elevation={3}
        className={clsx('app-page-title', pageTitleStyle, pageTitleBackground)}
        style={{
          height: '135px',
          maxWidth: '100%',
        }}
      >
        <div>
          <div style={{ marginTop: '-25px' }}>
            <div className="app-page-title--heading">
              <h1 style={{ fontFamily: 'Poppins' }}>{props.titleHeading}</h1>
              {pageTitleDescription && (
                <div className="app-page-title--description">
                  {props.titleDescription}
                </div>
              )}
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
}
const mapStateToProps = (state) => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription,
});

export default connect(mapStateToProps)(PageTitle);
