import AxiosInterceptor from "utils/axios-interceptor";

export async function updateTestemunha(updateBusinessWitnesses) {
  const axiosInstance = AxiosInterceptor();
  const response = await axiosInstance.patch(
    `${process.env.REACT_APP_NODE_API}/businessWitness/updateMany`,
    updateBusinessWitnesses
  );

  return response;
}
