import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { useTheme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ContractIcon from "components/SideMenu/icons/Contract";
import { ShowcaseContext } from "contexts/showcase.context";
import isAfter from "date-fns/isAfter";
import { useMaturitys } from "hooks/useMaturitys";
import React, { useContext } from "react";
import formatDateSmallYear from "utils/formatDateSmallYear";
import BoltIcon from "../icons/Bolt";
import PapersIcon from "../icons/Papers";
import YoutubeIcon from "../icons/Youtube";
import { getMainColorLight } from "../Search";
import { useHistory } from "react-router-dom";
import "./styles.scss";

export default function LeilaoCardRow({
  abrindo,
  abrirAbdicar,
  abrirTitulo,
  businessId,
  calcularRisco,
  cnpj,
  formatReal,
  formatarData,
  titulo,
  position
}) {
  const { openModalOffer } = useContext(ShowcaseContext);
  const theme = useTheme();
  const mainColorLight = getMainColorLight(theme.palette.primary.main, 0.2);

  const { maturitys } = useMaturitys(titulo?.invoice?.invoiceId);
  const history = useHistory();

  const maturitysSorted = maturitys?.sort((a, b) => {
    const dateA = new Date(a.originalDate);
    const dateB = new Date(b.originalDate);

    if (isAfter(dateA, dateB)) {
      return 1;
    } else if (isAfter(dateB, dateA)) {
      return -1;
    } else {
      return 0;
    }
  });

  return (
    <div
      className="leilao-card-row"
      style={{
        boxShadow:
          titulo.invoice.marketTypeId === 2 &&
          `0 -5px 0 0px ${theme.palette.primary.main}`,
      }}
    >
      <div className="leilao-card__item-container">
        <div className="leilao-card__badges">
          <div className="leilao-card--id">ID: {titulo.invoice.id}</div>

          {titulo.invoice.indOferta === 1 && (
            <div className="leilao-card--badge">
              <BoltIcon color="#ffffff" size={17} />
              <span>Você está ganhando</span>
            </div>
          )}

          {titulo.invoice.indOferta === 2 && (
            <div className="leilao-card--badge leilao-card--badge--red">
              <BoltIcon color="#ffffff" size={17} />
              <span>Sua oferta foi coberta</span>
            </div>
          )}

          {titulo.invoice.marketTypeId === 4 ||
          titulo.invoice.marketTypeId === 2 ? (
            <div className="leilao-card--badge leilao-card--badge--green">
              {titulo.invoice.marketTypeName}
            </div>
          ) : null}
        </div>

        <div className="leilao-card--info-container">
          <div className="leilao-card--info">
            <div
              className="leilao-card--info--letter"
              style={{ color: theme.palette.primary.main }}
            >
              S
            </div>

            <div className="leilao-card--info--titles">
              <Tooltip title={titulo.sacado.name}>
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="leilao-card--info--title"
                >
                  {titulo.sacado.name}
                </a>
              </Tooltip>

              <Tooltip title={titulo.sacado.corporateName}>
                <span className="leilao-card--info--subtitle">
                  {titulo.sacado.corporateName}
                </span>
              </Tooltip>

              <Tooltip title={cnpj(titulo.sacado.countryIdentity)}>
                <span className="leilao-card--info--subtitle">
                  {cnpj(titulo.sacado.countryIdentity)}
                </span>
              </Tooltip>
            </div>
          </div>

          <div className="leilao-card--info">
            <div
              className="leilao-card--info--letter"
              style={{ color: "#0d8018" }}
            >
              C
            </div>

            <div className="leilao-card--info--titles">
              <Tooltip title={titulo.business.name}>
                <a
                  href="#/"
                  onClick={(e) => e.preventDefault()}
                  className="leilao-card--info--title"
                >
                  {titulo.business.name}
                </a>
              </Tooltip>

              <Tooltip title={titulo.business.corporateName}>
                <span className="leilao-card--info--subtitle">
                  {titulo.business.corporateName}
                </span>
              </Tooltip>

              <Tooltip title={cnpj(titulo.business.countryIdentity)}>
                <span className="leilao-card--info--subtitle">
                  {cnpj(titulo.business.countryIdentity)}
                </span>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      <div className="leilao-card__item-container">
        <div className="leilao-card__gray-container">
          <div className="leilao-card--rating-container">
            <div className="leilao-card--rating--item">
              <div className="leilao-card--rating--item--title">Rating</div>

              <div
                className="leilao-card--rating"
                style={{
                  backgroundColor: titulo.invoice.riskName
                    ? calcularRisco(titulo.invoice.riskName)
                    : "#ffffff",
                }}
              >
                {titulo.invoice.riskName}
              </div>
            </div>

            <div className="leilao-card--rating--item">
              <div className="leilao-card--rating--item--title">Tipo</div>

              <div className="leilao-card--item--content">
                {titulo.invoice.typeName}
              </div>
            </div>
          </div>

          <div className="leilao-card--content-gray">
            <div className="leilao-card--content-gray--item leilao-card--content-gray--item--title">
              <span>Valor</span>

              <span className="leilao-card--content-gray--item--value number">
                {formatReal(parseFloat(titulo.invoice.invoiceValue).toFixed(2))}
              </span>
            </div>

            <div className="leilao-card--content-gray--item">
              <span>Prazo médio</span>

              <span className="leilao-card--content-gray--item--value number">
                {`${titulo.invoice.days}d`}
              </span>
            </div>
            <div className="leilao-card--content-gray--item">
              <Accordion classes={{ root: "leilao--accordion--root--row" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMore style={{ color: "#0d8018", fontSize: 30 }} />
                  }
                  aria-controls="panel1a-content"
                  classes={{
                    root: "leilao--accordion-summary--row",
                    expandIcon: "leilao--accordion-summary-iccon-button--row",
                  }}
                  id="panel1a-header"
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ color: "#696969" }}>Parcelas</span>
                    <span style={{ color: "#696969" }}>
                      {titulo.invoice.maturities}x
                    </span>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  classes={{ root: "leilao-accordion-details--row" }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <span>Data</span>
                        </th>
                        <th>
                          <span>Prazo</span>
                        </th>
                        <th style={{ textAlign: "center" }}>
                          <span>Valor</span>
                        </th>
                      </tr>
                    </thead>
                    {maturitysSorted?.map((item, index) => (
                      <tbody key={`${item} ${index}`}>
                        <tr>
                          <td>
                            <span>{formatDateSmallYear(item?.originalDate)}</span>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <span>{item?.dias}d</span>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <span>{formatReal(item?.valor)}</span>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>

        <div className="leilao-card__item-bottom">
          <div>
            {titulo.tags && titulo.tags.length > 0 ? (
              <Tooltip
                title={titulo.tags.map(
                  (tag, index) =>
                    `${tag.NAME}${index + 1 < titulo.tags.length ? ", " : ""}`
                )}
              >
                <div className="leilao-card--tags">
                  <span className="leilao-card--tags-title">Tags: </span>

                  {titulo.tags.map((tag, index) => (
                    <span key={`${tag.NAME} ${index}`}>
                      {tag.NAME}
                      {index + 1 < titulo.tags.length ? ", " : ""}
                    </span>
                  ))}
                </div>
              </Tooltip>
            ) : null}
          </div>

          <div className="leilao-card__item-bottom__right">
            <div className="leilao-card--rating--item--title">Dossiê</div>

            <div className="leilao-card--item--content">
              <Tooltip title="Abrir Vídeos" placement="top">
                <IconButton
                  classes={{ root: "leilao-card--item--button" }}
                  onClick={() => openModalOffer(titulo, 2, "videos")}
                >
                  <YoutubeIcon color="#2C2C2C" size={30} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Abrir Detalhes" placement="top">
                <IconButton
                  classes={{ root: "leilao-card--item--button" }}
                  onClick={() => openModalOffer(titulo, 1)}
                >
                  <PapersIcon color="#2C2C2C" size={20} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Abrir Título" placement="top">
                <IconButton
                  classes={{ root: "leilao-card--item--button" }}
                  onClick={() => openModalOffer(titulo, 3)}
                >
                  <ContractIcon color="#2C2C2C" size={21} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      <div
        className="leilao-card__item-container leilao-card--current-offer"
        style={{ backgroundColor: mainColorLight }}
      >
        <h4>Oferta atual</h4>

        <div className="leilao-card--current-offer--items">
          <div className="leilao-card--current-offer--item leilao-card--current-offer--item--main">
            <div className="leilao-card--current-offer--item---text">
              <span>Desconto</span>
            </div>

            <div className="number bold">
              {titulo.invoice.descontoAtual
                ? formatReal(
                    parseFloat(titulo.invoice.descontoAtual).toFixed(2)
                  )
                : "-"}
            </div>
          </div>

          <div className="leilao-card--current-offer--item">
            <div className="leilao-card--current-offer--item---text">
              <span>CET % am</span>

              {titulo.invoice.bidBusinessId === businessId && (
                <BoltIcon color="#5383FC" size={17} />
              )}
            </div>

            <div className="number bold">
              {titulo.invoice.cetAtual
                ? `${titulo.invoice.cetAtual.toFixed(3).replace(".", ",")}%`
                : "-"}
            </div>
          </div>
        </div>

        <div className="leilao-card--buttons">
          <Button
            className="leilao-card--button"
            color="secondary"
            disabled={abrindo}
            onClick={async () => {
              await abrirTitulo(titulo, position);
              history.replace({
                pathname: `/vitrine/oferta/${titulo.invoice.id}`,
              });
            }}
            type="button"
            variant="outlined"
          >
            {titulo.invoice.abdicado === "1" ? "Arrematar" : "Dar oferta"}
          </Button>

          {titulo.invoice.abdicarTitulo.trim() === "1" && (
            <Button
              className="leilao-card--button leilao-card--button--secondary"
              color="secondary"
              onClick={() => abrirAbdicar(titulo)}
              type="button"
              variant="outlined"
            >
              Abdicar
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
