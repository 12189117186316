import React from 'react';

import { useTheme } from '@material-ui/core/styles';

export default function Home({
  color = '#A0A0A0',
  primary = false,
  size = 28,
}) {
  const theme = useTheme();

  const iconColor = primary ? theme.palette.primary.main : color;

  return (
    <svg
      id="home_2_"
      data-name="home (2)"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 41.273 41.273"
    >
      <g id="Grupo_46" data-name="Grupo 46" transform="translate(0 0)">
        <path
          id="Caminho_46"
          data-name="Caminho 46"
          fill={iconColor}
          d="M40.478,14.883,21.905.438a2.064,2.064,0,0,0-2.534,0L.8,14.883a2.064,2.064,0,0,0,1.267,3.693H4.129v22.7H16.511V24.767h8.255V41.276H37.147v-22.7h2.064a2.063,2.063,0,0,0,1.267-3.693Zm-5.394,1.629v22.7H26.829V24.767A2.063,2.063,0,0,0,24.765,22.7H16.511a2.063,2.063,0,0,0-2.064,2.064V39.212H6.193v-22.7H2.065L20.638,2.067,39.211,16.512H35.083Z"
          transform="translate(-0.001 -0.003)"
        />
      </g>
    </svg>
  );
}
