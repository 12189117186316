import React from 'react';

export default function Download({ color = '#A0A0A0', size = 40 }) {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26.686 26.686"
    >
      <g
        id="Icon_feather-download"
        data-name="Icon feather-download"
        transform="translate(1 1)"
      >
        <path
          id="Caminho_111"
          data-name="Caminho 111"
          d="M29.186,22.5v5.486a2.743,2.743,0,0,1-2.743,2.743H7.243A2.743,2.743,0,0,1,4.5,27.986V22.5"
          transform="translate(-4.5 -6.043)"
          fill="none"
          stroke="#212121"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Caminho_112"
          data-name="Caminho 112"
          d="M10.5,15l6.857,6.857L24.214,15"
          transform="translate(-5.014 -5.4)"
          fill="none"
          stroke="#212121"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Caminho_113"
          data-name="Caminho 113"
          d="M18,20.957V4.5"
          transform="translate(-5.657 -4.5)"
          fill="none"
          stroke="#212121"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
