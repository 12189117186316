import useSWR from 'swr';

import { fetchWithToken } from '../fetchWithToken';

export default function useMaturity(
  invoiceId = null,
  maturityId = null,
  options = {
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
    shouldRetryOnError: true,
    errorRetryInterval: 1000,
  }
) {
  const {
    data: maturity,
    error,
    isValidating,
    mutate,
  } = useSWR(
    invoiceId && maturityId
      ? `${process.env.REACT_APP_NODE_API}/maturity/${invoiceId}/${maturityId}`
      : false,
    fetchWithToken,
    options
  );

  return {
    maturity: maturity && maturity.data ? maturity.data : null,
    error,
    isValidating,
    isLoading: invoiceId && maturityId && !error && !maturity,
    mutate,
  };
}
