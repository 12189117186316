export const headCells = [
  {
    id: 'edit',
    label: '',
    minWidth: 50,
    maxWidth: 50,
    backgroundColor: '#fafafa',
  },
  {
    id: 'number',
    label: 'Nota',
    minWidth: 130,
    maxWidth: 130,
    backgroundColor: '#fafafa',
  },
  {
    id: 'drawee.name',
    label: 'Sacado',
    minWidth: 130,
    maxWidth: 130,
    backgroundColor: '#fafafa',
  },
  {
    id: 'first_maturity_date',
    label: 'Data do 1º Vencimento',
    minWidth: 130,
    maxWidth: 130,
    backgroundColor: '#fafafa',
  },
  {
    id: 'status_name',
    label: 'Status',
    minWidth: 200,
    maxWidth: 216,
    backgroundColor: '#fafafa',
  },
  {
    id: 'risk_name',
    label: 'Risco',
    minWidth: 190,
    maxWidth: 216,
    backgroundColor: '#fafafa',
  },
  {
    id: 'maturities_value',
    label: 'Valor DC',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'available_percentage',
    label: '% Liberado',
    minWidth: 170,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'available_value',
    label: 'Total Liberado',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'commited_value',
    label: 'Valor Utilizado',
    minWidth: 300,
    maxWidth: 330,
    backgroundColor: '#fafafa',
  },
  {
    id: 'balance',
    label: 'Saldo',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'maturities',
    label: 'Parcelas',
    minWidth: 100,
    maxWidth: 150,
    backgroundColor: '#fafafa',
  },
  {
    id: 'agendaValue',
    label: 'Valor utilizado',
    minWidth: 150,
    maxWidth: 200,
    backgroundColor: '#fafafa',
  },
];

export const headCellsAdmin = [
  {
    id: 'edit',
    label: '',
    minWidth: 50,
    maxWidth: 50,
    backgroundColor: '#fafafa',
  },
  {
    id: 'number',
    label: 'Nota',
    minWidth: 130,
    maxWidth: 130,
    backgroundColor: '#fafafa',
  },
  {
    id: 'drawee.name',
    label: 'Sacado',
    minWidth: 130,
    maxWidth: 130,
    backgroundColor: '#fafafa',
  },
  {
    id: 'first_maturity_date',
    label: 'Data do 1º Vencimento',
    minWidth: 130,
    maxWidth: 130,
    backgroundColor: '#fafafa',
  },
  {
    id: 'status_name',
    label: 'Status',
    minWidth: 200,
    maxWidth: 216,
    backgroundColor: '#fafafa',
  },
  {
    id: 'risk_name',
    label: 'Risco',
    minWidth: 190,
    maxWidth: 216,
    backgroundColor: '#fafafa',
  },
  {
    id: 'maturities_value',
    label: 'Valor DC',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'available_percentage',
    label: '% Liberado',
    minWidth: 170,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'available_value',
    label: 'Total Liberado',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'balance',
    label: 'Saldo',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'maturities',
    label: 'Parcelas',
    minWidth: 150,
    maxWidth: 200,
    backgroundColor: '#fafafa',
  },
  {
    id: 'agendaValue',
    label: 'Valor utilizado',
    minWidth: 150,
    maxWidth: 200,
    backgroundColor: '#fafafa',
  },
];

export const headCellsFinanciador = [
  {
    id: 'edit',
    label: '',
    minWidth: 50,
    maxWidth: 50,
    backgroundColor: '#fafafa',
  },
  {
    id: 'number',
    label: 'Nota',
    minWidth: 130,
    maxWidth: 130,
    backgroundColor: '#fafafa',
  },
  {
    id: 'drawee.name',
    label: 'Sacado',
    minWidth: 130,
    maxWidth: 130,
    backgroundColor: '#fafafa',
  },
  {
    id: 'first_maturity_date',
    label: 'Data do 1º Vencimento',
    minWidth: 130,
    maxWidth: 130,
    backgroundColor: '#fafafa',
  },
  {
    id: 'risk_name',
    label: 'Risco',
    minWidth: 190,
    maxWidth: 216,
    backgroundColor: '#fafafa',
  },
  {
    id: 'maturities_value',
    label: 'Valor DC',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'maturities',
    label: 'Parcelas',
    minWidth: 150,
    maxWidth: 200,
    backgroundColor: '#fafafa',
  },
  {
    id: 'agendaValue',
    label: 'Valor utilizado',
    minWidth: 150,
    maxWidth: 200,
    backgroundColor: '#fafafa',
  },
];

export const statusList = [
  { label: 'Aguardando Ação Usuário', value: 'Aguardando Acao Usuario', id: 1 },
  { label: 'Aguardando Validação', value: 'Aguardando Validacao', id: 2 },
  { label: 'Aguardando Documentação', value: 'Aguardando Documentacao', id: 3 },
  { label: 'Cancelado', value: 'Cancelado', id: 4 },
  { label: 'Aprovado', value: 'Aprovado', id: 5 },
  { label: 'Reprovado', value: 'Reprovado', id: 6 },
];

export const riscoList = [
  { value: 'Não definido', id: 0 },
  { value: 'AA', id: 11 },
  { value: 'AB', id: 10 },
  { value: 'BA', id: 9 },
  { value: 'BB', id: 8 },
  { value: 'CA', id: 7 },
  { value: 'CB', id: 6 },
  { value: 'DA', id: 5 },
  { value: 'INAPTO', id: 1 },
];
