import React, { useEffect, useState } from "react";

import {
  Grid,
  InputLabel,
  IconButton,
  Box,
  Typography,
  Dialog,
  Tabs,
  Tab,
  Tooltip,
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
} from "@material-ui/core";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { Autocomplete } from "@material-ui/lab";
import PropTypes from "prop-types";

import { Modal } from "components/material/Modal";
import { ReactIf, ReactCatch, ReactThen } from "components/ReactIf";

import formatTelefone from "utils/formatTelefone";

import {
  CreateBepayAccount,
  UpdateBankAccount,
  UpdatePayrollAccount,
} from "services/gestao-contas";

import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";

import { useModal } from "hooks/useModal";
import "./style.css";
import AxiosInterceptor from "utils/axios-interceptor";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const BankAccountFocus = (props) => {
  const {
    bankAccountDialog,
    setBankAccountDialog,
    business,
    changeBusinessBankAccounts,
    StylizedInput,
    removeBankAccount,
    representantesConta,
    handleLoadBankAccounts,
  } = props;

  const { isAdmin, isCedente, canSelfUpdate } = useAuth();
  const { toast } = useToast();
  const { openModal } = useModal();

  const [selectedBankAccount, setSelectedBankAccount] = useState();
  const [loadingBankAccounts, setLoadingBankAccounts] = useState(true);
  const [bankAccountsData, setBankAccountsData] = useState([]);
  const [value, setValue] = useState(0);
  const [bankAccount, setBankAccount] = useState(bankAccountDialog.bankAccount);
  const [updatingBankAccount, setUpdatingBankAccount] = useState(false);

  const allFilled = true;
  const [updated, setUpdated] = useState(false);

  const [emailExists, setEmailExists] = useState(false);
  const [selectedRepresentante, setSelectedRepresentante] = useState(undefined);

  const [openModalFull, setOpenModalFull] = useState(false);

  useEffect(() => {
    if (
      bankAccountDialog.bankAccount &&
      bankAccountDialog.bankAccount.accountTypeId === 1 &&
      !bankAccountDialog.bankAccount.accountId
    ) {
      createBepayWithAcccount(bankAccountDialog.bankAccount);
    }
    setBankAccount(bankAccountDialog.bankAccount);
  }, [bankAccountDialog]);

  useEffect(() => {
    fetchBankAccountsData();
  }, []);

  async function fetchBankAccountsData() {
    const axiosInstance = AxiosInterceptor();
    setLoadingBankAccounts(true);
    const result = (
      await axiosInstance.get(
        `${process.env.REACT_APP_NODE_API}/businessBankAccounts/bank-list`
      )
    ).data;

    if (result) {
      setBankAccountsData(
        result.reduce(
          (acc, bankAccount) => {
            if (
              (bankAccount.name || bankAccount.fullName) &&
              bankAccount.code
            ) {
              acc.formattedOptions.push({
                value: String(bankAccount.code),
                item: bankAccount.code
                  ? `${bankAccount.code} - ${
                      bankAccount.name ?? bankAccount.fullName
                    }`
                  : String(bankAccount.name) ?? bankAccount.fullName,
              });
              acc.formattedAccounts[bankAccount.code] = bankAccount;
            }
            return acc;
          },
          { formattedOptions: [], formattedAccounts: {} }
        )
      );
    }
    setLoadingBankAccounts(false);
  }

  const isNewPayrollAccount = (payrollAccount) =>
    Number(payrollAccount.accountTypeId) === 1 &&
    payrollAccount.payrollAccountId === undefined;

  const handleChangeTabValue = (event, newValue) => {
    setValue(newValue);
  };

  const updateBankAccountStatus = async () => {};

  const updateBankAccountData = async () => {
    if (updatingBankAccount) return;

    setUpdatingBankAccount(true);
    const updatedBankAccount = bankAccount;
    const newBankAccount = await UpdateBankAccount(updatedBankAccount);

    if (newBankAccount === "") {
      setEmailExists(true);
      setUpdatingBankAccount(false);
      setTimeout(() => {
        setEmailExists(false);
      }, 2000);
    } else {
      let newBankAccountData = newBankAccount;

      if (Number(updatedBankAccount.accountTypeId) === 1) {
        const payrollAccount = await UpdatePayrollAccount(updatedBankAccount);

        newBankAccountData = {
          ...newBankAccountData,
          ...payrollAccount,
        };

        bankAccountDialog.setBankAccount(newBankAccountData);
        setBankAccount(newBankAccountData);
        setBankAccountDialog({
          ...bankAccountDialog,
          bankAccount: newBankAccountData,
        });
        changeBusinessBankAccounts(newBankAccountData, bankAccountDialog.index);
        setUpdated(true);
        setTimeout(() => {
          setUpdated(false);
          setUpdatingBankAccount(false);
        }, 2000);
        if (updatedBankAccount.payrollAccountId === undefined) setValue(0);
      } else {
        bankAccountDialog.setBankAccount(newBankAccountData);
        setBankAccount(newBankAccountData);
        setBankAccountDialog({
          ...bankAccountDialog,
          bankAccount: newBankAccountData,
        });
        changeBusinessBankAccounts(newBankAccountData, bankAccountDialog.index);
        setUpdated(true);
        setTimeout(() => {
          setUpdated(false);
          setUpdatingBankAccount(false);
        }, 2000);
      }
    }
  };

  const createBepayWithAcccount = async (bankAccount) => {
    if (updatingBankAccount) return;
    if (bankAccount === undefined) return;
    setUpdatingBankAccount(true);
    const createBepayAccount = {
      ...bankAccount,
      bairro: business.addressDistrict,
      cep: business.addressZipCode,
      cidade: business.addressCity,
      estado: business.addressState,
      logradouro: business.addressStreet,
      numero: business.addressNumber,
      complemento: business.addressComplement,
    };

    return new Promise((resolve, reject) => {
      CreateBepayAccount(createBepayAccount)
        .then((response) => {
          const newPayrollAccount = {
            ...response.data,
            ...response.data.payrollAccount,
          };
          setBankAccount(newPayrollAccount);
          handleLoadBankAccounts(business.businessId);
          setUpdatingBankAccount(false);
          if (!response.data.accountNumber) {
            toast.error(
              "Não foi possível obter número da conta. Aguarde um momento e tente novamente."
            );
          }
          resolve();
        })
        .catch((error) => {
          setUpdatingBankAccount(false);
          reject(error.response.data.erro ?? "Falha ao criar conta");
        });
    });
  };

  const createBepay = async () => {
    if (updatingBankAccount) return;
    if (bankAccount === undefined) return;
    setUpdatingBankAccount(true);
    const createBepayAccount = {
      ...bankAccount,
      rep_NOME: selectedRepresentante.rep.name,
      rep_CPF: selectedRepresentante.rep.countryIdentity,
      rep_TEL: selectedRepresentante.rep.mobilePhone,
      rep_EMAIL: selectedRepresentante.rep.email,
      bairro: business.addressDistrict,
      cep: business.addressZipCode,
      cidade: business.addressCity,
      estado: business.addressState,
      logradouro: business.addressStreet,
      numero: business.addressNumber,
      complemento: business.addressComplement,
    };

    return new Promise((resolve, reject) => {
      CreateBepayAccount(createBepayAccount)
        .then((response) => {
          const newPayrollAccount = {
            ...response.data,
            ...response.data.payrollAccount,
          };
          setBankAccount(newPayrollAccount);
          handleLoadBankAccounts(business.businessId);
          setUpdatingBankAccount(false);
          resolve();
        })
        .catch((error) => {
          setUpdatingBankAccount(false);
          reject(error.response.data.erro ?? "Falha ao criar conta");
        });
    });
  };

  const handleUpdateAccount = async () => {
    const create =
      bankAccount.bankAccountId === undefined &&
      bankAccount.payrollAccountId === undefined;

    const createToast = {
      loading: "Criando conta",
      success: "Conta criada com sucesso!",
      error: "Falha ao criar conta",
    };

    const updateToast = {
      loading: "Atualizando conta",
      success: "Conta atualizada com sucesso!",
      error: "Falha ao atualizar conta",
    };
    try {
      await toast.promise(
        updateBankAccountData(),
        create ? createToast : updateToast
      );
      await handleLoadBankAccounts();
    } catch (error) {
      setUpdatingBankAccount(false);
    }
  };

  const handleCreateBepay = async () => {
    if (selectedRepresentante === undefined) {
      toast.error("Representante não foi escolhido");
      return;
    }
    await toast.promise(createBepay(), {
      loading: "Criando conta",
      success: "Conta criada com sucesso!",
      error: (err) => `${err}`,
    });
  };

  const handleClose = () => {
    setOpenModalFull(false);
    setBankAccountDialog({ ...bankAccountDialog, open: false });
  };

  return (
    <>
      <Dialog
        maxWidth="xl"
        open={bankAccountDialog.open}
        onClose={() => {
          setBankAccountDialog({ ...bankAccountDialog, open: false });
          setOpenModalFull(false);
        }}
      >
        <Modal
          id="customized-dialog-title"
          style={{
            display: "flex",
            background: "#1E212E",
            width: "41.7%",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
          onClose={handleClose}
        />
        {bankAccount !== undefined && (
          <Grid
            container
            spacing={0}
            style={{ height: "600px", width: "1000px" }}
          >
            <Grid item xs={12} lg={5}>
              <div
                className="hero-wrapper bg-composed-wrapper h-100 justify-content-center p-4"
                style={{ backgroundColor: "#373A44", position: "relative" }}
              >
                {openModalFull && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      backgroundColor: "#000000aa",
                      zIndex: 999,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 16,
                        padding: 32,
                      }}
                    >
                      <h1 className="display-4 font-weight-bold">
                        {Number(bankAccount.statusId) === 1
                          ? "Desativar"
                          : "Ativar"}
                      </h1>
                      <p className="font-size-lg mb-0 text-black-50">
                        Deseja{" "}
                        {Number(bankAccount.statusId) === 1
                          ? "desativar"
                          : "ativar"}{" "}
                        o usuário?
                      </p>
                      <div className="d-flex flex-row align-items-center justify-content-end w-100 mt-3">
                        <span
                          className="text-black-50 mr-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenModalFull(false)}
                        >
                          Cancelar
                        </span>
                        <span
                          className="text-black-50"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            updateBankAccountStatus(
                              Number(bankAccount.statusId)
                            );
                          }}
                        >
                          Confirmar
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="w-100 d-flex align-items-center">
                  {/* <div
                  className="bg-composed-wrapper--image "
                  style={{ backgroundColor: '#555', backgroundImage: 'url(' + hero1 + ')', backgroundBlendMode: '' }}
                /> */}
                  <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                  <div className="bg-composed-wrapper--content p-5">
                    {bankAccount.statusId !== null &&
                      bankAccount.statusId !== undefined && (
                        <div
                          className="d-flex align-items-center"
                          style={{ color: "#fff" }}
                        >
                          {Number(bankAccount.statusId) === 1 ? (
                            <span
                              className="px-4 h-auto py-1 badge badge-pill"
                              style={{ backgroundColor: "#45B465" }}
                            >
                              LIBERADA
                            </span>
                          ) : Number(bankAccount.statusId) === 2 ? (
                            <span
                              className="px-4 h-auto py-1 badge badge-pill"
                              style={{ backgroundColor: "#F85051" }}
                            >
                              BLOQUEADA
                            </span>
                          ) : Number(bankAccount.statusId) === 3 ? (
                            <span
                              className="px-4 h-auto py-1 badge badge-pill"
                              style={{ border: "1px solid grey" }}
                            >
                              NEGADA
                            </span>
                          ) : Number(bankAccount.statusId) === 4 ? (
                            <span
                              className="px-4 h-auto py-1 badge badge-pill"
                              style={{ backgroundColor: "blue" }}
                            >
                              ABERTA
                            </span>
                          ) : (
                            <span className="px-4 h-auto py-1 badge badge-pill" />
                          )}
                        </div>
                      )}
                    <div className="text-white mt-3">
                      <h1 className="display-4 my-3 font-weight-bold text-white-50">
                        {business.name}
                      </h1>
                      <p className="font-size-lg mb-0 text-white-50">
                        {business.corporateName}
                      </p>
                      <p className="font-size-base mb-0 text-white-50">
                        {bankAccount.email}
                      </p>
                      <p className="font-size-lg mb-0 text-white-50">
                        {formatTelefone(bankAccount.phone)}
                      </p>
                      <div className="divider border-2 my-5 border-light opacity-2 rounded w-25" />
                    </div>
                  </div>
                </div>
                {(isAdmin() ||
                  (isCedente() && Number(bankAccount.accountTypeId) === 2)) &&
                  canSelfUpdate(business.statusId) && (
                    <div
                      className="hero-footer"
                      style={{ border: "1px solid white", borderRadius: 32 }}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <Tooltip arrow placement="top" title="Excluir Conta">
                          <IconButton
                            color="primary"
                            size="medium"
                            variant="outlined"
                            className="text-white"
                            onClick={() =>
                              openModal(
                                "warning",
                                "Atenção!",
                                "Tem certeza que deseja remover essa conta?",
                                removeBankAccount,
                                bankAccount.bankAccountId
                              )
                            }
                          >
                            <DeleteOutlineOutlinedIcon className="font-size-lg" />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  )}
              </div>
            </Grid>
            <Grid item xs={12} lg={7}>
              <div className="bg-white h-100">
                <ReactIf condition={isNewPayrollAccount(bankAccount)}>
                  <ReactThen>
                    <Tabs
                      value={value}
                      onChange={handleChangeTabValue}
                      indicatorColor="secondary"
                      textColor="secondary"
                      variant="fullWidth"
                    >
                      <Tab className="py-3" label="Abrir conta BePay" />
                      <Tab
                        className="py-3"
                        label="ou"
                        style={{ cursor: "default" }}
                        disabled
                      />
                      <Tab className="py-3" label="Preencher Dados" />
                    </Tabs>
                  </ReactThen>
                  <ReactCatch>
                    <Tabs
                      value={value}
                      onChange={handleChangeTabValue}
                      indicatorColor="secondary"
                      textColor="secondary"
                      variant="fullWidth"
                    />
                  </ReactCatch>
                </ReactIf>

                {/* Abrir Conta BePay */}
                <TabPanel
                  value={value}
                  index={isNewPayrollAccount(bankAccount) ? 0 : 3}
                  style={{
                    width: "100%",
                    height: "88%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ReactIf
                    condition={
                      Number(bankAccount.accountTypeId) === 1 &&
                      bankAccount.payrollAccountId !== undefined
                    }
                  >
                    <ReactThen>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "465px",
                        }}
                      >
                        <StylizedInput
                          disabled
                          label="AccountId"
                          inputName="accountId"
                          required
                          bankAccount={bankAccount}
                          setter={setBankAccount}
                          index={bankAccountDialog.index}
                          filledState={allFilled}
                          orError={emailExists}
                          style={{ marginTop: "-70px" }}
                        />
                      </div>
                    </ReactThen>
                  </ReactIf>
                  <ReactIf
                    condition={
                      representantesConta &&
                      Number(bankAccount.accountTypeId) === 1 &&
                      bankAccount.payrollAccountId === undefined &&
                      isAdmin()
                    }
                  >
                    <ReactThen>
                      <div className="d-flex flex-column align-items-center">
                        <p className="font-size-lg mb-0">
                          Escolha um representante para abrir uma conta
                          consignada
                        </p>
                        <FormControl
                          className="mt-2 mb-2 ml-4"
                          style={{ width: 200 }}
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            color="secondary"
                            className="ml-2"
                          >
                            Representantes*
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedRepresentante?.index}
                            color="secondary"
                            onChange={(e) => {
                              setSelectedRepresentante({
                                index: e.target.value,
                                rep: representantesConta[e.target.value],
                              });
                            }}
                          >
                            {representantesConta?.map((rep, index) => (
                              <MenuItem
                                style={{ fontSize: ".9em" }}
                                value={index}
                              >
                                {rep.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </ReactThen>
                  </ReactIf>

                  <ReactIf condition={updated}>
                    <ReactThen>
                      <div className="d-flex flex-column">
                        <ReactIf condition={isAdmin()}>
                          <ReactThen>
                            <div
                              style={{
                                position: "relative",
                                alignSelf: "flex-end",
                              }}
                            >
                              <Tooltip
                                placement="left"
                                title="Salvo!"
                                style={{
                                  borderRadius: "50%",
                                  width: 56,
                                  height: "auto",
                                  backgroundColor: "#373A44",
                                  padding: 16,
                                  color: "#fff",
                                  alignSelf: "flex-end",
                                  boxShadow: "1px 3px 5px #888",
                                  cursor: "pointer",
                                  transitionDuration: 1,
                                  marginTop: "20px",
                                }}
                              >
                                <IconButton
                                  color="primary"
                                  size="medium"
                                  variant="outlined"
                                  className="text-green"
                                >
                                  <CheckOutlinedIcon
                                    style={{ color: "#45B465" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </ReactThen>
                        </ReactIf>
                      </div>
                    </ReactThen>
                    <ReactCatch>
                      <div className="d-flex flex-column">
                        <ReactIf condition={isAdmin()}>
                          <ReactThen>
                            <div
                              style={{
                                position: "relative",
                                alignSelf: "flex-end",
                              }}
                            >
                              <Tooltip
                                placement="left"
                                title="Salvar"
                                style={{
                                  borderRadius: "50%",
                                  width: 56,
                                  height: "auto",
                                  backgroundColor: "#373A44",
                                  padding: 16,
                                  color: "#fff",

                                  boxShadow: "1px 3px 5px #888",
                                  cursor: "pointer",
                                  marginTop: 20,
                                }}
                              >
                                <IconButton
                                  color="primary"
                                  size="medium"
                                  variant="outlined"
                                  className="text-white"
                                  onClick={() =>
                                    Number(bankAccount.accountTypeId) === 1 &&
                                    bankAccount.payrollAccountId === undefined
                                      ? handleCreateBepay()
                                      : handleUpdateAccount()
                                  }
                                >
                                  <CheckOutlinedIcon />
                                </IconButton>
                              </Tooltip>
                              <ReactIf condition={updatingBankAccount}>
                                <ReactThen>
                                  <CircularProgress
                                    size={64}
                                    style={{
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      marginTop: -22,
                                      marginLeft: -32,
                                      color: "primary",
                                    }}
                                  />
                                </ReactThen>
                              </ReactIf>
                            </div>
                          </ReactThen>
                        </ReactIf>
                      </div>
                    </ReactCatch>
                  </ReactIf>
                </TabPanel>
                {/* Preencher Dados */}
                <TabPanel
                  value={value}
                  index={isNewPayrollAccount(bankAccount) ? 2 : 0}
                  style={{
                    width: "100%",
                    height: "88%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      height: "370px",
                      flexDirection: "column",
                      width: "465px",
                    }}
                  >
                    <Autocomplete
                      className="autocomplete"
                      options={bankAccountsData.formattedOptions.sort(
                        (a, b) => {
                          return parseInt(a.value, 10) - parseInt(b.value, 10);
                        }
                      )}
                      getOptionLabel={(option) => option.item}
                      id="bank-name"
                      loading={loadingBankAccounts}
                      style={{ background: "red !important" }}
                      noOptionsText="Nenhum banco encontrado"
                      value={selectedBankAccount}
                      onChange={(_, value) => {
                        setSelectedBankAccount(value);
                        setBankAccount((prev) => ({
                          ...prev,
                          bankName: value?.value
                            ? bankAccountsData.formattedAccounts[value?.value]
                                .name
                            : "",
                          bankCode: value?.value ?? "",
                          ispb: value?.value
                            ? bankAccountsData.formattedAccounts[value?.value]
                                .ispb
                            : "",
                        }));
                      }}
                      disablePortal
                      renderInput={(params) => {
                        return (
                          <StylizedInput
                            {...params}
                            label="Banco"
                            required
                            bankAccount={bankAccount}
                            index={bankAccountDialog.index}
                            style={{ marginBottom: "20px" }}
                            filledState={allFilled}
                          />
                        );
                      }}
                    />
                    {!isCedente() && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <StylizedInput
                          label="Código do Banco"
                          inputName="bankCode"
                          required
                          bankAccount={bankAccount}
                          setter={setBankAccount}
                          index={bankAccountDialog.index}
                          style={{ marginBottom: "20px", width: "90%" }}
                          filledState={allFilled}
                          disabled
                        />

                        <StylizedInput
                          label="ISPB"
                          inputName="ispb"
                          required
                          bankAccount={bankAccount}
                          setter={setBankAccount}
                          index={bankAccountDialog.index}
                          filledState={allFilled}
                          style={{
                            marginBottom: "20px",
                            width: "97%",
                            marginLeft: "30px",
                          }}
                          disabled
                        />
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <StylizedInput
                        label="Agência"
                        inputName="bankBranch"
                        required
                        bankAccount={bankAccount}
                        setter={setBankAccount}
                        index={bankAccountDialog.index}
                        filledState={allFilled}
                        style={{ marginBottom: "20px", width: "90%" }}
                        disabled={
                          (bankAccount.payrollAccountId !== undefined &&
                            Number(bankAccount.accountTypeId) === 1) ||
                          (!isAdmin() &&
                            Number(bankAccount.accountTypeId) === 1) ||
                          !canSelfUpdate(business.statusId)
                        }
                      />

                      <StylizedInput
                        label="Conta"
                        inputName="accountNumber"
                        required
                        bankAccount={bankAccount}
                        setter={setBankAccount}
                        index={bankAccountDialog.index}
                        filledState={allFilled}
                        style={{
                          marginBottom: "20px",
                          width: "97%",
                          marginLeft: "30px",
                        }}
                        disabled={
                          (bankAccount.payrollAccountId !== undefined &&
                            Number(bankAccount.accountTypeId) === 1) ||
                          (!isAdmin() &&
                            Number(bankAccount.accountTypeId) === 1) ||
                          !canSelfUpdate(business.statusId)
                        }
                        inputProps={{
                          maxLength: 14,
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <StylizedInput
                        label="Dígito"
                        inputName="accountDigit"
                        required
                        bankAccount={bankAccount}
                        setter={setBankAccount}
                        index={bankAccountDialog.index}
                        filledState={allFilled}
                        style={{ marginBottom: "20px", width: "90%" }}
                        disabled={
                          (bankAccount.payrollAccountId !== undefined &&
                            Number(bankAccount.accountTypeId) === 1) ||
                          (!isAdmin() &&
                            Number(bankAccount.accountTypeId) === 1) ||
                          !canSelfUpdate(business.statusId)
                        }
                      />
                      <FormControl
                        variant="outlined"
                        style={{ marginBottom: "20px", width: "50%" }}
                      >
                        <InputLabel
                          id="default-bank-account"
                          style={{
                            background: "white",
                            paddingRight: 20,
                            paddingLeft: 5,
                            marginLeft: -5,
                          }}
                        >
                          Conta padrão *
                        </InputLabel>

                        <Select
                          labelId="default-bank-account"
                          id="demo-simple-selecta"
                          value={bankAccount.isDefault}
                          disabled={!isAdmin()}
                          fullWidth
                          styl
                          onChange={(e) => {
                            setBankAccount((prev) => ({
                              ...prev,
                              isDefault: e.target.value,
                            }));
                          }}
                        >
                          <MenuItem style={{ fontSize: ".9em" }} value={true}>
                            Sim
                          </MenuItem>
                          <MenuItem style={{ fontSize: ".9em" }} value={false}>
                            Não
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div
                      className="d-flex flex-row justify-content-between align-items-center mb-5"
                      style={{ width: "100%" }}
                    >
                      {Number(bankAccount.accountTypeId) === 1 && (
                        <FormControl
                          style={{ width: "100%" }}
                          variant="outlined"
                        >
                          <InputLabel
                            id="demo-simple-select-label"
                            color="secondary"
                            className=""
                            style={{
                              backgroundColor: "white",
                              marginTop: ".1%",
                              marginLeft: "-.1%",
                              paddingLeft: 4,
                              paddingRight: 32,
                            }}
                          >
                            Status*
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={Number(bankAccount.statusId)}
                            color="secondary"
                            disabled={!isAdmin()}
                            // error={ numberAttributeIsInvalid(related?.maritalStatusId, 0) && !allFilled}
                            onChange={(e) => {
                              const newState = {
                                ...bankAccount,
                                statusId: e.target.value,
                                statusName:
                                  Number(e.target.value) === 1
                                    ? "LIBERADA"
                                    : Number(e.target.value) === 2
                                    ? "BLOQUEADA"
                                    : Number(e.target.value) === 3
                                    ? "NEGADA"
                                    : Number(e.target.value) === 4
                                    ? "ABERTA"
                                    : "",
                              };
                              setBankAccount(newState);
                            }}
                          >
                            <MenuItem style={{ fontSize: ".9em" }} value={0} />
                            <MenuItem style={{ fontSize: ".9em" }} value={1}>
                              Liberada
                            </MenuItem>
                            <MenuItem style={{ fontSize: ".9em" }} value={2}>
                              Bloqueada
                            </MenuItem>
                            <MenuItem style={{ fontSize: ".9em" }} value={3}>
                              Negada
                            </MenuItem>
                            <MenuItem style={{ fontSize: ".9em" }} value={4}>
                              Aberta
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </div>
                  </div>

                  {Number(bankAccount.accountTypeId) === 1 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "465px",
                      }}
                    />
                  )}
                  {updated ? (
                    <div className="d-flex flex-column">
                      <ReactIf
                        condition={
                          isAdmin() ||
                          (!isAdmin() &&
                            Number(bankAccount.accountTypeId) === 2)
                        }
                      >
                        <ReactThen>
                          <div
                            style={{
                              position: "relative",
                              alignSelf: "flex-end",
                            }}
                          >
                            <Tooltip
                              placement="left"
                              title="Salvo!"
                              style={{
                                borderRadius: "50%",
                                width: 56,
                                height: "auto",
                                backgroundColor: "#373A44",
                                padding: 16,
                                color: "#fff",
                                alignSelf: "flex-end",
                                boxShadow: "1px 3px 5px #888",
                                cursor: "pointer",
                                transitionDuration: 1,
                                marginTop: "20px",
                              }}
                            >
                              <IconButton
                                color="primary"
                                size="medium"
                                variant="outlined"
                                className="text-green"
                              >
                                <CheckOutlinedIcon
                                  style={{ color: "#45B465" }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </ReactThen>
                      </ReactIf>
                    </div>
                  ) : canSelfUpdate(business.statusId) ? (
                    <div className="d-flex flex-column">
                      <ReactIf
                        condition={
                          isAdmin() ||
                          (!isAdmin() &&
                            Number(bankAccount.accountTypeId) === 2)
                        }
                      >
                        <ReactThen>
                          <div
                            style={{
                              position: "relative",
                              alignSelf: "flex-end",
                            }}
                          >
                            <Tooltip
                              placement="left"
                              title="Salvar"
                              style={{
                                borderRadius: "50%",
                                width: 56,
                                height: "auto",
                                backgroundColor: "#373A44",
                                padding: 16,
                                color: "#fff",

                                boxShadow: "1px 3px 5px #888",
                                cursor: "pointer",
                                marginTop: 20,
                              }}
                            >
                              <IconButton
                                color="primary"
                                size="medium"
                                variant="outlined"
                                className="text-white"
                                onClick={() => handleUpdateAccount()}
                              >
                                <CheckOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                            <ReactIf condition={updatingBankAccount}>
                              <ReactThen>
                                <CircularProgress
                                  size={64}
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: -22,
                                    marginLeft: -32,
                                    color: "primary",
                                  }}
                                />
                              </ReactThen>
                            </ReactIf>
                          </div>
                        </ReactThen>
                      </ReactIf>
                    </div>
                  ) : (
                    <div />
                  )}
                </TabPanel>
              </div>
            </Grid>
          </Grid>
        )}
      </Dialog>
    </>
  );
};

export default BankAccountFocus;
