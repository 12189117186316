import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import { useTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandMore from '@material-ui/icons/ExpandMore';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Alert } from '@material-ui/lab';
import isAfter from 'date-fns/isAfter';
import { useMaturitys } from 'hooks/useMaturitys';
import React, { useCallback, useEffect, useState } from 'react';
import CurrencyInput from 'react-currency-input';
import formatDate from 'utils/formatDate';
import BoltIcon from '../../icons/Bolt';
import { getMainColorLight } from '../../Search';
import './styles.scss';

export default function DarOferta({
  abdicado,
  calcularRisco,
  calcularTituloCet,
  cnpj,
  darLance,
  desagio,
  diminuirOferta,
  formatReal,
  fraseErro,
  handleChange,
  ofertaAtual,
  ofertaIgual,
  subirOferta,
  titulo,
  buyTitleAbdicated,
}) {
  const [initialValues] = useState({
    bidDiscountValue: ofertaAtual.bidDiscountValue,
    bidDiscountPerct: ofertaAtual.bidDiscountPerct
      ?.toFixed(3)
      .replace('.', ','),
    bidNetValue: formatReal(
      parseFloat(titulo.invoice.bidNetValueLastOffer).toFixed(2)
    ),
  });

  const [valor, setValor] = useState(0);
  const [valorFloat, setValorFloat] = useState(0);

  useEffect(() => {
    if (desagio) {
      setValor(desagio);
      setValorFloat(desagio);
    }
  }, [desagio]);

  const theme = useTheme();
  const mainColorLight = getMainColorLight(theme.palette.primary.main, 0.2);

  const { maturitys } = useMaturitys(titulo?.invoice?.invoiceId);

  const maturitysSorted = maturitys?.sort((a, b) => {
    const dateA = new Date(a.originalDate);
    const dateB = new Date(b.originalDate);

    if (isAfter(dateA, dateB)) {
      return 1;
    } else if (isAfter(dateB, dateA)) {
      return -1;
    } else {
      return 0;
    }
  });

  const handleChangeValue = (newValue) => {
    handleChange(newValue);
    calcularTituloCet(newValue);
  };

  const handleSubirOferta = () => {
    const newValue = valorFloat + 0.05;

    setValorFloat(newValue);
    setValor(newValue);

    subirOferta(newValue);
  };

  const handleDiminuirOferta = () => {
    const newValue = valorFloat - 0.05;

    if (newValue >= 0) {
      setValorFloat(newValue);
      setValor(newValue);

      diminuirOferta(newValue);
    }
  };

  const showLabelCET = useCallback(
    (bidDiscountValue) => {
      if (titulo.invoice.abdicado === '1') {
        return 'CET ao mês (Taxa fixa)';
      }

      if (bidDiscountValue) {
        return 'CET ao mês';
      }

      return 'CET ao mês (Taxa máxima)';
    },
    [titulo]
  );

  return (
    <>
      {fraseErro ? (
        <Alert severity="warning" style={{ marginBottom: 10 }}>
          {fraseErro}
        </Alert>
      ) : null}
      <div className="dar-oferta--container">
        <div
          className="dar-oferta--wrapper dar-oferta--wrapper--left"
          style={{ backgroundColor: '#DDF2D6' }}
        >
          <h2 className="dar-oferta--title">Minha oferta</h2>

          <div className="dar-oferta--items">
            <div className="dar-oferta--item">
              <span>Valor</span>

              <div>
                <span className="number">
                  {formatReal(
                    parseFloat(titulo?.invoice?.invoiceValue)?.toFixed(2)
                  )}
                </span>
              </div>
            </div>

            <div className="dar-oferta--item">
              <span>Desconto</span>

              <div className="number">
                {formatReal(
                  parseFloat(titulo?.invoice?.bidDiscountValue)?.toFixed(2)
                )}
              </div>

              <div className="dar-oferta__item__align-right">
                <span>Total</span>
                <span className="value number">
                  {titulo?.invoice?.bidDiscountPerct
                    ?.toFixed(3)
                    ?.replace('.', ',')}
                  %
                </span>
              </div>
            </div>

            <div className="dar-oferta--item">
              <span>Líquido</span>

              <div>
                <span className="number">
                  {formatReal(
                    parseFloat(titulo?.invoice?.bidNetValue).toFixed(2)
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="dar-oferta--items">
            <div className="dar-oferta--item dar-oferta--item--big">
              <span className="text--with-icon">
                <span>CET ao mês</span>
                <BoltIcon color="#0d8018" size={28} />
              </span>

              <div />

              <div className="dar-oferta__item__align-right">
                <span className="value number bold">
                  {titulo?.invoice?.bidNetFactorOffer
                    ?.toFixed(3)
                    ?.replace('.', ',')}
                  %
                </span>
                <div className="month_align_right">Ao mês*</div>
              </div>
            </div>
          </div>

          <div className="dar-oferta--items">
            <div className="dar-oferta--item">
              <span>Deságio</span>

              <div>
                <span className="number">
                  {formatReal(
                    parseFloat(titulo?.invoice?.bidFactorValue)?.toFixed(2)
                  )}
                </span>
              </div>

              <div>
                <span className="number">
                  {titulo?.invoice?.bidFactorFloat
                    ?.toFixed(3)
                    ?.replace('.', ',')}
                  %
                </span>
              </div>
            </div>

            <div className="dar-oferta--item">
              <span>IOF</span>

              <div>
                <span className="number">
                  {formatReal(
                    parseFloat(titulo?.invoice?.bidIofAmountValue)?.toFixed(2)
                  )}
                </span>
              </div>

              <div>
                <span className="number">
                  {parseFloat(titulo?.invoice?.bidIofTermValue)
                    ?.toFixed(3)
                    ?.replace('.', ',')}
                  %
                </span>
              </div>
            </div>

            <div className="dar-oferta--item">
              <span>Tarifas</span>

              <div>
                <span className="number">
                  {formatReal(
                    parseFloat(titulo?.invoice?.bidOperationValue)?.toFixed(2)
                  )}
                </span>
              </div>

              <div>
                <span className="number">
                  {parseFloat(titulo?.invoice?.bidOperation)
                    ?.toFixed(3)
                    ?.replace('.', ',')}
                  %
                </span>
              </div>
            </div>

            <div className="dar-oferta--item">
              <span>Comissão</span>

              <div>
                <span className="number">
                  {formatReal(
                    parseFloat(titulo?.invoice?.bidCommission)?.toFixed(2)
                  )}
                </span>
              </div>

              <div>
                <span className="number">
                  {parseFloat(titulo?.invoice?.bidCommissionValue)
                    ?.toFixed(3)
                    ?.replace('.', ',')}
                  %
                </span>
              </div>
            </div>
          </div>

          <div className="dar-oferta--bottom">
            <div className="dar-oferta--input-container">
              <div className="dar-oferta--input-wrapper">
                <div className="currency-input-container">
                  <CurrencyInput
                    decimalSeparator=","
                    onChangeEvent={(event, maskedValue, floatValue) => {
                      if (floatValue >= 0) {
                        setValor(maskedValue);
                        setValorFloat(floatValue);
                        handleChangeValue(floatValue);
                      }
                    }}
                    precision={3}
                    disabled={abdicado}
                    thousandSeparator="."
                    value={valor}
                    suffix="%"
                  />
                </div>

                <span>Deságio ao mês</span>
              </div>

              <div className="dar-oferta--input-actions">
                {!abdicado ? (
                  <>
                    <button
                      className="dar-oferta--input--action"
                      onClick={handleSubirOferta}
                      type="button"
                    >
                      <KeyboardArrowUpIcon
                        style={{ color: '#080808', fontSize: 42 }}
                      />
                    </button>

                    <button
                      className="dar-oferta--input--action"
                      onClick={handleDiminuirOferta}
                      type="button"
                    >
                      <KeyboardArrowDownIcon
                        style={{ color: '#080808', fontSize: 42 }}
                      />
                    </button>
                  </>
                ) : null}
              </div>
            </div>

            <div>
              <Tooltip arrow title={fraseErro}>
                <span>
                  <Button
                    variant="contained"
                    disabled={(ofertaIgual && !abdicado) || fraseErro}
                    className="dar-oferta--button"
                    onClick={() => {
                      if (abdicado) {
                        return buyTitleAbdicated(titulo);
                      }

                      return darLance(titulo);
                    }}
                  >
                    {titulo?.invoice?.abdicado === '1'
                      ? 'Arrematar'
                      : 'Dar oferta'}
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>

          <small>
            *considera seu floating de {titulo?.buyer?.floating ?? 0} dias em
            cada vencimento
          </small>
        </div>

        <div className="dar-oferta--wrapper dar-oferta--wrapper--right">
          <div
            className="dar-oferta-wrapper__right__top"
            style={{ backgroundColor: mainColorLight }}
          >
            <h2 className="dar-oferta--title">Oferta atual</h2>

            <div className="dar-oferta--items">
              <div className="dar-oferta--item">
                <span>Valor</span>

                <div>
                  <span className="number">
                    {formatReal(
                      parseFloat(titulo?.invoice?.invoiceValue)?.toFixed(2)
                    )}
                  </span>
                </div>
              </div>

              <div className="dar-oferta--item">
                <span>Desconto</span>

                <div className="number">
                  {initialValues?.bidDiscountValue
                    ? formatReal(initialValues?.bidDiscountValue?.toFixed(2))
                    : '-'}
                </div>

                <div className="dar-oferta__item__align-right">
                  <span>Total</span>
                  <span className="value number">
                    {initialValues?.bidDiscountPerct === '0,000' ||
                    titulo?.invoice?.abdicado === '1'
                      ? '-'
                      : `${initialValues.bidDiscountPerct}%`}
                  </span>
                </div>
              </div>

              <div className="dar-oferta--item">
                <span>Líquido</span>

                <div className="number">{initialValues?.bidNetValue}</div>
              </div>
            </div>

            <div className="dar-oferta--items">
              <div className="dar-oferta--item dar-oferta--item--big">
                <span className="text--with-icon">
                  <span>{showLabelCET(initialValues?.bidDiscountValue)}</span>
                  <BoltIcon color="#F75649" size={28} />
                </span>

                <div />

                <div className="dar-oferta__item__align-right">
                  <span className="value number bold">
                    {titulo?.invoice?.bidNetFactor ||
                    titulo?.invoice?.abdicado === '1'
                      ? `${titulo.invoice.bidNetFactor
                          ?.toFixed(3)
                          ?.replace('.', ',')}%`
                      : titulo?.invoice?.bidMaxFactor
                      ? `${titulo.invoice.bidMaxFactor
                          ?.toFixed(3)
                          ?.replace('.', ',')}%`
                      : `${(10.001).toFixed(3).replace('.', ',')}%`}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div
            className="dar-oferta-wrapper__right__bottom"
            style={{ backgroundColor: '#EFEFEF' }}
          >
            <h2 className="dar-oferta--title">Título</h2>
            <div className="dar-oferta--items">
              <div className="dar-oferta--item between">
                <span>Valor</span>

                <div>
                  <span className="number">
                    {formatReal(
                      parseFloat(titulo?.invoice?.invoiceValue)?.toFixed(2)
                    )}
                  </span>
                </div>
              </div>

              <div className="dar-oferta--item between">
                <span>Prazo médio</span>

                <div>
                  <span className="number">{`${titulo?.invoice?.prazoMedioNog}d`}</span>
                </div>
              </div>

              <div className="dar-oferta-accordion-container">
                <Accordion classes={{ root: 'leilao--accordion--root' }}>
                  <AccordionSummary
                    expandIcon={
                      <ExpandMore style={{ color: '#0d8018', fontSize: 30 }} />
                    }
                    aria-controls="panel1a-content"
                    classes={{
                      root: 'leilao--accordion-summary',
                      expandIcon: 'leilao--accordion-summary-iccon-button',
                    }}
                    id="panel1a-header"
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span style={{ color: '#696969' }}>Parcelas</span>
                      <span style={{ color: '#696969' }}>
                        {titulo.invoice.maturities}x
                      </span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="dar-oferta-table-container">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <span>Data</span>
                            </th>
                            <th>
                              <span>Prazo</span>
                            </th>
                            <th>
                              <span>Valor</span>
                            </th>
                          </tr>
                        </thead>
                        {maturitysSorted?.map((item, index) => (
                          <tbody key={`${item} ${index}`}>
                            <tr>
                              <td>
                                <span>{formatDate(item?.originalDate)}</span>
                              </td>
                              <td>
                                <span>{item?.dias}d</span>
                              </td>
                              <td>
                                <span>{formatReal(item?.valor)}</span>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>

            <div className="dar-oferta--bottom">
              <div className="dar-oferta--info-container">
                <div className="dar-oferta--info">
                  <div
                    className="dar-oferta--info--letter"
                    style={{ color: theme.palette.primary.main }}
                  >
                    S
                  </div>

                  <div className="dar-oferta--info--titles">
                    <Tooltip title={titulo?.sacado?.name}>
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="dar-oferta--info--title"
                      >
                        {titulo?.sacado?.name}
                      </a>
                    </Tooltip>

                    <Tooltip title={titulo?.sacado?.corporateName}>
                      <span className="dar-oferta--info--subtitle">
                        {titulo?.sacado?.corporateName}
                      </span>
                    </Tooltip>

                    <Tooltip title={titulo?.sacado?.countryIdentity}>
                      <span className="dar-oferta--info--subtitle">
                        {cnpj(titulo?.sacado?.countryIdentity)}
                      </span>
                    </Tooltip>
                  </div>
                </div>

                <div className="dar-oferta--info">
                  <div
                    className="dar-oferta--info--letter"
                    style={{ color: '#0d8018' }}
                  >
                    C
                  </div>

                  <div className="dar-oferta--info--titles">
                    <Tooltip title={titulo?.business?.name}>
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="dar-oferta--info--title"
                      >
                        {titulo?.business?.name}
                      </a>
                    </Tooltip>

                    <Tooltip title={titulo?.business?.corporateName}>
                      <span className="dar-oferta--info--subtitle">
                        {titulo?.business?.corporateName}
                      </span>
                    </Tooltip>

                    <Tooltip title={titulo?.business?.countryIdentity}>
                      <span className="dar-oferta--info--subtitle">
                        {cnpj(titulo?.business?.countryIdentity)}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </div>

              <div className="dar-oferta--rating-container">
                <div className="dar-oferta--rating--item">
                  <div className="dar-oferta--rating--item--title">Rating</div>

                  <div
                    className="dar-oferta--rating"
                    style={{
                      backgroundColor: calcularRisco(titulo?.invoice?.riskName),
                    }}
                  >
                    {titulo?.invoice?.riskName}
                  </div>
                </div>

                <div className="dar-oferta--rating--item">
                  <div className="dar-oferta--rating--item--title">Tipo</div>

                  <div className="dar-oferta--item--content">
                    {titulo?.invoice?.typeName}
                  </div>
                </div>

                {titulo?.tags && titulo?.tags.length > 0 ? (
                  <div className="dar-oferta--rating--item">
                    <div className="dar-oferta--rating--item--title" />

                    <div className="dar-oferta--item--content">
                      <Tooltip
                        title={titulo?.tags?.map(
                          (tag, index) =>
                            `${tag.NAME}${
                              index + 1 < titulo.tags.length ? ', ' : ''
                            }`
                        )}
                      >
                        <div className="dar-oferta--tags">
                          <span className="dar-oferta--tags-title">Tags: </span>

                          {titulo?.tags?.map((tag, index) => (
                            <span key={`${tag.NAME} ${index}`}>
                              {tag.NAME}
                              {index + 1 < titulo.tags.length ? ', ' : ''}
                            </span>
                          ))}
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dar-oferta-container-mobile">
        <div>
          <div
            className="oferta-atual-mobile"
            style={{ backgroundColor: '#fafafa' }}
          >
            <h2 className="dar-oferta-title-mobile">Oferta atual</h2>
            <div className="dar-oferta-itemsWrapper">
              <div className="dar-oferta-items-mobile">
                <span>CET am</span>
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  <h2>
                    {titulo?.invoice?.bidNetFactor
                      ? `${titulo.invoice.bidNetFactor
                          ?.toFixed(3)
                          .replace('.', ',')}%`
                      : titulo?.invoice?.bidMaxFactor
                      ? `${titulo.invoice.bidMaxFactor
                          ?.toFixed(3)
                          ?.replace('.', ',')}%`
                      : `${10.001?.toFixed(3)?.replace('.', ',')}%`}
                  </h2>
                  <BoltIcon color="#F75649" size={12} />
                </div>
              </div>

              <div className="dar-oferta-items-mobile">
                <span>Desconto</span>
                <h2>
                  {initialValues?.bidDiscountValue
                    ? formatReal(initialValues?.bidDiscountValue?.toFixed(2))
                    : '-'}
                </h2>
              </div>

              <div className="dar-oferta-items-mobile">
                <span>Total</span>
                <h2>
                  {initialValues?.bidDiscountPerct === '0,000' ||
                  titulo?.invoice?.abdicado === '1'
                    ? '-'
                    : `${initialValues.bidDiscountPerct}%`}
                </h2>
              </div>
            </div>
          </div>

          <div
            className="oferta-atual-mobile"
            style={{ backgroundColor: '#FFF8F2' }}
          >
            <h2 className="dar-oferta-title-mobile">Minha oferta</h2>
            <div className="dar-oferta-itemsWrapper">
              <div className="dar-oferta-items-mobile">
                <span>CET am</span>
                <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                  <h2>
                    {titulo?.invoice?.bidNetFactorOffer
                      ?.toFixed(3)
                      ?.replace('.', ',')}
                    %
                  </h2>
                </div>
              </div>

              <div className="dar-oferta-items-mobile">
                <span>Desconto</span>
                <h2>
                  {formatReal(
                    parseFloat(titulo?.invoice?.bidDiscountValue)?.toFixed(2)
                  )}
                </h2>
              </div>

              <div className="dar-oferta-items-mobile">
                <span>Total</span>
                <h2>
                  {titulo?.invoice?.bidDiscountPerct
                    ?.toFixed(3)
                    ?.replace('.', ',')}
                  %
                </h2>
              </div>
            </div>
          </div>

          <div className="values-wrapper-mobile">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Valor</span>
              <p>
                {formatReal(
                  parseFloat(titulo?.invoice?.invoiceValue)?.toFixed(2)
                )}
              </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>IOF</span>
              <p>
                {parseFloat(titulo?.invoice?.bidIofTermValue)
                  ?.toFixed(3)
                  ?.replace('.', ',')}
                % am /{' '}
                {formatReal(
                  parseFloat(titulo?.invoice?.bidIofAmountValue)?.toFixed(2)
                )}
              </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Comissão</span>
              <p>
                {parseFloat(titulo?.invoice?.bidCommissionValue)
                  ?.toFixed(3)
                  ?.replace('.', ',')}
                % am /{' '}
                {formatReal(
                  parseFloat(titulo?.invoice?.bidCommission).toFixed(2)
                )}
              </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Tarifas</span>
              <p>
                {parseFloat(titulo?.invoice?.bidOperation)
                  ?.toFixed(3)
                  ?.replace('.', ',')}
                % am /{' '}
                {formatReal(
                  parseFloat(titulo?.invoice?.bidOperationValue)?.toFixed(2)
                )}
              </p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Valor líquido</span>
              <p>
                {formatReal(
                  parseFloat(titulo?.invoice?.bidNetValue)?.toFixed(2)
                )}
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className="dar-oferta--input-container-mobile">
            <div className="dar-oferta--input-wrapper-mobile">
              <div className="currency-input-container-mobile">
                <CurrencyInput
                  decimalSeparator=","
                  onChangeEvent={(event, maskedValue, floatValue) => {
                    if (floatValue >= 0) {
                      setValor(maskedValue);
                      setValorFloat(floatValue);
                      handleChangeValue(floatValue);
                    }
                  }}
                  precision={3}
                  disabled={abdicado}
                  thousandSeparator="."
                  value={valor}
                  suffix="%"
                />
              </div>

              <span>Deságio ao mês</span>
            </div>

            <div className="dar-oferta--input-actions-mobile">
              {!abdicado ? (
                <>
                  <button
                    className="dar-oferta--input--action-mobile"
                    onClick={handleSubirOferta}
                    type="button"
                  >
                    <KeyboardArrowUpIcon
                      style={{ color: '#080808', fontSize: 42 }}
                    />
                  </button>

                  <button
                    className="dar-oferta--input--action-mobile"
                    onClick={handleDiminuirOferta}
                    type="button"
                  >
                    <KeyboardArrowDownIcon
                      style={{ color: '#080808', fontSize: 42 }}
                    />
                  </button>
                </>
              ) : null}
            </div>
          </div>

          <div className="dar-oferta--disclaimer-mobile">
            <small>
              *considera seu floating de {titulo?.buyer?.floating ?? 0} dias em
              cada vencimento
            </small>
          </div>

          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Tooltip arrow title={fraseErro}>
              <span>
                <Button
                  variant="contained"
                  disabled={(ofertaIgual && !abdicado) || fraseErro}
                  className="dar-oferta--button-mobile"
                  onClick={() => {
                    if (abdicado) {
                      return buyTitleAbdicated(titulo);
                    }

                    return darLance(titulo);
                  }}
                >
                  {titulo?.invoice?.abdicado === '1'
                    ? 'Arrematar'
                    : 'Dar oferta'}
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
}
