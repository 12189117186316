import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { ThemeProvider } from "@material-ui/styles";

import { AnimatePresence } from "framer-motion";
import { LeftSidebar } from "layout-blueprints";
import AgendaRecebiveis from "pages/AgendaRecebiveis";
import CadastroFinanciadorCedente from "pages/CadastroFinaciadorCedente";
import CadastroTitulo from "pages/CadastroTitulo";
import Extrato from "pages/ContaConsignada/Extrato";
import HomeDashboard from "pages/HomeDashboard";
import ListagemTitulos from "pages/ListagemTitulos";
import MenuUsuarios from "pages/MenuUsuarios";
import PageError404 from "pages/PagesError404";
import { SellerTheme } from "theme";

import Terms from "components/Terms";

import DialogProvider from "contexts/dialog";
import DadosCadastrais from "pages/DadosCadastrais";

const SignRoutes = ({ tema }) => (
  <AnimatePresence>
    <ThemeProvider theme={SellerTheme(tema?.primary, tema?.secondary)}>
      <DialogProvider>
        <BrowserRouter>
          <Terms />
          <LeftSidebar>
            <Switch>
              <Route path="/" exact component={HomeDashboard} />
              <Redirect from="/capa" to="/" />
              <Route path="/cadastro" component={CadastroFinanciadorCedente} />
              <Route path="/invoices" component={ListagemTitulos} />
              <Route path="/invoice/:invoiceId" component={CadastroTitulo} />
              <Route path="/users" component={MenuUsuarios} />
              <Route path="/extrato" component={Extrato} />
              <Route path="/agenda-recebiveis" component={AgendaRecebiveis} />
              <Route component={PageError404} />
            </Switch>
          </LeftSidebar>
        </BrowserRouter>
      </DialogProvider>
    </ThemeProvider>
  </AnimatePresence>
);

export default SignRoutes;
