import { useEffect, useState } from "react";
import { useParams } from "react-router";

import "./invoice.scss";

import { Dialog, Modal } from "@material-ui/core";

import AvaliarRisco from "components/AvaliarRisco";
import { ConfirmButton } from "components/Button";
import Lance from "components/Lance";
import { PropostaSerpro } from "components/PropostaSerpro";
import { Recebiveis } from "components/Recebiveis";

import { getBusinessByBusinessId } from "services/business";
import {
  updateProductType,
  updateStatusInvoice,
  updatemaxOffer,
} from "services/invoice";
import { emailTituloCancelado } from "services/sendgrid";

import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";

import Vencimentos from "../../../components/Vencimentos";
import { InvoiceForm } from "./invoiceForm";

const canShowBuyerStatusesConditions = ["Vendido", "Título Pago"];

export default function InvoiceRegister(props) {
  const { invoiceData, setInvoiceData, invoiceBids } = props;

  const { user, isAdmin, isCedente, isFinanciador } = useAuth();
  const { toast } = useToast();
  const { invoiceId } = useParams();

  const [invoice, setInvoice] = useState({});
  const [seller, setSeller] = useState({});
  const [buyer, setBuyer] = useState({});
  const [bid, setBid] = useState(undefined);
  const [showBuyer, setShowBuyer] = useState(false);
  const [values, setValues] = useState([]);
  const [openPropostaSerproModal, setOpenPropostaSerproModal] = useState(false);

  useEffect(() => {
    loadInvoice();
  }, []);

  async function loadInvoice() {
    const invoiceStatus = invoiceData.statusName;
    handleShowBuyer(invoiceStatus);

    setInvoice(invoiceData);
    setValues(JSON.parse(invoiceData.valorRecebiveis));

    if (invoiceBids.length > 0) {
      const buyerBid = invoiceBids.find(
        (status) => status.statusName == "Melhor Oferta"
      );

      const parsedBuyerBid = buyerBid?.buyer;
      setBuyer(parsedBuyerBid);
    }
    if (invoiceData.invoiceSeller) setSeller(invoiceData.invoiceSeller);
    if (
      invoiceData.invoiceBuyer &&
      invoiceData.invoiceBuyer.invoiceBuyerBusiness
    )
      setBuyer(invoiceData.invoiceBuyer.invoiceBuyerBusiness);
    if (invoiceData && invoiceBids) {
      const invoiceBid = invoiceBids.find(
        (bid) => bid.bidId === invoiceData.bidId
      );
      if (invoiceBid) {
        setBid(invoiceBid);
      }
    }
  }
  function handleShowBuyer(invoiceStatus) {
    if (!invoiceStatus) return;
    const canShowBuyer = canShowBuyerStatusesConditions.some(
      (status) => invoiceStatus === status
    );
    if (canShowBuyer) {
      setShowBuyer(true);
    }
  }

  const handleChangeInvoiceStatus = async (event, data) => {
    const statusId = event.target.value;
    const statusName = rangeObjects[0].select.find(
      (item) => item.key === statusId
    ).name;

    try {
      await toast.promise(
        updateStatusInvoice(statusId, statusName, invoiceId, user.userId),
        {
          loading: "Alterando status do título",
          success: "Status alterado com sucesso!",
          error: "Erro ao alterar status",
        }
      );
      if (statusId == 7) {
        await emailTituloCancelado({
          empresa: invoice.originType,
          publico: buyer.typeId,
          email_destinatario: buyer.email,
          nome_cedente: seller.name,
          nome_financiador: buyer.name,
          numeroNF: invoice.id,
          data_registro_titulo: invoice.registerDate,
          valor_parcelas: invoice.maturitiesValue,
        });
      }
      setInvoiceData({ ...data, statusId, statusName });
      window.location.reload();
    } catch (error) {
      setInvoice({ ...data });
    }
  };

  const handleChangeProductType = async (event, data) => {
    const productId = event.target.value;
    const productName = rangeObjects[2].select.find(
      (item) => item.key === productId
    ).name;

    try {
      await toast.promise(updateProductType(productName, invoiceId), {
        loading: "Alterando tipo de produto",
        success: "Tipo de produto alterado com sucesso!",
        error: "Erro ao alterar tipo de produto",
      });
      setInvoiceData({ ...data, typeProduct: productName });
      window.location.reload();
    } catch (error) {
      setInvoice({ ...data });
    }
  };

  const handleChangemaxOffer = async (event, data) => {
    try {
      await updatemaxOffer(event.target.value, invoiceId);
      setInvoiceData({ ...data, maxOffer: event.target.value });
    } catch (error) {
      setInvoice({ ...data });
    }
  };

  const handlerChange = (event, data, key, setMethod) => {
    setMethod({ ...data, [key]: event.target.value });
  };

  const rangeObjects = [
    {
      label: "Status",
      key: "statusName;statusId",
      select: [
        { key: 1, name: "Cadastro Pendente" },
        { key: 3, name: "Aguardando Ação do Usuário" },
        { key: 4, name: "Aguardando Lance" },
        { key: 6, name: "Negado" },
        { key: 7, name: "Cancelado" },
        { key: 7, name: "Cancelado (Título Vencido)" },
        { key: 8, name: "Vendido" },
        { key: 9, name: "Aceitar Lance" },
        { key: 11, name: "Título Pago" },
        { key: 12, name: "Proposta em Criação" },
      ],
      isAdmin: isAdmin(),
      handler: handleChangeInvoiceStatus,
    },
    {
      label: "Tipo de Nota",
      key: "typeName;typeId",
      select: [
        { key: 1, name: "Serviços" },
        { key: 2, name: "Produtos" },
        { key: 3, name: "Precatório" },
        { key: 6, name: "Transporte (CTe)" },
      ],
    },
    {
      label: "Tipo de Produto",
      key: "typeProduct;typeProduct",
      select: [
        { key: "Duplicata", name: "Duplicata" },
        { key: "Conta Garantida", name: "Conta Garantida" },
        { key: "Limpa Balanço", name: "Limpa Balanço" },
        { key: "AntecipaGOV", name: "AntecipaGOV" },
        { key: "Antecipa Agro", name: "Antecipa Agro" },
        { key: "Antecipa Carteira", name: "Antecipa Carteira" },
        { key: "Trava Perfeita", name: "Trava Perfeita" },
        { key: "Compror", name: "Compror" },
        { key: "Confirme", name: "Confirme" },
      ],
      isAdmin: isAdmin(),
      handler: handleChangeProductType,
    },
    { label: "Data de inclusão", key: "registerDate", isDate: true },
    { label: "Lance aceito em:", key: "bidStatusDate", isDate: true },
    {
      label: "Tipo de Mercado",
      key: "marketTypeName;marketTypeId",
      select: [
        { key: 1, name: "Leilão" },
        { key: 2, name: "Balcão" },
      ],
    },

    {
      label: "Taxa máxima",
      key: "maxOffer;maxOffer",
      number: true,
      isAdmin: isAdmin(),
      handler: handleChangemaxOffer,
    },
  ];

  const rangeObjectsSeller = [
    {
      label: "CNPJ",
      key: "countryIdentity",
    },
    {
      label: "Razão Social",
      key: "corporateName",
    },
    {
      label: "Nome Fantasia",
      key: "name",
    },
    {
      label: "Telefone",
      key: "officePhone",
      phone: true,
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Cliente desde",
      key: "registerDate",
      isDate: true,
    },
  ];

  const rangeBuyer = [
    { label: "CNPJ", key: "countryIdentity" },
    { label: "Razão Social", key: "corporateName" },
    { label: "Nome Empresa", key: "name" },
    { label: "Email", key: "email" },
    { label: "Telefone", key: "officePhone", phone: true },
    { label: "Site", key: "site" },
  ];

  const rangeBid = [
    { label: "Data de Lance", key: "bidDate", isDate: true },
    { label: "Fator (CET)", key: "bidNetFactor", percentage: true },
    { label: "Valor dos Vencimentos", key: "bidMaturitiesValue", real: true },
    { label: "Valor Líquido", key: "bidNetValue", real: true },
    { label: "Status", key: "statusName" },
  ];

  return (
    <>
      <div className="formsGroup">
        <InvoiceForm
          disabled
          title="Títulos"
          rangeObjects={rangeObjects.filter((object) =>
            invoice.statusId !== 8 ? object.key !== "bidStatusDate" : true
          )}
          data={invoice}
          handler={handlerChange}
          setMethod={setInvoice}
        />
        {isFinanciador() ? (
          <Recebiveis
            typeId={404}
            businessId={invoiceData.businessId}
            invoiceView={invoiceId}
            listaIdRecebiveis={JSON.parse(invoiceData.recebiveis)}
            style={{ marginBottom: 30 }}
            title="Direitos Creditórios"
            agendaValues={values}
          />
        ) : isCedente() ? (
          <Recebiveis
            typeId={404}
            businessId={invoiceData.businessId}
            invoiceView={invoiceId}
            listaIdRecebiveis={JSON.parse(invoiceData.recebiveis)}
            style={{ marginBottom: 30 }}
            title="Direitos Creditórios"
            agendaValues={values}
            esconderValorComprometido
            esconderSacado
            esconderDataVencimento
            esconderRisco
            esconderPorcentagem
            esconderStatus
          />
        ) : isAdmin() ? (
          <Recebiveis
            typeId={404}
            businessId={invoiceData.businessId}
            invoiceView={invoiceId}
            listaIdRecebiveis={JSON.parse(invoiceData.recebiveis)}
            style={{ marginBottom: 30 }}
            title="Direitos Creditórios"
            agendaValues={values}
            esconderStatus
          />
        ) : null}
        {seller && seller !== null && !isCedente() && (
          <InvoiceForm
            disabled
            title="Cedente"
            rangeObjects={rangeObjectsSeller}
            data={seller}
            handler={handlerChange}
            setMethod={setSeller}
          />
        )}
        {buyer && buyer !== null && !isFinanciador() && showBuyer && (
          <InvoiceForm
            disabled
            title="Financiador"
            rangeObjects={rangeBuyer}
            data={buyer}
            handler={handlerChange}
            setMethod={setBuyer}
          />
        )}
        {/* <Maturities /> */}
        <Vencimentos invoiceId={invoiceId} invoice={invoice} bid={bid} />
        {user.userTypeId == 3 && <AvaliarRisco />}
        {bid && (
          <Lance
            disabled
            title="Lance"
            rangeObjects={rangeBid}
            data={bid}
            invoiceId={bid.invoiceId}
            invoice={invoice}
            buyerId={bid.buyerId}
            seller={seller}
          />
        )}
        {invoice.typeId == 4 && (
          <ConfirmButton
            onClick={() => setOpenPropostaSerproModal((old) => !old)}
          >
            Enviar Proposta Serpro
          </ConfirmButton>
        )}
      </div>

      <Dialog
        onClose={() => setOpenPropostaSerproModal(false)}
        aria-labelledby="simple-dialog-title"
        open={openPropostaSerproModal}
        scroll="body"
        maxWidth="xl"
      >
        <Modal
          id="customized-dialog-title"
          onClose={() => setOpenPropostaSerproModal(false)}
        />

        <div
          style={{
            display: "flex",
          }}
        >
          {invoice && (
            <PropostaSerpro
              invoiceId={invoiceId}
              isSerpro={invoice.typeId == 4}
            />
          )}
        </div>
      </Dialog>
    </>
  );
}
