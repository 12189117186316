import { useEffect, useState } from 'react';

import { riscoList, statusList } from 'components/Recebiveis/items';

import { removerCaracteresEspeciais } from 'utils/removerCaracteresEspeciais';

import {
  getAgendaById,
  getDocumentsById,
  getTagsAgendaService,
  saveTagsAgendaService,
  updateRisk,
  updateStatusAndNote,
} from 'services/agenda';
import { getAllTagsServices } from 'services/tags-services';

import { useAuth } from 'contexts/auth';
import { useToast } from 'contexts/toast';

import { useModal } from './useModal';

export function useRecebivel({ edit, agendaId }) {
  const { toast } = useToast();
  const { openModalTextInput, openModal } = useModal();
  const { isAdmin, isCedente, isFinanciador } = useAuth();

  const [agenda, setAgenda] = useState(null);
  const [agendaDocs, setAgendaDocs] = useState(null);
  const [agendaTags, setAgendaTags] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [isLoadingRecebivel, setIsLoadingRecebivel] = useState(false);

  useEffect(() => {
    if (!agendaId) return;

    handleLoadAgenda(agendaId);
  }, [agendaId]);

  async function handleLoadAgenda(agendaId) {
    try {
      setIsLoadingRecebivel(true);

      // handling load agenda
      toast.loading('Carregando agenda', { id: 'loading-agenda' });
      const agendaFromApi = await getAgendaById(agendaId);

      // handling load documents
      toast.loading('Carregando documentos', { id: 'loading-documentos' });
      const documentsFromApi = await getDocumentsById(agendaId);

      // handling load tags
      toast.loading('Carregando tags', { id: 'loading-tags' });
      await getAllTags(agendaId);

      // success message
      toast.success('Dados carregados');

      // dismissing toasts
      toast.dismiss('loading-agenda');
      toast.dismiss('loading-documentos');
      toast.dismiss('loading-tags');

      setAgendaDocs(documentsFromApi);
      setAgenda(agendaFromApi);
    } catch (error) {
      toast.error(error.message, { id: 'error' });
      toast.dismiss('loading-agenda');
      toast.dismiss('loading-documentos');
    } finally {
      setIsLoadingRecebivel(false);
    }
  }

  async function getAllTags() {
    const { data: allTagsFromApi } = await getAllTagsServices();
    const tagsFromAgenda = await getTagsAgendaService(agendaId);

    // handle selected tags
    const selectedTags = allTagsFromApi.filter((apiTag) =>
      tagsFromAgenda.some((agendaTag) => apiTag.tagId == agendaTag.tag_id)
    );

    setAgendaTags(selectedTags);
    setAllTags(allTagsFromApi);
  }

  async function handleSendAgendaTags() {
    try {
      toast.loading('Alterando tags...', { id: 'save-tags' });
      await saveTagsAgendaService(agendaId, agendaTags);
      toast.success('Tags alteradas', { id: 'save-tags' });
    } catch (error) {
      toast.error('Erro ao alterar tags', { id: 'save-tags' });
    }
  }

  async function isTitulo(agendaValues) {
    if (agendaValues && isFinanciador()) return true;
    return false;
  }

  async function refreshRecebivel() {
    await handleLoadAgenda(agendaId);
  }

  function statusesThatNeedToInputANoteTogether() {
    return statusList.filter(
      (status) =>
        status.value == 'Aguardando Documentacao' || status.value == 'Reprovado'
    );
  }

  function getStatusByValue(statusValue) {
    return statusList.find((status) => status.value == statusValue);
  }

  function canApproveAndCreateInvoice({
    statusName,
    tag: aprovarECriarTitulo,
  }) {
    const status = getStatusByValue(statusName);
    if (!isAdmin()) return false;
    if (!aprovarECriarTitulo) return false;

    // Aguardando Validação
    if (status.id != 2) return false;

    return true;
  }

  function canEditAvailableValuePercent({ statusName }) {
    if (!edit) return false;
    if (!isAdmin()) return false;

    const status = getStatusByValue(statusName);

    // Aguardando validação
    if (status.id != 2) return false;

    return true;
  }

  function canEditScheduling({ statusName }) {
    if (!edit) return false;
    const status = getStatusByValue(statusName);

    // Aguardando Ação do Usuário
    if (status.id == 1) return true;

    return false;
  }

  function canEditDocuments({ statusName }) {
    if (!edit) return false;
    // sanatizing statusName
    statusName = removerCaracteresEspeciais(statusName);

    if (isAdmin()) return true;
    const status = getStatusByValue(statusName);

    if (!status) return false;

    // Aguardando Ação do Usuário e Aguardando Documentos
    if (status.id == 1 || status.id == 3) return true;

    return false;
  }
  // dossie write permission validation
  function canEditDossie() {
    if (!edit) return false;
    if (isAdmin()) return true;

    return false;
  }

  function canEditRisco() {
    if (!edit) return false;
    if (!isAdmin()) return false;

    return true;
  }

  function canEditTags() {
    if (!isAdmin()) return false;

    return true;
  }

  function canReadDocuments() {
    return !isFinanciador();
  }

  function canReadDossie() {
    return !isCedente();
  }

  function canReadTags() {
    if (!isAdmin()) return false;

    return true;
  }

  function canReadStatus() {
    return !isFinanciador();
  }
  function canReadAvailableValue() {
    return !isFinanciador();
  }
  function canReadPercentAvailableValue() {
    return !isFinanciador() && !isCedente();
  }
  function canReadBalance() {
    return !isFinanciador();
  }
  function canReadMaturitiesValue() {
    return true;
  }
  function canReadCommitedValue() {
    return isCedente();
  }
  function canReadSacado() {
    const all = isCedente() || isFinanciador() || isAdmin();
    return all;
  }
  function canReadDataVencimento() {
    const all = isCedente() || isFinanciador() || isAdmin();
    return all;
  }
  function canReadRisco() {
    return !isCedente() || !isAdmin();
  }

  function canReadDetalhes() {
    return true;
  }

  function canCedenteEditStatus(status) {
    // Aguardando Ação do Usuário e Aguardando Documentos
    if (status.id == 1 || status.id == 3) return true;
    return false;
  }

  async function handleUpdateStatusAndNote(
    { agendaId, status, refreshRecebivelExt },
    note = undefined
  ) {
    await toast.promise(updateStatusAndNote({ agendaId, status }, note), {
      loading: 'Atualizando status',
      success: 'Status atualizado',
      error: 'Erro ao atualizar status',
    });

    await refreshRecebivelExt();
  }

  async function handleStatusChange({
    agendaId,
    value,
    // setNote,
    // setStatusName,
    refreshRecebivelExt,
  }) {
    const isValueInsideStatusList = statusList.some(
      (status) => status.value == value
    );
    const doesItNeesToInputANote = statusesThatNeedToInputANoteTogether().some(
      (status) => status.value == value
    );
    const statusInsideStatusList = getStatusByValue(value);

    if (!isValueInsideStatusList) return toast('Valor Inválido');

    if (doesItNeesToInputANote)
      await openModalTextInput(
        'textField',
        'Insira uma notificação',
        'Notificar',
        'Cancelar',
        handleUpdateStatusAndNote,
        {
          agendaId,
          status: statusInsideStatusList,
          //updateNote: setNote,
          refreshRecebivelExt,
        }
      );

    if (!doesItNeesToInputANote)
      await handleUpdateStatusAndNote({
        agendaId,
        status: statusInsideStatusList,
        //updateNote: setNote,
        refreshRecebivelExt,
      });
  }

  function verifyPermissiontoShowStatusNote({ statusName }) {
    const status = getStatusByValue(statusName);

    return status.id == 3 || status.id == 6;
  }

  function statusIdEqualsToWaitingValidation({ statusId }) {
    if (isAdmin()) return true;
    if (!isCedente()) return false;

    // Aguardando Validação
    if (statusId == 2) return true;
    return false;
  }

  function verifyPermissionToEditStatus({ statusName }) {
    if (!edit) return false;
    if (isAdmin()) return true;
    if (!isCedente()) return false;

    // cedente permission validation
    const status = getStatusByValue(statusName);
    if (!canCedenteEditStatus(status)) return false;

    return true;
  }

  function getRiscoById(risco_name) {
    return riscoList.find((risco) => risco.value == risco_name);
  }

  async function handleUpdateRisco({ value, agendaId, updateState }) {
    if (!edit) return;
    if (!isAdmin()) return;
    const risco = getRiscoById(value);

    await openModal(
      'warning',
      `Tem certeza ?`,
      `Essa ação irá salvar a agenda com o risco: ${risco.value}`,
      updateRisk,
      {
        agendaId,
        riskId: risco.id,
        riskName: risco.value,
        updateState,
      }
    );
  }

  return {
    statusesThatNeedToInputANoteTogether,
    getStatusByValue,
    canCedenteEditStatus,
    handleUpdateStatusAndNote,
    handleStatusChange,
    verifyPermissiontoShowStatusNote,
    statusIdEqualsToWaitingValidation,
    verifyPermissionToEditStatus,
    handleUpdateRisco,
    agenda,
    agendaDocs,
    setAgendaDocs,
    refreshRecebivel,
    // tags
    getAllTags,
    allTags,
    agendaTags,
    selectAgendaTag: setAgendaTags,
    handleSendAgendaTags,
    // read permissions
    canReadDossie,
    canReadDocuments,
    canReadTags,
    canReadStatus,
    canReadAvailableValue,
    canReadPercentAvailableValue,
    canReadBalance,
    canReadMaturitiesValue,
    canReadCommitedValue,
    canReadSacado,
    canReadDataVencimento,
    canReadRisco,
    canReadDetalhes,
    isTitulo,
    // write permissions
    canApproveAndCreateInvoice,
    canEditAvailableValuePercent,
    canEditDocuments,
    canEditDossie,
    canEditTags,
    canEditRisco,
    canEditScheduling,
    isLoadingRecebivel,
  };
}
