import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export function loadingSwal(message = 'Aguarde, por favor') {
  return MySwal.fire({
    title: message,
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });
}

export function successMessageSwal(title, text) {
  return MySwal.fire({
    title,
    showConfirmButton: false,
    icon: 'success',
    text,
  });
}

export function messageSwal(title, text) {
  return MySwal.fire({
    title,
    text,
  });
}

export function confirmationSwal(title, text, hardWarning = false) {
  return MySwal.fire({
    title: hardWarning
      ? `<h1 style='color:#fff; text-align: center'>${title}</h5>`
      : title,
    background: hardWarning ? '#414a4c' : '',
    color: hardWarning ? '#fff' : '',
    confirmButtonColor: hardWarning ? '#4caf50' : '',
    cancelButtonColor: hardWarning ? '#b81a37' : '',
    iconColor: hardWarning ? '#b81a37' : '',
    showConfirmButton: true,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
    reverseButtons: true,
    showCancelButton: true,
    icon: 'warning',
    text,
    html: hardWarning ? `<h2 style='color: #fff'>${text}</h2>` : false,
  });
}

export function showComponentMessageSwal(title, component, customClass = null) {
  return MySwal.fire({
    title,
    html: component,
    showCancelButton: false,
    customClass,
    confirmButtonText: 'Fechar',
  });
}

export function emailSwal(
  title = 'Informe seu e-mail',
  placeholder = 'Insira seu e-mail'
) {
  return MySwal.fire({
    title,
    input: 'email',
    confirmButtonText: 'Recuperar',
    validationMessage: 'E-mail inválido',
    inputPlaceholder: placeholder,
  });
}

export function codeConfirmation(
  title = 'Informe o código recebido pelo e-amail',
  placeholder = 'Código de ativação'
) {
  return MySwal.fire({
    title,
    input: 'text',
    confirmButtonText: 'Confirmar',
    showDenyButton: true,
    showCancelButton: true,
    cancelButtonText: `Não recebi, quero reenviar.`,
    inputPlaceholder: placeholder,
    denyButtonText: `Não recebi, quero reenviar.`,
    inputValidator: (result) =>
      !result && 'Para confirmar insira o código recebido',
  });
}

export function textarea(title, placeholder) {
  return MySwal.fire({
    title,
    input: 'textarea',
    confirmButtonText: 'Confirmar',
    showDenyButton: true,
    showCancelButton: true,
    cancelButtonText: `Cancelar`,
    inputPlaceholder: placeholder,
    inputValidator: (result) => !result && 'Campo obrigatório',
  });
}

export function selectOptionSwal(
  title = 'Selecione uma opção',
  placeholder = 'Status',
  options = []
) {
  const optionSwal = {};
  options.forEach((o) => {
    optionSwal[o.value] = o.name;
  });
  return MySwal.fire({
    title,
    confirmButtonText: 'Confirmar',
    showDenyButton: true,
    showCancelButton: true,
    cancelButtonText: `Cancelar`,
    inputPlaceholder: placeholder,
    input: 'select',
    inputOptions: optionSwal,
    animation: 'slide-from-top',
    denyButtonText: `Cancelar`,
    inputValidator: (result) => !result && 'Selecione uma opção',
  });
}

export function nameFile(
  title = 'Informe o nome do arquivo',
  placeholder = 'Nome do arquivo'
) {
  return MySwal.fire({
    title,
    input: 'text',
    confirmButtonText: 'Confirmar',
    showCancelButton: true,
    cancelButtonText: `Cancelar`,
    inputPlaceholder: placeholder,
    inputValidator: (result) =>
      !result && 'Para confirmar insira o nome do arquivo',
  });
}

export function errorMessageSwal(title, text) {
  return MySwal.fire({
    title,
    showConfirmButton: false,
    icon: 'error',
    text,
  });
}

export function alertMessageSwal(title, text) {
  return MySwal.fire({
    title,
    showConfirmButton: false,
    icon: 'warning',
    text,
  });
}
