import React, { useCallback, useContext, useEffect } from 'react';

import { Box, Button, Grid, TextField } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useAuth } from '../../contexts/auth';
import useExtension from '../../hooks/useExtension';
import { confirmationSwal } from '../../services/SwalService';
import formatReal, { removeRealFormat } from '../../utils/formatReal';
import { ExtensionContext } from '../ExtensionModal';
import TitleSection from '../TitleSection';
import AcceptButton from './AcceptButton';
import RejectButton from './RejectButton';

export default function ExtensionFormBuyer({ extensionId = null }) {
  const { isCedente, isAdmin } = useAuth();
  const { setFormExtension, maturity, updateSolicitation, disableButtons } =
    useContext(ExtensionContext);

  const { extension, isValidating } = useExtension(
    maturity?.maturityId,
    extensionId
  );

  const {
    values,
    errors,
    setFieldValue,
    isValid,
    handleSubmit,
    touched,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues: {
      buyer_reason: '',
      buyer_fee: 0,
      buyer_interest: 0,
      total: 0,
    },

    validationSchema: Yup.object().shape({
      buyer_reason: Yup.string().required('Motivo da aceitação ou rejeição'),
    }),

    onSubmit: async (val) => {
      const { isConfirmed } = await confirmationSwal(
        'Tem certeza?',
        'Deseja realmente aceitar esta proposta?'
      );

      if (isConfirmed) {
        updateSolicitation(
          extension._id,
          {
            status_id: 3,
            ...val,
            buyer_fee: removeRealFormat(val.buyer_fee) * 100,
            buyer_interest: removeRealFormat(val.buyer_interest) * 100,
          },
          'Proposta enviada ao cedente!'
        );
      }
    },
  });

  useEffect(() => {
    const total =
      removeRealFormat(values.buyer_fee) +
      removeRealFormat(values.buyer_interest);


    setFieldValue('total', formatReal(total.toFixed(2)));
  }, [values.buyer_fee, values.buyer_interest]);

  useEffect(() => {
    if (extension?.buyer_reason) {
      setFieldValue('buyer_reason', extension.buyer_reason);
    }

    if (extension?.buyer_fee) {
      setFieldValue('buyer_fee', formatReal(extension.buyer_fee));
    }

    if (extension?.buyer_interest) {
      setFieldValue('buyer_interest', formatReal(extension.buyer_interest));
    }
  }, [extension]);

  const rejectOperation = useCallback(async (status, reason = null) => {
    const { isConfirmed } = await confirmationSwal(
      'Tem certeza?',
      'Tem certeza que deseja rejeitar a solicitação?'
    );

    if (isConfirmed) {
      const data = { status_id: status };

      if (reason) {
        data.buyer_reason = reason;
      }

      updateSolicitation(extension._id, data, 'Solicitação rejeitada!');
    }
  }, []);

  const acceptOperation = useCallback(async () => {
    const { isConfirmed } = await confirmationSwal(
      'Tem certeza?',
      'Ao confirmar a operação ela entrará imediatamente em vigor'
    );

    if (isConfirmed) {
      updateSolicitation(
        extension._id,
        { status_id: 6 },
        'Solicitação aceita com sucesos!'
      );
    }
  }, []);

  return (
    <Box>
      <TitleSection title="Proposta do financiador" />

      <Box mb={4} mt={4} pr={4} pl={4}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                variant="outlined"
                name="buyer_reason"
                disabled={extension?.buyer_reason}
                value={values.buyer_reason}
                label="Motivo da aceitação ou rejeição"
                helperText={touched.buyer_reason ? errors.buyer_reason : ''}
                error={Boolean(errors.buyer_reason) && touched.buyer_reason}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Deixe alguma mensagem ao cedente"
                className="text-field-label"
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: 20,
                    color: '#000',
                    fontSize: 22,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <TextField
                variant="outlined"
                name="buyer_fee"
                disabled={extension?.buyer_reason}
                value={values.buyer_fee || 'R$ 0,00'}
                label="Juros"
                helperText={touched.buyer_fee ? errors.buyer_fee : ''}
                error={Boolean(errors.buyer_fee) && touched.buyer_fee}
                onChange={(e) =>
                  setFieldValue('buyer_fee', formatReal(e.target.value))
                }
                onBlur={handleBlur}
                className="text-field-label"
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: 20,
                    color: '#000',
                    fontSize: 22,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <TextField
                variant="outlined"
                name="buyer_interest"
                value={values.buyer_interest || 'R$ 0,00'}
                label="Multa"
                disabled={extension?.buyer_reason}
                helperText={touched.buyer_fee ? errors.buyer_fee : ''}
                error={Boolean(errors.buyer_fee) && touched.buyer_fee}
                onChange={(e) =>
                  setFieldValue('buyer_interest', formatReal(e.target.value))
                }
                onBlur={handleBlur}
                className="text-field-label"
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: 20,
                    color: '#000',
                    fontSize: 22,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                variant="outlined"
                name="total"
                disabled
                value={values.total || 'R$ 0,00'}
                label="Total"
                helperText={touched.total ? errors.total : ''}
                error={Boolean(errors.total) && touched.total}
                className="text-field-label"
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: 20,
                    color: '#000',
                    fontSize: 22,
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Button
                variant="text"
                color="black"
                disabled={disableButtons}
                onClick={() => {
                  setFormExtension(false);
                }}
                startIcon={<ArrowBack />}
              >
                Voltar
              </Button>
            </Grid>

            {extension?.status_id === 3 && (isCedente() || isAdmin()) ? (
              <Grid item xs={12} sm={12} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <RejectButton
                      disabled={isValidating || disableButtons}
                      onClick={() => rejectOperation(4)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <AcceptButton
                      disabled={isValidating || disableButtons}
                      onClick={() => acceptOperation()}
                      type="button"
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : null}

            {!extension?.buyer_reason ? (
              <Grid item xs={12} sm={12} md={6}>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <RejectButton
                        disabled={!isValid || isValidating || disableButtons}
                        onClick={() => rejectOperation(5, values.buyer_reason)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <AcceptButton
                        disabled={!isValid || isValidating || disableButtons}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ) : null}
          </Grid>
        </form>
      </Box>
    </Box>
  );
}
