import { removePontuacao } from "utils/removePontuacao";
import AxiosInterceptor from "utils/axios-interceptor";

export const updateBusinessService = async (business) => {
  const axiosInstance = AxiosInterceptor();

  business.buyerType = undefined;
  business.businessSize = undefined;
  business.taxRule = undefined;
  business.constitution = undefined;
  const { data } = await axiosInstance.patch(
    `${process.env.REACT_APP_NODE_API}/business/${business.businessId}`,
    business
  );

  return data;
};

export const getBigBoostCnpj = async (cnpj = "") => {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_DECISION_API}/api/v1/le/execute/6f0fc40c-586e-481f-9137-79be44abf49f`,
    {
      CNPJ: removePontuacao(cnpj),
    },
    {
      headers: { Authorization: process.env.REACT_APP_DECISION_API_TOKEN },
    }
  );

  if (data.result.error) return null;
  return data;
};

export const getBusinessByBusinessId = async (businessId) => {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/business/find/${businessId}`
  );

  return data;
};

export const switchCompany = async (token, businessId) => {
  const axiosInstance = AxiosInterceptor();

  await axiosInstance.post(
    `${process.env.REACT_APP_HEIMDALL_API}/v1/companies/update/default/${businessId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  window.location.reload();
};

export const getCompanies = async (token) => {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_HEIMDALL_API}/v1/companies/all`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

export const getDefaultCompany = async (token) => {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_HEIMDALL_API}/v1/companies/default`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const getBusinessByBusinessIdWithToken = async (businessId, token) => {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/business/find/${businessId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

export const loadBankAccounts = async (businessId) => {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/business/loadBankAccounts/${businessId}`
  );

  return data;
};

export async function getSellerMarket(businessId) {
  const axiosInstance = AxiosInterceptor();

  const options = {
    method: "GET",
    url: `${process.env.REACT_APP_NODE_API}/business/market/${businessId}`,
  };

  const { data } = await axiosInstance.request(options);

  return data;
}

export async function sendToAnalysis(businessId) {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/business/sendToAnalysis`,
    { businessId }
  );

  return data;
}

export async function getDadosEmail(businessid, userid) {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/business/dadosEmail/${businessid}/${userid}`
  );
  return data;
}

export async function getDadosBusiness(businessid) {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/business/dadosBusiness/${businessid}`
  );
  return data;
}

export async function syncZeev(businessid) {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_HEIMDALL_API}/v1/companies/zeev-retry/sync/${businessid}`
  );
  return data;
}
