import React from 'react';

export default function LayoutRows({ color = '#A0A0A0' }) {
  return (
    <svg
      id="Grupo_132"
      data-name="Grupo 132"
      xmlns="http://www.w3.org/2000/svg"
      width="52.077"
      height="39.501"
      viewBox="0 0 52.077 39.501"
    >
      <rect
        id="Retângulo_127"
        data-name="Retângulo 127"
        width="8.727"
        height="10.475"
        rx="2"
        transform="translate(0 8.727) rotate(-90)"
        fill={color}
      />
      <rect
        id="Retângulo_135"
        data-name="Retângulo 135"
        width="8.727"
        height="10.475"
        rx="2"
        transform="translate(0 24.344) rotate(-90)"
        fill={color}
      />
      <rect
        id="Retângulo_137"
        data-name="Retângulo 137"
        width="8.727"
        height="10.475"
        rx="2"
        transform="translate(0 39.501) rotate(-90)"
        fill={color}
      />
      <rect
        id="Retângulo_133"
        data-name="Retângulo 133"
        width="8.727"
        height="38.297"
        rx="2"
        transform="translate(13.779 8.727) rotate(-90)"
        fill={color}
      />
      <rect
        id="Retângulo_134"
        data-name="Retângulo 134"
        width="8.727"
        height="38.297"
        rx="2"
        transform="translate(13.779 24.344) rotate(-90)"
        fill={color}
      />
      <rect
        id="Retângulo_136"
        data-name="Retângulo 136"
        width="8.727"
        height="38.297"
        rx="2"
        transform="translate(13.779 39.501) rotate(-90)"
        fill={color}
      />
    </svg>
  );
}
