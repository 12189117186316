import AxiosInterceptor from "utils/axios-interceptor";

export async function getByInvoiceId(invoiceId) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/maturity/${invoiceId}`;

  const { data } = await axiosInstance.get(url);

  return data;
}

export async function liquidarParcela(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/invoice/liquidacao`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
