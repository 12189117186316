import React, { createContext, useState } from 'react';

import {
  ModalError,
  ModalInfo,
  ModalLoading,
  ModalSuccess,
  ModalWarning,
  TextFieldModal,
} from 'components/Modal';

export const ModalContext = createContext({});

export function ModalProvider({ children }) {
  const [modalState, setModalState] = useState({
    message: '',
    description: '',
    visible: false,
    label: '',
    confirmButtonText: '',
    cancelButtonText: '',
    modalType: '',
    callback: {
      action: null,
      parameters: null,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  /**
   * @param {string} Mensagem do modal
   * @param {void} Função callback
   * @param {any} parâmetros da função callback
   */
  function openModal(type, message, description, callback, ...parameters) {
    setModalState({
      modalType: `${type}`,
      message,
      description,
      callback: {
        action: callback,
        parameters,
      },
      visible: true,
    });
  }

  /**
   * @param {string} label do TextField
   * @param {string} confirmButtonText O texto apresentado no botão de confirmar do modal
   * @param {string} cancelButtonText O texto apresentado no botão de confirmar do modal
   * @param {void} Função callback
   * @param {any} parâmetros da função callback
   */
  function openModalTextInput(
    type,
    label,
    typePassword,
    confirmButtonText,
    cancelButtonText,
    callback,
    ...parameters
  ) {
    setModalState({
      modalType: `${type}`,
      label,
      typePassword,
      confirmButtonText,
      cancelButtonText,
      callback: {
        action: callback,
        parameters,
      },
      visible: true,
    });
  }

  async function confirmModal() {
    if (isLoading) return;
    if (modalState.callback.action == null) setModalState({ visible: false });

    setIsLoading(true);

    try {
      await modalState.callback.action(...modalState.callback.parameters);
      setIsLoading(false);
      setModalState({ visible: false });
    } catch (error) {
      setIsLoading(false);
      setModalState({ visible: false });
    }
  }

  async function confirmTextInputModal(textInput) {
    if (isLoading) return;
    if (modalState.callback.action == null) setModalState({ visible: false });

    setIsLoading(true);

    try {
      await modalState.callback.action(
        ...modalState.callback.parameters,
        textInput
      );
      setIsLoading(false);
      setModalState({ visible: false });
    } catch (error) {
      setIsLoading(false);
      setModalState({ visible: false });
    }
  }

  function closeModal() {
    setModalState({ visible: false });
  }

  function closeModalScroll() {
    setModalState({ visible: false });
    window.scrollTo(0, 0);
  }

  function loadModal(type) {
    switch (type) {
      case 'warning':
        return <ModalWarning />;

      case 'success':
        return <ModalSuccess />;

      case 'info':
        return <ModalInfo />;

      case 'error':
        return <ModalError />;

      case 'loading':
        return <ModalLoading />;

      case 'textField':
        return <TextFieldModal />;

      default:
        return <ModalInfo />;
    }
  }

  return (
    <ModalContext.Provider
      value={{
        isLoading,
        modalState,
        openModal,
        openModalTextInput,
        closeModal,
        closeModalScroll,
        confirmModal,
        confirmTextInputModal,
      }}
    >
      {children}
      {loadModal(modalState.modalType)}
    </ModalContext.Provider>
  );
}
