import AxiosInterceptor from "utils/axios-interceptor";

export async function getDrawee(invoiceId) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/drawee/invoiceId/${invoiceId}`
  );

  return data;
}
