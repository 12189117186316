import AxiosInterceptor from "utils/axios-interceptor";

export const getTableTitulosFilter = async (
  userTypeId,
  businessId,
  page,
  shortOrigin
) => {
  const axiosInstance = AxiosInterceptor();
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/invoice/filter?${
      userTypeId !== 3 ? `businessId=${businessId}&` : ""
    }offset=0&page=${
      page !== undefined ? page : 0
    }&limit=10&originType=${shortOrigin}`
  );

  return response;
};

export const getBuyerTableTitulosFilter = async (businessId) => {
  const axiosInstance = AxiosInterceptor();

  const response = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/invoice/buyer/${businessId}`
  );

  return response;
};

export const getTableTitulos = async (
  userTypeId,
  businessId,
  ASC,
  page,
  filterParams,
  orderBy,
  shortOrigin
) => {
  let byUserType = "";
  if (userTypeId === 1) byUserType = `businessId=${businessId}&`;
  if (userTypeId === 2) byUserType = `bidBusinessId=${businessId}&`;
  const axiosInstance = AxiosInterceptor();

  const response = await axiosInstance.get(
    `${
      process.env.REACT_APP_NODE_API
    }/invoice/filter?${byUserType}offset=0&limit=10&orderBy=${orderBy}&asc=${
      ASC !== undefined ? ASC : "DESC"
    }&page=${
      page !== undefined ? page : 0
    }&${filterParams}originType=${shortOrigin}`
  );

  return response;
};
