import React from 'react';

export default function LayoutGrid({ color = '#A0A0A0', size = 40 }) {
  const containerWidth = (size * 137.84) / 100;
  const containerHeight = size;

  return (
    <svg
      id="Grupo_131"
      data-name="Grupo 131"
      xmlns="http://www.w3.org/2000/svg"
      width={containerWidth}
      height={containerHeight}
      viewBox="0 0 54.629 39.632"
    >
      <rect
        id="Retângulo_122"
        data-name="Retângulo 122"
        width={(containerWidth * 27.45) / 100}
        height={(containerHeight * 43.24) / 100}
        rx="2"
        fill={color}
      />
      <rect
        id="Retângulo_132"
        data-name="Retângulo 132"
        width={(containerWidth * 27.45) / 100}
        height={(containerHeight * 43.24) / 100}
        rx="2"
        transform="translate(0 22.494)"
        fill={color}
      />
      <rect
        id="Retângulo_123"
        data-name="Retângulo 123"
        width={(containerWidth * 27.45) / 100}
        height={(containerHeight * 43.24) / 100}
        rx="2"
        transform="translate(19.281)"
        fill={color}
      />
      <rect
        id="Retângulo_130"
        data-name="Retângulo 130"
        width={(containerWidth * 27.45) / 100}
        height={(containerHeight * 43.24) / 100}
        rx="2"
        transform="translate(19.281 22.494)"
        fill={color}
      />
      <rect
        id="Retângulo_124"
        data-name="Retângulo 124"
        width={(containerWidth * 27.45) / 100}
        height={(containerHeight * 43.24) / 100}
        rx="2"
        transform="translate(39.632)"
        fill={color}
      />
      <rect
        id="Retângulo_131"
        data-name="Retângulo 131"
        width={(containerWidth * 27.45) / 100}
        height={(containerHeight * 43.24) / 100}
        rx="2"
        transform="translate(39.632 22.494)"
        fill={color}
      />
    </svg>
  );
}
