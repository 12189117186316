import React, { useEffect, useState } from "react";

import UsuariosCedente from "pages/CadastroFinaciadorCedente/tabs/UsuariosCedente";

import PageTitle from "components/PageTitle";
import { ReactIf, ReactThen } from "components/ReactIf";

import axios from "axios";

import { useAuth } from "contexts/auth";
import AxiosInterceptor from "utils/axios-interceptor";

export default () => {
  const { user } = useAuth();
  const axiosInstance = AxiosInterceptor();
  const [business, setBusiness] = useState(undefined);
  const [businessUsers, setBusinessUsers] = useState(undefined);

  const getBusiness = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_NODE_API}/business/find/${user.businessId}`)
      .then((res) => {
        setBusiness(res.data);
      });
  };

  const getBusinessUsers = () => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_NODE_API}/business/users/${user.businessId}`
      )
      .then((res) => {
        setBusinessUsers(res.data);
      });
  };

  useEffect(() => {
    getBusiness();
    getBusinessUsers();
  }, []);

  return (
    <>
      <PageTitle
        titleHeading="Usuários"
        titleDescription="Gerencie os usuários cadastrados"
      />
      <div style={{ marginTop: "50px", marginLeft: "-30px", width: "100%" }}>
        <ReactIf condition={business && businessUsers}>
          <ReactThen>
            <UsuariosCedente
              business={business}
              businessUsers={businessUsers}
              setBusinessUsers={(value) => setBusinessUsers(value)}
            />
          </ReactThen>
        </ReactIf>
      </div>
    </>
  );
};
