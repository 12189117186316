import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import axios from "axios";
import { ReactIf, ReactThen } from "components/ReactIf";
import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";
import Fuse from "fuse.js";
import { useOferta } from "hooks/useOferta";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import EventBus from "services/EventBus";
import { getLasBuyer } from "services/invoice";
import {
  abdicate,
  actionInvoicesWt,
  arrematar,
  calculoCet,
  calculoCetAbdicado,
  calculoCetDescer,
  calculoCetFactor,
  salvarLance,
} from "services/leilao";
import {
  emailOfertaCoberta,
  emailOfertaRecebida,
  emailOfertaRegistrada,
  showError,
} from "services/sendgrid";
import { getAllTagsServices } from "services/tags-services";
import { recebiveisStyle } from "styles/material/recebiveis";
import formatReal from "utils/formatReal";
import ShowcaseProvider, {
  ShowcaseContext,
} from "../../contexts/showcase.context";
import { getDadosEmail } from "../../services/business";
import LayoutGrid from "./icons/LayoutGrid";
import LayoutRows from "./icons/LayoutRows";
import { ordens } from "./items";
import "./leilao.scss";
import LeilaoCardGrid from "./LeilaoCardGrid";
import LeilaoCardRow from "./LeilaoCardRow";
import ModalDarOferta from "./ModalDarOferta";
import Search from "./Search";
import WithAllowedAccessPage from "../../hocs/WithAllowedAccessPage";

const Leilao = () => {
  const { openModalOffer } = useContext(ShowcaseContext);
  const classes = recebiveisStyle();
  const theme = useTheme();

  const [titulos, setTitulos] = useState([]);
  const [titulosCompleto, setTitulosCompleto] = useState([]);
  const [titulo, setTitulo] = useState({
    business: {},
    drawee: {},
    bidFactorValue: "",
    bidFactor: "",
    bidNetFactor: "",
    bidIofAmountValue: "",
    bidCommission: "",
    bidOperationValue: "",
    bidDiscountValue: "",
    bidDiscountPect: "",
    bidNetValue: "",
    bidFactorFloat: "",
    invoiceValue: "",
  });
  const [modal2, setModal2] = useState({ open: false, tab: 0, data: {} });
  const [modalAbdicar, setModalAbdicar] = useState(false);
  const [carregou, setCarregou] = useState(false);
  const { user } = useAuth();
  const { businessId } = JSON.parse(localStorage.getItem("@App:user"));
  const [filter, setFilter] = useState({});
  const [abrindo, setAbrindo] = useState(false);
  const [carregando, setCarregando] = useState(true);
  const [tituloSelecionado, setTituloSelecionado] = useState(false);
  const { toast } = useToast();
  const validando = false;
  const [ofertaIgual, setOfertaIgual] = useState(false);
  const [fraseErro, setFraseErro] = useState("");
  const [risk, setRisk] = useState("");
  const [tagsLista, setTagsLista] = useState([]);
  const [marketType, setMarketType] = useState("");
  const [liquidationType, setLiquidationType] = useState("");
  const [abdicado, setAbdicado] = useState(false);
  const [tipoTitulo, setTipoTitulo] = useState("");
  const [ufCedente, setUfCedente] = useState("");
  const [tags, setTags] = useState("");
  const [seller, setSeller] = useState("");
  const [filterBalcao, setFilterBalcao] = useState(false);
  const [filterMeusLances, setFilterMeusLances] = useState(false);

  const [drawee, setDrawee] = useState("");

  const [taxaAbdicar, setTaxaAbdicar] = useState(0);
  const [cedentesUnicos, setCedentesUnicos] = useState([]);
  const [sacadosUnicos, setSacadosUnicos] = useState([]);

  const [parcelas, setParcelas] = useState();

  const {
    novoFactor,
    setNovoFactor,
    desagio,
    setDesagio,
    subirOferta,
    diminuirOferta,
    alterandoOferta,
    handleChange,
  } = useOferta({ calcularTituloCet });

  const [values, setValues] = useState([]);

  const history = useHistory();
  const location = useLocation();

  const toggle2 = () => {
    setModal2({ ...modal2, open: !modal2.open, data: {} });
    history.push("/vitrine");
  };
  const toggleAbdicar = () => setModalAbdicar(!modalAbdicar);

  const [filtroOrdem, setFiltroOrdem] = useState(99);

  async function calcularLance() {
    let erroBanco = "Erro ao calcular lance";
    try {
      const comissaoNogord = await calculoNogord(titulo);

      let lastBuyer = null;
      lastBuyer = await getLasBuyer(titulo.invoice.invoiceId);

      const retornoLance = await toast.promise(
        salvarLance(
          titulo.invoice.invoiceId,
          user.businessId,
          parseFloat(novoFactor),
          comissaoNogord,
          user.userId,
          user.name,
          titulo.invoice.bidNetFactor
        ),
        {
          loading: "Realizando o lance",
          success: "Lance enviado para processamento.",
          error: "Falha ao realizar o lance",
        }
      );

      if (retornoLance.retorno == 0) {
        toast.error(retornoLance.mensagem);
      } else {
        let sendmail =
          !retornoLance || (retornoLance && retornoLance.retorno == 1);
        if (retornoLance && retornoLance.retorno == 0) {
          sendmail = false;
          erroBanco = retornoLance.mensagem;
        }

        const sellerData = await getDadosEmail(
          titulo.business.businessId,
          titulo.invoice.userId
        );
        const buyerData = await getDadosEmail(businessId, user.userId);

        if (
          lastBuyer.length > 0 &&
          sendmail &&
          businessId != lastBuyer[0].BUYER_ID
        ) {
          const payloadOfertaCoberta = {
            empresa: lastBuyer[0].ORIGIN_TYPE_SHORT,
            publico: 2,
            email_destinatario: lastBuyer[0].EMAIL,
            nome_cedente: sellerData[0].businessName,
            nome_financiador: lastBuyer[0].NAME,
            numeroNF: titulo.invoice.id,
            data_registro_titulo: titulo.invoice.registerDate,
            valor_parcelas: titulo.invoice.maturitiesValue,
            // user_email: lastBuyer[0].USER_MAIL
          };

          try {
            await emailOfertaCoberta(payloadOfertaCoberta);
          } catch {
            showError();
          }
        }

        if (sendmail) {
          const payloadOfertaRegistrada = {
            empresa: buyerData[0].origemWL,
            publico: 2,
            email_destinatario: buyerData[0].businessEmail,
            nome_cedente: sellerData[0].businessName,
            nome_financiador: buyerData[0].businessName,
            numeroNF: titulo.invoice.id,
            CET: titulo.invoice.bidNetFactor.toFixed(3),
            data_registro_titulo: titulo.invoice.registerDate,
            valor_parcelas: titulo.invoice.maturitiesValue,
            user_email: buyerData[0].userEmail,
          };

          try {
            await emailOfertaRegistrada(payloadOfertaRegistrada);
          } catch (error) {
            showError();
          }
          const payloadOfertaRecebida = {
            empresa: sellerData[0].origemWL,
            publico: 1,
            email_destinatario: sellerData[0].businessEmail,
            nome_cedente: sellerData[0].businessName,
            nome_financiador: buyerData[0].businessName,
            numeroNF: titulo.invoice.id,
            CET: titulo.invoice.bidNetFactor.toFixed(3),
            data_registro_titulo: titulo.invoice.registerDate,
            valor_parcelas: titulo.invoice.maturitiesValue,
            user_email: sellerData[0].userEmail,
          };

          try {
            await emailOfertaRecebida(payloadOfertaRecebida);
          } catch {
            showError();
          }
        }

        const elementIndex = titulos.findIndex(
          (t) => t.invoice.invoiceId == titulo.invoice.invoiceId
        );
        titulo.invoice.cetAtual = titulo.invoice.bidNetFactor;
        titulo.invoice.descontoAtual = titulo.invoice.bidDiscountValue;
        const newTitulos = [...titulos];
        newTitulos[elementIndex] = titulo;
        setTitulos(newTitulos);
        setModal2({ ...modal2, open: false, data: {} });
      }
    } catch (error) {
      toast.error(erroBanco);
    }
  }

  async function enviarArremate() {
    try {
      const comissaoNogord = await calculoNogord(titulo);
      //  const lanceAnterior = await melhorLance(titulo.invoice.invoiceId);

      await toast.promise(
        arrematar(
          titulo.invoice.invoiceId,
          user.businessId,
          parseFloat(novoFactor),
          comissaoNogord,
          user.userId,
          user.name
        ),
        {
          loading: "Realizando o lance",
          success: "Lance realizado com sucesso",
          error: "Falha ao realizar o lance",
        }
      );
      const sellerData = await getDadosEmail(
        titulo.business.businessId,
        titulo.invoice.userId
      );
      const buyerData = await getDadosEmail(businessId, user.userId);

      const payloadOfertaRegistrada = {
        empresa: buyerData[0].origemWL,
        publico: 2,
        email_destinatario: buyerData[0].businessEmail,
        nome_cedente: sellerData[0].businessName,
        nome_financiador: buyerData[0].businessName,
        numeroNF: titulo.invoice.id,
        CET: titulo.invoice.bidNetFactor.toFixed(3),
        data_registro_titulo: titulo.invoice.registerDate,
        valor_parcelas: titulo.invoice.maturitiesValue,
      };
      const payloadOfertaRegistradaUsr = {
        empresa: buyerData[0].origemWL,
        publico: 2,
        email_destinatario: buyerData[0].userEmail,
        nome_cedente: sellerData[0].businessName,
        nome_financiador: buyerData[0].businessName,
        numeroNF: titulo.invoice.id,
        CET: titulo.invoice.bidNetFactor.toFixed(3),
        data_registro_titulo: titulo.invoice.registerDate,
        valor_parcelas: titulo.invoice.maturitiesValue,
      };
      try {
        await emailOfertaRegistrada(payloadOfertaRegistrada);
        await emailOfertaRegistrada(payloadOfertaRegistradaUsr);
      } catch (error) {
        showError();
      }
      const payloadOfertaRecebida = {
        empresa: sellerData[0].origemWL,
        publico: 1,
        email_destinatario: sellerData[0].businessEmail,
        nome_cedente: sellerData[0].businessName,
        nome_financiador: buyerData[0].businessName,
        numeroNF: titulo.invoice.id,
        CET: titulo.invoice.bidNetFactor.toFixed(3),
        data_registro_titulo: titulo.invoice.registerDate,
        valor_parcelas: titulo.invoice.maturitiesValue,
      };
      const payloadOfertaRecebidaUst = {
        empresa: sellerData[0].origemWL,
        publico: 1,
        email_destinatario: sellerData[0].userEmail,
        nome_cedente: sellerData[0].businessName,
        nome_financiador: buyerData[0].businessName,
        numeroNF: titulo.invoice.id,
        CET: titulo.invoice.bidNetFactor.toFixed(3),
        data_registro_titulo: titulo.invoice.registerDate,
        valor_parcelas: titulo.invoice.maturitiesValue,
      };
      try {
        await emailOfertaRecebida(payloadOfertaRecebida);
        await emailOfertaRecebida(payloadOfertaRecebidaUst);
      } catch {
        showError();
      }

      const elementIndex = titulos.findIndex(
        (t) => t.invoice.invoiceId == titulo.invoice.invoiceId
      );
      titulo.invoice.cetAtual = titulo.invoice.bidNetFactor;
      titulo.invoice.descontoAtual = titulo.invoice.bidDiscountValue;
      const newTitulos = [...titulos];
      newTitulos[elementIndex] = titulo;
      setTitulos(newTitulos);
      setModal2({ ...modal2, open: false, data: {} });
    } catch (error) {
      toast.error("Erro ao calcular lance");
    }
  }

  async function darLance() {
    try {
      if (abdicado) {
        await toast.promise(enviarArremate(), {
          loading: "Calculando e salvando seu lance",
          success: "Lance realizado!",
          error: "Falha ao realizar lance",
        });
      } else {
        await toast.promise(calcularLance(), {
          loading: "Calculando e salvando seu lance",
          success: "Lance realizado!",
          error: "Falha ao realizar lance",
        });
      }
      window.location.replace("/vitrine");
    } catch (error) {
      toast.error(error.message);
    }
  }

  async function calculoNogord(titulo, { useCache } = { useCache: true }) {
    try {
      let diferenca = novoFactor - titulo.invoice.bidFactor;

      if (abdicado == 1) {
        diferenca = 0;
      }

      const sessionData = JSON.parse(localStorage.getItem("@App:user"));

      const data = JSON.stringify({
        segmentacao: titulo.business.segmentacao,
        prazoMedio: titulo.invoice.bidWeightedAverageTerm,
        origin_type_buyer: user.originType,
        origin_type_seller: titulo.business.originType,
        primeiroVenc: titulo.invoice.bidDate,
        partnerID: titulo.business.partnerId,
        // buyerID: titulo.invoice.bidBusinessId,
        buyerID: sessionData.businessId,
        invoiceID: titulo.invoice.invoiceId,
        tipoLiquidacao: titulo.invoice.liquidationTypeName,
        parcelas: titulo.invoice.maturities,
        Tipo_mercado_titulo: titulo.invoice.marketTypeName,
        sellerID: titulo.invoice.businessId,
        valor: titulo.invoice.maturitiesValue,
        numeroDC: titulo.invoice.number,
        tipoDC: titulo.invoice.typeName,
        rating: titulo.invoice.riskId,
        cetAtual: titulo.invoice.bidNetFactor, // r + diferenca,
        typeID: titulo.invoice.typeId,
      });

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_DECISION_API}/api/v1/le/execute/8bd478d4-d5da-4339-b41d-11892f622bf4`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_DECISION_API_TOKEN,
        },
        data,
        timeout: 1000 * 15,
      };

      const key = "CalculoNogord";

      const cached = localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key))
        : null;

      let resultado;

      if (useCache && cached) {
        resultado = cached;
      } else {
        try {
          const apiResult = await axios(config);

          localStorage.setItem(key, JSON.stringify(apiResult));

          resultado = apiResult;
        } catch (error) {
          const value = {
            data: {
              result: {
                value: {
                  cetCorte1: 0.01,
                  cetCorte2: 0.02,
                  cetCorte3: 0.03,
                  cetCorte4: 0.04,
                  comissaoMin: 15,
                  comissaoPct1: 0.005,
                  comissaoPct2: 0.005,
                  comissaoPct3: 0.005,
                  comissaoPct4: 0.005,
                  comissaoPct5: 0.005,
                  invoiceID: titulo.invoice.invoiceId,
                },
              },
            },
          };

          localStorage.setItem(key, JSON.stringify(value));
          resultado = value;
        }
      }

      switch (true) {
        case parseFloat(titulo.invoice.bidNetFactor + diferenca).toFixed(3) <=
          resultado.data.result.value?.cetCorte1 * 100:
          return resultado.data.result.value?.comissaoPct1;
        case parseFloat(titulo.invoice.bidNetFactor + diferenca).toFixed(3) >
          resultado.data.result.value?.cetCorte1 * 100 &&
          parseFloat(titulo.invoice.bidNetFactor + diferenca).toFixed(3) <=
            resultado.data.result.value?.cetCorte2 * 100:
          return resultado.data.result.value?.comissaoPct2;
        case parseFloat(titulo.invoice.bidNetFactor + diferenca).toFixed(3) >
          resultado.data.result.value?.cetCorte2 * 100 &&
          parseFloat(titulo.invoice.bidNetFactor + diferenca).toFixed(3) <=
            resultado.data.result.value?.cetCorte3 * 100:
          return resultado.data.result.value?.comissaoPct3;
        case parseFloat(titulo.invoice.bidNetFactor + diferenca).toFixed(3) >
          resultado.data.result.value?.cetCorte3 * 100 &&
          parseFloat(titulo.invoice.bidNetFactor + diferenca).toFixed(3) <=
            resultado.data.result.value?.cetCorte4 * 100:
          return resultado.data.result.value?.comissaoPct4;
        case parseFloat(titulo.invoice.bidNetFactor + diferenca).toFixed(3) >
          resultado.data.result.value?.cetCorte4 * 100:
          return resultado.data.result.value?.comissaoPct5;
        default:
          return resultado.data.result.value?.comissaoPct1;
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const recuperarFilterStorage = () => {
    const filtrosSalvosObj = localStorage.getItem("filtrosStorage") ?? "{}";
    const filtrosSalvos = JSON.parse(filtrosSalvosObj);

    setRisk(filtrosSalvos.risk);
    setMarketType(filtrosSalvos.marketType);
    setUfCedente(filtrosSalvos.ufCedente);
    setLiquidationType(filtrosSalvos.liquidationType);
    setTipoTitulo(filtrosSalvos.tipoTitulo);
    setFilter({
      drawee: filtrosSalvos.drawee,
      number: filtrosSalvos.number,
      valorInicial: filtrosSalvos.valorInicial,
      valorFinal: filtrosSalvos.valorFinal,
    });
    setSeller(filtrosSalvos.seller);
    setTags(filtrosSalvos.tags);

    return filtrosSalvos;
  };

  const salvaFiltros = () => {
    const _filtros = {
      risk: risk || "",
      marketType: marketType || "",
      ufCedente: ufCedente || "",
      liquidationType: liquidationType || "",
      tipoTitulo: tipoTitulo || "",
      valorInicial: filter.valorInicial ? filter.valorInicial : null,
      valorFinal: filter.valorFinal ? filter.valorFinal : null,
      number: filter.number ? filter.number : null,
      sacado: filter.drawee ? filter.drawee : null,
      seller: seller || "",
      tags: tags || "",
    };

    localStorage.setItem("filtrosStorage", JSON.stringify(_filtros));
  };

  function formatarData(data) {
    const date = new Date(data);
    return `${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}/${
      date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
    }/${date.getFullYear()}`;
  }

  const handlerChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const handleMultipleChanges = (e) => {
    setFilter({ ...filter, ...e });
  };

  function limparFiltro() {
    setRisk("");
    setMarketType("");
    setUfCedente("");
    setTags("");
    setDrawee("");
    setSeller("");
    setLiquidationType("");
    setTipoTitulo("");
    setFilter({
      ...filter,
      number: null,
      drawee: null,
      valorInicial: null,
      valorFinal: null,
    });
    setTitulos(titulosCompleto);
  }

  /**
   * Aplica os filtros selecionados ao array de títulos.
   *
   * O campo de busca de texto utiliza fuzzy search (fuse.js) para buscar o que foi digitado nos campos
   * declarados no variável "keys";
   *
   * Os campos de seleção são concatenados para juntar os filtros.
   */
  function getTitulosFiltered(initialFilters, initialTitulos = []) {
    salvaFiltros();

    let newTitulos =
      titulosCompleto.length > 0 ? [...titulosCompleto] : [...initialTitulos];

    // initialFilters.number é o valor do campo de busca
    // O nome foi mantido para evitar quebrar o código
    const searchText = initialFilters?.number ?? filter.number;

    if (searchText) {
      // Campos que devem ser considerados na busca
      const keys = [
        "invoice.bidBusinessId",
        "invoice.marketTypeName",
        "invoice.id",
        "sacado.name",
        "sacado.corporateName",
        "sacado.countryIdentity",
        "business.name",
        "business.corporateName",
        "business.countryIdentity",
        "invoice.number",
        "invoice.riskName",
        "invoice.typeName",
        "invoice.invoiceValue",
        "invoice.issueDate",
        "invoice.maturities",
        "invoice.days",
        "invoice.descontoAtual",
        "invoice.cetAtual",
        "tags.NAME",
      ];

      const fuse = new Fuse(newTitulos, { keys, minMatchCharLength: 2 });

      newTitulos = fuse.search(searchText).map((i) => i.item);
    }

    const valueRisk = initialFilters?.risk ?? risk;
    const valueMarketType = initialFilters?.marketType ?? marketType;
    const valueUfCedente = initialFilters?.ufCedente ?? ufCedente;
    const valueLiquidationType =
      initialFilters?.liquidationType ?? liquidationType;
    const valueTipoTitulo = initialFilters?.tipoTitulo ?? tipoTitulo;
    const valueTags = initialFilters?.tags ?? tags;
    const valueValorInicial =
      initialFilters?.valorInicial ?? filter.valorInicial;
    const valueValorFinal = initialFilters?.valorFinal ?? filter.valorFinal;
    const valueDrawee = initialFilters?.drawee ?? drawee;
    const valueSeller = initialFilters?.seller ?? seller;

    newTitulos = newTitulos.filter(
      (titulo) =>
        (valueRisk ? titulo.invoice.riskName === valueRisk : true) &&
        (valueMarketType
          ? titulo.invoice.marketTypeName === valueMarketType
          : true) &&
        (valueUfCedente
          ? titulo.business.addressState === valueUfCedente
          : true) &&
        (valueLiquidationType
          ? titulo.invoice.liquidationTypeName === valueLiquidationType
          : true) &&
        (valueTipoTitulo
          ? titulo.invoice.typeName.toUpperCase() === valueTipoTitulo
          : true) &&
        (valueTags
          ? titulo.tags.filter((tag) => tag.NAME === valueTags).length > 0
          : true) &&
        (valueValorInicial
          ? titulo.invoice.invoiceValue >= valueValorInicial
          : true) &&
        (valueValorFinal
          ? titulo.invoice.invoiceValue <= valueValorFinal
          : true) &&
        (valueDrawee
          ? titulo.sacado.name
              .toLowerCase()
              .indexOf(valueDrawee.toLowerCase()) > -1
          : true) &&
        (valueSeller
          ? titulo.business.name
              .toLowerCase()
              .indexOf(valueSeller.toLowerCase()) > -1
          : true) &&
        (filterBalcao ? titulo.invoice.marketTypeId === 2 : true) &&
        (filterMeusLances
          ? titulo.invoice.indOferta === 1 || titulo.invoice.indOferta === 2
          : true)
    );

    return newTitulos;
  }

  function filterSearch() {
    const newTitulos = getTitulosFiltered();

    setTitulos(newTitulos);
  }

  function cnpj(valor) {
    const x = valor
      .replace(/\D/g, "")
      .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
    return !x[2]
      ? x[1]
      : `${x[1]}.${x[2]}.${x[3]}/${x[4]}${x[5] ? `-${x[5]}` : ""}`;
  }

  function calcularRisco(riskName) {
    switch (riskName) {
      case "AA":
        return "#05ff22";
      case "AB":
        return "#9afe06";
      case "BA":
        return "#fef306";
      case "BB":
        return "#fff203";
      case "CA":
        return "#fb7f03";
      case "CB":
        return "#e37100";
      case "INAPTO":
        return "#c80203";
      case "Não Definido":
        return "#0048bd";
      case "Médio":
        return "#0048bd";
      default:
        return "#0048bd";
    }
  }

  useEffect(() => {
    setFraseErro("");

    const tituloLista = titulos.find(
      (t) => t.invoice.invoiceId == titulo.invoice.invoiceId
    );
    if (tituloLista != null) {
      if (titulo.invoice.bidFactorFloat < 0) {
        setOfertaIgual(true);
        setFraseErro("Fator de deságio não pode ser negativo.");
      } else {
        if (titulo.invoice.abdicado == "0") {
          if (titulo.invoice.cetAtual > 0) {
            if (titulo.invoice.bidNetFactor >= titulo.invoice.cetAtual) {
              setOfertaIgual(true);
              setFraseErro("Sua oferta é maior ou igual a atual.");
            } else {
              setOfertaIgual(false);
              setFraseErro("");
            }
          } else if (
            titulo.invoice.bidNetFactor > tituloLista.invoice.maxOffer
          ) {
            setOfertaIgual(true);
            setFraseErro("Sua oferta excedeu a taxa máxima.");
          } else {
            setOfertaIgual(false);
            setFraseErro("");
          }
        }

        if (titulo.invoice.abdicado === "1") {
          setAbdicado(true);
        } else {
          setAbdicado(false);
        }

        if (titulo.invoice.indOferta && titulo.invoice.abdicado === "1") {
          setFraseErro("Você arrematou este título");
        }
      }
    }
  }, [titulo]);

  const sortTitulosByRanking = (titulos) =>
    titulos.sort((a, b) => {
      if (!a.invoice.ranking) {
        return 1;
      } else if (!b.invoice.ranking) {
        return -1;
      }

      // Se os rankings forem iguais, ordena pelo maior valor de titulo
      if (a.invoice.ranking === b.invoice.ranking) {
        if (a.invoice.invoiceValue < b.invoice.invoiceValue) {
          return 1;
        } else if (a.invoice.invoiceValue > b.invoice.invoiceValue) {
          return -1;
        } else {
          return 0;
        }
      } else {
        if (a.invoice.ranking < b.invoice.ranking) {
          return 1;
        } else if (a.invoice.ranking > b.invoice.ranking) {
          return -1;
        } else {
          return 0;
        }
      }
    });

  useEffect(() => {
    if (filtroOrdem) {
      let titulosOrdenado = [...titulos];
      const searchText = filter.number;

      if (filtroOrdem == 1) {
        titulosOrdenado = titulosOrdenado.sort(function (a, b) {
          return new Date(a.invoice.issueDate) - new Date(b.invoice.issueDate);
        });
      } else if (filtroOrdem == 2) {
        titulosOrdenado = titulosOrdenado.sort(function (a, b) {
          return new Date(b.invoice.issueDate) - new Date(a.invoice.issueDate);
        });
      } else if (filtroOrdem == 3) {
        titulosOrdenado = titulosOrdenado.sort(function (a, b) {
          return a.invoice.invoiceValue - b.invoice.invoiceValue;
        });
      } else if (filtroOrdem === 99 && !searchText) {
        titulosOrdenado = sortTitulosByRanking(titulosOrdenado);
      } else {
        titulosOrdenado = titulosOrdenado.sort(function (a, b) {
          return b.invoice.invoiceValue - a.invoice.invoiceValue;
        });
      }
      setTitulos(titulosOrdenado);
    }
  }, [filtroOrdem]);

  async function percorrer(response) {
    const cedentes = [];
    const sacados = [];
    for await (const titulo of response) {
      if (cedentes.indexOf(titulo.business.name) == -1) {
        cedentes.push(titulo.business.name);
      }
      for await (const sacado of titulo.drawee) {
        if (sacados.indexOf(sacado?.name) == -1) {
          sacados.push(sacado?.name);
        }
      }
    }
    setCedentesUnicos(cedentes);
    setSacadosUnicos(sacados);
  }

  async function carregarTitulos() {
    try {
      setCarregou(false);
      setCarregando(true);

      const { data } = await getAllTagsServices();

      setTagsLista(data);

      const response = await actionInvoicesWt(
        user.businessId,
        user.originType === "AF" || user.originType === ""
          ? ""
          : user.originType
      );
      let itemsProcessed = 0;

      for await (const [index, titulo] of response.entries()) {
        const taxasResposta = {};

        try {
          const taxas = taxasResposta;

          if (titulo.invoice.abdicado == "1") {
            itemsProcessed++;
          } else if (titulo.invoice.bidNetFactor == 0) {
            itemsProcessed++;
          } else {
            titulo.invoice.cetAtual = titulo.invoice.bidNetFactor;
            titulo.invoice.descontoAtual = titulo.invoice.bidDiscountValue;
            itemsProcessed++;
          }
          toast(
            `Titulos processados: ${itemsProcessed} de ${response.length}`,
            {
              id: "percentComplete",
            }
          );
        } catch (e) {
          itemsProcessed++;
          response.splice(index, 1);
          // TODO: Enviar aviso ao backend que um título falhou
        }
      }

      // if (itemsProcessed === response.length) {
      response.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return (
          new Date(a.invoice.registerDate) - new Date(b.invoice.registerDate)
        );
      });

      setTitulos(response);

      percorrer(response);
      setTitulosCompleto(response);

      let newTitulos = [...response];

      const _filter = recuperarFilterStorage();

      if (_filter) {
        const temFiltrosSalvos = Object.values(_filter).some(
          (x) => x != null && x != ""
        );

        if (temFiltrosSalvos) {
          newTitulos = getTitulosFiltered(_filter, response);

          setTitulos(newTitulos);
        }
      }

      setCarregou(true);
      setCarregando(false);
      // }
    } catch (error) {
      toast.error("Erro ao carregar os títulos");
    }
  }

  useMemo(async () => {
    toast.promise(carregarTitulos(), {
      loading: "Carregando títulos",
      success: "Títulos carregados",
    });

    EventBus.on("reload-vitrine", () => {
      carregarTitulos();
    });
  }, []);

  const abrirAbdicar = (titulo) => {
    setTitulo(titulo);
    setTituloSelecionado(true);
    setModalAbdicar(true);
  };

  async function abdicar(titulo) {
    try {
      const totalDesconto =
        ((titulo.invoice.maturitiesValue * (taxaAbdicar / 100)) / 30) *
        titulo.invoice.bidWeightedAverageTerm;

      await toast.promise(
        abdicate(
          titulo.invoice.invoiceId,
          parseFloat(taxaAbdicar),
          totalDesconto,
          titulo.buyer.businessId
        ),
        {
          loading: "Abdicando título",
          success: () => {
            carregarTitulos();
            return "Título abdicado com sucesso";
          },
          error: "Falha ao abdicar título",
        }
      );
      setModalAbdicar(false);
    } catch (error) {
      toast.error(error);
    }
  }

  async function calcularTituloCet(novoFactor) {
    try {
      const taxaComissao = await calculoNogord(titulo);
      const taxasResposta = await calculoCetFactor(
        user,
        titulo,
        taxaComissao,
        novoFactor
      );
      const taxas = taxasResposta;
      setParcelas(taxasResposta);
      setTitulo({
        ...titulo,
        invoice: {
          ...titulo.invoice,
          bidCommission:
            taxas[taxas.length - 1].BID_COMISSION_VALUE > 15
              ? taxas[taxas.length - 1].BID_COMISSION_VALUE
              : 15,
          bidCommissionValue: taxas[taxas.length - 1].BID_PERCT_COMM,
          bidNetFactor: taxas[taxas.length - 1].BID_PERCT_CET,
          bidAnalysisValue: taxas[taxas.length - 1].BID_ANALYSIS_VALUE,
          bidPaymentTerm: taxas[taxas.length - 1].BID_PAYMENT_TERM,
          bidFactor: parseFloat(
            taxas[taxas.length - 1].BID_PERCT_FACTOR
          ).toFixed(3),
          bidFactorValue: taxas[taxas.length - 1].BID_FACTOR_VALUE,
          bidFactorFloat: taxas[taxas.length - 1].BID_PERCT_FACTORFLO,
          bidDiscountValue: taxas[taxas.length - 1].BID_DISCOUNT_VALUE,
          bidDiscountPerct: taxas[taxas.length - 1].BID_PERCT_DISCOUNT_TOTAL,
          bidNetValue: taxas[taxas.length - 1].BID_NET_VALUE,
          bidNetValueInvoice: taxas[taxas.length - 1].BID_NET_VALUE_INVOICE,
          bidIofAmountValue: taxas[taxas.length - 1].BID_TOTAL_IOF,
          bidIofTermValue: taxas[taxas.length - 1].BID_PERCT_IOF,
          bidOperationValue: taxas[taxas.length - 1].BID_TOTAL_TAC,
          bidOperation: taxas[taxas.length - 1].BID_PERCT_TAC,
          bidAdValoremValue: taxas[taxas.length - 1].BID_ADVALOREM_VALUE,
          bidBankSlip: taxas[taxas.length - 1].BID_BANK_SLIP,
          bidAverageTerm: taxas[taxas.length - 1].DAYS,
          bidMaturitiesValue: taxas[taxas.length - 1].MATURITY_VALUE,
          bidWeightedAverageTerm: taxas[taxas.length - 1].WEIGHTAVERAGETERM,
          bidWeightedAverageTermPond:
            taxas[taxas.length - 1].WEIGHTTOTALPONDVALUE,
        },
      });
    } catch (error) {
      toast.error(error);
    }
  }

  async function calcularTituloAbdicado(titulo, tab = 0, data = {}) {
    setAbrindo(true);

    try {
      titulo.invoice.bidFactor = parseFloat(titulo.invoice.bidFactor).toFixed(
        3
      );

      const comissaoNogord = await calculoNogord(titulo, { useCache: false });
      const taxasResposta = await calculoCetAbdicado(
        user,
        titulo,
        comissaoNogord
      );
      const taxas = taxasResposta;

      setParcelas(taxasResposta);
      setTitulo({
        ...titulo,
        invoice: {
          ...titulo.invoice,
          bidCommission:
            taxas[taxas.length - 1].BID_COMISSION_VALUE > 15
              ? taxas[taxas.length - 1].BID_COMISSION_VALUE
              : 15,
          bidCommissionValue: taxas[taxas.length - 1].BID_PERCT_COMM,
          bidNetFactor: taxas[taxas.length - 1].BID_PERCT_CET,
          bidFactor: parseFloat(
            taxas[taxas.length - 1].BID_PERCT_FACTOR
          ).toFixed(3),
          bidFactorFloat: taxas[taxas.length - 1].BID_PERCT_FACTORFLO,
          bidAnalysisValue: taxas[taxas.length - 1].BID_ANALYSIS_VALUE,
          bidPaymentTerm: taxas[taxas.length - 1].BID_PAYMENT_TERM,
          bidFactorValue: taxas[taxas.length - 1].BID_FACTOR_VALUE,
          bidDiscountValue: taxas[taxas.length - 1].BID_DISCOUNT_VALUE,
          bidDiscountPerct: taxas[taxas.length - 1].BID_PERCT_DISCOUNT_TOTAL,
          bidNetValue: taxas[taxas.length - 1].BID_NET_VALUE,
          bidNetValueInvoice: taxas[taxas.length - 1].BID_NET_VALUE_INVOICE,
          bidIofAmountValue: taxas[taxas.length - 1].BID_TOTAL_IOF,
          bidIofTermValue: taxas[taxas.length - 1].BID_PERCT_IOF,
          bidOperationValue: taxas[taxas.length - 1].BID_TOTAL_TAC,
          bidOperation: taxas[taxas.length - 1].BID_PERCT_TAC,
          bidAdValoremValue: taxas[taxas.length - 1].BID_ADVALOREM_VALUE,
          bidBankSlip: taxas[taxas.length - 1].BID_BANK_SLIP,
          bidAverageTerm: taxas[taxas.length - 1].DAYS,
          bidMaturitiesValue: taxas[taxas.length - 1].MATURITY_VALUE,
          bidWeightedAverageTerm: taxas[taxas.length - 1].WEIGHTAVERAGETERM,
          bidWeightedAverageTermPond:
            taxas[taxas.length - 1].WEIGHTTOTALPONDVALUE,
          bidMaxFactor: taxas[taxas.length - 1].BID_PERCT_CET,
        },
      });
      setNovoFactor(
        parseFloat(taxas[taxas.length - 1].BID_PERCT_FACTOR).toFixed(3)
      );
      setDesagio(
        parseFloat(taxas[taxas.length - 1].BID_PERCT_FACTOR).toFixed(3)
      );
      setModal2({ open: true, tab, data });
      setTituloSelecionado(true);
      setAbrindo(false);
    } catch (e) {
      setAbrindo(false);
    }
  }

  async function calcularTitulo(titulo, tab = 0, data = {}) {
    setAbrindo(true);

    try {
      if (titulo.invoice.abdicado === "1") {
        setAbdicado(true);
      } else {
        setAbdicado(false);
      }

      if (titulo.invoice.bidFactor === 0) {
        titulo.invoice.bidFactor = parseFloat(titulo.invoice.bidFactor).toFixed(
          3
        );

        const comissaoNogord = 0;
        const taxasResposta =
          titulo.invoice.abdicado == "1"
            ? await calculoCet(user, titulo, comissaoNogord)
            : await calculoCet(user, titulo, comissaoNogord);
        const taxas = taxasResposta;

        setParcelas(taxasResposta);
        setTitulo({
          ...titulo,
          invoice: {
            ...titulo.invoice,
            bidCommission:
              taxas[taxas.length - 1].BID_COMISSION_VALUE > 15
                ? taxas[taxas.length - 1].BID_COMISSION_VALUE
                : 15,
            bidCommissionValue: taxas[taxas.length - 1].BID_PERCT_COMM,
            bidNetFactor: taxas[taxas.length - 1].BID_PERCT_CET,
            bidFactor: parseFloat(
              taxas[taxas.length - 1].BID_PERCT_FACTOR
            ).toFixed(3),
            bidFactorFloat: taxas[taxas.length - 1].BID_PERCT_FACTORFLO,
            bidAnalysisValue: taxas[taxas.length - 1].BID_ANALYSIS_VALUE,
            bidPaymentTerm: taxas[taxas.length - 1].BID_PAYMENT_TERM,
            bidFactorValue: taxas[taxas.length - 1].BID_FACTOR_VALUE,
            bidDiscountValue: taxas[taxas.length - 1].BID_DISCOUNT_VALUE,
            bidDiscountPerct: taxas[taxas.length - 1].BID_PERCT_DISCOUNT_TOTAL,
            bidNetValue: taxas[taxas.length - 1].BID_NET_VALUE,
            bidNetValueInvoice: taxas[taxas.length - 1].BID_NET_VALUE_INVOICE,
            bidIofAmountValue: taxas[taxas.length - 1].BID_TOTAL_IOF,
            bidIofTermValue: taxas[taxas.length - 1].BID_PERCT_IOF,
            bidOperationValue: taxas[taxas.length - 1].BID_TOTAL_TAC,
            bidOperation: taxas[taxas.length - 1].BID_PERCT_TAC,
            bidAdValoremValue: taxas[taxas.length - 1].BID_ADVALOREM_VALUE,
            bidBankSlip: taxas[taxas.length - 1].BID_BANK_SLIP,
            bidAverageTerm: taxas[taxas.length - 1].DAYS,
            bidMaturitiesValue: taxas[taxas.length - 1].MATURITY_VALUE,
            bidWeightedAverageTerm: taxas[taxas.length - 1].WEIGHTAVERAGETERM,
            bidWeightedAverageTermPond:
              taxas[taxas.length - 1].WEIGHTTOTALPONDVALUE,
          },
        });
        setNovoFactor(
          parseFloat(taxas[taxas.length - 1].BID_PERCT_FACTOR).toFixed(3)
        );
        setDesagio(
          parseFloat(taxas[taxas.length - 1].BID_PERCT_FACTOR).toFixed(3)
        );
        setModal2({ open: true, tab, data });
        setTituloSelecionado(true);
      } else {
        const comissaoNogord = 0;
        const taxasResposta =
          titulo.invoice.abdicado === "1"
            ? await calculoCet(user, titulo, comissaoNogord)
            : await calculoCetDescer(user, titulo, comissaoNogord);
        const taxas = taxasResposta;

        setParcelas(taxasResposta);
        setTitulo({
          ...titulo,
          invoice: {
            ...titulo.invoice,
            bidCommission:
              taxas[taxas.length - 1].BID_COMISSION_VALUE > 15
                ? taxas[taxas.length - 1].BID_COMISSION_VALUE
                : 15,
            bidCommissionValue: taxas[taxas.length - 1].BID_PERCT_COMM,
            bidAnalysisValue: taxas[taxas.length - 1].BID_ANALYSIS_VALUE,
            bidPaymentTerm: taxas[taxas.length - 1].BID_PAYMENT_TERM,
            bidNetFactor: taxas[taxas.length - 1].BID_PERCT_CET,
            bidFactor: parseFloat(
              taxas[taxas.length - 1].BID_PERCT_FACTOR
            ).toFixed(3),
            bidFactorFloat: taxas[taxas.length - 1].BID_PERCT_FACTORFLO,
            bidFactorValue: taxas[taxas.length - 1].BID_FACTOR_VALUE,
            bidDiscountValue: taxas[taxas.length - 1].BID_DISCOUNT_VALUE,
            bidDiscountPerct: taxas[taxas.length - 1].BID_PERCT_DISCOUNT_TOTAL,
            bidNetValue: taxas[taxas.length - 1].BID_NET_VALUE,
            bidNetValueInvoice: taxas[taxas.length - 1].BID_NET_VALUE_INVOICE,
            bidIofAmountValue: taxas[taxas.length - 1].BID_TOTAL_IOF,
            bidIofTermValue: taxas[taxas.length - 1].BID_PERCT_IOF,
            bidOperationValue: taxas[taxas.length - 1].BID_TOTAL_TAC,
            bidOperation: taxas[taxas.length - 1].BID_PERCT_TAC,
            bidAdValoremValue: taxas[taxas.length - 1].BID_ADVALOREM_VALUE,
            bidBankSlip: taxas[taxas.length - 1].BID_BANK_SLIP,
            bidAverageTerm: taxas[taxas.length - 1].DAYS,
            bidMaturitiesValue: taxas[taxas.length - 1].MATURITY_VALUE,
            bidWeightedAverageTerm: taxas[taxas.length - 1].WEIGHTAVERAGETERM,
            bidWeightedAverageTermPond:
              taxas[taxas.length - 1].WEIGHTTOTALPONDVALUE,
          },
        });
        setNovoFactor(
          parseFloat(taxas[taxas.length - 1].BID_PERCT_FACTOR).toFixed(3)
        );
        setDesagio(
          parseFloat(taxas[taxas.length - 1].BID_PERCT_FACTOR).toFixed(3)
        );
        setModal2({ open: true, tab, data });
        setTituloSelecionado(true);
      }
    } catch (error) {
      toast.error(error.message);
      setAbrindo(false);
    } finally {
      setAbrindo(false);
    }
  }

  const abrirTitulo = async (titulo, tab = 0, data = {}) => {
    // if (titulo.invoice.abdicado === '1') {
    //   await toast.promise(calcularTituloAbdicado(titulo, tab, data), {
    //     loading: 'Abrindo o título',
    //     success: 'Título aberto com sucesso',
    //     error: 'Impossível abrir o titulo',
    //   });
    // } else {
    //   await toast.promise(calcularTitulo(titulo, tab, data), {
    //     loading: 'Abrindo o título',
    //     success: 'Título aberto com sucesso',
    //     error: 'Impossível abrir o titulo',
    //   });
    // }
  };

  const [layout, setLayout] = useState("grid");

  useEffect(() => {
    filterSearch();
  }, [
    filterBalcao,
    filterMeusLances,
    risk,
    tipoTitulo,
    liquidationType,
    tags,
    ufCedente,
    seller,
    drawee,
    marketType,
    filter.valorInicial,
    filter.valorFinal,
  ]);

  useEffect(() => {
    if (titulosCompleto && titulosCompleto.length > 0) {
      setTitulos(sortTitulosByRanking(titulosCompleto));
    }
  }, [titulosCompleto]);

  useEffect(() => {
    if (location.pathname.includes("oferta/") && titulosCompleto.length > 0) {
      const titulo = titulosCompleto.find(
        (titulo) =>
          titulo.invoice.id ==
          location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
      );
      if (titulo) {
        openModalOffer(titulo);
      } else history.push("/vitrine");
    }
  }, [titulosCompleto]);

  const tituloOriginal = titulo
    ? titulosCompleto.find((t) => t.invoice.id === titulo?.invoice?.id)
    : null;

  return (
    <ShowcaseProvider>
      <ShowcaseContext.Consumer>
        {({ openModalOffer, selectedTitle }) => (
          <div className="leilao">
            <Search
              cedentesUnicos={cedentesUnicos}
              drawee={drawee}
              filter={filter}
              filterBalcao={filterBalcao}
              filterMeusLances={filterMeusLances}
              filterSearch={filterSearch}
              formatReal={formatReal}
              handleMultipleChanges={handleMultipleChanges}
              handlerChange={handlerChange}
              limparFiltro={limparFiltro}
              liquidationType={liquidationType}
              marketType={marketType}
              risk={risk}
              sacadosUnicos={sacadosUnicos}
              seller={seller}
              setDrawee={setDrawee}
              setFilterBalcao={setFilterBalcao}
              setFilterMeusLances={setFilterMeusLances}
              setLiquidationType={setLiquidationType}
              setMarketType={setMarketType}
              setRisk={setRisk}
              setSeller={setSeller}
              setTags={setTags}
              setTipoTitulo={setTipoTitulo}
              setUfCedente={setUfCedente}
              tags={tags}
              tagsLista={tagsLista}
              tipoTitulo={tipoTitulo}
              titulos={titulos}
              titulosCompleto={titulosCompleto}
              ufCedente={ufCedente}
              user={user}
            />

            <ReactIf condition={carregando}>
              <ReactThen>
                <Grid
                  container
                  spacing={4}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    sm={6}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <div className="m-3">
                      <CircularProgress />
                    </div>
                  </Grid>
                </Grid>
              </ReactThen>
            </ReactIf>

            <div className="leilao-content">
              {carregou && (
                <>
                  <div className="leilao-content--top">
                    <div className="leilao-content--layouts-container">
                      <div
                        className="leilao-content--icon"
                        onClick={() => setLayout("grid")}
                      >
                        <LayoutGrid
                          color={
                            layout === "grid"
                              ? theme.palette.primary.main
                              : "#d6d6d6"
                          }
                        />
                      </div>

                      <div
                        className="leilao-content--icon"
                        onClick={() => setLayout("rows")}
                      >
                        <LayoutRows
                          color={
                            layout === "rows"
                              ? theme.palette.primary.main
                              : "#d6d6d6"
                          }
                        />
                      </div>
                    </div>

                    <div className="leilao-content--filtro">
                      <label>Ordernar por:</label>

                      <TextField
                        select
                        value={filtroOrdem}
                        onChange={(e) => {
                          setFiltroOrdem(
                            e.target.value === "" ? "" : e.target.value
                          );
                        }}
                      >
                        <MenuItem disabled value="">
                          Selecione uma opção
                        </MenuItem>

                        {ordens.map((element, key) => (
                          <MenuItem
                            classes={{ root: classes.SELECT }}
                            key={key}
                            value={element.value}
                          >
                            {element.nome}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </div>

                  {layout === "grid" && (
                    <div className="leilao-content--grid">
                      {titulos?.map((titulo, index) => (
                        <div key={index}>
                          <LeilaoCardGrid
                            abrindo={abrindo}
                            abrirAbdicar={abrirAbdicar}
                            abrirTitulo={openModalOffer}
                            businessId={businessId}
                            calcularRisco={calcularRisco}
                            cnpj={cnpj}
                            formatReal={formatReal}
                            formatarData={formatarData}
                            titulo={titulo}
                            position={index}
                          />
                        </div>
                      ))}
                    </div>
                  )}

                  {layout === "rows" && (
                    <div className="leilao-content--row">
                      {titulos?.map((titulo, index) => (
                        <LeilaoCardRow
                          abrindo={abrindo}
                          abrirAbdicar={abrirAbdicar}
                          abrirTitulo={openModalOffer}
                          businessId={businessId}
                          calcularRisco={calcularRisco}
                          cnpj={cnpj}
                          formatReal={formatReal}
                          formatarData={formatarData}
                          key={index}
                          titulo={titulo}
                          position={index}
                        />
                      ))}
                    </div>
                  )}
                </>
              )}

              {selectedTitle && (
                <ModalDarOferta
                  abdicado={abdicado}
                  alterandoOferta={alterandoOferta}
                  businessId={businessId}
                  calcularLance={calcularLance}
                  calcularRisco={calcularRisco}
                  calcularTituloCet={calcularTituloCet}
                  cnpj={cnpj}
                  darLance={darLance}
                  desagio={desagio}
                  diminuirOferta={diminuirOferta}
                  enviarArremate={enviarArremate}
                  formatarData={formatarData}
                  formatReal={formatReal}
                  fraseErro={fraseErro}
                  handleChange={handleChange}
                  initialTab={modal2.tab}
                  data={modal2.data}
                  novoFactor={novoFactor}
                  ofertaAtual={{
                    bidDiscountPerct: 0,
                    bidDiscountValue: 0,
                    // bidDiscountPerct: tituloOriginal.invoice.bidDiscountPerct,
                    // bidDiscountValue: tituloOriginal.invoice.descontoAtual,
                  }}
                  ofertaIgual={ofertaIgual}
                  onClose={toggle2}
                  open={modal2.open}
                  subirOferta={subirOferta}
                  titulo={titulo}
                  validando={validando}
                  values={values}
                />
              )}

              {tituloSelecionado && (
                <Dialog
                  scroll="body"
                  maxWidth="lg"
                  open={modalAbdicar}
                  onClose={toggleAbdicar}
                >
                  <DialogTitle
                    id="form-dialog-title"
                    style={{ fontFamily: "Poppins, sans-serif" }}
                  >
                    Especifique uma Taxa Fixa
                    <Tooltip
                      title="Ao definir uma taxa fixa, o título só poderá ser arrematado com a mesma taxa de CET definida por você."
                      placement="top"
                    >
                      <HelpOutlineIcon
                        style={{
                          marginLeft: "10px",
                        }}
                      />
                    </Tooltip>
                  </DialogTitle>
                  <DialogContent>
                    <CurrencyTextField
                      label="CET %am"
                      variant="outlined"
                      value={taxaAbdicar}
                      currencySymbol="%"
                      outputFormat="string"
                      decimalPlaces={2}
                      fullWidth
                      decimalCharacter="."
                      digitGroupSeparator=","
                      onChange={(event, value) => setTaxaAbdicar(value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => setModalAbdicar(false)}
                      color="primary"
                    >
                      Cancelar
                    </Button>
                    <Button onClick={() => abdicar(titulo)} color="primary">
                      Abdicar
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </div>
          </div>
        )}
      </ShowcaseContext.Consumer>
    </ShowcaseProvider>
  );
};

export default WithAllowedAccessPage()(Leilao);
