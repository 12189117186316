import { useState } from 'react';

import { timerPromiseSeconds } from 'utils/timer';

import Moment from 'moment';

import {
  buscarTokenSerpro,
  enviarPropostaSerpro,
  montarPropostaSerpro,
} from 'services/invoice';

import { useToast } from 'contexts/toast';

export function usePropostaSerpro({ invoiceId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState({
    valorMulta: false,
    txJurosMora: false,
    valorSeguro: false,
    dataLiberacao: false,
    nomeInstituicao: false,
    codPlataforma: false,
  });

  const [formValues, setFormValues] = useState({
    valorLiquido: null,
    txJurosMora: null,
    valorMulta: null,
    valorSeguro: null,
    inModalidade: 'Empréstimo com garantia',
    dataLiberacao: Moment(),
    nomeInstituicao: '',
    codPlataforma: '',
    idCotacao: '',
    invoiceId,
  });

  const { toast } = useToast();

  function handleDataLiberacaoChange(value) {
    setFormValues((old) => ({
      ...old,
      dataLiberacao: value,
    }));

    resetErrorValues();
  }

  function handleChange(event) {
    const { name, value } = event.target;
    const newState = {
      ...formValues,
      [name]: value,
    };

    setFormValues(newState);
    resetErrorValues();
  }

  function resetErrorValues() {
    setIsError({
      valorMulta: false,
      txJurosMora: false,
      valorSeguro: false,
      dataLiberacao: false,
      nomeInstituicao: false,
      codPlataforma: false,
    });
  }

  async function validateFormValues() {
    let isValid = true;

    if (!formValues.valorMulta) {
      setIsError((old) => ({
        ...old,
        valorMulta: true,
      }));
      await timerPromiseSeconds(0.1);
      isValid = false;
    }

    if (!formValues.txJurosMora) {
      setIsError((old) => ({
        ...old,
        txJurosMora: true,
      }));
      await timerPromiseSeconds(0.1);
      isValid = false;
    }

    if (!formValues.valorSeguro) {
      setIsError((old) => ({
        ...old,
        valorSeguro: true,
      }));
      await timerPromiseSeconds(0.1);
      isValid = false;
    }

    if (!formValues.dataLiberacao) {
      setIsError((old) => ({
        ...old,
        dataLiberacao: true,
      }));
      await timerPromiseSeconds(0.1);
      isValid = false;
    }

    if (!formValues.nomeInstituicao) {
      setIsError((old) => ({
        ...old,
        nomeInstituicao: true,
      }));
      await timerPromiseSeconds(0.1);
      isValid = false;
    }

    if (!formValues.codPlataforma) {
      setIsError((old) => ({
        ...old,
        codPlataforma: true,
      }));
      await timerPromiseSeconds(0.1);
      isValid = false;
    }

    return isValid;
  }

  function serializeForm() {
    // serializando data liberacao
    const serializedDataLiberacao = Moment(formValues.dataLiberacao).format(
      'YYYY-MM-DD'
    );

    return {
      ...formValues,
      dataLiberacao: serializedDataLiberacao,
    };
  }

  async function handleSendPropostaSerpro() {
    const isFormValid = await validateFormValues();

    if (!isFormValid)
      return toast.error('Verifique os campos obrigatórios', {
        id: 'proposta-serpro',
      });

    // serializando formulário
    const serializedForm = serializeForm();

    try {
      setIsLoading(true);

      toast.loading('Autenticando na serpro', { id: 'proposta-serpro' });
      const serproToken = await buscarTokenSerpro();
      toast.loading('Montando proposta', { id: 'proposta-serpro' });
      const propostaSerpro = await montarPropostaSerpro(serializedForm);
      toast.loading('Enviando proposta', { id: 'proposta-serpro' });
      await enviarPropostaSerpro({
        ...propostaSerpro,
        token: serproToken,
      });

      toast.success('Proposta enviada', { id: 'proposta-serpro' });
    } catch (error) {
      toast.error('Erro ao enviar proposta serpro', { id: 'proposta-serpro' });
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isLoading,
    formValues,
    handleChange,
    handleDataLiberacaoChange,
    handleSendPropostaSerpro,
    isError,
  };
}
