import AxiosInterceptor from "utils/axios-interceptor";
import axios from "axios";

export async function downloadAWSFile(fileUrl, fileName) {
  const { data } = await axios.get(fileUrl, {
    responseType: "arraybuffer",
    transformRequest: (data, headers) => {
      delete headers.common.Authorization;
      return data;
    },
  });
  return {
    data,
    name: `${fileName}`,
  };
}

export async function updateDossie(document) {
  const axiosInstance = AxiosInterceptor();
  const response = await axiosInstance.patch(
    `${process.env.REACT_APP_NODE_API}/files/update/${document.fileId}`,
    { ...document, downloadUrl: undefined, isChecked: undefined }
  );

  return response;
}
