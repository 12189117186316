import React from 'react';

import {InputAdornment, TextField } from '@material-ui/core';

import getDaysToGo from 'utils/diffDays';
import formatDate from 'utils/formatDate';

import moment from 'moment';

import { useToast } from 'contexts/toast';

import Calender from '../Icons/Calender';

export default (props) => {
  const { venclist } = props;

  const { toast } = useToast();

  return venclist.map((val, idx) => (
    <div
      key={idx || val.maturity_id}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 25,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 35,
          height: 35,
          backgroundColor: '#DDF2D6',
        }}
      >
        <span style={{ fontWeight: 500, fontSize: 13 }}>{idx + 1}</span>
      </div>
      <div style={{ width: 150 }}>
        <TextField
          className="lastroDetailsTextField"
          classes={{ root: 'lastroDetailsTextFieldWithIconRoot' }}
          autoFocus
          fullWidth
          id="dtvenc"
          label="Data Vencimento"
          name="vencimento"
          value={formatDate(venclist[idx].maturity_date)}
          variant="outlined"
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <Calender size={25} />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <div>
        <TextField
          fullWidth
          className="lastroDetailsTextField"
          classes={{ root: 'lastroDetailsTextFieldRoot' }}
          id="value"
          label="Valor"
          name="valor"
          variant="outlined"
          onBlur={(e) => {
            if (e.target.value == '' || e.target.value == 0) {
              toast.error(
                'ATENÇÃO: Você precisa informar um valor para a parcela.'
              );
            }
          }}
          value={venclist[idx].valor || venclist[idx].value || ''}
          disabled
        />
      </div>

      <div style={{ width: 150 }}>
        <TextField
          fullWidth
          className="lastroDetailsTextField"
          classes={{ root: 'lastroDetailsTextFieldRoot' }}
          id="days"
          label="Prazo em dias"
          name="dias"
          disabled
          variant="outlined"
          value={
            venclist[idx].dias ||
            `${venclist[idx].days ?? getDefaultDays(venclist, idx)}` ||
            ''
          }
        />
      </div>
    </div>
  ));
};

function getDefaultDays(venclist, idx) {
  return getDaysToGo(
    venclist[idx].dtvencimento ||
      venclist[idx].maturity_date ||
      moment().format('YYYY/MM/DD')
  );
}
