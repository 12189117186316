import AxiosInterceptor from "utils/axios-interceptor";

export const updatePassword = async (user) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/users/updatePassword`,
    user
  );
  return data;
};

export const createUpdateUser = async (updatedUser, shortOrigin) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/users/${
      updatedUser.userId === undefined ? "create" : "update"
    }/${shortOrigin}`,
    updatedUser
  );

  return updatedUser.userId === undefined && typeof data.userId !== "string"
    ? { ...data, userId: data.userId.value }
    : data;
};

export async function resetUserPasswordService(token, password) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/users/reset-password`;

  const payload = { token, password };
  const headers = {
    Authorization: token,
  };
  await axiosInstance.put(url, payload, headers);
}

export async function sendEmailToResetPasswordService(accessId) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/users/reset-password-email`;

  const payload = {
    accessId,
  };

  await axiosInstance.post(url, payload);
}
