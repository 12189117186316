import { useEffect, useState } from 'react';

import getDaysToGo from 'utils/diffDays';

import { getByInvoiceId } from 'services/maturity';
import { getMaturityExtensionByMaturityIds } from 'services/maturityExtension';

export function useMaturitys(invoiceId) {
  const [maturitys, setMaturitys] = useState(null);
  const [isLoadingMatutitys, setLoadingMaturitys] = useState(null);
  

  useEffect(() => {
    if (!invoiceId) return;
    handleGetMaturitysFromDatabase();
  }, [invoiceId]);

  async function handleGetMaturitysFromDatabase() {
    setLoadingMaturitys(true);
    let maturity = await getByInvoiceId(invoiceId);

    maturity = maturity.map((itemMaturity) => ({
      valor: itemMaturity.VALUE.toFixed(2),
      data: new Date(
        itemMaturity.MATURITY_DATE.replace('Z', '')
      ).toLocaleDateString(),
      dias: getDaysToGo(itemMaturity.MATURITY_DATE.replace('Z', '')),
      invoiceMaturityId: itemMaturity.MATURITY_ID,
      originalDate: new Date(itemMaturity.MATURITY_DATE.replace('Z', '')),
      statusName: itemMaturity.status_name,
      liquidationTypeId: itemMaturity.LIQUIDATION_TYPE_ID,
      liquidationDias: itemMaturity.LIQUIDATION_DATE
        ? getDaysToGo(itemMaturity.LIQUIDATION_DATE.replace('Z', ''))
        : null,
      liquidationDate: itemMaturity.LIQUIDATION_DATE
        ? new Date(itemMaturity.LIQUIDATION_DATE.replace('Z', ''))
        : null,
    }));

    const maturitiesExtensions = await getMaturityExtensionByMaturityIds({
      maturityIds: maturity.map((mat) => mat.invoiceMaturityId),
    });

    maturity = maturity.map((itemMaturity) => ({
      ...itemMaturity,
      extension: maturitiesExtensions.find(
        (matExt) => matExt.invoiceMaturityId === itemMaturity.invoiceMaturityId
      ),
    }));
    setLoadingMaturitys(false);
    setMaturitys(maturity);

    return maturity;
  }


  return { maturitys, handleGetMaturitysFromDatabase, isLoadingMatutitys };
}
