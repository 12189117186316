import React from 'react';
import ReactPlayer from 'react-player';

import './styles.scss';

export default function Videos({ sellerDocuments = [] }) {
  // documentTypeId 55 são os videos e inDossie 1 são os aprovados
  sellerDocuments = sellerDocuments.filter(
    (document) => document.documentTypeId === 55 && document.inDossie === 1
  );

  // Calcula o aspect ratio de 16x9 baseado na largura
  const videoHeight = (width) => (9 * width) / 16;

  return (
    <div>
      {sellerDocuments.length > 0 ? (
        <div className="cedente__videos">
          {sellerDocuments.map((document, index) => (
            <ReactPlayer
              key={document.name + index}
              url={document.url}
              controls
              config={{
                file: { attributes: { controlsList: 'nodownload' } },
              }}
              width="80%"
              height={videoHeight('80%')}
            />
          ))}
        </div>
      ) : (
        'Nenhum vídeo carregado'
      )}
    </div>
  );
}
