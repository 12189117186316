import AxiosInterceptor from "utils/axios-interceptor";

export async function emailCadastro(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/cadastro`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}

export async function emailCadastroAprovado(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/cadastroaprovado`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}

export async function emailCadastroNegado(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/cadastroreprovado`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}

export async function emailCadastroExpirado(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/cadastroexpirado`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}

export async function emailCadastroAguardandoDocumentacao(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/documentospendentes`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}

export async function emailTituloCriado(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/titulocriado`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailTituloAprovado(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/tituloaprovado`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailTituloCorrecao(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/titulocorrecao`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailTituloNegado(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/titulonegado`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailOfertaRecebida(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/ofertatitulo`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailTituloArrematadoCed(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/tituloarrematado-ced`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailTituloArrematadoFin(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/tituloarrematado-fin`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailTituloVencido(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/titulovencido`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailTituloLiquidado(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/tituloliquidado`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailTituloExpirado(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/tituloexpirado`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailOfertaRegistrada(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/oferta-registrada`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailOfertaCoberta(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/oferta-coberta`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailTituloCancelado(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/titulo-cancelado`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}

export async function emailAberturaContaBepay(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/abertura-conta`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailSolicitacaoProrrogacao(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/prorrogacao-solicitacao`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}

export async function emailRegistroProrrogacao(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/prorrogacao-registro`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailProrrogacaoPropostaRecebida(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/prorrogacao-recebida`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailProrrogacaoEnvioProposta(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/prorrogacao-enviada`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailProrrogacaoAprovada(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/prorrogacao-aprovada`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}
export async function emailProrrogacaoNegada(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/prorrogacao-negada`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}

export async function emailBoasVindasFIN(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/boasvindasfin`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}

export async function emailBoasVindasPAR(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/boasvindaspar`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}

export async function emailIndicacao(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/indicacao`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}

export async function emailIndicacaoCedente(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/indicacaocedente`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}

export async function emailIndicacaoAceita(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/indicacaoaceita`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}

export async function emailExrtatoIndicacao(payload) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/sendgrid/indicacaoextrato`;
  const { data } = await axiosInstance.post(url, payload);
  return data;
}

export function showError() {}
