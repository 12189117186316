class Supplier {
  constructor(
    supplierId,
    businessId,
    statusId,
    statusName,
    name,
    countryIdentity,
    officePhone,
    email,
    contactName
  ) {
    this.supplierId = supplierId;
    this.businessId = businessId;
    this.name = name;
    this.countryIdentity = countryIdentity;
    this.statusId = statusId;
    this.statusName = statusName;
    this.contactName = contactName;
    this.email = email;
    this.officePhone = officePhone;
  }
}

export default Supplier;
