import AxiosInterceptor from "utils/axios-interceptor";

export const getTagsServices = async () => {
  const axiosInstance = AxiosInterceptor();
  const tags = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/tags`
  );
  return tags;
};

export const getAllTagsServices = async () => {
  const axiosInstance = AxiosInterceptor();
  const tags = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/tags/all`
  );
  return tags;
};

export const getTagsByTypeIdServices = async (typeId) => {
  const axiosInstance = AxiosInterceptor();
  const tags = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/tags/all/${typeId}`
  );
  return tags;
};

export const saveTagServices = async (tag) => {
  const axiosInstance = AxiosInterceptor();
  await axiosInstance.post(`${process.env.REACT_APP_NODE_API}/tags`, tag);
  return true;
};

export const getInvoiceTagsService = async (invoiceid) => {
  const axiosInstance = AxiosInterceptor();
  const invoiceTags = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/invoicetags/${invoiceid}`
  );
  return invoiceTags;
};

export const postInvoiceTagService = async (invoiceId, tagId) => {
  const axiosInstance = AxiosInterceptor();
  const params = {
    invoiceId,
    tagId,
  };
  const invoiceTags = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/invoicetags`,
    params
  );
  return invoiceTags;
};

export const deleteTagServices = (tagId) => {
  const axiosInstance = AxiosInterceptor();
  axiosInstance.delete(`${process.env.REACT_APP_NODE_API}/tags/${tagId}`);
};
