const formatDate = (date) =>
  `${
    new Date(date).getDate() < 10
      ? `0${new Date(date).getDate()}`
      : new Date(date).getDate()
  }/${
    new Date(date).getMonth() + 1 < 10
      ? `0${new Date(date).getMonth() + 1}`
      : new Date(date).getMonth() + 1
  }/${new Date(date).getFullYear()}`;

export default formatDate;
