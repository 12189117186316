import { useEffect, useState } from 'react';

import Customer from 'pages/CadastroFinaciadorCedente/tabs/classes/Customer';
import Supplier from 'pages/CadastroFinaciadorCedente/tabs/classes/Supplier';

import { getBigBoostCnpj } from 'services/business';
import {
  deleteCustomersService,
  deleteSuppliersService,
  getCustomersByBusinessId,
  getSuppliersByBusinessId,
  updateCustomersService,
  updateSuppliersService,
} from 'services/clientes-cedente';
import { getQuiz } from 'services/quiz';

import { useAuth } from 'contexts/auth';
import { useToast } from 'contexts/toast';

import { useModal } from 'hooks/useModal';

export function useClientesCedente({
  business,
  businessCustomers,
  setBusinessCustomers,
  businessSuppliers,
  setBusinessSuppliers,
}) {
  const { isAdmin, canSelfUpdate, isCedente } = useAuth();
  const { toast } = useToast();
  const { openModal } = useModal();

  const [removedCustomers, setRemovedCustomers] = useState([]);
  const [removedSuppliers, setRemovedSuppliers] = useState([]);
  const [allFilled, setAllFilled] = useState(true);
  const [savingCustomers, setSavingCustomers] = useState(false);

  const fillRegisterData = async (cnpj) => {
    try {
      setSavingCustomers(true);
      const quiz = await getQuiz(cnpj);
      const clientCNPJ = quiz.statusQuiz.CountryIdentityClient;
      const bigBoostClientData = await toast.promise(
        getBigBoostCnpj(clientCNPJ),
        {
          loading: 'Carregando dados',
          success: 'Dados carregados com sucesso!',
          error: 'Erro ao preencher campos',
        }
      );
      const bigBoostClient = bigBoostClientData.result.value;

      const mainCustomer = new Customer();
      mainCustomer.businessId = business.businessId;
      if (bigBoostClient?.Razao_Social)
        mainCustomer.name = bigBoostClient?.Razao_Social;
      mainCustomer.countryIdentity = clientCNPJ;

      await toast.promise(updateCustomers([mainCustomer]), {
        loading: 'Guardando dados',
        success: 'Dados guardados com sucesso!',
        error: 'Erro ao guardar dados',
      });

      setSavingCustomers(false);
    } catch (error) {
      const newCustomer = new Customer();
      newCustomer.businessId = business.businessId;
      setBusinessCustomers([newCustomer]);
      setSavingCustomers(false);
    }
  };

  const stringAttributeIsInvalid = (attr) =>
    attr === undefined || attr === '' || attr === null;

  async function updateCustomers(businessCustomers) {
    if (businessCustomers === undefined || savingCustomers) return;

    setSavingCustomers(true);

    const isCustomerCountryIdentityFilled = !businessCustomers.some(
      (customer) => stringAttributeIsInvalid(customer.countryIdentity)
    );

    if (isCustomerCountryIdentityFilled) {
      const updateCustomersPromise = updateCustomersService(businessCustomers);

      const promises = [updateCustomersPromise];
      const hasCustomersToDelete =
        removedCustomers.filter((removed) => removed.customerId !== undefined)
          .length > 0;

      if (hasCustomersToDelete) {
        promises.push(deleteCustomersService(removedCustomers));
      }

      await toast.promise(Promise.all(promises), {
        loading: 'Atualizando clientes',
        success: 'Clientes atualizados',
        error: 'Erro ao atualizar clientes',
      });

      const updatedCustomers = await getCustomersByBusinessId(
        business.businessId
      );

      setBusinessCustomers(updatedCustomers);

      setRemovedCustomers([]);
      setSavingCustomers(false);

      if (isCedente()) {
        openModal(
          'info',
          'Atenção!',
          'Vá para a próxima página para continuar o cadastro.'
        );
      }
    } else {
      setAllFilled(false);
      toast.error('Por favor, preencha todos os dados');
      window.scrollTo(0, 0);
      setSavingCustomers(false);
    }
  }

  async function updateSuppliers() {
    if (businessCustomers === undefined || savingCustomers) return;

    setSavingCustomers(true);

    const isSupplierCountryIdentityFilled = !businessSuppliers.some(
      (supplier) => stringAttributeIsInvalid(supplier.countryIdentity)
    );

    if (isSupplierCountryIdentityFilled) {
      setAllFilled(true);
      setSavingCustomers(false);

      const suppliersResponse = updateSuppliersService(businessSuppliers);

      const promises = [suppliersResponse];

      const hasSupplierstoDelete =
        removedSuppliers.filter((removed) => removed.supplierId !== undefined)
          .length > 0;

      if (hasSupplierstoDelete)
        promises.push(deleteSuppliersService(removedSuppliers));

      await toast.promise(Promise.all(promises), {
        loading: 'Atualizando fornecedores',
        success: 'Fornecedores atualizados',
        error: 'Erro ao atualizar fornecedores',
      });

      const updatedSuppliers = await getSuppliersByBusinessId(
        business.businessId
      );

      setBusinessSuppliers(updatedSuppliers);

      setRemovedCustomers([]);

      setSavingCustomers(false);

      if (isCedente()) {
        openModal(
          'info',
          'Atenção!',
          'Vá para a próxima página para continuar o cadastro.'
        );
      }
    } else {
      setAllFilled(false);
      toast.error('Por favor, preencha todos os dados!');
      window.scrollTo(0, 0);
      setSavingCustomers(false);
    }
  }

  useEffect(() => {
    if (
      businessCustomers.length === 0 &&
      business &&
      canSelfUpdate(business.statusId)
    ) {
      fillRegisterData(business.countryIdentity);
    }
  }, []);

  const changeBusinessCustomers = (newState, index) => {
    const customers = businessCustomers;
    customers[index] = newState;

    setBusinessCustomers(customers);
  };

  const changeBusinessSuppliers = (newState, index) => {
    const suppliers = businessSuppliers;
    suppliers[index] = newState;

    setBusinessSuppliers(suppliers);
  };

  const handlerChange = (e, state, setter, index, supplier) => {
    const newState = { ...state, [e.target.name]: e.target.value };

    if (!supplier) changeBusinessCustomers(newState, index);
    else changeBusinessSuppliers(newState, index);

    setter(newState);
  };

  const addCustomer = () => {
    const customers = businessCustomers;
    const newCustomer = new Customer();
    newCustomer.businessId = business.businessId;
    customers.push(newCustomer);

    setBusinessCustomers([...customers]);
  };

  const removeCustomer = (index) => {
    if (businessCustomers.length === 0) return;
    setRemovedCustomers([...removedCustomers, businessCustomers[index]]);

    const customers = businessCustomers;
    customers.splice(index, 1);

    setBusinessCustomers(customers);
  };

  const addSupplier = () => {
    const suppliers = businessSuppliers;
    const newSupplier = new Supplier();
    newSupplier.businessId = business.businessId;
    suppliers.push(newSupplier);

    setBusinessSuppliers([...suppliers]);
  };

  const removeSupplier = (index) => {
    if (businessSuppliers.length === 0) return;
    setRemovedSuppliers([...removedSuppliers, businessSuppliers[index]]);

    const suppliers = businessSuppliers;
    suppliers.splice(index, 1);

    setBusinessSuppliers(suppliers);
  };

  return {
    updateCustomers,
    updateSuppliers,
    removedCustomers,
    setRemovedCustomers,
    removedSuppliers,
    setRemovedSuppliers,
    allFilled,
    setAllFilled,
    savingCustomers,
    setSavingCustomers,
    handlerChange,
    addCustomer,
    removeCustomer,
    addSupplier,
    removeSupplier,
    stringAttributeIsInvalid,
    canSelfUpdate,
    isAdmin,
  };
}
