export const headCells = [
  {
    id: 'name',
    label: 'Empresa',
    minWidth: 50,
    backgroundColor: '#fafafa',
  },
  {
    id: 'bankName',
    label: 'Banco',
    minWidth: 130,
    maxWidth: 130,
    backgroundColor: '#fafafa',
  },
  {
    id: 'statusDate',
    label: 'Última Atualização',
    minWidth: 200,
    maxWidth: 216,
    backgroundColor: '#fafafa',
  },
  {
    id: 'accountStatusName',
    label: 'Status',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
];

export const status = [
  { statusId: 1, statusName: 'LIBERADA', backgroundColor: '#39854d' },
  { statusId: 2, statusName: 'BLOQUEADA', backgroundColor: '#c21d36' },
  { statusId: 3, statusName: 'NEGADA', backgroundColor: '#c21d36' },
  { statusId: 4, statusName: 'ABERTA', backgroundColor: '#366199' },
];
