import React, { useState } from 'react';

import {
  Dialog,
  Card,
  CircularProgress,
  useTheme,
  DialogContent,
  TextField,
  DialogActions,
  Button,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import { CancelButton, ConfirmButton, InfoButton } from 'components/Button';

import { useModal } from 'hooks/useModal';

export function ModalWarning() {
  const { modalState, closeModal, confirmModal, isLoading } = useModal();

  return (
    <>
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={modalState.visible}
        scroll="body"
        maxWidth="sm"
      >
        <Card className="card-box p-4 mb-4">
          <div className="text-center">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div
                className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper text-success m-0 d-130 align-items-center"
                style={{ backgroundColor: '#fff4e5' }}
              >
                {isLoading ? (
                  <>
                    <CircularProgress color="primary" />
                  </>
                ) : (
                  <ReportProblemOutlinedIcon
                    style={{
                      color: '#ff9800',
                      fontSize: 56,
                    }}
                  />
                )}
              </div>
            </div>
            <h4
              className="font-weight-bold mt-4"
              style={{
                fontFamily: 'Poppins',
                fontSize: '26px',
                textAlign: 'center',
                color: '#663c00',
              }}
            >
              {modalState.message}
            </h4>
            <p
              className="mb-0 font-size-lg"
              style={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                color: '#212529',
              }}
            >
              {modalState.description}
            </p>
            <div
              className="pt-4"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CancelButton onClick={closeModal}>Cancelar</CancelButton>

              <div style={{ marginRight: '30px' }} />

              <ConfirmButton onClick={confirmModal}>Confirmar</ConfirmButton>
            </div>
          </div>
        </Card>
      </Dialog>
    </>
  );
}

export function ModalSuccess() {
  const { modalState, closeModal, confirmModal, isLoading } = useModal();

  return (
    <>
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={modalState.visible}
        scroll="body"
        maxWidth="sm"
      >
        <Card className="card-box p-4 mb-4">
          <div className="text-center">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div
                className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper text-success m-0 d-130 align-items-center"
                style={{ backgroundColor: '#edf7ed' }}
              >
                {isLoading ? (
                  <CircularProgress color="primary" />
                ) : (
                  <CheckCircleOutlineIcon
                    style={{
                      color: '#4caf50',
                      fontSize: 56,
                    }}
                  />
                )}
              </div>
            </div>
            <h4
              className="font-weight-bold mt-4"
              style={{
                fontFamily: 'Poppins',
                fontSize: '26px',
                textAlign: 'center',
                color: '#1e4620',
              }}
            >
              {modalState.message}
            </h4>
            <p
              className="mb-0 font-size-lg"
              style={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                color: '#212529',
              }}
            >
              {modalState.description}
            </p>
            <div
              className="pt-4"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CancelButton onClick={closeModal}>Cancelar</CancelButton>

              <div style={{ marginRight: '30px' }} />

              <ConfirmButton onClick={confirmModal}>Confirmar</ConfirmButton>
            </div>
          </div>
        </Card>
      </Dialog>
    </>
  );
}

export function ModalInfo() {
  const { modalState, isLoading, closeModalScroll } = useModal();

  return (
    <>
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={modalState.visible}
        scroll="body"
        maxWidth="sm"
      >
        <Card className="card-box p-4 mb-4">
          <div className="text-center">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div
                className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper text-success m-0 d-130 align-items-center"
                style={{ backgroundColor: '#e8f4fd' }}
              >
                {isLoading ? (
                  <CircularProgress color="primary" />
                ) : (
                  <InfoOutlinedIcon
                    style={{
                      color: '#2196f3',
                      fontSize: 56,
                    }}
                  />
                )}
              </div>
            </div>
            <h4
              className="font-weight-bold mt-4"
              style={{
                fontFamily: 'Poppins',
                fontSize: '26px',
                textAlign: 'center',
                color: '#0d3c61',
              }}
            >
              {modalState.message}
            </h4>
            <p
              className="mb-0 font-size-lg"
              style={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                color: '#212529',
              }}
            >
              {modalState.description}
            </p>
            <div className="pt-4">
              <InfoButton onClick={closeModalScroll}>Ok</InfoButton>
            </div>
          </div>
        </Card>
      </Dialog>
    </>
  );
}

export function ModalError() {
  const { modalState, closeModal, confirmModal, isLoading } = useModal();

  return (
    <>
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={modalState.visible}
        scroll="body"
        maxWidth="sm"
      >
        <Card className="card-box p-4 mb-4">
          <div className="text-center">
            <div className="avatar-icon-wrapper rounded-circle m-0">
              <div
                className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper text-success m-0 d-130 align-items-center "
                style={{ backgroundColor: '#fdecea' }}
              >
                {isLoading ? (
                  <CircularProgress color="primary" />
                ) : (
                  <InfoOutlinedIcon
                    style={{
                      color: '#f44336',
                      fontSize: 56,
                    }}
                  />
                )}
              </div>
            </div>
            <h4
              className="font-weight-bold mt-4"
              style={{
                fontFamily: 'Poppins',
                fontSize: '26px',
                textAlign: 'center',
                color: '#611a15',
              }}
            >
              {modalState.message}
            </h4>
            <p
              className="mb-0 font-size-lg"
              style={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                color: '#212529',
              }}
            >
              {modalState.description}
            </p>
            <div
              className="pt-4"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CancelButton onClick={closeModal}>Cancelar</CancelButton>

              <div style={{ marginRight: '30px' }} />

              <ConfirmButton onClick={confirmModal}>Confirmar</ConfirmButton>
            </div>
          </div>
        </Card>
      </Dialog>
    </>
  );
}

export function ModalLoading() {
  const { modalState } = useModal();
  const { palette } = useTheme();

  return (
    <>
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={modalState.visible}
        scroll="body"
        // maxWidth="xl"
      >
        <Card
          style={{
            width: '300px',
            height: '250px',
            display: 'flex',
            flexDirection: 'column',

            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            backgroundColor: `${palette.primary.main}`,
          }}
        >
          <CircularProgress
            style={{ color: `${palette.secondary.contrastText}` }}
            value={55}
          />
        </Card>
      </Dialog>
    </>
  );
}

export const TextFieldModal = () => {
  const { modalState, confirmTextInputModal, closeModal } = useModal();

  const [textInput, setTextInput] = useState('');

  return (
    <Dialog
      open={modalState.visible}
      onClose={closeModal}
      aria-labelledby="form-dialog-title"
    >
      <h4
        id="form-dialog-title"
        style={{ fontFamily: 'Poppins, sans-serif', textAlign: 'center' }}
      >
        {modalState.label}
      </h4>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          type={modalState.typePassword}
          id="modal-text-field"
          label={modalState.label}
          fullWidth
          value={textInput}
          onChange={(e) => setTextInput(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary">
          {modalState.cancelButtonText ?? 'Cancelar'}
        </Button>
        <Button
          onClick={() => confirmTextInputModal(textInput)}
          color="primary"
        >
          {modalState.confirmButtonText ?? 'Confirmar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
