import React from 'react';

import { Typography } from '@material-ui/core';

export default function TitleSection({ title }) {
  return (
    <Typography
      variant="h5"
      style={{
        backgroundColor: '#FDEDDD',
        padding: 15,
        fontSize: 22,
        fontWeight: 'bold',
        fontFamily: 'Poppins',
      }}
    >
      {title}
    </Typography>
  );
}
