import React from 'react';

import { Button as MiuiButton } from '@material-ui/core/';

import { ButtonStyle } from 'styles/material/button';

export function ConfirmButton({ children, ...props }) {
  const classes = ButtonStyle();

  return (
    <>
      <MiuiButton
        variant="contained"
        color="primary"
        classes={{ root: classes.Confirm }}
        {...props}
      >
        {children}
      </MiuiButton>
    </>
  );
}

export function CancelButton({ children, ...props }) {
  const classes = ButtonStyle();

  return (
    <>
      <MiuiButton
        variant="contained"
        classes={{ root: classes.Cancel }}
        {...props}
      >
        {children}
      </MiuiButton>
    </>
  );
}

export function InfoButton({ children, ...props }) {
  const classes = ButtonStyle();

  return (
    <>
      <MiuiButton
        variant="contained"
        classes={{ root: classes.Info }}
        {...props}
      >
        {children}
      </MiuiButton>
    </>
  );
}
