import querystring from "querystring";
import toast from "react-hot-toast";
import { response } from "msw";
import AxiosInterceptor from "utils/axios-interceptor";

export async function getInvoiceById(invoiceId, shortOrigin) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/invoice/filter?invoiceId=${invoiceId}&shortOrigin=${shortOrigin}`
  );

  return data;
}

export async function updateRiskInvoice(newRisk, invoiceId) {
  const axiosInstance = AxiosInterceptor();
  if (!newRisk) throw new Error("Nenhum objeto informado");
  const { data } = await axiosInstance.put(
    `${process.env.REACT_APP_NODE_API}/invoice/update/risk/${invoiceId}`,
    newRisk
  );

  return data;
}

export async function updateStatusInvoice(
  statusId,
  statusName,
  invoiceId,
  userId
) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.patch(
    `${process.env.REACT_APP_NODE_API}/invoice/update/status/${invoiceId}`,
    {
      invoiceStatusId: statusId,
      invoiceStatusName: statusName,
      invoiceId,
      userId,
    }
  );

  return data;
}

export async function updatemaxOffer(maxOffer, invoiceId) {
  const axiosInstance = AxiosInterceptor();
  if (!maxOffer) return;
  if (parseInt(maxOffer) < 0) {
    return toast.error(
      "Erro ao alterar o valor da taxa. O valor deve ser maior que zero."
    );
  }

  axiosInstance
    .put(`${process.env.REACT_APP_NODE_API}/invoice/update/maxOffer`, {
      invoiceId,
      maxOffer,
    })
    .then((response) => {
      toast.success("Taxa máxima alterado com sucesso!");
      return response;
    })
    .catch(() => toast.error("Erro ao alterar o valor da taxa."));
}

export async function updateProductType(productName, invoiceId) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.patch(
    `${process.env.REACT_APP_NODE_API}/invoice/update/type-product/${invoiceId}`,
    {
      typeProduct: productName,
    }
  );

  return data;
}

export async function updateMarketType(market, invoiceId) {
  const axiosInstance = AxiosInterceptor();
  if (!market || !invoiceId) return;
  const { data } = await axiosInstance.put(
    `${process.env.REACT_APP_NODE_API}/invoice/update/market`,
    {
      marketId: market.id,
      marketName: market.name,
      invoiceId,
    }
  );

  return data;
}

export async function getMarket(invoiceId) {
  const axiosInstance = AxiosInterceptor();
  if (!invoiceId) return;
  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/invoice/market/${invoiceId}`
  );

  return data;
}

export async function updateInvoiceBuyers(invoiceBuyersArr, invoiceId) {
  const axiosInstance = AxiosInterceptor();
  if (!invoiceId) return;

  const payload = {
    invoiceId,
    buyersIdArr: invoiceBuyersArr,
  };
  await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/InvoiceBuyer`,
    payload
  );
}

export async function getInvoiceBuyers(invoiceId) {
  const axiosInstance = AxiosInterceptor();
  if (!invoiceId) return;

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/InvoiceBuyer/${invoiceId}`
  );

  return data;
}

export async function montarPropostaSerpro({
  invoiceId,
  valorLiquido,
  txJurosMora,
  valorMulta,
  valorSeguro,
  inModalidade,
  dataLiberacao,
  nomeInstituicao,
  codPlataforma,
  idCotacao,
}) {
  const axiosInstance = AxiosInterceptor();
  const payload = {
    invoiceId,
    valorLiquido: Number(valorLiquido),
    txJurosMora: Number(txJurosMora),
    valorMulta: Number(valorMulta),
    valorSeguro: Number(valorSeguro),
    inModalidade,
    dataLiberacao,
    nomeInstituicao,
    codPlataforma,
    idCotacao,
  };

  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/invoice/montar-proposta-serpro`,
    payload
  );

  return data;
}

export async function buscarTokenSerpro() {
  const axiosInstance = AxiosInterceptor();
  const options = {
    data: querystring.stringify({ grant_type: "client_credentials" }),
    method: "POST",
    url: "https://gateway.apiserpro.serpro.gov.br/token",
    headers: {},
  };

  const { data } = await axiosInstance.request(options);

  return data.access_token;
}

export async function enviarPropostaSerpro({
  idProposta,
  valorEmprestimo,
  valorLiquido,
  numeroParcelas,
  valorParcela,
  fluxoPagamento,
  txJuros,
  txJurosMora,
  valorMulta,
  valorSeguro,
  valorIOF,
  valorTAC,
  valorCET,
  inModalidade,
  dataLiberacao,
  dataUltimaParcela,
  codInstituicao,
  nomeInstituicao,
  codPlataforma,
  nomePlataforma,
  linkPlataforma,
  idCotacao,
  token,
}) {
  const axiosInstance = AxiosInterceptor();
  // SE EM PROD, USAR TOKEN DO PARÂMETRO
  token =
    process.env.ENV_NODE == "production"
      ? token
      : "06aef429-a981-3ec5-a1f8-71d38d86481e";

  const options = {
    method: "POST",
    url: "https://gateway.apiserpro.serpro.gov.br/antecipagov-trial/v1/IncluirProposta",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data: {
      idProposta,
      valorEmprestimo,
      valorLiquido,
      numeroParcelas,
      valorParcela,
      fluxoPagamento,
      txJuros,
      txJurosMora,
      valorMulta,
      valorSeguro,
      valorIOF,
      valorTAC,
      valorCET,
      inModalidade,
      dataLiberacao,
      dataUltimaParcela,
      codInstituicao,
      nomeInstituicao,
      codPlataforma,
      nomePlataforma,
      linkPlataforma,
      idCotacao,
    },
  };

  await axiosInstance.request(options);
}

export async function getLasBuyer(invoiceId) {
  const axiosInstance = AxiosInterceptor();
  const body = {
    invoiceid: invoiceId,
  };
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/invoice/ultimocomprador`,
    body
  );
  return data;
}

export async function arremateTituloCedente(invoiceId, userId, businessId) {
  const axiosInstance = AxiosInterceptor();
  const body = {
    invoiceId,
    userId,
    businessId,
  };

  const { data } = await axiosInstance.put(
    `${process.env.REACT_APP_NODE_API}/invoice/arremate/cedente`,
    body
  );

  return data;
}

export async function buscarParcelasLance(invoiceId) {
  const axiosInstance = AxiosInterceptor();
  const options = {
    method: "GET",
    url: `${process.env.REACT_APP_NODE_API}/invoice/parcelas-lance/${invoiceId}`,
  };

  const { data } = await axiosInstance.request(options);

  return data;
}

export async function verificarTituloArrematado(invoiceId) {
  const axiosInstance = AxiosInterceptor();
  const options = {
    method: "GET",
    url: `${process.env.REACT_APP_NODE_API}/invoice/verificar/titulo/arrematado/${invoiceId}`,
  };

  const { data } = await axiosInstance.request(options);

  return data;
}
