import React from "react";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import AssignmentIcon from "@material-ui/icons/AssignmentOutlined";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import BallotIcon from "@material-ui/icons/BallotOutlined";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import ChatIcon from "@material-ui/icons/ChatOutlined";
import CodeIcon from "@material-ui/icons/Code";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import DashboardTwoToneIcon from "@material-ui/icons/DashboardTwoTone";
import DescriptionIcon from "@material-ui/icons/Description";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import FolderIcon from "@material-ui/icons/FolderOutlined";
import GavelIcon from "@material-ui/icons/GavelOutlined";
import GradeTwoTone from "@material-ui/icons/GradeTwoTone";
import GroupIcon from "@material-ui/icons/Group";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import LabelIcon from "@material-ui/icons/Label";
import ListIcon from "@material-ui/icons/List";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import LockOpenIcon from "@material-ui/icons/LockOpenOutlined";
import MailIcon from "@material-ui/icons/MailOutlined";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import PersonIcon from "@material-ui/icons/PersonOutlined";
import PieChartIcon from "@material-ui/icons/PieChart";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import StarIcon from "@material-ui/icons/Star";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import ThumbUp from "@material-ui/icons/ThumbUp";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import BuildingsIcon from "./icons/Buildings";
import ContractIcon from "./icons/Contract";
import DiscountVoucherIcon from "./icons/DiscountVoucher";
import HomeIcon from "./icons/Home";
import VitrineIcon from "./icons/Vitrine";
import { AccountBalanceWallet } from "@material-ui/icons";
import { Forum } from "@material-ui/icons";


const iconsMap = {
  BarChartIcon: DiscountVoucherIcon,
  CalendarTodayIcon,
  ChatIcon,
  CodeIcon,
  DashboardIcon,
  ErrorIcon,
  FolderIcon,
  DashboardTwoToneIcon,
  GradeTwoTone,
  ListAltIcon,
  LockOpenIcon,
  MailIcon,
  PresentToAllIcon,
  PeopleIcon,
  PersonIcon,
  ReceiptIcon,
  SettingsIcon,
  ViewModuleIcon,
  ListIcon,
  ThumbUp,
  AssignmentIcon,
  Gavel: GavelIcon,
  Ballot: BallotIcon,
  LabelIcon,
  AccountBalanceIcon,
  HomeWorkIcon,
  LocalAtmIcon: ContractIcon,
  TrendingUpIcon: VitrineIcon,
  InsertChartIcon,
  PieChartIcon,
  CreditCardIcon,
  LocalOfferIcon,
  DescriptionIcon,
  AttachMoneyIcon,
  EmojiTransportationIcon: BuildingsIcon,
  StarIcon,
  SwapHorizIcon,
  GroupAddIcon,
};

export const externalMenus = {
  antecipaGOV: {
    name: "AntecipaGOV",
    uri: "https://gov.antecipa.rec.br/antecipaGov",
    description: "AntecipaGOV",
    icon: <StarIcon />,
  },
  antigaAF: {
    name: "Plataforma Antiga",
    uri: "https://app.antecipafacil.com.br/",
    description: "Plataforma Antiga AF",
    icon: <SwapHorizIcon />,
  },
};

export function loadPerfilList(userTypeId) {
  if (userTypeId == 3) {
    return adminMenuList;
  }
  if (userTypeId == 2) {
    return buyerMenuList;
  }
  if (userTypeId == 1) {
    return sellerMenuList;
  }
}

export const adminMenuList = [
  {
    name: "Home",
    uri: "/",
    description: "Home Dashboard",
    icon: <HomeIcon />,
    iconActive: <HomeIcon />,
  },
  {
    name: "Cadastro",
    uri: "/listagem",
    description: "Listagem de Cadastro de Usuários",
    icon: <HomeWorkIcon />,
    iconActive: <HomeWorkIcon />,
  },
  {
    name: "Títulos",
    uri: "/invoices",
    description: "Listagem de Títulos",
    icon: <ContractIcon />,
    iconActive: <ContractIcon primary />,
  },
  {
    name: "Parcelas",
    uri: "/listagem-parcelas",
    description: "Listagem de Parcelas",
    icon: <VitrineIcon />,
    iconActive: <VitrineIcon primary />,
  },
  {
    name: "Gestão de Contas",
    uri: "/listagem-contas",
    description: "Listagem de Contas",
    icon: <CreditCardIcon />,
    iconActive: <CreditCardIcon />,
  },
  {
    name: "Gestão de Contas 2.0",
    uri: "/gestao-contas",
    disabled: false,
    description: "Gestão de Contas",
    icon: <AccountBalanceIcon />,
    iconActive: <AccountBalanceIcon />,
  },
  {
    name: "Cadastro de Tags",
    uri: "/cadastro-tags",
    description: "Cadastrar Tags",
    icon: <LabelIcon />,
    iconActive: <LabelIcon />,
  },
  {
    name: "Cotações",
    uri: "/cotacoes",
    description: "Relação de Cotações",
    icon: <LocalOfferIcon />,
    iconActive: <LocalOfferIcon />,
  },
  {
    name: "Pré Aprovados",
    uri: "/bradesco-pre-aprovados",
    description: "Bradesco Pré Aprovados",
    icon: <CardTravelIcon />,
    iconActive: <CardTravelIcon />,
  },
  {
    name: "Criar WhiteLabel",
    uri: "/cadastro-whitelabel",
    description: "Criar WhiteLabel",
    icon: <GroupAddIcon />,
    iconActive: <GroupAddIcon />,
  },
  // externalMenus.antigaAF,
];

export const sellerMenuList = [
  {
    name: "Home",
    uri: "/",
    description: "Home Dashboard",
    icon: <HomeIcon />,
    iconActive: <HomeIcon />,
  },
  // {
  //   name: 'Empresa',
  //   uri: '/cadastro',
  //   description: 'Dados da empresa',
  //   icon: <BuildingsIcon />,
  //   iconActive: <BuildingsIcon primary />,
  // },
  {
    name: "Dados Cadastrais",
    uri: "/register-company",
    description: "Dados Cadastrais",
    icon: <BuildingsIcon />,
    iconActive: <BuildingsIcon primary />,
    redirectToV2: true,
  },
  {
    name: "Agenda de Recebíveis",
    uri: "/agenda-recebiveis",
    description: "Agendar Recebíveis",
    icon: <AttachMoneyIcon />,
    iconActive: <AttachMoneyIcon />,
  },
  {
    name: "Meus Títulos",
    uri: "/invoices",
    description: "Meus Títulos",
    icon: <ContractIcon />,
    iconActive: <ContractIcon primary />,
  },
  {
    name: "Conta Consignada",
    uri: "/client-extract",
    description: "conta-consignada",
    icon: <AccountBalanceWallet />,
    iconActive: <AccountBalanceWallet />,
    redirectToV2: true,
  },
  // externalMenus.antigaAF,
  {
    name: "Usuários",
    uri: "/users",
    description: "Usuários",
    icon: <GroupIcon />,
    iconActive: <GroupIcon />,
  },
];

export const buyerMenuList = [
  {
    name: "Home",
    uri: "/",
    description: "Home Dashboard",
    icon: <HomeIcon />,
    iconActive: <HomeIcon primary />,
  },
  {
    name: "Cedentes",
    uri: "/customers-list",
    description: "Lista de Cedentes",
    icon: <GroupIcon />,
    iconActive: <GroupIcon />,
    redirectToV2: true,
  },
  {
    name: "Vitrine",
    uri: "/vitrine",
    description: "Vitrine",
    icon: <VitrineIcon />,
    iconActive: <VitrineIcon primary />,
  },

  {
    name: "Meus Títulos",
    uri: "/invoices",
    description: "Meus Títulos",
    icon: <ContractIcon />,
    iconActive: <ContractIcon primary />,
  },
  // {
  //   name: "Empresa",
  //   uri: "/cadastro",
  //   description: "Dados da empresa",
  //   icon: <BuildingsIcon />,
  //   iconActive: <BuildingsIcon primary />,
  // },
  {
    name: "Empresa",
    uri: "/register-company",
    description: "Dados Cadastrais",
    icon: <BuildingsIcon />,
    iconActive: <BuildingsIcon primary />,
    redirectToV2: true,
  },
  {
    name: "Parcelas",
    uri: "/parcelas-fin",
    description: "Listagem de Parcelas",
    icon: <DiscountVoucherIcon />,
    iconActive: <DiscountVoucherIcon primary />,
  },
  {
    name: "Fórum",
    uri: "https://antecipafacil.discourse.group",
    description: "Fórum",
    icon: <Forum onClick={() => window.open("https://antecipafacil.discourse.group", '_blank')} />,
    iconActive: <Forum onClick={() => window.open("https://antecipafacil.discourse.group", '_blank')} />,
  },

  // externalMenus.antigaAF,
];

export default (string_list) => [
  {
    label: "Menu",
    content: JSON.parse(string_list, (key, value) => {
      if (key === "icon") {
        return iconsMap[value];
      }
      return value;
    }),
  },
];
