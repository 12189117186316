import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptBR from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import useCompany from 'hooks/useCompany';
import * as moment from 'moment';
import React, { useState } from 'react';
import * as Yup from 'yup';
import useExtract from '../../../hooks/useExtract';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function ExtractModal({ account, accountData }) {
  const [filters, setFilters] = useState(null);

  const [selectedMaxDate, setSelectedMaxDate] = useState(new Date());
  const [fetchExtract, setFetchExtract] = useState(false);
  const {
    company,
    isLoading: isLoadingCompany,
    error: errorCompany,
  } = useCompany(true, account.businessId);

  const { extract, isValidating, isLoading, error } = useExtract(
    account?.accountId,
    filters,
    fetchExtract
  );

  const {
    values,
    errors,
    setFieldValue,
    isValid,
    handleSubmit,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      initialDate: null,
      endDate: null,
    },

    validationSchema: Yup.object().shape({
      initialDate: Yup.mixed().required('Informe uma data inicial'),
      endDate: Yup.mixed().required('Informe uma data final'),
    }),

    onSubmit: async (values) => {
      setFetchExtract(false);
      setFetchExtract(true);
      setFilters({ ...values });
    },
  });

  return (
    <Box>
      <Paper elevation={4} variant="outlined">
        <Box mb={4} mt={4} pr={4} pl={4}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={5}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <DatePicker
                    cancelLabel="Cancelar"
                    fullWidth
                    locale={ptBR}
                    helperText={touched.initialDate ? errors.initialDate : ''}
                    error={Boolean(errors.initialDate) && touched.initialDate}
                    name="initialDate"
                    id="initialDate"
                    label="Data Inicial"
                    onBlur={handleBlur}
                    value={values.initialDate}
                    format="dd/MM/yyyy"
                    minDate={new Date(account?.createdAt)}
                    maxDate={selectedMaxDate}
                    onChange={(value) =>
                      setFieldValue('initialDate', value.toISOString())
                    }
                    TextFieldComponent={(props) => (
                      <TextField
                        {...props}
                        className="text-field-label"
                        variant="outlined"
                        InputProps={{
                          style: {
                            borderRadius: 20,
                            color: '#000',
                            fontSize: 22,
                          },
                        }}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} sm={12} md={5}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <DatePicker
                    cancelLabel="Cancelar"
                    fullWidth
                    locale={ptBR}
                    helperText={touched.endDate ? errors.endDate : ''}
                    error={Boolean(errors.endDate) && touched.endDate}
                    name="endDate"
                    id="endDate"
                    label="Data Final"
                    onBlur={handleBlur}
                    value={values.endDate}
                    format="dd/MM/yyyy"
                    maxDate={new Date()}
                    minDate={values.initialDate}
                    onChange={(value) => {
                      setSelectedMaxDate(value.toISOString());
                      setFieldValue('endDate', value.toISOString());
                    }}
                    TextFieldComponent={(props) => (
                      <TextField
                        {...props}
                        className="text-field-label"
                        variant="outlined"
                        InputProps={{
                          style: {
                            borderRadius: 20,
                            color: '#000',
                            fontSize: 22,
                          },
                        }}
                      />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    height: '100%',
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ color: 'white' }}
                    disabled={!isValid || isValidating}
                    fullWidth
                  >
                    Buscar
                  </Button>
                </Box>
              </Grid>
              {isLoading || isValidating ? (
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" mt={2} mb={2}>
                    <CircularProgress />
                  </Box>
                </Grid>
              ) : (
                <>
                  <Grid
                    item
                    justify="space-evenly"
                    container
                    alignItems="center"
                    xs={12}
                  >
                    <Grid item xs={6}>
                      <img
                        alt="logo_abc"
                        src="https://af-logo.s3.sa-east-1.amazonaws.com/Logo-bancoABCwebp.webp"
                        style={{ maxWidth: 200 }}
                      />
                    </Grid>
                    <Grid item container xs={4} style={{ textAlign: 'right' }}>
                      <Grid item xs={12}>
                        <Typography>
                          <b>BANCO ABC BRASIL S.A.</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ alignSelf: 'flex-end' }}>
                        <Typography>
                          <b>CNPJ: </b>28.195.667/0001-06
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography style={{ justifySelf: 'left' }}>
                          Aveninda Cidade Jardim, 803 -2º andar Itaim Bibi
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>São Paulo - SP</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <b>CEP: </b>01453-000
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container style={{ marginLeft: 80 }} xs={6}>
                    <Grid item>
                      <Typography style={{ fontWeight: 600 }}>
                        {company?.corporateName ?? 'Carregando...'}
                      </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                      <Typography style={{ fontWeight: 600 }}>
                        CNPJ:&nbsp;
                      </Typography>
                      <Typography>
                        {accountData?.account?.document.replace(
                          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                          '$1.$2.$3/$4-$5'
                        )}
                      </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                      <Typography style={{ fontWeight: 600 }}>
                        Banco:&nbsp;
                      </Typography>
                      <Typography>{accountData?.account?.bankCode}</Typography>
                    </Grid>
                    <Grid item container xs={12}>
                      <Typography style={{ fontWeight: 600 }}>
                        Agência:&nbsp;
                      </Typography>
                      <Typography>
                        {accountData?.account?.accountAgency?.slice(0, -1)}
                      </Typography>
                    </Grid>
                    <Grid item container xs={12}>
                      <Typography style={{ fontWeight: 600 }}>
                        Conta:&nbsp;
                      </Typography>
                      <Typography>
                        {accountData?.account?.accountNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    {!fetchExtract ? (
                      <Box width="100%" justifyContent="center" display="flex">
                        <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                          Selecione um período para buscar o extrato
                        </Typography>
                      </Box>
                    ) : (
                      <>
                        {error ? (
                          <Alert severity="error">
                            Tivemos um problema ao consultar o extrato. Tente
                            novamente
                          </Alert>
                        ) : (
                          <>
                            {extract && extract.length > 0 ? (
                              <Table aria-label="customized table">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>
                                      Data Movimentação
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      Descrição
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      Complemento
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      Saldo Anterior
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      Valor da Movimentação
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                      Saldo Após Movimento
                                    </StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {extract
                                    ? extract?.map((row) => (
                                        <>
                                          <StyledTableRow key={row.document}>
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {moment(row.dataMovimento).format(
                                                'DD/MM/YYYY'
                                              )}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                              {row.descricaoHistorico}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                              {row.cpfCnpjContraparte}-
                                              {row.nomeContraparte}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                              {row.saldoAntesMovimento.toLocaleString(
                                                'pt-BR',
                                                {
                                                  minimumFractionDigits: 2,
                                                  style: 'currency',
                                                  currency: 'BRL',
                                                }
                                              )}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                              {row.valor.toLocaleString(
                                                'pt-BR',
                                                {
                                                  minimumFractionDigits: 2,
                                                  style: 'currency',
                                                  currency: 'BRL',
                                                }
                                              )}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">
                                              {row.saldoAposMovimento.toLocaleString(
                                                'pt-BR',
                                                {
                                                  minimumFractionDigits: 2,
                                                  style: 'currency',
                                                  currency: 'BRL',
                                                }
                                              )}
                                            </StyledTableCell>
                                          </StyledTableRow>
                                          <Divider />
                                        </>
                                      ))
                                    : 'Nenhuma movimentação encontrada.'}

                                  {extract ? (
                                    <StyledTableRow>
                                      <StyledTableCell rowSpan={4} />
                                      <StyledTableCell
                                        colSpan={4}
                                        align="right"
                                      >
                                        Saldo Atual
                                      </StyledTableCell>
                                      <StyledTableCell align="right">
                                        {extract[0]?.saldoAtual.toLocaleString(
                                          'pt-BR',
                                          {
                                            minimumFractionDigits: 2,
                                            style: 'currency',
                                            currency: 'BRL',
                                          }
                                        )}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  ) : (
                                    ''
                                  )}
                                </TableBody>
                              </Table>
                            ) : (
                              <Alert severity="info">
                                Não há movimentação para o período selecionado
                              </Alert>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </Box>
      </Paper>
    </Box>
  );
}
