import useSWR from 'swr';
import { fetchWithTokenExternalService } from '../fetchWithToken';

export default function useAccountBalance(
  fetch = false,
  accountId,
  options = {
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
    shouldRetryOnError: true,
    errorRetryInterval: 1000,
  }
) {
  const {
    data: balance,
    error,
    isValidating,
    mutate,
  } = useSWR(
    fetch && accountId
      ? `${process.env.REACT_APP_BANKING_API}/balances/${accountId}`
      : null,
    fetchWithTokenExternalService,
    options
  );

  return {
    balance: balance && balance.data ? balance.data : null,
    error,
    isValidating,
    isLoading: fetch && accountId && !error && !balance,
    mutate,
  };
}
