import React, { Fragment, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as moment from 'moment'

import {
  Avatar,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Button,
  Tooltip
} from '@material-ui/core';
import avatar4 from '../../../assets/images/avatars/avatar4.jpg';
import avatar5 from '../../../assets/images/avatars/avatar5.jpg';
import avatar6 from '../../../assets/images/avatars/avatar6.jpg';
import avatar7 from '../../../assets/images/avatars/avatar7.jpg';

export default function LivePreviewExample(props) {
  const statusEnum = Object.freeze({
    "1": "EM FORMAÇÃO - PRÉ-CONTRATO",
    "003": "EM FORMAÇÃO - AGUARDANDO LIBERAÇÃO",
    "15": "ATIVO - NORMAL",
    "11": "ATIVO - CADASTRADO NO DIA (LIBERAÇÃO AUTOMÁTICA)",
    "12": "ATIVO - LIBERADO NO DIA (POR COMANDO)",
    "31": "ENCERRADO - NORMAL (DIA)",
    "32": "ENCERRADO - NORMAL",
    "33": "ENCERRADO - TRANSFERIDO PARA CL (DIA)",
    "34": "ENCERRADO - TRANSFERIDO PARA CL",
    "35": "ENCERRADO - PRÉ-PAGAMENTO (DIA)",
    "36": "ENCERRADO - PRÉ-PAGAMENTO",
    "37": "ENCERRADO - LIQUIDAÇÃO ANTECIPADA (DIA)",
    "38": "ENCERRADO - LIQUIDAÇÃO ANTECIPADA",
    "39": "ENCERRADO - CESSÃO DE CRÉDITO S/COOBRIGAÇÃO (DIA)",
    "40": "ENCERRADO - CESSÃO DE CRÉDITO S/COOBRIGAÇÃO",
    "41": "ENCERRADO - RENOVAÇÃO CONTRATO DIA (DIA)",
    "42": "ENCERRADO - RENOVAÇÃO CONTRATO",
    "43": "ENCERRADO - EM REATIVAÇÃO",
    "51": "ESTORNADO (DIA)",
    '52': "ESTORNADO"
  })
  return (
    <Fragment>
      <Card className="card-box mb-4">
        <div className="card-header pr-2 justify-content-end">
          <div className="card-header--actions">
            <Tooltip arrow title="Refresh">
              <IconButton onClick={async () => {
                await props.fetchFunc()
              }} size="small" color="primary" className="mr-3">
                <FontAwesomeIcon icon={['fas', 'sync']} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <CardContent className="p-3">
          <div className="table-responsive">
            <table className="text-nowrap mb-0 table table-borderless table-hover">
              <thead>
                <tr>

                  <th>TÍTULO</th>
                  <th className="text-center">Data</th>
                  <th className="text-center">STATUS_CONTRATO</th>
                  <th className="text-center">DADOS_ERRO</th>
                  <th className="text-center">PRODUTO</th>
                  <th className="text-center">FAMILIA</th>
                  <th className="text-center">CONTRATO</th>
                </tr>
              </thead>
              <tbody>
                {
                  props.data.map(log => {
                    return <tr style={{ textAlign: 'center' }} key={log.INVOICE_ID}>
                      <td style={{ overflow: 'hidden', textAlign: 'left' }}>
                        <Tooltip arrow title={log.INVOICE_ID}>
                          <div style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: log.INVOICE_ID }}></div>
                        </Tooltip>
                      </td>
                      <td>
                        <div style={{ fontSize: '12px' }}>
                          {moment(new Date(log.DAT_LOG.date)).format('DD/MM/YYYY')}
                        </div>
                      </td>
                      <td>
                        <span className="d-inline-flex align-items-center badge badge-light">
                          <div style={{ fontSize: '10px' }}>
                            {statusEnum[log.STATUS_CONTRATO]}
                          </div>
                        </span>
                      </td>
                      <td>
                        <span className="d-inline-flex align-items-center badge badge-warning">
                          <div style={{ fontSize: '10px' }}>
                            {log.DADOS_ERRO}
                          </div>
                        </span>
                      </td>
                      <td>
                        <div style={{ fontSize: '12px' }}>
                          {log.PRODUTO}
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: '12px' }}>
                          {log.FAMILIA}
                        </div>
                      </td>
                      <td>
                        <div style={{ fontSize: '12px' }}>
                          {log.CONTRATO}
                        </div>
                      </td>
                    </tr>
                  })
                }
              </tbody>
            </table>
          </div>
        </CardContent>
      </Card>
    </Fragment >
  );
}
