import React from 'react';

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import './index.scss';

export function AutoCompleteSelect({
  label = '',
  value,
  options,
  onChange,
  ...props
}) {
  return (
    <>
      <Autocomplete
        {...props}
        multiple
        fullWidth
        className="tags-container"
        id="outlined-multi"
        value={value}
        options={options}
        noOptionsText="Nenhuma tag encontrada"
        onChange={onChange}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <div className="tag-items">
            <TextField
              fullWidth
              {...params}
              className="tag-item"
              variant="outlined"
              label={label}
            />
          </div>
        )}
      />
    </>
  );
}
