import React, { useState } from 'react';

import { Dialog, TextField } from '@material-ui/core';

import { Modal } from 'components/material/Modal';
import { SolicitarProrrogacao } from 'components/SolicitarProrrogacao';

import formatReal from 'utils/formatReal';

import { useAuth } from 'contexts/auth';
import { useToast } from 'contexts/toast';

import { useMaturitys } from 'hooks/useMaturitys';

import { expireStyle } from 'styles/material/maturity';

import 'styles/vencimento.scss';
import { ConfirmButton } from 'components/Button';
import { LiquidacaoParcelas } from 'components/LiquidacaoParcelas';

import EventBus from 'services/EventBus';

import ExtensionButton from '../ExtensionButton';

function Vencimentos({ invoiceId, invoice, bid }) {
  const classes = expireStyle();

  const { isCedente, isFinanciador, isAdmin } = useAuth();
  const { toast } = useToast();
  const { maturitys, handleGetMaturitysFromDatabase } = useMaturitys(invoiceId);

  EventBus.on('reload-maturities', () => {
    handleGetMaturitysFromDatabase();
  });

  const [openExtensionModal, setOpenExtensionModal] = useState(false);
  const [openExtensionModalLiquidar, setOpenExtensionModalLiquidar] =
    useState(false);
  const [selectedMaturity, setSelectedMaturity] = useState(undefined);

  const Days = ({ value }) => (
    <div className="container">
      <TextField
        disabled
        id="outlined-basic"
        variant="filled"
        name="parcelaValue"
        label="Prazo"
        onChange={(e) => toast(e.target.value)}
        value={value}
        classes={{
          root: classes.days,
        }}
      />
    </div>
  );

  const Vencimento = ({ value }) => (
    <div className="container">
      <TextField
        disabled
        variant="filled"
        id="outlined-basic"
        name="parcelaValue"
        label="Data de vencimento"
        value={value}
        onChange={(e) => toast(e.target.value)}
      />
    </div>
  );

  const ButtonLiquidar = ({ maturity }) => {
    const tituloPago = invoice.statusId == 11;
    const ehAdm = isAdmin();
    const ehFin = isFinanciador();

    const dtLiquidacao = maturity.liquidationDate;

    return (ehAdm || ehFin) && !dtLiquidacao && tituloPago ? (
      <ConfirmButton
        onClick={() => {
          maturity.is_parcel = true;
          setSelectedMaturity(maturity);
          setOpenExtensionModalLiquidar(true);
        }}
      >
        Liquidar
      </ConfirmButton>
    ) : (
      <div />
    );
  };

  const ValorParcela = ({ value }) => (
    <div className="container">
      <TextField
        disabled
        variant="filled"
        id="outlined-basic"
        name="parcelaValue"
        label="Valor da parcela"
        value={value}
        onChange={(e) => toast(e.target.value)}
      />
    </div>
  );

  return (
    <>
      <div className="formContainer">
        <div className="containerTitle">
          <h4 style={{ fontFamily: 'Poppins, sans-serif' }}>Vencimentos</h4>
        </div>

        <main id="vencimento-main">
          <div className="content">
            {maturitys &&
              maturitys.length > 0 &&
              maturitys.map((maturity, i) => (
                <div className="vencimento-item w-100">
                  <strong>{++i}</strong>
                  <Vencimento value={maturity.data} />
                  <ValorParcela value={formatReal(maturity.valor)} />
                  <Days
                    value={
                      maturity.liquidationDias &&
                        maturity.liquidationTypeId > 10
                        ? maturity.liquidationDias
                        : maturity.dias
                    }
                  />
                  <ButtonLiquidar maturity={maturity} />
                  <ExtensionButton maturity={maturity} invoice={invoice} />
                </div>
              ))}
          </div>

          <aside>
            <div className="total">
              <h4>Valor Total dos Vencimentos</h4>
              <p>
                R${' '}
                <span>
                  {formatReal((+buscarTotalVencimentos()).toFixed(2))
                    .replace('R$', '')
                    .trim()}
                </span>
              </p>
            </div>
          </aside>
        </main>
      </div>
      <Dialog
        onClose={() => setOpenExtensionModal(false)}
        aria-labelledby="simple-dialog-title"
        open={openExtensionModal}
        scroll="body"
        maxWidth="xl"
      >
        <Modal
          id="customized-dialog-title"
          onClose={() => {
            setOpenExtensionModal(false);
          }}
        />

        <div
          style={{
            display: 'flex',
            marginLeft: '30px',
            marginRight: '30px',
            marginBottom: '50px',
            width: '995px',
          }}
        >
          <SolicitarProrrogacao
            maturity={selectedMaturity}
            invoice={invoice}
            bid={bid}
            onClose={() => {
              setOpenExtensionModal(false);
            }}
          />
        </div>
      </Dialog>

      {isAdmin() || isFinanciador() ? (
        <Dialog
          onClose={() => setOpenExtensionModalLiquidar(false)}
          aria-labelledby="simple-dialog-title"
          open={openExtensionModalLiquidar}
          scroll="paper"
        >
          <Modal
            id="customized-dialog-title"
            onClose={() => {
              setOpenExtensionModalLiquidar(false);
            }}
          />

          <div
            style={{
              display: 'flex',
              marginLeft: '30px',
              marginRight: '30px',
              marginBottom: '50px',
              width: '500px',
            }}
          >
            <LiquidacaoParcelas
              maturity={selectedMaturity}
              invoice={invoice}
              onClose={() => {
                setOpenExtensionModalLiquidar(false);
              }}
            />
          </div>
        </Dialog>
      ) : (
        <div />
      )}
    </>
  );

  function buscarTotalVencimentos() {
    return maturitys && maturitys.length > 0
      ? maturitys
        .map((item) => item.valor)
        .reduce((total, currentElement) => +total + +currentElement)
      : '--.---,--';
  }
}

export default Vencimentos;
