export default function getDaysToGo(future, today) {
  const date1 = new Date(today || parseDate(new Date()));
  const date2 = new Date(future);

  // One day in milliseconds
  const oneDay = 1000 * 60 * 60 * 24;

  // Calculating the time difference between two dates
  const diffInTime = date2.getTime() - date1.getTime();

  // Calculating the no. of days between two dates
  const diffInDays = Math.round(diffInTime / oneDay);

  return diffInDays;

  function parseDate(data) {
    const getDias = data.getDate();
    const getMonth = data.getMonth() + 1;
    const getFullYear = data.getFullYear();

    return `${getMonth}/${getDias}/${getFullYear}`;
  }
}
