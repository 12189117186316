import AxiosInterceptor from "utils/axios-interceptor";

export const CreateBepayAccount = (createBepayAccount) => {
  const axiosInstance = AxiosInterceptor();
  new Promise((resolve, reject) => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_NODE_API}/businessBankAccounts/bepay`,
        createBepayAccount
      )
      .then((res) => resolve(res))
      .catch((e) => reject(e));
  });
};

export const UpdateBankAccount = async (updatedBankAccount) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/businessBankAccounts/${
      updatedBankAccount.bankAccountId === undefined ? "create" : "update"
    }`,
    updatedBankAccount
  );

  return data;
};

export const UpdatePayrollAccount = async (updatedBankAccount) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/businessPayrollAccounts/${
      updatedBankAccount.payrollAccountId === undefined ? "create" : "update"
    }`,
    updatedBankAccount
  );

  return data;
};
