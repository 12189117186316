class Witness {
  constructor(
    witnessId,
    businessId,
    name,
    countryIdentity,
    stateIdentity,
    email
  ) {
    this.witnessId = witnessId;
    this.businessId = businessId;
    this.name = name;
    this.countryIdentity = countryIdentity;
    this.stateIdentity = stateIdentity;
    this.email = email;
  }
}

export default Witness;
