import React from 'react';

export default function Bolt({ color = '#A0A0A0', size = 40 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={(size * 58.65) / 100}
      height={size}
      viewBox="0 0 19 32.395"
    >
      <path
        id="light"
        d="M124.216,12.8h-9.384L119.511,0,105.216,19.6H114.6l-4.679,12.8Z"
        transform="translate(-105.216)"
        fill={color}
      />
    </svg>
  );
}
