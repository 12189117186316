import React, { useState, useEffect } from 'react';
import { SkewLoader } from 'react-spinners';

import { Card, Checkbox } from '@material-ui/core';

import {
  getInvoiceTagsService,
  postInvoiceTagService,
} from 'services/tags-services';

import { useAuth } from 'contexts/auth';
import { useToast } from 'contexts/toast';

const Tags = (props) => {
  const { invoice } = props;

  const [checkedTags, setCheckedTags] = useState([]);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const { isAdmin } = useAuth();
  const { toast } = useToast();

  useEffect(() => {
    async function getInvoiceTags() {
      try {
        const { data } = await getInvoiceTagsService(invoice.invoiceId);
        setCheckedTags(data);
      } catch (error) {
        toast.error('Ocorreu um erro ao obter as tags do Título');
      }
    }
    setLoadingRequest(true);
    getInvoiceTags();
    setLoadingRequest(false);
  }, []);

  const onCheckTag = async (i, event) => {
    const _checkedTags = checkedTags;
    _checkedTags[i].isChecked = event.target.checked;
    const { tagId } = _checkedTags[i];
    setCheckedTags([..._checkedTags]);
    if (isAdmin()) {
      setLoadingRequest(true);
      await postInvoiceTagService(invoice.invoiceId, tagId);
      setLoadingRequest(false);
    }
  };

  const TagCheckbox = ({ tag, index }) => (
    <div key={tag.tagId}>
      <Checkbox
        inputProps={{ 'aria-label': tag.tagId }}
        checked={tag.isChecked}
        onChange={(e) => onCheckTag(index, e)}
        disabled={!isAdmin()}
      />{' '}
      {tag.name}
    </div>
  );
  const Loading = () => (
    <>
      {loadingRequest ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            visibility: 'visible',
            position: 'absolute',
            zIndex: '100000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SkewLoader color="#4caf50" loading />
        </div>
      ) : (
        <div />
      )}
    </>
  );

  return (
    <>
      <Card className="p-4 mb-4">
        <Loading />
        {checkedTags ? (
          checkedTags
            .filter((lista) => isAdmin() || lista.isChecked)
            .map((lista, i) => <TagCheckbox tag={lista} index={i} />)
        ) : (
          <div />
        )}
      </Card>
    </>
  );
};

export default Tags;
