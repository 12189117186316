import React, { Fragment, useEffect, useState } from "react";

import { PageTitle, ExampleWrapperSeamless } from "../../../layout-components";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

import RegularTables2Example2 from "../../../example-components/RegularTables2/RegularTables2Example2";
import { LinearProgress, Dialog, DialogTitle } from "@material-ui/core";
import AxiosInterceptor from "utils/axios-interceptor";
export default () => {
  const [logsData, setlLogsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState({});
  async function fetchData() {
    const axiosInstance = AxiosInterceptor();
    setlLogsData([]);
    setLoading(true);
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_ANTICORS}/${process.env.REACT_APP_SERPRO_PHP}/v1/logbradesco.php`
    );
    setlLogsData(data);
    setLoading(false);
    if (!data) {
      setMessageSuccess({ message: "Falha ao carregar logs", success: false });
    }
  }
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Fragment>
      <PageTitle
        titleHeading="Registro de Logs"
        titleDescription="Status dos títulos Bradesco"
      />
      <ExampleWrapperSeamless sectionHeading="">
        {!loading && (
          <RegularTables2Example2 data={logsData} fetchFunc={fetchData} />
        )}
        {loading && <LinearProgress value={55} color="primary" />}
      </ExampleWrapperSeamless>

      {openSuccess ? (
        <Dialog
          onClose={() => setOpenSuccess(false)}
          open={openSuccess}
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle
            style={{ display: "flex", alignItems: "center" }}
            id="simple-dialog-title"
          >
            {messageSuccess.message}{" "}
            {messageSuccess.success ? (
              <CheckCircleIcon style={{ color: "green" }} />
            ) : (
              <ErrorIcon style={{ color: "red" }} />
            )}
          </DialogTitle>
        </Dialog>
      ) : (
        ""
      )}
    </Fragment>
  );
};
