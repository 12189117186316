import { Box, Button, CircularProgress } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Cedente from '../../pages/Leilao/ModalDarOferta/Cedente';
import DarOferta from '../../pages/Leilao/ModalDarOferta/DarOferta';
import Lastro from '../../pages/Leilao/ModalDarOferta/Lastro';
import Titulo from '../../pages/Leilao/ModalDarOferta/Titulo';
import formatCnpj from '../../utils/formatCNPJ';
import formatReal from '../../utils/formatReal';
import './styles.scss';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      className="modal-dar-oferta--tabpanel"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
    >
      {value === index && children}
    </div>
  );
}

function calcularRisco(riskName) {
  switch (riskName) {
    case 'AA':
      return '#05ff22';
    case 'AB':
      return '#9afe06';
    case 'BA':
      return '#fef306';
    case 'BB':
      return '#fff203';
    case 'CA':
      return '#fb7f03';
    case 'CB':
      return '#e37100';
    case 'INAPTO':
      return '#c80203';
    case 'Não Definido':
      return '#0048bd';
    case 'Médio':
      return '#0048bd';
    default:
      return '#0048bd';
  }
}

function OfferModal({
  selectedTitle,
  consultCet,
  getComissionNogord,
  sendOffer,
  buyTitleAbdicated,
  tab = 0,
  activeComponent = null,
  position,
}) {
  const history = useHistory();
  const [value, setValue] = useState(0);
  const [titleConsultCet, setTitleConsultCet] = useState(selectedTitle);
  const [comissionCached, setComissionCached] = useState([]);
  const [responseNogordCache, setResponseNogordCache] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleChange = useCallback(
    (newBidFactor) => {
      let stopLoop = false;

      const { invoice, buyer } = selectedTitle;

      comissionCached.forEach((val) => {
        if (stopLoop) return;

        let BID_COMMISSION_VALUE =
          (invoice.maturitiesValue *
            val *
            (titleConsultCet.invoice.bidWeightedAverageTermPond +
              buyer.floating)) /
          30;

        if (BID_COMMISSION_VALUE < responseNogordCache.comissaoMin) {
          BID_COMMISSION_VALUE = responseNogordCache.comissaoMin;
        }

        const BID_PERCT_COMM =
          ((30 * BID_COMMISSION_VALUE) /
            (invoice.maturitiesValue *
              titleConsultCet.invoice.bidWeightedAverageTermPond)) *
          100;

        const bidFactorValue =
          ((newBidFactor / 100) *
            invoice.maturitiesValue *
            (titleConsultCet.invoice.bidWeightedAverageTermPond +
              buyer.floating)) /
          30;

        const bidFactorPerct =
          ((30 * bidFactorValue) /
            (invoice.maturitiesValue *
              titleConsultCet.invoice.bidWeightedAverageTermPond)) *
          100;

        const discount =
          BID_COMMISSION_VALUE +
          bidFactorValue +
          titleConsultCet.invoice.bidIofAmountValue +
          titleConsultCet.invoice.bidOperationValue;

        const BID_PERCT_CET =
          (30 * discount) /
          (invoice.maturitiesValue *
            titleConsultCet.invoice.bidWeightedAverageTermPond);

        if (
          BID_PERCT_CET <= responseNogordCache.cetCorte1 &&
          val === responseNogordCache.comissaoPct1
        ) {
          setTitleConsultCet({
            ...titleConsultCet,
            invoice: {
              ...titleConsultCet.invoice,
              bidCommission: BID_COMMISSION_VALUE,
              bidCommissionValue: BID_PERCT_COMM,
              bidNetFactorOffer: BID_PERCT_CET * 100,
              bidNetValue: invoice.maturitiesValue - discount,
              bidDiscountValue: discount,
              bidDiscountPerct: (discount / invoice.maturitiesValue) * 100,
              bidFactorFloat: bidFactorPerct,
              bidFactorValue,
              newBidFactor,
              comissionPct: responseNogordCache.comissaoPct1,
            },
          });

          stopLoop = true;
          return;
        }

        if (
          BID_PERCT_CET > responseNogordCache.cetCorte1 &&
          BID_PERCT_CET <= responseNogordCache.cetCorte2 &&
          val === responseNogordCache.comissaoPct2
        ) {
          setTitleConsultCet({
            ...titleConsultCet,
            invoice: {
              ...titleConsultCet.invoice,
              bidCommission: BID_COMMISSION_VALUE,
              bidCommissionValue: BID_PERCT_COMM,
              bidNetFactorOffer: BID_PERCT_CET * 100,
              bidNetValue: invoice.maturitiesValue - discount,
              bidDiscountValue: discount,
              bidDiscountPerct: (discount / invoice.maturitiesValue) * 100,
              bidFactorFloat: bidFactorPerct,
              bidFactorValue,
              newBidFactor,
              comissionPct: responseNogordCache.comissaoPct2,
            },
          });

          stopLoop = true;

          return;
        }

        if (
          BID_PERCT_CET > responseNogordCache.cetCorte2 &&
          BID_PERCT_CET <= responseNogordCache.cetCorte3 &&
          val === responseNogordCache.comissaoPct3
        ) {
          setTitleConsultCet({
            ...titleConsultCet,
            invoice: {
              ...titleConsultCet.invoice,
              bidCommission: BID_COMMISSION_VALUE,
              bidCommissionValue: BID_PERCT_COMM,
              bidNetFactorOffer: BID_PERCT_CET * 100,
              bidNetValue: invoice.maturitiesValue - discount,
              bidDiscountValue: discount,
              bidDiscountPerct: (discount / invoice.maturitiesValue) * 100,
              bidFactorFloat: bidFactorPerct,
              bidFactorValue,
              newBidFactor,
              comissionPct: responseNogordCache.comissaoPct3,
            },
          });

          stopLoop = true;

          return;
        }

        if (
          BID_PERCT_CET > responseNogordCache.cetCorte3 &&
          BID_PERCT_CET <= responseNogordCache.cetCorte4 &&
          val === responseNogordCache.comissaoPct4
        ) {
          setTitleConsultCet({
            ...titleConsultCet,
            invoice: {
              ...titleConsultCet.invoice,
              bidCommission: BID_COMMISSION_VALUE,
              bidCommissionValue: BID_PERCT_COMM,
              bidNetFactorOffer: BID_PERCT_CET * 100,
              bidNetValue: invoice.maturitiesValue - discount,
              bidDiscountValue: discount,
              bidDiscountPerct: (discount / invoice.maturitiesValue) * 100,
              bidFactorFloat: bidFactorPerct,
              bidFactorValue,
              newBidFactor,
              comissionPct: responseNogordCache.comissaoPct4,
            },
          });
          stopLoop = true;
          return;
        }

        if (
          BID_PERCT_CET > responseNogordCache.cetCorte4 &&
          val === responseNogordCache.comissaoPct5
        ) {
          setTitleConsultCet({
            ...titleConsultCet,
            invoice: {
              ...titleConsultCet.invoice,
              bidCommission: BID_COMMISSION_VALUE,
              bidCommissionValue: BID_PERCT_COMM,
              bidNetFactorOffer: BID_PERCT_CET * 100,
              bidNetValue: invoice.maturitiesValue - discount,
              bidDiscountValue: discount,
              bidDiscountPerct: (discount / invoice.maturitiesValue) * 100,
              bidFactorFloat: bidFactorPerct,
              bidFactorValue,
              newBidFactor,
              comissionPct: responseNogordCache.comissaoPct5,
            },
          });

          stopLoop = true;
        }
      });
    },
    [comissionCached, titleConsultCet, responseNogordCache, selectedTitle]
  );

  const initialConsults = useCallback(async () => {
    const responseNog = await getComissionNogord(selectedTitle);

    setIsLoading(true);
    setIsError(false);

    const commissions = [
      responseNog.comissaoPct1,
      responseNog.comissaoPct2,
      responseNog.comissaoPct3,
      responseNog.comissaoPct4,
      responseNog.comissaoPct5,
    ];

    setComissionCached(commissions);
    setResponseNogordCache(responseNog);

    const { invoice } = selectedTitle;

    let bidCetCurrent = invoice?.bidNetFactor;

    if (invoice.abdicado !== '1') {
      bidCetCurrent = invoice?.bidNetFactor
        ? invoice?.bidNetFactor - 0.05
        : invoice?.bidMaxFactor - 0.05;
    }

    let stopLoop = false;
    let comissionNog = 0;

    const bidCetCurrentPerct = bidCetCurrent / 100;

    commissions.forEach((val) => {
      if (stopLoop) return;

      if (
        bidCetCurrentPerct <= responseNog.cetCorte1 &&
        val === responseNog.comissaoPct1
      ) {
        comissionNog = responseNog.comissaoPct1;
        stopLoop = true;
        return;
      }

      if (
        bidCetCurrentPerct > responseNog.cetCorte1 &&
        bidCetCurrentPerct <= responseNog.cetCorte2 &&
        val === responseNog.comissaoPct2
      ) {
        comissionNog = responseNog.comissaoPct2;
        stopLoop = true;
        return;
      }

      if (
        bidCetCurrentPerct > responseNog.cetCorte2 &&
        bidCetCurrentPerct <= responseNog.cetCorte3 &&
        val === responseNog.comissaoPct3
      ) {
        comissionNog = responseNog.comissaoPct3;
        stopLoop = true;

        return;
      }

      if (
        bidCetCurrentPerct > responseNog.cetCorte3 &&
        bidCetCurrentPerct <= responseNog.cetCorte4 &&
        val === responseNog.comissaoPct4
      ) {
        comissionNog = responseNog.comissaoPct4;
        stopLoop = true;
        return;
      }

      if (
        bidCetCurrentPerct > responseNog.cetCorte4 &&
        val === responseNog.comissaoPct5
      ) {
        comissionNog = responseNog.comissaoPct5;
        stopLoop = true;
      }
    });

    const sessionData = JSON.parse(localStorage.getItem('@App:user'));
    consultCet({
      buyerId: sessionData.businessId,
      invoiceId: invoice.invoiceId,
      fatorDesagio: bidCetCurrent,
      comissaoNog: comissionNog,
      position: position,
      ranking: invoice.ranking,
    })
      .then((response) => {
        const { data } = response;
        const lastResult = data.pop();
        setTitleConsultCet({
          ...titleConsultCet,
          invoice: {
            ...titleConsultCet.invoice,
            bidWeightedAverageTermPond: lastResult.WEIGHTTOTALPONDVALUE,
            bidDiscountValue: lastResult.BID_DISCOUNT_VALUE,
            bidNetValue: lastResult.BID_NET_VALUE,
            bidDiscountPerct: lastResult.BID_PERCT_DISCOUNT_TOTAL,
            bidDiscountValueLastOffer: selectedTitle.invoice.bidDiscountValue,
            bidDiscountPerctLastOffer: selectedTitle.invoice.bidDiscountPerct,
            bidNetValueLastOffer: selectedTitle.invoice.bidNetValue,
            bidNetFactorOffer:
              lastResult.BID_PERCT_CET > 0 ? lastResult.BID_PERCT_CET : 0,
            bidFactorValue:
              lastResult.BID_FACTOR_VALUE > 0 ? lastResult.BID_FACTOR_VALUE : 0,
            bidFactorFloat:
              lastResult.BID_PERCT_FACTORFLO > 0
                ? lastResult.BID_PERCT_FACTORFLO
                : 0,
            bicFactorInitial:
              lastResult.BID_PERCT_FACTOR > 0 ? lastResult.BID_PERCT_FACTOR : 0,
            bidIofAmountValue: lastResult.BID_TOTAL_IOF,
            bidIofTermValue: lastResult.BID_PERCT_IOF,
            bidOperationValue: lastResult.BID_TOTAL_TAC,
            bidOperation: lastResult.BID_PERCT_TAC,
            bidCommission: lastResult.BID_COMISSION_VALUE,
            bidCommissionValue: lastResult.BID_PERCT_COMM,
            newBidFactor: lastResult.BID_PERCT_FACTOR,
            comissionPct: comissionNog,
          },
        });
      })
      .catch((e) => {
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedTitle, handleChange]);

  useEffect(() => {
    initialConsults();

    return () => {
      history.replace({
        pathname: `/vitrine`,
      });
    };
  }, []);

  useEffect(() => {
    if (tab) {
      setValue(tab);
    }
  }, [tab]);

  return (
    <Box className="modal-dar-oferta">
      <div className="modal-dar-oferta--tabs--container">
        <Tabs
          classes={{ indicator: 'modal-dar-oferta--indicator' }}
          onChange={(event, newValue) => setValue(newValue)}
          value={value}
        >
          <Tab
            classes={{ root: 'modal-dar-oferta--tab-root' }}
            label="Dar oferta"
          />
          <Tab
            className="modal-dar-oferta-tab-lastros"
            classes={{ root: 'modal-dar-oferta--tab-root' }}
            label="Lastros"
          />
          <Tab
            classes={{ root: 'modal-dar-oferta--tab-root' }}
            label="Cedente"
          />

          <Tab
            className="modal-dar-oferta-tab-titulo"
            classes={{ root: 'modal-dar-oferta--tab-root' }}
            label="Título"
          />
        </Tabs>
      </div>
      <div
        className={clsx(
          'modal-dar-oferta--content',
          value !== 0 && 'modal-dar-oferta--content--grey'
        )}
      >
        <TabPanel value={value} index={0}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              {isError ? (
                <>
                  <Alert severity="error">
                    Desculpe. Tivemos um problema ao tentar realizar a consulta.
                  </Alert>
                  <Box
                    display="flex"
                    mt={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => initialConsults()}
                    >
                      Tentar novamente
                    </Button>
                  </Box>
                </>
              ) : (
                <DarOferta
                  abdicado={titleConsultCet?.invoice?.abdicado === '1'}
                  calcularRisco={calcularRisco}
                  calcularTituloCet={() => {}}
                  cnpj={formatCnpj}
                  darLance={() => sendOffer(titleConsultCet, position)}
                  desagio={
                    titleConsultCet?.invoice?.bicFactorInitial > 0
                      ? titleConsultCet?.invoice?.bicFactorInitial
                      : 0
                  }
                  diminuirOferta={handleChange}
                  formatReal={formatReal}
                  fraseErro={
                    selectedTitle?.invoice?.cetAtual <=
                      titleConsultCet?.invoice?.bidNetFactorOffer ||
                    titleConsultCet?.invoice?.bidNetFactorOffer >
                      titleConsultCet?.invoice?.bidMaxFactor
                      ? 'Sua oferta é maior ou igual a atual'
                      : selectedTitle.invoice.indOferta === '1' &&
                        selectedTitle.invoice.abdicado === '1'
                      ? 'Você arrematou este título'
                      : titleConsultCet?.invoice?.bicFactorInitial <= 0
                      ? 'Suas taxas estão muito altas, impossibilitando uma oferta viável para qualquer fator de deságio. Diminua suas taxas caso queira dar uma oferta para este título.'
                      : ''
                  }
                  handleChange={handleChange}
                  ofertaAtual={{
                    bidDiscountValue:
                      titleConsultCet?.invoice?.bidDiscountValueLastOffer,
                    bidDiscountPerct:
                      titleConsultCet?.invoice?.bidDiscountPerctLastOffer,
                  }}
                  ofertaIgual={
                    selectedTitle?.invoice?.cetAtual <=
                    titleConsultCet?.invoice?.bidNetFactorOffer
                  }
                  subirOferta={handleChange}
                  titulo={titleConsultCet}
                  buyTitleAbdicated={buyTitleAbdicated}
                />
              )}
            </>
          )}
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Lastro titulo={titleConsultCet} values={[]} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Cedente activeComponent={activeComponent} titulo={titleConsultCet} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Titulo titulo={titleConsultCet} />
        </TabPanel>
      </div>
    </Box>
  );
}

export default OfferModal;
