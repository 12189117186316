class User {
  constructor(
    userId,
    businessId,
    typeId,
    accessProfileId,
    statusId,
    name,
    accessId,
    password,
    resetPassword,
    secureTimeStamp,
    email,
    emailConfirmed,
    emailFactorEnabled,
    phone,
    phoneConfirmed,
    phoneFactorEnabled,
    lockoutDate,
    lockoutEnabled,
    accessFailed,
    accessCount,
    lastAccess,
    code,
    photo,
    registerDate,
    note,
    deletionDate,
    deletionUserId,
    deletionUserName
  ) {
    this.userId = userId;
    this.businessId = businessId;
    this.typeId = typeId;
    this.accessProfileId = accessProfileId;
    this.statusId = 1;
    this.name = '';
    this.accessId = '';
    this.password = '';
    this.resetPassword = resetPassword;
    this.secureTimeStamp = secureTimeStamp;
    this.email = '';
    this.emailConfirmed = emailConfirmed;
    this.emailFactorEnabled = emailFactorEnabled;
    this.phone = phone;
    this.phoneConfirmed = phoneConfirmed;
    this.phoneFactorEnabled = phoneFactorEnabled;
    this.lockoutDate = lockoutDate;
    this.lockoutEnabled = lockoutEnabled;
    this.accessFailed = accessFailed;
    this.accessCount = accessCount;
    this.lastAccess = lastAccess;
    this.code = code;
    this.photo = photo;
    this.registerDate = registerDate;
    this.note = note;
    this.deletionDate = deletionDate;
    this.deletionUserId = deletionUserId;
    this.deletionUserName = deletionUserName;
  }
}

export default User;
