import React from 'react';

import { AutoCompleteSelect } from 'components/AutoCompleteSelect';
import { ConfirmButton } from 'components/Button';
import { WarningCard } from 'components/CatchCards';

import { useRecebivel } from 'hooks/useRecebivel';

import './index.scss';

export function AgendaTags({ edit, agendaId }) {
  const {
    allTags,
    agendaTags,
    isLoadingRecebivel,
    selectAgendaTag,
    handleSendAgendaTags,
  } = useRecebivel({
    edit,
    agendaId,
  });

  return (
    <>
      <div id="agenda-tags">
        {!isLoadingRecebivel && (
          <>
            <AutoCompleteSelect
              noOptionsText="Nenhuma tag encontrada"
              label="Tags"
              value={agendaTags}
              options={allTags}
              onChange={(event, newValue) => {
                selectAgendaTag([...newValue]);
              }}
            />
            <ConfirmButton onClick={handleSendAgendaTags}>
              Salvar Tags
            </ConfirmButton>
          </>
        )}
        {isLoadingRecebivel && (
          <>
            <WarningCard className="warning-card">Carregando Tags</WarningCard>
          </>
        )}
      </div>
    </>
  );
}
