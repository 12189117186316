import { makeStyles } from '@material-ui/core';

export const expireStyle = makeStyles({
  BUTTON: {
    backgroundColor: '#000 !important',
    color: '#FFF !important',
    maxHeight: '50px',
    // alignSelf: 'center',
  },
  BUTTON_DISABLED: {
    backgroundColor: '#777 !important',
    color: '#FFF !important',
    maxHeight: '50px',
  },
});
