import React from 'react';
import 'styles/wrapper.scss';

export function Wrapper(props) {
  return (
    <>
      <div id="wrapper">
        {props.sectionHeading && (
          <div id="wrapperTitle">{props.sectionHeading}</div>
        )}
        <div id="wrapperBody" className={`${props.column ? 'column' : ''}`}>
          {props.children}
        </div>
      </div>
    </>
  );
}
