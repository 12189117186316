import AxiosInterceptor from "utils/axios-interceptor";

export const getAgenda = async (businessId) => {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/agenda/${
      businessId ? `filter/${businessId}` : ""
    }`
  );

  return data;
};

export async function updateAvailableValueService(
  agenda_id,
  available_percentage
) {
  const axiosInstance = AxiosInterceptor();

  await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/agenda/availableValue`,
    {
      agenda_id,
      available_percentage,
    }
  );
}

export async function updateStatusAndNote(
  { agendaId, status },
  note = undefined
) {
  const axiosInstance = AxiosInterceptor();

  const payload = {
    agendaId,
    statusName: status.label,
    statusId: status.id,
    note,
  };

  await axiosInstance.put(
    `${process.env.REACT_APP_NODE_API}/agenda/update/statusAndNote`,
    payload
  );
}

export async function updateRisk({ agendaId, riskId, riskName, updateState }) {
  const axiosInstance = AxiosInterceptor();

  const payload = { agendaId, riskId, riskName };

  await axiosInstance.put(
    `${process.env.REACT_APP_NODE_API}/agenda/update/risk`,
    payload
  );

  updateState(riskName);
}

export async function getAgendaById(agendaId) {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/agenda/${agendaId}`
  );

  return data;
}

export async function getDocumentsById(agendaId) {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/agenda/documents/agendaId/${agendaId}`
  );

  return data;
}

export async function getTagsAgendaService(agendaId) {
  const axiosInstance = AxiosInterceptor();

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/agenda-tag/all/${agendaId}`
  );

  return data;
}

export async function saveTagsAgendaService(agendaId, tags) {
  const axiosInstance = AxiosInterceptor();

  const payload = {
    agendaId,
    tags,
  };

  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/agenda-tag`,
    payload
  );

  return data;
}

export async function autoCreateInvoiceService(agendaId, percentage, userId) {
  const axiosInstance = AxiosInterceptor();

  const payload = {
    agendaId,
    available_percentage: +percentage,
    userId,
  };
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/agenda/auto-create-invoice`,
    payload
  );

  return data;
}

export async function gerarParcelasAgendasService(parcelas) {
  const axiosInstance = AxiosInterceptor();

  const payload = {
    parcelas,
  };

  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/agenda/parcelas`,
    payload
  );

  return data;
}
