import React, { useState } from "react";

import "../capa.scss";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  Card,
  CardContent,
  TextField,
  Tooltip,
  IconButton,
  Button,
  Menu,
  List,
  ListItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FolderIcon from "@material-ui/icons/Folder";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import UndoIcon from "@material-ui/icons/Undo";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { WarningCard } from "components/CatchCards";
import { ReactIf, ReactThen } from "components/ReactIf";

import { openDocumentWithUrlUtil } from "utils/openDocuments";

import { updatePending, uploadDocument } from "services/documentos/agenda";

import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";

import { useRecebivel } from "hooks/useRecebivel";

import TabPage from "./TabPage";

export default (props) => {
  const { agendaId, edit, refreshAgendaData } = props;
  const { toast } = useToast();
  const { isAdmin } = useAuth();

  const {
    agenda,
    agendaDocs: documents,
    refreshRecebivel,
    canEditDocuments,
    isLoadingRecebivel,
  } = useRecebivel({
    agendaId,
    edit,
  });

  const [openedInvoiceAccordion, setOpenedInvoiceAccordion] = useState(false);
  const [openedIncludedDocsAccordion, setOpenedIncludedDocsAccordion] =
    useState(false);

  const invoiceDocTypes = [
    {
      title: "Nota Fiscal que gerou a operação",
      description: "",
      documentTypeId: 31,
    },
    {
      title: "Canhoto da Nota Fiscal assinado e carimbado pelo sacado",
      description: "",
      documentTypeId: 32,
    },
    {
      title: "Termo de Entrega e Conformidade devidamente preenchido",
      description: "",
      documentTypeId: 33,
    },
    {
      title: "Cópia do Pedido de Compra que gerou a operação",
      description: "",
      documentTypeId: 34,
    },
    {
      title: "Fotos do produto ou serviço entregue",
      description:
        "Colocar as 3 imagens aqui ajuda você a fazer melhor negócio na Plataforma.",
      documentTypeId: 35,
    },
    {
      title: "Boletos",
      description: "",
      documentTypeId: 36,
    },
  ];

  const analysisDocTypes = [
    {
      documentTypeId: 0,
      title: "Análise e Relatório",
      description:
        "Documentos incluídos nesta seção são enviados automaticamente ao dossiê.",
    },
    {
      documentTypeId: 55,
      title: "Vídeos",
      description:
        "Documentos incluídos nesta seção são enviados automaticamente ao dossiê.",
    },
  ];

  const updateDocument = async (document, documents, setDocuments) => {
    try {
      const promises = [updatePending(document)];

      await toast.promise(Promise.all(promises), {
        loading: "Atualizando dados",
        success: "Atualizado com sucesso!",
        error: "Erro ao atualizar!",
      });

      const updatedDocs = documents.map((doc) => {
        if (doc.fileId === document.fileId) return document;
        return doc;
      });
      setDocuments(updatedDocs);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const ModifyDescriptionModal = (props) => {
    const {
      open,
      handleClose,
      initialDescripiton,
      document,
      documents,
      setDocuments,
    } = props;
    const [newDescription, setNewDescription] = useState(
      initialDescripiton || ""
    );

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Alterar Descrição</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Descrição do Documento"
            type="email"
            fullWidth
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              const updatedDoc = document;
              updatedDoc.description = newDescription;
              updateDocument(updatedDoc, documents, setDocuments);
              handleClose();
            }}
            color="primary"
          >
            Alterar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const UploadButton = ({ typeId, typeName, uploadDocuments, analise }) => (
    <Tooltip
      placement="right"
      style={{ position: "unset", marginTop: 12, marginLeft: 4 }}
      title="Anexar Documento"
      aria-label="attach-file"
      className="card-tr-actions"
    >
      <div className="">
        <input
          accept={
            typeId == 55
              ? "video/*"
              : "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
          }
          className="d-none"
          id={`icon-button-file-${typeId}${typeName.replaceAll(" ", "-")}${
            analise !== undefined ? "analise" : ""
          }`}
          type="file"
          multiple
          onChange={(e) => {
            uploadDocuments(e.target.files, typeId, typeName);
          }}
        />
        <label
          htmlFor={`icon-button-file-${typeId}${typeName.replaceAll(" ", "-")}${
            analise !== undefined ? "analise" : ""
          }`}
        >
          <IconButton color="primary" aria-label="attach-file" component="span">
            <AttachFileIcon />
          </IconButton>
        </label>
      </div>
    </Tooltip>
  );

  const AccordionSection = ({ title, docTypes, invoiceDocuments }) => {
    const documents = invoiceDocuments;
    const [openedAccordion, setOpenedAccordion] = useState(false);

    return (
      <Accordion defaultExpanded expanded className="w-100">
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="w-100"
          onClick={() => {
            setOpenedAccordion(!openedAccordion);
          }}
        >
          <h4>{title}</h4>
        </AccordionSummary>
        <AccordionDetails
          className="d-flex flex-column"
          style={{ marginTop: "-50px" }}
        >
          {docTypes.map((docType) => (
            <DocumentAccordion
              key={docType.documentTypeId}
              docType={{
                ...docType,
                documentTypeId: docType.documentTypeId,
                title: docType.title,
              }}
              documents={documents}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    );
  };

  const AnalysisAccordionSection = ({ title, docTypes, agendaDocuments }) => {
    const documents = agendaDocuments;
    const [openedAccordion, setOpenedAccordion] = useState(false);

    return (
      <Accordion defaultExpanded expanded className="w-100">
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="w-100"
          onClick={() => {
            setOpenedAccordion(!openedAccordion);
          }}
        >
          <h4>{title}</h4>
        </AccordionSummary>
        <AccordionDetails
          className="d-flex flex-column"
          style={{ marginTop: "-50px" }}
        >
          {docTypes.map((docType) => (
            <DocumentAccordion
              key={docType.documentTypeId}
              docType={docType}
              documents={documents}
              analise
            />
          ))}
        </AccordionDetails>
      </Accordion>
    );
  };

  const DocumentAccordion = (props) => {
    const { docType, documents, analise } = props;

    const [thisAccordionOpened, setThisAccordionOpened] = useState(false);
    const [accordionDocs, setAccordionDocs] = useState(documents);

    // handler progresso de upload de documentos
    const handleUploadProgress = (percentComplete) => {
      if (percentComplete === 0) return;
      toast(`Enviando arquivo - ${percentComplete}%`, {
        id: "percentComplete",
      });
    };

    const countByStatusId = (statusId) =>
      accordionDocs
        .filter((doc) => doc.documentTypeId === docType.documentTypeId)
        .filter((doc) => Number(doc.statusId) === statusId).length;

    const uploadDocuments = async (files, typeId, typeName) => {
      const formData = new FormData();

      Object.values(files).forEach((file) => formData.append("files", file));
      formData.append("originId", agenda.agenda_id);
      formData.append("originTypeId", 8);
      formData.append("originTypeName", "Agenda");
      formData.append("documentTypeId", typeId);
      formData.append("documentTypeName", typeName);

      try {
        const promises = [
          refreshAgendaData(agenda),
          uploadDocument(formData, handleUploadProgress),
        ];

        await toast.promise(Promise.all(promises), {
          loading: `Enviando documento`,
          success: "Documento enviado com sucesso",
          error: "Erro ao enviar documento",
        });

        // Recarregando documentos
        await refreshRecebivel();
      } catch (error) {
        toast.error(error.message);
      }
    };

    return (
      <Accordion className="w-100">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="w-100 d-flex flex-row align-items-center justify-content-center"
          style={{ position: "relative" }}
          onClick={() => {
            setThisAccordionOpened(!thisAccordionOpened);
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            onFocus={(e) => e.stopPropagation()}
            className="d-flex flex-row align-items-center"
          >
            <div style={{ marginTop: 12 }}>
              <Tooltip
                placement="top"
                style={{ position: "unset" }}
                title="Aguardando Aprovação"
                aria-label="Aguardando Aprovação"
                className="card-tr-actions"
              >
                <div className="px-3 mx-1 badge badge-warning">
                  {countByStatusId(1)}
                </div>
              </Tooltip>
              <Tooltip
                placement="top"
                style={{ position: "unset" }}
                title="Aprovados"
                aria-label="Aprovados"
                className="card-tr-actions"
              >
                <div className="px-3 mx-1 badge badge-success">
                  {countByStatusId(2)}
                </div>
              </Tooltip>
              <Tooltip
                placement="top"
                style={{ position: "unset" }}
                title="Reprovados"
                aria-label="Reprovados"
                className="card-tr-actions"
              >
                <div className="px-3 mx-1 badge badge-danger">
                  {countByStatusId(3)}
                </div>
              </Tooltip>
              <Tooltip
                placement="top"
                style={{ position: "unset" }}
                title="Excluidos"
                aria-label="Excluidos"
                className="card-tr-actions"
              >
                <div className="px-3 mx-1 badge badge-dark">
                  {countByStatusId(6)}
                </div>
              </Tooltip>
            </div>
            <h5 style={{ marginTop: 12, marginLeft: 4 }}>{docType.title}:</h5>

            {docType.description && (
              <Tooltip
                placement="right"
                style={{ position: "unset", marginTop: 12, marginLeft: 4 }}
                title={docType.description}
                aria-label={docType.title}
                className="card-tr-actions"
              >
                <InfoIcon />
              </Tooltip>
            )}
            {canEditDocuments({ statusName: agenda.status_name }) && (
              <>
                <UploadButton
                  typeId={docType.documentTypeId}
                  typeName={docType.title}
                  uploadDocuments={uploadDocuments}
                  analise={analise}
                />
              </>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails className="d-flex flex-column">
          {accordionDocs !== undefined &&
            accordionDocs
              .filter((doc) => doc.documentTypeId === docType.documentTypeId)
              .sort((a, b) => Number(a.statusId) - Number(b.statusId))
              .map((doc) => (
                <DocumentCard
                  key={doc.fileId}
                  document={doc}
                  documents={accordionDocs}
                  setDocuments={setAccordionDocs}
                />
              ))}
        </AccordionDetails>
      </Accordion>
    );
  };

  const DocumentCard = (props) => {
    const { document, documents, setDocuments } = props;

    const documentState = document;
    const [anchorElMenu4, setAnchorElMenu4] = useState(null);
    const [descriptionDialogOpen, setDescriptionDialogOpen] = useState(false);

    const handleOpenMenu = (e) => {
      setAnchorElMenu4(e.currentTarget);
    };

    const handleCloseMenu4 = () => {
      setAnchorElMenu4(null);
    };

    const handleCloseDescriptionDialog = () => {
      setDescriptionDialogOpen(false);
    };

    return (
      <>
        <Card className="card-box mb-4">
          {Number(documentState.statusId) === 1 ? (
            <div className="card-indicator bg-warning" />
          ) : Number(documentState.statusId) === 2 ? (
            Number(document.inDossie) === 1 ? (
              <div className="card-indicator bg-info" />
            ) : (
              <div className="card-indicator bg-success" />
            )
          ) : Number(documentState.statusId) === 3 ? (
            <div className="card-indicator bg-danger" />
          ) : Number(documentState.statusId) === 6 ? (
            <div className="card-indicator bg-first" />
          ) : (
            <></>
          )}
          <CardContent className="px-4 py-3 d-flex flex-row align-items-center justify-content-between">
            <div>
              <div className="pb-3 d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  {Number(documentState.statusId) === 1 ? (
                    <div
                      className="px-3 badge badge-warning"
                      style={{ width: "100% !", cursor: "default" }}
                    >
                      {documentState.statusName}
                    </div>
                  ) : Number(documentState.statusId) === 2 ? (
                    Number(document.inDossie) === 1 ? (
                      <div
                        className="px-3 badge badge-info"
                        style={{ width: "100% !", cursor: "default" }}
                      >
                        Dossiê
                      </div>
                    ) : (
                      <div
                        className="px-3 badge badge-success"
                        style={{ width: "100% !", cursor: "default" }}
                      >
                        {documentState.statusName}
                      </div>
                    )
                  ) : Number(documentState.statusId) === 3 ? (
                    <div
                      className="px-3 badge badge-danger"
                      style={{ width: "100% !", cursor: "default" }}
                    >
                      {documentState.statusName}
                    </div>
                  ) : Number(documentState.statusId) === 6 ? (
                    <Badge
                      color="primary"
                      className="px-3 badge badge-dark"
                      style={{ width: "100% !", cursor: "default" }}
                    >
                      {documentState.statusName}
                    </Badge>
                  ) : (
                    <></>
                  )}
                  <a
                    href={documentState.url}
                    target="_blank"
                    className="ml-3"
                    rel="noreferrer"
                  >
                    {documentState.description === null ||
                    documentState.description === undefined
                      ? documentState.name
                      : documentState.description}
                  </a>
                </div>
              </div>
              <div className="d-flex flex-column align-items-start justify-content-center">
                <div className="font-size-sm pt-2">
                  {documentState.name} -{" "}
                  {(Number(documentState.size) / 1024 / 1024).toFixed(2)} MB
                </div>
                <div className="font-size-sm pt-2">
                  <FontAwesomeIcon icon={["far", "clock"]} className="mr-1" />
                  {new Date(documentState.registerDate).toLocaleString("pt-BR")}
                </div>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center">
              {isAdmin() && Number(documentState.statusId) === 1 ? (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <Button
                    variant="outlined"
                    className="m-2 btn text-success w-100"
                    style={{ borderColor: "green" }}
                    onClick={() => {
                      const updatedDoc = documentState;
                      updatedDoc.statusId = 2;
                      updatedDoc.statusName = "Aprovado";
                      updateDocument(updatedDoc, documents, setDocuments);
                    }}
                  >
                    Aprovar
                  </Button>
                  <Button
                    variant="outlined"
                    className="m-2 btn text-danger w-100"
                    style={{ borderColor: "red" }}
                    onClick={() => {
                      const updatedDoc = documentState;
                      updatedDoc.statusId = 3;
                      updatedDoc.statusName = "Reprovado";
                      updateDocument(updatedDoc, documents, setDocuments);
                    }}
                  >
                    Reprovar
                  </Button>
                </div>
              ) : isAdmin() &&
                Number(documentState.statusId) === 2 &&
                (documentState.inDossie !== null ||
                  documentState.inDossie !== undefined) &&
                Number(documentState.inDossie) === 0 ? (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <Button
                    variant="contained"
                    className="m-2 btn text-white"
                    style={{ backgroundColor: "#373A44" }}
                    onClick={() => {
                      const updatedDoc = documentState;
                      updatedDoc.inDossie = 1;
                      updateDocument(updatedDoc, documents, setDocuments);
                    }}
                  >
                    Enviar para o Dossiê
                  </Button>
                </div>
              ) : (
                <></>
              )}
              <div>
                <IconButton
                  aria-label="menu"
                  className="m-2"
                  onClick={(e) => handleOpenMenu(e)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorElMenu4}
                  keepMounted
                  open={Boolean(anchorElMenu4)}
                  onClose={handleCloseMenu4}
                  classes={{ list: "p-0" }}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className="overflow-hidden dropdown-menu-xl">
                    <List className="flex-column">
                      {isAdmin() && Number(documentState.statusId) !== 1 && (
                        <ListItem
                          button
                          onClick={() => {
                            const updatedDoc = {
                              ...documentState,
                              statusId: 1,
                              statusName: "Aguardando Aprovação",
                              inDossie: 0,
                            };

                            updateDocument(updatedDoc, documents, setDocuments);
                          }}
                        >
                          <UndoIcon />
                          <span className="ml-2">Desfazer Ação</span>
                        </ListItem>
                      )}
                      {isAdmin() && (
                        <ListItem
                          button
                          onClick={() => {
                            setDescriptionDialogOpen(true);
                            handleCloseMenu4();
                          }}
                        >
                          <EditIcon />
                          <span className="ml-2">Alterar Descrição</span>
                        </ListItem>
                      )}
                      <ListItem
                        button
                        onClick={() => {
                          const { url } = documentState;
                          openDocumentWithUrlUtil(url);
                          handleCloseMenu4();
                        }}
                      >
                        <FolderIcon />
                        <span className="ml-2">Abrir</span>
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => {
                          window.open(documentState.downloadUrl, "blank");
                          handleCloseMenu4();
                        }}
                      >
                        <SaveAltIcon />
                        <span className="ml-2">Baixar</span>
                      </ListItem>
                      <Divider />
                      {isAdmin() && (
                        <ListItem
                          button
                          onClick={() => {
                            const updatedDoc = documentState;
                            updatedDoc.statusId = 6;
                            updatedDoc.statusName = "Excluido";
                            updateDocument(updatedDoc, documents, setDocuments);
                          }}
                        >
                          <DeleteIcon />
                          <span className="ml-2">Excluir</span>
                        </ListItem>
                      )}
                    </List>
                  </div>
                </Menu>
              </div>
            </div>
          </CardContent>
        </Card>
        <ModifyDescriptionModal
          open={descriptionDialogOpen}
          handleClose={handleCloseDescriptionDialog}
          initialDescripiton={documentState.description}
          document={documentState}
          documents={documents}
          setDocuments={setDocuments}
        />
      </>
    );
  };

  return (
    <>
      <TabPage title="Documentos">
        {agenda && documents && !isLoadingRecebivel && (
          <>
            <AccordionSection
              docTypes={invoiceDocTypes}
              invoiceDocuments={documents}
              openedAccordion={openedInvoiceAccordion}
              setOpenedAccordion={setOpenedInvoiceAccordion}
            />
            <ReactIf condition={isAdmin()}>
              <ReactThen>
                <div className="containerTitle w-100 mt-4">
                  <div className="itemTile d-flex flex-row justify-content-between align-items-center w-100">
                    <h3 style={{ fontFamily: "Poppins, sans-serif" }}>
                      Documentos de Análise
                    </h3>
                  </div>
                </div>
                <AnalysisAccordionSection
                  docTypes={analysisDocTypes}
                  agendaDocuments={documents}
                  openedAccordion={openedIncludedDocsAccordion}
                  setOpenedAccordion={setOpenedIncludedDocsAccordion}
                />
              </ReactThen>
            </ReactIf>
          </>
        )}
        {isLoadingRecebivel && (
          <>
            <WarningCard>Carregando documentos</WarningCard>
          </>
        )}

        {!isLoadingRecebivel && (!agenda || !documents) && (
          <>
            <WarningCard>Nenhum dado encontrado</WarningCard>
          </>
        )}
      </TabPage>
    </>
  );
};
