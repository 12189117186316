import React, { useState, useEffect } from "react";
import PageTitle from "components/PageTitle";
import { useAuth } from "contexts/auth";
import AxiosInterceptor from "utils/axios-interceptor";
import "./homeDashboard.scss";
import BarChart from "@material-ui/icons/BarChart";
import { Button } from "@material-ui/core";

const HomeDashboard = () => {
  const { user } = useAuth();
  const [metabaseEmbedURL, setMetabaseEmbedURL] = useState("");
  const [show, setShow] = useState(false);
  const getMetabase = async () => {
    const axiosInstance = AxiosInterceptor();
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_NODE_API}/business/metabase/dashboard/${user?.userTypeId}/${user?.businessId}/${user?.originType}`
    );
    setMetabaseEmbedURL(data);
  };

  function handleShowMetabaseDashboard() {
    getMetabase();
    setShow(true);
  }

  const Iframe = ({ source }) => {
    if (!source) {
      return <div className="loading">Carregando...</div>;
    }

    const src = source;
    return (
      <iframe
        title="Home Dashboard"
        height={user.userTypeId === 3 ? 1200 : 1800}
        src={src}
        frameBorder={0}
        style={{ width: "100%" }}
      />
    );
  };

  return (
    <div className="w-100">
      <PageTitle titleHeading="Dashboard Principal" />
      <div style={{ marginTop: "50px", marginLeft: "-30px", width: "100%" }}>
        {!show ? (
          <div className="container">
            <BarChart color="primary" fontSize="large" />
            Seu dashboard está pronto para ser explorado. Clique no botão abaixo
            para exibir as informações detalhadas.
            <Button
              onClick={handleShowMetabaseDashboard}
              color="primary"
              variant="contained"
            >
              Exibir Dashboard
            </Button>
          </div>
        ) : (
          <Iframe source={metabaseEmbedURL} />
        )}
      </div>
    </div>
  );
};

export default HomeDashboard;
