import useSWR from 'swr';

import { fetchWithTokenExternalService } from '../fetchWithToken';

export default function useExtension(maturityId = null, extensionId = null) {
  const {
    data: extension,
    error,
    isValidating,
    mutate,
  } = useSWR(
    maturityId && extensionId
      ? `${process.env.REACT_APP_EXTENSION_API}/extensions/${maturityId}/${extensionId}`
      : false,
    fetchWithTokenExternalService,
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      shouldRetryOnError: true,
      errorRetryInterval: 1000,
    }
  );

  return {
    extension: extension && extension.data ? extension.data : null,
    error,
    isValidating,
    isLoading: maturityId && extensionId && !error && !extension,
    mutate,
  };
}
