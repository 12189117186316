import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getDefaultCompany } from "services/business";

const WithAllowedAccessPage = () => (Component) => {
  const ComponentWithAllowedAccessPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    useEffect(async () => {
      setIsLoading(true);
      const token = localStorage.getItem("@App:token");

      const defaultCompany = await getDefaultCompany(token);
      setIsLoading(false);

      if (defaultCompany.statusId !== 4) {
        history.push("/");
      }
    }, []);

    if (isLoading) return <div />;

    return <Component />;
  };

  return ComponentWithAllowedAccessPage;
};

export default WithAllowedAccessPage;
