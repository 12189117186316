import React from 'react';

import { FormControl, TextField } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { ConfirmButton } from 'components/Button';

import { usePropostaSerpro } from 'hooks/usePropostaSerpro';

import './index.scss';

export function PropostaSerpro({ invoiceId }) {
  const {
    handleChange,
    formValues,
    handleSendPropostaSerpro,
    handleDataLiberacaoChange,
    isError,
  } = usePropostaSerpro({ invoiceId });
  return (
    <>
      <div
        id="proposta-serpro"
        // style={{ marginTop: '0', marginLeft: '0', width: '100%' }}
      >
        <header>
          <span>PROPOSTA SERPRO</span>
        </header>
        <body>
          <TextField
            variant="outlined"
            id="outlined-basic"
            name="valorLiquido"
            placeholder="Digite algo"
            label="Valor Líquido"
            type="number"
            fullWidth
            onChange={handleChange}
            value={formValues.valorLiquido}
          />

          <TextField
            variant="outlined"
            id="outlined-basic"
            name="valorMulta"
            placeholder="Digite algo"
            required
            label="Valor Multa"
            type="number"
            fullWidth
            error={isError.valorMulta}
            onChange={handleChange}
            value={formValues.valorMulta}
          />

          <TextField
            variant="outlined"
            id="outlined-basic"
            name="txJurosMora"
            placeholder="Digite algo"
            required
            label="Taxa Juros Mora"
            type="number"
            fullWidth
            error={isError.txJurosMora}
            onChange={handleChange}
            value={formValues.txJurosMora}
          />

          <TextField
            variant="outlined"
            id="outlined-basic"
            name="valorSeguro"
            placeholder="Digite algo"
            label="Valor Seguro*"
            type="number"
            fullWidth
            error={isError.valorSeguro}
            onChange={handleChange}
            value={formValues.valorSeguro}
          />

          <TextField
            variant="outlined"
            id="outlined-basic"
            name="inModalidade"
            placeholder="Digite algo"
            label="Modalidade"
            fullWidth
            onChange={handleChange}
            value={formValues.inModalidade}
          />

          <div className="containerDatePicker">
            <FormControl className="w-100">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <KeyboardDatePicker
                  helperText=""
                  cancelLabel="Cancelar"
                  fullWidth
                  className="ml-2 mr-2 mb-0 text-field"
                  id="dtvenc"
                  locale={ptBR}
                  label="Data Vencimento"
                  format="yyyy-MM-dd"
                  name="vencimento"
                  variant="outlined"
                  inputVariant="outlined"
                  disablePast
                  value={formValues.dataLiberacao}
                  onChange={handleDataLiberacaoChange}
                  error={isError.dataLiberacao}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </div>

          <TextField
            variant="outlined"
            id="outlined-basic"
            name="nomeInstituicao"
            placeholder="Digite algo"
            label="Nome Instituição*"
            fullWidth
            error={isError.nomeInstituicao}
            onChange={handleChange}
            value={formValues.nomeInstituicao}
          />

          <TextField
            variant="outlined"
            id="outlined-basic"
            name="codPlataforma"
            placeholder="Digite algo"
            label="Código da plataforma*"
            fullWidth
            error={isError.codPlataforma}
            onChange={handleChange}
            value={formValues.codPlataforma}
          />

          <TextField
            variant="outlined"
            id="outlined-basic"
            name="idCotacao"
            placeholder="Digite algo"
            label="ID Cotação"
            fullWidth
            onChange={handleChange}
            value={formValues.idCotacao}
          />
        </body>
        <footer>
          <ConfirmButton onClick={handleSendPropostaSerpro}>
            Enviar Proposta
          </ConfirmButton>
        </footer>
      </div>
    </>
  );
}
