import { createMuiTheme } from '@material-ui/core';
import typography from './typography';

import vars from '!!sass-vars-to-js-loader!../assets/core/_variables-mui.scss';

// const FederalInvestSellerTheme = createMuiTheme({
//   palette: {
//     primary: {
//       main: 'rgb(76,175,80)',
//     },
//     grey: {
//       300: vars.inheritDefault1,
//       A100: vars.inheritDefault2,
//     },
//     secondary: {
//       main: vars.secondary,
//     },
//     error: {
//       main: vars.red,
//     },
//     success: {
//       main: vars.green,
//     },
//     warning: {
//       main: vars.orange,
//     },
//     helpers: {
//       primary: vars.blue,
//       main: 'rgba(25, 46, 91, .035)',
//     },
//     contrastThreshold: 3,
//     tonalOffset: 0.1,
//   },
//   shape: {
//     borderRadius: '0.5rem',
//   },
//   overrides: {
//     MuiButton: {
//       text: {
//         paddingLeft: '14px',
//         paddingRight: '14px',
//       },
//       containedSizeSmall: {
//         paddingLeft: '14px',
//         paddingRight: '14px',
//       },
//       root: {
//         textTransform: 'none',
//         fontWeight: 'normal',
//       },
//     },
//     MuiTooltip: {
//       tooltip: {
//         backgroundColor: vars.second,
//         padding: '8px 16px',
//         fontSize: '13px',
//       },
//       arrow: {
//         color: vars.second,
//       },
//     },
//   },
//   typography,
// });

// const FederalInvestAdminTheme = createMuiTheme({
//   palette: {
//     primary: {
//       main: 'rgb(36 34 34)',
//       dark: 'rgb(80 80 80)',
//     },
//     grey: {
//       300: vars.inheritDefault1,
//       A100: vars.inheritDefault2,
//     },
//     secondary: {
//       main: vars.secondary,
//     },
//     error: {
//       main: vars.red,
//     },
//     success: {
//       main: vars.green,
//     },
//     warning: {
//       main: vars.orange,
//     },
//     helpers: {
//       primary: vars.blue,
//       main: 'rgba(25, 46, 91, .035)',
//     },
//     contrastThreshold: 3,
//     tonalOffset: 0.1,
//   },
//   shape: {
//     borderRadius: '0.5rem',
//   },
//   overrides: {
//     MuiButton: {
//       text: {
//         paddingLeft: '14px',
//         paddingRight: '14px',
//       },
//       containedSizeSmall: {
//         paddingLeft: '14px',
//         paddingRight: '14px',
//       },
//       root: {
//         textTransform: 'none',
//         fontWeight: 'normal',
//       },
//     },
//     MuiTooltip: {
//       tooltip: {
//         backgroundColor: vars.second,
//         padding: '8px 16px',
//         fontSize: '13px',
//       },
//       arrow: {
//         color: vars.second,
//       },
//     },
//   },
//   typography,
// });

// const FederalInvestBuyerTheme = createMuiTheme({
//   palette: {
//     primary: {
//       main: '#2d82e2',
//     },
//     grey: {
//       300: vars.inheritDefault1,
//       A100: vars.inheritDefault2,
//     },
//     secondary: {
//       main: vars.secondary,
//     },
//     error: {
//       main: vars.red,
//     },
//     success: {
//       main: vars.green,
//     },
//     warning: {
//       main: vars.orange,
//     },
//     helpers: {
//       primary: vars.blue,
//       main: 'rgba(25, 46, 91, .035)',
//     },
//     contrastThreshold: 3,
//     tonalOffset: 0.1,
//   },
//   shape: {
//     borderRadius: '0.5rem',
//   },
//   overrides: {
//     MuiButton: {
//       text: {
//         paddingLeft: '14px',
//         paddingRight: '14px',
//       },
//       containedSizeSmall: {
//         paddingLeft: '14px',
//         paddingRight: '14px',
//       },
//       root: {
//         textTransform: 'none',
//         fontWeight: 'normal',
//       },
//     },
//     MuiTooltip: {
//       tooltip: {
//         backgroundColor: vars.second,
//         padding: '8px 16px',
//         fontSize: '13px',
//       },
//       arrow: {
//         color: vars.second,
//       },
//     },
//   },
//   typography,
// });

// const AntecipaFacilSellerTheme = createMuiTheme({
//   palette: {
//     primary: {
//       main: 'rgb(76,175,80)',
//     },
//     grey: {
//       300: vars.inheritDefault1,
//       A100: vars.inheritDefault2,
//     },
//     secondary: {
//       main: vars.secondary,
//     },
//     error: {
//       main: vars.red,
//     },
//     success: {
//       main: vars.green,
//     },
//     warning: {
//       main: vars.orange,
//     },
//     helpers: {
//       primary: vars.blue,
//       main: 'rgba(25, 46, 91, .035)',
//     },
//     contrastThreshold: 3,
//     tonalOffset: 0.1,
//   },
//   shape: {
//     borderRadius: '0.5rem',
//   },
//   overrides: {
//     MuiButton: {
//       text: {
//         paddingLeft: '14px',
//         paddingRight: '14px',
//       },
//       containedSizeSmall: {
//         paddingLeft: '14px',
//         paddingRight: '14px',
//       },
//       root: {
//         textTransform: 'none',
//         fontWeight: 'normal',
//       },
//     },
//     MuiTooltip: {
//       tooltip: {
//         backgroundColor: vars.second,
//         padding: '8px 16px',
//         fontSize: '13px',
//       },
//       arrow: {
//         color: vars.second,
//       },
//     },
//   },
//   typography,
// });

// const AntecipaFacilAdminTheme = createMuiTheme({
//   palette: {
//     primary: {
//       main: 'rgb(36 34 34)',
//       dark: 'rgb(80 80 80)',
//     },
//     grey: {
//       300: vars.inheritDefault1,
//       A100: vars.inheritDefault2,
//     },
//     secondary: {
//       main: vars.secondary,
//     },
//     error: {
//       main: vars.red,
//     },
//     success: {
//       main: vars.green,
//     },
//     warning: {
//       main: vars.orange,
//     },
//     helpers: {
//       primary: vars.blue,
//       main: 'rgba(25, 46, 91, .035)',
//     },
//     contrastThreshold: 3,
//     tonalOffset: 0.1,
//   },
//   shape: {
//     borderRadius: '0.5rem',
//   },
//   overrides: {
//     MuiButton: {
//       text: {
//         paddingLeft: '14px',
//         paddingRight: '14px',
//       },
//       containedSizeSmall: {
//         paddingLeft: '14px',
//         paddingRight: '14px',
//       },
//       root: {
//         textTransform: 'none',
//         fontWeight: 'normal',
//       },
//     },
//     MuiTooltip: {
//       tooltip: {
//         backgroundColor: vars.second,
//         padding: '8px 16px',
//         fontSize: '13px',
//       },
//       arrow: {
//         color: vars.second,
//       },
//     },
//   },
//   typography,
// });

// const AntecipaFacilBuyerTheme = createMuiTheme({
//   palette: {
//     primary: {
//       main: '#FA850E',
//     },
//     grey: {
//       300: vars.inheritDefault1,
//       A100: vars.inheritDefault2,
//     },
//     secondary: {
//       main: vars.secondary,
//     },
//     error: {
//       main: vars.red,
//     },
//     success: {
//       main: vars.green,
//     },
//     warning: {
//       main: vars.orange,
//     },
//     helpers: {
//       primary: vars.blue,
//       main: 'rgba(25, 46, 91, .035)',
//     },
//     contrastThreshold: 3,
//     tonalOffset: 0.1,
//   },
//   shape: {
//     borderRadius: '0.5rem',
//   },
//   overrides: {
//     MuiButton: {
//       text: {
//         paddingLeft: '14px',
//         paddingRight: '14px',
//       },
//       containedSizeSmall: {
//         paddingLeft: '14px',
//         paddingRight: '14px',
//       },
//       root: {
//         textTransform: 'none',
//         fontWeight: 'normal',
//       },
//     },
//     MuiTooltip: {
//       tooltip: {
//         backgroundColor: vars.second,
//         padding: '8px 16px',
//         fontSize: '13px',
//       },
//       arrow: {
//         color: vars.second,
//       },
//     },
//   },
//   typography,
// });

export const FederalDefault = createMuiTheme({
  palette: {
    primary: {
      main: vars.green,
    },
    grey: {
      300: vars.inheritDefault1,
      A100: vars.inheritDefault2,
    },
    secondary: {
      main: vars.secondary,
    },
    error: {
      main: vars.red,
    },
    success: {
      main: vars.green,
    },
    warning: {
      main: vars.orange,
    },
    helpers: {
      primary: vars.blue,
      main: 'rgba(25, 46, 91, .035)',
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shape: {
    borderRadius: '0.5rem',
  },
  overrides: {
    MuiButton: {
      text: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      containedSizeSmall: {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      root: {
        textTransform: 'none',
        fontWeight: 'normal',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: vars.second,
        padding: '8px 16px',
        fontSize: '13px',
      },
      arrow: {
        color: vars.second,
      },
    },
  },
  typography,
});

export const Default = (primary) => (secondary) =>
  createMuiTheme({
    palette: {
      primary: {
        main: primary || vars.green,
      },
      grey: {
        300: vars.inheritDefault1,
        A100: vars.inheritDefault2,
      },
      secondary: {
        main: secondary || vars.secondary,
      },
      error: {
        main: vars.red,
      },
      success: {
        main: vars.green,
      },
      warning: {
        main: vars.orange,
      },
      helpers: {
        primary: vars.blue,
        main: 'rgba(25, 46, 91, .035)',
      },
      contrastThreshold: 3,
      tonalOffset: 0.1,
    },
    shape: {
      borderRadius: '0.5rem',
    },
    overrides: {
      MuiButton: {
        text: {
          paddingLeft: '14px',
          paddingRight: '14px',
        },
        containedSizeSmall: {
          paddingLeft: '14px',
          paddingRight: '14px',
        },
        root: {
          textTransform: 'none',
          fontWeight: 'normal',
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: vars.second,
          padding: '8px 16px',
          fontSize: '13px',
        },
        arrow: {
          color: vars.second,
        },
      },
    },
    typography,
  });

export const DefaultSellerTheme = (primary) => (secondary) =>
  createMuiTheme({
    palette: {
      primary: {
        main: primary || 'rgb(76,175,80)',
        contrastText: '#fff'
      },
      grey: {
        300: vars.inheritDefault1,
        A100: vars.inheritDefault2,
      },
      secondary: {
        main: secondary || vars.secondary,
      },
      error: {
        main: vars.red,
      },
      success: {
        main: vars.green,
      },
      warning: {
        main: vars.orange,
      },
      helpers: {
        primary: vars.blue,
        main: 'rgba(25, 46, 91, .035)',
      },
      contrastThreshold: 3,
      tonalOffset: 0.1,
    },
    shape: {
      borderRadius: '0.5rem',
    },
    overrides: {
      MuiButton: {
        text: {
          paddingLeft: '14px',
          paddingRight: '14px',
        },
        containedSizeSmall: {
          paddingLeft: '14px',
          paddingRight: '14px',
        },
        root: {
          textTransform: 'none',
          fontWeight: 'normal',
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: vars.second,
          padding: '8px 16px',
          fontSize: '13px',
        },
        arrow: {
          color: vars.second,
        },
      },
    },
    typography,
  });

export const DefaultBuyerTheme = (primary) => (secondary) =>
  createMuiTheme({
    palette: {
      primary: {
        main: primary || '#FA850E',
        contrastText: '#fff'
      },
      grey: {
        300: vars.inheritDefault1,
        A100: vars.inheritDefault2,
      },
      secondary: {
        main: secondary || vars.secondary,
      },
      error: {
        main: vars.red,
      },
      success: {
        main: vars.green,
      },
      warning: {
        main: vars.orange,
      },
      helpers: {
        primary: vars.blue,
        main: 'rgba(25, 46, 91, .035)',
      },
      contrastThreshold: 3,
      tonalOffset: 0.1,
    },
    shape: {
      borderRadius: '0.5rem',
    },
    overrides: {
      MuiButton: {
        text: {
          paddingLeft: '14px',
          paddingRight: '14px',
        },
        containedSizeSmall: {
          paddingLeft: '14px',
          paddingRight: '14px',
        },
        root: {
          textTransform: 'none',
          fontWeight: 'normal',
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: vars.second,
          padding: '8px 16px',
          fontSize: '13px',
        },
        arrow: {
          color: vars.second,
        },
      },
    },
    typography,
  });

export const DefaultAdminTheme = (primary) => (secondary) =>
  createMuiTheme({
    palette: {
      primary: {
        main: primary || 'rgb(36 34 34)',
        contrastText: '#fff'
      },
      grey: {
        300: vars.inheritDefault1,
        A100: vars.inheritDefault2,
      },
      secondary: {
        main: secondary || vars.secondary,
        contrastText: "#fff"
      },
      error: {
        main: vars.red,
        contrastText: "#fff"
      },
      success: {
        main: vars.green,
        contrastText: "#fff"
      },
      warning: {
        main: vars.orange,
      },
      helpers: {
        primary: vars.blue,
        main: 'rgba(25, 46, 91, .035)',
      },
      contrastThreshold: 3,
      tonalOffset: 0.1,
    },
    shape: {
      borderRadius: '0.5rem',
    },
    overrides: {
      MuiButton: {
        text: {
          paddingLeft: '14px',
          paddingRight: '14px',
        },
        containedSizeSmall: {
          paddingLeft: '14px',
          paddingRight: '14px',
        },
        root: {
          textTransform: 'none',
          fontWeight: 'normal',
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: vars.second,
          padding: '8px 16px',
          fontSize: '13px',
        },
        arrow: {
          color: vars.second,
        },
      },
    },
    typography,
  });

const loadDefaultTheme = (primary, secondary) => {
  return Default(primary)(secondary);
};

const loadAdminTheme = (primary, secondary) => {
  return DefaultAdminTheme(primary)(secondary);
};

const loadBuyerTheme = (primary, secondary) => {
  return DefaultBuyerTheme(primary)(secondary);
};

const loadSellerTheme = (primary, secondary) => {
  return DefaultSellerTheme(primary)(secondary);
};

export const AdminTheme = loadAdminTheme;
export const BuyerTheme = loadBuyerTheme;
export const SellerTheme = loadSellerTheme;

export default loadDefaultTheme;
