import api from 'axios';

export async function cadastrarTitulo(form) {
  const payload = {
    cotacao: form,
  };
  const url = `${process.env.REACT_APP_NODE_API}/invoice`;

  await api.post(url, payload);
}
