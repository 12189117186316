import React, { useContext, useState } from 'react';

import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Autocomplete } from '@material-ui/lab';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { DialogContext } from '../../../contexts/dialog';
import useBusiness from '../../../hooks/useBusiness';
import { alertMessageSwal } from '../../../services/SwalService';
import TitleSection from '../../TitleSection';

function CreateAccountModal({
  statuses = [],
  tabValue,
  openAccountConsigned,
  setPage,
  setFilters,
}) {
  const dialogContext = useContext(DialogContext);
  const { business, isLoading: isLoadingBusiness } = useBusiness({
    revalidateOnFocus: false,
  });

  const [tab, setTabValue] = useState(tabValue);

  const formikFreeMovement = useFormik({
    initialValues: {
      company: null,
      account_number: '',
      account_agency: '',
      account_status: '',
      account_ted_number: '',
      account_ted_agency: '',
      account_ted_bank: '',
      account_pix_number: '',
      account_pix_agency: '',
      account_pix_bank: '',
    },
  });

  const formikConsignedAccount = useFormik({
    initialValues: {
      company: null,
    },

    validationSchema: Yup.object({
      company: Yup.mixed().required('Informe a empresa'),
    }),

    onSubmit: async (values) => {
      if (!values.company.countryIdentity) {
        return alertMessageSwal(
          'Atenção',
          'Empresa não possui CNPJ. Não é possível abrir conta. Preencha o CNPJ da empresa em seu cadastro'
        );
      }

      const response = await openAccountConsigned({
        authorizationConsultSCR: true,
        cnpj: values.company.countryIdentity
          .replace('/', '')
          .replace('-', '')
          .replace(/\./g, ''),
        businessId: values.company.businessId,
        financialData: {
          annualBilling: 0,
          netWorth: 0,
        },
      });

      if (response) {
        setPage(1);
        setFilters({});
        dialogContext.toggleDialog(false);
      }
    },
  });

  return (
    <Box>
      <Box mb={2}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => {
            formikConsignedAccount.resetForm();
            formikFreeMovement.resetForm();
            setTabValue(value);
          }}
        >
          <Tab label="Contas consignadas" value="account-consigned" />
          <Tab label="Livre movimentação" value="free-movement" />
        </Tabs>
      </Box>

      {tab === 'account-consigned' ? (
        <form onSubmit={formikConsignedAccount.handleSubmit}>
          <Paper elevation={4} variant="outlined">
            <TitleSection title="Empresa responsável" />

            <Box p={2}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12}>
                  <Autocomplete
                    options={business ?? []}
                    getOptionLabel={(value) => value.name}
                    filterSelectedOptions
                    loading={isLoadingBusiness}
                    value={formikConsignedAccount.values.company}
                    noOptionsText="Nenhuma empresa encontrada"
                    onChange={(e, value) => {
                      formikConsignedAccount.setFieldValue('company', value);
                    }}
                    onBlur={formikConsignedAccount.handleBlur}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          formikConsignedAccount.touched.company
                            ? formikConsignedAccount.errors.company
                            : ''
                        }
                        error={
                          Boolean(formikConsignedAccount.errors.company) &&
                          formikConsignedAccount.touched.company
                        }
                        label="Empresa"
                        name="company"
                        fullWidth
                        className="text-field-label"
                        variant="outlined"
                        placeholder="Informe de qual empresa é a conta"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      type="submit"
                      disabled={!formikConsignedAccount.isValid}
                      color="primary"
                      variant="contained"
                    >
                      Salvar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </form>
      ) : null}

      {tab === 'free-movement' ? (
        <form onSubmit={formikFreeMovement.handleSubmit}>
          <Paper elevation={4} variant="outlined">
            <TitleSection title="Dados básicos" />

            <Box p={2}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12}>
                  <Autocomplete
                    options={[]}
                    getOptionLabel={(value) => value.name}
                    filterSelectedOptions
                    value={formikFreeMovement.values.company}
                    noOptionsText="Nenhuma empresa encontrada"
                    autoSelect
                    onChange={(e, value) => {
                      formikFreeMovement.setFieldValue('company', value);
                    }}
                    onBlur={formikFreeMovement.handleBlur}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          formikFreeMovement.touched.company
                            ? formikFreeMovement.errors.company
                            : ''
                        }
                        error={
                          Boolean(formikFreeMovement.errors.company) &&
                          formikFreeMovement.touched.company
                        }
                        label="Empresa"
                        name="company"
                        fullWidth
                        variant="outlined"
                        InputProps={{
                          style: {
                            borderRadius: 20,
                            color: '#000',
                            fontSize: 22,
                          },
                        }}
                        placeholder="Informe de qual empresa é a conta"
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    variant="outlined"
                    value={formikFreeMovement.values.account_number}
                    name="account_number"
                    label="Informe o número da conta bancária"
                    helperText={
                      formikFreeMovement.touched.account_number
                        ? formikFreeMovement.errors.account_number
                        : ''
                    }
                    error={
                      Boolean(formikFreeMovement.errors.account_number) &&
                      formikFreeMovement.touched.account_number
                    }
                    onChange={formikFreeMovement.handleChange}
                    onBlur={formikFreeMovement.handleBlur}
                    placeholder="Número da conta"
                    className="text-field-label"
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: 20,
                        color: '#000',
                        fontSize: 22,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    variant="outlined"
                    value={formikFreeMovement.values.account_agency}
                    name="account_agency"
                    label="Agência bancária"
                    helperText={
                      formikFreeMovement.touched.account_agency
                        ? formikFreeMovement.errors.account_agency
                        : ''
                    }
                    error={
                      Boolean(formikFreeMovement.errors.account_agency) &&
                      formikFreeMovement.touched.account_agency
                    }
                    onChange={formikFreeMovement.handleChange}
                    onBlur={formikFreeMovement.handleBlur}
                    placeholder="Informe a agência bancária"
                    className="text-field-label"
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: 20,
                        color: '#000',
                        fontSize: 22,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    className="text-field-label"
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      fullWidth
                      labelId="status-label"
                      id="status-outlined"
                      placeholder="Selecione o status"
                      value={formikFreeMovement.values.account_status}
                      helperText={
                        formikFreeMovement.touched.account_status
                          ? formikFreeMovement.errors.account_status
                          : ''
                      }
                      error={
                        Boolean(formikFreeMovement.errors.account_status) &&
                        formikFreeMovement.touched.account_status
                      }
                      onChange={formikFreeMovement.handleChange}
                      onBlur={formikFreeMovement.handleBlur}
                      label="Status"
                      name="account_status"
                    >
                      <MenuItem value={1}>Liberado</MenuItem>
                      <MenuItem value={2}>Bloqueado</MenuItem>
                      <MenuItem value={3}>Negada</MenuItem>
                      <MenuItem value={4}>Aberta</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Paper>

          <Box mt={2}>
            <Paper elevation={4} variant="outlined">
              <TitleSection title="TED" />

              <Box p={2}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      variant="outlined"
                      value={formikFreeMovement.values.account_ted_number}
                      name="account_ted_number"
                      label="Informe o número da conta bancária"
                      helperText={
                        formikFreeMovement.touched.account_ted_number
                          ? formikFreeMovement.errors.account_ted_number
                          : ''
                      }
                      error={
                        Boolean(formikFreeMovement.errors.account_ted_number) &&
                        formikFreeMovement.touched.account_ted_number
                      }
                      onChange={formikFreeMovement.handleChange}
                      onBlur={formikFreeMovement.handleBlur}
                      placeholder="Número da conta"
                      className="text-field-label"
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: 20,
                          color: '#000',
                          fontSize: 22,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      variant="outlined"
                      value={formikFreeMovement.values.account_ted_agency}
                      name="account_ted_number"
                      label="Informe o número da agência bancária"
                      helperText={
                        formikFreeMovement.touched.account_ted_agency
                          ? formikFreeMovement.errors.account_ted_agency
                          : ''
                      }
                      error={
                        Boolean(formikFreeMovement.errors.account_ted_agency) &&
                        formikFreeMovement.touched.account_ted_agency
                      }
                      onChange={formikFreeMovement.handleChange}
                      onBlur={formikFreeMovement.handleBlur}
                      placeholder="Número da agência"
                      className="text-field-label"
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: 20,
                          color: '#000',
                          fontSize: 22,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Autocomplete
                      options={[]}
                      getOptionLabel={(value) => value.name}
                      filterSelectedOptions
                      value={formikFreeMovement.values.account_ted_bank}
                      noOptionsText="Nenhum banco"
                      autoSelect
                      onChange={(e, value) => {
                        formikFreeMovement.setFieldValue(
                          'account_ted_bank',
                          value
                        );
                      }}
                      onBlur={formikFreeMovement.handleBlur}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            formikFreeMovement.touched.account_ted_bank
                              ? formikFreeMovement.errors.account_ted_bank
                              : ''
                          }
                          error={
                            Boolean(
                              formikFreeMovement.errors.account_ted_bank
                            ) && formikFreeMovement.touched.account_ted_bank
                          }
                          label="Banco"
                          name="account_ted_bank"
                          fullWidth
                          className="text-field-label"
                          variant="outlined"
                          InputProps={{
                            style: {
                              borderRadius: 20,
                              color: '#000',
                              fontSize: 22,
                            },
                          }}
                          placeholder="Informe o banco"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>

          <Box mt={2}>
            <Paper elevation={4} variant="outlined">
              <TitleSection title="PIX" />

              <Box p={2}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      variant="outlined"
                      value={formikFreeMovement.values.account_pix_number}
                      name="account_pix_number"
                      label="Informe o número da conta bancária"
                      helperText={
                        formikFreeMovement.touched.account_pix_number
                          ? formikFreeMovement.errors.account_pix_number
                          : ''
                      }
                      error={
                        Boolean(formikFreeMovement.errors.account_pix_number) &&
                        formikFreeMovement.touched.account_pix_number
                      }
                      onChange={formikFreeMovement.handleChange}
                      onBlur={formikFreeMovement.handleBlur}
                      placeholder="Número da conta"
                      className="text-field-label"
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: 20,
                          color: '#000',
                          fontSize: 22,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      variant="outlined"
                      value={formikFreeMovement.values.account_pix_agency}
                      name="account_pix_agency"
                      label="Informe o número da agência bancária"
                      helperText={
                        formikFreeMovement.touched.account_pix_agency
                          ? formikFreeMovement.errors.account_pix_agency
                          : ''
                      }
                      error={
                        Boolean(formikFreeMovement.errors.account_pix_agency) &&
                        formikFreeMovement.touched.account_pix_agency
                      }
                      onChange={formikFreeMovement.handleChange}
                      onBlur={formikFreeMovement.handleBlur}
                      placeholder="Número da agência"
                      className="text-field-label"
                      fullWidth
                      InputProps={{
                        style: {
                          borderRadius: 20,
                          color: '#000',
                          fontSize: 22,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Autocomplete
                      options={[]}
                      getOptionLabel={(value) => value.name}
                      filterSelectedOptions
                      value={formikFreeMovement.values.account_ted_bank}
                      noOptionsText="Nenhum banco"
                      autoSelect
                      onChange={(e, value) => {
                        formikFreeMovement.setFieldValue(
                          'account_ted_bank',
                          value
                        );
                      }}
                      onBlur={formikFreeMovement.handleBlur}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            formikFreeMovement.touched.account_ted_bank
                              ? formikFreeMovement.errors.account_ted_bank
                              : ''
                          }
                          error={
                            Boolean(
                              formikFreeMovement.errors.account_ted_bank
                            ) && formikFreeMovement.touched.account_ted_bank
                          }
                          label="Banco"
                          name="account_ted_bank"
                          fullWidth
                          className="text-field-label"
                          variant="outlined"
                          InputProps={{
                            style: {
                              borderRadius: 20,
                              color: '#000',
                              fontSize: 22,
                            },
                          }}
                          placeholder="Informe o banco"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>

            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button type="submit" variant="contained" disabled>
                Cadastrar
              </Button>
            </Box>
          </Box>
        </form>
      ) : null}
    </Box>
  );
}

export default React.memo(CreateAccountModal);
