import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export default function useVitrine() {
  const { pathname } = useLocation();
  const [isOnVitrine, setIsOnVitrine] = useState(false);

  useEffect(() => {
    setIsOnVitrine(pathname.includes('/vitrine'));
  }, [pathname]);

  return { isOnVitrine };
}
