import { useAuthToken } from "hooks/useAuth";
import LoadingPage from "pages/LoadingPage";
import React from "react";

import { useAuth } from "../contexts/auth";
import AdminRoutes from "./AdminRoutes";
import BuyerRoutes from "./BuyerRoutes";
import SellerRoutes from "./SellerRoutes";
import SignRoutes from "./SignRoutes";

const Routes = () => {
  const { loggedIn, loading, userTypeId } = useAuthToken();
  const { signed, user } = useAuth();

  if (loading) {
    return <LoadingPage />;
  }
  const loadPerfilRoutes = (userTypeId) => {
    if (userTypeId == 1) return <SellerRoutes tema={user?.tema?.tema?.ced} />;
    if (userTypeId == 2) return <BuyerRoutes tema={user?.tema?.tema?.fin} />;
    if (userTypeId == 3) return <AdminRoutes tema={user?.tema?.tema?.adm} />;
  };

  return loggedIn == true ? loadPerfilRoutes(userTypeId) : <SignRoutes />;
};

export default Routes;
