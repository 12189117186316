import React from 'react';

import { useTheme } from '@material-ui/core/styles';

export default function Vitrine({
  color = '#A0A0A0',
  primary = false,
  size = 40,
}) {
  const theme = useTheme();

  const iconColor = primary ? theme.palette.primary.main : color;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 75) / 100}
      viewBox="0 0 60.568 45.427"
    >
      <g
        id="Grupo_94"
        data-name="Grupo 94"
        transform="translate(101.683 -195.76)"
      >
        <g
          id="invoice_1_"
          data-name="invoice (1)"
          fill={iconColor}
          transform="translate(-67.192 202.288) rotate(9)"
        >
          <g id="Grupo_92" data-name="Grupo 92" transform="translate(0 0)">
            <g id="Grupo_91" data-name="Grupo 91">
              <path
                id="Caminho_70"
                data-name="Caminho 70"
                opacity={0.162}
                d="M26.187,7.549,18.853.215A.733.733,0,0,0,18.334,0H2.933A2.937,2.937,0,0,0,0,2.934V32.269A2.937,2.937,0,0,0,2.934,35.2H23.468A2.937,2.937,0,0,0,26.4,32.269V8.067A.733.733,0,0,0,26.187,7.549ZM19.068,2.5l4.83,4.83H20.535a1.468,1.468,0,0,1-1.467-1.467Zm5.867,29.765a1.468,1.468,0,0,1-1.467,1.467H2.934a1.468,1.468,0,0,1-1.467-1.467V2.934A1.468,1.468,0,0,1,2.934,1.467H17.6v4.4A2.937,2.937,0,0,0,20.535,8.8h4.4Z"
              />
              <path
                id="Caminho_71"
                data-name="Caminho 71"
                opacity={0.162}
                d="M16.868,0H.733a.733.733,0,1,0,0,1.467H16.868a.733.733,0,1,0,0-1.467Z"
                transform="translate(4.4 20.535)"
              />
              <path
                id="Caminho_72"
                data-name="Caminho 72"
                opacity={0.162}
                d="M16.868,0H.733a.733.733,0,0,0,0,1.467H16.868a.733.733,0,1,0,0-1.467Z"
                transform="translate(4.4 16.134)"
              />
              <path
                id="Caminho_73"
                data-name="Caminho 73"
                opacity={0.162}
                d="M16.868,0H.733a.733.733,0,1,0,0,1.467H16.868a.733.733,0,1,0,0-1.467Z"
                transform="translate(4.4 24.935)"
              />
              <path
                id="Caminho_74"
                data-name="Caminho 74"
                opacity={0.162}
                d="M9.534,0H.733a.733.733,0,1,0,0,1.467h8.8A.733.733,0,0,0,9.534,0Z"
                transform="translate(4.4 29.335)"
              />
              <path
                id="Caminho_75"
                data-name="Caminho 75"
                opacity={0.162}
                d="M0,.733a.733.733,0,0,0,.733.733h8.8A.733.733,0,1,0,9.534,0H.733A.733.733,0,0,0,0,.733Z"
                transform="translate(11.734 11.734)"
              />
              <path
                id="Caminho_76"
                data-name="Caminho 76"
                opacity={0.162}
                d="M2.2,6.6H.733a.733.733,0,1,0,0,1.467h.733a.733.733,0,1,0,1.467,0V7.932A2.195,2.195,0,0,0,2.2,3.667.733.733,0,0,1,2.2,2.2H3.667a.733.733,0,1,0,0-1.467H2.933a.733.733,0,1,0-1.467,0V.869A2.195,2.195,0,0,0,2.2,5.134.733.733,0,0,1,2.2,6.6Z"
                transform="translate(4.4 5.134)"
              />
            </g>
          </g>
        </g>
        <g
          id="invoice_1_2"
          data-name="invoice (1)"
          fill={iconColor}
          transform="translate(-101.683 206.418) rotate(-9)"
        >
          <g id="Grupo_92-2" data-name="Grupo 92" transform="translate(0 0)">
            <g id="Grupo_91-2" data-name="Grupo 91" transform="translate(0 0)">
              <path
                id="Caminho_70-2"
                data-name="Caminho 70"
                opacity={0.162}
                d="M26.187,7.549,18.853.215A.733.733,0,0,0,18.334,0H2.933A2.937,2.937,0,0,0,0,2.934V32.269A2.937,2.937,0,0,0,2.934,35.2H23.468A2.937,2.937,0,0,0,26.4,32.269V8.067A.733.733,0,0,0,26.187,7.549ZM19.068,2.5l4.83,4.83H20.535a1.468,1.468,0,0,1-1.467-1.467Zm5.867,29.765a1.468,1.468,0,0,1-1.467,1.467H2.934a1.468,1.468,0,0,1-1.467-1.467V2.934A1.468,1.468,0,0,1,2.934,1.467H17.6v4.4A2.937,2.937,0,0,0,20.535,8.8h4.4Z"
              />
              <path
                id="Caminho_71-2"
                data-name="Caminho 71"
                opacity={0.162}
                d="M16.868,0H.733a.733.733,0,1,0,0,1.467H16.868a.733.733,0,1,0,0-1.467Z"
                transform="translate(4.4 20.535)"
              />
              <path
                id="Caminho_72-2"
                data-name="Caminho 72"
                opacity={0.162}
                d="M16.868,0H.733a.733.733,0,0,0,0,1.467H16.868a.733.733,0,1,0,0-1.467Z"
                transform="translate(4.4 16.134)"
              />
              <path
                id="Caminho_73-2"
                data-name="Caminho 73"
                opacity={0.162}
                d="M16.868,0H.733a.733.733,0,1,0,0,1.467H16.868a.733.733,0,1,0,0-1.467Z"
                transform="translate(4.4 24.935)"
              />
              <path
                id="Caminho_74-2"
                data-name="Caminho 74"
                opacity={0.162}
                d="M9.534,0H.733a.733.733,0,1,0,0,1.467h8.8A.733.733,0,0,0,9.534,0Z"
                transform="translate(4.4 29.335)"
              />
              <path
                id="Caminho_75-2"
                data-name="Caminho 75"
                opacity={0.162}
                d="M0,.733a.733.733,0,0,0,.733.733h8.8A.733.733,0,1,0,9.534,0H.733A.733.733,0,0,0,0,.733Z"
                transform="translate(11.734 11.734)"
              />
              <path
                id="Caminho_76-2"
                data-name="Caminho 76"
                opacity={0.162}
                d="M2.2,6.6H.733a.733.733,0,1,0,0,1.467h.733a.733.733,0,1,0,1.467,0V7.932A2.195,2.195,0,0,0,2.2,3.667.733.733,0,0,1,2.2,2.2H3.667a.733.733,0,1,0,0-1.467H2.933a.733.733,0,1,0-1.467,0V.869A2.195,2.195,0,0,0,2.2,5.134.733.733,0,0,1,2.2,6.6Z"
                transform="translate(4.4 5.134)"
              />
            </g>
          </g>
        </g>
        <g
          id="invoice_1_3"
          data-name="invoice (1)"
          fill={iconColor}
          transform="translate(-86.05 195.76)"
        >
          <g id="Grupo_92-3" data-name="Grupo 92" transform="translate(0)">
            <g id="Grupo_91-3" data-name="Grupo 91">
              <path
                id="Caminho_70-3"
                data-name="Caminho 70"
                opacity={0.362}
                d="M96.107,9.255,87.115.263A.9.9,0,0,0,86.479,0H67.6A3.6,3.6,0,0,0,64,3.6V39.564a3.6,3.6,0,0,0,3.6,3.6H92.774a3.6,3.6,0,0,0,3.6-3.6V9.891A.9.9,0,0,0,96.107,9.255ZM87.379,3.07,93.3,8.992H89.177a1.8,1.8,0,0,1-1.8-1.8Zm7.193,36.494a1.8,1.8,0,0,1-1.8,1.8H67.6a1.8,1.8,0,0,1-1.8-1.8V3.6a1.8,1.8,0,0,1,1.8-1.8H85.58v5.4a3.6,3.6,0,0,0,3.6,3.6h5.4Z"
                transform="translate(-64 0)"
              />
              <path
                id="Caminho_71-3"
                data-name="Caminho 71"
                opacity={0.362}
                d="M148.681,298.667H128.9a.9.9,0,1,0,0,1.8h19.782a.9.9,0,1,0,0-1.8Z"
                transform="translate(-122.605 -273.49)"
              />
              <path
                id="Caminho_72-3"
                data-name="Caminho 72"
                opacity={0.362}
                d="M148.681,234.667H128.9a.9.9,0,1,0,0,1.8h19.782a.9.9,0,1,0,0-1.8Z"
                transform="translate(-122.605 -214.885)"
              />
              <path
                id="Caminho_73-3"
                data-name="Caminho 73"
                opacity={0.362}
                d="M148.681,362.667H128.9a.9.9,0,1,0,0,1.8h19.782a.9.9,0,1,0,0-1.8Z"
                transform="translate(-122.605 -332.095)"
              />
              <path
                id="Caminho_74-3"
                data-name="Caminho 74"
                opacity={0.362}
                d="M139.689,426.667H128.9a.9.9,0,1,0,0,1.8h10.79a.9.9,0,1,0,0-1.8Z"
                transform="translate(-122.605 -390.7)"
              />
              <path
                id="Caminho_75-3"
                data-name="Caminho 75"
                opacity={0.362}
                d="M234.667,171.565a.9.9,0,0,0,.9.9h10.79a.9.9,0,1,0,0-1.8h-10.79A.9.9,0,0,0,234.667,171.565Z"
                transform="translate(-220.28 -156.279)"
              />
              <path
                id="Caminho_76-3"
                data-name="Caminho 76"
                opacity={0.362}
                d="M130.7,82.758h-1.8a.9.9,0,1,0,0,1.8h.9a.9.9,0,1,0,1.8,0v-.166a2.691,2.691,0,0,0-.9-5.229.9.9,0,0,1,0-1.8h1.8a.9.9,0,0,0,0-1.8h-.9a.9.9,0,0,0-1.8,0v.166a2.691,2.691,0,0,0,.9,5.23.9.9,0,0,1,0,1.8Z"
                transform="translate(-122.605 -68.371)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
