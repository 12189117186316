import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { ThemeProvider } from "@material-ui/styles";

import { AnimatePresence } from "framer-motion";
import AntecipaGov from "pages/AntecipaGov";
import CadastroFinanciadorCedente from "pages/CadastroFinaciadorCedente";
import CadastroTags from "pages/CadastroTags";
import CadastroTitulo from "pages/CadastroTitulo";
import CadastroUsers from "pages/CadastroUsers";
import Extrato from "pages/ContaConsignada/Extrato";
import ListagemContas from "pages/ContaConsignada/Listagem";
import HomeDashboard from "pages/HomeDashboard";
import ListagemCadastro from "pages/ListagemCadastro";
import ListagemParcelas from "pages/ListagemParcelas";
import ListagemTitulos from "pages/ListagemTitulos";
import PageError404 from "pages/PagesError404";

import LogsBradesco from "example-pages/AntecipaGov/LogBradesco";

import BradescoPreAprovado from "components/BradescoPreAprovado";

import DialogProvider from "contexts/dialog";

import AccountManagementProvider from "../contexts/accountManager";
import { LeftSidebar } from "../layout-blueprints";
import AccountsManagement from "../pages/AccountManagement";
import { AdminTheme } from "../theme";

const SignRoutes = ({ tema }) => (
  <AnimatePresence>
    <ThemeProvider theme={AdminTheme(tema?.primary, tema?.secondary)}>
      <DialogProvider>
        <BrowserRouter>
          <LeftSidebar>
            <Switch>
              <Route path="/" exact component={HomeDashboard} />
              <Route path="/cotacoes" component={AntecipaGov} />
              <Route path="/capa" component={CadastroFinanciadorCedente} />
              <Route path="/logs" component={LogsBradesco} />
              <Route
                path="/bradesco-pre-aprovados"
                component={BradescoPreAprovado}
              />
              <Route path="/cadastro-whitelabel" component={CadastroUsers} />
              <Route path="/listagem" component={ListagemCadastro} />
              <Route path="/invoices" component={ListagemTitulos} />
              <Route path="/invoice/:invoiceId" component={CadastroTitulo} />
              <Route path="/listagem-parcelas" component={ListagemParcelas} />
              <Route path="/listagem-contas" component={ListagemContas} />
              <Route path="/gestao-contas">
                <AccountManagementProvider>
                  <AccountsManagement />
                </AccountManagementProvider>
              </Route>
              <Route path="/extrato/:accountId" component={Extrato} />
              <Route path="/cadastro-tags" component={CadastroTags} />
              <Route component={PageError404} />
            </Switch>
          </LeftSidebar>
        </BrowserRouter>
      </DialogProvider>
    </ThemeProvider>
  </AnimatePresence>
);

export default SignRoutes;
