/* eslint eqeqeq: 0 */
import React, { useEffect } from 'react';

import Cookies from 'js-cookie';

import CadastroTable from 'components/Table/CadastroTable';

export default function ListagemCadastro() {
  function removeCookie() {
    if (window.location.pathname == '/capa') {
      Cookies.get('businessId');
    } else {
      Cookies.remove('businessId');
    }
  }

  useEffect(() => {
    removeCookie();
  }, []);

  return (
    <>
      <CadastroTable />
    </>
  );
}
