import React from 'react';

import { TextField, MenuItem } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import formatReal from 'utils/formatReal';
import formatTelefone from 'utils/formatTelefone';

export const InvoiceForm = (props) => {
  const {
    title,
    rangeObjects,
    data,
    handler,
    setMethod,
    index,
    isMaturitie,
    disabled,
    format,
  } = props;
  function handlerChange(e, data, key) {
    if (isMaturitie) return handler(e, key, index);
    return handler(e, data, key, setMethod);
  }
  return (
    <div
      className="formContainer"
      style={{ marginBottom: '30px', borderRadius: 8 }}
    >
      {title && (
        <div className="containerTitle">
          <h4 style={{ fontFamily: 'Poppins, sans-serif' }}>{title}</h4>
        </div>
      )}
      {Object.keys(data).length > 0 &&
        rangeObjects.map((obj) => {
          if (obj.isDate) {
            return (
              <div className="container" style={{ marginTop: '-10px' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <KeyboardDatePicker
                    helperText=""
                    cancelLabel="Cancelar"
                    disableToolbar
                    locale={ptBR}
                    onChange={(e) => handler(e, data, obj.key, setMethod)}
                    value={new Date(data[obj.key]) || ''}
                    name={obj.key}
                    variant="outlined"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label={obj.label}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    disabled={disabled}
                  />
                </MuiPickersUtilsProvider>
              </div>
            );
          }

          if (obj.select) {
            return (
              <div className="container">
                <TextField
                  select
                  variant="outlined"
                  label={obj.label}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={(e) => obj.handler(e, data, obj.key, setMethod)}
                  value={data[obj.key.split(';')[1]] || ''}
                  disabled={!obj.isAdmin && disabled}
                >
                  {obj.select.map((item) => (
                    <MenuItem value={item.key}>{item.name}</MenuItem>
                  ))}
                </TextField>
              </div>
            );
          } 
          if (obj.number) {
            return (
              <div className="container">
                <TextField
                  inputProps={{
                    min: "0"
                  }}
                  type ='number'
                  id="outlined-basic"
                  defaultValue={data[obj.key.split(';')[0]]}
                  name={obj.key}
                  onChange={(e) => setMethod({ ...data, maxOffer: e.target.value })}
                  onBlur={(e) => obj.handler (e, data)}
                  label={obj.label}
                  variant="outlined"
                  disabled={!obj.isAdmin && disabled}
                />
              </div>
            );
          }


          if (obj.phone) {
            return (
              <div className="container">
                <TextField
                  id="outlined-basic"
                  defaultValue={data[obj.key]}
                  value={formatTelefone(data[obj.key]) || ''}
                  name={obj.key}
                  onChange={(e) => handlerChange(e, data, obj.key)}
                  // helperText={obj.label}
                  label={obj.label}
                  variant="outlined"
                  disabled={disabled}
                />
              </div>
            );
          }
          if (obj.real) {
            return (
              <div className="container">
                <TextField
                  id="outlined-basic"
                  defaultValue={data[obj.key]}
                  value={formatReal(parseFloat(data[obj.key]).toFixed(2))}
                  name={obj.key}
                  // onChange={(e) => handlerChange(e, data, obj.key)}
                  label={obj.label}
                  variant="outlined"
                  disabled={disabled}
                />
              </div>
            );
          }

          return (
            <div className="container">
              <TextField
                id="outlined-basic"
                defaultValue={data[obj.key]}
                value={
                  format ? format(data[obj.key]) || '' : data[obj.key] || ''
                }
                name={obj.key}
                onChange={(e) => handlerChange(e, data, obj.key)}
                label={obj.label}
                variant="outlined"
                disabled={disabled}
              />
            </div>
          );
        })}
    </div>
  );
};
