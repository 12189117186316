import React, { useEffect, useState } from "react";

import {
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EditIcon from "@material-ui/icons/Edit";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import FolderIcon from "@material-ui/icons/Folder";
import InfoIcon from "@material-ui/icons/Info";
import LabelIcon from "@material-ui/icons/Label";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import SaveIcon from "@material-ui/icons/Save";

import { ConfirmButton } from "components/Button";
import { ReactCatch, ReactIf, ReactThen } from "components/ReactIf";

import formatReal, { removeRealFormat } from "utils/formatReal";
import { removerCaracteresEspeciais } from "utils/removerCaracteresEspeciais";

import Moment from "moment";

import { autoCreateInvoiceService } from "services/agenda";
import { confirmationSwal } from "services/SwalService";

import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";

import { useModal } from "hooks/useModal";
import { useRecebivel } from "hooks/useRecebivel";

import { recebiveisStyle } from "styles/material/recebiveis";

import { statusList } from "./items";
import AxiosInterceptor from "utils/axios-interceptor";

export const AgendaRow = (props) => {
  const {
    agenda,
    aprovarECriarTitulo,
    setOpenEditModal,
    setOpenDetailModal,
    setOpenDocumentsModal,
    setOpenDossieModal,
    setOpenTagsModal,
    cadastroTitulo,
    handleUpdateCommitedValue,
    esconderValorComprometido,
    esconderSacado,
    esconderDataVencimento,
    esconderRisco,
    esconderStatus,
    esconderPorcentagem,
    esconderValorUtlizadoTitulo,
    getAgendas,
    formValues,
    geradorParcelas,
    agendaValue,
  } = props;

  const { isAdmin, isCedente } = useAuth();

  const classes = recebiveisStyle();

  const { openModal, closeModal } = useModal();

  const { toast } = useToast();

  const row = parseAgenda(agenda);

  const {
    canEditScheduling,
    handleStatusChange,
    verifyPermissiontoShowStatusNote,
    statusIdEqualsToWaitingValidation,
    verifyPermissionToEditStatus,
    handleUpdateRisco,

    canApproveAndCreateInvoice,
    canReadDossie,
    canReadDocuments,
    canReadTags,
    canReadStatus,
    canReadAvailableValue,
    canReadPercentAvailableValue,
    canReadBalance,
    canReadMaturitiesValue,
    canReadCommitedValue,
    canReadSacado,
    canReadDataVencimento,
    canReadRisco,

    canReadDetalhes,
    canEditAvailableValuePercent,
    canEditRisco,
    isTitulo,
  } = useRecebivel({ edit: props.edit });

  const [percentage, setPercentage] = useState(row.available_percentage);
  const [percentageError, setPercentageError] = useState(undefined);
  const [commitedValue, setCommitedValue] = useState(row.commited_value);
  const [balance, setBalance] = useState(row.balance);
  const [commitedValueError, setCommitedValueError] = useState(undefined);
  const [statusName, setStatusName] = useState(row.status_name);
  const [note, setNote] = useState(row.note);
  const [risco, setRisco] = useState(row.risk_name);

  const totalLiberado = parseFloat(row.available_value).toFixed(2);

  useEffect(() => {
    setPercentage(row.available_percentage);
    setPercentageError(undefined);
    setCommitedValue(row.commited_value);
    setBalance(row.balance);
    setCommitedValueError(undefined);
    setStatusName(row.status_name);
    setNote(row.note);
    setRisco(row.risk_name);
  }, [row]);

  function parseAgenda(agenda) {
    // Removendo caracteres especiais do status
    agenda.status_name = removerCaracteresEspeciais(agenda.status_name);
    return agenda;
  }

  const handlePercentageChange = (e) => {
    let { value } = e.target;

    value = value.slice(0, 3);

    setPercentage(value);
    setPercentageError("");
  };

  async function autoCreateInvoice() {
    if (Number(percentage) > 100 || Number(percentage) < 0) {
      setPercentageError("Porcentagem deve ser entre 0% e 100%");
    } else {
      try {
        toast.loading("Gerando Título", { id: "auto-create-invoice" });
        await autoCreateInvoiceService(row.agenda_id, percentage, row.user_id);
        toast.success("Título Gerado", { id: "auto-create-invoice" });
        await getAgendas();
      } catch (error) {
        toast.error(error.message, { id: "auto-create-invoice" });
      } finally {
        closeModal();
      }
    }
  }

  const updateAvailableValue = async () => {
    const axiosInstance = AxiosInterceptor();
    if (Number(percentage) > 100 || Number(percentage) < 0) {
      setPercentageError("Porcentagem deve ser entre 0% e 100%");
    } else {
      try {
        await toast.promise(
          axiosInstance.post(
            `${process.env.REACT_APP_NODE_API}/agenda/availableValue`,
            {
              agenda_id: row.agenda_id,
              available_percentage: percentage,
            }
          ),
          {
            loading: "Atualizando %",
            success: "% atualizada",
            error: "Erro atualizando %",
          }
        );
        await getAgendas();
      } catch (error) {
        toast.error(error.message);
      } finally {
        closeModal();
      }
    }
  };

  function handleCommitedValueChange(event) {
    const { agenda_id } = row;
    if (commitedValueError !== undefined) setCommitedValueError(undefined);
    if (parseFloat(removeRealFormat(event.target.value)) <= balance) {
      const value = formatReal(event.target.value);
      setCommitedValue(value);
      handleUpdateCommitedValue(agenda_id, event.target.value, row);
    } else {
      const value = formatReal(event.target.value);
      setCommitedValue(value);
      setCommitedValueError("Saldo indisponível");
    }
  }

  useEffect(() => {
    if (formValues?.addBack) {
      setBalance(balance + formValues.addBack);
    }
  }, [formValues]);

  return (
    <>
      <TableRow key={row.agenda_id}>
        <TableCell align="center">
          <div style={{ display: "flex" }}>
            {canEditScheduling({ statusName }) && (
              <>
                <Tooltip placement="top" title="Editar">
                  <IconButton onClick={() => setOpenEditModal(row.agenda_id)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {canReadDetalhes() && (
              <Tooltip placement="top" title="Detalhes">
                <IconButton onClick={() => setOpenDetailModal(row.agenda_id)}>
                  <FindInPageIcon />
                </IconButton>
              </Tooltip>
            )}
            {canReadDocuments() && (
              <Tooltip placement="top" title="Documentos">
                <IconButton
                  onClick={() => {
                    setOpenDocumentsModal(row.agenda_id);
                  }}
                >
                  <FolderIcon />
                </IconButton>
              </Tooltip>
            )}
            {canReadDossie() && (
              <Tooltip placement="top" title="Dossiê">
                <IconButton
                  onClick={() => {
                    setOpenDossieModal(row.agenda_id);
                  }}
                >
                  <AssignmentIcon />
                </IconButton>
              </Tooltip>
            )}
            {canReadTags() && (
              <Tooltip placement="top" title="Tags">
                <IconButton
                  onClick={() => {
                    setOpenTagsModal(row.agenda_id);
                  }}
                >
                  <LabelIcon />
                </IconButton>
              </Tooltip>
            )}

            {/*  */}
          </div>
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {row.number}
        </TableCell>
        {!esconderSacado && canReadSacado() && (
          <TableCell align="center">{row.drawee?.name ?? ""}</TableCell>
        )}
        {!esconderDataVencimento && canReadDataVencimento() && (
          <TableCell align="center">
            {Moment(row.first_maturity_date).utc(0).format("DD/MM/YYYY")}
          </TableCell>
        )}
        {/* <TableCell align="center">
      {Moment(row.register_date).format('DD/MM/YYYY')}
    </TableCell> */}
        {!esconderStatus && canReadStatus() && (
          <>
            <TableCell align="center">
              <>
                {/* {row.status_name} */}
                <ReactIf condition={isCedente() && row.status_id === 1}>
                  <ReactThen>
                    <div className="status-wrapper">
                      <ConfirmButton
                        className="w-100"
                        onClick={async () => {
                          const { isConfirmed } = await confirmationSwal(
                            "Você deseja continuar?",
                            "Negociar títulos sem lastro, descontar duplicada em duplicidade, fazer conluio com sacados para gerar operações frias e outros comportamentos que caracterizem fraude, são infrações previstas no código penal art. 171, 172, 298 e 299 podendo acarretar em pena de até 4 anos de reclusão mais pagamento de multa e outras penalidades previstas no contrato de uso de serviços da Plataforma Antecipa Fácil. Ressalta-se que podem ser negociadas na Plataforma, exclusivamente notas fiscais que referem-se a produtos ou serviços entregues e aceitos pelo cliente.",
                            true
                          );

                          if (isConfirmed) {
                            handleStatusChange({
                              agendaId: row.agenda_id,
                              value: "Aguardando Validacao",
                              setNote,
                              setStatusName,
                              refreshRecebivelExt: getAgendas,
                            });
                          }
                        }}
                      >
                        Enviar para análise
                      </ConfirmButton>
                    </div>
                  </ReactThen>
                  <ReactCatch>
                    <div className="status-wrapper">
                      <TextField
                        className="text-field-select"
                        id="status_name"
                        name="status_name"
                        label="Status"
                        fullWidth
                        select
                        value={statusName}
                        onChange={async (event) => {
                          const { value } = event.target;
                          await handleStatusChange({
                            agendaId: row.agenda_id,
                            value,
                            setNote,
                            setStatusName,
                            refreshRecebivelExt: getAgendas,
                          });
                        }}
                        variant="outlined"
                        disabled={!verifyPermissionToEditStatus({ statusName })}
                      >
                        <MenuItem disabled value="">
                          Selecione uma opção
                        </MenuItem>
                        {statusList.map((status) => (
                          <MenuItem
                            disabled={
                              !statusIdEqualsToWaitingValidation({
                                statusId: status.id,
                              })
                            }
                            classes={
                              statusIdEqualsToWaitingValidation({
                                statusId: status.id,
                              })
                                ? { root: classes.SELECT }
                                : ""
                            }
                            value={status.value}
                          >
                            {status.label}
                          </MenuItem>
                        ))}
                      </TextField>
                      <ReactIf
                        condition={verifyPermissiontoShowStatusNote({
                          statusName,
                        })}
                      >
                        <ReactThen>
                          <Tooltip
                            title={note}
                            placement="top"
                            style={{ maxWidth: "300px" }}
                          >
                            <InfoIcon />
                          </Tooltip>
                        </ReactThen>
                      </ReactIf>
                    </div>
                  </ReactCatch>
                </ReactIf>
              </>
            </TableCell>
          </>
        )}
        {!esconderRisco && canReadRisco() && (
          <TableCell align="center">
            <TextField
              className="text-field-select"
              id="risk_name"
              name="risk_name"
              label="Risco"
              fullWidth
              select
              value={risco}
              onChange={(event) =>
                handleUpdateRisco({
                  value: event.target.value,
                  agendaId: row.agenda_id,
                  updateState: setRisco,
                })
              }
              variant="outlined"
              disabled={!canEditRisco()}
            >
              <MenuItem
                value=""
                selected
                disabled
                classes={{ root: classes.default }}
              >
                Selecionar
              </MenuItem>
              <MenuItem value="AA" classes={{ root: classes.AA }}>
                AA
              </MenuItem>
              <MenuItem value="AB" classes={{ root: classes.AB }}>
                AB
              </MenuItem>
              <MenuItem value="BA" classes={{ root: classes.BA }}>
                BA
              </MenuItem>
              <MenuItem value="BB" classes={{ root: classes.BB }}>
                BB
              </MenuItem>
              <MenuItem value="CA" classes={{ root: classes.CA }}>
                CA
              </MenuItem>
              <MenuItem value="CB" classes={{ root: classes.CB }}>
                CB
              </MenuItem>
              <MenuItem value="DA" classes={{ root: classes.DA }}>
                DA
              </MenuItem>
              <MenuItem value="INAPTO" classes={{ root: classes.INAPTO }}>
                INAPTO
              </MenuItem>
            </TextField>
          </TableCell>
        )}
        {canReadMaturitiesValue() && (
          <TableCell align="center">
            {formatReal(
              row.maturities_value
                ? parseFloat(row.maturities_value).toFixed(2)
                : row.maturities_value
            )}
          </TableCell>
        )}
        {!esconderPorcentagem && canReadPercentAvailableValue() && (
          <TableCell align="center">
            <FormControl
              className="d-flex flex-row align-items-center jusitfy-content-center"
              style={{ width: "75%" }}
            >
              <TextField
                className="ml-2 mr-2 my-0 text-field percentage-available"
                id="available_percentage"
                name="available_percentage"
                style={{ minWidth: "100px" }}
                value={percentage}
                onChange={handlePercentageChange}
                error={percentageError}
                helperText={percentageError}
                InputProps={{
                  maxLength: 3,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                disabled={
                  !cadastroTitulo ||
                  !canEditAvailableValuePercent({ statusName })
                }
              />

              <ReactIf condition={isAdmin() && cadastroTitulo}>
                <ReactThen>
                  <Tooltip placement="top" title="Salvar porcentagem">
                    <IconButton className="p-0" onClick={updateAvailableValue}>
                      <SaveIcon style={{ width: 20, height: 20 }} />
                    </IconButton>
                  </Tooltip>
                </ReactThen>
              </ReactIf>
              {canApproveAndCreateInvoice({
                statusName,
                tag: aprovarECriarTitulo,
              }) && (
                <Tooltip placement="top" title="Aprovar e Gerar Título">
                  <IconButton
                    onClick={() =>
                      openModal(
                        "warning",
                        "Atenção!",
                        "Tem certeza que deseja aprovar e criar um título automaticamente?",
                        autoCreateInvoice
                      )
                    }
                  >
                    <MonetizationOnIcon />
                  </IconButton>
                </Tooltip>
              )}
            </FormControl>
          </TableCell>
        )}

        {canReadAvailableValue() && (
          <>
            <TableCell align="center">{formatReal(totalLiberado)}</TableCell>
          </>
        )}
        <ReactIf
          condition={!esconderValorComprometido && canReadCommitedValue()}
        >
          <ReactThen>
            <>
              <TableCell align="center">
                <FormControl
                  className="d-flex flex-row align-items-center jusitfy-content-center"
                  style={{ width: "75%" }}
                >
                  <TextField
                    fullWidth
                    className="ml-2 mr-2 my-0 text-field commited_value"
                    id="commited_value"
                    name="commited_value"
                    defaultValue={0}
                    value={
                      cadastroTitulo
                        ? commitedValue || "R$ 0,00"
                        : formatReal(
                            (balance - row.available_value).toFixed(2),
                            true
                          )
                    }
                    onChange={handleCommitedValueChange}
                    error={commitedValueError}
                    helperText={commitedValueError}
                    disabled={!cadastroTitulo}
                  />
                  {cadastroTitulo && !isAdmin() && (
                    <Tooltip placement="top" title="Gerar parcelas">
                      <IconButton
                        className="p-0"
                        onClick={() =>
                          geradorParcelas(
                            row.agenda_id,
                            removeRealFormat(commitedValue)
                          )
                        }
                      >
                        <DateRangeIcon
                          style={{ width: 20, height: 20, marginLeft: "1em" }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </FormControl>
              </TableCell>
            </>
          </ReactThen>
        </ReactIf>
        {canReadBalance() && (
          <TableCell align="center">
            {typeof balance === "number"
              ? formatReal(balance.toFixed(2), true)
              : formatReal(balance, true)}
          </TableCell>
        )}
        <TableCell align="center">{row.maturities}</TableCell>
        {!esconderValorUtlizadoTitulo && isTitulo(agendaValue) && (
          <TableCell align="center">
            {formatReal(parseFloat(agendaValue).toFixed(2))}
          </TableCell>
        )}
      </TableRow>
    </>
  );
};
