import axios from "axios";
import { getCustomHeaders } from "./headers";
import { readCookie } from "./cookie";

const axiosInstance = axios.create();

const AxiosInterceptor = (e) => {
  const userType = readCookie(`UserType_${process.env.REACT_APP_ENV_NODE}`);
  const storagedToken = readCookie("ApplicationToken");

  axiosInstance.interceptors.request.use(
    (config) => {
      const headers = getCustomHeaders(userType);
      config.headers = {
        Authorization: `Bearer ${storagedToken}`,
        ...headers,
        ...config.headers,
      };

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default AxiosInterceptor;
