import { ThemeProvider } from "@material-ui/styles";
import Terms from "components/Terms";
import DialogProvider from "contexts/dialog";
import ShowcaseProvider from "contexts/showcase.context";
import { AnimatePresence } from "framer-motion";
import CadastroFinanciadorCedente from "pages/CadastroFinaciadorCedente";
import CadastroTitulo from "pages/CadastroTitulo";
import HomeDashboard from "pages/HomeDashboard";
import Leilao from "pages/Leilao";
import ListagemTitulos from "pages/ListagemTitulos";
import ParcelasFinanciador from "pages/ParcelasFinanciador";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { LeftSidebar } from "../layout-blueprints";
import PageError404 from "../pages/PagesError404";
import DadosCadastrais from "pages/DadosCadastrais";
import { BuyerTheme } from "../theme";

const SignRoutes = ({ tema }) => (
  <AnimatePresence>
    <ThemeProvider theme={BuyerTheme(tema?.primary, tema?.secondary)}>
      <DialogProvider>
        <BrowserRouter>
          <Terms />
          <LeftSidebar>
            <Switch>
              <Route path="/" exact component={HomeDashboard} />
              <Redirect from="/capa" to="/" />
              <Route path="/cadastro" component={CadastroFinanciadorCedente} />
              <Route path="/invoices" component={ListagemTitulos} />
              <Route path="/invoice/:invoiceId" component={CadastroTitulo} />
              <Route path="/vitrine">
                <ShowcaseProvider>
                  <Leilao />
                </ShowcaseProvider>
              </Route>
              <Route path="/vitrine/oferta/:id">
                <ShowcaseProvider>
                  <Leilao />
                </ShowcaseProvider>
              </Route>
              <Route path="/parcelas-fin" component={ParcelasFinanciador} />
              <Route component={PageError404} />
            </Switch>
          </LeftSidebar>
        </BrowserRouter>
      </DialogProvider>
    </ThemeProvider>
  </AnimatePresence>
);

export default SignRoutes;
