import { useEffect, useState } from "react";

import { saveTagsAgendaService } from "services/agenda";

import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";
import AxiosInterceptor from "utils/axios-interceptor";

// TIPOS DE RECEBÍVEIS
//   { AntecipaGov: 4 }
//   { ContaGarantida: 5 }
//   { Precatorio: 3 }
//   { Produtos: 2 }
//   { Servicos: 1 }

const tiposRecebiveis = [2, 5, 4, 1, 3];

export function useRecebiveis(typeId, propsBusinessId, listaIdRecebiveis) {
  const { businessId: sessionBusinessId } = JSON.parse(
    localStorage.getItem("@App:user")
  );

  const businessId = propsBusinessId || sessionBusinessId;
  const { toast } = useToast();
  const { isCedente } = useAuth();
  const [agendas, setAgendas] = useState(null);
  const [agendaDocs, setAgendaDocs] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("row.number");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [openCadastroTitulo, setOpenCadastroTitulo] = useState(false);
  const [noAgendaMessage, setNoAgendaMessage] = useState(undefined);

  async function getAgendas() {
    const axiosInstance = AxiosInterceptor();
    try {
      setIsLoading(true);

      const { data } =
        await axiosInstance.get(`${process.env.REACT_APP_NODE_API}/agenda/filter/${businessId}
      `);

      let parsedAgendas = data.map((agenda) => ({
        ...agenda,
        balance: agenda.balance.toFixed(2),
        invoice_value: agenda.invoice_value.toFixed(2),
        maturities_value: agenda.maturities_value.toFixed(2),
        available_value: agenda.available_value.toFixed(2),
      }));

      if (listaIdRecebiveis)
        parsedAgendas = parsedAgendas.filter((agenda) =>
          listaIdRecebiveis.some((lir) => lir == agenda.agenda_id)
        );

      setAgendas(parsedAgendas);
      if (parsedAgendas.length === 0)
        setNoAgendaMessage("Não há nenhuma nota fiscal cadastrada.");
      setIsLoading(false);
    } catch (error) {
      setAgendas([]);
      setNoAgendaMessage("Não há nenhuma nota fiscal cadastrada.");
    }
  }

  async function saveTagsAgenda(agendaId, tagId) {
    await saveTagsAgendaService(agendaId, tagId);
  }

  async function getAgenda(businessId) {
    const axiosInstance = AxiosInterceptor();
    try {
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_NODE_API}/agenda/${businessId}`
      );

      return data;
    } catch (error) {
      return [];
    }
  }

  async function getAgendasFiltered(typeId) {
    const axiosInstance = AxiosInterceptor();
    try {
      setIsLoading(true);

      const path = isCedente() ? "filterbalance" : "filter";
      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_NODE_API}/agenda/${path}/${businessId}/${typeId}`
      );

      setAgendas(data);
      setIsLoading(false);
    } catch (error) {
      setAgendas([]);
    }
  }

  async function getDocuments(agendaId) {
    const axiosInstance = AxiosInterceptor();
    try {
      // setIsLoading(true);

      const { data } = await axiosInstance.get(
        `${process.env.REACT_APP_NODE_API}/agenda/documents/agendaId/${agendaId}`
      );

      setAgendaDocs(data);
      // setIsLoading(false);
    } catch (error) {
      setAgendaDocs([]);
    }
  }

  async function handleLoadAgenda(typeId) {
    const isValidTipo = validateTypeId();

    if (!isValidTipo) {
      await toast.promise(getAgendas(), {
        loading: "Carregando recebíveis",
        success: "Recebíveis carregados",
        error: "Erro ao buscar recebíveis disponíveis",
      });
    }
    if (isValidTipo) {
      await toast.promise(getAgendasFiltered(typeId), {
        loading: "Carregando recebíveis",
        success: "Recebíveis carregados",
        error: "Erro ao buscar recebíveis",
      });
    }
  }

  useEffect(() => {
    if (!typeId) return;
    handleLoadAgenda(typeId);
  }, [typeId]);

  function validateTypeId() {
    return tiposRecebiveis.some((tipo) => tipo === +typeId);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const labelDisplayedRows = ({ from, to, count }) =>
    `${from}-${to} de ${count}`;

  return {
    agendas,
    getAgenda,
    getAgendas,
    agendaDocs,
    getDocuments,
    saveTagsAgenda,
    stableSort,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    getComparator,
    handleChangeRowsPerPage,
    handleChangePage,
    labelDisplayedRows,
    isLoading,
    refresh: handleLoadAgenda,
    openCadastroTitulo,
    setOpenCadastroTitulo,
    setAgendaDocs,
    noAgendaMessage,
  };
}
