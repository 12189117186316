import React from 'react';

import { Box, Divider, Grid, Checkbox, Button } from '@material-ui/core';

import { Maximize, Download } from 'components/Icons';

import { openDocumentWithUrlUtil } from 'utils/openDocuments';

export const DossieDocumentItem = ({
  title,
  checkOne,
  dossieItensFiltred,
  selectedDocs,
}) => (
  <Grid item xs={12}>
    <Box
      sx={{
        border: '1px solid #EBEBEB',
        borderRadius: '10px',
        background: 'white',
      }}
    >
      <Box alignItems="center" sx={{ padding: '20px' }}>
        <b> {title} </b>{' '}
      </Box>
      <Divider />
      {dossieItensFiltred?.map((item) => (
        <Box
          alignItems="center"
          key={item.fileId}
          sx={{
            padding: '10px',
            backgroundColor:
              selectedDocs?.indexOf(item.fileId) > -1 ? '#F5FFF6' : null,
          }}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1}>
              <Box>
                <Checkbox
                  id={item.fileId}
                  onChange={checkOne}
                  color="#0e8018"
                  checked={selectedDocs?.indexOf(item.fileId) > -1}
                  iconStyle={{ fill: '#0e8018' }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box alignContent="center" alignText="left">
                <b> {item?.description} </b>
                <p
                  style={{
                    color: '#797979',
                    alignSelf: 'center',
                    textOverflow: 'clip',
                  }}
                >
                  {item?.name}
                </p>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <Box>
                  <Button
                    onClick={() => openDocumentWithUrlUtil(item.url)}
                    variant="outlined"
                    startIcon={<Maximize size={20} />}
                  >
                    <b> Abrir </b>
                  </Button>
                </Box>
                <Box sx={{ m: 0.5 }} />
                <Box>
                  <Button
                    onClick={() => window.open(item.downloadUrl, 'blank')}
                    target="_blank"
                    variant="outlined"
                    startIcon={<Download size={20} />}
                  >
                    <b> Baixar </b>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  </Grid>
);
