import React, { useState } from 'react';

import { Card, TextField } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { ConfirmButton } from 'components/Button';
import { ReactCatch, ReactIf, ReactThen } from 'components/ReactIf';

import formatCnpj from 'utils/formatCNPJ';
import formatTelefone from 'utils/formatTelefone';

import { useClientesCedente } from 'hooks/useClientesCedente';

const ClientesCedente = (props) => {
  const {
    business,
    businessCustomers,
    setBusinessCustomers,
    businessSuppliers,
    setBusinessSuppliers,
    setSnackbarState,
  } = props;

  const {
    updateCustomers,
    updateSuppliers,
    handlerChange,
    addCustomer,
    removeCustomer,
    addSupplier,
    removeSupplier,
    stringAttributeIsInvalid,
    canSelfUpdate,
    isAdmin,
  } = useClientesCedente({
    business,
    businessCustomers,
    setBusinessCustomers,
    businessSuppliers,
    setBusinessSuppliers,
    setSnackbarState,
  });

  const StylizedInput = ({
    className,
    label,
    inputName,
    disabled,
    required,
    customer,
    setter,
    index,
    supplier,
    andError,
    format,
    ...props
  }) => (
    <div className={`w-100 mx-1 ${className}`}>
      <TextField
        fullWidth
        className=""
        id={`${label}${supplier ? 'S' : 'C'}${index}`}
        label={`${label}${required ? '*' : ''}`}
        name={inputName}
        value={
          format
            ? format(customer[inputName])
            : customer[inputName] === null
            ? ''
            : customer[inputName]
        }
        disabled={disabled || !canSelfUpdate(business.statusId)}
        error={
          (andError !== undefined ? andError : true) &&
          stringAttributeIsInvalid(customer[inputName]) &&
          required
        }
        variant="outlined"
        onChange={(e) => {
          handlerChange(e, customer, setter, index, supplier);
        }}
        {...props}
      />
    </div>
  );

  const ClientesComponent = (props) => {
    const { index, customerItem } = props;

    const [customer, setCustomer] = useState(customerItem);

    return (
      <div className="pl-4 pr-4 pt-2">
        {index === 0 && (
          <div className="d-flex flex-row justify-content-between w-100 p-2 mb-3">
            <h3
              style={{
                fontFamily: 'Poppins, sans-serif',
              }}
            >
              Principais Clientes
            </h3>
            <ReactIf condition={canSelfUpdate(business.statusId)}>
              <ReactThen>
                <AddCircleOutlineIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => addCustomer()}
                />
              </ReactThen>
              <ReactCatch>
                <div />
              </ReactCatch>
            </ReactIf>
          </div>
        )}
        <div className="d-flex flex-row justify-content-between align-items-center w-100 pb-2 responsible">
          <StylizedInput
            label="Nome do Contato"
            inputName="contactName"
            customer={customer}
            setter={setCustomer}
            index={index}
          />
          <StylizedInput
            label="Telefone"
            inputName="officePhone"
            customer={customer}
            setter={setCustomer}
            index={index}
            format={formatTelefone}
            inputProps={{ maxLength: 14 }}
          />

          <StylizedInput
            label="E-mail"
            inputName="email"
            customer={customer}
            setter={setCustomer}
            index={index}
          />

          <StylizedInput
            label="Nome da Empresa"
            inputName="name"
            customer={customer}
            setter={setCustomer}
            index={index}
          />

          <StylizedInput
            label="CNPJ"
            inputName="countryIdentity"
            required
            customer={customer}
            setter={setCustomer}
            index={index}
            format={formatCnpj}
            inputProps={{ maxLength: 18 }}
          />
          <ReactIf condition={canSelfUpdate(business.statusId)}>
            <ReactThen>
              <CloseIcon
                style={{ cursor: 'pointer' }}
                className="mr-2"
                onClick={() => removeCustomer(index)}
              />
            </ReactThen>
            <ReactCatch>
              <div />
            </ReactCatch>
          </ReactIf>
        </div>
      </div>
    );
  };

  const FornecedoresComponent = (props) => {
    const { index, supplierItem } = props;

    const [supplier, setSupplier] = useState(supplierItem);

    return (
      <div className="pl-4 pr-4 pt-2">
        {index === 0 && (
          <div className="d-flex flex-row justify-content-between w-100 p-2 mb-3">
            <h3
              style={{
                fontFamily: 'Poppins, sans-serif',
              }}
            >
              Principais Fornecedores
            </h3>
            <ReactIf condition={canSelfUpdate(business.statusId)}>
              <ReactThen>
                <AddCircleOutlineIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => addSupplier()}
                />
              </ReactThen>
              <ReactCatch>
                <div />
              </ReactCatch>
            </ReactIf>
          </div>
        )}
        <div className="d-flex flex-row justify-content-between align-items-center w-100 pb-3 responsible">
          <StylizedInput
            label="Nome do Contato"
            inputName="contactName"
            supplier
            customer={supplier}
            setter={setSupplier}
            index={index}
          />
          <StylizedInput
            label="Telefone"
            inputName="officePhone"
            supplier
            customer={supplier}
            setter={setSupplier}
            index={index}
            format={formatTelefone}
            inputProps={{ maxLength: 14 }}
          />

          <StylizedInput
            label="E-mail"
            inputName="email"
            supplier
            customer={supplier}
            setter={setSupplier}
            index={index}
          />

          <StylizedInput
            label="Nome da Empresa"
            inputName="name"
            supplier
            customer={supplier}
            setter={setSupplier}
            index={index}
          />

          <StylizedInput
            label="CNPJ"
            inputName="countryIdentity"
            supplier
            customer={supplier}
            setter={setSupplier}
            index={index}
            format={formatCnpj}
            inputProps={{ maxLength: 18 }}
          />
          <ReactIf condition={canSelfUpdate(business.statusId)}>
            <ReactThen>
              <CloseIcon
                style={{ cursor: 'pointer' }}
                className="mr-2"
                onClick={() => removeSupplier(index)}
              />
            </ReactThen>
            <ReactCatch>
              <div />
            </ReactCatch>
          </ReactIf>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <ReactIf
          condition={
            business && !isAdmin() && canSelfUpdate(business?.statusId)
          }
        >
          <ReactThen>
            <Card
              className="card-box p-4 mb-4"
              style={{ width: '95%', maxWidth: '95%', margin: '0 auto' }}
            >
              <div className="text-center">
                <div className="avatar-icon-wrapper rounded-circle m-0">
                  <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper text-success m-0 d-65 align-items-center">
                    <InfoOutlinedIcon
                      style={{
                        color: '#2196f3',
                        fontSize: 28,
                      }}
                    />
                  </div>
                </div>
                <h4
                  className="font-weight-bold"
                  style={{
                    fontFamily: 'Poppins',
                    fontSize: '26px',
                    textAlign: 'center',
                    color: '#663c00',
                  }}
                >
                  Preencha as informações sobre os seus maiores clientes.
                </h4>
                <span>
                  Aqui você deve preencher com os principais clientes que
                  desejar antecipar recebimentos. Lembrando que a veracidade das
                  informações garantirá a agilidade no seu cadastro e na
                  antecipação das notas fiscais.
                </span>
              </div>
            </Card>
          </ReactThen>
        </ReactIf>
        <div className="searchForm" style={{ marginBottom: '15px' }}>
          <div className="w-100 mb-5 relacionados">
            <ReactIf
              condition={
                businessCustomers.length === 0 &&
                canSelfUpdate(business.statusId)
              }
            >
              <ReactThen>
                <div className="d-flex flew-row align-items-center justify-content-center w-100">
                  <span color="primary">
                    Nenhum cliente encontrado. Clique no botão ao lado para
                    adicionar o primeiro!
                  </span>
                  <AddCircleOutlineIcon
                    color="primary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => addCustomer()}
                  />
                </div>
              </ReactThen>
              <ReactCatch>
                {businessCustomers.map((customer, index) => (
                  <div key={customer.customerId ?? index}>
                    <ClientesComponent
                      customerItem={customer}
                      isLast={index === businessCustomers.length - 1}
                      index={index}
                    />
                  </div>
                ))}
              </ReactCatch>
            </ReactIf>
            <div className="btnContainer pr-4">
              <ReactIf condition={canSelfUpdate(business.statusId)}>
                <ReactThen>
                  <ConfirmButton
                    onClick={() => updateCustomers(businessCustomers)}
                  >
                    Salvar
                  </ConfirmButton>
                </ReactThen>
              </ReactIf>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="searchForm">
          <div className="w-100 mb-5 relacionados">
            <ReactIf
              condition={
                businessSuppliers.length === 0 &&
                canSelfUpdate(business.statusId)
              }
            >
              <ReactThen>
                <div className="d-flex flew-row align-items-center justify-content-center w-100">
                  <span color="primary">
                    Nenhum fornecedor encontrado. Clique no botão ao lado para
                    adicionar o primeiro!
                  </span>
                  <AddCircleOutlineIcon
                    color="primary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => addSupplier()}
                  />
                </div>
              </ReactThen>
              <ReactCatch>
                {businessSuppliers.map((supplier, index) => (
                  <div key={supplier.supplierId ?? index}>
                    <FornecedoresComponent
                      supplierItem={supplier}
                      isLast={index === businessSuppliers.length - 1}
                      index={index}
                    />
                    {/* {index !== businessSuppliers.length - 1 && <div className="separation-bar" ></div>} */}
                  </div>
                ))}
              </ReactCatch>
            </ReactIf>

            <div className="btnContainer pr-4">
              <ReactIf condition={canSelfUpdate(business.statusId)}>
                <ReactThen>
                  <ConfirmButton onClick={() => updateSuppliers()}>
                    Salvar
                  </ConfirmButton>
                </ReactThen>
              </ReactIf>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientesCedente;
