import React, { createContext, useContext, useEffect, useState } from "react";
import { getRedirectUrl, parseTheme } from "utils/origin";
import { handleThemeAppTab } from "utils/setAppTab";
import { timerPromiseSeconds } from "utils/timer";
import axios from "axios";
import { useToast } from "contexts/toast";
import * as api from "../services/api";
import { alertMessageSwal } from "../services/SwalService";
import { useAuthToken } from "hooks/useAuth";
import AxiosInterceptor from "utils/axios-interceptor";
import { useUserType } from "utils/useUserType";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { loading } = useAuthToken();
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [timeoutLogin, setTimeoutLogin] = useState(false);

  const { toast } = useToast();
  const users = useUserType();

  useEffect(() => {
    const storagedUser = localStorage.getItem("@App:user");
    const storagedToken = localStorage.getItem("@App:token");

    if (storagedToken && storagedUser) {
      setUser(JSON.parse(storagedUser));
      api.defaults.headers.Authorization = `${storagedToken}`;
    }

    handleThemeAppTab();
  }, [loading]);

  async function Login(userData) {
    if (timeoutLogin) {
      toast("Aguarde um momento.");
      return;
    }
    setTimeoutLogin(true);
    // truque para forçar renderização
    await timerPromiseSeconds(0.3);

    try {
      const response = await toast.promise(api.post(userData), {
        loading: "Efetuando login...",
        success: (response) => `Seja bem vindo! ${response.data.user.name}`,
        error: `Login ou senha inválidos!`,
      });

      if (
        response.data.user.businessId === "00000000-0000-0000-0000-000000000000"
      ) {
        alertMessageSwal(
          "Cadastro incompleto",
          "Parece que você ainda não finalizou seu cadastro"
        );
        setTimeoutLogin(false);
      } else {
        setTimeoutLogin(false);
        setSessionData(response);

        return { user: response.data.user, session: response.data.session };
      }
    } catch (error) {
      setTimeoutLogin(false);
    }
  }

  async function setSessionData(response, ghost = false) {
    if (ghost) {
      response = await setGhost(response);
    }

    axios.defaults.headers.Authorization = `Bearer ${response.data.token}`;
    api.defaults.headers.Authorization = `${response.data.token}`;

    const serializedTheme = {
      shortOrigin:
        response.data.user.origin.ORIGIN_TYPE_SHORT ||
        response.data.user.origin.originTypeShort,
      longOrigin:
        response.data.user.origin.ORIGIN_TYPE_NAME ||
        response.data.user.origin.originTypeName,
      logoLogin:
        response.data.user.origin.LOGO_LOGIN ||
        response.data.user.origin.logoLogin,
      logoTopo:
        response.data.user.origin.LOGO_TOPO ||
        response.data.user.origin.logoTopo,
      favIcon:
        response.data.user.origin.FAV_ICON || response.data.user.origin.favIcon,
      tema: parseTheme({
        tema: response.data.user.origin.TEMA || response.data.user.origin.tema,
      }).tema,
    };

    const apiTheme = serializedTheme;

    localStorage.setItem(
      "@App:user",
      JSON.stringify({
        ...response.data.user,
        tema: apiTheme,
      })
    );
    localStorage.setItem("@App:token", response.data.token);

    localStorage.setItem("bearer", response.data.session.token);
    localStorage.setItem("businessId", response.data.user.businessId);
    localStorage.setItem("userTypeId", response.data.user.userTypeId);

    handleThemeAppTab();

    setToken(response.data.session.token);
    setUser({
      ...response.data.user,
      tema: apiTheme,
    });
  }

  async function setGhost(data) {
    let userCompany = {
      name: data.origin.originTypeName,
      fullName: data.origin.originTypeName,
    };
    if (data.session.userTypeId != 3) {
      const userCompanyApi = await getUserCompanyService(data.business_id);

      if (userCompanyApi.name) {
        userCompany = {
          ...userCompanyApi,
        };
      }
    }

    return {
      data: {
        token: data.access_token,
        user: {
          name: data.name,
          email: data.email,
          userTypeId: data.session.userTypeId,
          businessId: data.business_id,
          originType: data.origin_type,
          origin: data.origin,
          company: userCompany,
          userId: data.user_id,
          status_cadastro: data.status_cadastro,
        },
        session: {
          token: data.session.token,
        },
      },
    };
  }

  async function getUserCompanyService(businessId) {
    const axiosInstance = AxiosInterceptor();
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_NODE_API}/users/company/${businessId}`
    );

    // data.name && data.fullName
    return data;
  }

  async function Logout() {
    localStorage.removeItem("@App:user");
    localStorage.removeItem("@App:token");
    localStorage.removeItem("bearer");
    const domain = window.location.hostname.replace(/^[^.]+\./, ".");
    document.cookie =
      "ApplicationTokenGraphql=; Max-Age=-99999999; domain=" +
      domain +
      "; path=/";
    document.cookie =
      "ApplicationToken=; Max-Age=-99999999; domain=" + domain + "; path=/";

    window.location.replace(getRedirectUrl());
  }

  function nomeEmpresa() {
    if (!localStorage.getItem("@App:user")) return "";
    const { origin, company } = JSON.parse(localStorage.getItem("@App:user"));

    if (isAdmin()) {
      return origin?.originTypeName;
    }

    return company?.name;
  }

  function isAdmin() {
    const { userTypeId } = JSON.parse(localStorage.getItem("@App:user"));

    return userTypeId === 3;
  }

  function isFinanciador() {
    const { userTypeId } = JSON.parse(localStorage.getItem("@App:user"));

    return userTypeId === 2;
  }

  function isCedente() {
    const { userTypeId } = JSON.parse(localStorage.getItem("@App:user"));

    return userTypeId == 1;
  }

  function cadastroAprovado() {
    if (!localStorage.getItem("@App:user")) return false;
    const { status_cadastro } = JSON.parse(localStorage.getItem("@App:user"));

    return status_cadastro == 4;
  }

  function canSelfUpdate(businessStatusId, isDocsPage) {
    const { userTypeId } = JSON.parse(localStorage.getItem("@App:user"));

    const statusIsIncluded = isDocsPage
      ? [1, 3].includes(businessStatusId)
      : businessStatusId === 1;

    return (userTypeId != 3 && statusIsIncluded) || userTypeId === 3;
  }

  function isFIF() {
    const { originType } = JSON.parse(localStorage.getItem("@App:user"));

    return originType.localeCompare("Federal_Invest") === 0;
  }

  function isAF() {
    if (!localStorage.getItem("@App:user")) return "";
    const isAF = userOrigin() == "AF";

    return isAF;
  }

  function userOrigin() {
    if (!localStorage.getItem("@App:user")) return "";
    const { originType } = JSON.parse(localStorage.getItem("@App:user"));

    return originType;
  }

  function isWhiteLabel() {
    if (!localStorage.getItem("@App:user")) return "";
    const isWhiteLabel = userOrigin() != "AF";

    return isWhiteLabel;
  }

  return (
    <AuthContext.Provider
      value={{
        signed: Boolean(user),
        user,
        Login,
        Logout,
        isAdmin,
        isCedente,
        canSelfUpdate,
        isFinanciador,
        isFIF,
        isAF,
        isWhiteLabel: isWhiteLabel(),
        cadastroAprovado: cadastroAprovado(),
        userOrigin: userOrigin(),
        nomeEmpresa: nomeEmpresa(),
        token,
        setSessionData,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
