import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/styles";

import clsx from "clsx";
import PapersIcon from "pages/Leilao/icons/Papers";
import YoutubeIcon from "pages/Leilao/icons/Youtube";
import { getMainColorLight } from "pages/Leilao/Search";

import DiscountVoucherIcon from "components/SideMenu/icons/DiscountVoucher";

import axios from "axios";

import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";

import Documentos from "./Documentos";
import Reputacao from "./Reputacao";
import Videos from "./Videos";

import "./styles.scss";
import AxiosInterceptor from "utils/axios-interceptor";

export default function Cedente({
  activeComponent: activeComponentProps,
  titulo,
}) {
  const [activeComponent, setActiveComponent] = useState({
    data: {},
    name: activeComponentProps ?? "reputacao",
  });
  const theme = useTheme();
  const { isAdmin, isFinanciador } = useAuth();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [seller, setSeller] = useState(undefined);
  const [sellerDocuments, setSellerDocuments] = useState(undefined);
  const [sellerPending, setSellerPending] = useState(undefined);
  const { toast } = useToast();
  const axiosInstance = AxiosInterceptor();

  async function loadTitulos() {
    try {
      const dataPromises = [getSeller()];

      await toast.promise(Promise.all(dataPromises), {
        loading: "Carregando dados",
        success: "Dados carregados",
        error: "Erro carregando dados",
      });
    } catch (error) {
      toast.error("Erro carregando dados");
    }
  }

  const getSeller = () => {
    axiosInstance
      .get(
        `${process.env.REACT_APP_NODE_API}/business/find/full/${titulo.business.businessId}`
      )
      .then((res) => {
        setSeller(res.data.business);
        setSellerDocuments(res.data.documents);
        setSellerPending(res.data.pendings);
        setDataLoaded(true);
      });
  };

  const hasInvoiceViewPermission = () => {
    if (isAdmin() || isFinanciador()) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (hasInvoiceViewPermission()) {
      loadTitulos();
    }
  }, []);

  function getComponent() {
    switch (activeComponent.name) {
      case "documentos": {
        return (
          <div>
            {dataLoaded && (
              <Documentos
                seller={seller}
                sellerDocuments={sellerDocuments}
                sellerPending={sellerPending}
                setSeller={setSeller}
                setSellerDocuments={setSellerDocuments}
                setSellerPending={setSellerPending}
              />
            )}
          </div>
        );
      }

      case "videos": {
        return (
          <div>
            {dataLoaded && (
              <Videos
                seller={seller}
                sellerDocuments={sellerDocuments}
                sellerPending={sellerPending}
                setSeller={setSeller}
                setSellerDocuments={setSellerDocuments}
                setSellerPending={setSellerPending}
              />
            )}
          </div>
        );
      }

      case "reputacao": {
        return (
          <div>
            <Reputacao seller={seller} />
          </div>
        );
      }

      default:
        return null;
    }
  }

  const mainColorLight = getMainColorLight(theme.palette.primary.main);

  return (
    <div className="cedente--container">
      <div className="cedente--left">
        <div className="cedente--left--buttons">
          <Button
            classes={{
              root: clsx(
                "cedente--button",
                activeComponent.name === "reputacao" &&
                  "cedente--button--active"
              ),
            }}
            onClick={() => setActiveComponent({ name: "reputacao", data: {} })}
            startIcon={<DiscountVoucherIcon color="#666666" size={34} />}
            style={{
              backgroundColor:
                activeComponent.name === "reputacao" && mainColorLight,
              boxShadow:
                activeComponent.name === "reputacao" &&
                `0 0 0 2px ${theme.palette.primary.main}`,
            }}
          >
            Reputação
          </Button>

          <Button
            classes={{
              root: clsx(
                "cedente--button",
                activeComponent.name === "videos" && "cedente--button--active"
              ),
            }}
            onClick={() => setActiveComponent({ name: "videos", data: {} })}
            startIcon={<YoutubeIcon color="#666666" size={34} />}
            style={{
              backgroundColor:
                activeComponent.name === "videos" && mainColorLight,
              boxShadow:
                activeComponent.name === "videos" &&
                `0 0 0 2px ${theme.palette.primary.main}`,
            }}
          >
            Vídeos
          </Button>

          <Button
            classes={{
              root: clsx(
                "cedente--button",
                activeComponent.name === "documentos" &&
                  "cedente--button--active"
              ),
            }}
            onClick={() => setActiveComponent({ name: "documentos", data: {} })}
            startIcon={<PapersIcon color="#666666" size={26} />}
            style={{
              backgroundColor:
                activeComponent.name === "documentos" && mainColorLight,
              boxShadow:
                activeComponent.name === "documentos" &&
                `0 0 0 2px ${theme.palette.primary.main}`,
            }}
          >
            Documentos
          </Button>
        </div>
      </div>

      <div className="cedente--right">{getComponent()}</div>
    </div>
  );
}
