import React from 'react';

export default function Maximize({ color = '#A0A0A0', size = 40 }) {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.697 24.697"
    >
      <g id="maximize_2_" data-name="maximize (2)" opacity="0.999">
        <path
          id="Caminho_78"
          data-name="Caminho 78"
          d="M22.768,21.8a.966.966,0,0,1-.965.965H2.894a.966.966,0,0,1-.965-.965V2.894a.966.966,0,0,1,.965-.965h8.49V0H2.894A2.9,2.9,0,0,0,0,2.894V21.8A2.9,2.9,0,0,0,2.894,24.7H21.8A2.9,2.9,0,0,0,24.7,21.8v-8.49H22.768Z"
        />
        <path
          id="Caminho_79"
          data-name="Caminho 79"
          d="M131.03,0V1.929h6.353L121.858,17.455l1.364,1.364L138.748,3.294V9.647h1.929V0Z"
          transform="translate(-115.98)"
        />
      </g>
    </svg>
  );
}
