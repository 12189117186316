export const headCells = [
  {
    id: 'invoiceIdNumerico',
    label: 'ID',
    minWidth: 50,
    backgroundColor: '#fafafa',
  },
  {
    id: 'number',
    label: 'Nota Fiscal',
    minWidth: 130,
    maxWidth: 130,
    backgroundColor: '#fafafa',
  },
  {
    id: 'sellerName',
    label: 'Cedente',
    minWidth: 200,
    maxWidth: 216,
    backgroundColor: '#fafafa',
  },
  {
    id: 'draweeName',
    label: 'Sacado',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'busubessName',
    label: 'Financiador',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'maturitiesValue',
    label: 'Vencimentos',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'bidNetValue',
    label: 'Valor Líquido',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'bidNetFactor',
    label: 'CET% AM',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'statusName',
    label: 'Status',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
];

export const status = [
  { statusId: 1, statusName: 'Cadastro Pendente' },
  { statusId: 3, statusName: 'Aguardando Ação do Usuário' },
  { statusId: 4, statusName: 'Aguardando Lance' },
  { statusId: 6, statusName: 'Negado' },
  { statusId: 7, statusName: 'Cancelado' },
  { statusId: 9, statusName: 'Aceitar lance' },
  { statusId: 8, statusName: 'Vendido' },
  { statusId: 11, statusName: 'Título pago' },
  { statusId: 12, statusName: 'Proposta em Criação' },
];

export const statusFinanciador = [
  { statusId: 8, statusName: 'Vendido' },
  { statusId: 11, statusName: 'Título pago' },
  { statusId: 13, statusName: 'Prorrogado' },
];

// export const statusMaturities = [
//   { statusMaturityId: 1, statusMaturityName: 'Liquidado' },
//   { statusMaturityId: 2, statusMaturityName: 'Em atraso' },
//   { statusMaturityId: 3, statusMaturityName: 'Em dia' },
//   { statusMaturityId: 4, statusMaturityName: 'Prorrogado' },
// ];

export const risks = [
  { riskId: 0, riskName: 'Não Definido' },
  { riskId: 1, riskName: 'AA' },
  { riskId: 2, riskName: 'AB' },
  { riskId: 3, riskName: 'BA' },
  { riskId: 4, riskName: 'BB' },
  { riskId: 5, riskName: 'CA' },
  { riskId: 6, riskName: 'CB' },
  { riskId: 7, riskName: 'DA' },
  { riskId: 8, riskName: 'INAPTO' },
];

export const marketTypes = [
  { typeId: 1, typeName: 'Leilão' },
  { typeId: 2, typeName: 'Balcão' },
  // { typeId: 4, typeName: 'Balcão FIF' },
];
