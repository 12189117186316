import React from "react";
import styled from "styled-components";
import { getRedirectUrl } from "utils/origin";

const DadosCadastrais = ({ className }) => {
  return (
    <iframe
      className={className}
      src={`${getRedirectUrl()}/register-company`}
      scrolling="yes"
      height="800px"
      width="100%"
      frameBorder="0"
    />
  );
};

export default styled(DadosCadastrais)`
  margin: -55px 0 0 -70px;
  width: calc(100vw - 60px);
  height: calc(100vh - 50px);
`;
