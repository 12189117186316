import { makeStyles } from '@material-ui/core';

export const ButtonStyle = makeStyles({
  default: {
    color: 'white',
    fontWeight: 600,
  },
  Confirm: {
    boxShadow: '0 12px 10px rgba(0, 0, 0, 0.1)',
    border: 0,
    color: 'white',
    fontWeight: 600,
  },
  Cancel: {
    boxShadow: '0 12px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FFF',
    border: 0,
    color: 'black',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: '#EEE',
    },
  },
  Info: {
    boxShadow: '0 12px 10px rgba(0, 0, 0, 0.1)',
    border: 0,
    backgroundColor: '#0d3c61',
    color: 'white',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#072d47',
    },
  },
});
