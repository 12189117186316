/* eslint eqeqeq: 0 */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Card, Divider, Grid, MenuItem, TextField } from '@material-ui/core';

import { AutoCompleteSelect } from 'components/AutoCompleteSelect';
import { ConfirmButton } from 'components/Button';

import {
  getInvoiceBuyers,
  getMarket,
  updateInvoiceBuyers,
  updateMarketType,
} from 'services/invoice';
import { GetBuyers } from 'services/tipo-mercado-services';

import { useAuth } from 'contexts/auth';
import { useToast } from 'contexts/toast';

const tiposMercado = [
  {
    value: 1,
    label: 'Leilão',
  },
  {
    value: 2,
    label: 'Balcão',
  },
  {
    value: 3,
    label: 'Exclusão',
  },
  // {
  //   value: 4,
  //   label: 'Balcão FIF',
  // },
];

const TipoMercado = () => {
  const [tipoMercado, setTipoMercado] = useState({
    // name: 'Leilão',
    // id: 1,
    name: '',
    id: null,
  });

  const [isLoading, setIsLoading] = useState(false);

  const [buyers, setBuyers] = useState([]);
  const [selectedBuyers, setSelectedBuyers] = useState([]);

  const { toast } = useToast();
  const { invoiceId } = useParams();
  const { userOrigin } = useAuth();

  const changeType = async (event) => {
    const option = event.target.value;

    const findTipoMercado = tiposMercado.find(
      (tipoMercado) => tipoMercado.value == option
    );

    if (!findTipoMercado)
      toast.error('Tipo de mercado inválido', { id: 'tipo-mercado' });

    setTipoMercado({
      name: findTipoMercado.label,
      id: findTipoMercado.value,
    });

    if ((option === 2 || option === 3) && buyers.length === 0) {
      const data = await GetBuyers(userOrigin);
      const _buyers = data.map((x) => ({
        name: x.name,
        id: x.businessId,
      }));
      setBuyers(_buyers);
    }
  };

  async function handleLoadBuyers() {
    const data = await GetBuyers(userOrigin);
    const invoiceBuyersId = await getInvoiceBuyers(invoiceId);

    const _buyers = data
      .map((x) => ({
        name: x.name,
        id: x.businessId,
      }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));

    const invoiceBuyersData = _buyers.filter((buyer) =>
      invoiceBuyersId.some((invoiceBuyer) => invoiceBuyer.BUYER_ID == buyer.id)
    );

    setBuyers(_buyers);
    setSelectedBuyers(invoiceBuyersData);
  }

  async function handleLoadMarket() {
    const market = await getMarket(invoiceId);

    setTipoMercado({ id: +market.id, name: market.name });
  }

  async function handleLoadData() {
    setIsLoading(true);
    try {
      toast('Carregando tipo de mercado', { id: 'tipo-mercado' });
      await handleLoadMarket();
      await handleLoadBuyers();
      toast.success('Tipo de mercado carregado', { id: 'tipo-mercado' });
    } catch (error) {
      toast.error('Erro carregando tipo de mercado', { id: 'tipo-mercado' });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setSelectedBuyers([]);
  }, [tipoMercado]);

  useEffect(() => {
    handleLoadData();
  }, []);

  async function handleChangeType() {
    try {
      toast.loading('Processando...', { id: 'tipo-mercado' });

      if (!tipoMercado)
        return toast.error('Nenhum tipo selecionado', { id: 'tipo-mercado' });

      // validando tipo de mercado que deve possuir financiadores
      if (tipoMercado.id != 1 && selectedBuyers.length == 0) {
        return toast.error('Selecione um nome', { id: 'tipo-mercado' });
      }

      // enviando nova lista de financiadores do título com base no tipo de mercado
      if (tipoMercado.id != 1 && selectedBuyers.length > 0) {
        // serializando formato da lista de financiadores para retornar uma lista com seus respectivos ids
        const serializedSelectedBuyers = selectedBuyers.map(
          (selectedBuyer) => selectedBuyer.id
        );

        // atualizando lista
        await updateInvoiceBuyers(serializedSelectedBuyers, invoiceId);
      }

      // atualizando tipo de mercado
      await updateMarketType(tipoMercado, invoiceId);

      toast.success('Tipo de mercado alterado', { id: 'tipo-mercado' });
    } catch (error) {
      toast.error('Erro ao alterar dados do mercado', { id: 'tipo-mercado' });
    }
  }

  return (
    <>
      <Card className="p-4 mb-4">
        <div className="font-size-lg font-weight-bold">Tipos de Mercado</div>
        <Divider className="my-4" />
        {!isLoading && (
          <>
            <Grid container spacing={4}>
              <>
                <Grid item xs={12} lg={tipoMercado.id == 1 ? 12 : 6}>
                  <div className="p-3">
                    <TextField
                      fullWidth
                      id="outlined-select-type"
                      select
                      label="Tipo de Mercado"
                      variant="outlined"
                      value={tipoMercado.id}
                      onChange={changeType}
                    >
                      {tiposMercado.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={6}
                  className={tipoMercado.id == 1 ? 'invisible' : 'visible'}
                >
                  {tipoMercado.id != 1 && buyers && (
                    <div className="p-3">
                      <AutoCompleteSelect
                        label="Financiadores"
                        noOptionsText="Nenhum financiador encontrado"
                        value={selectedBuyers}
                        options={buyers}
                        onChange={(event, newValue) => {
                          setSelectedBuyers([...newValue]);
                        }}
                      />
                    </div>
                  )}
                </Grid>
              </>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ConfirmButton onClick={handleChangeType}>Salvar</ConfirmButton>
              </div>
            </Grid>
          </>
        )}
      </Card>
    </>
  );
};

export default TipoMercado;
