export const postBidCalculoCet = [
  {
    INVOICE_ID: 'C64BABC6-F8C9-5E57-05BE-1B23834BE56D',
    MATURITY_ID: 'DB6F1DAA-6862-9F28-570E-14104FC075E8',
    BUYER_ID: '54844820-8F81-472E-ADA1-FE61823D8946',
    BID_TOTAL_TERM: 42,
    DAYS: 42,
    CURRENT_DAYS_FORM: 210000,
    POND_DAYS_FORM: 210000,
    BID_PAYMENT_TERM: 0,
    AVERAGE_TERM: 42,
    MATURITY_DATE: '2021-12-28',
    MATURITY_VALUE: 5000,
    WEIGHTAVERAGETERM: 42,
    WEIGHTTOTALPONDVALUE: 210000,
    BID_FACTOR_VALUE: -30.73,
    BID_COMISSION_VALUE: 38.5,
    BID_ADVALOREM_VALUE: 0,
    BID_IOF_AMOUNT_VALUE: 0,
    BID_IOF_TERM_VALUE: 0,
    BID_BANK_SLIP: 0,
    BID_OPERATION_VALUE: 0,
    BID_ANALYSIS_VALUE: 0,
    BID_DISCOUNT_VALUE: 7.77,
    BID_NET_VALUE: 4992.23,
    BID_PERCT_CET: 11.25,
    BID_PERCT_CET_TOTAL: -1.125,
    BID_TOTAL_TAC: null,
    BID_PERCT_TAC: null,
    BID_TOTAL_IOF: null,
    BID_PERCT_IOF: null,
    BID_PERCT_FACTOR: null,
    BID_PERCT_COMM: null,
    BID_PERCT_FACTORFLO: null,
  },
  {
    INVOICE_ID: 'C64BABC6-F8C9-5E57-05BE-1B23834BE56D',
    MATURITY_ID: '74878ABF-6B67-BB3B-F635-66BE22FD7724',
    BUYER_ID: '54844820-8F81-472E-ADA1-FE61823D8946',
    BID_TOTAL_TERM: 12,
    DAYS: 12,
    CURRENT_DAYS_FORM: 60000,
    POND_DAYS_FORM: 60000,
    BID_PAYMENT_TERM: 0,
    AVERAGE_TERM: 54,
    MATURITY_DATE: '2021-11-28',
    MATURITY_VALUE: 5000,
    WEIGHTAVERAGETERM: 12,
    WEIGHTTOTALPONDVALUE: 60000,
    BID_FACTOR_VALUE: -8.78,
    BID_COMISSION_VALUE: 7,
    BID_ADVALOREM_VALUE: 0,
    BID_IOF_AMOUNT_VALUE: 0,
    BID_IOF_TERM_VALUE: 0,
    BID_BANK_SLIP: 0,
    BID_OPERATION_VALUE: 0,
    BID_ANALYSIS_VALUE: 0,
    BID_DISCOUNT_VALUE: -1.78,
    BID_NET_VALUE: 5001.78,
    BID_PERCT_CET: 11.25,
    BID_PERCT_CET_TOTAL: -1.125,
    BID_TOTAL_TAC: null,
    BID_PERCT_TAC: null,
    BID_TOTAL_IOF: null,
    BID_PERCT_IOF: null,
    BID_PERCT_FACTOR: null,
    BID_PERCT_COMM: null,
    BID_PERCT_FACTORFLO: null,
  },
  {
    INVOICE_ID: 'C64BABC6-F8C9-5E57-05BE-1B23834BE56D',
    MATURITY_ID: null,
    BUYER_ID: '54844820-8F81-472E-ADA1-FE61823D8946',
    BID_TOTAL_TERM: null,
    DAYS: 42,
    CURRENT_DAYS_FORM: 270000,
    POND_DAYS_FORM: 270000,
    BID_PAYMENT_TERM: 0,
    AVERAGE_TERM: 27,
    MATURITY_DATE: null,
    MATURITY_VALUE: 10000,
    WEIGHTAVERAGETERM: 27,
    WEIGHTTOTALPONDVALUE: 27,
    BID_FACTOR_VALUE: -39.51,
    BID_COMISSION_VALUE: 45.5,
    BID_ADVALOREM_VALUE: 0,
    BID_IOF_AMOUNT_VALUE: 0,
    BID_IOF_TERM_VALUE: 0,
    BID_BANK_SLIP: 0,
    BID_OPERATION_VALUE: 0,
    BID_ANALYSIS_VALUE: 0,
    BID_DISCOUNT_VALUE: 5.99,
    BID_NET_VALUE: 9994.01,
    BID_PERCT_CET: 11.25,
    BID_PERCT_CET_TOTAL: -1.125,
    BID_TOTAL_TAC: 0,
    BID_PERCT_TAC: 0,
    BID_TOTAL_IOF: 0,
    BID_PERCT_IOF: 0,
    BID_PERCT_FACTOR: -0.439,
    BID_PERCT_COMM: 0.5055,
    BID_PERCT_FACTORFLO: -0.439,
  },
];

export const getTagsAllResponseSuccess = [
  {
    tagId: '014B4574-75F6-870F-097D-41B145E64174',
    typeId: 2,
    typeName: 'Agenda',
    name: 'Antecipação Parcial - 10%',
  },
  {
    tagId: 'D2B458E2-BCA3-4C96-8A75-A154A7659F83',
    typeId: 1,
    typeName: 'Título',
    name: 'Antecipação Parcial - 15%',
  },
  {
    tagId: '1E2ACCB9-5B20-4604-B2AB-0D14CFD334B8',
    typeId: 1,
    typeName: 'Título',
    name: 'Antecipação Parcial - 30%',
  },
  {
    tagId: '69BC8DD1-9339-4C2F-BB67-F73F4CE3ADE5',
    typeId: 1,
    typeName: 'Título',
    name: 'Antecipação Parcial - 45%',
  },
  {
    tagId: 'A978F0CB-FBEF-4A0D-901C-F364F90AD9D6',
    typeId: 1,
    typeName: 'Título',
    name: 'Antecipação Parcial - 50%',
  },
  {
    tagId: '29DD9596-C3FE-7A06-1D98-D897E697DDBF',
    typeId: 0,
    typeName: 'Default',
    name: 'Antecipação Parcial - 83%',
  },
  {
    tagId: '649B9431-66A3-4AC6-A856-F7B29F20C700',
    typeId: 1,
    typeName: 'Título',
    name: 'Antecipação Parcial - Até 10%',
  },
  {
    tagId: '35322D4B-F552-41E1-B5C9-3304392951DE',
    typeId: 1,
    typeName: 'Título',
    name: 'ANTECIPAÇÃO PARCIAL - ATÉ 20%',
  },
  {
    tagId: '37B6853E-D269-451C-86F0-80D10E2FA9A5',
    typeId: 1,
    typeName: 'Título',
    name: 'Antecipação Parcial - Até 20%',
  },
  {
    tagId: 'E27E9BB7-7188-4E2C-959F-5A6BEFAAEC91',
    typeId: 1,
    typeName: 'Título',
    name: 'Antecipação Parcial - Até 25%',
  },
  {
    tagId: '9300DC61-3459-4099-B909-F664AB8D01BD',
    typeId: 1,
    typeName: 'Título',
    name: 'Antecipação Parcial - Até 30%',
  },
  {
    tagId: '722A6E41-9B7F-4903-9537-55B807CC9355',
    typeId: 1,
    typeName: 'Título',
    name: 'Antecipação Parcial - Até 35%',
  },
  {
    tagId: '0C934E03-C42A-4E73-A809-54D6CECAD7FA',
    typeId: 1,
    typeName: 'Título',
    name: 'Antecipação Parcial - Até 40%',
  },
  {
    tagId: '424DF75C-E349-40DB-B12A-EAF32987460F',
    typeId: 1,
    typeName: 'Título',
    name: 'Antecipação Parcial - Até 5%',
  },
  {
    tagId: 'BFC90927-1D85-4D71-9BF5-6165B5DA5416',
    typeId: 1,
    typeName: 'Título',
    name: 'Antecipação Parcial - Até 50%',
  },
  {
    tagId: '4EBF2D82-8F72-4953-9B77-A877E7ECF076',
    typeId: 1,
    typeName: 'Título',
    name: 'Antecipação Parcial - Até 60%',
  },
  {
    tagId: '78617609-33B7-4587-AAEB-3C3D2EBF582D',
    typeId: 1,
    typeName: 'Título',
    name: 'Antecipação Parcial - Até 65%',
  },
  {
    tagId: '38E65F2E-AA10-4C3E-A84C-BB23A34A0EB1',
    typeId: 1,
    typeName: 'Título',
    name: 'Antecipação Parcial - Até 70%',
  },
  {
    tagId: '477B1024-406A-47C3-845D-D8E2D5A0660D',
    typeId: 1,
    typeName: 'Título',
    name: 'ANTECIPAÇÃO PARCIAL - ATÉ 85%',
  },
  {
    tagId: '98A0CA7D-83E4-42F3-9FA9-198F9CD7BC93',
    typeId: 1,
    typeName: 'Título',
    name: 'automação industrial',
  },
  {
    tagId: '96655993-4B28-4B85-83FB-4B1152959946',
    typeId: 1,
    typeName: 'Título',
    name: 'Balcão Financiadores - Títulos em atraso',
  },
  {
    tagId: '7E42DA7E-BDCB-4DB4-97E3-811B667DB331',
    typeId: 1,
    typeName: 'Título',
    name: 'Cartão de Crédito',
  },
  {
    tagId: 'E3C75D50-2069-46AA-858C-EEA979E764B9',
    typeId: 1,
    typeName: 'Título',
    name: 'Construção',
  },
  {
    tagId: '8364AFD1-BB3F-4AAA-A54C-546AF033D4E2',
    typeId: 1,
    typeName: 'Título',
    name: 'Consultoria TI',
  },
  {
    tagId: '832F2ACE-3446-4289-92BD-F583B23A832F',
    typeId: 1,
    typeName: 'Título',
    name: 'Conta Consignada',
  },
  {
    tagId: '2C8B229C-3B02-4E96-B042-A62C8C870499',
    typeId: 0,
    typeName: null,
    name: 'Conta Consignada',
  },
  {
    tagId: '8A733957-2269-43C7-A793-9362BF62C87E',
    typeId: 1,
    typeName: 'Título',
    name: 'Conta Vinculada',
  },
  {
    tagId: '7FE7E02D-2067-4DFA-98DC-2DAB55DA2620',
    typeId: 1,
    typeName: 'Título',
    name: 'Distribuidora',
  },
  {
    tagId: 'C5882FBF-76C5-470E-8879-93C33BC3802C',
    typeId: 1,
    typeName: 'Título',
    name: 'Em Recuperação Judicial',
  },
  {
    tagId: 'BC35157D-0EF3-48BC-B3B0-3CA2F63C0804',
    typeId: 1,
    typeName: 'Título',
    name: 'Eventos',
  },
  {
    tagId: '2CC99DAC-0F9D-410D-9E47-B67EC192A9E4',
    typeId: 1,
    typeName: 'Título',
    name: 'Intervenção',
  },
  {
    tagId: '392E481E-6403-4690-8734-A021DC151A82',
    typeId: 1,
    typeName: 'Título',
    name: 'LOCAÇÃO',
  },
  {
    tagId: '7BC382A6-6049-4142-89DB-133D01255148',
    typeId: 1,
    typeName: 'Título',
    name: 'Mercadoria Embarcada',
  },
  {
    tagId: '777B7BD9-54B6-4854-9CE9-3169DEF68743',
    typeId: 1,
    typeName: 'Título',
    name: 'NF - Contrato de Serviço',
  },
  {
    tagId: '2F07188E-2119-463E-A3F5-CA6AF3BFC523',
    typeId: 1,
    typeName: 'Título',
    name: 'Operação de Fomento',
  },
  {
    tagId: '3F5EF878-E4C7-4414-907E-71160ECE8736',
    typeId: 1,
    typeName: 'Título',
    name: 'Operação de Fomento - Até 20 dias',
  },
  {
    tagId: 'BC6AE92B-C36D-4E8E-8532-41821EE6F4D5',
    typeId: 1,
    typeName: 'Título',
    name: 'Operação em CTe',
  },
  {
    tagId: '4524CC96-DBFF-487B-8B2B-E91C1CBC561C',
    typeId: 1,
    typeName: 'Título',
    name: 'Pagamento via Boleto',
  },
  {
    tagId: 'F2CF4BFA-5D66-4FD4-B332-5F474E7950AB',
    typeId: 1,
    typeName: 'Título',
    name: 'Pré-Faturamento',
  },
  {
    tagId: 'BDD36078-FB24-4E1D-9185-A3E674181B66',
    typeId: 1,
    typeName: 'Título',
    name: 'Pré-faturamento  - Até 5 dias',
  },
  {
    tagId: '785B9E02-8077-4B05-88A5-9B41A0137AC2',
    typeId: 1,
    typeName: 'Título',
    name: 'Pré-Faturamento - 30 DIAS',
  },
  {
    tagId: '88D88326-B5DC-4925-A480-9F4EF7F952B2',
    typeId: 1,
    typeName: 'Título',
    name: 'Pré-Faturamento - 60 DIAS',
  },
  {
    tagId: '08287041-7825-43E7-8013-4E2DDCD8A318',
    typeId: 1,
    typeName: 'Título',
    name: 'Pré-faturamento - ATÉ 10 DIAS',
  },
  {
    tagId: 'B98992AC-4E1A-4973-964B-CC1D64AAFFFE',
    typeId: 1,
    typeName: 'Título',
    name: 'Pré-faturamento - ATÉ 15 DIAS',
  },
  {
    tagId: '567DEB5C-0EEA-49F3-9DEA-AF7924226EEE',
    typeId: 1,
    typeName: 'Título',
    name: 'Pré-faturamento - ATÉ 20 DIAS',
  },
  {
    tagId: 'D600652D-127E-43D4-866B-4776FBAB3F3E',
    typeId: 1,
    typeName: 'Título',
    name: 'Recompra',
  },
  {
    tagId: '621D5A97-46C3-4C53-8384-98C3834429A6',
    typeId: 1,
    typeName: 'Título',
    name: 'Sacado Fora SP/RJ',
  },
  {
    tagId: '3EA5FC72-B811-4FB9-B542-0833B522E09E',
    typeId: 1,
    typeName: 'Título',
    name: 'Sacado Multinacional',
  },
  {
    tagId: '7E47A637-62D0-4CC4-A233-552E0E46F190',
    typeId: 1,
    typeName: 'Título',
    name: 'Sacado RJ',
  },
  {
    tagId: '6505DDBC-4EA5-446F-A7B1-1A6EE8E1735E',
    typeId: 1,
    typeName: 'Título',
    name: 'Sacado SP',
  },
  {
    tagId: 'A6E2A0EC-B7F5-40E2-BC69-9BF25734C5B1',
    typeId: 1,
    typeName: 'Título',
    name: 'Substituição de Nota Fiscal',
  },
  {
    tagId: '80097402-9FB2-FB99-F53F-F17D3E2C445E',
    typeId: 0,
    typeName: 'Default',
    name: 'teste 6 ',
  },
  {
    tagId: 'BAC73038-3751-061B-1EEA-1CEEF1CE03F4',
    typeId: 2,
    typeName: 'Agenda',
    name: 'Teste Agenda',
  },
  {
    tagId: 'E98F6395-8ECE-9BCD-E96F-F29CE1FA40EF',
    typeId: 0,
    typeName: 'Default',
    name: 'Teste cadastro',
  },
  {
    tagId: '57C1FDE2-33FA-37FD-AFAF-5067A4ADEB1C',
    typeId: 2,
    typeName: 'Agenda',
    name: 'Teste de tag...',
  },
  {
    tagId: 'CDA045DF-CA90-B2FF-DBBE-F6CABFF5068A',
    typeId: 0,
    typeName: 'Default',
    name: 'Teste fred',
  },
  {
    tagId: 'E4961BA2-DA5C-444C-5282-F270D75F4580',
    typeId: 2,
    typeName: 'Agenda',
    name: 'Teste fred',
  },
  {
    tagId: '4434B442-B507-5D78-584D-D0C28C63E540',
    typeId: 0,
    typeName: 'Default',
    name: 'teste fred 01',
  },
  {
    tagId: '2A0F2899-AB7F-C8C9-E754-A621E157234E',
    typeId: 0,
    typeName: 'Default',
    name: 'teste fred 02',
  },
  {
    tagId: '96424785-E1A3-7907-12D0-1D57EDCA8B45',
    typeId: 0,
    typeName: 'Default',
    name: 'teste fred 100',
  },
  {
    tagId: '68B10E58-47E7-D52B-3020-0BF888AA9F81',
    typeId: 0,
    typeName: 'Default',
    name: 'teste fred 123',
  },
  {
    tagId: 'E6D80F90-5790-8BB1-02EE-E848A8A8524C',
    typeId: 0,
    typeName: 'Default',
    name: 'Teste fred 4',
  },
  {
    tagId: 'FB0C6B38-C1F0-CDE5-4426-D584E40D7627',
    typeId: 0,
    typeName: 'Default',
    name: 'Teste fred 5',
  },
  {
    tagId: 'BC084504-DAC0-E0D4-2D88-B620119FDD99',
    typeId: 0,
    typeName: 'Default',
    name: 'Teste fred 9',
  },
  {
    tagId: '124585E9-E79F-566C-2828-A2E5880541DC',
    typeId: 0,
    typeName: 'Default',
    name: 'Teste Tag 25',
  },
  {
    tagId: '3DA3A521-ABC3-4A30-A86F-992E6471BB6C',
    typeId: 1,
    typeName: 'Título',
    name: 'Título Performado',
  },
  {
    tagId: 'E15C485B-D077-4C60-B0AE-B19DA1DBBCDF',
    typeId: 1,
    typeName: 'Título',
    name: 'Título Público',
  },
];

export const getBusinessNotificationBusinessReceiverResponseSuccess = [
  {
    businessNotificationId: '165E787A-6004-23DA-438D-6C89272789BA',
    notificationText: 'O cedente aprovou a sua proposta de prorrogação!',
    notificationDate: '2021-10-22T19:57:07',
    notificationLink: '/Invoice/83ABC1B9-F8F7-DC3E-5F60-1B90150CB9CC',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C9FB5BF7-C1DA-4611-8B9A-F065D88F5E86',
    isReaded: 1,
  },
  {
    businessNotificationId: '6C09384E-46EA-4B34-687A-ACD65FD67CBA',
    notificationText: 'O cedente aprovou a sua proposta de prorrogação!',
    notificationDate: '2021-10-22T19:56:56',
    notificationLink: '/Invoice/83ABC1B9-F8F7-DC3E-5F60-1B90150CB9CC',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C9FB5BF7-C1DA-4611-8B9A-F065D88F5E86',
    isReaded: 1,
  },
  {
    businessNotificationId: '77CF98BD-0B0D-C5C5-D1F2-B4C96925E60D',
    notificationText: 'O cedente aprovou a sua proposta de prorrogação!',
    notificationDate: '2021-10-22T19:56:15',
    notificationLink: '/Invoice/83ABC1B9-F8F7-DC3E-5F60-1B90150CB9CC',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C9FB5BF7-C1DA-4611-8B9A-F065D88F5E86',
    isReaded: 1,
  },
  {
    businessNotificationId: 'D9C2766A-8596-7F98-56DE-2D42B041A46D',
    notificationText: 'Você recebeu uma solicitação de prorrogação!',
    notificationDate: '2021-10-22T19:52:46',
    notificationLink: '/Invoice/83ABC1B9-F8F7-DC3E-5F60-1B90150CB9CC',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C9FB5BF7-C1DA-4611-8B9A-F065D88F5E86',
    isReaded: 1,
  },
  {
    businessNotificationId: '79F2BEFE-F0F2-1B5B-7347-BE4EAFD44F03',
    notificationText: 'Você recebeu uma solicitação de prorrogação!',
    notificationDate: '2021-10-22T19:52:37',
    notificationLink: '/Invoice/83ABC1B9-F8F7-DC3E-5F60-1B90150CB9CC',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C9FB5BF7-C1DA-4611-8B9A-F065D88F5E86',
    isReaded: 1,
  },
  {
    businessNotificationId: '9258BA93-F140-0EBD-6DC4-E2C9E09FE2DC',
    notificationText: 'Você recebeu uma solicitação de prorrogação!',
    notificationDate: '2021-10-22T19:51:15',
    notificationLink: '/Invoice/83ABC1B9-F8F7-DC3E-5F60-1B90150CB9CC',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C9FB5BF7-C1DA-4611-8B9A-F065D88F5E86',
    isReaded: 1,
  },
  {
    businessNotificationId: '34653E3B-315C-31F9-6EDC-9B0DEDE6544D',
    notificationText: 'Você recebeu uma solicitação de prorrogação!',
    notificationDate: '2021-10-01T19:26:55',
    notificationLink: '/Invoice/83ABC1B9-F8F7-DC3E-5F60-1B90150CB9CC',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C9FB5BF7-C1DA-4611-8B9A-F065D88F5E86',
    isReaded: 1,
  },
  {
    businessNotificationId: 'A6A1BB11-8B48-4496-936E-9212141CDBF2',
    notificationText: 'Oferta registrada no Título 6823',
    notificationDate: '2021-09-30T03:36:39.450',
    notificationLink: '/Invoice/6E55DCE5-AB2A-84AB-2719-B4FE719668D8',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C7A97B17-AEE5-4D79-9E25-1FA9CDF72B62',
    isReaded: 1,
  },
  {
    businessNotificationId: '390390E0-7B50-4728-AA01-57ED35F2F491',
    notificationText: 'Oferta registrada no ID 6762',
    notificationDate: '2021-09-23T17:20:07.493',
    notificationLink: 'Invoice/30993273-DD71-F882-9F45-D7044A4489EE',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C7A97B17-AEE5-4D79-9E25-1FA9CDF72B62',
    isReaded: 1,
  },
  {
    businessNotificationId: 'DE75D2D8-AC65-463D-B78D-33A5604582A5',
    notificationText: 'Oferta registrada no ID 6801',
    notificationDate: '2021-09-23T00:59:27.360',
    notificationLink: 'Invoice/83ABC1B9-F8F7-DC3E-5F60-1B90150CB9CC',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C7A97B17-AEE5-4D79-9E25-1FA9CDF72B62',
    isReaded: 1,
  },
  {
    businessNotificationId: 'C431ADD9-8D87-4DAA-82CB-D81F231CAC12',
    notificationText: 'Oferta registrada no ID 6800',
    notificationDate: '2021-09-23T00:45:00.310',
    notificationLink: 'Invoice/D7ED2DD6-27CB-2A51-6BDB-D3164264F5F3',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C7A97B17-AEE5-4D79-9E25-1FA9CDF72B62',
    isReaded: 1,
  },
  {
    businessNotificationId: '285DEA24-7F49-44B1-B266-891BA9046549',
    notificationText: 'Oferta registrada no ID 6800',
    notificationDate: '2021-09-23T00:42:20.233',
    notificationLink: 'Invoice/D7ED2DD6-27CB-2A51-6BDB-D3164264F5F3',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C7A97B17-AEE5-4D79-9E25-1FA9CDF72B62',
    isReaded: 1,
  },
  {
    businessNotificationId: '18ACBEA0-D089-424A-808B-9374CBE671B7',
    notificationText: 'Oferta registrada no ID 6800',
    notificationDate: '2021-09-23T00:40:07.653',
    notificationLink: 'Invoice/D7ED2DD6-27CB-2A51-6BDB-D3164264F5F3',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C7A97B17-AEE5-4D79-9E25-1FA9CDF72B62',
    isReaded: 1,
  },
  {
    businessNotificationId: '767C8505-6B5D-451D-AA12-91A5BF1F8D8B',
    notificationText: 'Oferta registrada no ID 6800',
    notificationDate: '2021-09-23T00:37:54.087',
    notificationLink: 'Invoice/D7ED2DD6-27CB-2A51-6BDB-D3164264F5F3',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C7A97B17-AEE5-4D79-9E25-1FA9CDF72B62',
    isReaded: 1,
  },
  {
    businessNotificationId: '4ED7AF31-58EC-4AE1-B7C8-E0ABD845CA63',
    notificationText: 'Oferta registrada no ID 6705',
    notificationDate: '2021-09-23T00:18:55.060',
    notificationLink: 'Invoice/25A721A1-2DD4-472A-B5E8-17AF539C236E',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C7A97B17-AEE5-4D79-9E25-1FA9CDF72B62',
    isReaded: 1,
  },
  {
    businessNotificationId: '446A2284-1AFC-454F-99D6-BC61F6E87984',
    notificationText: 'Oferta registrada no ID 6705',
    notificationDate: '2021-09-23T00:14:57.697',
    notificationLink: 'Invoice/25A721A1-2DD4-472A-B5E8-17AF539C236E',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C7A97B17-AEE5-4D79-9E25-1FA9CDF72B62',
    isReaded: 1,
  },
  {
    businessNotificationId: 'EAB7F875-67D3-41D8-90D7-6D0D1F442346',
    notificationText: 'Oferta registrada no ID 6705',
    notificationDate: '2021-09-23T00:14:57.697',
    notificationLink: 'Invoice/25A721A1-2DD4-472A-B5E8-17AF539C236E',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C7A97B17-AEE5-4D79-9E25-1FA9CDF72B62',
    isReaded: 1,
  },
  {
    businessNotificationId: 'B1275E5D-EC44-4605-93F0-699177ECDAB5',
    notificationText: 'Oferta registrada no ID 6705',
    notificationDate: '2021-09-23T00:12:46.027',
    notificationLink: 'Invoice/25A721A1-2DD4-472A-B5E8-17AF539C236E',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C7A97B17-AEE5-4D79-9E25-1FA9CDF72B62',
    isReaded: 1,
  },
  {
    businessNotificationId: 'F75D6EFA-834E-4F08-9928-A33103A81805',
    notificationText: 'Oferta registrada no ID 6705',
    notificationDate: '2021-09-23T00:12:46.027',
    notificationLink: 'Invoice/25A721A1-2DD4-472A-B5E8-17AF539C236E',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C7A97B17-AEE5-4D79-9E25-1FA9CDF72B62',
    isReaded: 1,
  },
  {
    businessNotificationId: 'FE96C0C8-B21E-4B71-8414-A078CBAFA97C',
    notificationText: 'Oferta registrada no ID 6705',
    notificationDate: '2021-09-23T00:03:50.223',
    notificationLink: 'Invoice/25A721A1-2DD4-472A-B5E8-17AF539C236E',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C7A97B17-AEE5-4D79-9E25-1FA9CDF72B62',
    isReaded: 1,
  },
  {
    businessNotificationId: 'E07E33F5-77CC-4831-9DC4-90C9BF09EEFF',
    notificationText: 'Oferta registrada no ID 6703',
    notificationDate: '2021-09-06T21:40:22.190',
    notificationLink:
      'https://app.antecipafacil.com.br/Invoice/F9C0947E-E5B9-C2D9-99C0-3833EFEEC5DC',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C7A97B17-AEE5-4D79-9E25-1FA9CDF72B62',
    isReaded: 1,
  },
  {
    businessNotificationId: 'B8497CCB-A78A-4E81-B0F2-790FF3B857FC',
    notificationText: 'Oferta registrada no ID 6660',
    notificationDate: '2021-09-06T21:17:20.180',
    notificationLink:
      'https://app.antecipafacil.com.br/Invoice/2EF1CC73-3EAD-6FA0-9E3A-D81AD4AC9EFB',
    businessReceiverId: '54844820-8F81-472E-ADA1-FE61823D8946',
    businessSenderId: 'C7A97B17-AEE5-4D79-9E25-1FA9CDF72B62',
    isReaded: 1,
  },
];

export const postNoGordLeExecuteResponseSuccess = {
  result: {
    value: {
      comissaoMin: 15.0,
      comissaoPct1: 0.0055,
      invoiceID: 'C64BABC6-F8C9-5E57-05BE-1B23834BE56D',
      cetCorte1: 0.01,
      comissaoPct2: 0.0055,
      comissaoPct3: 0.0055,
      comissaoPct4: 0.0055,
      comissaoPct5: 0.0055,
      cetCorte2: 0.02,
      cetCorte3: 0.03,
      cetCorte4: 0.04,
    },
  },
  info: {
    external_sources: [
      {
        step: 'Exceções',
        source_type: '',
        data: {
          comissaoPct1: null,
          comissaoPct2: null,
          comissaoPct3: null,
          comissaoPct4: null,
          comissaoPct5: null,
          cetCorte1: null,
          cetCorte2: null,
          cetCorte3: null,
          cetCorte4: null,
          comissaoMin: null,
        },
      },
    ],
    env: 'DEPLOYED',
    type: 'rule',
    process_name: null,
    version: '3',
    process_execution_id: '4b1a2cf8-3732-4c34-8ed7-036eea451f28',
    process_execution_time: '00:00:00.002',
    process_execution_time_millis: 2,
    process_execution_date: 1637091349633,
    was_needed_to_deploy: false,
    start_time: '16/11/2021 16:35:49.631',
    end_time: '16/11/2021 16:35:49.633',
    output_size_bytes: 1169,
    compiler_version: 'v3',
  },
};

export const getInvoiceActionResponseSuccess = [
  {
    invoice: {
      invoiceId: 'B391ACE2-F2E8-7D0C-2489-6313FFB484B1',
      businessId: 'C9FB5BF7-C1DA-4611-8B9A-F065D88F5E86',
      typeId: 2,
      typeName: 'Produtos',
      riskId: 8,
      riskName: 'BB',
      statusId: 9,
      statusName: 'Aceitar Lance',
      number: '230968',
      invoiceValue: 55125,
      minimumBid: 0,
      winningBidId: '9A80E64B-90AF-421F-9F33-C2055F093D00',
      operationNatureId: 0,
      issueDate: '2021-12-15T06:00:00',
      registerDate: '2021-10-27T10:05:59',
      installments: 1,
      tradingFloorId: '4BC203CD-6987-531D-26D0-9145516F58F6',
      userId: '2C79BF0B-8948-853D-DA4B-501B2F522DCE',
      bidId: '9A80E64B-90AF-421F-9F33-C2055F093D00',
      bidBusinessId: 'C9EC4134-ABE5-488B-8647-32C54CAE7416',
      bidUserId: '5BAE0EC0-EA31-4457-BDD6-6375CA51A163',
      bidUserName: 'Frederico Casas Figueiredo',
      bidstatusId: 0,
      bidStatusName: '',
      bidStatusDate: '2021-11-12T16:08:19.197',
      bidFactor: 3.25,
      bidDiscountValue: 4409.786,
      bidCommissionValue: 587.9714,
      bidNetValue: 50715.214,
      bidDate: '2021-11-12T16:08:19.197',
      bidCommission: 0.5,
      bidAdValorem: 0,
      bidIofAmount: 0,
      bidIofTerm: 0,
      bidBankSlip: 0,
      bidOperation: 0,
      bidAnalysis: 0,
      bidPaymentTerm: 0,
      bidAverageTerm: 63,
      bidWeightedAverageTerm: 64,
      prazoMedioNog: 60,
      bidFactorValue: 3821.8146,
      bidAdValoremValue: 0,
      bidIofAmountValue: 0,
      bidIofTermValue: 0,
      bidBankSlipValue: 0,
      bidOperationValue: 0,
      bidAnalysisValue: 0,
      bidNetFactor: 3.75,
      maturitiesValue: 55125,
      id: 6852,
      statusDate: '2021-11-12T16:08:19.197',
      averageTerm: 63,
      maturities: 3,
      maxOffer: 4.5,
      liquidationTypeId: 0,
      marketTypeId: 1,
      marketTypeName: 'Leilão',
      riskModelCode: 'OPV2',
      riskExecutedDate: '2021-10-27T10:05:59',
      riskObs: 'Calculado pelo NOG',
      isSerpro: false,
      bidMaxFactor: 4.5,
      originType: 'AF',
      abdicado: '0',
      recebiveis: '["046ABD8E-E2CC-A599-AB4A-553FDA675072"]',
      valorRecebiveis: '[55125]',
      days: 60,
      abdicarTitulo: '0         ',
      bidNetFactorOriginal: 3.75,
      cetPeriodo: 8,
      indOferta: 0,
      ranking: 6,
    },
    drawee: [
      {
        agenda_id: '046ABD8E-E2CC-A599-AB4A-553FDA675072',
        name: 'Laticinio Vale do Pardo Ltda',
        corporate_name: 'Laticinio Vale do Pardo Ltda',
        birth_date: null,
        person_type_id: 2,
        person_type_name: 'Pessoa Jurídica',
        country_identity: '02.749.513/0001-25',
        address_street: 'Loc. Saida Pata Tres Lagoas Km 01',
        address_number: 'S/N',
        address_complement: null,
        address_district: 'Zona Rural',
        address_city: 'BATAGUASSU',
        address_state: 'MS',
        address_zip_code: '79780-000',
        office_phone: '(67)9999-9999',
        mobile_phone: '6799999999',
        email: 'tunico@top.com.br',
        site: null,
        note: null,
        contact_name: 'Laticinio Vale do Pardo Ltda',
        contact_email: 'tunico@top.com.br',
        contact_role: null,
      },
    ],
    business: {
      addressStreet: 'Rua Abílio Fernandes',
      addressNumber: '275',
      addressComplement: 'X',
      addressDistrict: 'Jardim Alvorada',
      addressCity: 'Campinas',
      addressState: 'SP',
      addressZipCode: '13050-743',
      businessId: 'C9FB5BF7-C1DA-4611-8B9A-F065D88F5E86',
      originId: 10,
      originName: '2021',
      typeId: 1,
      typeName: 'Cedente',
      statusId: 4,
      statusName: 'Aprovado',
      name: 'ACQUA-OFF LAVAGEM A SECO',
      corporateName: 'MARCOS FELIPE APARECIDO MARQUEZINI 33943845826',
      personTypeId: 1,
      personTypeName: 'Cedente',
      countryIdentity: '29880478000135',
      marketTypeId: 1,
      marketTypeName: 'Leilão',
    },
    buyer: { businessId: '54844820-8F81-472E-ADA1-FE61823D8946' },
    sacado: {
      name: 'Laticinio Vale do Pardo Ltda',
      corporateName: 'Laticinio Vale do Pardo Ltda',
      countryIdentity: '02.749.513/0001-25',
    },
    tags: [
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
      { NAME: 'tag mock' },
    ],
  },
  {
    invoice: {
      invoiceId: '408733B5-66C7-7BE3-8B8B-F9968A7A0B7A',
      businessId: 'C9FB5BF7-C1DA-4611-8B9A-F065D88F5E86',
      typeId: 2,
      typeName: 'Produtos',
      riskId: 7,
      riskName: 'CA',
      statusId: 9,
      statusName: 'Aceitar Lance',
      number: '7544',
      invoiceValue: 1500,
      minimumBid: 0,
      winningBidId: '156AEFE5-418E-43C4-B1DD-A1F477B6D994',
      operationNatureId: 0,
      issueDate: '2021-12-31T06:00:00',
      registerDate: '2021-10-26T14:25:48',
      installments: 1,
      tradingFloorId: 'A34FC3CF-8CEC-7C62-7A10-8F162F735004',
      userId: '2C79BF0B-8948-853D-DA4B-501B2F522DCE',
      bidId: '156AEFE5-418E-43C4-B1DD-A1F477B6D994',
      bidBusinessId: 'C9EC4134-ABE5-488B-8647-32C54CAE7416',
      bidUserId: '5BAE0EC0-EA31-4457-BDD6-6375CA51A163',
      bidUserName: 'Frederico Casas Figueiredo',
      bidstatusId: 0,
      bidStatusName: '',
      bidStatusDate: '2021-10-29T16:38:17.157',
      bidFactor: 0.3,
      bidDiscountValue: 31.3998,
      bidCommissionValue: 19.6249,
      bidNetValue: 1468.6002,
      bidDate: '2021-10-29T16:38:17.157',
      bidCommission: 0.5,
      bidAdValorem: 0,
      bidIofAmount: 0,
      bidIofTerm: 0,
      bidBankSlip: 0,
      bidOperation: 0,
      bidAnalysis: 0,
      bidPaymentTerm: 0,
      bidAverageTerm: 78,
      bidWeightedAverageTerm: 79,
      prazoMedioNog: 61,
      bidFactorValue: 11.7749,
      bidAdValoremValue: 0,
      bidIofAmountValue: 0,
      bidIofTermValue: 0,
      bidBankSlipValue: 0,
      bidOperationValue: 0,
      bidAnalysisValue: 0,
      bidNetFactor: 0.7999,
      maturitiesValue: 1500,
      id: 6850,
      statusDate: '2021-10-29T16:38:17.157',
      averageTerm: 78,
      maturities: 2,
      maxOffer: 10,
      liquidationTypeId: 0,
      marketTypeId: 1,
      marketTypeName: 'Leilão',
      riskModelCode: 'OPV2',
      riskExecutedDate: '2021-10-26T14:25:48',
      riskObs: 'Calculado pelo NOG',
      isSerpro: false,
      bidMaxFactor: 10,
      originType: 'AF',
      abdicado: '0',
      recebiveis: '["DDA8815C-E5B5-DBC9-2D78-118745FE3C00"]',
      valorRecebiveis: '[1500]',
      days: 61,
      abdicarTitulo: '0         ',
      bidNetFactorOriginal: 0.7999,
      cetPeriodo: 2.1064033,
      indOferta: 0,
      ranking: 6,
    },
    drawee: [
      {
        agenda_id: 'DDA8815C-E5B5-DBC9-2D78-118745FE3C00',
        name: 'PADARIA FAMILIA PIRES LTDA',
        corporate_name: 'PADARIA FAMILIA PIRES LTDA',
        birth_date: null,
        person_type_id: 2,
        person_type_name: 'Pessoa Jurídica',
        country_identity: '13.524.258/0001-39',
        address_street: 'AVENIDA VEREADOR OSORIO SAMPAIO',
        address_number: '45',
        address_complement: 'LOJA C',
        address_district: 'VILA SANTA ROSA',
        address_city: 'ITABIRA',
        address_state: 'MG',
        address_zip_code: '35900-700',
        office_phone: '(31)3835-8040',
        mobile_phone: '3138358040',
        email: null,
        site: null,
        note: null,
        contact_name: 'PADARIA FAMILIA PIRES LTDA',
        contact_email: null,
        contact_role: null,
      },
    ],
    business: {
      addressStreet: 'Rua Abílio Fernandes',
      addressNumber: '275',
      addressComplement: 'X',
      addressDistrict: 'Jardim Alvorada',
      addressCity: 'Campinas',
      addressState: 'SP',
      addressZipCode: '13050-743',
      businessId: 'C9FB5BF7-C1DA-4611-8B9A-F065D88F5E86',
      originId: 10,
      originName: '2021',
      typeId: 1,
      typeName: 'Cedente',
      statusId: 4,
      statusName: 'Aprovado',
      name: 'ACQUA-OFF LAVAGEM A SECO',
      corporateName: 'MARCOS FELIPE APARECIDO MARQUEZINI 33943845826',
      personTypeId: 1,
      personTypeName: 'Cedente',
      countryIdentity: '29880478000135',
      marketTypeId: 1,
      marketTypeName: 'Leilão',
    },
    buyer: { businessId: '54844820-8F81-472E-ADA1-FE61823D8946' },
    sacado: {
      name: 'PADARIA FAMILIA PIRES LTDA',
      corporateName: 'PADARIA FAMILIA PIRES LTDA',
      countryIdentity: '13.524.258/0001-39',
    },
    tags: [],
  },
  {
    invoice: {
      invoiceId: '41244AEF-FB37-6D05-9CD9-CAB5F088C888',
      businessId: '3E9B6E4E-E029-45DB-851B-DD2A75549A36',
      typeId: 1,
      typeName: 'Serviços',
      riskId: 9,
      riskName: 'BA',
      statusId: 4,
      statusName: 'Aguardando Lance',
      number: '1',
      invoiceValue: 4000,
      minimumBid: 0,
      winningBidId: '00000000-0000-0000-0000-000000000000',
      operationNatureId: 0,
      issueDate: '2021-11-22T00:00:00',
      registerDate: '2021-10-25T13:40:57',
      installments: 1,
      tradingFloorId: '94A49DD2-89F1-C54F-CFBE-0FC547DB8846',
      userId: '4247F940-DAE1-408F-B022-41D2B1A087DC',
      bidstatusId: 0,
      bidStatusName: '',
      bidFactor: 0,
      bidDiscountValue: 0,
      bidCommissionValue: 0,
      bidNetValue: 0,
      bidCommission: 0,
      bidAdValorem: 0,
      bidIofAmount: 0,
      bidIofTerm: 0,
      bidBankSlip: 0,
      bidOperation: 0,
      bidAnalysis: 0,
      bidPaymentTerm: 0,
      bidAverageTerm: 0,
      bidWeightedAverageTerm: 36,
      prazoMedioNog: 14,
      bidFactorValue: 0,
      bidAdValoremValue: 0,
      bidIofAmountValue: 0,
      bidIofTermValue: 0,
      bidBankSlipValue: 0,
      bidOperationValue: 0,
      bidAnalysisValue: 0,
      bidNetFactor: 5,
      maturitiesValue: 4000,
      id: 6840,
      statusDate: '2021-10-25T13:40:57',
      maturities: 2,
      maxOffer: 8,
      liquidationTypeId: 0,
      marketTypeId: 3,
      marketTypeName: 'Exclusão',
      riskModelCode: 'OPV2',
      riskExecutedDate: '2021-10-25T13:40:57',
      riskObs: 'Calculado pelo NOG',
      isSerpro: false,
      bidMaxFactor: 8,
      originType: 'FIF',
      abdicado: '1',
      recebiveis: '["B0D5E3F0-224E-D5B2-48C0-590C719D8E1E"]',
      valorRecebiveis: '[4000]',
      days: 14,
      abdicarTitulo: '0         ',
      bidNetFactorOriginal: 5,
      cetPeriodo: 6,
      indOferta: 0,
    },
    drawee: [
      {
        agenda_id: 'B0D5E3F0-224E-D5B2-48C0-590C719D8E1E',
        name: 'teste',
        corporate_name: 'teste',
        birth_date: null,
        person_type_id: 2,
        person_type_name: 'Pessoa Jurídica',
        country_identity: '11.111.111/1111-11',
        address_street: ' ',
        address_number: null,
        address_complement: null,
        address_district: null,
        address_city: null,
        address_state: null,
        address_zip_code: null,
        office_phone: null,
        mobile_phone: null,
        email: null,
        site: null,
        note: null,
        contact_name: null,
        contact_email: null,
        contact_role: null,
      },
    ],
    business: {
      addressStreet: 'Rua Doutor Miguel Pierro',
      addressNumber: '011',
      addressDistrict: 'Cidade Universitária',
      addressCity: 'Campinas',
      addressState: 'SP',
      addressZipCode: '13083300',
      businessId: '3E9B6E4E-E029-45DB-851B-DD2A75549A36',
      originId: 1,
      originName: 'Site',
      typeId: 1,
      typeName: 'Cedente',
      statusId: 4,
      statusName: 'Aprovado',
      name: 'André Cedente FIF',
      corporateName: 'André Cedente FIF',
      personTypeId: 2,
      personTypeName: 'Pessoa Jurídiva',
      countryIdentity: '17.517.291/0001-92',
      code: '11708185',
      marketTypeId: 2,
      marketTypeName: 'Balcão',
    },
    buyer: { businessId: '54844820-8F81-472E-ADA1-FE61823D8946', floating: 0 },
    sacado: {
      name: 'teste',
      corporateName: 'teste',
      countryIdentity: '11.111.111/1111-11',
    },
    tags: [],
  },
  {
    invoice: {
      invoiceId: '2C0DA7C1-E37A-A396-AFBD-F8773824258E',
      businessId: '3E9B6E4E-E029-45DB-851B-DD2A75549A36',
      typeId: 1,
      typeName: 'Serviços',
      riskId: 9,
      riskName: 'BA',
      statusId: 9,
      statusName: 'Aceitar Lance',
      number: '10',
      invoiceValue: 32000,
      minimumBid: 0,
      winningBidId: 'DECCC5D3-28D3-4AD7-9F31-6BE622897AC0',
      operationNatureId: 0,
      issueDate: '2021-11-23T00:00:00',
      registerDate: '2021-10-25T10:22:11',
      installments: 1,
      tradingFloorId: '102ADA2D-7FA6-4EB0-0A5B-D18FBED1E926',
      userId: '4247F940-DAE1-408F-B022-41D2B1A087DC',
      bidId: 'DECCC5D3-28D3-4AD7-9F31-6BE622897AC0',
      bidBusinessId: '8BADE8FE-71B1-4680-8E66-92938B11BD29',
      bidUserId: '4DD08BE1-89BA-4C51-B26F-AE4C8787FDEE',
      bidUserName: 'André Financiador FIF',
      bidstatusId: 0,
      bidStatusName: '',
      bidStatusDate: '2021-10-25T13:40:19.290',
      bidFactor: 3,
      bidDiscountValue: 2211.1431,
      bidCommissionValue: 84.2016,
      bidNetValue: 29788.8569,
      bidDate: '2021-10-25T13:40:19.290',
      bidCommission: 0.15,
      bidAdValorem: 0.2,
      bidIofAmount: 0.38,
      bidIofTerm: 0.0041,
      bidBankSlip: 5,
      bidOperation: 0.4,
      bidAnalysis: 1,
      bidPaymentTerm: 3,
      bidAverageTerm: 52,
      bidWeightedAverageTerm: 50,
      prazoMedioNog: 28,
      bidFactorValue: 1684.032,
      bidAdValoremValue: 112.2655,
      bidIofAmountValue: 121.6,
      bidIofTermValue: 69.044,
      bidBankSlipValue: 10,
      bidOperationValue: 128,
      bidAnalysisValue: 2,
      bidNetFactor: 5,
      maturitiesValue: 32000,
      id: 6837,
      statusDate: '2021-10-25T13:40:19.290',
      averageTerm: 52,
      maturities: 2,
      maxOffer: 4.5,
      liquidationTypeId: 0,
      marketTypeId: 3,
      marketTypeName: 'Exclusão',
      riskModelCode: 'OPV2',
      riskExecutedDate: '2021-10-25T10:22:11',
      riskObs: 'Calculado pelo NOG',
      isSerpro: false,
      bidMaxFactor: 4.5,
      originType: 'FIF',
      abdicado: '1',
      recebiveis: '["BB1C4604-8709-F8B5-96B2-490D34F305C9"]',
      valorRecebiveis: '[32000]',
      days: 28,
      abdicarTitulo: '0         ',
      bidNetFactorOriginal: 5,
      cetPeriodo: 8.3333333,
      indOferta: 0,
    },
    drawee: [
      {
        agenda_id: 'BB1C4604-8709-F8B5-96B2-490D34F305C9',
        name: 'teste',
        corporate_name: 'teste',
        birth_date: null,
        person_type_id: 2,
        person_type_name: 'Pessoa Jurídica',
        country_identity: '11.111.111/1111-11',
        address_street: ' ',
        address_number: null,
        address_complement: null,
        address_district: null,
        address_city: null,
        address_state: 'SP',
        address_zip_code: null,
        office_phone: null,
        mobile_phone: null,
        email: null,
        site: null,
        note: null,
        contact_name: null,
        contact_email: null,
        contact_role: null,
      },
    ],
    business: {
      addressStreet: 'Rua Doutor Miguel Pierro',
      addressNumber: '011',
      addressDistrict: 'Cidade Universitária',
      addressCity: 'Campinas',
      addressState: 'SP',
      addressZipCode: '13083300',
      businessId: '3E9B6E4E-E029-45DB-851B-DD2A75549A36',
      originId: 1,
      originName: 'Site',
      typeId: 1,
      typeName: 'Cedente',
      statusId: 4,
      statusName: 'Aprovado',
      name: 'André Cedente FIF',
      corporateName: 'André Cedente FIF',
      personTypeId: 2,
      personTypeName: 'Pessoa Jurídiva',
      countryIdentity: '17.517.291/0001-92',
      code: '11708185',
      marketTypeId: 2,
      marketTypeName: 'Balcão',
    },
    buyer: { businessId: '54844820-8F81-472E-ADA1-FE61823D8946', floating: 0 },
    sacado: {
      name: 'teste',
      corporateName: 'teste',
      countryIdentity: '11.111.111/1111-11',
    },
    tags: [],
  },
  {
    invoice: {
      invoiceId: '77FA3E67-550B-DAFB-DC70-F4D7575BDF22',
      businessId: '340491AE-3FB9-48D5-8111-194181897DF2',
      typeId: 1,
      typeName: 'Serviços',
      riskId: 8,
      riskName: 'BB',
      statusId: 4,
      statusName: 'Aguardando Lance',
      number: '435',
      invoiceValue: 50000,
      minimumBid: 0,
      winningBidId: '00000000-0000-0000-0000-000000000000',
      operationNatureId: 0,
      issueDate: '2021-11-28T00:00:00',
      registerDate: '2021-10-25T13:02:14',
      installments: 1,
      tradingFloorId: 'B3585FAF-CF20-992A-D5DE-CAB7B46F78DA',
      userId: '04F72084-1D6C-D3A7-67F6-43039E068855',
      bidstatusId: 0,
      bidStatusName: '',
      bidFactor: 0,
      bidDiscountValue: 0,
      bidCommissionValue: 0,
      bidNetValue: 0,
      bidCommission: 0,
      bidAdValorem: 0,
      bidIofAmount: 0,
      bidIofTerm: 0,
      bidBankSlip: 0,
      bidOperation: 0,
      bidAnalysis: 0,
      bidPaymentTerm: 0,
      bidAverageTerm: 0,
      bidWeightedAverageTerm: 49,
      prazoMedioNog: 27,
      bidFactorValue: 0,
      bidAdValoremValue: 0,
      bidIofAmountValue: 0,
      bidIofTermValue: 0,
      bidBankSlipValue: 0,
      bidOperationValue: 0,
      bidAnalysisValue: 0,
      bidNetFactor: 4,
      maturitiesValue: 50000,
      id: 6839,
      statusDate: '2021-10-25T13:02:14',
      maturities: 2,
      maxOffer: 4.5,
      liquidationTypeId: 0,
      marketTypeId: 3,
      marketTypeName: 'Exclusão',
      riskModelCode: 'OPV2',
      riskExecutedDate: '2021-10-25T13:02:14',
      riskObs: 'Calculado pelo NOG',
      isSerpro: false,
      bidMaxFactor: 4.5,
      originType: 'AF',
      abdicado: '1',
      recebiveis: '["4B961AD7-1A22-7D2C-3E93-77ECF92E48CC"]',
      valorRecebiveis: '[50000]',
      days: 27,
      abdicarTitulo: '0         ',
      bidNetFactorOriginal: 4,
      cetPeriodo: 6.5333333,
      indOferta: 0,
    },
    drawee: [
      {
        agenda_id: '4B961AD7-1A22-7D2C-3E93-77ECF92E48CC',
        name: 'TESTE SACADO 2',
        corporate_name: 'TESTE SACADO 2',
        birth_date: null,
        person_type_id: 2,
        person_type_name: 'Pessoa Jurídica',
        country_identity: '02.749.513/0001-25',
        address_street: ' ',
        address_number: null,
        address_complement: null,
        address_district: null,
        address_city: null,
        address_state: 'GO',
        address_zip_code: null,
        office_phone: null,
        mobile_phone: null,
        email: null,
        site: null,
        note: null,
        contact_name: null,
        contact_email: null,
        contact_role: null,
      },
    ],
    business: {
      addressStreet: 'Avenida Lourenço Belloli',
      addressNumber: '700',
      addressComplement: 'sala 1',
      addressDistrict: 'Vila Menck',
      addressCity: 'Osasco',
      addressState: 'SP',
      addressZipCode: '06268-11',
      businessId: '340491AE-3FB9-48D5-8111-194181897DF2',
      originId: 10,
      originName: '2021',
      typeId: 1,
      typeName: 'Cedente',
      statusId: 4,
      statusName: 'Aprovado',
      name: 'PANTANAL LOGISTICA',
      corporateName: 'PANTANAL LOGISTICA E TRANSPORTES LTDA',
      personTypeId: 1,
      personTypeName: 'Cedente',
      countryIdentity: '08662661000275',
      marketTypeId: 2,
      marketTypeName: 'Balcão',
      partnerId: 'C9EC4134-ABE5-488B-8647-32C54CAE7416',
    },
    buyer: { businessId: '54844820-8F81-472E-ADA1-FE61823D8946', floating: 0 },
    sacado: {
      name: 'TESTE SACADO 2',
      corporateName: 'TESTE SACADO 2',
      countryIdentity: '02.749.513/0001-25',
    },
    tags: [],
  },
  {
    invoice: {
      invoiceId: 'C64BABC6-F8C9-5E57-05BE-1B23834BE56D',
      businessId: '340491AE-3FB9-48D5-8111-194181897DF2',
      typeId: 1,
      typeName: 'Serviços',
      riskId: 10,
      riskName: 'AB',
      statusId: 4,
      statusName: 'Aguardando Lance',
      number: '5432',
      invoiceValue: 10000,
      minimumBid: 0,
      winningBidId: '00000000-0000-0000-0000-000000000000',
      operationNatureId: 0,
      issueDate: '2021-11-28T00:00:00',
      registerDate: '2021-10-25T12:19:14',
      installments: 1,
      tradingFloorId: '2FDE1B2A-BAC4-B34C-4E10-EA932790CDB1',
      userId: '04F72084-1D6C-D3A7-67F6-43039E068855',
      bidstatusId: 0,
      bidStatusName: '',
      bidFactor: 0,
      bidDiscountValue: 0,
      bidCommissionValue: 0,
      bidNetValue: 0,
      bidCommission: 0,
      bidAdValorem: 0,
      bidIofAmount: 0,
      bidIofTerm: 0,
      bidBankSlip: 0,
      bidOperation: 0,
      bidAnalysis: 0,
      bidPaymentTerm: 0,
      bidAverageTerm: 0,
      bidWeightedAverageTerm: 49,
      prazoMedioNog: 27,
      bidFactorValue: 0,
      bidAdValoremValue: 0,
      bidIofAmountValue: 0,
      bidIofTermValue: 0,
      bidBankSlipValue: 0,
      bidOperationValue: 0,
      bidAnalysisValue: 0,
      bidNetFactor: 10,
      maturitiesValue: 10000,
      id: 6838,
      statusDate: '2021-10-25T12:19:14',
      maturities: 2,
      maxOffer: 6,
      liquidationTypeId: 0,
      marketTypeId: 3,
      marketTypeName: 'Exclusão',
      riskModelCode: 'OPV2',
      riskExecutedDate: '2021-10-25T12:19:14',
      riskObs: 'Calculado pelo NOG',
      isSerpro: false,
      bidMaxFactor: 6,
      originType: 'AF',
      abdicado: '1',
      recebiveis: '["208A3EA6-5054-2417-21FD-AA52702EFB55"]',
      valorRecebiveis: '[10000]',
      days: 27,
      abdicarTitulo: '0         ',
      bidNetFactorOriginal: 10,
      cetPeriodo: 16.3333333,
      indOferta: 0,
    },
    drawee: [
      {
        agenda_id: '208A3EA6-5054-2417-21FD-AA52702EFB55',
        name: 'TESTE SACADO',
        corporate_name: 'TESTE SACADO',
        birth_date: null,
        person_type_id: 2,
        person_type_name: 'Pessoa Jurídica',
        country_identity: '00.000.000/0000-00',
        address_street: ' ',
        address_number: null,
        address_complement: null,
        address_district: null,
        address_city: null,
        address_state: null,
        address_zip_code: null,
        office_phone: null,
        mobile_phone: null,
        email: null,
        site: null,
        note: null,
        contact_name: null,
        contact_email: null,
        contact_role: null,
      },
    ],
    business: {
      addressStreet: 'Avenida Lourenço Belloli',
      addressNumber: '700',
      addressComplement: 'sala 1',
      addressDistrict: 'Vila Menck',
      addressCity: 'Osasco',
      addressState: 'SP',
      addressZipCode: '06268-11',
      businessId: '340491AE-3FB9-48D5-8111-194181897DF2',
      originId: 10,
      originName: '2021',
      typeId: 1,
      typeName: 'Cedente',
      statusId: 4,
      statusName: 'Aprovado',
      name: 'PANTANAL LOGISTICA',
      corporateName: 'PANTANAL LOGISTICA E TRANSPORTES LTDA',
      personTypeId: 1,
      personTypeName: 'Cedente',
      countryIdentity: '08662661000275',
      marketTypeId: 2,
      marketTypeName: 'Balcão',
      partnerId: 'C9EC4134-ABE5-488B-8647-32C54CAE7416',
    },
    buyer: { businessId: '54844820-8F81-472E-ADA1-FE61823D8946', floating: 0 },
    sacado: {
      name: 'TESTE SACADO',
      corporateName: 'TESTE SACADO',
      countryIdentity: '00.000.000/0000-00',
    },
    tags: [],
  },
  {
    invoice: {
      invoiceId: '85FA3EDA-9DE6-3944-5813-64C3E3E0729E',
      businessId: '340491AE-3FB9-48D5-8111-194181897DF2',
      typeId: 1,
      typeName: 'Serviços',
      riskId: 10,
      riskName: 'AB',
      statusId: 9,
      statusName: 'Aceitar Lance',
      number: '98B',
      invoiceValue: 18178,
      minimumBid: 0,
      winningBidId: 'AAA052E0-DC09-4A04-9C70-65B8AD59C48D',
      operationNatureId: 0,
      issueDate: '2021-12-11T03:00:00',
      registerDate: '2021-11-12T16:39:56',
      installments: 1,
      tradingFloorId: 'A09F583C-D375-505D-D002-16559B7FB073',
      userId: '04F72084-1D6C-D3A7-67F6-43039E068855',
      bidId: 'AAA052E0-DC09-4A04-9C70-65B8AD59C48D',
      bidBusinessId: 'C590B30A-FDA7-4A5F-881C-90D5DE52C37D',
      bidUserId: 'A8DECE92-748F-4B9F-A5E7-07B2D2C450B6',
      bidUserName: 'André Financiador AF',
      bidstatusId: 0,
      bidStatusName: '',
      bidStatusDate: '2021-11-12T16:44:31.220',
      bidFactor: 3.98,
      bidDiscountValue: 877.9458,
      bidCommissionValue: 87.8634,
      bidNetValue: 17300.0542,
      bidDate: '2021-11-12T16:44:31.220',
      bidCommission: 0.5,
      bidAdValorem: 0,
      bidIofAmount: 0.38,
      bidIofTerm: 0.0041,
      bidBankSlip: 0,
      bidOperation: 0,
      bidAnalysis: 0,
      bidPaymentTerm: 0,
      bidAverageTerm: 29,
      bidWeightedAverageTerm: 30,
      prazoMedioNog: 25,
      bidFactorValue: 699.3924,
      bidAdValoremValue: 0,
      bidIofAmountValue: 69.0764,
      bidIofTermValue: 21.6136,
      bidBankSlipValue: 0,
      bidOperationValue: 0,
      bidAnalysisValue: 0,
      bidNetFactor: 4.9962,
      maturitiesValue: 18178,
      id: 6869,
      statusDate: '2021-11-12T16:44:31.220',
      averageTerm: 29,
      maturities: 1,
      maxOffer: 15,
      liquidationTypeId: 0,
      marketTypeId: 1,
      marketTypeName: 'Leilão',
      riskModelCode: 'OPV2',
      riskExecutedDate: '2021-11-12T16:39:56',
      riskObs: 'Calculado pelo NOG',
      isSerpro: false,
      bidMaxFactor: 15,
      originType: 'AF ',
      abdicado: '0',
      recebiveis: '["8189AC5B-539B-28F7-BEF0-8C7F9848A9CF"]',
      valorRecebiveis: '[18178]',
      days: 25,
      abdicarTitulo: '0         ',
      bidNetFactorOriginal: 4.9962,
      cetPeriodo: 4.9962,
      indOferta: 0,
    },
    drawee: [
      {
        agenda_id: '8189AC5B-539B-28F7-BEF0-8C7F9848A9CF',
        name: 'TESTE OFERTA ERRADA',
        corporate_name: 'TESTE OFERTA ERRADA',
        birth_date: null,
        person_type_id: 2,
        person_type_name: 'Pessoa Jurídica',
        country_identity: '00.000.000/0000-00',
        address_street: ' ',
        address_number: null,
        address_complement: null,
        address_district: null,
        address_city: null,
        address_state: null,
        address_zip_code: null,
        office_phone: null,
        mobile_phone: null,
        email: null,
        site: null,
        note: null,
        contact_name: null,
        contact_email: null,
        contact_role: null,
      },
    ],
    business: {
      addressStreet: 'Avenida Lourenço Belloli',
      addressNumber: '700',
      addressComplement: 'sala 1',
      addressDistrict: 'Vila Menck',
      addressCity: 'Osasco',
      addressState: 'SP',
      addressZipCode: '06268-11',
      businessId: '340491AE-3FB9-48D5-8111-194181897DF2',
      originId: 10,
      originName: '2021',
      typeId: 1,
      typeName: 'Cedente',
      statusId: 4,
      statusName: 'Aprovado',
      name: 'PANTANAL LOGISTICA',
      corporateName: 'PANTANAL LOGISTICA E TRANSPORTES LTDA',
      personTypeId: 1,
      personTypeName: 'Cedente',
      countryIdentity: '08662661000275',
      marketTypeId: 2,
      marketTypeName: 'Balcão',
      partnerId: 'C9EC4134-ABE5-488B-8647-32C54CAE7416',
    },
    buyer: { businessId: '54844820-8F81-472E-ADA1-FE61823D8946', floating: 0 },
    sacado: {
      name: 'TESTE OFERTA ERRADA',
      corporateName: 'TESTE OFERTA ERRADA',
      countryIdentity: '00.000.000/0000-00',
    },
    tags: [],
  },
  {
    invoice: {
      invoiceId: '3F44D695-2DBE-A3AC-E9E0-BD1F2C020213',
      businessId: '22C95670-4BBD-43CA-B5FF-98CEDCA1ABE9',
      typeId: 1,
      typeName: 'Serviços',
      riskId: 10,
      riskName: 'AB',
      statusId: 9,
      statusName: 'Aceitar Lance',
      number: '70',
      invoiceValue: 70000,
      minimumBid: 0,
      winningBidId: '687A1036-5D88-449D-AF19-9DE18304FFE0',
      operationNatureId: 0,
      issueDate: '2021-11-26T06:00:00',
      registerDate: '2021-10-26T14:38:35',
      installments: 1,
      tradingFloorId: '7614181A-34F7-83D6-81C4-E133D0C00B08',
      userId: 'F058F3CA-2ACD-4B56-8DA7-DF179F3E68C3',
      bidId: '687A1036-5D88-449D-AF19-9DE18304FFE0',
      bidBusinessId: '8A9896B1-13B6-4ADB-A4E3-0392E10822C6',
      bidUserId: '52F03AC0-D447-51D0-81E6-9B35835DB3CC',
      bidUserName: 'TESTE',
      bidstatusId: 0,
      bidStatusName: '',
      bidStatusDate: '2021-10-28T22:02:57.947',
      bidFactor: 0.1,
      bidDiscountValue: 845.986,
      bidCommissionValue: 611.655,
      bidNetValue: 69154.014,
      bidDate: '2021-10-28T22:02:57.947',
      bidCommission: 0.5,
      bidAdValorem: 0,
      bidIofAmount: 0,
      bidIofTerm: 0,
      bidBankSlip: 6,
      bidOperation: 0,
      bidAnalysis: 50,
      bidPaymentTerm: 2,
      bidAverageTerm: 52,
      bidWeightedAverageTerm: 51,
      prazoMedioNog: 31,
      bidFactorValue: 122.331,
      bidAdValoremValue: 0,
      bidIofAmountValue: 0,
      bidIofTermValue: 0,
      bidBankSlipValue: 12,
      bidOperationValue: 0,
      bidAnalysisValue: 100,
      bidNetFactor: 0.7189,
      maturitiesValue: 70000,
      id: 6851,
      statusDate: '2021-10-28T22:02:57.947',
      averageTerm: 52,
      maturities: 2,
      maxOffer: 4,
      liquidationTypeId: 0,
      marketTypeId: 1,
      marketTypeName: 'Leilão',
      riskModelCode: 'OPV2',
      riskExecutedDate: '2021-10-26T14:38:35',
      riskObs: 'Calculado pelo NOG',
      isSerpro: false,
      bidMaxFactor: 4,
      originType: 'AF',
      abdicado: '0',
      recebiveis: '["F4091F60-15DC-6C70-6DB8-10BF253A5A6E"]',
      valorRecebiveis: '[70000]',
      days: 31,
      abdicarTitulo: '0         ',
      bidNetFactorOriginal: 0.7189,
      cetPeriodo: 1.22213,
      indOferta: 0,
    },
    drawee: [
      {
        agenda_id: 'F4091F60-15DC-6C70-6DB8-10BF253A5A6E',
        name: 'teste',
        corporate_name: 'teste',
        birth_date: null,
        person_type_id: 2,
        person_type_name: 'Pessoa Jurídica',
        country_identity: '11.111.111/1111-11',
        address_street: ' ',
        address_number: null,
        address_complement: null,
        address_district: null,
        address_city: null,
        address_state: null,
        address_zip_code: null,
        office_phone: null,
        mobile_phone: null,
        email: null,
        site: null,
        note: null,
        contact_name: null,
        contact_email: null,
        contact_role: null,
      },
    ],
    business: {
      addressStreet: 'Rua Giuseppe Máximo Scolfaro',
      addressNumber: '01',
      addressDistrict: 'Cidade Universitária',
      addressCity: 'Campinas',
      addressState: 'SP',
      addressZipCode: '13083100',
      businessId: '22C95670-4BBD-43CA-B5FF-98CEDCA1ABE9',
      originId: 1,
      originName: 'Site',
      typeId: 1,
      typeName: 'Cedente',
      statusId: 4,
      statusName: 'Aprovado',
      name: 'André Cedente AF',
      corporateName: 'André Cedente AF',
      personTypeId: 2,
      personTypeName: 'Pessoa Jurídiva',
      countryIdentity: '17237845000106',
      code: 'f3dfc8c1',
      marketTypeId: 1,
      marketTypeName: 'Leilão',
      originType: 'AF',
    },
    buyer: { businessId: '54844820-8F81-472E-ADA1-FE61823D8946', floating: 0 },
    sacado: {
      name: 'teste',
      corporateName: 'teste',
      countryIdentity: '11.111.111/1111-11',
    },
    tags: [],
  },
  {
    invoice: {
      invoiceId: 'A60B98D0-A661-DF05-9B5A-DD19A1BC1050',
      businessId: '22C95670-4BBD-43CA-B5FF-98CEDCA1ABE9',
      typeId: 1,
      typeName: 'Serviços',
      riskId: 11,
      riskName: 'AA',
      statusId: 9,
      statusName: 'Aceitar Lance',
      number: '1601',
      invoiceValue: 10000,
      minimumBid: 0,
      winningBidId: 'CB51681D-110F-4472-B78E-B93EE912963C',
      operationNatureId: 0,
      issueDate: '2021-12-03T06:00:00',
      registerDate: '2021-10-26T08:42:52',
      installments: 1,
      tradingFloorId: '68AC7658-F37D-E209-F45B-D6E35D716B19',
      userId: 'F058F3CA-2ACD-4B56-8DA7-DF179F3E68C3',
      bidId: 'CB51681D-110F-4472-B78E-B93EE912963C',
      bidBusinessId: 'C9EC4134-ABE5-488B-8647-32C54CAE7416',
      bidUserId: '5BAE0EC0-EA31-4457-BDD6-6375CA51A163',
      bidUserName: 'Frederico Casas Figueiredo',
      bidstatusId: 0,
      bidStatusName: '',
      bidStatusDate: '2021-10-29T16:43:58.657',
      bidFactor: 0.2,
      bidDiscountValue: 81.669,
      bidCommissionValue: 58.335,
      bidNetValue: 9918.331,
      bidDate: '2021-10-29T16:43:58.657',
      bidCommission: 0.5,
      bidAdValorem: 0,
      bidIofAmount: 0,
      bidIofTerm: 0,
      bidBankSlip: 0,
      bidOperation: 0,
      bidAnalysis: 0,
      bidPaymentTerm: 0,
      bidAverageTerm: 35,
      bidWeightedAverageTerm: 36,
      prazoMedioNog: 17,
      bidFactorValue: 23.334,
      bidAdValoremValue: 0,
      bidIofAmountValue: 0,
      bidIofTermValue: 0,
      bidBankSlipValue: 0,
      bidOperationValue: 0,
      bidAnalysisValue: 0,
      bidNetFactor: 0.7,
      maturitiesValue: 10000,
      id: 6846,
      statusDate: '2021-10-29T16:43:58.657',
      averageTerm: 35,
      maturities: 1,
      maxOffer: 6,
      liquidationTypeId: 0,
      marketTypeId: 1,
      marketTypeName: 'Leilão',
      riskModelCode: 'OPV2',
      riskExecutedDate: '2021-10-26T08:42:52',
      riskObs: 'Calculado pelo NOG',
      isSerpro: false,
      bidMaxFactor: 6,
      originType: 'AF',
      abdicado: '0',
      recebiveis: '["4E0F3256-5D99-403E-519E-5E02378B8A09"]',
      valorRecebiveis: '[10000]',
      days: 17,
      abdicarTitulo: '0         ',
      bidNetFactorOriginal: 0.7,
      cetPeriodo: 0.84,
      indOferta: 0,
    },
    drawee: [
      {
        agenda_id: '4E0F3256-5D99-403E-519E-5E02378B8A09',
        name: 'Eduardo Henrique Bortolotti',
        corporate_name: 'Eduardo Henrique Bortolotti',
        birth_date: null,
        person_type_id: 2,
        person_type_name: 'Pessoa Jurídica',
        country_identity: '33.333.333/3333-33',
        address_street: 'Rua Lamartine Babo',
        address_number: '229',
        address_complement: null,
        address_district: null,
        address_city: 'Sumaré',
        address_state: null,
        address_zip_code: '13171-750',
        office_phone: '(55)197415-3452',
        mobile_phone: null,
        email: null,
        site: null,
        note: null,
        contact_name: null,
        contact_email: null,
        contact_role: null,
      },
    ],
    business: {
      addressStreet: 'Rua Giuseppe Máximo Scolfaro',
      addressNumber: '01',
      addressDistrict: 'Cidade Universitária',
      addressCity: 'Campinas',
      addressState: 'SP',
      addressZipCode: '13083100',
      businessId: '22C95670-4BBD-43CA-B5FF-98CEDCA1ABE9',
      originId: 1,
      originName: 'Site',
      typeId: 1,
      typeName: 'Cedente',
      statusId: 4,
      statusName: 'Aprovado',
      name: 'André Cedente AF',
      corporateName: 'André Cedente AF',
      personTypeId: 2,
      personTypeName: 'Pessoa Jurídiva',
      countryIdentity: '17237845000106',
      code: 'f3dfc8c1',
      marketTypeId: 1,
      marketTypeName: 'Leilão',
      originType: 'AF',
    },
    buyer: { businessId: '54844820-8F81-472E-ADA1-FE61823D8946', floating: 0 },
    sacado: {
      name: 'Eduardo Henrique Bortolotti',
      corporateName: 'Eduardo Henrique Bortolotti',
      countryIdentity: '33.333.333/3333-33',
    },
    tags: [],
  },
  {
    invoice: {
      invoiceId: '494E0424-7996-79E7-93C2-8B391046E5E7',
      businessId: '693493FF-B60C-4E79-9C26-6FFA32382DBA',
      typeId: 1,
      typeName: 'Serviços',
      riskId: 10,
      riskName: 'AB',
      statusId: 9,
      statusName: 'Aceitar Lance',
      number: '10',
      invoiceValue: 60000,
      minimumBid: 0,
      winningBidId: 'B0D64802-B2CB-4BC3-BEC6-4D4A3E486101',
      operationNatureId: 0,
      issueDate: '2021-11-12T00:00:00',
      registerDate: '2021-10-14T14:35:36',
      installments: 1,
      tradingFloorId: 'E19A9B40-289A-85BD-1852-6BC59D9047D1',
      userId: '89C8B720-D755-4336-A60C-C90DE921D068',
      bidId: 'B0D64802-B2CB-4BC3-BEC6-4D4A3E486101',
      bidBusinessId: 'C9EC4134-ABE5-488B-8647-32C54CAE7416',
      bidUserId: '5BAE0EC0-EA31-4457-BDD6-6375CA51A163',
      bidUserName: 'Frederico Casas Figueiredo',
      bidstatusId: 0,
      bidStatusName: '',
      bidStatusDate: '2021-10-21T18:03:40.890',
      bidFactor: 2.35,
      bidDiscountValue: 3562.5,
      bidCommissionValue: 625,
      bidNetValue: 56437.5,
      bidDate: '2021-10-21T18:03:40.890',
      bidCommission: 0.5,
      bidAdValorem: 0,
      bidIofAmount: 0,
      bidIofTerm: 0,
      bidBankSlip: 0,
      bidOperation: 0,
      bidAnalysis: 0,
      bidPaymentTerm: 0,
      bidAverageTerm: 62,
      bidWeightedAverageTerm: 63,
      prazoMedioNog: 37,
      bidFactorValue: 2937.5,
      bidAdValoremValue: 0,
      bidIofAmountValue: 0,
      bidIofTermValue: 0,
      bidBankSlipValue: 0,
      bidOperationValue: 0,
      bidAnalysisValue: 0,
      bidNetFactor: 2.85,
      maturitiesValue: 60000,
      id: 6827,
      statusDate: '2021-10-21T18:03:40.890',
      averageTerm: 62,
      maturities: 3,
      maxOffer: 4,
      liquidationTypeId: 0,
      marketTypeId: 1,
      marketTypeName: 'Leilão',
      riskModelCode: 'OPV2',
      riskExecutedDate: '2021-10-14T14:35:36',
      riskObs: 'Calculado pelo NOG',
      isSerpro: false,
      bidMaxFactor: 4,
      originType: 'FIF',
      abdicado: '0',
      recebiveis: '["3B19B025-5916-3527-1246-04E94DA449DE"]',
      valorRecebiveis: '[60000]',
      days: 37,
      abdicarTitulo: '0         ',
      bidNetFactorOriginal: 2.85,
      cetPeriodo: 5.985,
      indOferta: 0,
    },
    drawee: [
      {
        agenda_id: '3B19B025-5916-3527-1246-04E94DA449DE',
        name: 'Qualquer Um',
        corporate_name: 'Qualquer Um',
        birth_date: null,
        person_type_id: 2,
        person_type_name: 'Pessoa Jurídica',
        country_identity: '11.111.111/1111-11',
        address_street: ' ',
        address_number: null,
        address_complement: null,
        address_district: null,
        address_city: null,
        address_state: 'CE',
        address_zip_code: null,
        office_phone: null,
        mobile_phone: null,
        email: null,
        site: null,
        note: null,
        contact_name: null,
        contact_email: null,
        contact_role: null,
      },
    ],
    business: {
      addressStreet: 'Rua Moysés Lucarelli',
      addressNumber: '1',
      addressDistrict: 'Cidade Universitária',
      addressCity: 'Campinas',
      addressState: 'SP',
      addressZipCode: '13083500',
      businessId: '693493FF-B60C-4E79-9C26-6FFA32382DBA',
      originId: 1,
      originName: 'Site',
      typeId: 1,
      typeName: 'Cedente',
      statusId: 4,
      statusName: 'Aprovado',
      name: 'Cedente FIF 2',
      corporateName: 'Cedente FIF 2',
      personTypeId: 2,
      personTypeName: 'Pessoa Jurídiva',
      countryIdentity: '47.994.705/0001-35',
      code: 'b351479f',
      marketTypeId: 1,
      marketTypeName: 'Leilão',
    },
    buyer: { businessId: '54844820-8F81-472E-ADA1-FE61823D8946', floating: 0 },
    sacado: {
      name: 'Qualquer Um',
      corporateName: 'Qualquer Um',
      countryIdentity: '11.111.111/1111-11',
    },
    tags: [],
  },
  {
    invoice: {
      invoiceId: '86DFF0A7-1BD3-75BE-C2F3-0C40F1CF8D51',
      businessId: '22C95670-4BBD-43CA-B5FF-98CEDCA1ABE9',
      typeId: 1,
      typeName: 'Serviços',
      riskId: 11,
      riskName: 'AA',
      statusId: 9,
      statusName: 'Aceitar Lance',
      number: '1234',
      invoiceValue: 1000,
      minimumBid: 0,
      winningBidId: 'A2C28D31-D137-475E-B808-8DB4FAEBC865',
      operationNatureId: 0,
      issueDate: '2021-11-24T00:00:00',
      registerDate: '2021-10-25T20:54:16',
      installments: 1,
      tradingFloorId: '1BF1BB4A-463D-3B66-9CEC-D80F119799A7',
      userId: 'F058F3CA-2ACD-4B56-8DA7-DF179F3E68C3',
      bidId: 'A2C28D31-D137-475E-B808-8DB4FAEBC865',
      bidBusinessId: '4B810B3E-0C42-4889-A1EB-5EBB69447500',
      bidUserId: '0F91E4C2-1D2F-78CE-E85F-B9272F901069',
      bidUserName: 'nivea',
      bidstatusId: 0,
      bidStatusName: '',
      bidStatusDate: '2021-11-09T18:47:58.013',
      bidFactor: 2.15,
      bidDiscountValue: 27.9,
      bidCommissionValue: 15,
      bidNetValue: 972.1,
      bidDate: '2021-11-09T18:47:58.013',
      bidCommission: 0.5,
      bidAdValorem: 0,
      bidIofAmount: 0,
      bidIofTerm: 0,
      bidBankSlip: 0,
      bidOperation: 0,
      bidAnalysis: 0,
      bidPaymentTerm: 3,
      bidAverageTerm: 18,
      bidWeightedAverageTerm: 16,
      prazoMedioNog: 8,
      bidFactorValue: 12.9,
      bidAdValoremValue: 0,
      bidIofAmountValue: 0,
      bidIofTermValue: 0,
      bidBankSlipValue: 0,
      bidOperationValue: 0,
      bidAnalysisValue: 0,
      bidNetFactor: 5.58,
      maturitiesValue: 1000,
      id: 6844,
      statusDate: '2021-11-09T18:47:58.013',
      averageTerm: 18,
      maturities: 1,
      maxOffer: 6,
      liquidationTypeId: 0,
      marketTypeId: 1,
      marketTypeName: 'Leilão',
      riskModelCode: 'OPV2',
      riskExecutedDate: '2021-10-25T20:54:16',
      riskObs: 'Calculado pelo NOG',
      isSerpro: false,
      bidMaxFactor: 6,
      originType: 'AF',
      abdicado: '0',
      recebiveis: '["3DA5EBDB-0EA2-5EDC-B2C9-0C7892BEBDE2"]',
      valorRecebiveis: '[1000]',
      days: 8,
      abdicarTitulo: '0         ',
      bidNetFactorOriginal: 5.58,
      cetPeriodo: 2.976,
      indOferta: 0,
    },
    drawee: [
      {
        agenda_id: '3DA5EBDB-0EA2-5EDC-B2C9-0C7892BEBDE2',
        name: 'Eduardo Henrique Bortolotti',
        corporate_name: 'Eduardo Henrique Bortolotti',
        birth_date: null,
        person_type_id: 2,
        person_type_name: 'Pessoa Jurídica',
        country_identity: '33.333.333/3333-33',
        address_street: 'Rua Lamartine Babo',
        address_number: '229',
        address_complement: null,
        address_district: 'pq versailles',
        address_city: 'Sumaré',
        address_state: 'SP',
        address_zip_code: '13171-750',
        office_phone: '(55)197415-3452',
        mobile_phone: null,
        email: null,
        site: null,
        note: null,
        contact_name: 'TESTE EDUARDO',
        contact_email: null,
        contact_role: null,
      },
    ],
    business: {
      addressStreet: 'Rua Giuseppe Máximo Scolfaro',
      addressNumber: '01',
      addressDistrict: 'Cidade Universitária',
      addressCity: 'Campinas',
      addressState: 'SP',
      addressZipCode: '13083100',
      businessId: '22C95670-4BBD-43CA-B5FF-98CEDCA1ABE9',
      originId: 1,
      originName: 'Site',
      typeId: 1,
      typeName: 'Cedente',
      statusId: 4,
      statusName: 'Aprovado',
      name: 'André Cedente AF',
      corporateName: 'André Cedente AF',
      personTypeId: 2,
      personTypeName: 'Pessoa Jurídiva',
      countryIdentity: '17237845000106',
      code: 'f3dfc8c1',
      marketTypeId: 1,
      marketTypeName: 'Leilão',
      originType: 'AF',
    },
    buyer: { businessId: '54844820-8F81-472E-ADA1-FE61823D8946', floating: 0 },
    sacado: {
      name: 'Eduardo Henrique Bortolotti',
      corporateName: 'Eduardo Henrique Bortolotti',
      countryIdentity: '33.333.333/3333-33',
    },
    tags: [],
  },
  {
    invoice: {
      invoiceId: '9912FA0F-2F71-6F2F-4E77-4B31352DEFDC',
      businessId: '22C95670-4BBD-43CA-B5FF-98CEDCA1ABE9',
      typeId: 1,
      typeName: 'Serviços',
      riskId: 10,
      riskName: 'AB',
      statusId: 9,
      statusName: 'Aceitar Lance',
      number: '19',
      invoiceValue: 10000,
      minimumBid: 0,
      winningBidId: '38FC45D1-2E05-41B2-A7A5-62D8354B1C61',
      operationNatureId: 0,
      issueDate: '2021-12-01T06:00:00',
      registerDate: '2021-11-01T10:47:10',
      installments: 1,
      tradingFloorId: '6A028CE6-8213-5A97-D02C-B324926361F4',
      userId: 'F058F3CA-2ACD-4B56-8DA7-DF179F3E68C3',
      bidId: '38FC45D1-2E05-41B2-A7A5-62D8354B1C61',
      bidBusinessId: 'C9EC4134-ABE5-488B-8647-32C54CAE7416',
      bidUserId: '5BAE0EC0-EA31-4457-BDD6-6375CA51A163',
      bidUserName: 'Frederico Casas Figueiredo',
      bidstatusId: 0,
      bidStatusName: '',
      bidStatusDate: '2021-11-12T16:58:33.957',
      bidFactor: 5.45,
      bidDiscountValue: 376.8135,
      bidCommissionValue: 31.665,
      bidNetValue: 9623.1865,
      bidDate: '2021-11-12T16:58:33.957',
      bidCommission: 0.5,
      bidAdValorem: 0,
      bidIofAmount: 0,
      bidIofTerm: 0,
      bidBankSlip: 0,
      bidOperation: 0,
      bidAnalysis: 0,
      bidPaymentTerm: 0,
      bidAverageTerm: 19,
      bidWeightedAverageTerm: 20,
      prazoMedioNog: 15,
      bidFactorValue: 345.1485,
      bidAdValoremValue: 0,
      bidIofAmountValue: 0,
      bidIofTermValue: 0,
      bidBankSlipValue: 0,
      bidOperationValue: 0,
      bidAnalysisValue: 0,
      bidNetFactor: 5.9496,
      maturitiesValue: 10000,
      id: 6865,
      statusDate: '2021-11-12T16:58:33.957',
      averageTerm: 19,
      maturities: 1,
      maxOffer: 6,
      liquidationTypeId: 0,
      marketTypeId: 1,
      marketTypeName: 'Leilão',
      riskModelCode: 'OPV2',
      riskExecutedDate: '2021-11-01T10:47:10',
      riskObs: 'Calculado pelo NOG',
      isSerpro: false,
      bidMaxFactor: 6,
      originType: 'AF',
      abdicado: '0',
      recebiveis: '["19ED2CA0-514F-3950-C5E8-D4F5643D1CEE"]',
      valorRecebiveis: '[10000]',
      days: 15,
      abdicarTitulo: '0         ',
      bidNetFactorOriginal: 5.9496,
      cetPeriodo: 3.9664,
      indOferta: 0,
    },
    drawee: [
      {
        agenda_id: '19ED2CA0-514F-3950-C5E8-D4F5643D1CEE',
        name: 'teste',
        corporate_name: 'teste',
        birth_date: null,
        person_type_id: 2,
        person_type_name: 'Pessoa Jurídica',
        country_identity: '11.111.111/1111-11',
        address_street: ' ',
        address_number: null,
        address_complement: null,
        address_district: null,
        address_city: null,
        address_state: null,
        address_zip_code: null,
        office_phone: null,
        mobile_phone: null,
        email: null,
        site: null,
        note: null,
        contact_name: null,
        contact_email: null,
        contact_role: null,
      },
    ],
    business: {
      addressStreet: 'Rua Giuseppe Máximo Scolfaro',
      addressNumber: '01',
      addressDistrict: 'Cidade Universitária',
      addressCity: 'Campinas',
      addressState: 'SP',
      addressZipCode: '13083100',
      businessId: '22C95670-4BBD-43CA-B5FF-98CEDCA1ABE9',
      originId: 1,
      originName: 'Site',
      typeId: 1,
      typeName: 'Cedente',
      statusId: 4,
      statusName: 'Aprovado',
      name: 'André Cedente AF',
      corporateName: 'André Cedente AF',
      personTypeId: 2,
      personTypeName: 'Pessoa Jurídiva',
      countryIdentity: '17237845000106',
      code: 'f3dfc8c1',
      marketTypeId: 1,
      marketTypeName: 'Leilão',
      originType: 'AF',
    },
    buyer: { businessId: '54844820-8F81-472E-ADA1-FE61823D8946', floating: 0 },
    sacado: {
      name: 'teste',
      corporateName: 'teste',
      countryIdentity: '11.111.111/1111-11',
    },
    tags: [],
  },
];
