import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./invoice.scss";

import {
  Tooltip,
  TextField,
  LinearProgress,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/lab/Pagination";

import clsx from "clsx";

import { ConfirmButton } from "components/Button";
import EnhancedTableHead from "components/EnhancedTableHead";
import { ReactIf, ReactThen } from "components/ReactIf";

import formatReal from "utils/formatReal";

import {
  getTableTitulosFilter,
  getTableTitulos,
  getBuyerTableTitulosFilter,
} from "services/listagem";

import { useAuth } from "contexts/auth";
import { usePermission } from "contexts/permission";
import { useToast } from "contexts/toast";

import { recebiveisStyle } from "styles/material/recebiveis";

import {
  headCells,
  status,
  marketTypes,
  statusFinanciador,
  risks,
} from "./items";

const useStyles = makeStyles({
  paper: {
    background: "white",
    width: "105%",
    boxShadow: "none",
    border: "1px solid #99999966",
    maxWidth: "100%",
    marginLeft: "-30px",
  },
  pagination: {
    background: "white",
    boxShadow: "none",
    border: "1px solid #99999966",
    marginLeft: "-30px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    width: "105%",
    maxWidth: "100%",
    marginTop: "70%",
    position: "absolute",
    bottom: "-75px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

export default function LivePreviewExample() {
  const classes = useStyles();
  const classesSelect = recebiveisStyle();
  const { toast } = useToast();
  const history = useHistory();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { user, isAdmin, isFinanciador, isCedente, userOrigin } = useAuth();
  const { setSellerInvoicesIds } = usePermission();

  const [invoices, setInvoices] = useState([]);
  const [filter, setFilter] = useState({});
  const [countElements, setCountElements] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [statusIds, setStatusId] = useState("");
  const [risk, setRisk] = useState("");
  const [marketType, setMarketType] = useState("");

  useEffect(() => {
    if (Object.keys(invoices).length === 0) setIsLoading(true);

    async function getItems() {
      try {
        const response =
          user.userTypeId === 2
            ? await getBuyerTableTitulosFilter(user.businessId)
            : await getTableTitulosFilter(
                user.userTypeId,
                user.businessId,
                page,
                userOrigin
              );

        const parsedInvoices =
          user.userTypeId == 2
            ? response.data
            : response.data.invoices.map((invoice) => ({
                ...invoice,
                sellerName: invoice.invoiceSeller?.name,
                draweeName: invoice?.agendaInvoices[0]?.agenda?.drawee?.name,
                busubessName: invoice.invoiceBuyer?.invoiceBuyerBusiness?.name,
              }));
        setInvoices(parsedInvoices);
        const count = response.data.count / 10;
        setCountElements(
          count === parseInt(count) ? parseInt(count) : parseInt(count) + 1
        );
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error("Ocorreu um erro ao buscar dados.");
      }
    }

    getItems();
  }, []);

  useEffect(() => {
    if (!isAdmin()) {
      setSellerInvoicesIds(invoices.map((invoice) => invoice.invoiceId));
    }
  }, [invoices]);

  function limparFiltro() {
    setRisk("");
    setMarketType("");
    setStatusId("");
    setFilter({
      ...filter,
      invoiceIdNumerico: "",
      number: "",
      seller: "",
      drawee: "",
      buyer: "",
    });
    setInvoices(invoices);
  }

  async function filterSearch(e, page, orderBy, ASC) {
    try {
      setIsLoading(true);
      e.preventDefault();
      setFilter({
        ...filter,
        statusIds,
        risk,
        marketType,
      });
      const sendFilter = {
        ...filter,
        statusId: statusIds,
        riskId:
          risk === ""
            ? ""
            : risks.find((item) => item.riskName === risk)?.riskId,
        marketTypeId: marketType,
      };
      let filterParams = "";
      Object.keys(sendFilter).forEach((key) => {
        if (sendFilter[key] === "") return;
        filterParams += `${key}=${sendFilter[key]}&`;
      });

      const response = await getTableTitulos(
        user.userTypeId,
        user.businessId,
        ASC,
        page,
        filterParams,
        orderBy,
        userOrigin
      );

      const parsedInvoices = response.data.invoices.map((invoice) => ({
        ...invoice,
        sellerName: invoice.invoiceSeller?.name,
        draweeName: invoice?.agendaInvoices[0]?.agenda?.drawee?.name,
        busubessName: invoice.invoiceBuyer?.invoiceBuyerBusiness?.name,
      }));
      setInvoices(parsedInvoices);
      const count = response.data.count / 10;
      setCountElements(
        count === parseInt(count) ? parseInt(count) : parseInt(count) + 1
      );
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event, newPage) => {
    filterSearch(event, newPage - 1);
  };
  const handlerChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="pageInvoice">
      <Paper
        elevation={3}
        className={clsx("app-page-title")}
        style={{
          // height: '135px',
          maxWidth: "100%",
          display: "flow-root",
        }}
      >
        <div>
          <div style={{ marginTop: "-25px" }}>
            <div className="app-page-title--heading">
              <h1 style={{ fontFamily: "Poppins" }}>
                {isFinanciador() ? "Meus Títulos" : "Títulos"}
              </h1>
            </div>
          </div>
        </div>
        <div
          className="searchForm"
          style={{
            boxShadow: "none",
            width: "100%",
            height: "245px",
            display: "flex",
            marginBottom: "-25px",
          }}
        >
          <div className="item">
            <TextField
              fullWidth
              className="text-field-select"
              id="outlined-basic"
              label="ID"
              name="invoiceIdNumerico"
              value={filter.invoiceIdNumerico}
              onChange={handlerChange}
              variant="outlined"
              onKeyPressCapture={(e) => {
                if (e.key == "Enter") filterSearch(e);
              }}
            />
            <TextField
              fullWidth
              className="text-field-select"
              id="outlined-basic"
              label="Nota Fiscal"
              name="number"
              value={filter.number}
              onChange={handlerChange}
              variant="outlined"
              onKeyPressCapture={(e) => {
                if (e.key == "Enter") filterSearch(e);
              }}
            />
            <div className="containerDatePicker mr-2">
              <TextField
                className="text-field-select"
                id="demo-simple-select"
                label="Status"
                fullWidth
                select
                value={statusIds}
                onChange={(e) => {
                  setStatusId(e.target.value === "" ? "" : e.target.value);
                }}
                variant="outlined"
              >
                <MenuItem disabled value="">
                  Selecione uma opção
                </MenuItem>
                {isFinanciador()
                  ? statusFinanciador.map((element) => (
                      <MenuItem
                        classes={{ root: classesSelect.SELECT }}
                        value={element.statusId}
                      >
                        {element.statusName}
                      </MenuItem>
                    ))
                  : status.map((element) => (
                      <MenuItem
                        classes={{ root: classesSelect.SELECT }}
                        value={element.statusId}
                      >
                        {element.statusName}
                      </MenuItem>
                    ))}
                <MenuItem value="" classes={{ root: classesSelect.CLEAR }}>
                  Limpar seleção
                </MenuItem>
              </TextField>
            </div>
            {!isCedente() && (
              <>
                <div className="containerDatePicker mr-2">
                  <TextField
                    className="text-field-select"
                    id="demo-simple-select"
                    label="Risco"
                    fullWidth
                    select
                    value={risk}
                    onChange={(e) => {
                      setRisk(e.target.value === "" ? "" : e.target.value);
                    }}
                    variant="outlined"
                  >
                    <MenuItem
                      value=""
                      selected
                      disabled
                      classes={{ root: classesSelect.default }}
                    >
                      Selecionar
                    </MenuItem>
                    <MenuItem
                      value="Não Definido"
                      classes={{ root: classesSelect.default }}
                    >
                      Não Definido
                    </MenuItem>
                    <MenuItem value="AA" classes={{ root: classesSelect.AA }}>
                      AA
                    </MenuItem>
                    <MenuItem value="AB" classes={{ root: classesSelect.AB }}>
                      AB
                    </MenuItem>
                    <MenuItem value="BA" classes={{ root: classesSelect.BA }}>
                      BA
                    </MenuItem>
                    <MenuItem value="BB" classes={{ root: classesSelect.BB }}>
                      BB
                    </MenuItem>
                    <MenuItem value="CA" classes={{ root: classesSelect.CA }}>
                      CA
                    </MenuItem>
                    <MenuItem value="CB" classes={{ root: classesSelect.CB }}>
                      CB
                    </MenuItem>
                    <MenuItem value="DA" classes={{ root: classesSelect.DA }}>
                      DA
                    </MenuItem>
                    <MenuItem
                      value="INAPTO"
                      classes={{ root: classesSelect.INAPTO }}
                    >
                      INAPTO
                    </MenuItem>
                    <MenuItem value="" classes={{ root: classesSelect.CLEAR }}>
                      Limpar seleção
                    </MenuItem>
                  </TextField>
                </div>
                <div className="containerDatePicker">
                  <TextField
                    className="text-field-select"
                    id="demo-simple-select"
                    label="Mercado"
                    fullWidth
                    select
                    value={marketType}
                    onChange={(e) => {
                      setMarketType(
                        e.target.value === "" ? "" : parseInt(e.target.value)
                      );
                    }}
                    variant="outlined"
                  >
                    <MenuItem disabled value="">
                      Selecione uma opção
                    </MenuItem>

                    {marketTypes.map((element) => (
                      <MenuItem
                        classes={{ root: classesSelect.SELECT }}
                        value={element.typeId}
                      >
                        {element.typeName}
                      </MenuItem>
                    ))}
                    <MenuItem value="" classes={{ root: classesSelect.CLEAR }}>
                      Limpar seleção
                    </MenuItem>
                  </TextField>
                </div>
              </>
            )}
          </div>
          <div className="item">
            <ReactIf condition={!isCedente()}>
              <ReactThen>
                <TextField
                  fullWidth
                  className="text-field-select"
                  name="seller"
                  value={filter.seller || ""}
                  onChange={handlerChange}
                  id="outlined-basic"
                  label="Cedente"
                  variant="outlined"
                  onKeyPressCapture={(e) => {
                    if (e.key == "Enter") filterSearch(e);
                  }}
                />
              </ReactThen>
            </ReactIf>
            <TextField
              fullWidth
              className="text-field-select"
              name="drawee"
              value={filter.drawee || ""}
              onChange={handlerChange}
              id="outlined-basic"
              label="Sacado"
              variant="outlined"
              onKeyPressCapture={(e) => {
                if (e.key == "Enter") filterSearch(e);
              }}
            />
            <ReactIf condition={!isFinanciador()}>
              <ReactThen>
                <TextField
                  fullWidth
                  className="text-field-select"
                  name="buyer"
                  value={filter.buyer || ""}
                  onChange={handlerChange}
                  id="outlined-basic"
                  label="Financiador"
                  variant="outlined"
                  onKeyPressCapture={(e) => {
                    if (e.key == "Enter") filterSearch(e);
                  }}
                />
              </ReactThen>
            </ReactIf>
          </div>
          <div className="item itemPicker">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <ConfirmButton
                style={{ marginRight: "30px" }}
                onClick={() => limparFiltro()}
              >
                Limpar
              </ConfirmButton>
              <ConfirmButton onClick={(e) => filterSearch(e)}>
                Consultar
              </ConfirmButton>
            </div>
          </div>
        </div>
      </Paper>
      <div
        className="tableContainerInvoice"
        style={{
          marginTop: "50px",
          position: "relative",
          height: "max-content",
        }}
      >
        {(invoices === undefined || isLoading) && (
          <div style={{ marginLeft: "-30px" }}>
            <LinearProgress value={55} color="primary" />
          </div>
        )}
        {invoices !== undefined && !isLoading && (
          <>
            <Paper className={classes.paper}>
              <TableContainer>
                <Table>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={headCells.filter((cell) =>
                      isCedente()
                        ? cell.id !== "sellerName"
                        : isFinanciador()
                        ? cell.id !== "busubessName"
                        : true
                    )}
                    classes={classes}
                  />
                  {stableSort(invoices, getComparator(order, orderBy))
                    // .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    .map((invoice) => (
                      <>
                        <TableBody
                          onClick={() => {
                            history.push(`/Invoice/${invoice.invoiceId}`);
                          }}
                          style={{ cursor: "pointer" }}
                          key={invoice.invoiceId}
                        >
                          <TableCell style={{ textAlign: "center" }}>
                            <Tooltip
                              title={invoice.id.toString()}
                              placement="top"
                            >
                              <span style={{ fontWeight: "bold" }}>
                                {invoice.id}
                              </span>
                            </Tooltip>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {invoice.number}
                          </TableCell>
                          <ReactIf condition={!isCedente()}>
                            <ReactThen>
                              <TableCell style={{ textAlign: "center" }}>
                                <Tooltip
                                  title={invoice.sellerName}
                                  placement="top"
                                >
                                  <span style={{ fontWeight: "bold" }}>
                                    {invoice.sellerName}
                                  </span>
                                </Tooltip>
                              </TableCell>
                            </ReactThen>
                          </ReactIf>
                          <TableCell style={{ textAlign: "center" }}>
                            <Tooltip title={invoice.draweeName} placement="top">
                              {invoice.draweeName?.length <= 10 ? (
                                <span style={{ whiteSpace: "normal" }}>
                                  {invoice.draweeName}
                                </span>
                              ) : (
                                <span style={{ whiteSpace: "normal" }}>
                                  {invoice.draweeName?.slice(0, 10)}
                                  ...
                                </span>
                              )}
                            </Tooltip>
                          </TableCell>
                          {/* {invoice.statusId} */}
                          {isAdmin() || isCedente() ? (
                            <>
                              {invoice.bidBuyer?.name == null ||
                              invoice.bidBuyer?.name == undefined ? (
                                <Tooltip title="..." placement="top-start">
                                  <TableCell style={{ textAlign: "center" }}>
                                    <span style={{ whiteSpace: "normal" }}>
                                      ...
                                    </span>
                                  </TableCell>
                                </Tooltip>
                              ) : isAdmin() ||
                                (isCedente() &&
                                  (invoice.statusId == 8 ||
                                    invoice.statusId == 11)) ? (
                                <Tooltip
                                  title={invoice.bidBuyer?.name}
                                  placement="top-start"
                                >
                                  <TableCell style={{ textAlign: "center" }}>
                                    <span style={{ whiteSpace: "normal" }}>
                                      {invoice.bidBuyer?.name.slice(0, 10)}...
                                    </span>
                                  </TableCell>
                                </Tooltip>
                              ) : (
                                <Tooltip title="..." placement="top-start">
                                  <TableCell style={{ textAlign: "center" }}>
                                    <span style={{ whiteSpace: "normal" }}>
                                      ...
                                    </span>
                                  </TableCell>
                                </Tooltip>
                              )}
                            </>
                          ) : null}

                          <TableCell style={{ textAlign: "center" }}>
                            {formatReal(
                              parseFloat(invoice.maturitiesValue).toFixed(2)
                            )}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {formatReal(
                              parseFloat(invoice.bidNetValue).toFixed(2)
                            )}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {invoice.bidNetFactor.toFixed(3).replace(".", ",")}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {invoice.statusName.length > 16
                              ? `${invoice.statusName.slice(0, 16)}...`
                              : invoice.statusName}
                          </TableCell>
                        </TableBody>
                      </>
                    ))}
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
      </div>
      <div
        className="tableContainerInvoice"
        style={{
          marginTop: "-10px",
          position: "relative",
          height: "max-content",
        }}
      >
        <Paper className={classes.pagination}>
          <TablePagination
            count={countElements}
            colSpan={3}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            size="large"
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </div>
  );
}
