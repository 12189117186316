import React, { useEffect, useState } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Slider from "@material-ui/core/Slider";
import { useTheme, createStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";

import clsx from "clsx";

import useVitrine from "hooks/useVitrine";

import { recebiveisStyle } from "styles/material/recebiveis";

import {
  marketTypesAF,
  marketTypesFIF,
  ufs as ufsInitial,
  liquidations as liquidationsInitial,
  risks as risksInitial,
  tiposTitulo as tiposTituloInitial,
} from "./items";

const AirbnbSliderStyles = (color = "currentColor") =>
  createStyles({
    root: {
      color: "#3a8589",
      height: 3,
      padding: "13px 0",
    },
    thumb: {
      height: 27,
      width: 27,
      backgroundColor: "#fff",
      border: `1px solid ${color}`,
      marginTop: -12,
      marginLeft: -13,
      boxShadow: "#ebebeb 0 2px 2px",
      "&:focus, &:hover, &$active": {
        boxShadow: "#ccc 0 2px 3px 1px",
      },
      "& .bar": {
        height: 9,
        width: 1,
        backgroundColor: color,
        marginLeft: 1,
        marginRight: 1,
      },
    },
    active: {},
    track: {
      height: 3,
      backgroundColor: color,
    },
    rail: {
      color: "#d8d8d8",
      opacity: 1,
      height: 3,
    },
  });

function AirbnbThumbComponent(props) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  const formated = `R$ ${parseFloat(+value).toFixed(2)}`;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={formated}>
      {children}
    </Tooltip>
  );
}

export function getMainColorLight(mainColor, opacity = 0.1) {
  const mainColorIsRgb = mainColor.includes("rgb");

  const colorRgb = mainColor.replace("rgb(", "").replace(")", "").split(",");

  const mainColorLight = mainColorIsRgb
    ? `rgba(${colorRgb[0]}, ${colorRgb[1]}, ${colorRgb[2]}, ${opacity})`
    : `${mainColor}22`;

  return mainColorLight;
}

export default function Search({
  cedentesUnicos: cedentesUnicosInitial = [],
  drawee,
  filter,
  filterBalcao,
  filterMeusLances,
  filterSearch,
  handleMultipleChanges,
  handlerChange,
  limparFiltro,
  liquidationType,
  marketType,
  risk,
  sacadosUnicos: sacadosUnicosInitial = [],
  seller,
  setDrawee,
  setFilterBalcao,
  setFilterMeusLances,
  setLiquidationType,
  setMarketType,
  setRisk,
  setSeller,
  setTags,
  setTipoTitulo,
  setUfCedente,
  tags,
  tagsLista: tagsListaInitial = [],
  tipoTitulo,
  titulos,
  titulosCompleto,
  ufCedente,
  user,
}) {
  const classes = recebiveisStyle();
  const theme = useTheme();

  const marketTypesInitial =
    user.originType !== "Antecipa" ? marketTypesFIF : marketTypesAF;

  // Salva os diferentes tipos em um state para poder filtrar os campos a medida que
  // os títulos vão sendo filtrados
  const [cedentesUnicos, setCedentesUnicos] = useState(cedentesUnicosInitial);
  const [liquidations, setLiquidations] = useState(liquidationsInitial);
  const [marketTypes, setMarketTypes] = useState(marketTypesInitial);
  const [risks, setRisks] = useState(risksInitial);
  const [sacadosUnicos, setSacadosUnicos] = useState(sacadosUnicosInitial);
  const [tagsLista, setTagsLista] = useState(tagsListaInitial);
  const [tiposTitulo, setTiposTitulo] = useState(tiposTituloInitial);
  const [ufs, setUfs] = useState(ufsInitial);

  const [moreFiltersExpanded, setMoreFiltersExpanded] = useState(false);
  const { isOnVitrine } = useVitrine();

  const hasActiveFilter =
    Boolean(risk) ||
    Boolean(marketType) ||
    Boolean(ufCedente) ||
    Boolean(tags) ||
    Boolean(drawee) ||
    Boolean(seller) ||
    Boolean(liquidationType) ||
    Boolean(tipoTitulo) ||
    Object.keys(filter).some((key) => filter[key]);

  const mainColorLight = getMainColorLight(theme.palette.primary.main);

  useEffect(() => {
    if (titulos) {
      setRisks(
        risksInitial.filter((risk) =>
          titulos.find(
            (titulo) =>
              titulo.invoice.riskName?.toLowerCase() ===
              risk.riskName.toLowerCase()
          )
        )
      );
      setTiposTitulo(
        tiposTituloInitial.filter((tipo) =>
          titulos.find(
            (titulo) =>
              titulo.invoice.typeName?.toLowerCase() === tipo.nome.toLowerCase()
          )
        )
      );
      setLiquidations(
        liquidationsInitial.filter((liquidation) =>
          titulos.find(
            (titulo) =>
              titulo.invoice.liquidationTypeName?.toLowerCase() ===
              liquidation.nome.toLowerCase()
          )
        )
      );
      setTagsLista(
        tagsListaInitial.filter((tag) =>
          titulos.find((titulo) =>
            titulo.tags?.find(
              (ttag) => ttag.NAME.toLowerCase() === tag.name.toLowerCase()
            )
          )
        )
      );
      setUfs(
        ufsInitial.filter((uf) =>
          titulos.find(
            (titulo) =>
              titulo.business.addressState?.toLowerCase() ===
              uf.Sigla.toLowerCase()
          )
        )
      );
      setCedentesUnicos(
        cedentesUnicosInitial.filter((cedente) =>
          titulos.find(
            (titulo) =>
              titulo.business.name?.toLowerCase() === cedente.toLowerCase()
          )
        )
      );
      setSacadosUnicos(
        sacadosUnicosInitial.filter((sacado) =>
          titulos.find(
            (titulo) =>
              titulo.sacado.name?.toLowerCase() === sacado?.toLowerCase()
          )
        )
      );
      setMarketTypes(
        marketTypesInitial.filter((marketType) =>
          titulos.find(
            (titulo) =>
              titulo.invoice.marketTypeName?.toLowerCase() ===
              marketType.typeName.toLowerCase()
          )
        )
      );
    }
  }, [titulos, cedentesUnicosInitial, sacadosUnicosInitial]);

  const balcaoCount = titulos.filter(
    (titulo) => titulo.invoice.marketTypeId === 2
  ).length;
  const meusLancescount = titulos.filter(
    (titulo) => titulo.invoice.indOferta === 1 || titulo.invoice.indOferta === 2
  ).length;

  const maxTituloValue = titulosCompleto.reduce(
    (curr, next) =>
      next.invoice.invoiceValue > curr ? next.invoice.invoiceValue : curr,
    0
  );

  const [valorInicial, setValorInicial] = useState(filter.valorInicial ?? 0);
  const [valorFinal, setValorFinal] = useState(
    filter.valorFinal ?? maxTituloValue
  );
  useEffect(() => {
    setValorInicial(filter.valorInicial ?? 0);
    setValorFinal(filter.valorFinal ?? maxTituloValue);
  }, [filter.valorInicial, filter.valorFinal]);

  const sliderStyles = AirbnbSliderStyles(theme.palette.primary.main);

  useEffect(() => {
    const maxTituloValue = titulosCompleto.reduce(
      (curr, next) =>
        next.invoice.invoiceValue > curr ? next.invoice.invoiceValue : curr,
      0
    );

    setValorFinal(maxTituloValue);
  }, [titulosCompleto]);

  return (
    <Paper
      elevation={3}
      className={clsx(
        "app-page-title",
        isOnVitrine && "app-page-title--vitrine"
      )}
      style={{
        // height: '135px',
        maxWidth: "100%",
        display: "flow-root",
      }}
    >
      <div className="app-page-title--heading">
        <h1 style={{ fontFamily: "Poppins" }}>Vitrine de títulos</h1>
      </div>

      <div className="search-form-vitrine">
        <div className="search-form-vitrine--top">
          <div className="search-form-vitrine--top--search-container">
            <TextField
              className="search-form-vitrine--input search-form-vitrine--search"
              id="outlined-basic"
              label="Pesquise aqui"
              name="number"
              value={filter.number || ""}
              onChange={(e) => {
                handlerChange(e);
              }}
              variant="outlined"
              onKeyPressCapture={(e) => {
                if (e.key == "Enter") filterSearch(e);
              }}
            />

            <IconButton
              className="search-form-vitrine__button-mobile"
              onClick={(e) => filterSearch(e)}
            >
              <SearchIcon
                style={{
                  color: theme.palette.primary.main,
                  fontSize: 40,
                }}
              />
            </IconButton>

            <Button
              className="search-form-vitrine--button"
              classes={{
                label: "search-form-vitrine--button--label",
              }}
              disableRipple
              onClick={(e) => filterSearch(e)}
              style={{
                backgroundColor: theme.palette.primary.main,
              }}
              variant="contained"
            >
              Consultar
            </Button>
          </div>

          <div className="search-form-vitrine__buttons-group">
            <Button
              className="search-form-vitrine__button-group"
              classes={{
                label: "search-form-vitrine__button-group__label",
              }}
              disabled={balcaoCount === 0}
              disableRipple
              onClick={() => setFilterBalcao(!filterBalcao)}
              style={{
                backgroundColor: filterBalcao ? mainColorLight : "#ffffff",
                boxShadow: filterBalcao
                  ? `0 0 0 1px ${theme.palette.primary.main}`
                  : "0 0 0 1px #b7b7b7",
              }}
              variant="contained"
            >
              <span className="search-form-vitrine__button-group__text">
                Balcão
                <span
                  className="search-form-vitrine__button-group__count"
                  style={{ color: theme.palette.primary.main }}
                >
                  {balcaoCount}
                </span>
              </span>
            </Button>

            <Button
              classes={{
                label: "search-form-vitrine__button-group__label",
              }}
              className="search-form-vitrine__button-group"
              disabled={meusLancescount === 0}
              onClick={() => setFilterMeusLances(!filterMeusLances)}
              style={{
                backgroundColor: filterMeusLances ? mainColorLight : "#ffffff",
                boxShadow: filterMeusLances
                  ? `0 0 0 1px ${theme.palette.primary.main}`
                  : "0 0 0 1px #b7b7b7",
              }}
              variant="contained"
            >
              <span className="search-form-vitrine__button-group__text">
                Minhas ofertas
                <span
                  className="search-form-vitrine__button-group__count"
                  style={{ color: theme.palette.primary.main }}
                >
                  {meusLancescount}
                </span>
              </span>
            </Button>
          </div>
        </div>

        <div
          className={clsx(
            "search-form-vitrine--bottom",
            moreFiltersExpanded && "search-form-vitrine--bottom--expanded"
          )}
        >
          <TextField
            className={clsx(
              "search-form-vitrine--input",
              "search-form-vitrine--input--select"
            )}
            style={{
              boxShadow: risk
                ? `0px 0px 0px 2px ${theme.palette.primary.main}`
                : "0px 0px 0px 1px #d5d5d5",
            }}
            label="Risco"
            select
            value={risk}
            onChange={(e) => {
              setRisk(e.target.value === "" ? "" : e.target.value);
            }}
            variant="outlined"
          >
            <MenuItem
              value=""
              selected
              disabled
              classes={{ root: classes.default }}
            >
              Selecionar
            </MenuItem>

            {risks.map((risk, index) => (
              <MenuItem
                classes={{
                  root: clsx(
                    risk.riskName === "AA" && classes.AA,
                    risk.riskName === "AB" && classes.AB,
                    risk.riskName === "BA" && classes.BA,
                    risk.riskName === "BB" && classes.BB,
                    risk.riskName === "CA" && classes.CA,
                    risk.riskName === "CB" && classes.CB,
                    risk.riskName === "DA" && classes.DA,
                    risk.riskName === "INAPTO" && classes.INAPTO
                  ),
                }}
                key={`${risk.riskId} ${index}`}
                value={risk.riskName}
              >
                {risk.riskName}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            className={clsx(
              "search-form-vitrine--input",
              "search-form-vitrine--input--select"
            )}
            style={{
              boxShadow: tags
                ? `0px 0px 0px 2px ${theme.palette.primary.main}`
                : "0px 0px 0px 1px #d5d5d5",
            }}
            value={tags}
            label="Tags"
            select
            onChange={(e) => {
              setTags(e.target.value === "" ? "" : e.target.value);
            }}
            variant="outlined"
          >
            <MenuItem key="0" disabled value="">
              Selecione uma opção
            </MenuItem>

            {tagsLista.map((element, i) => (
              <MenuItem
                key={i}
                classes={{ root: classes.SELECT }}
                value={element.name}
              >
                {element?.name?.toUpperCase()}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            className={clsx(
              "search-form-vitrine--input",
              "search-form-vitrine--input--select"
            )}
            style={{
              boxShadow: seller
                ? `0px 0px 0px 2px ${theme.palette.primary.main}`
                : "0px 0px 0px 1px #d5d5d5",
            }}
            value={seller}
            label="Cedentes"
            select
            onChange={(e) => {
              e.target.name = "seller";
              handlerChange(e);
              filterSearch(e);
              setSeller(e.target.value);
            }}
            variant="outlined"
          >
            <MenuItem key="0" disabled value="">
              Selecione uma opção
            </MenuItem>

            {cedentesUnicos?.map((element, i) => (
              <MenuItem
                key={i}
                classes={{ root: classes.SELECT }}
                value={element}
              >
                {element?.toUpperCase()}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            className={clsx(
              "search-form-vitrine--input",
              "search-form-vitrine--input--select"
            )}
            style={{
              boxShadow: drawee
                ? `0px 0px 0px 2px ${theme.palette.primary.main}`
                : "0px 0px 0px 1px #d5d5d5",
            }}
            value={drawee}
            label="Sacados"
            select
            onChange={(e) => {
              e.target.name = "drawee";
              handlerChange(e);
              filterSearch(e);
              setDrawee(e.target.value);
            }}
            variant="outlined"
          >
            <MenuItem key="0" disabled value="">
              Selecione uma opção
            </MenuItem>

            {sacadosUnicos.map((element, i) => (
              <MenuItem
                key={i}
                classes={{ root: classes.SELECT }}
                value={element}
              >
                {element?.toUpperCase()}
              </MenuItem>
            ))}
          </TextField>

          <Button
            variant="contained"
            classes={{
              label: "search-form-vitrine--button--label",
            }}
            className="search-form-vitrine--button search-form-vitrine--button--more-filters"
            onClick={() => setMoreFiltersExpanded((current) => !current)}
            startIcon={
              <FilterListIcon
                style={{ color: theme.palette.primary.main, fontSize: 26 }}
              />
            }
            style={{
              backgroundColor: mainColorLight,
              boxShadow:
                moreFiltersExpanded &&
                `0px 0px 0px 2px ${theme.palette.primary.main}`,
              minWidth: "initial",
            }}
          >
            {moreFiltersExpanded ? "- Filtros" : "+ Mais Filtros"}
          </Button>

          {hasActiveFilter && (
            <Button
              variant="text"
              classes={{
                label: "search-form-vitrine--button--label",
              }}
              className="search-form-vitrine--button search-form-vitrine--button--clear"
              onClick={() => limparFiltro()}
            >
              Limpar
            </Button>
          )}
        </div>

        <Accordion
          classes={{ root: "search-form-vitrine--accordion" }}
          expanded={moreFiltersExpanded}
          onChange={() => setMoreFiltersExpanded((current) => !current)}
        >
          <AccordionSummary
            classes={{ root: "search-form-vitrine--accordion-summary" }}
          />

          <AccordionDetails
            classes={{ root: "search-form-vitrine--accordion-details" }}
          >
            <div className="search-form-vitrine--more-filters">
              <TextField
                className={clsx(
                  "search-form-vitrine--input",
                  "search-form-vitrine--input--select"
                )}
                style={{
                  boxShadow: tipoTitulo
                    ? `0px 0px 0px 2px ${theme.palette.primary.main}`
                    : "0px 0px 0px 1px #d5d5d5",
                }}
                value={tipoTitulo}
                label="Tipo de título"
                select
                onChange={(e) => {
                  setTipoTitulo(e.target.value === "" ? "" : e.target.value);
                }}
                variant="outlined"
              >
                <MenuItem disabled value="">
                  Selecione uma opção
                </MenuItem>

                {tiposTitulo.map((element, index) => (
                  <MenuItem
                    classes={{ root: classes.SELECT }}
                    key={`${element.nome} ${index}`}
                    value={element.nome}
                  >
                    {element?.nome?.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                className={clsx(
                  "search-form-vitrine--input",
                  "search-form-vitrine--input--select"
                )}
                style={{
                  boxShadow: liquidationType
                    ? `0px 0px 0px 2px ${theme.palette.primary.main}`
                    : "0px 0px 0px 1px #d5d5d5",
                }}
                value={liquidationType}
                label="Tipo de liquidação"
                select
                onChange={(e) => {
                  setLiquidationType(
                    e.target.value === "" ? "" : e.target.value
                  );
                }}
                variant="outlined"
              >
                <MenuItem disabled value="">
                  Selecione uma opção
                </MenuItem>

                {liquidations.map((element, index) => (
                  <MenuItem
                    classes={{ root: classes.SELECT }}
                    key={`${element.name} ${index}`}
                    value={element.nome}
                  >
                    {element?.nome?.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                className={clsx(
                  "search-form-vitrine--input",
                  "search-form-vitrine--input--select"
                )}
                style={{
                  boxShadow: ufCedente
                    ? `0px 0px 0px 2px ${theme.palette.primary.main}`
                    : "0px 0px 0px 1px #d5d5d5",
                }}
                value={ufCedente}
                label="Localização"
                select
                onChange={(e) => {
                  setUfCedente(e.target.value === "" ? "" : e.target.value);
                }}
                variant="outlined"
              >
                <MenuItem disabled value="">
                  Selecione uma opção
                </MenuItem>

                {ufs.map((element, index) => (
                  <MenuItem
                    classes={{ root: classes.SELECT }}
                    key={`${element.Nome} ${index}`}
                    value={element.Sigla}
                  >
                    {element?.Nome?.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>

              <div
                className="search-form-vitrine__range-container"
                key={maxTituloValue}
              >
                <Slider
                  ThumbComponent={AirbnbThumbComponent}
                  ValueLabelComponent={ValueLabelComponent}
                  classes={{
                    active: sliderStyles.active,
                    rail: sliderStyles.rail,
                    root: sliderStyles.root,
                    thumb: sliderStyles.thumb,
                    track: sliderStyles.track,
                  }}
                  defaultValue={[0, maxTituloValue < 1 ? 1000 : maxTituloValue]}
                  getAriaLabel={(index) =>
                    index === 0 ? "Minimum price" : "Maximum price"
                  }
                  marks={[
                    {
                      value: 0,
                      label: "Valor Inicial",
                    },
                    {
                      value: maxTituloValue < 1 ? 1000 : maxTituloValue,
                      label: "Valor Final",
                    },
                  ]}
                  max={maxTituloValue < 1 ? 1000 : maxTituloValue}
                  min={0}
                  onChange={(event, newValue) => {
                    setValorInicial(newValue[0]);
                    setValorFinal(newValue[1]);
                  }}
                  onChangeCommitted={(event, newValue) => {
                    const valores = {
                      valorInicial: newValue[0],
                      valorFinal: newValue[1],
                    };

                    handleMultipleChanges(valores);
                  }}
                  value={[valorInicial, valorFinal]}
                  valueLabelDisplay="auto"
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Paper>
  );
}
