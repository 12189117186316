import AxiosInterceptor from "utils/axios-interceptor";

export async function getCustomersByBusinessId(businessId) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/business/customers/${businessId}`
  );

  return data;
}

export async function getSuppliersByBusinessId(businessId) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/business/suppliers/${businessId}`
  );

  return data;
}

export async function updateCustomersService(businessCustomers) {
  const axiosInstance = AxiosInterceptor();
  await axiosInstance.patch(
    `${process.env.REACT_APP_NODE_API}/customers/update`,
    businessCustomers
  );
}

export async function updateSuppliersService(businessSuppliers) {
  const axiosInstance = AxiosInterceptor();
  await axiosInstance.patch(
    `${process.env.REACT_APP_NODE_API}/suppliers/update`,
    businessSuppliers
  );
}

export async function deleteCustomersService(removedCustomers) {
  const axiosInstance = AxiosInterceptor();
  await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/customers/delete`,
    removedCustomers
  );
}

export async function deleteSuppliersService(removedSuppliers) {
  const axiosInstance = AxiosInterceptor();
  await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/suppliers/delete`,
    removedSuppliers
  );
}
