/* eslint eqeqeq: 0 */
import { useEffect, useState } from 'react';

import formatReal from 'utils/formatReal';

import { getBusinessByBusinessId, getDadosBusiness } from 'services/business';
import { getDrawee } from 'services/drawee';
import {
  createMaturityExtension,
  uploadDocument,
  getUploadDocument,
  buyerRejectExtension,
  buyerProposalExtension,
  sellerRejectExtension,
  sellerApproveExtension,
  buyerCancelExtension,
} from 'services/maturityExtension';
import { getRegisterBusinessId } from 'services/register';
import {
  emailProrrogacaoAprovada,
  emailProrrogacaoEnvioProposta,
  emailProrrogacaoNegada,
  emailProrrogacaoPropostaRecebida,
  emailRegistroProrrogacao,
  emailSolicitacaoProrrogacao,
} from 'services/sendgrid';

import { useAuth } from 'contexts/auth';
import { useToast } from 'contexts/toast';

export function useSolicitarProrrogacao(
  maturity,
  invoiceMaturityId,
  invoice,
  originalDateMaturity,
  bid,
  extension
) {
  const { user, isCedente, isFinanciador } = useAuth();
  const { toast } = useToast();
  const [sellerReasonText, setSellerReasonText] = useState('');
  const [buyerReasonText, setBuyerReasonText] = useState('');
  const [sellerExtensionDate, setSellerExtensionDate] = useState(new Date());
  const [buyerExtensionDate, setBuyerExtensionDate] = useState(new Date());
  const [minSellerExtensionDate, setMinSellerExtensionDate] = useState(
    new Date()
  );
  const [extensionDocs, setExtensionDocs] = useState([]);
  const [buyerFee, setBuyerFee] = useState(undefined);
  const [interest, setInterest] = useState(undefined);

  useEffect(() => {
    handleLoadSellerExtension();
    handleLoadBuyerExtension();
    const minDate =
      minSellerExtensionDate > originalDateMaturity
        ? minSellerExtensionDate
        : originalDateMaturity;
    setMinSellerExtensionDate(minDate);
  }, []);

  const removeFormatReal = (valor_formatado) => {
    if (valor_formatado === undefined) return 0;
    const parsedReal = valor_formatado
      .replace('R$', '')
      .replace(' ', '')
      .replace(/[.]/g, '')
      .replace(/[,]/g, '.');

    return parseFloat(parsedReal);
  };

  const formatDate = (date = new Date()) => {
    const formattedDate = date.toISOString();
    return formattedDate;
  };

  const handleLoadSellerExtension = async () => {
    if (!extension) return;

    setSellerReasonText(extension.sellerReason);
    setSellerExtensionDate(extension.sellerExtensionDate);
    await handleGetExtensionDocs();
  };

  const handleLoadBuyerExtension = async () => {
    if (!extension || !extension.requestBuyerUserId) return;

    setBuyerReasonText(extension.buyerReason);
    setBuyerExtensionDate(extension.buyerExtensionDate);
    setBuyerFee(formatReal(extension.buyerFee * 100));
    setInterest(formatReal(extension.interest * 100));
  };

  const handleGetExtensionDocs = async () => {
    if (!extension) setExtensionDocs([]);
    const docs = await toast.promise(
      getUploadDocument(extension.maturityExtensionId),
      {
        loading: 'Baixando evidências',
        success: 'Evidências baixadas com sucesso!',
        error: 'Erro ao baixar evidências',
      }
    );
    setExtensionDocs(docs);
  };

  const handleChangeSellerExtensionDate = (newSellerExtensionDate) => {
    setSellerExtensionDate(newSellerExtensionDate);
  };

  const handleChangeBuyerExtensionDate = (newBuyerExtensionDate) => {
    setBuyerExtensionDate(newBuyerExtensionDate);
  };

  const handleChangeSellerReasonText = (e) => {
    if (e.target.value.length < 8000) setSellerReasonText(e.target.value);
  };

  const handleChangeBuyerReasonText = (e) => {
    if (e.target.value.length < 8000) setBuyerReasonText(e.target.value);
  };

  const handleUploadProgress = (percentComplete) => {
    if (percentComplete === 0) return;
    toast(`Enviando arquivo - ${percentComplete}%`, {
      id: 'percentComplete',
    });
  };

  const handleSetDocs = (files) => {
    let selectedFiles = Object.values(files);
    selectedFiles = selectedFiles.map((file) => {
      const newFile = file;
      newFile.url = URL.createObjectURL(file);
      return newFile;
    });
    setExtensionDocs(selectedFiles);
  };

  const handleRemoveDoc = (index) => {
    const newDocs = extensionDocs.filter((docs, i) => i !== index);
    setExtensionDocs(newDocs);
  };

  const uploadExtensionDocuments = async (files, maturityExtensionId) => {
    const formData = new FormData();

    files.forEach((file) => formData.append('files', file));
    formData.append('originId', maturityExtensionId);
    formData.append('originTypeId', 7);
    formData.append('originTypeName', 'Prorrogação');
    formData.append('documentTypeId', 54);
    formData.append('documentTypeName', 'Evidência prorrogação');

    try {
      await toast.promise(uploadDocument(formData, handleUploadProgress), {
        loading: `Enviando documento`,
        success: 'Documento enviado com sucesso',
        error: 'Erro ao enviar documento',
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCreateMaturityExtension = async () => {
    const createExtensionBody = {
      invoiceMaturityId,
      invoiceId: invoice.invoiceId,
      invoiceNumber: invoice.number,
      sellerId: invoice.businessId,
      buyerId: bid.buyerId,
      requestSellerUserId: user.userId,
      sellerExtensionDate,
      sellerReason: sellerReasonText,
      originalDate: originalDateMaturity,
    };

    const newMaturityExtension = await toast.promise(
      createMaturityExtension(createExtensionBody),
      {
        loading: 'Solicitando Prorrogação',
        success: 'Prorrogação solicitada com sucesso!',
        error: 'Erro solicitar prorrogação',
      }
    );

    await sendMailSolicitacaoProrrogacao(
      bid.buyerId,
      invoice,
      maturity,
      originalDateMaturity
    );

    await uploadExtensionDocuments(
      extensionDocs,
      newMaturityExtension.maturityExtensionId
    );

    window.location.reload();

    return newMaturityExtension;
  };

  const sendMailSolicitacaoProrrogacao = async (
    buyerId,
    invoice,
    maturity,
    originalDateMaturity
  ) => {
    // const buyerRegister = await getRegisterBusinessId(buyerId);
    const buyerBusiness = await getBusinessByBusinessId(buyerId);
    const sellerRegister = await getRegisterBusinessId(invoice.businessId);
    // const sellerBusiness = await getBusinessByBusinessId(invoice.businessId);
    const drawee = await getDrawee(invoice.invoiceId);

    const buyerData = await getDadosBusiness(buyerId);
    const sellerData = await getDadosBusiness(invoice.businessId);

    const solicitacaoProrrogacaoBody = {
      empresa: buyerData[0].origemWL,
      publico: 2,
      email_destinatario: buyerData[0].businessEmail,
      nome_cedente: sellerData[0].businessName,
      email_cedente: sellerData[0].businessEmail,
      telefone_cedente: sellerRegister.phone,
      numeroNF: invoice.id,
      nome_sacado: drawee.name,
      valor_parcelas: maturity.value,
      data_vencimento: originalDateMaturity,
      motivo_prorrogacao: sellerReasonText,
      data_prorrogacao: originalDateMaturity,
      valor_proposta: maturity.value,
    };

    const registroProrrogacaoBody = {
      empresa: sellerData[0].origemWL,
      publico: 1,
      email_destinatario: sellerData[0].businessEmail,
      nome_financiador: buyerData[0].businessName,
      email_financiador: buyerData[0].businessEmail,
      telefone_financiador: buyerBusiness.phone,
      numeroNF: invoice.id,
      nome_sacado: drawee.name,
      motivo_prorrogacao: sellerReasonText,
      data_prorrogacao: originalDateMaturity,
      valor_proposta: maturity.value,
    };

    await emailSolicitacaoProrrogacao(solicitacaoProrrogacaoBody);
    await emailRegistroProrrogacao(registroProrrogacaoBody);
  };

  const handleRejectBuyer = async () => {
    const updateExtensionBody = {
      maturityExtensionId: extension.maturityExtensionId,
      approveBuyerUserId: user.userId,
    };

    await toast.promise(buyerRejectExtension(updateExtensionBody), {
      loading: 'Rejeitando Proposta',
      success: 'Proposta rejeitada',
      error: 'Erro ao rejeitar Proposta',
    });
    // const buyerData = await getDadosBusiness(user.buyerId);
    const sellerData = await getDadosBusiness(invoice.businessId);

    const buyerBusiness = await getBusinessByBusinessId(user.buyerId);
    const sellerRegister = await getRegisterBusinessId(invoice.businessId);
    const sellerBusiness = await getBusinessByBusinessId(invoice.businessId);
    const drawee = await getDrawee(invoice.invoiceId);

    const prorrogacaoNegadaBody = {
      empresa: sellerData[0].origemWL,
      publico: 1,
      email_destinatario: sellerRegister.email,
      nome_cliente: buyerBusiness.name,
      numeroNF: invoice.id,
      nome_cedente: sellerBusiness.name,
      email_cedente: sellerBusiness.email,
      nome_sacado: drawee.name,
      motivo_prorrogacao: sellerReasonText,
      data_prorrogacao: originalDateMaturity,
      valor_proposta: maturity.value,
    };

    await emailProrrogacaoNegada(prorrogacaoNegadaBody);

    window.location.reload();
  };

  const handleRejectSeller = async () => {
    const updateExtensionBody = {
      maturityExtensionId: extension.maturityExtensionId,
      approveSellerUserId: user.userId,
    };

    await toast.promise(sellerRejectExtension(updateExtensionBody), {
      loading: 'Rejeitando Proposta',
      success: 'Proposta rejeitada',
      error: 'Erro ao rejeitar Proposta',
    });
    // const buyerData = await getDadosBusiness(user.buyerId);
    // const sellerData = await getDadosBusiness(invoice.businessId);

    const buyerRegister = await getRegisterBusinessId(user.buyerId);
    const sellerBusiness = await getBusinessByBusinessId(invoice.businessId);
    const drawee = await getDrawee(invoice.invoiceId);

    const propostaRejeitadaBody = {
      empresa:
        buyerRegister.originType == 'FIF' ||
        buyerRegister.originType == 'Federal_Invest'
          ? 'FIF'
          : 'AF',
      publico: 2,
      email_destinatario: buyerRegister.email,
      nome_cliente: sellerBusiness.name,
      numeroNF: invoice.id,
      nome_cedente: sellerBusiness.name,
      email_cedente: sellerBusiness.email,
      nome_sacado: drawee.name,
      motivo_prorrogacao: sellerReasonText,
      data_prorrogacao: originalDateMaturity,
      valor_proposta: maturity.value,
    };

    await emailProrrogacaoNegada(propostaRejeitadaBody);

    window.location.reload();
  };

  const handleApproveSeller = async () => {
    const updateExtensionBody = {
      maturityExtensionId: extension.maturityExtensionId,
      approveSellerUserId: user.userId,
    };

    await toast.promise(sellerApproveExtension(updateExtensionBody), {
      loading: 'Aceitando Proposta',
      success: 'Proposta aceita!',
      error: 'Erro ao aceitar Proposta',
    });

    const buyerRegister = await getRegisterBusinessId(
      invoice.invoice.businessId
    );
    const sellerBusiness = await getBusinessByBusinessId(invoice.businessId);
    const drawee = await getDrawee(invoice.invoiceId);

    const propostaAprovadaBody = {
      empresa:
        buyerRegister.originType == 'FIF' ||
        buyerRegister.originType == 'Federal_Invest'
          ? 'FIF'
          : 'AF',
      publico: 2,
      email_destinatario: buyerRegister.email,
      nome_cliente: sellerBusiness.name,
      numeroNF: invoice.id,
      nome_cedente: sellerBusiness.name,
      email_cedente: sellerBusiness.email,
      nome_sacado: drawee.name,
      motivo_prorrogacao: sellerReasonText,
      data_prorrogacao: originalDateMaturity,
      valor_proposta: maturity.value,
    };

    await emailProrrogacaoAprovada(propostaAprovadaBody);
    window.location.reload();
  };

  const handleCancelBuyer = async () => {
    const updateExtensionBody = {
      maturityExtensionId: extension.maturityExtensionId,
      approveBuyerUserId: user.userId,
    };
    await toast.promise(buyerCancelExtension(updateExtensionBody), {
      loading: 'Cancelando Prorrogação',
      success: 'Prorrogação cancelada',
      error: 'Erro ao cancelar Prorrogação',
    });
    window.location.reload();
  };

  const handleProposalBuyer = async () => {
    const updateExtensionBody = {
      maturityExtensionId: extension.maturityExtensionId,
      requestBuyerUserId: user.userId,
      buyerFee: removeFormatReal(buyerFee),
      interest: removeFormatReal(interest),
      buyerReason: buyerReasonText,
      buyerExtensionDate,
    };

    await toast.promise(buyerProposalExtension(updateExtensionBody), {
      loading: 'Enviando Proposta',
      success: 'Proposta enviada!',
      error: 'Erro enviar proposta',
    });

    const buyerRegister = await getRegisterBusinessId(user.buyerId);
    const buyerBusiness = await getBusinessByBusinessId(user.buyerId);
    const sellerRegister = await getRegisterBusinessId(
      invoice.invoice.businessId
    );
    const sellerBusiness = await getBusinessByBusinessId(
      invoice.invoice.businessId
    );
    const drawee = await getDrawee(invoice.invoiceId);

    const payloadPropostaSeller = {
      empresa:
        sellerRegister.originType == 'FIF' ||
        sellerRegister.originType == 'Federal_Invest'
          ? 'FIF'
          : 'AF',
      publico: 1,
      email_destinatario: sellerBusiness.email,
      email_cedente: sellerRegister.email_cedente,
      telefone_cedente: sellerRegister.phone,
      numeroNF: invoice.id,
      nome_sacado: drawee.name,
      valor_parcelas: maturity.value,
      data_vencimento: originalDateMaturity,
      motivo_prorrogacao: sellerReasonText,
      data_prorrogacao: originalDateMaturity,
      valor_proposta: maturity.value,
    };
    await emailProrrogacaoPropostaRecebida(payloadPropostaSeller);

    const payloadPropostaBuyer = {
      empresa:
        buyerRegister.originType == 'FIF' ||
        buyerRegister.originType == 'Federal_Invest'
          ? 'FIF'
          : 'AF',
      publico: 2,
      email_destinatario: buyerBusiness.email,
      email_cedente: sellerRegister.email_cedente,
      telefone_cedente: sellerRegister.phone,
      numeroNF: invoice.id,
      nome_sacado: drawee.name,
      valor_parcelas: maturity.value,
      data_vencimento: originalDateMaturity,
      motivo_prorrogacao: sellerReasonText,
      data_prorrogacao: originalDateMaturity,
      valor_proposta: maturity.value,
    };

    await emailProrrogacaoEnvioProposta(payloadPropostaBuyer);

    window.location.reload();
  };

  return {
    sellerReasonText,
    buyerReasonText,

    originalDate: originalDateMaturity,

    sellerExtensionDate,
    buyerExtensionDate,

    minSellerExtensionDate,

    handleChangeSellerReasonText,
    handleChangeBuyerReasonText,

    handleChangeSellerExtensionDate,
    handleChangeBuyerExtensionDate,

    buyerFee,
    setBuyerFee,
    interest,
    setInterest,

    handleCreateMaturityExtension,
    handleRejectBuyer,
    handleProposalBuyer,
    handleRejectSeller,
    handleApproveSeller,
    handleCancelBuyer,

    extensionDocs,
    handleSetDocs,
    handleRemoveDoc,

    isCedente,
    isFinanciador,

    removeFormatReal,
    formatDate,
  };
}
