import React, { useEffect, useState } from "react";

import {
  Box,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  TextField,
  Typography,
} from "@material-ui/core";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";

import PropTypes from "prop-types";

import { Modal } from "components/material/Modal";

import formatTelefone from "utils/formatTelefone";

import { updateBankAccountSevices } from "services/contas-consignadas";

import { useToast } from "contexts/toast";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const BankAccountFocus = (props) => {
  const { toast } = useToast();
  const {
    bankAccountDialog,
    setBankAccountDialog,
    StylizedInput,
    refreshListagemData,
  } = props;

  const [openModalFull, setOpenModalFull] = useState(false);

  // const [value, setValue] = useState(0);
  const value = 0;
  const [bankAccount, setBankAccount] = useState(bankAccountDialog.bankAccount);
  const [updatingBankAccount, setUpdatingBankAccount] = useState(false);

  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    setBankAccount(bankAccountDialog.bankAccount);
  }, [bankAccountDialog]);

  const updateBankAccountData = async () => {
    if (updatingBankAccount) return;
    setUpdatingBankAccount(true);
    const updatedBankAccount = bankAccount.payrollAccount;

    try {
      const payrollAccount = await toast.promise(
        updateBankAccountSevices(updatedBankAccount),
        {
          loading: "Atualizando status",
          success: "Status atualizado!",
          error: "Erro ao atualizar o status.",
        }
      );

      const newBankAccountData = {
        ...bankAccount,
        ...payrollAccount,
      };

      bankAccountDialog.setBankAccount(newBankAccountData);

      setBankAccount(newBankAccountData);
      setBankAccountDialog({
        ...bankAccountDialog,
        bankAccount: newBankAccountData,
      });
      setUpdated(true);
      setOpenModalFull(false);
      setBankAccountDialog({ ...bankAccountDialog, open: false });

      await refreshListagemData();
      setUpdated(false);
      setUpdatingBankAccount(false);
    } catch (err) {
      toast.error("Erro ao atualizar dados da conta bancária.");
      setUpdatingBankAccount(false);
    }
  };

  const handleClose = () => {
    setOpenModalFull(false);
    setBankAccountDialog({ ...bankAccountDialog, open: false });
  };

  return (
    <>
      <Dialog
        maxWidth="xl"
        open={bankAccountDialog.open}
        onClose={() => {
          setBankAccountDialog({ ...bankAccountDialog, open: false });
          setOpenModalFull(false);
        }}
      >
        <Modal
          id="customized-dialog-title"
          style={{
            display: "flex",
            background: "#1E212E",
            width: "41.7%",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
          onClose={handleClose}
        />

        {bankAccount !== undefined && (
          <Grid
            container
            spacing={0}
            style={{ height: "600px", width: "1000px" }}
          >
            <Grid item xs={12} lg={5}>
              <div
                className="hero-wrapper bg-composed-wrapper h-100 justify-content-center p-4"
                style={{ backgroundColor: "#373A44", position: "relative" }}
              >
                {openModalFull && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      backgroundColor: "#000000aa",
                      zIndex: 999,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 16,
                        padding: 32,
                      }}
                    >
                      <h1 className="display-4 font-weight-bold">
                        {Number(bankAccount.payrollAccount.statusId) === 1
                          ? "Desativar"
                          : "Ativar"}
                      </h1>
                      <p className="font-size-lg mb-0 text-black-50">
                        Deseja{" "}
                        {Number(bankAccount.payrollAccount.statusId) === 1
                          ? "desativar"
                          : "ativar"}{" "}
                        o usuário?
                      </p>
                      <div className="d-flex flex-row align-items-center justify-content-end w-100 mt-3">
                        <span
                          className="text-black-50 mr-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenModalFull(false)}
                        >
                          Cancelar
                        </span>
                        <span
                          className="text-black-50"
                          style={{ cursor: "pointer" }}
                          onClick={() => {}}
                        >
                          Confirmar
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                  <div className="bg-composed-wrapper--content p-5">
                    {bankAccount.payrollAccount.statusId !== null &&
                      bankAccount.payrollAccount.statusId !== undefined && (
                        <div
                          className="d-flex align-items-center"
                          style={{ color: "#fff" }}
                        >
                          {Number(bankAccount.payrollAccount.statusId) === 1 ? (
                            <span
                              className="px-4 h-auto py-1 badge badge-pill"
                              style={{ backgroundColor: "#45B465" }}
                            >
                              LIBERADA
                            </span>
                          ) : Number(bankAccount.payrollAccount.statusId) ===
                            2 ? (
                            <span
                              className="px-4 h-auto py-1 badge badge-pill"
                              style={{ backgroundColor: "#F85051" }}
                            >
                              BLOQUEADA
                            </span>
                          ) : Number(bankAccount.payrollAccount.statusId) ===
                            3 ? (
                            <span
                              className="px-4 h-auto py-1 badge badge-pill"
                              style={{ border: "1px solid grey" }}
                            >
                              NEGADA
                            </span>
                          ) : Number(bankAccount.payrollAccount.statusId) ===
                            4 ? (
                            <span
                              className="px-4 h-auto py-1 badge badge-pill"
                              style={{ backgroundColor: "blue" }}
                            >
                              ABERTA
                            </span>
                          ) : (
                            <span className="px-4 h-auto py-1 badge badge-pill" />
                          )}
                        </div>
                      )}
                    <div className="text-white mt-3">
                      <h1
                        className="display-4 my-3 font-weight-bold"
                        style={{ color: "#fff" }}
                      >
                        {bankAccount.name}
                      </h1>
                      <p className="font-size-lg mb-0 text-white-50">
                        {bankAccount.corporateName}
                      </p>
                      <p className="font-size-base mb-0 text-white-50">
                        {bankAccount.email}
                      </p>
                      <p className="font-size-lg mb-0 text-white-50">
                        {formatTelefone(bankAccount.phone)}
                      </p>
                      <div className="divider border-2 my-5 border-light opacity-2 rounded w-25" />
                    </div>
                  </div>
                </div>
                <div
                  className="hero-footer"
                  style={{ border: "1px solid white", borderRadius: 32 }}
                >
                  <div className="d-flex justify-content-center align-items-center" />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={7}>
              <div className="bg-white h-100">
                <Tabs
                  style={{ marginTop: "-45px" }}
                  value={value}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="fullWidth"
                >
                  <Tab className="py-3" label="Dados Bancários" />
                </Tabs>
                <TabPanel
                  value={value}
                  index={0}
                  style={{
                    width: "100%",
                    height: "88%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      height: "370px",
                      flexDirection: "column",
                      width: "465px",
                    }}
                  >
                    <StylizedInput
                      label="Banco"
                      inputName="bankName"
                      required
                      bankAccount={bankAccount}
                      setter={setBankAccount}
                      index={bankAccountDialog.index}
                      disabled
                      style={{ marginBottom: "20px" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <StylizedInput
                        label="Código do Banco"
                        inputName="bankCode"
                        required
                        bankAccount={bankAccount}
                        setter={setBankAccount}
                        index={bankAccountDialog.index}
                        disabled
                        style={{ marginBottom: "20px", width: "90%" }}
                      />

                      <StylizedInput
                        label="ISPB"
                        inputName="ispb"
                        required
                        bankAccount={bankAccount}
                        setter={setBankAccount}
                        index={bankAccountDialog.index}
                        disabled
                        style={{
                          marginBottom: "20px",
                          width: "120%",
                          marginLeft: "30px",
                        }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <StylizedInput
                        label="Agência"
                        inputName="bankBranch"
                        required
                        bankAccount={bankAccount}
                        setter={setBankAccount}
                        index={bankAccountDialog.index}
                        disabled
                        style={{ marginBottom: "20px", width: "90%" }}
                      />

                      <StylizedInput
                        label="Conta"
                        inputName="accountNumber"
                        required
                        bankAccount={bankAccount}
                        setter={setBankAccount}
                        index={bankAccountDialog.index}
                        disabled
                        style={{
                          marginBottom: "20px",
                          width: "120%",
                          marginLeft: "30px",
                        }}
                      />
                    </div>
                    <div
                      className="d-flex flex-row justify-content-between align-items-center mb-5"
                      style={{ marginLeft: "-10px", width: "105%" }}
                    >
                      {Number(bankAccount.accountTypeId) === 1 && (
                        <TextField
                          fullWidth
                          className="m-2"
                          id="outlined-select-currency"
                          select
                          label="Status*"
                          value={Number(bankAccount.payrollAccount.statusId)}
                          onChange={(e) => {
                            const newState = {
                              ...bankAccount,
                              payrollAccount: {
                                ...bankAccount.payrollAccount,
                                statusId: e.target.value,
                                statusName:
                                  Number(e.target.value) === 1
                                    ? "LIBERADA"
                                    : Number(e.target.value) === 2
                                    ? "BLOQUEADA"
                                    : Number(e.target.value) === 3
                                    ? "NEGADA"
                                    : Number(e.target.value) === 4
                                    ? "ABERTA"
                                    : "",
                              },
                            };
                            setBankAccount(newState);
                          }}
                          variant="outlined"
                        >
                          <MenuItem
                            disabled
                            style={{ fontSize: ".9em" }}
                            value={0}
                          >
                            Selecione uma opção
                          </MenuItem>
                          <MenuItem style={{ fontSize: ".9em" }} value={1}>
                            Liberada
                          </MenuItem>
                          <MenuItem style={{ fontSize: ".9em" }} value={2}>
                            Bloqueada
                          </MenuItem>
                          <MenuItem style={{ fontSize: ".9em" }} value={3}>
                            Negada
                          </MenuItem>
                          <MenuItem style={{ fontSize: ".9em" }} value={4}>
                            Aberta
                          </MenuItem>
                        </TextField>
                      )}
                    </div>
                    {Number(bankAccount.accountTypeId) === 1 && (
                      <div
                        style={{
                          display: "flex",
                          height: "370px",
                          flexDirection: "column",
                          width: "465px",
                        }}
                      >
                        <StylizedInput
                          label="AccountId"
                          inputName="accountId"
                          required
                          bankAccount={bankAccount}
                          setter={setBankAccount}
                          index={bankAccountDialog.index}
                          disabled
                          style={{ marginTop: "-25px" }}
                        />
                      </div>
                    )}
                    {updated ? (
                      <Tooltip
                        placement="left"
                        title="Salvo!"
                        style={{
                          borderRadius: "50%",
                          width: 56,
                          height: "auto",
                          backgroundColor: "#373A44",
                          padding: 16,
                          color: "#fff",
                          alignSelf: "flex-end",
                          boxShadow: "1px 3px 5px #888",
                          cursor: "pointer",
                          transitionDuration: 1,
                          marginTop: "20px",
                        }}
                      >
                        <IconButton
                          color="primary"
                          size="medium"
                          variant="outlined"
                          className="text-green"
                        >
                          <CheckOutlinedIcon style={{ color: "#45B465" }} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        placement="left"
                        title="Salvar"
                        style={{
                          borderRadius: "50%",
                          width: 56,
                          height: "auto",
                          backgroundColor: "#373A44",
                          padding: 16,
                          color: "#fff",
                          alignSelf: "flex-end",
                          boxShadow: "1px 3px 5px #888",
                          cursor: "pointer",
                          marginTop: "20px",
                        }}
                      >
                        <IconButton
                          color="primary"
                          size="medium"
                          variant="outlined"
                          className="text-white"
                          onClick={() => updateBankAccountData()}
                        >
                          <CheckOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TabPanel>
              </div>
            </Grid>
          </Grid>
        )}
      </Dialog>
    </>
  );
};

export default BankAccountFocus;
