import React, { useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";

import {
  Card,
  TextField,
  Tooltip,
  Grid,
  Button,
  IconButton,
  Box,
} from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import EditIcon from "@material-ui/icons/VpnKeyOutlined";

import defaultUser from "assets/images/avatars/default-user.png";

import formatTelefone from "utils/formatTelefone";

import { confirmationSwal } from "services/SwalService";
import { updatePassword } from "services/user";

import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";

import { useModal } from "hooks/useModal";

import User from "./classes/User";
import UserFocus from "./UserFocus";

import "../capa.scss";
import AxiosInterceptor from "utils/axios-interceptor";

const formatDate = (date) => {
  if (date === undefined) return "";
  return `${
    new Date(date).getDate() < 10
      ? `0${new Date(date).getDate()}`
      : new Date(date).getDate()
  }/${
    new Date(date).getMonth() + 1 < 10
      ? `0${new Date(date).getMonth() + 1}`
      : new Date(date).getMonth() + 1
  }/${new Date(date).getFullYear()}`;
};

export default (props) => {
  const { business, businessUsers, setBusinessUsers } = props;
  const { user, setSessionData, isAdmin } = useAuth();
  const { toast } = useToast();
  const { openModalTextInput } = useModal();

  const [userDialog, setUserDialog] = useState({ open: false });
  const [disableButton, setDisableButton] = useState(false);

  const history = useHistory();

  const stringAttributeIsInvalid = (attr) =>
    attr === undefined || attr === "" || attr === null;

  const changeBusinessUsers = (newState, index) => {
    const users = businessUsers;
    users[index] = newState;

    setBusinessUsers(users);
  };

  const handlerChange = (e, state, setter, index) => {
    const newState = { ...state, [e.target.name]: e.target.value };

    changeBusinessUsers(newState, index);

    setter(newState);
  };

  const addUser = () => {
    const users = businessUsers;
    if (users.some((user) => user.userId === undefined)) return;
    const newUser = new User();
    newUser.businessId = business.businessId;
    newUser.typeId = business.typeId;
    users.push(newUser);

    setBusinessUsers(users);
    setUserDialog({ open: false });
  };

  const removeUser = async (index) => {
    if (businessUsers.length === 1) return;

    const users = businessUsers;
    const removedUser = users[index];
    users.splice(index, 1);
    const axiosInstance = AxiosInterceptor();
    await axiosInstance.post(
      `${process.env.REACT_APP_NODE_API}/users/delete`,
      removedUser
    );

    setBusinessUsers(users);
    setUserDialog({ open: false });
  };
  const axiosInstance = AxiosInterceptor();

  const setGhostUser = useCallback(
    (userId) => {
      setDisableButton(true);
      toast.promise(
        axiosInstance.get(
          `${process.env.REACT_APP_NODE_API}/ghost-roles/${userId}`
        ),
        {
          loading: "Assumindo Papel",
          success: (response) => {
            setSessionData(response.data, true);
            return `Assumido papel do usuário: ${response.data.name}`;
          },
          error: (e) => {
            setDisableButton(false);
            return `Falha ao assumir papel.`;
          },
        }
      );
    },
    [toast]
  );

  const StylizedInput = ({
    className,
    label,
    inputName,
    disabled,
    required,
    user,
    setter,
    index,
    andError,
    filledState,
    orError,
    format,
    ...props
  }) => (
    <div className={`${className}`}>
      <TextField
        fullWidth
        className=""
        id={`${label}${index}`}
        label={`${label}${required ? "*" : ""}`}
        name={inputName}
        value={
          format
            ? format(user[inputName])
            : user[inputName] === null
            ? ""
            : user[inputName]
        }
        disabled={disabled}
        error={
          (orError !== undefined ? orError : false) ||
          ((andError !== undefined ? andError : true) &&
            stringAttributeIsInvalid(user[inputName]) &&
            !filledState &&
            required)
        }
        helperText={`${
          inputName === "accessId" && orError
            ? "Email já está sendo utilizado"
            : ""
        }`}
        variant="outlined"
        onChange={(e) => {
          handlerChange(e, user, setter, index);
        }}
        {...props}
      />
    </div>
  );

  const UserCard = (props) => {
    const { index, userItem, setUserDialog } = props;

    const [user, setUser] = useState(userItem);
    const [copied, setCopied] = useState(false);

    const textAreaRef = useRef(null);

    const copyToClipboard = (e) => {
      textAreaRef.current.select();
      document.execCommand("copy");
      e.target.focus();
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    };

    const handleUpdatePassword = async (user, newPassword) => {
      await toast.promise(updatePassword({ ...user, password: newPassword }), {
        loading: "Redefinindo senha",
        success: "Senha redefinida com sucesso!",
        error: "Falha ao redefinir senha",
      });
    };

    const handleOpenPasswordModal = (user) => {
      openModalTextInput(
        "textField",
        "Redefinir Senha",
        "password",
        "Redefinir",
        "Cancelar",
        handleUpdatePassword,
        user
      );
    };

    return (
      <Grid item xs={12} lg={4}>
        <Card className="card-box mb-4 pt-4 pb-2">
          <Tooltip
            title="Redefinir senha"
            aria-label="Redefinir senha"
            className="card-tr-actions"
          >
            <Box>
              <IconButton
                color="secondary"
                size="small"
                className="pr-0 pt-2"
                onClick={() => handleOpenPasswordModal(user)}
              >
                <EditIcon />
              </IconButton>
            </Box>
          </Tooltip>
          <div className="d-flex align-items-center px-4 mb-3">
            <div className="avatar-icon-wrapper rounded mr-3">
              <div className="d-block p-0 avatar-icon-wrapper m-0 d-100">
                <div className="rounded overflow-hidden">
                  <img
                    alt={user.name}
                    className="img-fluid"
                    src={user.photo ?? defaultUser}
                  />
                </div>
              </div>
            </div>
            <div className="w-100">
              <a
                href="#/"
                onClick={(e) => e.preventDefault()}
                className="font-weight-bold font-size-lg"
                title="..."
                style={{
                  maxWidth: "30ch",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "normal",
                  width: "190px",
                  display: "flex",
                }}
              >
                {user.name}
              </a>
              <span className="text-black-50 d-block pb-1">
                {business.name}
              </span>
              <div className="d-flex align-items-center justify-content-start pt-2">
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  className="mr-2"
                  onClick={() =>
                    setUserDialog({
                      open: true,
                      user,
                      setUser,
                      index,
                    })
                  }
                >
                  Editar
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  disabled
                >
                  {Number(user.statusId) === 1 ? "Ativo" : "Desativado"}
                </Button>
              </div>
              {isAdmin() && user.userId ? (
                <div className="d-flex align-items-center justify-content-start pt-2">
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    className="mr-2"
                    onClick={async () => {
                      const { isConfirmed } = await confirmationSwal(
                        "Você deseja assumir o papel?"
                      );

                      if (isConfirmed) {
                        setGhostUser(user.userId);
                      }
                    }}
                  >
                    Assumir Papel
                  </Button>
                </div>
              ) : (
                false
              )}
            </div>
          </div>
          <div className="font-size-sm p-3 my-3 bg-neutral-dark mx-4 rounded-sm">
            <div className="d-flex justify-content-between">
              <span className="font-weight-bold">Email:</span>
              {user.accessId?.length < 20 ? (
                <div className="d-flex flex-row align-items-end justify-content-end text-black-50 user-email">
                  <textarea
                    className="text-black-50 text-right"
                    value={`${user.accessId}`}
                    readOnly
                    ref={textAreaRef}
                    onClick={copyToClipboard}
                  />
                </div>
              ) : (
                <Tooltip title={user.accessId} aria-label={user.accessId}>
                  <div className="d-flex flex-row align-items-end justify-content-end text-black-50 user-email">
                    <textarea
                      className="text-black-50 text-right"
                      value={`${user.accessId}`}
                      readOnly
                      ref={textAreaRef}
                      onClick={copyToClipboard}
                    />
                    <span>...</span>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className="d-flex justify-content-between py-2">
              <span className="font-weight-bold">Telefone:</span>
              <span className="text-black-50">
                {formatTelefone(user.phone)}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="font-weight-bold">Acesso:</span>
              <span className="text-black-50">
                {formatDate(user.lastAccess)}
              </span>
            </div>
            <div className="d-flex justify-content-center">
              {copied ? (
                <span className="text-black-50">Email copiado!</span>
              ) : (
                <span className="text-black-50" style={{ minHeight: 24 }} />
              )}
            </div>
          </div>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      <Grid container spacing={4}>
        {businessUsers
          .filter((businessUser) => businessUser.accessId !== user.accessId)
          .map((user, index) => (
            <UserCard
              key={user.userId ?? index}
              userItem={user}
              setUserDialog={setUserDialog}
              isLast={index === businessUsers.length - 1}
              index={index}
            />
          ))}
        <Grid
          item
          xs={12}
          lg={4}
          className="d-flex justify-content-center align-items-center"
        >
          <Tooltip
            arrow
            placement="top"
            title="Adicionar Usuário"
            style={{
              borderRadius: "50%",
              width: 56,
              height: "auto",
              backgroundColor: "#373A44",
              padding: 16,
              color: "#fff",
              boxShadow: "1px 3px 5px #888",
              cursor: "pointer",
            }}
          >
            <IconButton
              color="primary"
              size="medium"
              variant="outlined"
              className="text-white"
              onClick={() => addUser()}
            >
              <AddOutlinedIcon className="font-size-lg" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <UserFocus
        business={business}
        userDialog={userDialog}
        setUserDialog={setUserDialog}
        users={businessUsers}
        StylizedInput={StylizedInput}
        removeUser={removeUser}
        changeBusinessUsers={changeBusinessUsers}
      />
    </>
  );
};
