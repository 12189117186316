import React, { useEffect, useState } from "react";

import PageTitle from "components/PageTitle";

import "./parcelas.scss";
import { useAuth } from "contexts/auth";
import AxiosInterceptor from "utils/axios-interceptor";

const Iframe = ({ source }) => {
  if (!source) {
    return <div>Loading...</div>;
  }

  const src = source;
  return (
    <iframe
      title="BI"
      height={1300}
      src={src}
      frameBorder={0}
      className="frame"
      style={{ width: "100%" }}
    />
  );
};

export default function ParcelasFinanciador() {
  const [metabaseEmbedURL, setMetabaseEmbedURL] = useState("");
  const { user } = useAuth();

  useEffect(() => {
    getMetabase();
  }, []);

  const getMetabase = async () => {
    const axiosInstance = AxiosInterceptor();
    const { data } = await axiosInstance.get(
      `${process.env.REACT_APP_NODE_API}/business/metabase/parcelasfin/${user.businessId}`
    );
    setMetabaseEmbedURL(data);
  };
  return (
    <>
      <div className="w-100">
        <PageTitle titleHeading="Parcelas" />
        <div style={{ marginTop: "50px", marginLeft: "-30px", width: "100%" }}>
          {metabaseEmbedURL !== "" && <Iframe source={metabaseEmbedURL} />}
        </div>
      </div>
    </>
  );
}
