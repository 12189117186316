import React from 'react';

export default function Papers({ color = '#A0A0A0', size = 40 }) {
  return (
    <svg
      id="_x32_2_documents_2_"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 122.66) / 100}
      viewBox="0 0 32.646 40.042"
    >
      <path
        id="Caminho_82"
        data-name="Caminho 82"
        d="M184.071,163.406h15.654a.587.587,0,1,0,0-1.173H184.071a.587.587,0,0,0,0,1.173Z"
        transform="translate(-172.832 -149.545)"
      />
      <path
        id="Caminho_83"
        data-name="Caminho 83"
        d="M184.071,218.994h15.654a.587.587,0,1,0,0-1.173H184.071a.587.587,0,0,0,0,1.173Z"
        transform="translate(-172.832 -200.786)"
      />
      <path
        id="Caminho_84"
        data-name="Caminho 84"
        d="M184.071,274.582h15.654a.587.587,0,1,0,0-1.173H184.071a.587.587,0,0,0,0,1.173Z"
        transform="translate(-172.832 -252.026)"
      />
      <path
        id="Caminho_85"
        data-name="Caminho 85"
        d="M79.931,7.51a.592.592,0,0,0-.171-.4L72.817.172A.594.594,0,0,0,72.4,0H55.071a2.3,2.3,0,0,0-2.3,2.3v.442h-.442A2.3,2.3,0,0,0,50.041,4.8h-.455a2.3,2.3,0,0,0-2.3,2.3V26.816a.587.587,0,1,0,1.173,0V7.1a1.129,1.129,0,0,1,1.127-1.127h.442V35.685a2.3,2.3,0,0,0,2.3,2.3H67.25a.587.587,0,0,0,0-1.173H52.328A1.129,1.129,0,0,1,51.2,35.685V5.042a1.129,1.129,0,0,1,1.127-1.127h.442v3.2a.587.587,0,0,0,1.173,0V2.3a1.129,1.129,0,0,1,1.127-1.127H71.816V5.816a2.3,2.3,0,0,0,2.3,2.3h4.643V32.943a1.129,1.129,0,0,1-1.127,1.127H55.071a1.128,1.128,0,0,1-1.127-1.127V9.667a.587.587,0,0,0-1.173,0V32.943a2.3,2.3,0,0,0,2.3,2.3H76.017v.442a1.129,1.129,0,0,1-1.127,1.127H69.806a.587.587,0,1,0,0,1.173h3.441a1.129,1.129,0,0,1-1.1.883H49.586a1.129,1.129,0,0,1-1.127-1.127v-8.37a.587.587,0,1,0-1.173,0v8.37a2.3,2.3,0,0,0,2.3,2.3H72.147a2.3,2.3,0,0,0,2.287-2.057h.455a2.3,2.3,0,0,0,2.3-2.3v-.442h.442a2.3,2.3,0,0,0,2.3-2.3V7.529C79.932,7.523,79.931,7.516,79.931,7.51Zm-5.815-.567a1.128,1.128,0,0,1-1.127-1.127V2l4.94,4.94Z"
        transform="translate(-47.286 0)"
      />
    </svg>
  );
}
