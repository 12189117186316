/* eslint-disable react/display-name */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/button-has-type */

import React from 'react';
import ReactFileReader from 'react-file-reader';

import { ReactIf, ReactThen } from 'components/ReactIf';

import './agendaRecebiveis.scss';

export default (props) => {
  const { fileUploadState, setFileUploadState, setFileUploadData } = props;
  const handleFiles = (files) => {
    const arquivos = [];
    const lista = files;
    Array.from(lista).forEach((e) => {
      arquivos.push(e);
    });
    setFileUploadData(files);
    setFileUploadState(arquivos);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          width: '60%',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px',
          textAlign: 'center',
          border: '1px dashed #ddd',
          color: '#999',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
        className={
          fileUploadState.length === 0 ? 'react-file-empty' : 'react-file'
        }
      >
        <input
          id="fileButton"
          type="file"
          accept="pdf/*"
          style={{ display: 'none', width: '100%' }}
        />
        <ReactFileReader
          fileTypes={['.pdf', '.xml']}
          base64={false}
          multipleFiles={false}
          handleFiles={handleFiles}
        >
          <h4
            className="text-center"
            style={{ fontFamily: 'Poppins', fontWeight: 400, color: '#999' }}
          >
            {fileUploadState.length === 0
              ? 'Adicione aqui o XML da sua Nota Fiscal Eletrônica.'
              : 'Clique novamente caso deseje substituir a Nota Fiscal Eletrônica escolhida.'}
          </h4>
          <ReactIf condition={fileUploadState.length !== 0}>
            <ReactThen>
              <br />
              <br />
              <div style={{ marginTop: '-40px', paddingBottom: 30 }}>
                {fileUploadState.map((file) => (
                  <span key={file.name}>
                    {file.name} - {file.size}kb
                    <br />
                  </span>
                ))}
              </div>
            </ReactThen>
          </ReactIf>
        </ReactFileReader>
      </div>
    </>
  );
};
