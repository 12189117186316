export const headCells = [
  {
    id: '#',
    label: '',
    minWidth: 50,
    backgroundColor: '#fafafa',
  },
  {
    id: 'niFornecedor',
    label: 'Empresa',
    minWidth: 130,
    maxWidth: 130,
    backgroundColor: '#fafafa',
  },
  {
    id: 'valorCotacao',
    label: 'Cotação Solicitada',
    minWidth: 200,
    maxWidth: 216,
    backgroundColor: '#fafafa',
  },
  {
    id: 'margemDisponivel',
    label: 'Margem Disponível',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'dataCotacao',
    label: 'Data da Cotação',
    minWidth: 150,
    maxWidth: 200,
    backgroundColor: '#fafafa',
  },
  {
    id: 'preAprovado.taxa12',
    label: 'Taxas 12/24/36',
    minWidth: 150,
    maxWidth: 185,
    backgroundColor: '#fafafa',
  },
  {
    id: 'preAprovado.valorAprovado',
    label: 'Pré-Aprovado',
    minWidth: 125,
    maxWidth: 175,
    backgroundColor: '#fafafa',
  },
  {
    id: 'preAprovado.prazoMaximo',
    label: 'Prazo Máximo',
    minWidth: 125,
    maxWidth: 175,
    backgroundColor: '#fafafa',
  },
  {
    id: 'mais',
    label: '',
    minWidth: 50,
    backgroundColor: '#fafafa',
  },
];
