export const headCells = [
  {
    id: 'tagId',
    label: 'ID',
    minWidth: 50,
    backgroundColor: '#fafafa',
  },
  // {
  //   id: 'typeId',
  //   label: 'Tipo ID',
  //   minWidth: 130,
  //   maxWidth: 130,
  //   backgroundColor: '#fafafa',
  // },
  // {
  //   id: 'typeName',
  //   label: 'Tipo',
  //   minWidth: 200,
  //   maxWidth: 216,
  //   backgroundColor: '#fafafa',
  // },
  {
    id: 'name',
    label: 'Tag',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
  {
    id: 'acoes',
    label: 'Ações',
    minWidth: 140,
    maxWidth: 220,
    backgroundColor: '#fafafa',
  },
];

export const tagTypes = [
  {
    value: 1,
    label: 'Título',
  },
  {
    value: 2,
    label: 'Agenda',
  },
];
