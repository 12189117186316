/* eslint eqeqeq: 0 */
import React, { useState, useEffect } from 'react';

import { IconButton, TextField, Tooltip } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import Content from 'example-components/content';
import ColorPicker from 'material-ui-color-picker';

import { ConfirmButton } from 'components/Button';

import { useToast } from 'contexts/toast';

import { useCadastroOrigin } from 'hooks/useCadastroOrigin';

function CadastroOrigin(props) {
  const { origin, blank, edit } = props;
  const [values, setValues] = useState({
    businessId: origin?.businessId || null,
    favIcon: origin?.favIcon || null,
    hostname: origin?.hostname || null,
    logoLogin: origin?.logoLogin || null,
    logoTopo: origin?.logoTopo || null,
    originTypeId: origin?.originTypeId || null,
    originTypeName: origin?.originTypeName || null,
    originTypeShort: origin?.originTypeShort || null,
    telaLogin: origin?.telaLogin || null,
    tema: {
      adm: {
        primary: origin?.tema?.adm?.primary || null,
        secondary: origin?.tema?.adm?.secondary || null,
      },

      ced: {
        primary: origin?.tema?.ced?.primary || null,
        secondary: origin?.tema?.ced?.secondary || null,
      },

      default: {
        primary: origin?.tema?.default?.primary || null,
        secondary: origin?.tema?.default?.secondary || null,
      },

      fin: {
        primary: origin?.tema?.fin?.primary || null,
        secondary: origin?.tema?.fin?.secondary || null,
      },
    },
  });

  const { uploadDocuments, docsEnum, enviarFormulario } = useCadastroOrigin();
  const { toast } = useToast();

  useEffect(() => {
    if (blank) {
      setValues({ ...values });
    }
    setValues({ ...values });
  }, []);

  const handlerChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handlerChangeColorDefault = (e, nome, campo) => {
    setValues({
      ...values,
      tema: { ...values.tema, [campo]: { ...values.tema.default, [nome]: e } },
    });
  };

  const handlerChangeColorAdm = (e, nome, campo) => {
    setValues({
      ...values,
      tema: { ...values.tema, [campo]: { ...values.tema.adm, [nome]: e } },
    });
  };

  const handlerChangeColorCed = (e, nome, campo) => {
    setValues({
      ...values,
      tema: { ...values.tema, [campo]: { ...values.tema.ced, [nome]: e } },
    });
  };

  const handlerChangeColorFin = (e, nome, campo) => {
    setValues({
      ...values,
      tema: { ...values.tema, [campo]: { ...values.tema.fin, [nome]: e } },
    });
  };

  async function changeFile(event) {
    const { files, name } = event.target;

    try {
      const documentType = docsEnum.filter((eDoc) => eDoc.key == name)[0];

      if (!documentType) return;

      const uploadedDocument = await uploadDocuments(
        files,
        values.originTypeId,
        documentType.documentTypeId,
        documentType.documentTypeName
      );

      handlerChange({ target: { value: uploadedDocument[0].key, name } });
    } catch (error) {
      toast.error(`Erro ao enviar ${files[0].name}`);
    }
  }

  const UploadButton = ({ name }) => (
    <Tooltip
      placement="right"
      style={{
        position: 'unset',
        marginTop: 12,
        zIndex: 999,
        marginLeft: '-5px',
      }}
      title="Anexar Documento"
      aria-label="attach-file"
      className="card-tr-actions"
    >
      <div className="">
        <input
          accept={'image/*'}
          className="d-none"
          id={name}
          type="file"
          onChange={changeFile}
          name={name}
          // onChange={(e) => {
          //   uploadDocuments(e.target.files, typeId, typeName);
          // }}
        />
        <label htmlFor={name}>
          <IconButton color="primary" aria-label="attach-file" component="span">
            <AttachFileIcon />
          </IconButton>
        </label>
      </div>
    </Tooltip>
  );

  return (
    <Content>
      <div className="example-card-seamless mb-4-spacing">
        {/* <h5 className="display-5 mb-4 font-weight-bold" /> */}
      </div>

      <div style={{ marginTop: '30px', marginLeft: '-65px', width: '105%' }}>
        <div className="searchForm" style={{ marginLeft: '65px' }}>
          <div
            className="containerTitle"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <h2 style={{ fontFamily: 'Poppins', alignSelf: 'center' }}>
              Preencha todos os campos para criação da Whitelabel
            </h2>
          </div>
          <div className="containerTitle w-100 mt-4">
            <div className="d-flex flex-row justify-content-between w-100">
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Sigla"
                  placeholder="AF"
                  name="originTypeShort"
                  value={values?.originTypeShort || ''}
                  variant="outlined"
                  onChange={(e) => handlerChange(e)}
                  inputProps={{ maxLength: 4 }}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Nome da Empresa"
                  name="originTypeName"
                  value={values?.originTypeName || ''}
                  variant="outlined"
                  onChange={(e) => handlerChange(e)}
                />
              </div>
              <div className="item pr-2 w-100 ">
                <TextField
                  fullWidth
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  id="outlined-basic"
                  label="Site"
                  placeholder="https://www.empresa.com.br"
                  name="hostname"
                  value={values?.hostname || ''}
                  variant="outlined"
                  onChange={(e) => handlerChange(e)}
                />
              </div>
            </div>

            <div className="d-flex flex-row justify-content-between w-100">
              <div className="item pr-2 w-50 ">
                <ColorPicker
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  label="Selecione a cor principal"
                  name="default.primary"
                  value={values?.tema?.default?.primary || ''}
                  onChange={(color) =>
                    handlerChangeColorDefault(color, 'primary', 'default')
                  }
                  TextFieldProps={{
                    value: values?.tema?.default?.primary,
                    variant: 'outlined',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                <ColorPicker
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  label="Selecione a cor secundária"
                  name="default.secondary"
                  value={values?.tema.default?.secondary || ''}
                  onChange={(color) =>
                    handlerChangeColorDefault(color, 'secondary', 'default')
                  }
                  TextFieldProps={{
                    value: values?.tema.default?.secondary,
                    variant: 'outlined',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-between w-100">
              <h4 style={{ fontFamily: 'Poppins, sans-serif' }}>
                Selecione as cores do Administrador
              </h4>

              <div className="item pr-2 w-50 ">
                <ColorPicker
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  label="Selecione a cor principal"
                  name="adm.primary"
                  value={values?.tema?.adm?.primary || ''}
                  onChange={(color) =>
                    handlerChangeColorAdm(color, 'primary', 'adm')
                  }
                  TextFieldProps={{
                    value: values?.tema?.adm?.primary,
                    variant: 'outlined',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                <ColorPicker
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  label="Selecione a cor secundária"
                  name="adm.secondary"
                  value={values?.tema?.adm?.secondary || ''}
                  onChange={(color) =>
                    handlerChangeColorAdm(color, 'secondary', 'adm')
                  }
                  TextFieldProps={{
                    value: values?.tema?.adm?.secondary,
                    variant: 'outlined',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-between w-100">
              <h4 style={{ fontFamily: 'Poppins, sans-serif' }}>
                Selecione as cores do Cedente
              </h4>

              <div className="item pr-2 w-50 ">
                <ColorPicker
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  label="Selecione a cor principal"
                  name="ced.primary"
                  value={values?.tema?.ced?.primary || ''}
                  onChange={(color) =>
                    handlerChangeColorCed(color, 'primary', 'ced')
                  }
                  TextFieldProps={{
                    value: values?.tema?.ced?.primary,
                    variant: 'outlined',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                <ColorPicker
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  label="Selecione a cor secundária"
                  name="ced.secondary"
                  value={values?.tema?.ced?.secondary || ''}
                  onChange={(color) =>
                    handlerChangeColorCed(color, 'secondary', 'ced')
                  }
                  TextFieldProps={{
                    value: values?.tema?.ced?.secondary,
                    variant: 'outlined',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-between w-100">
              <h4 style={{ fontFamily: 'Poppins, sans-serif' }}>
                Selecione as cores do Financiador
              </h4>

              <div className="item pr-2 w-50 ">
                <ColorPicker
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  label="Selecione a cor principal"
                  name="fin.primary"
                  value={values?.tema?.fin?.primary || ''}
                  onChange={(color) =>
                    handlerChangeColorFin(color, 'primary', 'fin')
                  }
                  TextFieldProps={{
                    value: values?.tema?.fin?.primary,
                    variant: 'outlined',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
                <ColorPicker
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
                  label="Selecione a cor secundária"
                  name="fin.secondary"
                  value={values?.tema?.fin?.secondary || ''}
                  onChange={(color) =>
                    handlerChangeColorFin(color, 'secondary', 'fin')
                  }
                  TextFieldProps={{
                    value: values?.tema?.fin?.secondary,
                    variant: 'outlined',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>

            <div className="d-flex flex-column justify-content-between w-100">
              <div
                className="item pr-2 w-100"
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginBottom: '-15px',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Poppins, sans-serif',
                    textAlign: 'center',
                  }}
                >
                  1. Adicione sua logo principal:
                </p>
                <UploadButton name="logoLogin" />
                {values.logoLogin && values.logoLogin}
              </div>
              <div
                className="item pr-2 w-100"
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginBottom: '-15px',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Poppins, sans-serif',
                    textAlign: 'center',
                  }}
                >
                  2. Adicione sua logo para o topo:
                </p>
                <UploadButton name="logoTopo" />
                {values.logoTopo && values.logoTopo}
              </div>
              <div
                className="item pr-2 w-100"
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginBottom: '-15px',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Poppins, sans-serif',
                    textAlign: 'center',
                  }}
                >
                  3. Adicione um icone:
                </p>
                <UploadButton name="favIcon" />
                {values.favIcon && values.favIcon}
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <ConfirmButton
              onClick={() => {
                enviarFormulario(values);
              }}
            >
              {edit ? 'Atualizar' : 'Cadastrar'}
            </ConfirmButton>
          </div>
        </div>
      </div>
    </Content>
  );
}

export default CadastroOrigin;
