import React, { useState } from 'react';
import ReactPlayer from 'react-player';

import '../capa.scss';
import {
  Card,
  CardContent,
  Checkbox,
  TextField,
  Tooltip,
  IconButton,
  Button,
  Menu,
  List,
  ListItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FolderIcon from '@material-ui/icons/Folder';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import UndoIcon from '@material-ui/icons/Undo';

import { saveAs } from 'file-saver';
import jszip from 'jszip';

import { openDocumentWithUrlUtil } from 'utils/openDocuments';

import { downloadAWSFile, updateDossie } from 'services/dossie';

import { useToast } from 'contexts/toast';

export default (props) => {
  const { toast } = useToast();
  const {
    business,
    businessDocuments,
    setBusinessDocuments,
    refreshBusinessData,
  } = props;

  const updateDocument = async (document, documents, setDocuments) => {
    try {
      const promises = [refreshBusinessData(business), updateDossie(document)];

      await toast.promise(Promise.all(promises), {
        loading: 'Atualizando dados',
        success: 'Atualizado com sucesso!',
        error: 'Erro ao atualizar!',
      });

      const updatedDocs = documents.map((doc) => {
        if (doc.fileId === document.fileId) return document;
        return doc;
      });
      setDocuments(updatedDocs);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const ModifyDescriptionModal = (props) => {
    const {
      open,
      handleClose,
      initialDescripiton,
      document,
      documents,
      setDocuments,
    } = props;
    const [newDescription, setNewDescription] = useState(
      initialDescripiton ?? ''
    );

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Alterar Descrição</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Descrição do Documento"
            type="email"
            fullWidth
            value={newDescription || document.name}
            onChange={(e) => setNewDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              const updatedDoc = document;
              updatedDoc.description = newDescription;
              updateDocument(updatedDoc, documents, setDocuments);
              handleClose();
            }}
            color="primary"
          >
            Alterar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const VideoModal = (props) => {
    const { open, handleClose, video } = props;
    const videoHeight = (width) => (9 * width) / 16;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="md"
      >
        <ReactPlayer
          url={video}
          controls
          playing
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          width={960}
          height={videoHeight(960)}
        />
      </Dialog>
    );
  };

  const DocumentCard = (props) => {
    const { document, documents, setDocuments } = props;

    const documentState = document;
    const [anchorElMenu4, setAnchorElMenu4] = useState(null);
    const [descriptionDialogOpen, setDescriptionDialogOpen] = useState(false);

    const handleOpenMenu = (e) => {
      setAnchorElMenu4(e.currentTarget);
    };

    const handleCloseMenu4 = () => {
      setAnchorElMenu4(null);
    };

    const handleCloseDescriptionDialog = () => {
      setDescriptionDialogOpen(false);
    };

    return (
      <>
        <Card className="card-box d-flex flex-row align-items-center w-100">
          <div className="card-indicator bg-info" />
          <CardContent className="pr-4 py-1 d-flex flex-row align-items-center justify-content-between w-100">
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  {/* <div className="px-3 badge badge-info">Dossiê</div> */}
                  <a
                    href={documentState.url}
                    target="_blank"
                    className=""
                    rel="noreferrer"
                  >
                    {documentState.description === null ||
                    documentState.description === undefined
                      ? documentState.name
                      : documentState.description}
                  </a>
                </div>
                <div className="d-flex flex-row align-items-center">
                  {documentState.inDossie === 1 && (
                    <Tooltip
                      placement="top"
                      style={{ position: 'unset', marginLeft: 4 }}
                      title="Desfazer Ação"
                      aria-label="modify-description"
                      className="card-tr-actions"
                    >
                      <div className="">
                        <IconButton
                          aria-label="modify-description"
                          onClick={() => {
                            const updatedDoc = documentState;
                            updatedDoc.inDossie = 0;
                            updateDocument(updatedDoc, documents, setDocuments);
                          }}
                        >
                          <UndoIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}
                  <Tooltip
                    placement="top"
                    style={{ position: 'unset', marginLeft: 4 }}
                    title="Abrir"
                    aria-label="modify-description"
                    className="card-tr-actions"
                  >
                    <div className="">
                      <IconButton
                        aria-label="modify-description"
                        onClick={() => {
                          const { url } = documentState;
                          openDocumentWithUrlUtil(url);
                          handleCloseMenu4();
                        }}
                      >
                        <FolderIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                  <Tooltip
                    placement="top"
                    style={{ position: 'unset', marginLeft: 4 }}
                    title="Baixar"
                    aria-label="modify-description"
                    className="card-tr-actions"
                  >
                    <div className="">
                      <IconButton
                        aria-label="modify-description"
                        onClick={() => {
                          window.open(documentState.downloadUrl, 'blank');
                          handleCloseMenu4();
                        }}
                      >
                        <SaveAltIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center">
              <div>
                <IconButton
                  aria-label="menu"
                  className="m-2"
                  onClick={(e) => handleOpenMenu(e)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorElMenu4}
                  keepMounted
                  open={Boolean(anchorElMenu4)}
                  onClose={handleCloseMenu4}
                  classes={{ list: 'p-0' }}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <div className="overflow-hidden dropdown-menu-xl">
                    <List className="flex-column">
                      {documentState.inDossie === 1 && (
                        <ListItem
                          button
                          onClick={() => {
                            const updatedDoc = documentState;
                            updatedDoc.inDossie = 0;
                            updateDocument(updatedDoc, documents, setDocuments);
                          }}
                        >
                          <UndoIcon />
                          <span className="ml-2">Desfazer Ação</span>
                        </ListItem>
                      )}
                      <ListItem
                        button
                        onClick={() => {
                          setDescriptionDialogOpen(true);
                          handleCloseMenu4();
                        }}
                      >
                        <EditIcon />
                        <span className="ml-2">Alterar Descrição</span>
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => {
                          const { url } = documentState;
                          openDocumentWithUrlUtil(url);
                          handleCloseMenu4();
                        }}
                      >
                        <FolderIcon />
                        <span className="ml-2">Abrir</span>
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => {
                          window.open(documentState.downloadUrl, 'blank');
                          handleCloseMenu4();
                        }}
                      >
                        <SaveAltIcon />
                        <span className="ml-2">Baixar</span>
                      </ListItem>
                      <Divider />
                      <ListItem
                        button
                        onClick={() => {
                          const updatedDoc = documentState;
                          updatedDoc.statusId = 6;
                          updatedDoc.statusName = 'Excluido';
                          updateDocument(updatedDoc, documents, setDocuments);
                        }}
                      >
                        <DeleteIcon />
                        <span className="ml-2">Excluir</span>
                      </ListItem>
                    </List>
                  </div>
                </Menu>
              </div>
            </div>
          </CardContent>
        </Card>
        <ModifyDescriptionModal
          open={descriptionDialogOpen}
          handleClose={handleCloseDescriptionDialog}
          initialDescripiton={documentState.description}
          document={documentState}
          documents={documents}
          setDocuments={setDocuments}
        />
      </>
    );
  };

  const VideoCard = (props) => {
    const { document, documents, setDocuments } = props;

    const documentState = document;
    const [anchorElMenu4, setAnchorElMenu4] = useState(null);
    const [descriptionDialogOpen, setDescriptionDialogOpen] = useState(false);
    const [playVideo, setPlayVideo] = useState(undefined);

    const handleOpenMenu = (e) => {
      setAnchorElMenu4(e.currentTarget);
    };

    const handleCloseMenu4 = () => {
      setAnchorElMenu4(null);
    };

    const handleCloseDescriptionDialog = () => {
      setDescriptionDialogOpen(false);
    };

    return (
      <>
        <Card className="card-box d-flex flex-row align-items-center w-100">
          <div className="card-indicator bg-info" />
          <CardContent className="pr-4 py-1 d-flex flex-row align-items-center justify-content-between w-100">
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-start">
                  {/* <div className="px-3 badge badge-info">Dossiê</div> */}
                  <Button>
                    {documentState.description === null ||
                    documentState.description === undefined
                      ? documentState.name
                      : documentState.description}
                  </Button>
                </div>
                <div className="d-flex flex-row align-items-center">
                  {documentState.inDossie === 1 && (
                    <Tooltip
                      placement="top"
                      style={{ position: 'unset', marginLeft: 4 }}
                      title="Desfazer Ação"
                      aria-label="modify-description"
                      className="card-tr-actions"
                    >
                      <div className="">
                        <IconButton
                          aria-label="modify-description"
                          onClick={() => {
                            const updatedDoc = documentState;
                            updatedDoc.inDossie = 0;
                            updateDocument(updatedDoc, documents, setDocuments);
                          }}
                        >
                          <UndoIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )}
                  <Tooltip
                    placement="top"
                    style={{ position: 'unset', marginLeft: 4 }}
                    title="Reproduzir Vídeo"
                    aria-label="modify-description"
                    className="card-tr-actions"
                  >
                    <div className="">
                      <IconButton
                        aria-label="modify-description"
                        onClick={() => {
                          setPlayVideo(documentState.url);
                          handleCloseMenu4();
                        }}
                      >
                        <PlayArrowIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-center">
              <div>
                <IconButton
                  aria-label="menu"
                  className="m-2"
                  onClick={(e) => handleOpenMenu(e)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorElMenu4}
                  keepMounted
                  open={Boolean(anchorElMenu4)}
                  onClose={handleCloseMenu4}
                  classes={{ list: 'p-0' }}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <div className="overflow-hidden dropdown-menu-xl">
                    <List className="flex-column">
                      {documentState.inDossie === 1 && (
                        <ListItem
                          button
                          onClick={() => {
                            const updatedDoc = documentState;
                            updatedDoc.inDossie = 0;
                            updateDocument(updatedDoc, documents, setDocuments);
                          }}
                        >
                          <UndoIcon />
                          <span className="ml-2">Desfazer Ação</span>
                        </ListItem>
                      )}
                      <ListItem
                        button
                        onClick={() => {
                          setDescriptionDialogOpen(true);
                          handleCloseMenu4();
                        }}
                      >
                        <EditIcon />
                        <span className="ml-2">Alterar Descrição</span>
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => {
                          const { url } = documentState;
                          openDocumentWithUrlUtil(url);
                          handleCloseMenu4();
                        }}
                      >
                        <FolderIcon />
                        <span className="ml-2">Abrir</span>
                      </ListItem>
                      <ListItem
                        button
                        onClick={() => {
                          window.open(documentState.downloadUrl, 'blank');
                          handleCloseMenu4();
                        }}
                      >
                        <SaveAltIcon />
                        <span className="ml-2">Baixar</span>
                      </ListItem>
                      <Divider />
                      <ListItem
                        button
                        onClick={() => {
                          const updatedDoc = documentState;
                          updatedDoc.statusId = 6;
                          updatedDoc.statusName = 'Excluido';
                          updateDocument(updatedDoc, documents, setDocuments);
                        }}
                      >
                        <DeleteIcon />
                        <span className="ml-2">Excluir</span>
                      </ListItem>
                    </List>
                  </div>
                </Menu>
              </div>
            </div>
          </CardContent>
        </Card>
        <VideoModal
          open={playVideo}
          handleClose={() => setPlayVideo(undefined)}
          video={playVideo}
        />
        <ModifyDescriptionModal
          open={descriptionDialogOpen}
          handleClose={handleCloseDescriptionDialog}
          initialDescripiton={documentState.description}
          document={documentState}
          documents={documents}
          setDocuments={setDocuments}
        />
      </>
    );
  };

  // const IncludePendingButton = () => {
  //   const [includePendingDialogOpen, setIncludePendingDialogOpen] =
  //     useState(false);
  //   const handleCloseIncludePendingDialog = () => {
  //     setIncludePendingDialogOpen(false);
  //   };

  //   return (
  //     <>
  //       <Button
  //         variant="contained"
  //         className="m-2 btn text-white"
  //         style={{ backgroundColor: '#373A44' }}
  //         onClick={() => {
  //           setIncludePendingDialogOpen(true);
  //         }}
  //       >
  //         Incluir Pendência
  //       </Button>
  //       <IncludePendingModal
  //         open={includePendingDialogOpen}
  //         handleClose={handleCloseIncludePendingDialog}
  //         businessId={business.businessId}
  //       />
  //     </>
  //   );
  // };

  const CheckboxList = ({ docs, cardHeight }) => {
    const [selectedDocs, setSelectedDocs] = useState({});
    const [compressing, setCompressing] = useState(false);

    const handleCheckboxChange = (event, checked) => {
      setSelectedDocs({ ...selectedDocs, [event.target.name]: checked });
    };

    const checkAll = (check) => {
      let all = {};
      businessDocuments
        .filter((doc) => {
          if (doc.statusName == 'Excluido') return false;
          if (Number(doc.originTypeId) === 1) return Number(doc.inDossie) === 1;
          if (Number(doc.originTypeId) === 5) return true;
          return false;
        })
        .forEach((doc) => {
          all = { ...all, [doc.fileId]: check };
        });
      setSelectedDocs(all);
    };

    const saveAsZIP = async (documentsChecked) => {
      if (Object.keys(documentsChecked).length === 0) return;
      setCompressing(true);
      const documents = businessDocuments.filter(
        (doc) => documentsChecked[doc.fileId] && doc.documentTypeId !== 55
      );

      const zip = jszip();

      const files = [];

      for await (const doc of documents) {
        const file = await downloadAWSFile(doc.url, doc.name);
        files.push(file);
      }

      files.forEach((file) => zip.file(file.name, file.data));
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `Dossie_${business.countryIdentity}.zip`);
        setCompressing(false);
      });
    };

    return (
      <div className="d-flex flex-column space-around">
        {docs
          .filter((doc) => {
            if (doc.statusName == 'Excluido') return false;
            if (Number(doc.originTypeId) === 1)
              return Number(doc.inDossie) === 1;
            if (Number(doc.originTypeId) === 5) return true;
            return false;
          })
          .map((doc) => (
            <div
              key={`${doc.fileId}checkbox`}
              className="d-flex justify-content-center align-items-center"
              style={{ height: cardHeight }}
            >
              <Checkbox
                name={doc.fileId}
                checked={!!selectedDocs[doc.fileId]}
                onChange={handleCheckboxChange}
              />
            </div>
          ))}
        <div
          style={{
            position: 'absolute',
            right: '40px',
            top: '50px',
            marginRight: '35px',
          }}
        >
          <Button
            variant="contained"
            className="text-white"
            style={{
              backgroundColor: compressing ? '#878a94' : '#373A44',
            }}
            disabled={compressing}
            onClick={() => saveAsZIP(selectedDocs)}
          >
            Baixar selecionados
          </Button>
          {compressing && (
            <CircularProgress
              size={24}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: -12,
                marginLeft: -12,
                color: 'green',
              }}
            />
          )}
        </div>

        <Checkbox
          style={{ position: 'absolute', top: 48, left: 52 }}
          onChange={(e) => checkAll(e.target.checked)}
        />
      </div>
    );
  };

  return (
    <div className="w-100 pb-5 mb-2" style={{ position: 'relative' }}>
      <div className="searchForm">
        <div className="containerTitle w-100 mt-4">
          <div className="itemTile d-flex flex-row justify-content-between align-items-center w-100">
            <h3 style={{ marginLeft: 32 }}>Dossiê</h3>
          </div>
        </div>
        <div className="d-flex flex-row">
          <CheckboxList docs={businessDocuments} cardHeight={72} />
          <div className="d-flex flex-column h-100 w-100">
            {businessDocuments
              .filter((doc) => {
                if (doc.statusName == 'Excluido') return false;
                if (Number(doc.originTypeId) === 1)
                  return Number(doc.inDossie) === 1;
                if (Number(doc.originTypeId) === 5) return true;
                return false;
              })
              .map((doc) => (
                <div key={doc.fileId}>
                  {doc.documentTypeId !== 55 ? (
                    <DocumentCard
                      document={doc}
                      documents={businessDocuments}
                      setDocuments={setBusinessDocuments}
                    />
                  ) : (
                    <VideoCard
                      document={doc}
                      documents={businessDocuments}
                      setDocuments={setBusinessDocuments}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
