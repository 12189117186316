import axios from 'axios';

export async function getOriginTypeList() {
  const options = {
    method: 'GET',
    url: `${process.env.REACT_APP_NODE_API}/originType`,
  };

  const { data } = await axios.request(options);

  return data;
}

export async function getOriginTypeUserId(userId) {
  const options = {
    method: 'GET',
    url: `${process.env.REACT_APP_NODE_API}/users/origin/userId/${userId}`,
  };

  const { data } = await axios.request(options);

  return data;
}
