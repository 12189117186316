/* eslint eqeqeq: 0 */
import { useEffect, useState } from 'react';

import { FormControl, MenuItem, TextField } from '@material-ui/core';
import '../capa.scss';

import { AutoCompleteSelect } from 'components/AutoCompleteSelect';
import { ConfirmButton } from 'components/Button';
import { ReactIf, ReactThen } from 'components/ReactIf';
import { StatusCadastro } from 'components/StatusCadastro';

import formatTelefone from 'utils/formatTelefone';

import { getSellerMarket, updateBusinessService } from 'services/business';
import { sendBusinessNotification } from 'services/businessNotification';
import {
  getRegisterBusinessId,
  getSellerBuyers,
  updateSellerBuyers,
  updateSellerMarket
} from 'services/register';
import {
  emailCadastroAguardandoDocumentacao, emailCadastroAprovado, emailCadastroExpirado, emailCadastroNegado
} from 'services/sendgrid';
import { GetBuyers } from 'services/tipo-mercado-services';

import { useAuth } from 'contexts/auth';
import { useToast } from 'contexts/toast';

import { useModal } from 'hooks/useModal';

import Autocomplete from './AutoComplete';
import {
  marketTypes, motives, motivesIdsByStatusType, radioStates, status, statusCadastro, statusFinal
} from './items';

export default (props) => {
  const { business, setBusiness, businessInitial, initialRadio, businesses } =
    props;
  const { isAdmin, userOrigin, user } = useAuth();
  const { toast } = useToast();

  const { openModalTextInput } = useModal();

  const [radioState, setRadioState] = useState(initialRadio);
  const [partner, setPartner] = useState(undefined);

  const [tipoMercado, setTipoMercado] = useState({
    // name: 'Leilão',
    // id: 1,
    name: '',
    id: null,
  });

  const [buyers, setBuyers] = useState([]);
  const [selectedBuyers, setSelectedBuyers] = useState([]);

  const [, setIsLoading] = useState(false);

  async function handleLoadMarket() {
    const market = await getSellerMarket(business.businessId);

    setTipoMercado({ id: +market.id, name: market.name });
  }

  async function handleLoadBuyers() {
    const data = await GetBuyers(userOrigin);
    const invoiceBuyersId = await getSellerBuyers(business.businessId);

    const _buyers = data
      .map((x) => ({
        name: x.name,
        id: x.businessId,
      }))
      .sort((a, b) => (a.name < b.name ? -1 : 1));

    const invoiceBuyersData = _buyers.filter((buyer) =>
      invoiceBuyersId.some((invoiceBuyer) => invoiceBuyer.BUYER_ID == buyer.id)
    );

    setBuyers(_buyers);
    setSelectedBuyers(invoiceBuyersData);
  }

  async function handleLoadData() {
    setIsLoading(true);

    try {
      if (business.typeId != 2) {
        toast('Carregando tipo de mercado', { id: 'tipo-mercado' });
        await handleLoadMarket();
        await handleLoadBuyers();
        toast.success('Tipo de mercado carregado', { id: 'tipo-mercado' });
      }
    } catch (error) {
      toast.error('Erro carregando tipo de mercado', { id: 'tipo-mercado' });
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    handleLoadData();
  }, []);

  const changeType = async (event) => {
    const option = event.target.value;

    const findTipoMercado = marketTypes.find(
      (tipoMercado) => tipoMercado.value == option
    );

    if (!findTipoMercado)
      toast.error('Tipo de mercado inválido', { id: 'tipo-mercado' });

    setTipoMercado({
      name: findTipoMercado.label,
      id: findTipoMercado.value,
    });

    if ((option === 2 || option === 3) && buyers.length === 0) {
      const data = await GetBuyers(userOrigin);
      const _buyers = data.map((x) => ({
        name: x.name,
        id: x.businessId,
      }));
      setBuyers(_buyers);
    }
  };

  const handleSetMotives = (statusTypeId) => {
    if (statusTypeId == undefined) return [];
    const selectedMotives = motivesIdsByStatusType[statusTypeId].map(
      (motiveId) => motives.find((motive) => motive.motiveId == motiveId)
    );
    return selectedMotives;
  };

  const updateBusiness = async (updateOption) => {
    if (business === undefined) return;
    let updatedBusiness = {};
    if (updateOption === 'save') {
      updatedBusiness = {
        ...businessInitial,
        countryIdentity: business.countryIdentity,
        corporateName: business.corporateName,
        name: business.name,
        email: business.email,
        officePhone: business.officePhone,
        addressZipCode: business.addressZipCode,
        addressStreet: business.addressStreet,
        addressNumber: business.addressNumber,
        addressComplement: business.addressComplement,
        addressDistrict: business.addressDistrict,
        addressCity: business.addressCity,
        addressState: business.addressState,
      };
    } else if (updateOption === 'status') {
      updatedBusiness = {
        ...businessInitial,
        statusDate: new Date(),
        statusId: business.statusId,
        statusName: business.statusName,
        motiveId: business.motiveId,
        motiveName: business.motiveName,
        note: business.note,
      };
    } else if (updateOption === 'tipoMercado') {
      updatedBusiness = {
        ...businessInitial,
        marketTypeId: business.marketTypeId,
        marketTypeName: business.marketTypeName,
      };
    } else if (updateOption === 'parceiro') {
      if (partner?.businessId === null || partner?.businessId === undefined) {
        updatedBusiness = {
          ...businessInitial,
          partnerId: null,
        };
      } else {
        updatedBusiness = {
          ...businessInitial,
          partnerId: partner.businessId,
        };
      }
    }

    setBusiness(updatedBusiness);

    await toast.promise(updateBusinessService(updatedBusiness), {
      loading: 'Alterando dados',
      success: 'Dados atualizados com sucesso!',
      error: 'Falha ao atualizar dados',
    });

    if (updateOption === 'status') {
      await sendBusinessNotification({
        businessReceiverId: updatedBusiness.businessId,
        businessSenderId: user.businessId,
        notificationText: updatedBusiness.note,
        notificationLink: `/cadastro`,
      });
      const register = await getRegisterBusinessId(business.businessId);
      const empresa = register.originType;
      if (business.statusId == 4) {
        const payloadAprovado = {
          empresa,
          publico: business.typeId,
          email_destinatario: business.email,
          nome_cliente: business.name,
        };
        await emailCadastroAprovado(payloadAprovado);
      } else if (business.statusId == 6) {
        const payloadNegado = {
          empresa,
          publico: business.typeId,
          email_destinatario: business.email,
          nome_cliente: business.name,
        };
        await emailCadastroNegado(payloadNegado);
      } else if (business.statusId == 14) {
        const payloadExpirado = {
          empresa,
          publico: business.typeId,
          email_destinatario: business.email,
          nome_cliente: business.name,
        };
        await emailCadastroExpirado(payloadExpirado);
      } else if (business.statusId == 3) {
        const payloadDocumentacao = {
          empresa,
          publico: business.typeId,
          email_destinatario: business.email,
          nome_cliente: business.name,
          documentos_pendentes: '',
        };
        await emailCadastroAguardandoDocumentacao(payloadDocumentacao);
      }
    }
    window.scrollTo(0, 0);
  };

  const handlerChange = (e) => {
    setBusiness({ ...business, [e.target.name]: e.target.value });
  };

  const updateNotification = async (updatedBusiness) => {
    await updateBusinessService(updatedBusiness);
    await sendBusinessNotification({
      businessReceiverId: updatedBusiness.businessId,
      businessSenderId: user.businessId,
      notificationText: updatedBusiness.note,
      notificationLink: `/cadastro`,
    });
    setBusiness(updatedBusiness);
  };

  const handleUpdateNotification = async (business, newNotification) => {
    await toast.promise(
      updateNotification({ ...business, note: newNotification }),
      {
        loading: 'Alterando notificação',
        success: 'Notificação alterada com sucesso!',
        error: 'Falha ao alterar notificação',
      }
    );
  };

  const handleOpenNotificationModal = (business) => {
    openModalTextInput(
      'textField',
      'Adicionar Observação',
      '',
      'Confirmar',
      'Cancelar',
      handleUpdateNotification,
      business
    );
  };

  const StylizedInput = ({
    className,
    label,
    name,
    value,
    disabled,
    format,
    ...props
  }) => (
    <div className={`item pr-2 w-100 ${className}`}>
      <TextField
        fullWidth
        className="mt-3 mb-2 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
        id="outlined-basic"
        label={label}
        name={name}
        value={format ? format(business[name]) : business[name]}
        disabled={disabled}
        variant="outlined"
        onChange={handlerChange}
        {...props}
      />
    </div>
  );

  function getStatusColor(business) {
    const statusColor = status.find(
      (item) => item.statusName == business.statusName
    );

    return statusColor ? statusColor.backgroundColor : 'red';
  }

  async function handleChangeType() {
    try {
      toast.loading('Processando...', { id: 'tipo-mercado' });

      if (!tipoMercado)
        return toast.error('Nenhum tipo selecionado', { id: 'tipo-mercado' });

      // validando tipo de mercado que deve possuir financiadores
      if (tipoMercado.id != 1 && selectedBuyers.length == 0) {
        return toast.error('Selecione um nome', { id: 'tipo-mercado' });
      }

      // enviando nova lista de financiadores do título com base no tipo de mercado
      if (tipoMercado.id != 1 && selectedBuyers.length > 0) {
        // serializando formato da lista de financiadores para retornar uma lista com seus respectivos ids
        const serializedSelectedBuyers = selectedBuyers.map(
          (selectedBuyer) => selectedBuyer.id
        );

        // atualizando lista
        await updateSellerBuyers(serializedSelectedBuyers, business.businessId);
      }

      // atualizando tipo de mercado
      await updateSellerMarket(tipoMercado, business.businessId);

      toast.success('Tipo de mercado alterado', { id: 'tipo-mercado' });
    } catch (error) {
      toast.error('Erro ao alterar dados do mercado', { id: 'tipo-mercado' });
    }
  }

  return (
    <div className="w-100">
      <ReactIf condition={business && business?.typeId === 1 && business.note}>
        <ReactThen>
          <div
            style={{
              width: '95%',
              margin: '0 auto',
              backgroundColor: '#f44336',
              color: 'white',
              padding: 8,
              marginBottom: 8,
              fontWeight: 'bold',
              borderRadius: 5,
            }}
          >
            {business.note}
          </div>
        </ReactThen>
      </ReactIf>
      {business?.typeId === 1 && (
        <div className="searchForm">
          <div className="containerTitle w-100 mt-4">
            <div className="itemTile d-flex flex-row justify-content-between w-100">
              {business && (
                <div className="d-flex flex-row align-items-center">
                  <h3
                    style={{
                      marginBottom: '7.25%',
                      fontFamily: 'Poppins, sans-serif',
                    }}
                  >
                    Empresa
                  </h3>
                  <ConfirmButton
                    className="ml-4"
                    onClick={() =>
                      window.open(
                        `https://app.moskitcrm.com/?/deal/%7B%7BTB_BUSINESS.${business?.MOSKIT_CRM_DEAL_ID}/`
                      )
                    }
                  >
                    Acessar MOSKIT
                  </ConfirmButton>
                </div>
              )}
              <div className="d-flex flex-column align-items-end">
                <ConfirmButton
                  className="mb-2"
                  style={{ marginTop: -24 }}
                  onClick={() => {
                    handleOpenNotificationModal(business);
                  }}
                >
                  Adicionar Observação
                </ConfirmButton>
                <div
                  style={{
                    cursor: 'default',
                    backgroundColor: getStatusColor(business),
                    color: '#FFF',
                    height: '30px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    borderRadius: '180px',
                    minWidth: '270px',
                    maxWidth: '360px',
                    fontWeight: 500,
                  }}
                >
                  {business?.statusName}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-between w-100">
            <StylizedInput
              label="CNPJ*"
              name="countryIdentity"
              value={business?.countryIdentity}
              disabled
            />
            <StylizedInput
              label="Razão Social*"
              name="corporateName"
              value={business?.corporateName}
              disabled
            />
            <StylizedInput
              label="Nome Fantasia*"
              name="name"
              value={business?.name}
              disabled
            />
          </div>
          <div className="d-flex flex-row">
            <StylizedInput
              label="E-mail*"
              name="email"
              value={business?.email}
              disabled
            />
            <StylizedInput
              label="Telefone*"
              name="officePhone"
              value={business?.officePhone}
              disabled
              format={formatTelefone}
            />
            <StylizedInput
              label="Site*"
              name="site"
              value={business?.site}
              disabled
            />
          </div>
        </div>
      )}
      {/* Não mostrar tela de tipo de mercado se for Financiador */}
      {business.typeId != 2 && (
        <>
          <ReactIf condition={isAdmin()}>
            <ReactThen>
              <div className="searchForm" style={{ marginTop: '30px' }}>
                <div className="containerTitle w-100 pr-2 mb-4">
                  <div
                    className={`itemTile d-flex flex-row justify-content-${
                      business?.typeId === 1 ? 'between' : 'start'
                    }`}
                  >
                    <h3 style={{ fontFamily: 'Poppins, sans-serif' }}>
                      Tipo de Mercado
                    </h3>
                  </div>
                </div>
                <FormControl className="w-100 m-2 pr-3">
                  <div className="p-3">
                    <TextField
                      fullWidth
                      id="outlined-select-type"
                      select
                      // label="Tipo de Mercado"
                      variant="outlined"
                      value={tipoMercado.id}
                      onChange={changeType}
                    >
                      {marketTypes.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  {tipoMercado.id != 1 && buyers && (
                    <div className="p-3">
                      <AutoCompleteSelect
                        label="Financiadores"
                        noOptionsText="Nenhum financiador encontrado"
                        value={selectedBuyers}
                        options={buyers}
                        onChange={(event, newValue) => {
                          setSelectedBuyers([...newValue]);
                        }}
                      />
                    </div>
                  )}
                  {/* <InputLabel id="demo-simple-select-label" className="ml-2 mt-2">
                Tipo
              </InputLabel>
              <Select
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={Number(business?.marketTypeId)}
                onChange={(e) => {
                  setBusiness({
                    ...business,
                    marketTypeId: e.target.value,
                    marketTypeName: marketTypes.find(
                      (id) => Number(id.typeId) === Number(e.target.value)
                    )?.typeName,
                  });
                }}
              >
                {marketTypes.map((marketType) => (
                  <MenuItem value={marketType.typeId}>
                    {marketType.typeName}
                  </MenuItem>
                ))}
              </Select> */}
                </FormControl>
                <div
                  className="item itemPicker mt-4"
                  style={{ paddingRight: '2.5%' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <ConfirmButton
                      onClick={() => {
                        handleChangeType();
                      }}
                    >
                      Alterar Tipo de Mercado
                    </ConfirmButton>
                  </div>
                </div>
              </div>
            </ReactThen>
          </ReactIf>
        </>
      )}
      <StatusCadastro
        motives={motives}
        business={business}
        updateBusiness={updateBusiness}
        setBusiness={setBusiness}
        radioState={radioState}
        setRadioState={setRadioState}
        radioStates={radioStates}
        status={status}
        handleSetMotives={handleSetMotives}
        statusCadastro={statusCadastro}
        statusFinal={statusFinal}
        handlerChange={handlerChange}
      />

      {/* Não mostrar tela de parceiro se for financiador */}
      {business.typeId != 2 && (
        <>
          {' '}
          <div className="searchForm" style={{ marginTop: '30px' }}>
            <div className="containerTitle w-100 pr-2 mb-4">
              <div
                className={`itemTile d-flex flex-row justify-content-${
                  business?.typeId === 1 ? 'between' : 'start'
                }`}
              >
                <h3 style={{ fontFamily: 'Poppins, sans-serif' }}>
                  Parceiro de Origem Cedente
                </h3>
              </div>
            </div>
            <Autocomplete
              items={businesses}
              setItem={setPartner}
              business={business}
            />
            <div
              className="item itemPicker mt-4"
              style={{ paddingRight: '2.5%' }}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                <ConfirmButton onClick={() => updateBusiness('parceiro')}>
                  Alterar Parceiro
                </ConfirmButton>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
