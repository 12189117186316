export const formatCNPJOutput = (cnpj) => {
  if (cnpj.length > 18) return;
  const isNumber = (char) =>
    char != ' ' && char != '' && !Number.isNaN(Number(char));

  const lastCharIndex = cnpj.length - 1;
  const lastChar = cnpj[lastCharIndex];

  if (!isNumber(lastChar)) {
    if (
      ((lastCharIndex == 2 || lastCharIndex == 6) && lastChar == '.') ||
      (lastCharIndex == 10 && lastChar == '/') ||
      (lastCharIndex == 15 && lastChar == '-')
    ) {
      return cnpj;
    }
  } else {
    if (isNumber(cnpj.charAt(2)))
      cnpj = `${cnpj.substring(0, 2)}.${cnpj.charAt(2)}${cnpj.substring(
        3,
        cnpj.length
      )}`;

    if (isNumber(cnpj.charAt(6)))
      cnpj = `${cnpj.substring(0, 6)}.${cnpj.charAt(6)}${cnpj.substring(
        7,
        cnpj.length
      )}`;

    if (isNumber(cnpj.charAt(10)))
      cnpj = `${cnpj.substring(0, 10)}/${cnpj.charAt(10)}${cnpj.substring(
        11,
        cnpj.length
      )}`;

    if (isNumber(cnpj.charAt(15)))
      cnpj = `${cnpj.substring(0, 15)}-${cnpj.charAt(15)}${cnpj.substring(
        16,
        cnpj.length
      )}`;

    return cnpj;
  }
};
