/* eslint eqeqeq: 0 */
import React from 'react';

import { FormControl, TextField } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import getDaysToGo from 'utils/diffDays';
import formatReal from 'utils/formatReal';

import moment from 'moment';

import { useToast } from 'contexts/toast';

export default (props) => {
  const { venclist, add, del, handleChangeVencimento, handlerChange, edit } =
    props;

  const { toast } = useToast();

  return venclist.map((val, idx) => (
    <div
      className="item"
      key={idx || val.maturity_id}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '10px',
        height: '70px',
      }}
    >
      {/* <div className="containerDatePicker mr-5">
        <FormControl className="w-100">
          <TextField
            fullWidth
            className="ml-2 mr-2 mb-0 text-field"
            id="itemdesc"
            label="Descrição do Recebível"
            name="descricao"
            variant="outlined"
            onChange={(e) => handlerChange(idx, e)}
            value={
              venclist[idx].descricao ||
              venclist[idx].status_name_extension ||
              ''
            }
            disabled={!edit}
          />
        </FormControl>
      </div> */}
      <div className="containerDatePicker mr-5" style={{ marginTop: '-15px' }}>
        <FormControl className="w-100">
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <KeyboardDatePicker
              helperText=""
              cancelLabel="Cancelar"
              fullWidth
              className="ml-2 mr-2 mb-0 text-field"
              id="dtvenc"
              locale={ptBR}
              label="Data Vencimento"
              format="dd/MM/yyyy"
              name="vencimento"
              variant="outlined"
              minDateMessage={null}
              maxDateMessage={null}
              minDate={new Date()}
              onChange={(e) => {
                handleChangeVencimento(idx, e);
              }}
              value={
                moment(venclist[idx].maturity_date)
                  .utc(0)
                  .format('YYYY/MM/DD') || moment().format('YYYY/MM/DD')
              }
              disabled={!edit}
            />
          </MuiPickersUtilsProvider>
        </FormControl>
      </div>
      <div className="containerDatePicker mr-5">
        <FormControl className="w-100">
          <TextField
            fullWidth
            className="ml-2 mr-2 mb-0 text-field"
            id="value"
            label="Valor"
            name="valor"
            required
            variant="outlined"
            onBlur={(e) => {
              if (e.target.value == '' || e.target.value == 0) {
                toast.error(
                  'ATENÇÃO: Você precisa informar um valor para a parcela.'
                );
              }
            }}
            onChange={(e) => {
              e.target.value = formatReal(e.target.value);
              handlerChange(idx, e);
            }}
            value={venclist[idx].valor || venclist[idx].value || ''}
            disabled={!edit}
          />
        </FormControl>
      </div>
      <div className="containerDatePicker mr-5">
        <FormControl className="w-100">
          <TextField
            fullWidth
            className="ml-2 mr-2 mb-0 text-field"
            id="days"
            label="Dias"
            name="dias"
            disabled
            onChange={(e) => handlerChange(idx, e)}
            variant="filled"
            value={
              venclist[idx].dias ||
              `${venclist[idx].days ?? getDefaultDays(venclist, idx)}` ||
              ''
            }
          />
        </FormControl>
      </div>
      {idx === 0 ? (
        <>{edit && <AddIcon onClick={add} style={{ cursor: 'pointer' }} />}</>
      ) : (
        <>
          {edit && (
            <DeleteIcon
              onClick={() => del(idx)}
              style={{ cursor: 'pointer' }}
            />
          )}
        </>
      )}
    </div>
  ));
};

function getDefaultDays(venclist, idx) {
  return getDaysToGo(
    venclist[idx].dtvencimento ||
      venclist[idx].maturity_date ||
      moment().format('YYYY/MM/DD')
  );
}
