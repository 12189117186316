import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';

import './notification.scss';

import { IconButton, Box, Typography, Tabs, Popover } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import dateFormat from 'dateformat';

import { ReactCatch, ReactIf, ReactThen } from 'components/ReactIf';


import {
  getBusinessNotifications,
  setMarcarLido,
  readBusinessNotification,
} from 'services/businessNotification';

import { useToast } from 'contexts/toast';

import BellIcon from '../SideMenu/icons/Bell';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
export default ({ businessId }) => {
  if (!businessId) return null;

  const { toast } = useToast();
  const history = useHistory();
  const theme = useTheme();

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    loadBusinessNotifications();
  }, []);

  const loadBusinessNotifications = async () => {
    const notifications = await getBusinessNotifications(businessId);
    setNotifications(notifications);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
    setMarcarLido(businessId);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const open1 = Boolean(anchorEl1);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const unReadedNotifications = (notifications) =>
    notifications.filter((notf) => notf.isReaded === 0);

  const handleReadNotification = async (notificationId) => {
    await readBusinessNotification(notificationId);
    await loadBusinessNotifications();
  };

  const handleOpenNotificationLink = async (notification) => {
    await toast.promise(
      handleReadNotification(notification?.businessNotificationId),
      {
        loading: 'Carregando página da notificação',
        success: 'Página carregada com sucesso!',
        error: 'Falha ao carregar página da notificação',
      }
    );
    handleClose1();
    window.location.replace(notification?.notificationLink);
  };

  return (
    <Box className="notification-container" component="span" mr="2" pr="2">
      <IconButton
        className="notification-button btn-inverse mx-1 d-50"
        color="inherit"
        onClick={handleClick1}
      >
        <ReactIf
          condition={
            notifications !== null &&
            notifications !== undefined &&
            unReadedNotifications(notifications).length > 0
          }
        >
          <ReactThen>
            <div
              className="notification-badge"
              style={{ border: `2px solid ${theme.palette.primary.main}` }}
            >
              {notifications && unReadedNotifications(notifications).length}
            </div>
          </ReactThen>
        </ReactIf>

        <BellIcon color="#ffffff" size={20} />
      </IconButton>
      <Popover
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'app-header-dots',
        }}
      >
        <div className="popover-custom-xl overflow-hidden">
          <div className="bg-composed-wrapper border-0 text-center rounded-sm m-2">
            <div className="bg-composed-wrapper--content text-light px-2 pt-4">
              <h4
                className="font-size-xl font-weight-bold mb-1"
                style={{ fontFamily: 'Poppins, sans-serif' }}
              >
                Notificações
              </h4>
              {notifications && (
                <p className="opacity-8 mb-0">
                  Você possui{' '}
                  <Typography component="b" color="primary">
                    {notifications &&
                      unReadedNotifications(notifications).length}
                  </Typography>{' '}
                  notificações não lidas!
                </p>
              )}
            </div>
          </div>
          <div className="mx-2">
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={handleChange}
              className="prorrogacao-size"
            />
          </div>
          <div className="height-280">
            <PerfectScrollbar>
              <TabPanel value={value} index={0}>
                <div
                  className="timeline-list timeline-list-offset timeline-list-offset-dot"
                  style={{
                    display: notifications?.length > 0 ? 'initial' : 'none',
                  }}
                >
                  {notifications &&
                    notifications?.map((notification, index) => (
                      <ReactIf
                        condition={notification.notificationLink}
                        key={index}
                      >
                        <ReactThen>
                          <div
                            className="timeline-item"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleOpenNotificationLink(notification);
                            }}
                          >
                            <div className="timeline-item-offset">
                              {dateFormat(
                                notification.notificationDate,
                                'dd/mm H:MM'
                              )}
                            </div>
                            <div className="timeline-item--content">
                              <div className="timeline-item--icon" />
                              <Typography
                                variant="h1"
                                component="h3"
                                className="timeline-item--label pb-2 font-weight-bold"
                              >
                                {notification.notificationText}
                              </Typography>
                              <Typography component="p" color="primary">
                                Clique para acessar a página
                              </Typography>
                              <ReactIf
                                condition={
                                  notification && !notification.isReaded
                                }
                              >
                                <ReactThen>
                                  <div
                                    className="badge badge-pill badge-danger badge-header"
                                    style={{
                                      top: '50%',
                                      transform: 'translateY(-50%)',
                                      right: '10%',
                                      height: 8,
                                      width: 8,
                                    }}
                                  >
                                    <div />
                                  </div>
                                </ReactThen>
                              </ReactIf>
                            </div>
                          </div>
                        </ReactThen>
                        <ReactCatch>
                          <div
                            className="timeline-item"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              handleReadNotification(
                                notification.businessNotificationId
                              );
                            }}
                          >
                            <div className="timeline-item-offset">
                              {
                                // formatDateToHours(
                                //   notification.notificationDate
                                // ).split(':')[0]
                                dateFormat(
                                  notification.notificationDate,
                                  'dd/mm H:MM'
                                )
                              }
                            </div>
                            <div className="timeline-item--content">
                              <div className="timeline-item--icon" />
                              <Typography
                                variant="h1"
                                component="h3"
                                className="timeline-item--label pb-2 font-weight-bold"
                              >
                                {notification.notificationText}
                              </Typography>
                              <Typography component="p" color="primary" />
                              <ReactIf
                                condition={
                                  notification && !notification.isReaded
                                }
                              >
                                <ReactThen>
                                  <div
                                    className="badge badge-pill badge-danger badge-header"
                                    style={{
                                      top: '50%',
                                      transform: 'translateY(-50%)',
                                      right: '10%',
                                      height: 8,
                                      width: 8,
                                    }}
                                  >
                                    <div />
                                  </div>
                                </ReactThen>
                              </ReactIf>
                            </div>
                          </div>
                        </ReactCatch>
                      </ReactIf>
                    ))}
                </div>
              </TabPanel>
            </PerfectScrollbar>
          </div>
          {/* <Divider />
          <div className="text-center py-3">
            <Badge
              color="error"
              variant="dot"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Button color="primary" variant="contained" className="px-4">
                <span className="btn-wrapper--label">Learn more</span>
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                </span>
              </Button>
            </Badge>
          </div> */}
        </div>
      </Popover>
    </Box>
  );
};
