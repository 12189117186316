import React, { createContext, useCallback, useState } from "react";

import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  AccessTime,
  AttachMoney,
  CalendarTodayRounded,
  Close,
  DoneAll,
  PriorityHigh,
} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";

import moment from "moment";

import Axios from "axios";

import EventBus from "services/EventBus";

import useMaturity from "hooks/useMaturity";

import { useAuth } from "../../contexts/auth";
import { useToast } from "../../contexts/toast";
import useExtensions from "../../hooks/useExtensions";
import formatReal from "../../utils/formatReal";
import ExtensionForm from "../ExtensionFormSeller";
import TitleSection from "../TitleSection";
import AxiosInterceptor from "utils/axios-interceptor";

export const ExtensionContext = createContext({});

export default function ExtensionModal({ maturityId, invoice }) {
  const { isCedente, isAdmin, isFinanciador } = useAuth();

  const { toast } = useToast();
  const [extensionId, setExtensionId] = useState(null);
  const [formExtension, setFormExtension] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);

  const {
    maturity,
    error: maturityError,
    isLoading: isLoadingMaturity,
    isValidating,
    mutate: mutateMaturity,
  } = useMaturity(invoice.invoiceId, maturityId);

  const { extensions, error, isLoading, mutate } = useExtensions(
    maturity?.maturityId
  );
  const axiosInstance = AxiosInterceptor();
  const sendSolicitation = useCallback(
    (formData) => {
      setDisableButtons(true);
      toast.promise(
        axiosInstance.post(
          `${process.env.REACT_APP_EXTENSION_API}/extensions`,
          formData
        ),
        {
          loading: "Enviando solicitação",
          success: () => {
            setExtensionId(null);
            setFormExtension(false);
            mutate();
            setDisableButtons(false);
            return `Solicitação criada com sucesso!`;
          },
          error: () => {
            setDisableButtons(false);
            return `Falha ao enviar solicitação! Verifique se já não há uma em processo`;
          },
        }
      );
    },
    [toast, mutate]
  );

  const updateSolicitation = useCallback(
    (id, data, successMsg) => {
      setDisableButtons(true);
      toast.promise(
        axiosInstance.patch(
          `${process.env.REACT_APP_EXTENSION_API}/extensions/${id}`,
          data
        ),
        {
          loading: "Atualizando solicitação",
          success: () => {
            setExtensionId(null);
            setFormExtension(false);

            if (data.status_id === 6 || data.status_id === 2) {
              EventBus.dispatch("reload-maturities"); // TODO: Remover quando estiver tudo reativo
              mutateMaturity();
            }

            mutate();

            setDisableButtons(false);
            return successMsg;
          },
          error: (err) => {
            setDisableButtons(false);
            return `Falha ao atualizar a solicitação. Tente novamente em instantes.`;
          },
        }
      );
    },
    [toast, mutate]
  );

  return (
    <Box>
      {isLoadingMaturity || isValidating ? (
        <Box p={4}>
          <CircularProgress
            color="secondary"
            style={{ margin: "0 auto", display: "flex" }}
          />
        </Box>
      ) : (
        <>
          {maturityError ? (
            <Alert severity="error">
              Tivemos um problema na conexão com o servidor. Estamos tentando
              reconectar...
            </Alert>
          ) : (
            <ExtensionContext.Provider
              value={{
                setExtensionId,
                setFormExtension,
                updateSolicitation,
                maturity,
                disableButtons,
                sendSolicitation,
                extensions,
                invoiceId: invoice?.invoiceId,
                sellerId: invoice?.businessId,
                buyerId: invoice?.bidBuyer?.businessId,
              }}
            >
              <Paper elevation={4} variant="outlined">
                <TitleSection title="Informações da parcela" />

                <Box mb={4} mt={4} pr={4} pl={4}>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        variant="outlined"
                        label="Data de vencimento"
                        disabled
                        className="text-field-label"
                        fullWidth
                        InputProps={{
                          style: {
                            borderRadius: 20,
                            color: "#000",
                            fontSize: 22,
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarTodayRounded />
                            </InputAdornment>
                          ),
                        }}
                        value={moment(maturity?.maturityDate)
                          .utc(0)
                          .format("DD/MM/YYYY")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        variant="outlined"
                        label="Valor"
                        fullWidth
                        className="text-field-label"
                        disabled
                        style={{ color: "black" }}
                        InputProps={{
                          style: {
                            borderRadius: 20,
                            color: "#000",
                            fontSize: 22,
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <AttachMoney />
                            </InputAdornment>
                          ),
                        }}
                        value={formatReal(maturity?.value.toFixed(2))}
                      />
                    </Grid>
                  </Grid>
                </Box>

                {!formExtension ? (
                  <>
                    <TitleSection title="Solicitações de prorrogações" />

                    <Box mb={4} mt={4} pr={4} pl={4}>
                      {isLoading ? (
                        <CircularProgress
                          color="secondary"
                          style={{ margin: "0 auto", display: "flex" }}
                        />
                      ) : (
                        <>
                          {error ? (
                            <Alert severity="error">
                              Tivemos um problema na conexão com o servidor.
                              Estamos tentando reconectar...
                            </Alert>
                          ) : (
                            <List>
                              {isCedente() || isAdmin() ? (
                                <Paper
                                  elevation={2}
                                  variant="outlined"
                                  style={{ marginBottom: 5 }}
                                >
                                  <ListItem
                                    button
                                    style={{ textAlign: "center" }}
                                    onClick={() => {
                                      setExtensionId(null);
                                      setFormExtension(true);
                                    }}
                                  >
                                    <ListItemText primary="Solicitar nova prorrogação" />
                                  </ListItem>
                                </Paper>
                              ) : null}

                              {!extensions?.length && isFinanciador() ? (
                                <Typography
                                  variant="body1"
                                  style={{ textAlign: "center" }}
                                >
                                  Não há nenhuma solicitação
                                </Typography>
                              ) : null}

                              {extensions?.map((val) => (
                                <Paper
                                  elevation={2}
                                  style={{ marginBottom: 5 }}
                                >
                                  <ListItem
                                    button
                                    onClick={() => {
                                      setExtensionId(val._id);
                                      setFormExtension(true);
                                    }}
                                  >
                                    <Tooltip title={val.status_name}>
                                      <ListItemIcon>
                                        {val.status_id === 1 ? (
                                          <AccessTime
                                            color="default"
                                            style={{ color: "black" }}
                                          />
                                        ) : null}

                                        {val.status_id === 4 ||
                                        val.status_id === 2 ||
                                        val.status_id === 5 ||
                                        val.status_id === 7 ? (
                                          <Close
                                            color="default"
                                            style={{ color: "red" }}
                                          />
                                        ) : null}

                                        {val.status_id === 3 ? (
                                          <PriorityHigh />
                                        ) : null}

                                        {val.status_id === 6 ? (
                                          <DoneAll
                                            style={{ color: "#1bc943" }}
                                          />
                                        ) : null}
                                      </ListItemIcon>
                                    </Tooltip>

                                    <ListItemText
                                      primary={`Prorrogação para ${moment(
                                        val.seller_extension_date
                                      ).format("DD/MM/YYYY")}`}
                                    />
                                  </ListItem>
                                </Paper>
                              ))}
                            </List>
                          )}
                        </>
                      )}
                    </Box>
                  </>
                ) : (
                  <ExtensionForm extensionId={extensionId} />
                )}
              </Paper>
            </ExtensionContext.Provider>
          )}
        </>
      )}
    </Box>
  );
}
