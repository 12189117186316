import React, { createContext, useCallback, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

export const DialogContext = createContext({
  toggleDialog: (open, title, content, closeable = true) => undefined,
});

export default function DialogProvider({ children }) {
  const [title, setTitle] = useState('');
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [closeable, setCloseable] = useState(true);
  const [maxWidth, setMaxwidth] = useState('lg');

  const toggleDialog = useCallback(
    (open, title, content, closeable = true, maxWidth = 'lg') => {
      setOpen(open);
      setTitle(title);
      setContent(content);
      setCloseable(closeable);
      setMaxwidth(maxWidth);
    },
    []
  );

  return (
    <DialogContext.Provider
      value={{
        toggleDialog,
      }}
    >
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        open={open}
        // onClose={handleClose}
      >
        <DialogTitle
          style={{
            fontSize: 38,
            fontWeight: 'bold',
            fontFamily: 'Poppins',
            marginBottom: 4,
          }}
          disableTypography
        >
          {title}
        </DialogTitle>
        {closeable ? (
          <IconButton
            onClick={() => {
              toggleDialog(false, '', '');
            }}
            style={{
              position: 'absolute',
              right: 0,
              color: 'red',
            }}
            color="error"
            aria-label="Exit"
            component="span"
          >
            <Close />
          </IconButton>
        ) : null}

        <DialogContent style={{ backgroundColor: '#FAFAFA' }}>
          {content}
        </DialogContent>
      </Dialog>
      {/* {loadModal(modalState.modalType)} */}
      {children}
    </DialogContext.Provider>
  );
}
