import React, { useCallback, useContext, useState } from "react";

import { Typography, Button, Box } from "@material-ui/core";

import { DialogContext } from "contexts/dialog";
import { useToast } from "contexts/toast";
import AxiosInterceptor from "utils/axios-interceptor";

export default function TermsModal({ user, identity }) {
  const [disableButton, setDisableButton] = useState(false);

  const { toggleDialog } = useContext(DialogContext);
  const { businessId, userId } = user;
  const { toast } = useToast();

  const data = {
    userId,
    businessId,
    identity,
  };

  const sendAcceptTerms = useCallback(() => {
    const axiosInstance = AxiosInterceptor();
    setDisableButton(true);
    toast.promise(
      axiosInstance.post(`${process.env.REACT_APP_NODE_API}/terms`, data),
      {
        loading: "Processando",
        success: () => {
          toggleDialog(false, "", "");
          return `Termos de uso aceito com sucesso!`;
        },
        error: () => {
          setDisableButton(false);
          return `Falha ao enviar aceite! Tente novamente em alguns instantes.`;
        },
      }
    );
  }, [toast]);

  return (
    <>
      <Typography variant="h2">
        DISPOSIÇÕES GERAIS SOBRE USUÁRIOS, CLIENTES E UTILIZAÇÃO DE DADOS
      </Typography>
      <Typography variant="h5" align="justify">
        A Plataforma Antecipa Fácil é um agente de liquidação de contas
        vinculadas, canal de intermediação (Market Place) para realização de
        transações de antecipação de recebíveis.
        <br /> As transações financeiras relativas ao processo de cessão de
        títulos não são de responsabilidade da Plataforma, uma vez que essa se
        limita a apresentar pareceres de créditos e oportunidades de negócio aos
        financiadores, cabendo aos agentes financeiros e cedentes optarem
        livremente pela realização ou não dos negócios.
        <br /> Consideram-se usuários da Plataforma todas as empresas que,
        formalmente concordaram com as Condições Gerais e Específicas de Uso da
        plataforma.
        <br /> A Plataforma Antecipa Fácil disponibilizará em seu portal, para
        àqueles que adquirirem títulos de outros usuários, dados específicos
        daquela operação, a fim de que as partes possam ter maior volume de
        informações para avaliar o risco do negócio. <br /> As partes envolvidas
        na negociação, desde sua adesão à ferramenta, formalizaram suas
        intenções de manter relacionamento comercial baseado em tecnologia da
        informação e em ambiente digital com a Plataforma Antecipa Fácil, não
        podendo utilizá-la para a prospecção direta de clientes. <br /> Diante
        disso, é vedada a utilização dos bancos de dados e outras informações
        sobre usuários da Plataforma que são disponibilizadas no fluxo de compra
        e venda títulos para fins de prospecção direta, pesquisas de satisfação,
        envio de material promocional digital ou não, entre outros tipos de
        abordagem direcionadas, a partir de informações sobre usuários obtidas
        por meio da Plataforma Antecipa Fácil. Em se comprovando essa prática, o
        usuário infrator poderá ser banido da Plataforma e estará sujeito a
        aplicação das penalidades previstas nas Condições Gerais de Uso.
      </Typography>
      <Typography variant="h2">UTILIZAÇÃO</Typography>
      <Typography variant="h4">Para comprar títulos (adquirentes):</Typography>
      <Typography variant="h5">
        Empresas formalmente constituídas e com Código Nacional de Atividade
        compatível com a prática de fomento comercial/mercantil e crédito pessoa
        jurídica, inclusive com registro vigente no respectivo órgão de classe,
        empresas destinas à realização de operações de financiamentos,
        empréstimos e descontos de títulos de crédito (ESC), e compatível com a
        prática de consultoria e assessoria administrativo financeira,
        atividades de cobranças e informações cadastrais, atividade de
        consultoria em gestão empresarial.
      </Typography>
      <Typography variant="h2">PARA VENDER TÍTULOS (CEDENTES)</Typography>
      <Typography variant="h5">
        Pessoas jurídicas formalmente constituídas com CNPJ ativo.
      </Typography>
      <Typography variant="h2">CADASTRO</Typography>
      <Typography variant="h4">Para vendedores de títulos:</Typography>
      <Typography variant="h5">
        Será necessário um cadastro de adesão para tornar o usuário apto a
        operar na Plataforma Antecipa Fácil. Esse cadastro deverá ser renovado
        periodicamente mediante aviso e/ou em razão do bloqueio de acesso. A
        lista de documentos necessários para a realização do cadastro poderá ser
        visualizada na própria plataforma após o preenchimento do formulário pré
        cadastro.
      </Typography>
      <Typography variant="h4">
        Para compradores de títulos (financiadores):
      </Typography>
      <Typography variant="h5">
        Um cadastro simplificado de adesão será necessário para a confirmação da
        existência e finalidade jurídica do CNPJ. A lista de documentos
        necessários poderá ser visualizada após a realização do cadastro básico
        de usuários.
      </Typography>
      <Typography variant="h2">LEILÃO</Typography>
      <Typography variant="h4">Para inclusão de títulos:</Typography>
      <Typography variant="h5">
        A inclusão de títulos para antecipação na Plataforma Antecipa Fácil
        poderá ser feita todos os dias, de segunda-feira a domingo, inclusive
        feriados, sem restrições de horário. O envio do título para leilão será
        feito em no máximo 4 (quatro) horas após a sua inclusão. Para os
        clientes que realizarem a primeira operação na plataforma esse prazo
        poderá ser prorrogado a 24 (vinte e quatro) horas.
      </Typography>
      <Typography variant="h4">Das Validação e análise:</Typography>
      <Typography variant="h5">
        A verificação e validação dos títulos colocados por usuários na
        Plataforma ocorrerá em horário comercial, de segunda a sexta-feira, das
        08h às 18h. A Plataforma não funcionará em feriados bancários
      </Typography>
      <Typography variant="h4">Dos Pregões:</Typography>
      <Typography variant="h5">
        Os pregões de venda de títulos serão realizados todos os dias, de
        segunda-feira a domingo, inclusive feriados, sem restrições de horário.
      </Typography>
      <Typography variant="h4">Transferências de pagamentos:</Typography>
      <Typography variant="h5">
        O pagamento pelo título arrematado deverá ocorrer por meio transferência
        bancária até às 15h no mesmo dia do arremate. Para isso, os dados
        bancários do cedente deverão estar devidamente atualizados na página de
        cadastro da Plataforma.
      </Typography>
      <Typography variant="h4">Inclusão de Títulos:</Typography>
      <Typography variant="h5">
        Considera-se “título recebível”, a Nota Fiscal/Fatura de venda a prazo
        de um produto ou serviço com o devido aceite do sacado. Quanto maior for
        o volume e precisão das informações sobre a operação enviadas pelo
        vendedor, melhor será a análise do risco da operação e melhores serão as
        chances de o vendedor encontrar uma taxa de desconto justa para o seu
        título.
      </Typography>
      <Typography variant="h2">ANÁLISE DE TÍTULO</Typography>
      <Typography variant="h4">Dossiê de verificação:</Typography>
      <Typography variant="h5">
        A cada operação disponibilizada na Plataforma, estará também disponível
        para consulta, uma pasta digital contendo documentos referentes aos
        títulos, cedentes e sacados envolvidos na transação. A completude e
        complexidade desse dossiê irá variar de acordo com o valor da operação.
        Basicamente, neste espaço virtual, serão disponibilizados dados
        históricos de mercado, evidências da entrega dos serviços e/ou
        mercadores, validade das notas fiscais, reputação do usuário na
        Plataforma e Classificação de risco.
      </Typography>
      <Typography variant="h4">Acesso e disponibilidade ao dossiê:</Typography>
      <Typography variant="h5">
        Os dados dos dossiês estarão disponíveis para livre consulta durante o
        tempo em que os títulos estiverem em leilão. Após o arremate, esses
        dados ficarão disponíveis apenas para o adquirente, com a opção de
        armazenamento virtual, download ou impressão do arquivo.
      </Typography>
      <Typography variant="h4">Classificação do risco da operação:</Typography>
      <Typography variant="h5">
        As análises de risco serão divididas em de risco de crédito e risco de
        fraude, ambas, analisadas sob a ótica de cedentes e sacados. Na análise
        de risco de crédito serão contemplados aspectos que se referem à
        capacidade de pagamento, garantias, caráter, condições e capital. O
        risco de fraude será analisado, basicamente, pelo cruzamento de dados e
        análise da coerência entre eles. Para tanto, a Plataforma Antecipa Fácil
        se utilizará de dados externos de birôs especializados, dados públicos,
        além de dados internos de transações ocorridas no ambiente da Plataforma
        e modelos estatísticos específicos.A compilação desses dados e as
        informações serão traduzidas em 4 classes de agrupamento risco: Alto,
        Médio, Moderado e Baixo. Ressalta-se, entretanto, que a Antecipa Fácil
        sempre destinará seus esforços para a redução de riscos, entretanto, não
        se responsabiliza pela existência, validade,eficácia dos títulos
        negociados em sua Plataforma, bem como não tem qualquer responsabilidade
        pela sua solvência.
      </Typography>
      <Typography variant="h4">Títulos inaptos:</Typography>
      <Typography variant="h5">
        Caso um título analisado não seja considerado apto para prosseguir no
        fluxo de venda para a fase de leilão, um e-mail de aviso será emitido
        para o usuário. A saber, 3 motivos podem tornar um título inapto.
        <br /> ● Título com dados inconsistentes;
        <br /> ● Evidência de entrega insuficiente;
        <br /> ● Fraude.
      </Typography>
      <Typography variant="h2">LEILÃO</Typography>
      <Typography variant="h4">Escolha do título:</Typography>
      <Typography variant="h5">
        Os títulos já validados e analisados pela retaguarda de avaliação de
        risco da Antecipa Fácil serão disponibilizados em forma de lista durante
        o pregão. Filtros de vencimento, valor, prazo, entre outros estarão
        habilitados para facilitar a escolha dos adquirentes.
      </Typography>
      <Typography variant="h4">Lances:</Typography>
      <Typography variant="h5">
        Adquirentes cadastrados e considerados aptos a operar, darão lances
        pelos títulos disponíveis no pregão na modalidade de leilão reverso.
        Entende-se por leilão reverso, aquele que define o vencedor pelo menor
        lance ofertado. As ofertas serão feitas pela determinação do fator de
        deságio percentual ao mês. A concretização da operação sempre dependerá
        do aceite do lance por parte do cedente do título via página do pregão.
      </Typography>
      <Typography variant="h4">Venda:</Typography>
      <Typography variant="h5">
        O acompanhamento do menor lance vigente pelo título à venda deverá ser
        feito diretamente na tela de leilões da Plataforma e o aceite do lance
        poderá ser feito exclusivamente pelo vendedor do título.
      </Typography>
      <Typography variant="h4">Compromisso de venda:</Typography>
      <Typography variant="h5">
        O cedente poderá declinar da cessão de antecipação e retirar o título do
        pregão, sem custo, uma vez por mês. A partir do segundo declínio a
        Plataforma poderá cobrar do cedente uma taxa de até 0,5% (zero virgula
        cinco porcento) ao mês sobre o valor bruto da operação, a título de
        cobertura pelas despesas operacionais e de análise de crédito.
      </Typography>
      <Typography variant="h4">Compromisso de compra:</Typography>
      <Typography variant="h5">
        Ao efetuar um lance, o adquirente compromete-se a realizar a provisão do
        recurso até o final do pregão ou extinção automática deste lance em
        razão da colocação de lance menor por parte de outro adquirente. Caso o
        Cedente não aceite a oferta dada pelo adquirente em até 3 (três) horas,
        será facultado ao adquirente o direito de declinar da oferta sem
        prejuízo/justificava, devendo este apenas informar à mesa de operação da
        Antecipa Fácil.
        <br />
        Caso o adquirente desista de efetivar a operação após o aceite do lance
        por parte do vendedor, um chamado deverá ser aberto para a equipe de
        suporte da Antecipa Fácil com a apresentação de uma evidência de fraude
        que justifique o cancelamento da operação. Se for considerada
        procedente, a operação será cancelada e o cedente suspenso ou banido da
        Plataforma, se for considerado improcedente, uma taxa no valor de até
        0,5% sobre o valor bruto da operação será cobrada do adquirente a título
        de cobertura de custos operacionais e haverá impacto sobre a sua
        reputação na Plataforma.
      </Typography>
      <Typography variant="h2">FORMALIZAÇÃO</Typography>
      <Typography variant="h5">
        A formalização da operação se dará por meio do aceite eletrônico das
        condições gerais e específicas da operação, e assinatura digital por
        meio de processo eletrônico com validade jurídica comprovada. As
        evidências dessas manifestações permanecerão arquivadas nos bancos de
        dados da Plataforma por até 5 (cinco) anos após a liquidação da operação
        e disponíveis para consulta e compartilhamento mediante a solicitação
        formal prévia, exclusivamente para as partes nela envolvidas. <br />
        Em razão da preservação do direito ao sigilo bancário dos usuários da
        plataforma, é vedado ao financiador, consultas com o objetivo de obter
        informações gerais ou o saldo da conta de cedentes, devendo atentar-se
        ao prazo de D+1 da data prevista para a liquidação como comprovação de
        depósito. Caso o pagamento não ocorra neste prazo o financiador deverá
        adotar as medidas de notificação e cobrança descritas neste documento.
      </Typography>
      <Typography variant="h2">MODELO DE CONTRATO</Typography>
      <Typography variant="h5">
        É de responsabilidade do Financiador enviar o seu contrato padrão e os
        demais aditivos para a parte Cedente. <br />
        Caso o Financiador não faça uso de nenhuma plataforma de assinatura
        digital, a Antecipa Fácil disponibilizará a circulação do documento em
        plataforma de sua utilização. <br />O Financiador deverá enviar o seu
        contrato ao Cedente logo após o seu aceite no Leilão.
      </Typography>
      <Typography variant="h2">CONTA CONSIGNADA</Typography>
      <Typography variant="h5">
        A conta vinculada onde a Plataforma Antecipa Fácil figurará como
        Mandatária Interveniente tem a exclusiva finalidade de receber
        créditos/recursos provenientes de depósitos de sacados pagadores de
        transações de venda a prazo realizadas pelo contratante, tendo sido
        esses direitos creditórios antecipados ou não. <br />
        As Partes declaram ter ciência e concordam que a Conta Vinculada não
        poderá ser usada para operações, movimentações, recebimento de depósitos
        e créditos estranhos a sua finalidade expressamente prevista neste termo
        de Política de Utilização e no Contrato de Conta-Corrente Vinculada, o
        qual será assinado no ato da adesão do serviço junto à instituição
        bancária.
      </Typography>
      <Typography variant="h2">COBRANÇA</Typography>
      <Typography variant="h4">
        Operações com liquidação em conta consignada ou boleto consignado:
      </Typography>
      <Typography variant="h5">
        Atentando-se ao prazo máximo de D+1 após o vencimento para a liquidações
        de operações em conta consignada é faculdade do financiador notificar o
        cedente no primeiro dia subsequente a esta data (D+2), solicitando
        posicionamento, prorrogação ou recompra da operação. <br />
        Caso ocorra a notificação prevista no item acima e o cedente não se
        manifeste ou não se manifeste ou não proceda a prorrogação ou recompra
        da operação no prazo de 48 horas, é facultado ao financiador o direito
        de notificar o sacado em conformidade com o Art. 290 do Código Civil.{" "}
        <br />
        Após o vencimento da segunda prorrogação, mesmo que o cedente se
        disponha a uma nova repactuação, é facultado ao financiador o direito de
        notificar o sacado.
      </Typography>
      <Typography variant="h4">
        Operações com liquidação em boleto financiador:
      </Typography>
      <Typography variant="h5">
        Operações tradicionais onde boleto de cobrança é enviado diretamente
        pelo financiador deverão passar por confirmação junto ao sacado antes do
        pagamento dos valores ao cedente. <br />
        Destaca-se que a notificação do sacado só é permitida ao financiador que
        tiver a sua oferta aceita pelo cedente. <br />
        Caso o título não seja confirmado pelo sacado, ou seja, detectada
        qualquer evidência de fraude na operação, o financiador poderá declinar
        do pagamento mediante ao reporte à mesa de operação da Antecipa Fácil
        com a devida apresentação do motivo.
      </Typography>
      <Typography variant="h2">ATRIBUIÇÕES DA ANTECIPA FÁCIL</Typography>
      <Typography variant="h5">
        Conforme disposto nos termos do artigo 664 do Código Civil, a Antecipa
        Fácil, desde já assegura o seu direito de reter valores constantes na
        conta para a liquidação de operações de antecipação de direitos
        creditórios e outros pagamentos, limitando- se tal poder às seguintes
        ações: <br />
        1) Direcionamento de receitas para a liquidação de operações de
        antecipação de direitos creditórios realizados exclusivamente via
        Plataforma Antecipa Fácil. <br />
        2) Direcionamento de recursos, em caso de atrasos decorrentes de outras
        operações, após a liquidação dos pagamentos referentes aos itens 1 a
        Antecipa Fácil não permitirá débitos na conta do usuário. <br />
        3) Os pagamentos acima não excluem eventuais custos bancários
        decorrentes do contrato firmado entre o contratante e a instituição
        financeira. <br />
        4) Após a quitação dos itens acima, havendo saldo remanescente na conta
        consignada, este saldo será considerado “recurso livre” e será
        direcionado para uma conta de livre movimentação do favorecido,
        especificada no contrato de adesão à conta vinculada em até 1 (um) dia
        útil. É vedada à Plataforma Antecipa Fácil a retenção e destinação de
        recursos da conta vinculada para quaisquer outras finalidades que não
        sejam as especificadas no referido contrato de adesão assinado
        anteriormente.
      </Typography>
      <Typography variant="h2">LIQUIDAÇÃO: Regras de Conciliação</Typography>
      <Typography variant="h4">Fatores de convergência:</Typography>
      <Typography variant="h5">
        Sempre que houver um evento de crédito na Conta Consignada e este
        crédito for compatível com uma operação de antecipação de recebível
        ocorrido no ambiente do mercado digital da Antecipa Fácil, a Plataforma
        direcionará o crédito para o Agente Financeiro adquirente do título para
        a respectiva liquidação. A compatibilidade do crédito em conta com a
        operação de antecipação será verificada pela convergência de três
        fatores no comparativo do crédito com a operação realizada na
        Plataforma. Quais sejam: Fonte pagadora, valor do crédito e data do
        crédito.
      </Typography>
      <Typography variant="h4">Convergência do valor do crédito:</Typography>
      <Typography variant="h5">
        O valor de um crédito será considerado convergente caso este valor
        represente de 50% a 110% do valor de uma operação de antecipação
        realizada na Plataforma que esteja vencendo no dia ou a vencer.
      </Typography>
      <Typography variant="h4">Convergência Parcial:</Typography>
      <Typography variant="h5">
        Caso apenas dois dos fatores citados no item 1. convirjam, e um deles
        seja a Fonte Pagadora, a conciliação não ocorrerá automaticamente,
        fazendo-se necessária uma análise da mesa conciliadora para verificar
        se: <br />
        a) houve crédito proveniente de uma liquidação antecipada; <br />
        b) trata-se de uma liquidação parcial de título antecipado; <br />
        c) trata-se recurso livre do favorecido titular da conta.
      </Typography>
      <Typography variant="h4">Verificação da mesa conciliadora:</Typography>
      <Typography variant="h5">
        A verificação da mesa conciliadora será baseada na consulta ao
        favorecido, que deverá comprovar por meio da apresentação de um
        documento fiscal que, o recurso creditado não se trata de liquidação de
        título transacionado na Plataforma e, portanto, deverá ser transferido
        como recurso livre.
      </Typography>
      <Typography variant="h4">Notificação da mesa conciliadora:</Typography>
      <Typography variant="h5">
        Caso ocorra o evento descrito acima e, o favorecido deixe de se
        manifestar em 24h úteis a partir da notificação da mesa conciliadora, o
        crédito será direcionado ao(s) agente(s) financeiro(s) adquirente(s) do
        título que apresentar as duas convergências.
      </Typography>
      <Typography variant="h4">Liquidação das operações:</Typography>
      <Typography variant="h5">
        Em caso de vencimento de duas ou mais operações com a mesma data de
        crédito prevista, mesma fonte pagadora e mesmo valor, em situações em
        que só uma ou mais, desde que não representem a totalidade das
        liquidações, tenha crédito verificado em conta consignada, tais créditos
        serão direcionados para a mesa de liquidação, que avaliará a operação
        que de fato tenha sido liquidada com o referido propósito. <br />
        Após analisar as comprovações, a mesa conciliadora direcionará os
        recursos para os adquirentes de fato. Caso tal evento ocorra, em caráter
        excepcional, o prazo máximo para o roteamento dos recursos passará para
        48 horas úteis.
      </Typography>
      <Typography variant="h4">Liquidações parciais:</Typography>
      <Typography variant="h5">
        Caso constate-se que a conta consignada recebeu um crédito parcial para
        a liquidação de operações antecipação, dar-se-á:
      </Typography>
      <Typography variant="h4">
        Operação envolvendo um único financiador:
      </Typography>
      <Typography variant="h5">
        O crédito referente à liquidação parcial será direcionado para o Agente
        Financeiro adquirente do título, ocorrerá a baixa parcial da operação na
        plataforma, e o cedente tomador do crédito deverá repactuar a fração do
        título não liquidada com o financiador ou estará sujeito às ações de
        cobrança.
      </Typography>
      <Typography variant="h4">
        Caso de operação envolva múltiplos financiadores:
      </Typography>
      <Typography variant="h5">
        A liquidação parcial de uma ou mais operações será feita
        proporcionalmente aos valores antecipados pelos agentes financeiros.
      </Typography>
      <Typography variant="h4">Transferência de Recursos Livres:</Typography>
      <Typography variant="h5">
        Caso haja créditos em conta consignada que não se possa verificar a
        convergência dos fatores de conciliação descritos nos termos dos itens
        acima e o favorecido encontrar-se com operações vencidas ou vencendo (no
        mesmo dia), os recursos creditados serão destinados à liquidação das
        operações ou parcelas vencidas e vencendo, independentemente da fonte
        pagadora.
      </Typography>
      <Typography variant="h4">
        Roteamento de créditos com operações vencidas:
      </Typography>
      <Typography variant="h5">
        A prioridade da liquidação será baseada nos critérios de liquidação
        descritos acima. Caso os “fatores de convergência” não sejam
        verificados, o recurso creditado será destinado aos Financiadores com
        operações vencidas, obedecendo o seguinte critério: a) os que tenham
        operações vencidas da mesma Fonte Pagadora; <br /> b) ou todos outros.
      </Typography>
      <Typography variant="h4">Definições:</Typography>
      <Typography variant="h5">
        1. Operação: Direito creditório, título ou parcela que represente
        capital antecipado em uma operação de crédito realizada na Plataforma
        Antecipa Fácil. <br />
        2. Recebíveis: são os títulos de crédito que o Cedente disponibilizará
        na Plataforma Antecipa, ou por qualquer outro meio, para que sejam
        antecipados pelo Financiador. <br />
        3. Plataforma Antecipa: é uma plataforma que une financiadores e
        empresas que precisam de crédito, através de leilão online de recebíveis
        (duplicatas), de forma segura e transparente para ambos. <br />
        4. Recurso livre: Crédito na conta consignada que não seja conciliável
        com operações de antecipação de recebíveis realizadas na Plataforma e
        que, portanto, serão transferidos para uma conta de livre movimentação
        do favorecido.
      </Typography>
      <Typography variant="h2">
        DOCUMENTOS PARA REALIZAR OPERAÇÃO VIA CONTA CONSIGNADA
      </Typography>
      <Typography variant="h5">
        Para que uma operação seja realizada, via conta consignada, é necessário
        que o contratante apresente o pedido da operação de compra e venda que
        originou o crédito e a respectiva nota fiscal, na qual deve constar
        expressamente, a conta vinculada como destinatária do pagamento a ser
        realizado. Além dessas informações, é desejável a apresentação de outras
        evidências de entrega e conformidade dos produtos ou serviços geradores
        da operação conforme especificado no site de cadastro de operações na
        Antecipa Fácil.
      </Typography>
      <Typography variant="h4">
        Requisitos para realização da operação via boleto financiador:
      </Typography>
      <Typography variant="h5">
        • Pedido de compra <br />
        • Aceite do Sacado <br />• Nota Fiscal
      </Typography>
      <Typography variant="h4">Reputação de usuários:</Typography>
      <Typography variant="h5">
        A Plataforma exibirá entre adquirentes de títulos uma análise da
        reputação do cedente baseada nas transações por ele efetuadas na
        Plataforma, com o intuito de auxiliá-los na avaliação do risco da
        operação e tomada de decisão. Além disso, irá monitorar a reputação de
        adquirentes de títulos apenas para seus controles e construção de
        parâmetros internos, mas não irá exibir essas informações para outros
        usuários.
      </Typography>
      <Typography variant="h2">LIMITE DE OPERAÇÃO</Typography>
      <Typography variant="h5">
        Nos três primeiros meses de operação, a antecipa irá considerar como
        limite, o faturamento declarado versus o prazo médio de recebimento
        declarado, e poderá antecipar recebíveis que comprometam até 80% do
        resultado desta relação. Após o terceiro mês, será considerado limite de
        crédito, os valores mensais de depósitos, originados por Sacados, em
        conta consignada versus o prazo médio. A extensão desses limites não são
        garantidas e estará sujeita a avaliações adicionais da mesa de crédito.
      </Typography>
      <Typography variant="h2">ALTERAÇÃO</Typography>
      <Typography variant="h5">
        Este Termo poderá ser alterado, modificado ou aditado a qualquer
        momento, ficando a Plataforma Antecipa responsável por comunicar as
        Partes através de e- mail em 07 (sete) dias.
      </Typography>
      <br />
      <Box display="flex" justifyContent="flex-end">
        <Button
          disabled={disableButton}
          variant="contained"
          color="primary"
          style={{ color: "white" }}
          onClick={() => {
            sendAcceptTerms();
          }}
        >
          Aceitar
        </Button>
      </Box>
    </>
  );
}
