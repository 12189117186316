import AxiosInterceptor from "utils/axios-interceptor";

export const getTableContasFilter = async (shortOrigin) => {
  const axiosInstance = AxiosInterceptor();
  const response = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/businessBankAccounts/filter?asc=desc&limit=10&shortOrigin=${shortOrigin}`
  );
  return response;
};

export const getTableContas = async (
  filterParams,
  orderBy,
  ASC,
  page,
  shortOrigin
) => {
  const axiosInstance = AxiosInterceptor();
  const response = await axiosInstance.get(
    `${
      process.env.REACT_APP_NODE_API
    }/businessBankAccounts/filter?${filterParams}limit=10&orderBy=${
      orderBy !== undefined ? orderBy : "statusDate"
    }&asc=${ASC !== undefined ? ASC : "DESC"}&page=${
      page !== undefined ? page : 0
    }&shortOrigin=${shortOrigin}`
  );

  return response;
};

export const updateBankAccountSevices = async (updatedBankAccount) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/businessPayrollAccounts/update`,
    updatedBankAccount
  );

  return data;
};
