import React, { useEffect, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CloseIcon from '@material-ui/icons/Close';

import clsx from 'clsx';

import BoltIcon from '../icons/Bolt';
import Cedente from './Cedente';
import DarOferta from './DarOferta';
import Lastro from './Lastro';
import Titulo from './Titulo';

import './styles.scss';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      className="modal-dar-oferta--tabpanel"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
    >
      {value === index && children}
    </div>
  );
}

export default function ModalDarOferta({
  abdicado,
  alterandoOferta,
  businessId,
  calcularRisco,
  calcularTituloCet,
  cnpj,
  darLance,
  data,
  desagio,
  diminuirOferta,
  formatarData,
  formatReal,
  fraseErro,
  initialTab = 0,
  handleChange,
  novoFactor,
  ofertaAtual,
  ofertaIgual,
  onClose,
  open,
  subirOferta,
  titulo,
  values,
}) {
  const [value, setValue] = useState(initialTab);

  useEffect(() => {
    if (initialTab !== value) {
      setValue(initialTab);
    }
  }, [initialTab]);

  return (
    <Dialog
      className="modal-dar-oferta"
      classes={{
        container: 'modal-dar-oferta--container',
        paper: 'modal-dar-oferta--paper',
      }}
      disableBackdropClick
      scroll="body"
      maxWidth="xl"
      open={open}
      onClose={onClose}
    >
      <div className="modal-dar-oferta--top">
        <div className="modal-dar-oferta--top--left">
          <h1 className="modal-dar-oferta--title">
            {titulo?.business?.name} ID: {titulo?.invoice?.id}
          </h1>
          {titulo?.invoice?.bidBusinessId === businessId && (
            <div className="modal-dar-oferta--badge">
              <BoltIcon color="#ffffff" size={17} />
              <span>Você está ganhando</span>
            </div>
          )}

          {titulo?.invoice?.marketTypeId === 4 && (
            <div className="modal-dar-oferta--badge modal-dar-oferta--badge--green">
              {titulo?.invoice?.marketTypeName}
            </div>
          )}

          {titulo?.invoice?.marketTypeId === 2 && (
            <div className="modal-dar-oferta--badge modal-dar-oferta--badge--green">
              {titulo?.invoice?.marketTypeName}
            </div>
          )}
        </div>

        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon style={{ color: '#272727', fontSize: 30 }} />
        </IconButton>
      </div>

      <div className="modal-dar-oferta--tabs--container">
        <Tabs
          classes={{ indicator: 'modal-dar-oferta--indicator' }}
          onChange={(event, newValue) => setValue(newValue)}
          value={value}
        >
          <Tab
            classes={{ root: 'modal-dar-oferta--tab-root' }}
            label="Dar oferta"
          />
          <Tab
            className="modal-dar-oferta-tab-lastros"
            classes={{ root: 'modal-dar-oferta--tab-root' }}
            label="Lastros"
          />
          <Tab
            classes={{ root: 'modal-dar-oferta--tab-root' }}
            label="Cedente"
          />

          <Tab
            className="modal-dar-oferta-tab-titulo"
            classes={{ root: 'modal-dar-oferta--tab-root' }}
            label="Título"
          />
        </Tabs>
      </div>

      <div
        className={clsx(
          'modal-dar-oferta--content',
          value !== 0 && 'modal-dar-oferta--content--grey'
        )}
      >
        <TabPanel value={value} index={0}>
          <DarOferta
            abdicado={abdicado}
            calcularRisco={calcularRisco}
            calcularTituloCet={calcularTituloCet}
            cnpj={cnpj}
            darLance={darLance}
            desagio={desagio}
            diminuirOferta={diminuirOferta}
            formatarData={formatarData}
            formatReal={formatReal}
            fraseErro={fraseErro}
            handleChange={handleChange}
            ofertaAtual={ofertaAtual}
            novoFactor={novoFactor}
            ofertaIgual={ofertaIgual}
            subirOferta={subirOferta}
            titulo={titulo}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Lastro titulo={titulo} values={values} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Cedente activeComponent={data?.activeComponent} titulo={titulo} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Titulo titulo={titulo} />
        </TabPanel>
      </div>
    </Dialog>
  );
}
