/* eslint eqeqeq: 0 */
import React from "react";

import {
  FormControl,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";

import { ConfirmButton } from "components/Button";
import { syncZeev } from "services/business";
import { useToast } from "contexts/toast";

export function StatusCadastro({
  motives,
  business,
  updateBusiness,
  setBusiness,
  radioState,
  setRadioState,
  radioStates,
  status,
  handleSetMotives,
  statusCadastro,
  statusFinal,
  handlerChange,
}) {
  const { toast } = useToast();

  async function handleZeev(business) {
    try {
      await syncZeev(business);

      toast.success("Sincronização do Zeev completa");
    } catch (error) {
      toast.error("Erro ao sincronizar Zeev");
    }
  }
  return (
    <>
      <div className="searchForm" style={{ marginTop: "30px" }}>
        <div className="containerTitle w-100 pr-2">
          <div
            className={`itemTile d-flex flex-row justify-content-${
              business?.typeId === 1 ? "between" : "start"
            }`}
          >
            <h3 style={{ fontFamily: "Poppins, sans-serif" }}>
              Status do Cadastro
            </h3>
            {business.statusId === 5 && (
              <ConfirmButton
                style={{ marginRight: "22px" }}
                onClick={() => handleZeev(business.businessId)}
              >
                Sincronizar com Zeev
              </ConfirmButton>
            )}

            {/* { business?.typeId === 1 && <h3>Parceiro de Origem Cedente</h3>} */}
          </div>
        </div>
        <div
          className={`d-flex flex-row justify-content-${
            business?.typeId === 1 ? "between" : "start"
          }`}
        >
          <div className="d-flex flex-row justify-content-start">
            <div className="d-flex flex-row align-items-center">
              <Radio
                className="mt-3"
                checked={radioState === radioStates.cadastro}
                onClick={() => setRadioState(radioStates.cadastro)}
              />
              <span className="d-flex flex-row align-items-center text-nowrap mr-2 mt-3">
                {radioStates.cadastro}
              </span>
            </div>
            <div className="d-flex flex-row">
              <Radio
                className="mt-3"
                checked={radioState === radioStates.final}
                onClick={() => setRadioState(radioStates.final)}
              />
              <span className="d-flex flex-row align-items-center text-nowrap mr-2 mt-3">
                {radioStates.final}
              </span>
            </div>
          </div>
          {/* { business?.typeId === 1 && <h2 className="mt-3 pr-2">FEDERAL_INVEST</h2> } */}
        </div>

        <div className="containerTitle mt-4">
          <div className="itemTile">
            <h3 style={{ fontFamily: "Poppins, sans-serif" }}>{radioState}</h3>
          </div>
        </div>
        <div
          className={`containerDatePicker w-100 d-flex flex-row align-items-center justify-content-${
            business?.typeId === 1 ? "between" : "start"
          }`}
        >
          <FormControl className="w-25 m-2">
            <InputLabel id="demo-simple-select-label" className="ml-2 mt-2">
              Tipo
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={Number(business?.statusId)}
              onChange={(e) => {
                setBusiness({
                  ...business,
                  statusId: e.target.value,
                  statusName: status.find((id) => id.statusId == e.target.value)
                    ?.statusName,
                });
                handleSetMotives(e.target.value);
              }}
            >
              {radioState === radioStates.cadastro
                ? statusCadastro.map((element) => (
                    <option key={element.statusId} value={element.statusId}>
                      {element.statusName}
                    </option>
                  ))
                : statusFinal.map((element) => (
                    <option key={element.statusId} value={element.statusId}>
                      {element.statusName}
                    </option>
                  ))}
            </Select>
          </FormControl>
          <FormControl className="w-25 m-2">
            <InputLabel id="demo-simple-select-label" className="ml-2 mt-2">
              Motivo
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={business?.motiveId}
              onChange={(e) => {
                setBusiness({
                  ...business,
                  motiveId: e.target.value,
                  motiveName: motives.find(
                    (id) => id.motiveId == e.target.value
                  )?.motiveName,
                });
              }}
            >
              <MenuItem value="" />
              {handleSetMotives(business?.statusId).map((element) => (
                <option key={element.motiveId} value={element.motiveId}>
                  {element.motiveName}
                </option>
              ))}
            </Select>
          </FormControl>

          <div className="item pr-2 w-100 mt-3">
            <TextField
              fullWidth
              className="mt-3 ml-1 mr-1 d-flex flex-row align-items-center parceiroInput"
              id="outlined-basic"
              label="Observação*"
              name="note"
              value={business?.note}
              variant="outlined"
              onChange={handlerChange}
            />
          </div>
        </div>
        <div className="item itemPicker" style={{ paddingRight: "2.5%" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
            }}
          >
            <ConfirmButton
              onClick={() => updateBusiness("status")}
              disabled={
                !business.motiveId ||
                !business.statusId ||
                !!!handleSetMotives(business?.statusId).find(
                  (id) => id.motiveId == business.motiveId
                )
              }
            >
              Alterar Status
            </ConfirmButton>
          </div>
        </div>
      </div>
    </>
  );
}
