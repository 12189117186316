import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { connect } from 'react-redux';

import SideMenu from 'components/SideMenu';
import { Footer } from '../../layout-components';

import useVitrine from 'hooks/useVitrine';

const LeftSidebar = (props) => {
  const {
    children,
    sidebarToggle,
    sidebarFixed,
    footerFixed,
    contentBackground,
  } = props;

  const { isOnVitrine } = useVitrine();

  return (
    <>
      <div className={clsx('app-wrapper', contentBackground)}>
        <div
          className={clsx('app-main', {
            'app-main-sidebar-static': !sidebarFixed,
          })}
        >
          <SideMenu />

          <div
            className={clsx('app-content', {
              'app-content-vitrine': isOnVitrine,
              'app-content-sidebar-collapsed': sidebarToggle,
              'app-content-sidebar-fixed': sidebarFixed,
              'app-content-footer-fixed': footerFixed,
            })}
          >
            <div
              className="app-content--inner__wrapper"
              style={{ marginLeft: !isOnVitrine ? -50 : 0 }}
            >
              {children}
            </div>

            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,

  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,

  contentBackground: state.ThemeOptions.contentBackground,
});

export default connect(mapStateToProps)(LeftSidebar);
