import { useAuth } from "contexts/auth";
import { useState, useEffect } from "react";

export const useUserType = () => {
  const [userType, setUserType] = useState(null);

  useEffect(() => {
    if (localStorage.getItem("userType")) {
      setUserType(localStorage.getItem("userType"));
    }
  }, []);

  return userType;
};
