import React from 'react';

export function ReactThen({ children }) {
  return children;
}

export function ReactCatch({ children }) {
  return children;
}

export function ReactCatchIf({ children, condition }) {
  if (condition) {
    return children;
  }

  return null;
}

export function ReactIf({ children, condition }) {
  if (!children) return null;

  if (condition) {
    const element = React.Children.toArray(children).find(
      (child) => child.type == ReactThen
    );

    if (!element) throw new Error('Componente ReactThen obrigatório');

    return element;
  }

  const element = React.Children.toArray(children).find(
    (child) => child.type === ReactCatch || child.type === ReactCatchIf
  );

  return element || null;
}
