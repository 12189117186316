import AxiosInterceptor from "utils/axios-interceptor";

export const createPending = async (businessId, documentTypeName) => {
  const axiosInstance = AxiosInterceptor();
  const response = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/pending/create`,
    {
      businessId,
      typeName: documentTypeName,
    }
  );
  return response;
};

export const updatePending = async (document) => {
  const axiosInstance = AxiosInterceptor();
  const response = await axiosInstance.patch(
    `${process.env.REACT_APP_NODE_API}/files/update/${document.fileId}`,
    { ...document, downloadUrl: undefined }
  );

  return response;
};

export const getUploadDocument = async (businessId) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/business/documents/${businessId}`,
    {
      responseType: "arraybuffer",
    }
  );
  return data;
};

const getProgress = (event, handleProgress) => {
  if (event.lengthComputable) {
    const percentComplete = Math.round((event.loaded * 100) / event.total);

    handleProgress(percentComplete);
  }
};

export const uploadDocument = async (formData, handleUploadProgress) => {
  const axiosInstance = AxiosInterceptor();
  const response = await axiosInstance.post(
    `${process.env.REACT_APP_UPLOAD_BACKEND_API}/files/upload`,
    formData,
    {
      onUploadProgress: (event) => {
        getProgress(event, handleUploadProgress);
      },
      // responseType: 'arraybuffer',
    }
  );
  return response;
};
