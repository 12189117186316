import React from 'react';

function TabPage({ children, title, type }) {
  const isOnModalLastro = type === 'modal-lastro';

  return (
    <>
      <div
        style={{
          marginTop: '30px',
          marginLeft: !isOnModalLastro && -65,
          width: isOnModalLastro ? '100%' : '105%',
        }}
      >
        <div
          className="searchForm"
          style={{
            marginLeft: !isOnModalLastro && 35,
            width: isOnModalLastro && '100%',
          }}
        >
          <div className="containerTitle">
            <div className="itemTile">
              <h2 style={{ fontFamily: 'Poppins' }}>
                {title || 'Defína um título'}
              </h2>
            </div>
          </div>
          <div className="containerBody" style={{ width: '100%' }}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default TabPage;
