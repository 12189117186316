import React, { useContext, useEffect } from 'react';

import TermsModal from 'components/TermsModal';

import { useAuth } from 'contexts/auth';
import { DialogContext } from 'contexts/dialog';

import useTerms from 'hooks/useTerms';

const identity = 'TA01';

export default function Terms() {
  const { user } = useAuth();

  const { toggleDialog } = useContext(DialogContext);
  const { error } = useTerms(user, identity);

  useEffect(() => {
    if (error?.response?.status === 404) {
      toggleDialog(
        true,
        'TERMOS E CONDIÇÕES DE USO',
        <TermsModal user={user} identity={identity} />,
        false
      );
    }
  }, [error]);

  return null;
}
