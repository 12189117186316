import React, { Fragment } from "react";

import clsx from "clsx";
import { AppBar } from "@material-ui/core";

import { connect } from "react-redux";

import {
  setSidebarToggle,
  setSidebarToggleMobile,
} from "../../reducers/ThemeOptions";

import HeaderLogo from "../../layout-components/HeaderLogo";
import { Button } from "@material-ui/core";
import { useAuth } from "../../contexts/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from 'react-router-dom'

const Header = (props) => {
  const history = useHistory()
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const {
    headerShadow,
    headerFixed,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle,
  } = props;

  const { Logout } = useAuth();

  return (
    <Fragment>
      <AppBar
        color="primary"
        className={clsx("app-header", {
          "app-header-collapsed-sidebar": props.isCollapsedLayout,
        })}
        position={headerFixed ? "fixed" : "absolute"}
        elevation={headerShadow ? 11 : 3}
        style={{ width: "100%", display: "flex", justifyContent: "between" }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {!props.isCollapsedLayout && <HeaderLogo />}
          <Button
            onClick={async () => {await Logout(); history.push("/")}}
            color="secondary"
            className="m-2 mr-4"
          >
            <span
              className="btn-wrapper--label"
              style={{
                color: "white",
                fontWeight: "bolder",
                fontFamily: "sans-serif",
                fontSize: "1.4em",
              }}
            >
              Sair
            </span>
            <span className="btn-wrapper--icon">
              <FontAwesomeIcon icon={["fas", "sign-out-alt"]} color="red" size="lg" />
            </span>
          </Button>
        </div>
      </AppBar>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
