import React from 'react';

import { Button } from '@material-ui/core';

export default function AcceptButton({
  onClick = null,
  type = 'submit',
  disabled = false,
}) {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      fullWidth
      disabled={disabled}
      type={type}
      style={{ color: 'white' }}
      color="primary"
    >
      Aceitar
    </Button>
  );
}
