import React from 'react';

export default function Youtube({ color = '#A0A0A0', size = 40 }) {
  return (
    <svg
      id="youtube_1_"
      data-name="youtube (1)"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 75.74) / 100}
      viewBox="0 0 45.329 34.332"
    >
      <path
        id="Caminho_80"
        data-name="Caminho 80"
        d="M203.682,112.231l-10.014-5.479a1.839,1.839,0,0,0-2.722,1.614V119.23a1.836,1.836,0,0,0,2.711,1.62l10.014-5.385a1.839,1.839,0,0,0,.011-3.234Zm-10.08,5.632v-8.12l7.484,4.1Zm0,0"
        fill={color}
        transform="translate(-174.041 -97.093)"
      />
      <path
        id="Caminho_81"
        data-name="Caminho 81"
        d="M44.995,8.1l0-.021c-.038-.365-.421-3.61-2-5.261A7.376,7.376,0,0,0,38.109.531L37.884.5,37.805.5C31.813.06,22.763,0,22.672,0h-.016c-.091,0-9.141.06-15.187.5L7.389.5,7.177.53A7.19,7.19,0,0,0,2.32,2.891C.818,4.524.383,7.7.339,8.057L.333,8.1C.32,8.255,0,11.869,0,15.5v3.392c0,3.628.32,7.243.333,7.395l0,.023c.038.359.42,3.545,1.991,5.2a7.527,7.527,0,0,0,5.049,2.258c.184.021.343.039.451.058l.1.015c3.46.329,14.308.491,14.768.5h.028c.091,0,9.14-.06,15.133-.5l.079-.008c.076-.01.161-.019.254-.029a7.024,7.024,0,0,0,4.815-2.3c1.5-1.633,1.937-4.809,1.982-5.166l.005-.046c.013-.152.334-3.766.334-7.394V15.5C45.328,11.869,45.008,8.255,44.995,8.1ZM42.672,18.889c0,3.358-.293,6.817-.321,7.134a8.433,8.433,0,0,1-1.3,3.68,4.368,4.368,0,0,1-3.135,1.455c-.1.011-.2.021-.283.032-5.8.419-14.5.483-14.906.486-.45-.007-11.139-.17-14.494-.482-.172-.028-.358-.049-.553-.072A4.967,4.967,0,0,1,4.28,29.7l-.025-.026a7.651,7.651,0,0,1-1.277-3.643c-.021-.24-.322-3.739-.322-7.145V15.5c0-3.355.293-6.809.321-7.133a8.261,8.261,0,0,1,1.3-3.681,4.583,4.583,0,0,1,3.2-1.515L7.7,3.143c5.88-.421,14.651-.484,14.966-.486s9.082.065,14.911.486l.234.028a4.757,4.757,0,0,1,3.253,1.474l.011.011a7.775,7.775,0,0,1,1.277,3.7c.02.227.322,3.734.322,7.146Zm0,0"
        fill={color}
        transform="translate(0)"
      />
    </svg>
  );
}
