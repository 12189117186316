import AxiosInterceptor from "utils/axios-interceptor";

export async function getBusinessbyId(businessId) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/users/business/${businessId}`
  );
  return data;
}

export async function getBusinessbyIdUser(businessId, userId) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/users/businessuser/${businessId}/${userId}`
  );
  return data;
}

export async function postBid(bid) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/bid`,
    {
      bid,
    }
  );
  return data;
}

export async function updateMaturity(parcela) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/maturity/update`,
    {
      parcela,
    }
  );
  return data;
}

export async function melhorLance(invoiceId) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/bid/lastbid`,
    {
      invoiceId,
    }
  );
  return data;
}

export async function salvarLance(
  invoiceId,
  businessId,
  novoFactor,
  comissaoNogord,
  userId,
  userName,
  ofertaAtual
) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/invoice/lance`,
    {
      invoiceId,
      businessId,
      novoFactor,
      comissaoNogord,
      userId,
      userName,
      ofertaAtual,
    }
  );
  return data;
}

export async function arrematar(
  invoiceId,
  businessId,
  novoFactor,
  comissaoNogord,
  userId,
  userName
) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/invoice/arrematar`,
    {
      invoiceId,
      businessId,
      novoFactor,
      comissaoNogord,
      userId,
      userName,
    }
  );
  return data;
}

export async function updateInvoiceBuyer(invoiceId, buyerId) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/InvoiceBuyer/update`,
    {
      updatedInvoiceBuyer: { invoiceId, buyerId },
    }
  );
  return data;
}

export async function updateInvoice(updatedInvoice, invoiceId) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/invoice/update`,
    {
      updatedInvoice,
      invoiceId,
    }
  );
  return data;
}

export async function lostBid(bid) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/bid/lostbid`,
    {
      bidId: bid,
    }
  );
  return data;
}

export async function getTaxaMaxima(titulo, user) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/nogord/taxa-maxima`,
    {
      valor: titulo.invoice.maturitiesValue,
      rating: titulo.invoice.riskId,
      prazoMedio: titulo.invoice.bidAverageTerm,
      invoiceID: titulo.invoice.invoiceId,
      origin_type: user.originType,
      sellerID: titulo.business.businessId,
    }
  );
  return data;
}

export async function calculoCetSubir(user, titulo, taxaComissao) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/bid/calculo/cet`,
    {
      buyerId: user.businessId,
      invoiceId: titulo.invoice.invoiceId,
      fatorDesagio: titulo.invoice.bidFactor + 0.05,
      comissaoNog: taxaComissao,
    }
  );
  return data;
}

export async function calculoCetFactor(user, titulo, taxaComissao, novoFactor) {
  const axiosInstance = AxiosInterceptor();
  if (
    titulo.invoice.maxOffer != null &&
    titulo.invoice.abdicado == "1" &&
    titulo.invoice.bidFactor == 0
  ) {
    const { data } = await axiosInstance.post(
      `${process.env.REACT_APP_NODE_API}/bid/calculo/cet`,
      {
        buyerId: user.businessId,
        invoiceId: titulo.invoice.invoiceId,
        fatorDesagio: novoFactor,
        comissaoNog: taxaComissao,
      }
    );
    return data;
  }
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/bid/calculo/cet`,
    {
      buyerId: user.businessId,
      invoiceId: titulo.invoice.invoiceId,
      fatorDesagio: novoFactor,
      comissaoNog: taxaComissao,
    }
  );
  return data;
}

export async function calculoCet(user, titulo, taxaComissao) {
  const axiosInstance = AxiosInterceptor();
  if (
    titulo.invoice.maxOffer != null &&
    titulo.invoice.abdicado == "1" &&
    titulo.invoice.bidFactor == 0
  ) {
    const { data } = await axiosInstance.post(
      `${process.env.REACT_APP_NODE_API}/bid/calculo/cet`,
      {
        buyerId: user.businessId,
        invoiceId: titulo.invoice.invoiceId,
        fatorDesagio: titulo.invoice.bidFactor,
        comissaoNog: taxaComissao,
      }
    );
    return data;
  }

  let fatorDesagioAjustado = titulo.invoice.bidFactor;
  if (Number.isNaN(parseFloat(fatorDesagioAjustado))) {
    fatorDesagioAjustado = -5;
  }
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/bid/calculo/cet`,
    {
      buyerId: user.businessId,
      invoiceId: titulo.invoice.invoiceId,
      fatorDesagio: fatorDesagioAjustado,
      comissaoNog: taxaComissao,
    }
  );
  return data;
}

export async function calculoCetAbdicado(user, titulo, taxaComissao) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/bid/calculo/cetabdicado`,
    {
      buyerId: user.businessId,
      invoiceId: titulo.invoice.invoiceId,
      fatorDesagio: titulo.invoice.bidFactor,
      comissaoNog: taxaComissao,
    }
  );
  return data;
}

export async function calculoCetAbdicar(
  user,
  titulo,
  taxaAbdicar,
  taxaComissao
) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/bid/calculo/cet`,
    {
      buyerId: user.businessId,
      invoiceId: titulo.invoice.invoiceId,
      fatorDesagio: taxaAbdicar,
      comissaoNog: taxaComissao,
    }
  );
  return data;
}

export async function calculoCetDescer(user, titulo, taxaComissao) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/bid/calculo/cet`,
    {
      buyerId: user.businessId,
      invoiceId: titulo.invoice.invoiceId,
      fatorDesagio:
        titulo.invoice.bidFactor === "0.000" || !titulo.invoice.bidFactor
          ? 0
          : titulo.invoice.bidFactor - 0.05,
      comissaoNog: taxaComissao,
    }
  );
  return data;
}

export async function actionInvoices(businessId) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/invoice/action/${businessId}`
  );
  return data;
}

export async function actionInvoicesWt(businessId, originType) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/invoice/action/${businessId}/${originType}`
  );
  return data;
}

export async function abdicate(invoiceId, taxa, taxaCet, buyerId) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/invoice/update/abdicate`,
    { invoiceId, taxa, taxaCet, buyerId }
  );
  return data;
}
