import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AuthProvider } from 'contexts/auth';
import { ToastProvider } from 'contexts/toast';
import { PermissionProvider } from 'contexts/permission';
import { weakStart } from 'mocks/browser';

import { worker } from "./mocks/browser";

if (process.env.NODE_ENV === 'development') {
  // This enables the mocks but doesn't start them by default
  // To start the mocks, on browser console, run:
  // window.mocks.start()
  // window.mocks.stop()
  // After starting, if the page refreshes, the mocks will stop again
  weakStart(worker);
}

// Deixar comentado
// weakStart(worker);
// window.mocks.start();

const notistackRef = React.createRef();

const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};
ReactDOM.render(
  <ToastProvider>
    <AuthProvider>
      <PermissionProvider>
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            maxSnack={3}
            ref={notistackRef}
            // preventDuplicate
            action={(key) => (
              <IconButton
                aria-label="close"
                color="inherit"
                // className={classes.close}
                onClick={onClickDismiss(key)}
              >
                <CloseIcon />
              </IconButton>
            )}
          >
            <App />
          </SnackbarProvider>
      </PermissionProvider>
    </AuthProvider>
  </ToastProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
