import React from "react";

import { Card, FormControl, MenuItem, TextField } from "@material-ui/core";

import VencList from "pages/AgendaRecebiveis/VencList";

import { ConfirmButton } from "components/Button";
import { ReactIf, ReactThen } from "components/ReactIf";
import { Recebiveis } from "components/Recebiveis";
import { Wrapper } from "components/Wrapper";

import formatReal from "utils/formatReal";

import { useCadastroTituloRecebivel } from "hooks/useCadastroTituloRecebivel";
import { useModal } from "hooks/useModal";

export function CadastroTitulo(props) {
  const { getAgendas, handleClose: handleCloseCadastro } = props;

  const { businessId: sessionBusinessId, email } = JSON.parse(
    localStorage.getItem("@App:user")
  );

  const {
    handleEnviarFormulario,
    addNewVenc,
    formValues,
    parcelasGeradas,
    gerarParcelasAgendas,
    getSavedAgendas,
    saveAgendas,
    openRecebiveis,
    tiposRecebiveis,
    handlerChange,
  } = useCadastroTituloRecebivel({
    email,
    businessId: sessionBusinessId,
    getAgendas,
    handleClose: handleCloseCadastro,
  });

  const { openModal } = useModal();

  return (
    <>
      <Card
        style={{
          display: "flex",
          flexDirection: "column",

          justifyContent: "flex-start",
          alignContent: "flex-start",
          alignItems: "flex-start",

          width: "100%",
        }}
      >
        <Wrapper sectionHeading="Informe o tipo da Nota Fiscal">
          <div style={{ marginTop: "20px", width: "100%" }}>
            <div style={{ marginLeft: "30px", display: "inline" }}>
              <FormControl style={{ width: "250px" }}>
                <TextField
                  autoFocus
                  fullWidth
                  select
                  variant="outlined"
                  label="Tipo de Nota"
                  id="tipoRecebivel"
                  value={formValues.tipoRecebivel || ""}
                  name="tipoRecebivel"
                  onChange={(event) => {
                    handlerChange(event);
                  }}
                >
                  <MenuItem value="">Selecione</MenuItem>
                  {tiposRecebiveis &&
                    tiposRecebiveis.map((tipo) => (
                      <MenuItem key={tipo.value} value={tipo.value}>
                        {tipo.label}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </div>
          </div>
        </Wrapper>

        <ReactIf condition={openRecebiveis}>
          <ReactThen>
            <Wrapper sectionHeading="Quanto você deseja usar de cada recebivel?">
              <div style={{ marginTop: "20px", width: "100%" }}>
                <Recebiveis
                  addVenc={addNewVenc}
                  typeId={formValues.tipoRecebivel}
                  businessId={sessionBusinessId}
                  cadastroTitulo
                  getSavedAgendas={getSavedAgendas}
                  saveAgendas={saveAgendas}
                  formValues={formValues}
                  geradorParcelas={gerarParcelasAgendas}
                  esconderDataVencimento
                  esconderSacado
                  esconderRisco
                  esconderStatus
                  esconderPorcentagem
                  esconderValorUtlizadoTitulo
                />
              </div>
            </Wrapper>
          </ReactThen>
        </ReactIf>

        <Wrapper
          sectionHeading="Informe como você deseja liquidar esse título"
          column
        >
          <TextField
            autoFocus
            fullWidth
            variant="standard"
            id="valor"
            disabled
            label="Valor Total"
            value={formatReal(
              formValues.valor ? formValues.valor.toFixed(2) : ""
            )}
            name="valor"
          />
          {parcelasGeradas && <VencList venclist={formValues.vencList} />}
        </Wrapper>

        <Wrapper sectionHeading="" style={{ alignSelf: "end" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <ConfirmButton
              onClick={() =>
                openModal(
                  "error",
                  "Tem certeza ?",
                  "Confirme para prosseguir para a criação do título",
                  handleEnviarFormulario
                )
              }
            >
              Cadastrar Título
            </ConfirmButton>
          </div>
        </Wrapper>
      </Card>
    </>
  );
}
