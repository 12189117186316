export function setAppTab(longOrigin, favIcon) {
  // alterando fav-icon e título da página
  document.getElementById("app-title").innerHTML = longOrigin;
  document.getElementById("app-fav-icon").href = favIcon;
}

export function handleThemeAppTab() {
  // prevent app tab overwrite
  const storagedHostTheme = localStorage.getItem("@App:hostTheme");

  if (!storagedHostTheme) return;
  const theme = JSON.parse(storagedHostTheme);
  const storagedUser = localStorage.getItem("@App:user");
  !storagedUser && setAppTab(theme.longOrigin, theme.favIcon);

  if (storagedUser) {
    const user = JSON.parse(storagedUser);
    setAppTab(user?.tema?.longOrigin, user?.tema?.favIcon);
  }
}
