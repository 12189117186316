function formatCpf(valor) {
  if (!valor) return;
  valor = valor.replace(/\D/g, ''); // Remove tudo o que não é dígito
  valor = valor.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
  valor = valor.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
  // de novo (para o segundo bloco de números)
  valor = valor.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca um hífen entre o terceiro e o quarto dígitos
  return valor;
}

export default formatCpf;
