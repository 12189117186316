import Axios from 'axios';

// Default config options
const defaultOptions = {
  headers: {},
  baseURL: process.env.REACT_APP_NODE_API,
};

defaultOptions.headers.Authorization = `Bearer ${localStorage.getItem('bearer')}`;

// Create instance
const AxiosInstance = Axios.create(defaultOptions);

export default AxiosInstance;

