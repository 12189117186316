import React from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  MenuList,
  Popover,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import AgendaRecebiveis from "pages/AgendaRecebiveis";

import { removeAntecipaCors } from "utils/removeCors";

import * as moment from "moment";

import AxiosInterceptor from "utils/axios-interceptor";

export default ({ contratos, cotacao, close }) => {
  const [expanded, setExpanded] = React.useState("");
  const [loadingDetails, setLoadingDetails] = React.useState(false);
  const [dialogState, setDialogState] = React.useState({
    open: false,
    anchorEl: null,
  });
  const [contratoDetails, setContratoDetails] = React.useState({});

  const handleChange = (contrato) => async (event, isExpanded) => {
    const clickedOnArrow =
      Array.from(event.target.classList).filter(
        (classe) => classe === "MuiAccordionSummary-expandIcon"
      ).length > 0 ||
      event.target.nodeName === "svg" ||
      event.target.nodeName === "path";
    if (!clickedOnArrow) return;

    setExpanded(false);
    setContratoDetails({});

    if (isExpanded) {
      setExpanded(contrato.nrAnoContrato);
      await getContratoDetails(contrato);
    }
  };

  const handleClose = () => close(false);
  const getContratoDetails = async (contrato) => {
    const axiosInstance = AxiosInterceptor();
    setLoadingDetails(true);
    try {
      const { data } = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/serpro/contract/${contrato.cnpj}/${contrato.idCotacao}/details`,
        [{ nrAnoContrato: contrato.nrAnoContrato, uasg: contrato.uasg }]
      );
      setContratoDetails(data.contratos);
      return data;
    } catch (error) {
      return error;
    } finally {
      setLoadingDetails(false);
    }
  };

  const formatBRLCurrency = (value) => {
    if (Number.isNaN(value)) return value;
    return parseFloat(value).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const downloadPDF = (contrato) => {
    const token = localStorage.getItem("@App:token");
    let url = `${process.env.REACT_APP_SERPRO_PHP}/v1/serpro/getPDF.php?idCotacao=${contrato.idCotacao}&nrContrato=${contrato.nrAnoContrato}&uasg=${contrato.uasg}&token=${token}`;

    url = removeAntecipaCors(url);

    // abrir outra guia com o documento
    window.open(url, "_blank");
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "start",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        background: "#fff",
        margin: "0 auto",
        width: "65%",
        borderRadius: "10px",
        padding: "35px",
        rowGap: "50px",
        overflow: "auto",
      }}
    >
      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>Relação de Contratos - Cotação nº {cotacao.idCotacao}</h2>
          <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        </div>
        <div
          style={{
            justifyContent: "space-between",
            margin: "30px 0 0 0",
          }}
          className="labelContainer"
        >
          <div className="labelValue">
            <label>Empresa:</label>
            <p>
              {" "}
              {cotacao.niFornecedor.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5"
              )}{" "}
              - {cotacao.nomeFornecedor}
            </p>
          </div>
          <div className="labelValue">
            <label>Valor:</label>
            <p>
              {Number(cotacao.valorCotacao).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "BRL",
              })}
            </p>
          </div>
          <div className="labelValue">
            <label>Margem:</label>
            <p>
              {" "}
              R${" "}
              {Number(cotacao.margemDisponivel).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "BRL",
              })}
            </p>
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "0 0 15px 0",
          }}
        >
          <strong>Nr. Ano Contrato</strong>
          <strong>Sacado Pagador</strong>
          <strong>Baixar em PDF</strong>
          <strong>Agenda de Recebíveis</strong>
        </div>
        {contratos.map((contrato) => (
          <div>
            <Accordion
              key={contrato.nrAnoContrato}
              expanded={expanded === contrato.nrAnoContrato}
              onChange={handleChange(contrato)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "35px" }}>
                    <span style={{ fontSize: "12px" }}>
                      {contrato.nrAnoContrato}
                    </span>
                  </div>
                  <div style={{ width: "130px" }}>
                    <span style={{ fontSize: "12px" }}>
                      {contrato.uasgNome}
                    </span>
                  </div>

                  <Button
                    onClick={() => downloadPDF(contrato)}
                    variant="outlined"
                    color="primary"
                    className="m-2"
                    disabled
                  >
                    PDF
                  </Button>
                  <Button
                    disabled
                    onClick={() => {
                      setDialogState({
                        ...dialogState,
                        open: true,
                        anchorEl: null,
                        openDialogOption: false,
                      });
                    }}
                  >
                    Ver
                  </Button>
                  <Popover
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    anchorEl={dialogState.anchorEl}
                    id="mouse-over-popover2"
                    open={dialogState.openAnchorEl}
                  >
                    <MenuList>
                      <MenuItem
                        onClick={() => {
                          setDialogState({
                            ...dialogState,
                            open: true,
                            anchorEl: null,
                            openDialogOption: false,
                          });
                        }}
                      >
                        Agenda de recebíveis
                      </MenuItem>
                    </MenuList>
                  </Popover>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {loadingDetails && (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress size={20} color="primary" />
                  </div>
                )}
                {!loadingDetails && !contratoDetails && (
                  <span style={{ margin: "0 auto" }}>
                    Não há nada para ser exibido
                  </span>
                )}
                {!loadingDetails &&
                  contratoDetails &&
                  contratoDetails.length &&
                  Array.from(contratoDetails).map((contrato) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        margin: "0 0 30px 0px",
                      }}
                      key={contrato.nrAnoContrato + contrato.uasg.uasg}
                    >
                      {moment(contrato.dataFinalAtual).diff(moment()) < 0 ? ( // Data final MAIOR que hoje
                        <span className="badge badge-danger">
                          Venceu em{" "}
                          {moment(contrato.dataFinalAtual).format("DD/MM/YYYY")}
                        </span>
                      ) : moment(contrato.dataFinalAtual).diff(
                          moment(),
                          "days"
                        ) > 31 ? (
                        <span className="badge badge-warning">
                          Válido por{" "}
                          {moment(contrato.dataFinalAtual).diff(
                            moment(),
                            "months"
                          )}{" "}
                          meses
                        </span>
                      ) : (
                        <span className="badge badge-warning">
                          Válido por{" "}
                          {moment(contrato.dataFinalAtual).diff(
                            moment(),
                            "days"
                          )}{" "}
                          dias
                        </span>
                      )}
                      <span
                        style={{
                          display: "flex",
                          flexBasis: "100%",
                          flexDirection: "column",
                          margin: "15px 0",
                        }}
                      >
                        <strong>Objeto contratado:</strong>
                        {formatBRLCurrency(contrato.objeto)}
                      </span>

                      <span
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <strong>Empenhado SIAFI</strong>
                        {formatBRLCurrency(contrato.valorEmpenhadoSIAFI)}
                      </span>
                      <span
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <strong>Liquidado SIAFI</strong>
                        {formatBRLCurrency(contrato.valorLiquidadoSIAFI)}
                      </span>
                      <span
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <strong>Pago SIAFI</strong>
                        {formatBRLCurrency(contrato.valorPagoSIAFI)}
                      </span>
                      <span
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <strong>valorTotalAtual</strong>
                        {formatBRLCurrency(contrato.valorTotalAtual)}
                      </span>
                      <span
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <strong>Data inicial</strong>
                        {moment(new Date(contrato.dataInicialAtual)).format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    </div>
                  ))}
              </AccordionDetails>
            </Accordion>
            {dialogState.open && (
              <Dialog
                maxWidth="xl"
                open={dialogState.open}
                onClose={() => setDialogState({ ...dialogState, open: false })}
              >
                <DialogTitle id="alert-dialog-title">
                  {`Agenda Recebíveis - Contrato ${cotacao.idCotacao}`}
                </DialogTitle>
                <DialogContent>
                  <AgendaRecebiveis data={cotacao} />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() =>
                      setDialogState({ ...dialogState, open: false })
                    }
                    color="primary"
                  >
                    Fechar
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
