import { useContext, useEffect } from "react";

import { Box, Button, Grid, Paper, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";

import formatCpf from "utils/formatCPF";

import moment from "moment";

import { DialogContext } from "../../contexts/dialog";
import useAccountBalance from "../../hooks/useAccountBalance";
import useBanks from "../../hooks/useBanks";
import useCompany from "../../hooks/useCompany";
import formatCNPJ from "../../utils/formatCNPJ";
import formatReal, { removeRealFormat } from "../../utils/formatReal";
import TitleSection from "../TitleSection";
import Balance from "./Balance";

const pourposeOptions = [
  "PagamentoDeImpostosTributosETaxas",
  "PagamentoAConcessionariasDeServicoPublico",
  "PagamentosDeDividendos",
  "PagamentoDeSalarios",
  "PagamentoDeFornecedores",
  "PagamentoDeHonorarios",
  "PagamentoDeAlugueisETaxasDeCondominio",
  "PagamentoDeDuplicatasETitulos",
  "PagamentoDeMensalidadeEscolar",
  "CreditoEmConta",
  "PagamentoACorretoras",
  "PagamentoDeBoletoBancarioEmCartorio",
  "PagamentoDeTarifasPelaPrestacaoDeServicosDe",
  "RepasseDeValoresReferentesATitulosLiquidados",
  "LiquidacaoFinanceiraDeOperadoraDeCartao",
  "OperacoesSeguroHabitacionalSFH",
  "OperacoesDoFDSCaixa",
  "TaxaDeAdministracao",
  "PagamentoDeAcordoExecucaoJudicial",
  "LiquidacaoDeEmprestimosConsignados",
  "PagamentoDeBolsaAuxilio",
  "RemuneracaoACooperado",
  "PagamentoDePrebendaRemuneracaoAPadresESace",
  "PagamentoDeJurosSobreCapitalProprio",
  "PagamentoDeRendimentosOuAmortizacaoSCotasE",
  "TaxaDeServico",
  "PagamentoDeChequeParaNaoCorrentista",
  "PagamentoDeJurosEOuAmortizacaoDeTitulosDep",
  "EstornoOuRestituicaoDiversos",
  "PagamentoDeValeTransporte",
  "SimplesNacional",
  "RepasseDeValoresParaOFUNDEB",
  "RepasseDeValoresDeConvenioCentralizado",
  "PatrocinioComIncentivoFiscal",
  "DoacaoComIncentivoFiscal",
  "TransferenciaDeContaCorrenteDeInstituicaoNao",
  "PagamentoDeRescisaoDeContratoDeTrabalho",
  "ReembolsoDeDespesasComEstruturacaoDeOperacoe",
  "CompraDeMoedaEstrangeiraPeloFSBFundoSober",
  "DepositoJudicial",
  "PensaoAlimenticia",
  "CessaoDeCreditosLiquidPrincipalPorAquisCr",
  "CessaoDeCreditosLiquidacaoPrincipalPorAqui",
  "CessaoDeCreditosRepasseContratualDeFluxo107",
  "CessaoDeCreditosRepasseContratualDeFluxo108",
  "CessaoDeCreditosAjustesDiversos",
  "TransferenciaEntreContasDeMesmaTitularidade",
  "CreditoParaInvestidorEmClienteDaIFCreditada",
  "DebitoDeInvestidorEmClienteDaIFDebitada",
  "PagamentoDeOperacoesDeCreditoPorCliente",
  "ResgateDeAplicacaoFinanceiraDeClienteParaCo",
  "AplicacaoFinanceiraEmNomeDoClienteRemetente",
  "CessaoDeCreditosLiquidPrincPorRecompraDe",
  "CessaoDeCreditosLiquidacaoPrincipalPorReco",
  "FgcoopRecolhimentoFundoGarantidorDoCooperat",
  "FgcoopDevolucaoDeRecolhimentoAMaior",
  "FGTSSaqueEmergencial",
  "CreditoAoConsumidorDecorrenteDeProgramaDeIn",
  "AuxilioEmergencialLei13982",
  "BeneficioEmergencialDePreservacaoDoEmpregoE",
  "TributosMunicipaisISSLCP157",
  "TributosMunicipaisISSTERCEIROSLCP157",
  "TransferenciaInternacionalEmReais",
  "AjustePosicaoMercadoFuturo",
  "RepasseDeValoresDoBNDES",
  "LiquidacaoDeCompromissosJuntoAoBNDES",
  "OperacoesDeCompraEVendaDeAcoesBolsasDeV",
  "ContratosReferenciadosEmAcoesOuIndicesDeAco",
  "OperacaoDeCambioNaoInterbancaria",
  "OperacoesNoMercadoDeRendaFixaEDeRendaVari",
  "OperacaoDeCambioMercadoInterbancarioInsti",
  "PagamentoDeOperacoesComIdentificacaoDeDestin",
  "RestituicaoDeImpostoDeRenda",
  "OrdemBancariaDoTesouroOBT",
  "PagamentoDeMultasAoBACENPorAtrasosDeImport",
  "RestituicaoDeTributosRFB",
  "RestituicaoDePremiosDeSeguros",
  "PagamentoDeIndenizacaoDeSinistroDeSeguro",
  "PagamentoDePremioDeCoSeguro",
  "PagamentoDeIndenizacaoDeSinistroDeCoSeguro",
  "PagamentoDePremioDeResseguro",
  "PagamentoDeIndenizacaoDeSinistroDeResseguro",
  "RestituicaoDeIndenizacaoDeSinistroDeRessegur",
  "PagamentoDeDespesasComSinistros",
  "PagamentoDeInspecoesVistoriasPrevias",
  "PagamentoDeResgateDeTituloDaCapitalizacao",
  "PagamentoDeSorteioDeTituloDeCapitalizacao",
  "PagamentoDeDevolucaoDeMensalidadeDeTituloDe",
  "RestituicaoDeContribuicaoDePlanoPrevidenciari",
  "PagamentoDeBeneficioPrevidenciarioDePeculio",
  "PagamentoDeBeneficioPrevidenciarioDePensao",
  "PagamentoDeBeneficioPrevidenciarioDeAposentad",
  "PagamentoDeResgatePrevidenciario",
  "PagamentoDeComissaoDeCorretagem",
  "PagamentoDeTransferenciasPortabilidadeDeReser",
  "Outros",
];

function ModalTransfer({ bankingAccount, createTransfer }) {
  const { toggleDialog } = useContext(DialogContext);
  const { company, isLoading: isLoadingCompany } = useCompany(
    true,
    bankingAccount?.account?.businessId
  );
  const { balance, isLoading } = useAccountBalance(
    true,
    bankingAccount?.account?.accountId
  );
  const { banks } = useBanks();

  const formik = useFormik({
    initialValues: {
      transfer_date: new Date(),
      value: 0,
      tarnsfer_type: "TED", // TED OR TEV
      account_type: "CC", // CC OR CP
      purpose: "CreditoEmConta",
      cpfCnpj: "",
      agency: "",
      bank: "",
      account_number: "",
      name_grantee: "",
      notification_email: "",
      bank_name: "",
      sender_account: "",
      sender_cnpj: "",
      sender_agency: "",
      account_digit: "",
    },
    validationSchema: Yup.object({
      transfer_date: Yup.date()
        .min(
          moment().subtract(1, "day").toDate(),
          "A data não pode ser passada"
        )
        .required("Informe uma data")
        .nullable()
        .transform((curr, orig) => (orig === "" ? null : curr)),
      value: Yup.string()
        .test(
          "lessThan",
          "Você não possui saldo suficiente para esta transação",
          (val) => removeRealFormat(val) <= balance?.balance
        )
        .test(
          "moreOrThanFive",
          "O valor precisa ser maior que R$ 5,00",
          (val) => removeRealFormat(val) >= 5
        )
        .required("Informe o valor da transferência"),
      cpfCnpj: Yup.string().required("Informe o CNPJ do(a) favorecido(a)"),
      agency: Yup.string().required("Informe a agência do(a) favorecido(a)"),
      bank: Yup.mixed().required("Informe o banco do(a) favorecido(a)"),
      purpose: Yup.mixed().required("Informe a finalidade da transferência"),
      account_type: Yup.mixed().required("Informe o tipo de conta"),
      tarnsfer_type: Yup.mixed().required("Informe o tipo de transação"),
      account_number: Yup.string().required(
        "Informe o número da conta do(a) favorecido(a)"
      ),
      account_digit: Yup.string().required(
        "Informe o digito da conta do(a) favorecido(a)"
      ),
      name_grantee: Yup.string().required("Informe o nome do(a) favorecido(a)"),
      notification_email: Yup.string()
        .required("Informe o e-mail para ser notificado em caso de sucesso")
        .email("Informe um e-mail válido"),
    }),
    onSubmit: async (values) => {
      const response = await createTransfer({
        ...values,
        cpfCnpj: values.cpfCnpj
          .replaceAll(".", "")
          .replaceAll("-", "")
          .replaceAll("/", ""),
        value: removeRealFormat(values.value),
        account_id: bankingAccount?.account?.accountId,
        bank: values?.bank?.value,
        bank_name: values?.bank?.label,
      });

      if (response) {
        toggleDialog(false);
      }
    },
  });

  useEffect(() => {
    if (company && bankingAccount) {
      const { account } = bankingAccount;
      formik.setValues({
        ...formik.values,
        sender_account: account?.accountNumber,
        sender_cnpj: formatCNPJ(company.countryIdentity),
        sender_agency: account?.accountAgency,
      });
    }
  }, [bankingAccount, company]);

  return (
    <Box>
      <Balance bankingAccount={bankingAccount} mb={2} />
      <form onSubmit={formik.handleSubmit}>
        <Box mb={2}>
          <Paper elevetaion={4} variant="outlined">
            <TitleSection title="Remetente" />

            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    name="reason"
                    disabled
                    value={formik.values.sender_cnpj}
                    label="CNPJ"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    variant="outlined"
                    name="sender_agency"
                    disabled
                    value={formik.values.sender_agency}
                    label="Agência"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    variant="outlined"
                    name="sender_account"
                    disabled
                    value={formik.values.sender_account}
                    label="Número da conta"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>

        <Paper elevation={4} variant="outlined">
          <TitleSection title="Destinatário" />

          <Box p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <DatePicker
                    cancelLabel="Cancelar"
                    fullWidth
                    locale={ptBR}
                    helperText={
                      formik.touched.transfer_date
                        ? formik.errors.transfer_date
                        : ""
                    }
                    error={
                      Boolean(formik.errors.transfer_date) &&
                      formik.touched.transfer_date
                    }
                    minDate={new Date()}
                    name="transfer_datez"
                    label="Data da transferência"
                    onBlur={formik.handleBlur}
                    value={formik.values.transfer_date}
                    format="dd/MM/yyyy"
                    onChange={(value) =>
                      formik.setFieldValue("transfer_date", value)
                    }
                    TextFieldComponent={(props) => (
                      <TextField {...props} variant="outlined" />
                    )}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  variant="outlined"
                  name="value"
                  value={formik.values.value || "R$ 0,00"}
                  label="Valor"
                  helperText={formik.touched.value ? formik.errors.value : ""}
                  error={Boolean(formik.errors.value) && formik.touched.value}
                  onChange={(e) =>
                    formik.setFieldValue("value", formatReal(e.target.value))
                  }
                  onBlur={formik.handleBlur}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Autocomplete
                  options={["TED", "TEV"]}
                  getOptionLabel={(value) => value}
                  filterSelectedOptions
                  value={formik.values.tarnsfer_type}
                  noOptionsText="Nenhum tipo encontrado"
                  label="Tipo de transação"
                  placeholder="Informe o tipo da transação"
                  onChange={(e, value) => {
                    formik.setFieldValue("tarnsfer_type", value);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={
                        formik.touched.tarnsfer_type
                          ? formik.errors.tarnsfer_type
                          : ""
                      }
                      error={
                        Boolean(formik.errors.tarnsfer_type) &&
                        formik.touched.tarnsfer_type
                      }
                      label="Tipo de transação"
                      name="tarnsfer_type"
                      fullWidth
                      variant="outlined"
                      placeholder="Informe o tipo da transação"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Autocomplete
                  options={["CC", "CP"]}
                  getOptionLabel={(value) => value}
                  filterSelectedOptions
                  value={formik.values.account_type}
                  noOptionsText="Nenhum tipo encontrado"
                  onChange={(e, value) => {
                    formik.setFieldValue("account_type", value);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={
                        formik.touched.account_type
                          ? formik.errors.account_type
                          : ""
                      }
                      error={
                        Boolean(formik.errors.account_type) &&
                        formik.touched.account_type
                      }
                      label="Tipo de conta"
                      name="account_type"
                      fullWidth
                      variant="outlined"
                      placeholder="Informe o tipo da conta"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Autocomplete
                  options={pourposeOptions}
                  getOptionLabel={(value) => value}
                  filterSelectedOptions
                  value={formik.values.purpose}
                  noOptionsText="Nada encontrado"
                  onChange={(e, value) => {
                    formik.setFieldValue("purpose", value);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={
                        formik.touched.purpose ? formik.errors.purpose : ""
                      }
                      error={
                        Boolean(formik.errors.purpose) && formik.touched.purpose
                      }
                      label="Finalidade da transferência"
                      name="purpose"
                      fullWidth
                      variant="outlined"
                      placeholder="Finalidade da transferência"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  variant="outlined"
                  name="cpfCnpj"
                  value={formik.values.cpfCnpj}
                  label="CPF / CNPJ"
                  helperText={
                    formik.touched.cpfCnpj ? formik.errors.cpfCnpj : ""
                  }
                  error={
                    Boolean(formik.errors.cpfCnpj) && formik.touched.cpfCnpj
                  }
                  onChange={(e) => {
                    const value = e.target.value
                      .replaceAll(".", "")
                      .replaceAll("-", "")
                      .replaceAll("/", "");

                    if (value.length > 12) {
                      return formik.setFieldValue("cpfCnpj", formatCNPJ(value));
                    }

                    return formik.setFieldValue("cpfCnpj", formatCpf(value));
                  }}
                  onBlur={formik.handleBlur}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Autocomplete
                  options={banks}
                  getOptionLabel={(value) => value.label}
                  filterSelectedOptions
                  value={formik.values.bank}
                  noOptionsText="Nada encontrado"
                  name="bank"
                  variant="outlined"
                  label="Banco"
                  placeholder="Banco"
                  onChange={(e, value) => {
                    formik.setFieldValue("bank", value);
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={formik.touched.bank ? formik.errors.bank : ""}
                      error={Boolean(formik.errors.bank) && formik.touched.bank}
                      label="Banco"
                      placeholder="Banco"
                      name="bank"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  variant="outlined"
                  name="agency"
                  value={formik.values.agency}
                  label="Agência"
                  helperText={formik.touched.agency ? formik.errors.agency : ""}
                  error={Boolean(formik.errors.agency) && formik.touched.agency}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextField
                  variant="outlined"
                  name="account_number"
                  value={formik.values.account_number}
                  label="Número da conta"
                  helperText={
                    formik.touched.account_number
                      ? formik.errors.account_number
                      : ""
                  }
                  error={
                    Boolean(formik.errors.account_number) &&
                    formik.touched.account_number
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  inputProps={{ maxLength: "1" }}
                  variant="outlined"
                  name="account_digit"
                  helperText={
                    formik.touched.account_digit
                      ? formik.errors.account_digit
                      : ""
                  }
                  error={
                    Boolean(formik.errors.account_digit) &&
                    formik.touched.account_digit
                  }
                  value={formik.values.account_digit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Digito"
                  fullWidth
                />
              </Grid>
              <Grid item xs={10} sm={10} md={4}>
                <TextField
                  variant="outlined"
                  name="name_grantee"
                  value={formik.values.name_grantee}
                  label="Nome do(a) favorecido(a)"
                  helperText={
                    formik.touched.name_grantee
                      ? formik.errors.name_grantee
                      : ""
                  }
                  error={
                    Boolean(formik.errors.name_grantee) &&
                    formik.touched.name_grantee
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  variant="outlined"
                  name="notification_email"
                  value={formik.values.notification_email}
                  label="E-mail para notificação"
                  helperText={
                    formik.touched.notification_email
                      ? formik.errors.notification_email
                      : ""
                  }
                  error={
                    Boolean(formik.errors.notification_email) &&
                    formik.touched.notification_email
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={isLoading}
                >
                  Transferir
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </form>
    </Box>
  );
}

ModalTransfer.propTypes = {
  bankingAccount: PropTypes.object.isRequired,
};

export default ModalTransfer;
