import React from 'react';

import Cedente from 'pages/CadastroTitulo/tabs/Cedente';

export default function Documentos({
  seller,
  sellerDocuments,
  sellerPending,
  setSeller,
  setSellerDocuments,
  setSellerPending,
}) {
  return (
    <Cedente
      business={seller}
      businessDocuments={sellerDocuments}
      businessPending={sellerPending}
      setBusiness={(value) => setSeller(value)}
      setBusinessDocuments={(value) => setSellerDocuments(value)}
      setBusinessPending={(value) => setSellerPending(value)}
      type="modal-vitrine"
    />
  );
}
