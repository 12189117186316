/* eslint eqeqeq: 0 */
import React, { useState } from 'react';

import {
  makeStyles,
  Paper,
  Table,
  TableContainer,
  TablePagination,
  Dialog,
} from '@material-ui/core';

import Documentos from 'pages/AgendaRecebiveis/tabs/Documentos';
import Nova from 'pages/AgendaRecebiveis/tabs/Nova';
import NovaDetail from 'pages/AgendaRecebiveis/tabs/NovaDetail';

import { AgendaTags } from 'components/AgendaTags';
// import { ConfirmButton } from 'components/Button';
import { CadastroTitulo } from 'components/CadastroTituloRecebivel';
import { ErrorCard, InfoCard, WarningCard } from 'components/CatchCards';
import Dossie from 'components/Dossie';
import EnhancedTableHead from 'components/EnhancedTableHead';
import { Modal } from 'components/material/Modal';
import {
  ReactCatch,
  ReactCatchIf,
  ReactIf,
  ReactThen,
} from 'components/ReactIf';

import { useAuth } from 'contexts/auth';

import { useModal } from 'hooks/useModal';
import { useRecebiveis } from 'hooks/useRecebiveis';

import { headCells, headCellsAdmin, headCellsFinanciador } from './items';
import { AgendaRow } from './row';

const useStyles = makeStyles({
  paper: {
    background: 'white',
    width: '105%',
    boxShadow: 'none',
    border: '1px solid #99999966',
    maxWidth: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

export function Recebiveis({
  typeId,
  edit,
  businessId,
  cadastroTitulo,
  saveAgendas,
  getSavedAgendas,
  agendaValues,
  style,
  title,
  addVenc,
  aprovarECriarTitulo,
  listaIdRecebiveis,
  // criarTitulo,
  esconderValorComprometido,
  esconderSacado,
  esconderDataVencimento,
  esconderRisco,
  esconderStatus,
  esconderPorcentagem,
  esconderValorUtlizadoTitulo,
  formValues,
  geradorParcelas,
}) {
  const classes = useStyles();

  const { isAdmin, isCedente, isFinanciador } = useAuth();

  const [agenda, setAgenda] = useState(null);
  const [agendaId, setAgendaId] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [openDocuments, setOpenDocuments] = useState(false);
  const [openDossie, setOpenDossie] = useState(false);
  const [openTags, setOpenTags] = useState(false);

  function handleUpdateCommitedValue(agenda_id, commited_value, row, venc) {
    let aux = getSavedAgendas().filter(
      (row) => row.agenda_id && row.agenda_id != agenda_id
    );

    const toInsert = {
      ...row,
      agenda_id,
      commited_value: `${commited_value}`.trim(),
    };

    aux.push(toInsert);
    // Validando linhas de commited value para atualizar
    aux = validateRowToUpdateCommitedValue(aux);

    // salvando agenda
    saveAgendas(aux);

    // adiciona vencimento automaticamente à lista de vencimentos
    if (venc) {
      addVenc(venc);
    }
  }

  function validateRowToUpdateCommitedValue(rows) {
    if (rows.length == 0) return;
    const validated = rows.filter((row) => row.agenda_id != '');

    return validated;
  }

  const handleClose = () => {
    setOpenEdit(false);
    setOpenDetail(false);
    setOpenCadastroTitulo(false);
    setOpenTags(false);
  };

  const setOpenEditModal = async (agenda_id) => {
    openModal('loading');
    const agenda = await getAgenda(agenda_id);
    setAgenda(agenda);
    setOpenEdit(true);
    closeModal();
  };

  const setOpenDetailModal = async (agenda_id) => {
    openModal('loading');
    const agenda = await getAgenda(agenda_id);
    setAgenda(agenda);
    setOpenDetail(true);
    closeModal();
  };

  const setOpenDocumentsModal = async (agendaId) => {
    openModal('loading');

    setAgendaId(agendaId);

    setOpenDocuments(true);
    closeModal();
  };

  const setOpenDossieModal = async (agendaId) => {
    openModal('loading');

    setAgendaId(agendaId);

    setOpenDossie(true);
    closeModal();
  };

  async function setOpenTagsModal(agendaId) {
    openModal('loading');

    setAgendaId(agendaId);

    setOpenTags(true);
    closeModal();
  }

  function loadHeadCells() {
    if (isCedente()) {
      let newHeadCells = headCells;
      if (esconderValorComprometido) {
        newHeadCells = newHeadCells.filter(
          (cell) => cell.id != 'commited_value'
        );
      }
      if (esconderSacado) {
        newHeadCells = newHeadCells.filter((cell) => cell.id != 'drawee.name');
      }
      if (esconderDataVencimento) {
        newHeadCells = newHeadCells.filter(
          (cell) => cell.id != 'first_maturity_date'
        );
      }
      if (esconderRisco) {
        newHeadCells = newHeadCells.filter((cell) => cell.id != 'risk_name');
      }
      if (esconderPorcentagem) {
        newHeadCells = newHeadCells.filter(
          (cell) => cell.id != 'available_percentage'
        );
      }
      if (esconderStatus) {
        newHeadCells = newHeadCells.filter((cell) => cell.id != 'status_name');
      }
      if (esconderValorUtlizadoTitulo) {
        newHeadCells = newHeadCells.filter((cell) => cell.id != 'agendaValue');
      }

      return newHeadCells;
    }
    if (isAdmin()) {
      let newHeadCells = headCellsAdmin;
      if (esconderValorComprometido) {
        newHeadCells = newHeadCells.filter(
          (cell) => cell.id != 'commited_value'
        );
      }
      if (esconderSacado) {
        newHeadCells = newHeadCells.filter((cell) => cell.id != 'drawee.name');
      }
      if (esconderDataVencimento) {
        newHeadCells = newHeadCells.filter(
          (cell) => cell.id != 'first_maturity_date'
        );
      }
      if (esconderStatus) {
        newHeadCells = newHeadCells.filter((cell) => cell.id != 'status_name');
      }
      if (esconderValorUtlizadoTitulo) {
        newHeadCells = newHeadCells.filter((cell) => cell.id != 'agendaValue');
      }

      return newHeadCells;
    }

    if (isFinanciador()) {
      return headCellsFinanciador;
    }

    let newHeadCells = headCells;
    if (esconderValorComprometido) {
      newHeadCells = newHeadCells.filter((cell) => cell.id != 'commited_value');
    }
    if (esconderSacado) {
      newHeadCells = newHeadCells.filter((cell) => cell.id != 'drawee.name');
    }
    if (esconderDataVencimento) {
      newHeadCells = newHeadCells.filter(
        (cell) => cell.id != 'first_maturity_date'
      );
    }

    return newHeadCells;
  }

  const {
    agendas,
    agendaDocs,
    getAgenda,
    stableSort,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    getComparator,
    handleChangePage,
    handleChangeRowsPerPage,
    labelDisplayedRows,
    refresh: refreshAgendas,
    isLoading: isLoadingRecebiveis,
    openCadastroTitulo,
    setOpenCadastroTitulo,
    setAgendaDocs,
    noAgendaMessage,
  } = useRecebiveis(typeId, businessId, listaIdRecebiveis);

  const { openModal, closeModal } = useModal();

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '1em',
        }}
      >
        {/* {isCedente() && criarTitulo && (
          <ConfirmButton
            onClick={() => {
              openModal('loading');
              setOpenCadastroTitulo(true);
              closeModal();
            }}
            style={{ maxHeight: '50px' }}
          >
            Criar Título
          </ConfirmButton>
        )} */}
      </div>
      {agendas && (
        <Paper className={classes.paper} style={style}>
          <ReactIf condition={title}>
            <ReactThen>
              <div className="containerTitle mx-4 my-3">
                <h4 style={{ fontFamily: 'Poppins, sans-serif' }}>{title}</h4>
              </div>
            </ReactThen>
          </ReactIf>
          {agendas.length > 0 && !isLoadingRecebiveis && (
            <>
              <TableContainer>
                <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    headCells={loadHeadCells()}
                  />
                  {stableSort(agendas, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    // .filter(
                    //   (agenda) =>
                    //     !(
                    //       invoiceView &&
                    //       !agenda.agendaInvoices.some(
                    //         (agendaInvoice) =>
                    //           agendaInvoice.invoiceId === invoiceView
                    //       )
                    //     )
                    // )
                    .map((row, index) => (
                      <>
                        <AgendaRow
                          agenda={row}
                          aprovarECriarTitulo={aprovarECriarTitulo}
                          cadastroTitulo={cadastroTitulo}
                          getAgendas={async () => {
                            openModal('loading');
                            await refreshAgendas(typeId);
                            closeModal();
                          }}
                          esconderValorComprometido={esconderValorComprometido}
                          esconderSacado={esconderSacado}
                          esconderDataVencimento={esconderDataVencimento}
                          esconderRisco={esconderRisco}
                          esconderPorcentagem={esconderPorcentagem}
                          esconderStatus={esconderStatus}
                          esconderValorUtlizadoTitulo={
                            esconderValorUtlizadoTitulo
                          }
                          edit={edit}
                          geradorParcelas={geradorParcelas}
                          handleUpdateCommitedValue={handleUpdateCommitedValue}
                          setOpenEditModal={setOpenEditModal}
                          setOpenDetailModal={setOpenDetailModal}
                          setOpenDocumentsModal={setOpenDocumentsModal}
                          setOpenDossieModal={setOpenDossieModal}
                          setOpenTagsModal={setOpenTagsModal}
                          formValues={formValues}
                          key={index}
                          agendaValue={
                            agendaValues ? agendaValues[index] : null
                          }
                        />
                      </>
                    ))}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={agendas.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Linhas por página"
                labelDisplayedRows={labelDisplayedRows}
              />
            </>
          )}

          <ReactIf condition={isLoadingRecebiveis}>
            <ReactThen>
              <WarningCard>Carregando recebíveis...</WarningCard>
            </ReactThen>

            <ReactCatchIf condition={agendas.length === 0}>
              <ReactIf condition={noAgendaMessage}>
                <ReactThen>
                  <InfoCard>{noAgendaMessage}</InfoCard>
                </ReactThen>

                <ReactCatch>
                  <ErrorCard />
                </ReactCatch>
              </ReactIf>
            </ReactCatchIf>
          </ReactIf>
        </Paper>
      )}

      <Dialog
        onClose={() => setOpenEdit(false)}
        aria-labelledby="simple-dialog-title"
        open={openEdit}
        scroll="body"
        maxWidth="xl"
      >
        <Modal id="customized-dialog-title" onClose={handleClose} />

        <div
          style={{
            display: 'flex',
            marginLeft: '60px',
            maxWidth: '80vw',
          }}
        >
          {agenda && (
            <Nova
              title="Alterar Agenda"
              blank
              edit={edit}
              agenda={agenda}
              handleClose={handleClose}
              getAgendas={async () => {
                openModal('loading');
                await refreshAgendas(typeId);
                closeModal();
              }}
            />
          )}
        </div>
      </Dialog>

      <Dialog
        onClose={() => setOpenDetail(false)}
        aria-labelledby="simple-dialog-title"
        open={openDetail}
        scroll="body"
        maxWidth="xl"
      >
        <Modal id="customized-dialog-title" onClose={handleClose} />

        <div
          style={{
            display: 'flex',
            marginLeft: '60px',
            maxWidth: '80vw',
          }}
        >
          {agenda && (
            <NovaDetail
              title="Detalhes"
              edit
              agenda={agenda}
              getAgendas={async () => {
                openModal('loading');
                await refreshAgendas(typeId);
                closeModal();
              }}
            />
          )}
        </div>
      </Dialog>

      <Dialog
        onClose={() => setOpenDocuments(false)}
        aria-labelledby="simple-dialog-title"
        open={openDocuments}
        scroll="body"
        maxWidth="xl"
      >
        <Modal
          id="customized-dialog-title"
          onClose={() => {
            setOpenDocuments(false);
          }}
        />

        <div
          style={{
            display: 'flex',
            marginLeft: '60px',
            marginBottom: '50px',
            width: '995px',
          }}
        >
          {agendaId && (
            <>
              <Documentos
                agendaId={agendaId}
                agenda={agenda}
                documents={agendaDocs}
                refreshAgendaData={setAgendaDocs}
                edit={edit}
              />
            </>
          )}
        </div>
      </Dialog>

      <Dialog
        onClose={() => setOpenDossie(false)}
        aria-labelledby="simple-dialog-title"
        open={openDossie}
        scroll="body"
        maxWidth="xl"
      >
        <Modal
          id="customized-dialog-title"
          onClose={() => {
            setOpenDossie(false);
          }}
        />

        <div
          style={{
            minWidth: '60rem',
            width: '100%',
            display: 'flex',
            marginBottom: '50px',
            padding: '30px',
          }}
        >
          {agendaId && (
            <>
              <Dossie
                title="Dossiê"
                agendaId={agendaId}
                edit={edit}
                agenda={agenda}
                setAgenda={(value) => setAgenda(value)}
                agendaDocs={agendaDocs}
                setAgendaDocuments={(value) => setAgendaDocs(value)}
              />
            </>
          )}
        </div>
      </Dialog>

      <Dialog
        onClose={() => setOpenCadastroTitulo(false)}
        disableBackdropClick
        aria-labelledby="simple-dialog-title"
        open={openCadastroTitulo}
        scroll="body"
        maxWidth="xl"
      >
        <Modal id="customized-dialog-title" onClose={handleClose} />

        <div
          style={{
            display: 'flex',
            width: '80vw',
          }}
        >
          <CadastroTitulo
            handleClose={handleClose}
            getAgendas={async () => {
              openModal('loading');
              await refreshAgendas(typeId);
              closeModal();
            }}
          />
        </div>
      </Dialog>

      <Dialog
        onClose={() => setOpenTagsModal(false)}
        disableBackdropClick
        aria-labelledby="simple-dialog-title"
        open={openTags}
        scroll="body"
        maxWidth="xl"
      >
        <Modal id="customized-dialog-title" onClose={handleClose} />

        <div
          style={{
            display: 'flex',
            width: '80vw',
          }}
        >
          <AgendaTags handleClose={handleClose} agendaId={agendaId} />
        </div>
      </Dialog>

      {/* openTags */}
    </>
  );
}
