import React, { useState } from "react";
import { Link } from "react-router-dom";

import {
  Card,
  TextField,
  Tooltip,
  Button,
  IconButton,
} from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { ReactIf, ReactThen } from "components/ReactIf";

import { loadBankAccounts } from "services/business";
import { getBusinessResponsibles } from "services/business-responsibles";

import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";

import BankAccountFocus from "./BankAccountFocus";
import BankAccount from "./classes/BankAccount";
import PayrollAccount from "./classes/PayrollAccount";

import "../capa.scss";
import { Autocomplete } from "@material-ui/lab";
import AxiosInterceptor from "utils/axios-interceptor";

export default (props) => {
  const { business, businessBankAccounts, setBusinessBankAccounts } = props;

  const { isAdmin, canSelfUpdate } = useAuth();
  const { toast } = useToast();

  const [bankAccountDialog, setBankAccountDialog] = useState({ open: false });
  const [representantes, setRepresentantes] = useState(undefined);

  const stringAttributeIsInvalid = (attr) =>
    attr === undefined || attr === "" || attr === null;

  const handleLoadBankAccounts = async () => {
    const accounts = await toast.promise(
      loadBankAccounts(business.businessId),
      {
        loading: "Carregando Dados Bancários",
        success: "Dados Bancários carregados com sucesso!",
        error: "Falha ao carregar Dados Bancários",
      }
    );

    const bankAccounts = accounts.map((bankAccount) => ({
      ...bankAccount,
      ...bankAccount.payrollAccount,
    }));

    setBankAccountDialog({ open: false });
    setBusinessBankAccounts(bankAccounts);
  };

  const changeBusinessBankAccounts = (newState, index) => {
    const bankAccounts = businessBankAccounts;
    bankAccounts[index] = newState;

    setBusinessBankAccounts(bankAccounts);
  };

  const handlerChange = (e, state, setter, index) => {
    const newState = { ...state, [e.target.name]: e.target.value };

    changeBusinessBankAccounts(newState, index);

    setter(newState);
  };

  const addBankAccount = () => {
    const bankAccounts = businessBankAccounts;
    if (
      bankAccounts.some(
        (bankAccount) => bankAccount.bankAccountId === undefined
      )
    )
      return;
    const newBankAccount = new BankAccount();
    newBankAccount.name = business.name;
    newBankAccount.corporateName = business.corporateName;
    newBankAccount.countryIdentity = business.countryIdentity;
    newBankAccount.email = business.email;
    newBankAccount.phone = business.officePhone;
    newBankAccount.businessId = business.businessId;
    newBankAccount.businessTypeId = business.typeId;
    newBankAccount.businessTypeName = business.typeName;
    newBankAccount.personTypeId = business.personTypeId;
    newBankAccount.personTypeName = business.personTypeName;
    bankAccounts.push(newBankAccount);

    setBusinessBankAccounts(bankAccounts);
    setBankAccountDialog({ open: false });
  };

  const addPayrollAccount = async () => {
    const data = await getBusinessResponsibles(business.businessId);

    setRepresentantes(data);

    const bankAccounts = businessBankAccounts;
    if (
      bankAccounts.some(
        (bankAccount) => bankAccount.bankAccountId === undefined
      )
    )
      return;
    const newBankAccount = new PayrollAccount();
    newBankAccount.name = business.name;
    newBankAccount.corporateName = business.corporateName;
    newBankAccount.countryIdentity = business.countryIdentity;
    newBankAccount.email = business.email;
    newBankAccount.phone = business.officePhone;
    newBankAccount.businessId = business.businessId;
    newBankAccount.businessTypeId = business.typeId;
    newBankAccount.businessTypeName = business.typeName;
    newBankAccount.personTypeId = business.personTypeId;
    newBankAccount.personTypeName = business.personTypeName;
    bankAccounts.push(newBankAccount);

    setBusinessBankAccounts(bankAccounts);
    setBankAccountDialog({ open: false });
  };

  const removeBankAccount = async (bankAccountId) => {
    if (businessBankAccounts.length === 0) return;

    const bankAccounts = businessBankAccounts;
    const index = businessBankAccounts.findIndex(
      (account) => account.bankAccountId == bankAccountId
    );
    const removedBankAccount = bankAccounts[index];
    bankAccounts.splice(index, 1);
    const axiosInstance = AxiosInterceptor();
    await axiosInstance.post(
      `${process.env.REACT_APP_NODE_API}/businessBankAccounts/delete`,
      removedBankAccount
    );
    await axiosInstance.post(
      `${process.env.REACT_APP_NODE_API}/businessPayrollAccounts/delete`,
      removedBankAccount
    );

    setBusinessBankAccounts(bankAccounts);
    handleLoadBankAccounts();
    setBankAccountDialog({ open: false });
  };

  const StylizedInput = ({
    className,
    label,
    inputName,
    disabled,
    required,
    bankAccount,
    setter,
    index,
    andError,
    filledState,
    orError,
    ...props
  }) => (
    <div className={`${className}`}>
      <TextField
        fullWidth
        id={`${label}${index}`}
        label={`${label}${required ? "*" : ""}`}
        name={inputName}
        value={bankAccount[inputName] ?? ""}
        disabled={disabled}
        error={
          (orError !== undefined ? orError : false) ||
          ((andError !== undefined ? andError : true) &&
            stringAttributeIsInvalid(bankAccount[inputName]) &&
            !filledState &&
            required)
        }
        helperText={`${
          inputName === "email" && orError ? "Email já existe" : ""
        }`}
        variant={"outlined"}
        onChange={(e) => {
          if (setter) handlerChange(e, bankAccount, setter, index);
        }}
        {...props}
      />
    </div>
  );

  const BankAccountCard = (props) => {
    const { index, bankAccountItem, setBankAccountDialog } = props;

    const [bankAccount, setBankAccount] = useState(bankAccountItem);

    return (
      <div>
        <Card className="card-box mb-4 pt-4 pb-2">
          <div className="d-flex align-items-center px-4 mb-3">
            <div className="w-100">
              <a
                href="#/"
                onClick={(e) => e.preventDefault()}
                className="font-weight-bold font-size-lg"
                title="..."
              >
                {bankAccount.name}
              </a>
              <span className="text-black-50 d-block pb-1">
                {business.name}
              </span>
              <div className="d-flex align-items-center justify-content-start pt-2">
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  className="mr-2"
                  onClick={() =>
                    setBankAccountDialog({
                      open: true,
                      bankAccount,
                      setBankAccount,
                      index,
                    })
                  }
                >
                  {isAdmin() ? "Editar" : "Visualizar"}
                </Button>

                {bankAccount.accountId !== undefined &&
                  bankAccount.accountId !== null && (
                    <Link to={`/extrato/${bankAccount.accountId}`}>
                      <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        className="mr-2"
                      >
                        Extrato
                      </Button>
                    </Link>
                  )}
                {bankAccount.statusId !== null &&
                  bankAccount.statusId !== undefined && (
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      disabled
                    >
                      {bankAccount.statusName}
                    </Button>
                  )}
              </div>
            </div>
          </div>
          <div className="font-size-sm p-3 my-3 bg-neutral-dark mx-4 rounded-sm">
            <div className="d-flex justify-content-between">
              <span className="font-weight-bold">Banco:</span>
              <span className="text-black-50">{bankAccount.bankName}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="font-weight-bold">Agência:</span>
              <span className="text-black-50">{bankAccount.bankBranch}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="font-weight-bold">Conta:</span>
              <Tooltip placement="top" title={bankAccount.accountNumber}>
                <span className="text-black-50">
                  {bankAccount.accountNumber}
                </span>
              </Tooltip>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-around align-items-start">
        <div
          className="d-flex flex-column align-items-center"
          style={{ width: "37.5%", minWidth: 360 }}
        >
          <h2
            style={{
              margin: "auto",
              height: 100,
              marginTop: 22,
              fontFamily: "Poppins",
            }}
          >
            Conta Consignada
          </h2>
          <ReactIf
            condition={
              business && !isAdmin() && canSelfUpdate(business?.statusId)
            }
          >
            <ReactThen>
              <Card
                className="card-box p-4 mb-4"
                style={{ width: "95%", maxWidth: "95%", margin: "0 auto" }}
              >
                <div className="text-center">
                  <div className="avatar-icon-wrapper rounded-circle m-0">
                    <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper text-success m-0 d-65 align-items-center">
                      <InfoOutlinedIcon
                        style={{
                          color: "#2196f3",
                          fontSize: 28,
                        }}
                      />
                    </div>
                  </div>
                  <h4
                    className="font-weight-bold"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "22px",
                      textAlign: "center",
                      color: "#663c00",
                    }}
                  >
                    Fique tranquilo(a) nós cuidamos dela para você.
                  </h4>
                </div>
              </Card>
            </ReactThen>
          </ReactIf>
          {businessBankAccounts
            .filter((account) => Number(account.accountTypeId) === 1)
            .slice(0, 1)
            .map((account, index) => (
              <BankAccountCard
                key={account.bankAccountId ?? index}
                bankAccountItem={account}
                setBankAccountDialog={setBankAccountDialog}
                isLast={index === businessBankAccounts.length - 1}
                index={index}
              />
            ))}
          {isAdmin() &&
            businessBankAccounts.filter(
              (account) => Number(account.accountTypeId) === 1
            ).length === 0 && (
              <div className="d-flex justify-content-center align-items-center">
                <Tooltip
                  arrow
                  placement="top"
                  title="Adicionar Conta"
                  style={{
                    borderRadius: "50%",
                    width: 56,
                    height: "auto",
                    backgroundColor: "#373A44",
                    padding: 16,
                    color: "#fff",
                    boxShadow: "1px 3px 5px #888",
                    cursor: "pointer",
                  }}
                >
                  <IconButton
                    color="primary"
                    size="medium"
                    variant="outlined"
                    className="text-white"
                    onClick={() => addPayrollAccount()}
                  >
                    <AddOutlinedIcon className="font-size-lg" />
                  </IconButton>
                </Tooltip>
              </div>
            )}
        </div>
        <div
          className="d-flex flex-column align-items-center"
          style={{ width: "37.5%", minWidth: 360 }}
        >
          <h2
            style={{
              margin: "auto",
              height: 100,
              marginTop: 22,
              fontFamily: "Poppins, sans-serif",
              textAlign: "center",
            }}
          >
            Contas Correntes
          </h2>
          <ReactIf
            condition={
              business && !isAdmin() && canSelfUpdate(business?.statusId)
            }
          >
            <ReactThen>
              <Card
                className="card-box p-4 mb-4"
                style={{ width: "95%", maxWidth: "95%", margin: "0 auto" }}
              >
                <div className="text-center">
                  <div className="avatar-icon-wrapper rounded-circle m-0">
                    <div className="d-inline-flex justify-content-center p-0 rounded-circle avatar-icon-wrapper text-success m-0 d-65 align-items-center">
                      <InfoOutlinedIcon
                        style={{
                          color: "#2196f3",
                          fontSize: 28,
                        }}
                      />
                    </div>
                  </div>
                  <h4
                    className="font-weight-bold"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "22px",
                      textAlign: "center",
                      color: "#663c00",
                    }}
                  >
                    Preencha com as suas contas de livre movimentação.
                  </h4>
                </div>
              </Card>
            </ReactThen>
          </ReactIf>
          {businessBankAccounts
            .filter((account) => Number(account.accountTypeId) !== 1)
            .map((account, index) => (
              <BankAccountCard
                key={account.payrollAccountId ?? index}
                bankAccountItem={account}
                setBankAccountDialog={setBankAccountDialog}
                isLast={index === businessBankAccounts.length - 1}
                index={index}
              />
            ))}
          <ReactIf condition={canSelfUpdate(business.statusId)}>
            <ReactThen>
              <div className="d-flex justify-content-center align-items-center">
                <Tooltip
                  arrow
                  placement="top"
                  title="Adicionar Conta"
                  style={{
                    borderRadius: "50%",
                    width: 56,
                    height: "auto",
                    backgroundColor: "#373A44",
                    padding: 16,
                    color: "#fff",
                    boxShadow: "1px 3px 5px #888",
                    cursor: "pointer",
                  }}
                >
                  <IconButton
                    color="primary"
                    size="medium"
                    variant="outlined"
                    className="text-white"
                    onClick={() => addBankAccount()}
                  >
                    <AddOutlinedIcon className="font-size-lg" />
                  </IconButton>
                </Tooltip>
              </div>
            </ReactThen>
          </ReactIf>
        </div>

        <BankAccountFocus
          business={business}
          bankAccountDialog={bankAccountDialog}
          setBankAccountDialog={setBankAccountDialog}
          bankAccounts={businessBankAccounts}
          StylizedInput={StylizedInput}
          removeBankAccount={removeBankAccount}
          changeBusinessBankAccounts={changeBusinessBankAccounts}
          representantesConta={representantes}
          handleLoadBankAccounts={handleLoadBankAccounts}
        />
      </div>
    </>
  );
};
