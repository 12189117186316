import React, { createContext, useContext, useEffect, useState } from 'react';

const PermissionContext = createContext();

export const PermissionProvider = ({ children }) => {
  const [sellerInvoicesIds, setSellerInvoices] = useState(null);

  useEffect(() => {
    const storagedInvoicesIds = localStorage.getItem('@App:invoicesIds');

    if (storagedInvoicesIds) {
      setSellerInvoicesIds(JSON.parse(storagedInvoicesIds));
    }
  }, []);

  function setSellerInvoicesIds(invoicesIds) {
    localStorage.setItem('@App:invoicesIds', JSON.stringify(invoicesIds));
    setSellerInvoices(invoicesIds);
  }

  return (
    <PermissionContext.Provider
      value={{
        sellerInvoicesIds,
        setSellerInvoicesIds,
      }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export function usePermission() {
  const context = useContext(PermissionContext);

  return context;
}
