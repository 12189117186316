import AxiosInterceptor from "utils/axios-interceptor";

export async function getRegisterBusinessId(businessId) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/register/businessid/${businessId}`;
  const { data } = await axiosInstance.get(url);
  return data;
}

export async function deleteRegisterBusinessId(businessId) {
  const axiosInstance = AxiosInterceptor();
  const url = `${process.env.REACT_APP_NODE_API}/register/businessid/${businessId}`;
  const { data } = await axiosInstance.delete(url);
  return data;
}

export async function updateSellerBuyers(sellerBuyersArr, sellerId) {
  const axiosInstance = AxiosInterceptor();
  if (!sellerId) return;

  const payload = {
    sellerId,
    buyersIdArr: sellerBuyersArr,
  };

  await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/seller-buyer`,
    payload
  );
}

export async function updateSellerMarket(tipoMercado, businessId) {
  const axiosInstance = AxiosInterceptor();
  if (!businessId) return;

  const payload = {
    businessId,
    marketTypeId: tipoMercado.id,
    marketTypeName: tipoMercado.name,
  };

  await axiosInstance.put(
    `${process.env.REACT_APP_NODE_API}/business/market`,
    payload
  );
}

export async function getSellerBuyers(businessId) {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/seller-buyer/${businessId}`
  );

  return data;
}
