import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogTitle,
  IconButton,
  LinearProgress,
  MenuItem,
  MenuList,
  Modal,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";

import Content from "example-components/content";

import { PageTitle } from "layout-components";

import CotacaoSimulator from "example-pages/AntecipaGov/cotacaoSimulator";

import Contratos from "components/ContractGov/ContractGov_v2";
import EnhancedTableHead from "components/EnhancedTableHead";

import formatCnpj from "utils/formatCNPJ";

import * as moment from "moment";

import calculadora from "../../assets/images/calculadora.jpeg";
import { headCells } from "./items";
import AxiosInterceptor from "utils/axios-interceptor";
import { useAuth } from "contexts/auth";

const useStyles = makeStyles({
  paper: {
    background: "white",
    width: "105%",
    boxShadow: "none",
    border: "1px solid #99999966",
    maxWidth: "100%",
    marginLeft: "-30px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

export default () => {
  const classes = useStyles();
  const axiosInstance = AxiosInterceptor();

  const [openDetail, setOpenDetail] = useState(false);
  const [cotacaoSelect, setCotacaoSelect] = useState({});
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [orderBy, setOrderBy] = useState("idCotacao");
  const [initialArray, setInitialArray] = useState([]);
  const [messageSuccess] = useState({
    message: "",
    success: false,
  });
  const [data, setData] = useState(initialArray);
  const [cotacoes, setCotacoes] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openSimulador, setOpenSimulator] = useState(false);
  const [rowSelect, setRowSelect] = useState({});
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    if (data.length === 0) {
      axiosInstance
        .get(`${process.env.REACT_APP_NODE_API}/quotations`)
        .then((response) => {
          setLoading(true);
          setData(response.data);
          setCotacoes(response.data);
          setInitialArray(response.data);
          setLoading(false);
        });
    }
  }, [data.length]);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const labelDisplayedRows = ({ from, to, count }) =>
    `${from}-${to} de ${count}`;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleChangePage = (event, newPage) => {
    if (Number.isNaN(parseInt(newPage, 10))) return;

    const maximumPages = Math.max(
      0,
      Math.ceil(cotacoes.length / rowsPerPage) - 1
    );

    if (newPage <= maximumPages) {
      setPage(parseInt(newPage, 10));
      const skip = rowsPerPage * page;
      const take = rowsPerPage * newPage;
      const newDataToShow = cotacoes.slice(skip, take);
      setData(newDataToShow);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setData(cotacoes);
  };

  const postCreateInvoice = async (row) => {
    const axiosInstance = AxiosInterceptor();
    axiosInstance.post(
      `${process.env.REACT_APP_API_URL}/serpro/CreateInvoice`,
      row
    );
  };
  const createPreTitle = (row) => {
    setRowSelect(row);
    return postCreateInvoice(row);
  };

  function Row(props) {
    const { row, index } = props;
    const [anchorElement, setAnchorElement] = useState(null);
    const openAnchorEl = Boolean(anchorElement);

    function closeAnchor() {
      setAnchorElement(null);
    }

    const handlerOpenSimulador = () => {
      setOpenSimulator(true);
    };
    const selectRows = (e) => {
      e.stopPropagation();
      setCotacaoSelect(row);
      setOpenDetail(true);
    };
    return (
      <>
        <TableBody key={index}>
          <TableCell>
            <IconButton onClick={selectRows}>
              <AddCircleIcon />
            </IconButton>
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            {formatCnpj(row.niFornecedor)}
            {"\n"}
            <br />
            {row.nomeFornecedor}
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            {parseFloat(row.valorCotacao).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "BRL",
            })}
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            {parseFloat(row.margemDisponivel).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              style: "currency",
              currency: "BRL",
            })}
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            {moment(new Date(row.dataCotacao)).format("DD/MM/YYYY")}
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            {row.preAprovado && row.preAprovado.taxa12
              ? `${row.preAprovado.taxa12} | ${row.preAprovado.taxa24} | ${row.preAprovado.taxa36}`
              : "N/A"}
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            {row.preAprovado
              ? Number(
                  row.preAprovado.valorAprovado
                    .replace(".", "")
                    .replace(",", ".")
                ).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "BRL",
                })
              : Number(0).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "BRL",
                })}
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            {row.preAprovado != null ? (
              <Tooltip arrow title="Vencimento próximo ou já vencido">
                <span
                  className="w-75 badge badge-danger"
                  style={{ fontSize: "12px" }}
                >
                  {moment(new Date(row.dataCotacao))
                    .add(row.preAprovado.prazoMaximo, "days")
                    .format("DD/MM/YYYY")}
                </span>
              </Tooltip>
            ) : (
              <span style={{ fontSize: "12px" }}>Sem prazo</span>
            )}
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>
            <img
              onClick={() => {
                handlerOpenSimulador(row);
                setRowSelect(row);
              }}
              className="calculator"
              src={calculadora}
              alt="Simular"
              style={{ cursor: "pointer" }}
            />
          </TableCell>
        </TableBody>

        <Popover
          id="mouse-over-popover"
          open={openAnchorEl}
          anchorEl={anchorElement}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={closeAnchor}
          disableRestoreFocus
        >
          <MenuList>
            <MenuItem className="itemIncludeTitle" onClick={() => {}}>
              Regstrar operação
            </MenuItem>
            <MenuItem
              className="itemIncludeTitle"
              onClick={() => {
                handlerOpenSimulador(row);
                setRowSelect(row);
              }}
            >
              Abrir Simulador
            </MenuItem>
          </MenuList>
        </Popover>
      </>
    );
  }

  return (
    <>
      {openSuccess ? (
        <Dialog
          onClose={() => setOpenSuccess(false)}
          open={openSuccess}
          aria-labelledby="simple-dialog-title"
        >
          <DialogTitle
            style={{ display: "flex", alignItems: "center" }}
            id="simple-dialog-title"
          >
            {messageSuccess.message}{" "}
            {messageSuccess.success ? (
              <CheckCircleIcon style={{ color: "green" }} />
            ) : (
              <ErrorIcon style={{ color: "red" }} />
            )}
          </DialogTitle>
        </Dialog>
      ) : (
        ""
      )}
      <Content>
        <PageTitle
          titleHeading="Relação de Cotações e Simulador"
          titleDescription="Exibe a relação de cotações e contratos vindos da SERPRO"
        />

        <div className="example-card-seamless mb-4-spacing">
          <h5 className="display-5 mb-4 font-weight-bold" />

          <div>
            {loading && <LinearProgress value={55} color="primary" />}
            {!loading && (
              <>
                <Paper className={classes.paper}>
                  <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                        headCells={headCells}
                        classes={classes}
                      />
                      {stableSort(data, getComparator(order, orderBy))
                        .slice(0, rowsPerPage)
                        .map((row, index) => (
                          <Row key={index} row={row} index={index} />
                        ))}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={cotacoes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage="Linhas por página"
                    labelDisplayedRows={labelDisplayedRows}
                  />
                </Paper>
              </>
            )}
          </div>
        </div>
      </Content>

      <Modal
        open={openDetail}
        onClose={() => setOpenDetail(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-deion"
      >
        <Contratos
          close={setOpenDetail}
          contratos={cotacaoSelect.cotacaoContratos}
          cotacao={cotacaoSelect}
        />
      </Modal>
      <Modal
        open={openSimulador}
        onClose={() => setOpenSimulator(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-deion"
      >
        <CotacaoSimulator
          close={setOpenSimulator}
          handlerMaturity={createPreTitle}
          row={rowSelect}
        />
      </Modal>
    </>
  );
};
