export const LiquidacaoConts = [
  {
    value: 11,
    label: 'Boleto',
  },
  {
    value: 12,
    label: 'Crédito em conta corrente',
  },
  {
    value: 13,
    label: 'Liquidação em Cartório',
  },
  {
    value: 14,
    label: 'Sacado - Boleto',
  },
  {
    value: 15,
    label: 'Sacado - Crédito em Conta Corrente',
  },
  {
    value: 16,
    label: 'Recompra Cedente - Nova Operação',
  },
  {
    value: 17,
    label: 'Recompra Cedente - Crédito em Conta Corrente',
  },
  {
    value: 18,
    label: 'Renegociação',
  },
  {
    value: 19,
    label: 'Liquidação com isenção de comissão',
  },
];
