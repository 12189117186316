import React, { useEffect, useState } from "react";

import {
  Grid,
  IconButton,
  Box,
  Typography,
  Dialog,
  Tabs,
  Tooltip,
} from "@material-ui/core";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";

import PropTypes from "prop-types";

import { Modal } from "components/material/Modal";
import { ReactIf, ReactThen } from "components/ReactIf";

import formatCpf from "utils/formatCPF";
import formatTelefone from "utils/formatTelefone";

import axios from "axios";

import { createUpdateUser, updatePassword } from "services/user";

import { useAuth } from "contexts/auth";
import { useToast } from "contexts/toast";

import { useModal } from "hooks/useModal";
import AxiosInterceptor from "utils/axios-interceptor";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const compareString = (str1, str2) => str1.localeCompare(str2) === 0;

const UserFocus = (props) => {
  const {
    userDialog,
    setUserDialog,
    business,
    changeBusinessUsers,
    StylizedInput,
    removeUser,
  } = props;
  const axiosInstance = AxiosInterceptor();

  const auth = useAuth();
  const { userOrigin } = useAuth();
  const userEmail = auth.user.email;

  const { toast } = useToast();

  const { openModal, openModalTextInput } = useModal();

  const [openModalFull, setOpenModalFull] = useState(false);

  const [value, setValue] = useState(0);
  const [user, setUser] = useState(userDialog.user);
  const [updatingUser, setUpdatingUser] = useState(false);

  const [allFilled, setAllFilled] = useState(true);
  const [updated, setUpdated] = useState(false);

  const [emailExists, setEmailExists] = useState(false);

  const stringAttributeIsInvalid = (attr) =>
    attr === undefined || attr === "" || attr === null;

  useEffect(() => {
    setUser(userDialog.user);
    if (!userDialog.user) return;
    handleLoadData(userDialog.user);
  }, [userDialog]);

  const handleLoadData = async (user) => {
    if (!user.userId) return;
    try {
      toast("Carregando dados", { id: "user" });

      toast.success("Dados carregados", { id: "user" });
    } catch (err) {
      toast.error(err.message, { id: "user" });
    }
  };

  const handleUpdatePassword = async (user, newPassword) => {
    await toast.promise(updatePassword({ ...user, password: newPassword }), {
      loading: "Redefinindo senha",
      success: "Senha redefinida com sucesso!",
      error: "Falha ao redefinir senha",
    });
  };

  const handleOpenPasswordModal = (user) => {
    openModalTextInput(
      "textField",
      "Redefinir Senha",
      "password",
      "Redefinir",
      "Cancelar",
      handleUpdatePassword,
      user
    );
  };

  const updateUserStatus = async (actualStatus) => {
    if (updatingUser) return;
    setUpdatingUser(true);
    const updatedUser = user;
    updatedUser.statusId = actualStatus === 1 ? 2 : 1;
    updatedUser.password = undefined;
    axiosInstance
      .post(
        `${process.env.REACT_APP_NODE_API}/users/${
          updatedUser.userId === undefined ? "create" : "update"
        }`,
        updatedUser
      )
      .then(() => {
        userDialog.setUser(updatedUser);
        setOpenModalFull(false);
        setUpdatingUser(false);
      })
      .catch(() => {
        setOpenModalFull(false);
        setUpdatingUser(false);
      });
  };

  const updateUser = async () => {
    const updatedUser = { ...user, email: user.accessId, password: undefined };
    const data = await createUpdateUser(updatedUser, userOrigin);

    userDialog.setUser(data);
    setUser(data);
    setUserDialog({ ...userDialog, user: data });
    changeBusinessUsers(data, userDialog.index);
    setUpdated(true);
    setTimeout(() => {
      setUpdated(false);
      setUpdatingUser(false);
    }, 2000);
  };

  const updateUserData = async () => {
    if (updatingUser) return;
    setUpdatingUser(true);

    const userFilled = !(
      stringAttributeIsInvalid(user.name) ||
      stringAttributeIsInvalid(user.accessId) ||
      stringAttributeIsInvalid(user.phone)
    );

    if (userFilled) {
      try {
        await toast.promise(updateUser(), {
          loading: "Atualizando dados",
          success: "Dados atualizados com sucesso",
          error: "Erro atualizar dados",
        });
      } catch (error) {
        const isEmailError = error.response?.data.emailError;
        if (isEmailError) {
          setEmailExists(true);
          setUpdatingUser(false);
          setTimeout(() => {
            setEmailExists(false);
          }, 2000);
        }
        setUpdatingUser(false);
      }
    } else {
      setAllFilled(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setOpenModalFull(false);
    setUserDialog({ ...userDialog, open: false });
  };

  return (
    <>
      <Dialog
        maxWidth="xl"
        open={userDialog.open}
        onClose={() => {
          setUserDialog({ ...userDialog, open: false });
          setOpenModalFull(false);
        }}
      >
        <Modal
          id="customized-dialog-title"
          style={{
            display: "flex",
            background: "#1E212E",
            width: "41.7%",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
          onClose={handleClose}
        />
        {user !== undefined && (
          <Grid
            container
            spacing={0}
            style={{ height: "580px", width: "910px" }}
          >
            <Grid item xs={12} lg={5}>
              <div
                className="hero-wrapper bg-composed-wrapper h-100 justify-content-center p-4"
                style={{ backgroundColor: "#373A44", position: "relative" }}
              >
                {openModalFull && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      backgroundColor: "#000000aa",
                      zIndex: 999,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: 16,
                        padding: 32,
                      }}
                    >
                      <h1 className="display-4 font-weight-bold">
                        {Number(user.statusId) === 1 ? "Desativar" : "Ativar"}
                      </h1>
                      <p className="font-size-lg mb-0 text-black-50">
                        Deseja{" "}
                        {Number(user.statusId) === 1 ? "desativar" : "ativar"} o
                        usuário?
                      </p>
                      <div className="d-flex flex-row align-items-center justify-content-end w-100 mt-3">
                        <span
                          className="text-black-50 mr-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => setOpenModalFull(false)}
                        >
                          Cancelar
                        </span>
                        <span
                          className="text-black-50"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            updateUserStatus(Number(user.statusId));
                          }}
                        >
                          Confirmar
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="w-100 d-flex align-items-center">
                  <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                  <div className="bg-composed-wrapper--content p-5">
                    <div
                      className="d-flex align-items-center"
                      style={{ color: "#fff" }}
                    >
                      {Number(user.statusId) === 1 ? (
                        <span
                          className="px-4 h-auto py-1 badge badge-pill"
                          style={{ backgroundColor: "#45B465" }}
                        >
                          Ativo
                        </span>
                      ) : (
                        <span
                          className="px-4 h-auto py-1 badge badge-pill"
                          style={{ backgroundColor: "#F85051" }}
                        >
                          Desativado
                        </span>
                      )}
                    </div>
                    <div className="text-white mt-3">
                      <h1 className="display-4 my-3 font-weight-bold text-white-50">
                        {business.name}
                      </h1>
                      <p className="font-size-lg mb-0 text-white-50">
                        {user.name}
                      </p>
                      <p className="font-size-lg mb-0 text-white-50">
                        {user.accessId}
                      </p>
                      <p className="font-size-lg mb-0 text-white-50">
                        {formatTelefone(user.phone)}
                      </p>
                      <div className="divider border-2 my-5 border-light opacity-2 rounded w-25" />
                    </div>
                  </div>
                </div>
                <div
                  className="hero-footer"
                  style={{ border: "1px solid white", borderRadius: 32 }}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <ReactIf
                      condition={!compareString(userEmail, user.accessId)}
                    >
                      <ReactThen>
                        {Number(user.statusId) === 1 ? (
                          <Tooltip arrow placement="top" title="Desativar">
                            <IconButton
                              color="primary"
                              size="medium"
                              variant="outlined"
                              className="text-white"
                              onClick={() => setOpenModalFull(true)}
                            >
                              <CancelOutlinedIcon className="font-size-lg" />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip arrow placement="top" title="Ativar">
                            <IconButton
                              color="primary"
                              size="medium"
                              variant="outlined"
                              className="text-white"
                              onClick={() => setOpenModalFull(true)}
                            >
                              <CheckCircleOutlinedIcon className="font-size-lg" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </ReactThen>
                    </ReactIf>

                    <Tooltip arrow placement="top" title="Redefinir Senha">
                      <IconButton
                        color="primary"
                        size="medium"
                        variant="outlined"
                        className="text-white"
                        onClick={() => handleOpenPasswordModal(user)}
                      >
                        <VpnKeyOutlinedIcon className="font-size-lg" />
                      </IconButton>
                    </Tooltip>
                    <ReactIf
                      condition={!compareString(userEmail, user.accessId)}
                    >
                      <ReactThen>
                        <Tooltip arrow placement="top" title="Excluir Usuário">
                          <IconButton
                            color="primary"
                            size="medium"
                            variant="outlined"
                            className="text-white"
                            onClick={() =>
                              openModal(
                                "warning",
                                "Atenção!",
                                "Tem certeza que deseja remover esse usuário?",
                                removeUser,
                                userDialog.index
                              )
                            }
                          >
                            <DeleteOutlineOutlinedIcon className="font-size-lg" />
                          </IconButton>
                        </Tooltip>
                      </ReactThen>
                    </ReactIf>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} lg={7}>
              <div className="bg-white h-100">
                <Tabs
                  value={value}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="fullWidth"
                  onChange={handleChange}
                />
                <TabPanel
                  value={value}
                  index={0}
                  style={{
                    width: "100%",
                    height: "88%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      height: "370px",
                      flexDirection: "column",
                      width: "465px",
                    }}
                  >
                    <StylizedInput
                      label="Nome"
                      inputName="name"
                      required
                      user={user}
                      setter={setUser}
                      index={userDialog.index}
                      filledState={allFilled}
                      style={{ marginBottom: "20px" }}
                      inputProps={{ maxLength: 70 }}
                    />
                    <StylizedInput
                      label="Email"
                      inputName="accessId"
                      required
                      user={user}
                      setter={setUser}
                      index={userDialog.index}
                      filledState={allFilled}
                      orError={emailExists}
                      style={{ marginBottom: "20px" }}
                    />
                    <StylizedInput
                      label="CPF"
                      inputName="cpf"
                      user={user}
                      setter={setUser}
                      index={userDialog.index}
                      filledState={allFilled}
                      style={{ marginBottom: "20px" }}
                      inputProps={{ maxLength: 14 }}
                      format={formatCpf}
                    />
                    {/* <TextField
                      autoFocus
                      fullWidth
                      select
                      variant="outlined"
                      label="Origem"
                      id="origem"
                      value={shortOrigin}
                      name="origin"
                      onChange={(e) => setShortOrigin(e.target.value)}
                    >
                      <MenuItem disabled value="">
                        Selecionar
                      </MenuItem>
                      {shortOriginList.map((origin) => (
                        <MenuItem value={origin} key={origin}>
                          {origin}
                        </MenuItem>
                      ))}
                    </TextField> */}
                    <div>
                      <StylizedInput
                        fullwidth
                        label="Celular"
                        inputName="phone"
                        required
                        user={user}
                        setter={setUser}
                        index={userDialog.index}
                        filledState={allFilled}
                        format={formatTelefone}
                        inputProps={{ maxLength: 14 }}
                        style={{ marginTop: "20px" }}
                      />
                    </div>

                    {updated ? (
                      <Tooltip
                        placement="left"
                        title="Salvo!"
                        style={{
                          borderRadius: "50%",
                          width: 56,
                          height: "auto",
                          backgroundColor: "#373A44",
                          padding: 16,
                          color: "#fff",
                          alignSelf: "flex-end",
                          boxShadow: "1px 3px 5px #888",
                          cursor: "pointer",
                          transitionDuration: 1,
                          marginTop: "35px",
                        }}
                      >
                        <IconButton
                          color="primary"
                          size="medium"
                          variant="outlined"
                          className="text-green"
                        >
                          <CheckOutlinedIcon style={{ color: "#45B465" }} />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        placement="left"
                        title="Salvar"
                        style={{
                          borderRadius: "50%",
                          width: 56,
                          height: "auto",
                          backgroundColor: "#373A44",
                          padding: 16,
                          color: "#fff",
                          alignSelf: "flex-end",
                          boxShadow: "1px 3px 5px #888",
                          cursor: "pointer",
                          marginTop: "35px",
                        }}
                      >
                        <IconButton
                          color="primary"
                          size="medium"
                          variant="outlined"
                          className="text-white"
                          onClick={() => updateUserData()}
                        >
                          <CheckOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                </TabPanel>
              </div>
            </Grid>
          </Grid>
        )}
      </Dialog>
    </>
  );
};

export default UserFocus;
