import AxiosInterceptor from "utils/axios-interceptor";

export async function getBusinessIdOrigin(businessId) {
  const axiosInstance = AxiosInterceptor();

  const options = {
    method: "GET",
    url: `${process.env.REACT_NODE_APP_API}/users/origin/businessId/${businessId}`,
  };

  const { data } = await axiosInstance.request(options);

  return data;
}

export async function getUserIdOrigin(userId) {
  const axiosInstance = AxiosInterceptor();

  const options = {
    method: "GET",
    url: `${process.env.REACT_NODE_APP_API}/users/origin/userId/${userId}`,
  };

  const { data } = await axiosInstance.request(options);

  return data;
}

export async function getTheme(businessId) {
  const axiosInstance = AxiosInterceptor();

  if (!businessId) return;

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/originType/theme/${businessId}`
  );

  return data;
}

export async function getBusinessByUserEmail(email, token) {
  const axiosInstance = AxiosInterceptor();

  if (!email) return;

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/users/${email}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
}

export async function getOriginByHostname(hostname) {
  const axiosInstance = AxiosInterceptor();

  if (!hostname) return;

  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/originType/host/${hostname}`
  );

  return data;
}

export async function insertOrUpdateOrigin(data) {
  const axiosInstance = AxiosInterceptor();

  const { data: retornoDoInsert } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/originType`,
    data
  );

  return retornoDoInsert;
}
