export const CONST_RISCO = [
  {
    name: 'AA',
    value: 11,
  },
  {
    name: 'AB',
    value: 10,
  },
  {
    name: 'BA',
    value: 9,
  },
  {
    name: 'BB',
    value: 8,
  },
  {
    name: 'CA',
    value: 7,
  },
  {
    name: 'CB',
    value: 6,
  },
  {
    name: 'DA',
    value: 5,
  },
];
