import AxiosInterceptor from "utils/axios-interceptor";

export const setMarcarLido = async (businessId) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/businessNotification/marcarLido/${businessId}`
  );

  return data;
};

export const getBusinessNotifications = async (businessId) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.get(
    `${process.env.REACT_APP_NODE_API}/businessNotification/businessReceiver/${businessId}`
  );

  return data;
};

export const readBusinessNotification = async (businessNotificationId) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/businessNotification/update`,
    {
      businessNotificationId,
      isReaded: 1,
    }
  );

  return data;
};

export const sendBusinessNotification = async ({
  businessReceiverId,
  businessSenderId,
  notificationText,
  notificationLink,
}) => {
  const axiosInstance = AxiosInterceptor();
  const { data } = await axiosInstance.post(
    `${process.env.REACT_APP_NODE_API}/businessNotification/create`,
    {
      businessReceiverId,
      businessSenderId,
      notificationText,
      notificationLink,
    }
  );

  return data;
};
