import React from 'react';

export default function Dossier({ color = '#FFFFFF', size = 40 }) {
  return (
    <svg
      id="Grupo_187"
      data-name="Grupo 187"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 122.3) / 100}
      viewBox="0 0 33.425 40.998"
    >
      <g id="_x32_2_documents_2_">
        <path
          id="Caminho_82"
          data-name="Caminho 82"
          d="M184.085,163.434h16.027a.6.6,0,1,0,0-1.2H184.085a.6.6,0,0,0,0,1.2Z"
          transform="translate(-172.578 -149.242)"
          fill={color}
        />
        <path
          id="Caminho_83"
          data-name="Caminho 83"
          d="M184.085,219.022h16.027a.6.6,0,1,0,0-1.2H184.085a.6.6,0,0,0,0,1.2Z"
          transform="translate(-172.578 -200.379)"
          fill={color}
        />
        <path
          id="Caminho_84"
          data-name="Caminho 84"
          d="M184.085,274.61h16.027a.6.6,0,1,0,0-1.2H184.085a.6.6,0,0,0,0,1.2Z"
          transform="translate(-172.578 -251.516)"
          fill={color}
        />
        <path
          id="Caminho_85"
          data-name="Caminho 85"
          d="M80.71,7.689a.606.606,0,0,0-.175-.4L73.427.176A.608.608,0,0,0,73,0H55.256A2.358,2.358,0,0,0,52.9,2.355v.452h-.452a2.359,2.359,0,0,0-2.342,2.106h-.466a2.358,2.358,0,0,0-2.355,2.355V27.456a.6.6,0,0,0,1.2,0V7.268a1.155,1.155,0,0,1,1.154-1.154h.452V36.537a2.358,2.358,0,0,0,2.355,2.355H67.727a.6.6,0,0,0,0-1.2H52.449a1.155,1.155,0,0,1-1.154-1.154V5.163a1.155,1.155,0,0,1,1.154-1.154H52.9V7.28a.6.6,0,0,0,1.2,0V2.355A1.155,1.155,0,0,1,55.256,1.2H72.4V5.955A2.358,2.358,0,0,0,74.757,8.31H79.51V33.73a1.155,1.155,0,0,1-1.154,1.154h-23.1A1.155,1.155,0,0,1,54.1,33.73V9.9a.6.6,0,0,0-1.2,0V33.73a2.358,2.358,0,0,0,2.355,2.355H76.7v.452a1.156,1.156,0,0,1-1.154,1.154h-5.2a.6.6,0,1,0,0,1.2h3.523a1.156,1.156,0,0,1-1.126.9h-23.1a1.155,1.155,0,0,1-1.154-1.154V30.073a.6.6,0,1,0-1.2,0v8.569A2.358,2.358,0,0,0,49.641,41h23.1a2.359,2.359,0,0,0,2.342-2.106h.466A2.358,2.358,0,0,0,77.9,36.537v-.452h.452a2.358,2.358,0,0,0,2.355-2.355V7.709C80.711,7.7,80.71,7.7,80.71,7.689Zm-5.954-.58A1.155,1.155,0,0,1,73.6,5.955V2.05l5.058,5.058Z"
          transform="translate(-47.286 0)"
          fill={color}
        />
      </g>
    </svg>
  );
}
