import React from 'react';

import { useTheme } from '@material-ui/core/styles';

export default function DiscountVoucher({
  color = '#A0A0A0',
  primary = false,
  size = 32,
}) {
  const theme = useTheme();

  const iconColor = primary ? theme.palette.primary.main : color;

  return (
    <svg
      id="discount-voucher"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * 91.66) / 100}
      viewBox="0 0 44.79 41.057"
    >
      <path
        id="Caminho_47"
        data-name="Caminho 47"
        fill={iconColor}
        d="M17.732,20.465a3.732,3.732,0,1,1,3.732-3.732A3.736,3.736,0,0,1,17.732,20.465Zm0-5.6A1.866,1.866,0,1,0,19.6,16.732,1.869,1.869,0,0,0,17.732,14.866Z"
        transform="translate(12.127 9.395)"
      />
      <path
        id="Caminho_48"
        data-name="Caminho 48"
        fill={iconColor}
        d="M23.732,26.465a3.732,3.732,0,1,1,3.732-3.732A3.736,3.736,0,0,1,23.732,26.465Zm0-5.6A1.866,1.866,0,1,0,25.6,22.732,1.869,1.869,0,0,0,23.732,20.866Z"
        transform="translate(17.325 14.592)"
      />
      <path
        id="Caminho_49"
        data-name="Caminho 49"
        fill={iconColor}
        d="M15.933,31.662a.932.932,0,0,1-.735-1.506l13.064-16.8a.933.933,0,1,1,1.472,1.148L16.67,31.3a.937.937,0,0,1-.737.358Z"
        transform="translate(12.994 9.395)"
      />
      <path
        id="Caminho_50"
        data-name="Caminho 50"
        fill={iconColor}
        d="M21.462,28.993H4.666A4.672,4.672,0,0,1,0,24.328V5.666A4.672,4.672,0,0,1,4.666,1H36.391a4.672,4.672,0,0,1,4.666,4.666V18.729a.933.933,0,0,1-1.866,0V5.666a2.8,2.8,0,0,0-2.8-2.8H4.666a2.8,2.8,0,0,0-2.8,2.8V24.328a2.8,2.8,0,0,0,2.8,2.8h16.8a.933.933,0,1,1,0,1.866Z"
        transform="translate(0 -1)"
      />
      <path
        id="Caminho_51"
        data-name="Caminho 51"
        fill={iconColor}
        d="M40.124,5.866H.933A.933.933,0,0,1,.933,4H40.124a.933.933,0,1,1,0,1.866Z"
        transform="translate(0 1.599)"
      />
      <path
        id="Caminho_52"
        data-name="Caminho 52"
        fill={iconColor}
        d="M40.124,8.866H.933A.933.933,0,0,1,.933,7H40.124a.933.933,0,1,1,0,1.866Z"
        transform="translate(0 4.197)"
      />
    </svg>
  );
}
